import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RestService } from '../rest.service';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentFluxService {
    private _currentPayment: BehaviorSubject<any>;
    private _dynamicfields: BehaviorSubject<any>;
    private _dynamicValues: BehaviorSubject<any>;
    private _customerReference: BehaviorSubject<string>;

    constructor(
        private _sessionService: SessionService,
        private _router: Router,
        private _restService: RestService
    ) {
        this._currentPayment = new BehaviorSubject(null);
        this._dynamicfields = new BehaviorSubject(null);
        this._dynamicValues = new BehaviorSubject(null);
        this._customerReference = new BehaviorSubject('');
    }

    /**
     * @method getDynamicValues()
     * @description return the value of the dynamic values
     */

    getDynamicValues(): any {
        return this._dynamicValues.value ? this._dynamicValues.value : this._sessionService.getElement('dynamicValues');
    }

    /**
     * @method getDynamicFields()
     * @description return the value of the dynamic fields
     */

    getDynamicFields(): any {
        return this._dynamicfields.value ? this._dynamicfields.value : this._sessionService.getElement('dynamicFields');
    }

    /**
     * @method getCurrentPayment()
     * @description return the value of the current payment
     */

    getCurrentPayment(): any {
        return this._currentPayment.value
            ? this._currentPayment.value
            : this._sessionService.getElement('currentPayment');
    }

    /**
     * @method getCurrentPayment()
     * @description return the value of the current payment as Observable
     */

    getCurrentPayment$(): Observable<any> {
        return this._currentPayment.asObservable();
    }

    /**
     * @method getDynamicFields()
     * @description return the value of the current payment as Observable
     */

    getDynamicFields$(): Observable<any> {
        return this._dynamicfields.asObservable();
    }

    /**
     * @method getDynamicValues()
     * @description return the value of the current payment as Observable
     */

    getDynamicValues$(): Observable<any> {
        return this._dynamicValues.asObservable();
    }

    /**
     * @method setCurrentPayment()
     * @param (currentPayment: any)
     * @description set current payment object
     */

    setCurrentPayment(currentPayment: any): void {
        this._sessionService.saveElement('currentPayment', currentPayment);
        this._currentPayment.next(currentPayment);
    }

    /**
     * @method setDynamicValues()
     * @param (dynamicValues: any)
     * @description set dynamic values object
     */

    setDynamicValues(dynamicValues: any): void {
        this._sessionService.saveElement('dynamicValues', dynamicValues);
        this._dynamicValues.next(dynamicValues);
    }

    /**
     * @method setDynamicValues()
     * @param (dynamicFields: any)
     * @description set dynamic values object
     */

    setDynamicFields(dynamicFields: any): void {
        this._sessionService.saveElement('dynamicFields', dynamicFields);
        this._dynamicfields.next(dynamicFields);
    }

    /**
     * @method setData()
     * @param (step: string)
     * @param (value: any)
     * @description set specific value in current payment
     */

    setData(step: string, value: any) {
        let currentPayment = this.getCurrentPayment() || {};
        currentPayment[step] = value;
        this.setCurrentPayment(currentPayment);
    }

    /**
     * @method setDataDynamic()
     * @param (step: string)
     * @param (value: any)
     * @description set specific value in dynamic values
     */

    setValue(step: string, value: any) {
        let dynamicValues = this.getDynamicValues() || {};
        dynamicValues[step] = value;
        this.setDynamicValues(dynamicValues);
    }

    /**
     * @method setDataDynamic()
     * @param (step: string)
     * @param (value: any)
     * @description set specific value in dynamic values
     */

    setField(step: string, value: any) {
        let dynamicFields = this.getDynamicFields() || {};
        dynamicFields[step] = value;
        this.setDynamicFields(dynamicFields);
    }

    /**
     * @method removeCurrentPayment()
     * @description remove current payment object
     */

    removeCurrentPayment() {
        this._sessionService.removeElement('currentPayment');
        this._currentPayment.next(this._sessionService.getElement('currentPayment'));
    }

    /**
     * @method removeDynamicValues()
     * @description remove current payment object
     */

    removeDynamicValues() {
        this._sessionService.removeElement('dynamicValues');
        this._dynamicValues.next(this._sessionService.getElement('dynamicValues'));
    }

    /**
     * @method removeDynamicFields()
     * @description remove current payment object
     */

    removeDynamicFields() {
        this._sessionService.removeElement('dynamicFields');
        this._dynamicfields.next(this._sessionService.getElement('dynamicFields'));
    }

    goBackToFacilitySearch(): void {
        const currentPayment = this.getCurrentPayment();
        const cartUrl = environment.uris.method.cart;

        let files =
            currentPayment.details && currentPayment.details.nameFiles && currentPayment.details.nameFiles.split(',');
        if (files) {
            files.forEach((element: string): void => {
                this._restService.delete(
                    cartUrl + '/removePaymentRequestAttachment' + '?attachmentLocation=' + element
                );
            });
        }
        if (currentPayment && !currentPayment.id) {
            this.removeCurrentPayment();
            this.removeDynamicFields();
            this.removeDynamicValues();
        }
        this._sessionService.removeElement('payments_selected');
        this._router.navigate([
            currentPayment && currentPayment.redirectRoute
                ? currentPayment.redirectRoute
                : '/admin/facilityPayments/newPayment',
        ]);
    }

    setCustomerReference(reference: string): void {
        this._customerReference.next(reference);
    }

    getCustomerReference$(): Observable<string> {
        return this._customerReference.asObservable();
    }

    get instant_customer_reference(): string {
        return this._customerReference.value || '';
    }
}
