<mat-form-field appearance="outline" class="" *ngIf="showBranchSelector">
    <mat-label>Branch</mat-label>
    <mat-select
        multiple
        [(value)]="selectedBranches"
        panelClass="mb-0 pb-0 branch-selector"
        #branchSelector
        (openedChange)="onOpenChange($event)"
    >
        <mat-select-trigger>
            <span *ngIf="selectedBranches?.length === branchesList?.length; else moreThanOne"> ALL </span>
            <ng-template #moreThanOne>
                <span *ngIf="(selectedBranches?.length ?? 0) > 1; else oneSelected">
                    {{selectedBranches.length}} selected
                </span>
            </ng-template>
            <ng-template #oneSelected>
                <span *ngIf="selectedBranches?.length === 1"> {{getBranchName(selectedBranches[0].id)}} </span>
            </ng-template>
        </mat-select-trigger>
        <mat-checkbox
            color="primary"
            class="item-border w-100 px-3 py-2 fw-medium"
            [indeterminate]="isCheckboxIndeterminate"
            [checked]="hasAllItemsSelected"
            (change)="toggleAllItemsSelection($event)"
        >
            {{ hasAllItemsSelected ? 'Deselect All' : 'Select All'}}
        </mat-checkbox>
        <mat-option *ngFor="let branch of branchesList" [value]="branch" class="item-border">
            <span>{{branch.accountName}}</span>
        </mat-option>
        <div class="p-2 w-100">
            <button mat-stroked-button color="primary" class="w-100" (click)="onApplyBranches()">APPLY</button>
        </div>
    </mat-select>
</mat-form-field>
