<!--START: CREDIT CARD ON HOLD-->
<div class="creditCardHold mb-0 fw-normal" role="alert">
    You have past due invoices and your account has been placed on a credit hold.<br />
    Please contact&nbsp;
    <a class="fw-bolder" [href]="'mailto:collections@cargosprint.com' | sanitizer:'url'"
        >collections&#64;cargosprint.com</a
    >
    &nbsp;for support.
</div>
<!--END: CREDIT CARD ON HOLD-->
