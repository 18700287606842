import { Injectable } from '@angular/core';
import { CartBill, PaymentCart } from '@cargos/sprintpay-models';
import { BehaviorSubject, Observable, from, map, of, switchMap, take } from 'rxjs';
import { SecurityService } from 'src/app/services/utils/security.service';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';
import { Facility } from '../models/payments/facility.model';
import { profileComponents } from '../utils/constants';
import { CartAPIService } from './requests/cart-api.service';
import { CartService } from './utils/cart.service';
import { SessionService } from './utils/session.service';

@Injectable({
    providedIn: 'root',
})
export class SummaryValidationService {
    private readonly _cartUrl: string;
    private cartHasExternalLookupRequest: BehaviorSubject<PaymentCart[]> = new BehaviorSubject<PaymentCart[]>([]);

    constructor(
        private _cartApiService: CartAPIService,
        private _securityService: SecurityService,
        private _sessionService: SessionService,
        private _cartService: CartService
    ) {
        this._cartUrl = environment.uris.method.cart;
    }

    validateCartByPort(): Observable<boolean> {
        return this.addToCartValidate('paymentRequest').pipe(
            take(1),
            switchMap((response: any) => {
                if (!response.continue) {
                    return from(
                        Swal.fire({
                            html: '<h5>Hey, psst psst...</h5>' + '<p>' + response.message + '</p>',
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            allowOutsideClick: false,
                            reverseButtons: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.deleteCart();
                                return true;
                            } else {
                                return false;
                            }
                        })
                    );
                } else {
                    return of(true);
                }
            })
        );
    }

    addToCartValidate(chargeType: string, facility?: Facility): Observable<{ message?: string; continue: boolean }> {
        return of(this._securityService.verifyComponentsSecurity(profileComponents.cart)).pipe(
            switchMap((isCartAvailable: boolean) => {
                if (isCartAvailable) {
                    return this._cartApiService.getCart().pipe(
                        map((response: CartBill) => {
                            this._cartService.setCurrentCart(response?.cart);
                            if (response?.cart?.length) {
                                if (chargeType === 'invoices') {
                                    const hasPayments = this.getPaymentRequests(response);
                                    if (hasPayments.length) {
                                        return {
                                            message: `You can't add open invoices while you have payment requests in the cart, Do you want to empty the cart and add an open invoice?`,
                                            continue: false,
                                        };
                                    }
                                    return {
                                        continue: true,
                                    };
                                }
                                if (chargeType === 'paymentRequest') {
                                    const hasInvoices = this.getOpenInvoice(response);
                                    if (hasInvoices.length) {
                                        return {
                                            message: `You cannot add a payment request while you have open invoices in your cart. Do you want to empty the cart and add a new payment request?`,
                                            continue: false,
                                        };
                                    }
                                    let externalPayment = this.getPaymentExternalSource(response);
                                    let currentPayment = facility ? facility.awbLookupData : null;
                                    let externalSource: string = externalPayment.length
                                        ? externalPayment[0].facility.awbLookupData.paymentSource
                                        : '';
                                    let currentSource: string = currentPayment ? currentPayment.paymentSource : '';
                                    if (
                                        (currentPayment == null && externalPayment.length == 0) ||
                                        (currentPayment != null &&
                                            externalPayment.length > 0 &&
                                            externalSource === currentSource)
                                    ) {
                                        return {
                                            continue: true,
                                        };
                                    }
                                    return {
                                        message: `You can't add to cart while you have payments of another source, Do you want to empty the cart and add this?`,
                                        continue: false,
                                    };
                                }
                            }
                            return {
                                continue: true,
                            };
                        })
                    );
                }
                return of({
                    continue: true,
                });
            })
        );
    }

    getOpenInvoice(cartBill: CartBill) {
        return cartBill.cart
            .filter((item: PaymentCart) => item.originalInvoice)
            .map((openInvoice: PaymentCart) => {
                return openInvoice;
            });
    }

    getPaymentRequests(cartBill: CartBill) {
        return cartBill.cart
            .filter((item: PaymentCart) => !item.originalInvoice)
            .map((paymentRequest: PaymentCart) => {
                return paymentRequest;
            });
    }

    getPaymentExternalSource(cartBill: CartBill) {
        return cartBill.cart
            .filter((item: any) => item.facility?.awbLookupData)
            .map((externalPayment: any) => {
                return externalPayment;
            });
    }

    /**
     * @method deleteCart()
     * @description Clear the cart so the user can add a new payment
     */

    deleteCart(): void {
        this._cartApiService
            .deleteCart()
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this._sessionService.setPayCount(0);
                },
            });
    }

    setCurrentCart(cart: any): void {
        this.cartHasExternalLookupRequest.next(cart);
    }

    /**
     * @method getCurrentCart()
     * @description
     */

    getCurrentCart$() {
        return this.cartHasExternalLookupRequest.asObservable();
    }

    /**
     *
     * @description Get the request in cart with facility externalLookUp
     */
    hasRequestWithExternalLookup(): Observable<PaymentCart[]> {
        return this._cartService.getCartBill$().pipe(
            map((cartBill) => {
                if (cartBill?.cart) {
                    return cartBill?.cart?.filter((paymentRequest: any) => {
                        return paymentRequest.facility?.externalLookup;
                    });
                }
                return [];
            })
        );
    }

    hasCartInvoices(): Observable<PaymentCart[]> {
        return this._cartService
            .getCartV2$()
            .pipe(map((cart: PaymentCart[]) => cart.filter((paymentCart: PaymentCart) => paymentCart.originalInvoice)));
    }
}
