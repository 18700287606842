<form [formGroup]="companyForm" *ngIf="companyForm">
    <!--START: Company name -->
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>Company name</mat-label>
            <input
                matInput
                type="text"
                id="companyName"
                name="companyName"
                data-cy="companyName"
                formControlName="companyName"
                placeholder="Company name"
                [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="companyName.hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
        </mat-form-field>
    </div>
    <!--END: Company name -->
    <!--START: Company branch -->
    <div class="form-group" *ngIf="branchLocations?.length">
        <mat-form-field class="d-block mb-2" appearance="outline">
            <mat-label>Branch Location</mat-label>
            <mat-select
                formControlName="branchLocation"
                id="brachLocation"
                name="brachLocation"
                [errorStateMatcher]="matcher"
            >
                <mat-option *ngFor="let item of branchLocations" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="branchLocation.hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
        </mat-form-field>
    </div>
    <!--END: Company branch -->
</form>
