<mat-dialog-content id="voucher-request-modal">
    <!-- START: DOWNLOAD -->
    <section id="voucher-request" class="w-100">
        <div id="voucher-request__modal" class="container-fluid">
            <div class="row header mb-2">
                <!-- START: HEADER -->
                <div class="text-center">
                    <h5 class="mb-0">Please, enter the Schenker Voucher #</h5>
                </div>
                <!-- END: HEADER -->
            </div>
            <form class="row" [formGroup]="voucherForm">
                <div class="col-12">
                    <div class="form-group">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>Schenker Voucher #</mat-label>
                            <input
                                matInput
                                type="text"
                                id="voucher"
                                (change)="validateVoucherNumber($event)"
                                name="voucher"
                                formControlName="voucher"
                                autocomplete="off"
                                placeholder="Enter - Schenker Voucher #"
                                [errorStateMatcher]="matcher"
                            />
                            <mat-error *ngIf="voucherForm.controls['voucher'].hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                            </mat-error>
                            <mat-error *ngIf="voucherForm.controls['voucher'].hasError('incorrect')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>
                                Oops. The Voucher # should start with a '5' (for USA offices) or a '6' (for Canada
                                offices) and must be all numeric, with mininum 9 characters and maximum 10 characters.
                                Or may start with a '9' (must be a number between 900000000 and 959999999)
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <!-- START: BUTTON ACTIONS -->
            <div class="row">
                <div class="col-12">
                    <div id="download__actions" mat-dialog-actions class="pb-4 text-end d-block pt-1 pt-md-3">
                        <button
                            mat-button
                            type="button"
                            (click)="closeDialog()"
                            class="btn__sprintpay btn__sprintpay--link p-0 m-0 border-0 text-uppercase text-center"
                        >
                            Cancel
                        </button>
                        <button
                            mat-button
                            type="button"
                            (click)="continue()"
                            class="btn__sprintpay btn__sprintpay--green p-0 m-0 border-0 rounded-0 text-uppercase text-center"
                            [disabled]="voucherForm.invalid"
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
            <!-- END: BUTTON ACTIONS -->
        </div>
    </section>
    <!-- END: DOWNLOAD -->
</mat-dialog-content>
