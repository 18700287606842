<app-breadcrumb></app-breadcrumb>
<app-no-information *ngIf="noInformation === true" [error_message]="noInformationMessage"></app-no-information>
<!--START: VOIDED PAYMENTS DETAIL -->
<section id="voided_payments_detail" *ngIf="paymentDetailFinish && noInformation === false">
    <div id="voided_payments_detail__header" class="mb-3 pt-3">
        <!--START: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
        <div class="row">
            <div class="col-12 col-md-6 d-flex align-items-center justify-content-start">
                <div class="row">
                    <div class="col-12">
                        <h6>Payment Detail: Request#&nbsp;{{id}}</h6>
                    </div>
                </div>
            </div>
            <div class="col-6 justify-content-end d-none d-md-flex align-items-start">
                <div class="align-items-baseline">
                    <button
                        mat-button
                        color="primary"
                        type="button"
                        class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-end voided_payments__submit"
                        (click)="openDialog(logSideNav)"
                    >
                        <mat-icon class="material-symbols-outlined align-middle me-2">show_chart</mat-icon>
                        Activity log
                    </button>
                </div>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
        <!--START: HEADER AND ACTION BUTTONS FOR SMALL SCREEN -->
        <div class="row">
            <div class="col-12 justify-content-end d-flex d-md-none align-items-center">
                <button
                    mat-button
                    type="button"
                    class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-start voided_payments__submit"
                    (click)="openDialog(logSideNav)"
                >
                    <mat-icon class="material-symbols-outlined align-middle me-2">show_chart</mat-icon>
                    Activity log
                </button>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS FOR SMALL SCREEN -->
    </div>
    <!--START: PAYMENT STATUS -->
    <app-payment-status [paymentDetail]="paymentDetail"></app-payment-status>
    <!--END: PAYMENT STATUS -->
    <div id="voided_payments_detail__container" class="container-fluid">
        <div class="mb-4 py-2">
            <div class="row">
                <div class="col-sm-12">
                    <div class="p-4">
                        <!-- START: DETAIL CONTAINER -->
                        <!--START: TEMPLATE PAYMENT DETAILS -->
                        <app-payment-details
                            [paymentDetail]="paymentDetail"
                            [values]="paymentDetailsSection"
                            [files]="files"
                            [nameFiles]="nameFiles"
                        ></app-payment-details>
                        <!--END: TEMPLATE PAYMENT DETAILS -->
                        <!--START: TEMPLATE CUSTOMER REFERENCE -->
                        <app-customer-reference
                            [values]="paymentCustomerReference"
                            [paymentDetail]="paymentDetail"
                        ></app-customer-reference>
                        <!--END: TEMPLATE CUSTOMER REFERENCE -->
                        <ng-container [ngSwitch]="paymentDetail.companyType">
                            <!--START: TEMPLATE KN USERS -->
                            <app-kn-customer-reference
                                *ngSwitchCase="companyNames.kn"
                                [paymentDetail]="paymentDetail"
                            ></app-kn-customer-reference>
                            <!--END: TEMPLATE KN USERS -->
                            <!--START: TEMPLATE SCHENKER USERS -->
                            <app-schenker-customer-reference
                                *ngSwitchCase="companyNames.schenker"
                                [paymentDetail]="paymentDetail"
                            ></app-schenker-customer-reference>
                            <!--END: TEMPLATE SCHENKER USERS -->
                            <!--START: TEMPLATE DHL USERS -->
                            <app-dhl-customer-reference
                                *ngSwitchCase="companyNames.dhl"
                                [paymentDetail]="paymentDetail"
                            ></app-dhl-customer-reference>
                            <!--END: TEMPLATE KN USERS -->
                            <!--START: TEMPLATE GEODIS USERS -->
                            <app-geodis-customer-reference
                                *ngSwitchCase="companyNames.geodis"
                                [paymentDetail]="paymentDetail"
                            ></app-geodis-customer-reference>
                            <!--END: TEMPLATE GEODIS USERS -->
                            <!--START: TEMPLATE EMOTRANS USERS -->
                            <app-emotrans-customer-reference
                                *ngSwitchCase="companyNames.emotrans"
                                [paymentDetail]="paymentDetail"
                            ></app-emotrans-customer-reference>
                            <!--END: TEMPLATE EMOTRANS USERS -->
                            <!--START: TEMPLATE RHENUS USERS -->
                            <app-rhenus-customer-reference
                                *ngSwitchCase="companyNames.rhenus"
                                [paymentDetail]="paymentDetail"
                            ></app-rhenus-customer-reference>
                            <!--END: TEMPLATE RHENUS USERS -->
                            <!--START: TEMPLATE DYNAMIC COMPANY -->
                            <app-company-customer-reference
                                [paymentDetail]="paymentDetail"
                                *ngSwitchDefault
                            ></app-company-customer-reference>
                            <!--END: TEMPLATE DYNAMIC COMPANY -->
                        </ng-container>
                        <!-- END: DETAIL CONTAINER -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--END: VOIDED PAYMENTS DETAIL -->
<!--START: TEMPLATE ACTIVITY LOG SIDENAV -->
<ng-template #logSideNav>
    <app-timeline [paymentLogs]="paymentLogs" [paymentNumber]="this.paymentDetail.details.awb"></app-timeline>
</ng-template>
<!--END: TEMPLATE ACTIVITY LOG SIDENAV -->
