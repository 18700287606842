import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// COMPONENTS
import { PasswordComponent } from './password.component';

const routes: Routes = [
    {
        path: '',
        component: PasswordComponent,
        children: [
            {
                path: '',
                redirectTo: 'password/forgot-password',
                pathMatch: 'full',
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import('./forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
            },
            {
                path: 'new-password',
                loadChildren: () => import('./new-password/new-password.module').then((m) => m.NewPasswordModule),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PasswordRoutingModule {}
