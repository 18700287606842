import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Facility } from '../../models/payments/facility.model';

@Component({
    standalone: true,
    selector: 'app-facility-data',
    imports: [CommonModule, MatIconModule],
    templateUrl: './facility-data.component.html',
})
export class FacilityDataComponent {
    @Input() facility: Facility | undefined;

    constructor() {}
}
