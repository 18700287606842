import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsTokensType } from '@cargos/sprintpay-models';
import { Observable } from 'rxjs';
import { UserData } from 'src/app/models/auth/signup.model';
import { CompanyDetails } from 'src/app/models/utils/company';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';
export interface ResponseEmail {
    companyDetails?: CompanyDetails;
    activationUuid?: string;
}

@Injectable({ providedIn: 'root' })
export class SignUpAPIService {
    private _authentication;

    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenService
    ) {
        this._authentication = environment.uris.method.authentication;
    }

    signUp(data: UserData): Observable<string> {
        const url = `${this._authentication}/signup/customer?source=sprintpay`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.post(url, data, { headers, responseType: 'text' });
    }

    validateEmail(email: string): Observable<ResponseEmail> {
        const url = `${this._authentication}/register/validate`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.post(url, { email }, { headers });
    }

    sendEmail(source: string, activationKey: string): Observable<any> {
        const url = `${this._authentication}/activationEmail/resend`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.post(url, { source, activationKey }, { headers });
    }

    refreshToken(refreshToken: string | null): Observable<CredentialsTokensType> {
        const url = `${this._authentication}/refreshToken?token=${refreshToken}`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.post<CredentialsTokensType>(url, {}, { headers });
    }
}
