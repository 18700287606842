<!-- START: GUEST PAYMENT CONFIRMATION -->
<section id="payment-confirmation" class="container-fluid">
    <div class="d-flex flex-column h-100 justify-content-center align-items-center payment-confirmation--height p-4">
        <div class="row justify-content-center">
            <div class="col-12">
                <img class="mx-auto d-block mb-5" src="assets/images/spLogo.svg" alt="Logo" />
            </div>
            <div class="col-12">
                <img
                    class="mx-auto d-block"
                    src="assets/images/credit-card-brand/success.svg"
                    alt="New user illustration"
                />
            </div>
            <!-- START: NOTIFICATION EMAIL -->
            <div class="col-12">
                <h4 class="d-block text-center mt-2 mb-3">Your payment has been successfully processed!</h4>
                <p class="d-block text-center">We have sent your payment receipt to: {{notificationEmail}}</p>
            </div>
            <!-- END: NOTIFICATION EMAIL -->
            <!-- START: DOWNLOAD RECEIPT -->
            <div class="col-12">
                <h5 class="d-block text-center mt-2 mb-3">Receipt:</h5>
                <p class="d-block text-center">Now you can download your receipt.</p>
                <button
                    mat-button
                    color="primary"
                    class="d-block mx-auto border-0 rounded-0 text-uppercase text-center btn__sprintpay btn__sprintpay--greenBorder"
                    (click)="getPaymentReceipt()"
                >
                    Download PDF
                </button>
            </div>
            <!-- END: DOWNLOAD RECEIPT -->
        </div>
        <!-- START: CREATE NEW ACCOUNT -->
        <ng-container class="row">
            <div class="col-12 my-3">
                <div class="card p-4">
                    <h6 class="d-block text-center mt-2">Good news!</h6>
                    <small class="d-block text-center mt-2"
                        >By registering right now, you can save a significant amount of time when it comes to making
                        payments.</small
                    >
                    <button
                        class="d-block mx-auto btn__sprintpay btn__sprintpay--link text-uppercase text-center"
                        (click)="validateEmail()"
                    >
                        Create a new account
                    </button>
                </div>
            </div>
        </ng-container>
        <!-- END: CREATE NEW ACCOUNT -->
    </div>
</section>
<!-- END: GUEST PAYMENT CONFIRMATION -->
