import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription, finalize, take, takeUntil, throwError } from 'rxjs';
import { HomeService } from 'src/app/services/home.service';
import { CartService } from 'src/app/services/utils/cart.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { EChecksService } from 'src/app/services/utils/echeck.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import Swal from 'sweetalert2';
import { environment } from '../../../../../../environments/environment';
import { RestService } from '../../../../../services/rest.service';
import { SummaryValidationService } from '../../../../../services/summary-validation.service';
import { SecurityService } from '../../../../../services/utils/security.service';
import { profileComponents } from '../../../../../utils/constants';

@Component({
    selector: 'app-card-invoice',
    templateUrl: './card-invoice.component.html',
})
export class CardInvoiceComponent implements OnInit, OnDestroy {
    invoices: any[];
    selectedInvoices: number[];
    addToCartCapability: string[] = [''];
    isAllSelected: boolean;
    private _subscription: Subscription;
    private readonly _invoicesUrl: string;
    private readonly _cartUrl: string;
    public isAutomatedDebitsEnabled: boolean = false;
    private unsubscribe$ = new Subject<void>();
    public hasBankAccounts = true;

    constructor(
        private _restService: RestService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _router: Router,
        private _summaryValidationService: SummaryValidationService,
        private _securityService: SecurityService,
        private _homeService: HomeService,
        private _customerService: CustomerService,
        private _cartService: CartService,
        private _dialog: MatDialog,
        private _errorHandlerService: ErrorHandlerService,
        private _echecksService: EChecksService
    ) {
        this._subscription = Subscription.EMPTY;
        this._invoicesUrl = environment.uris.method.invoices;
        this._cartUrl = environment.uris.method.cart;
        this.invoices = [];
        this.selectedInvoices = [];
        this.addToCartCapability = [''];
        this.isAllSelected = false;
    }

    ngOnInit(): void {
        this._getOpenInvoices();
        this.isAutomatedDebitsEnable();
        this.getBankAccounts();

        if (this._securityService.verifyComponentsSecurity(profileComponents.addOpenInvoicesOfTableToCart)) {
            this.addToCartCapability.push(this._securityService.getUserType());
        }
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    /**
     * @method someSelected()
     * @description Checks if selected more than one and not all invoices
     */

    someSelected(): boolean {
        return this.selectedInvoices.length > 0 && !this.isAllSelected;
    }

    /**
     * @method selectedInvoice(invoice)
     * @param (item: any)
     * @description Toggle selection of invoice
     */

    selectedInvoice(item: any): void {
        const targetIndex: number = this.selectedInvoices.indexOf(item.id);
        targetIndex === -1 ? this.selectedInvoices.push(item.id) : this.selectedInvoices.splice(targetIndex, 1);
        this.isAllSelected = this.selectedInvoices.length === this.invoices.length;
    }

    /**
     * @method selectAllInvoices()
     * @description Toggles selection of all invoices
     */

    selectAllInvoices(isSelected: boolean): void {
        this.isAllSelected = isSelected;
        this.selectedInvoices = this.isAllSelected
            ? this.invoices.filter((invoice) => !invoice?.achDebitId).map((invoice) => invoice.id)
            : [];
    }

    /**
     * @method overdueInvoice();
     * @param (date: any)
     * @description Reviews the date of the invoices and select the overdue date time
     */

    overdueInvoice(date: any): any {
        const currentDate: string = moment().format('YYYY-MM-DD 00:00:00');
        const dueDate: moment.Moment = moment(date);
        const overdueDays: any = dueDate.startOf('day').diff(currentDate, 'days');
        return overdueDays >= 0
            ? { message: overdueDays + ' day(s)', overdue: false }
            : { message: overdueDays * -1 + ' day(s)', overdue: true };
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.id;
    }

    /**
     * @method addInvoiceToCart()
     * @description Makes the id array so the invoices can be added into the cart
     */

    addInvoiceToCart(): void {
        let idInvoices: any[] = this.selectedInvoices.map((id: any) => {
            return id;
        });
        this._addInvoice(idInvoices);
    }

    /**
     * @method _getOpenInvoices()
     * @description Gets the open invoices, and we order those from last to newest
     */

    private _getOpenInvoices(): void {
        this._ngxSpinnerService.show();
        this._restService
            .get(`${this._invoicesUrl}/getRecentInvoicesOpen?date=${new Date().valueOf()}`, {})
            .then((result: any): void => {
                this.invoices = result.slice(0, 8).map((item: any) => {
                    return Object.assign(item, {
                        overdueMessage: this.overdueInvoice(item.dueDate).message,
                        overdue: this.overdueInvoice(item.dueDate).overdue,
                    });
                });
            })
            .finally((): void => {
                this._homeService.setInvoices(!this.invoices.length);
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method _addInvoice()
     * @param (idInvoices: any)
     * @description Reviews if the user has payment request on the cart
     */

    private _addInvoice(idInvoices: any): void {
        this._subscription = this._summaryValidationService.addToCartValidate('invoices').subscribe({
            next: (result: any): void => {
                if (result.continue) {
                    this._ngxSpinnerService.show();
                    this._postOpenInvoices(idInvoices);
                } else {
                    Swal.fire({
                        html: `<h5>Hey, psst psst...</h5>
                    <p>${result.message}</p>`,
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        allowOutsideClick: false,
                        reverseButtons: true,
                    }).then((result): void => {
                        if (result.isConfirmed) {
                            this._summaryValidationService.deleteCart();
                            this._ngxSpinnerService.show();
                            this._postOpenInvoices(idInvoices);
                        }
                    });
                }
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
    }

    /**
     * @method _postOpenInvoices()
     * @param (idInvoices: any)
     * @description Adds and open invoice into the cart
     */

    private _postOpenInvoices(idInvoices: any): void {
        this._restService
            .post(`${this._cartUrl}/addOpenInvoicesToCart`, { data: idInvoices })
            .then((result: any): void => {
                if (result) {
                    setTimeout((): void => {
                        this._ngxSpinnerService.hide();
                        this._router.navigate(['admin/cart']);
                    }, 1500);
                }
            })
            .catch((error: any): void => {
                this._ngxSpinnerService.hide();
                Swal.fire({
                    title: 'Oops...',
                    text: error?.error?.errors[0],
                    confirmButtonColor: '#14bb9c',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                });
            });
    }

    public isAutomatedDebitsEnable(): void {
        this._customerService
            .hasAutomatedDebits$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (value: { customerAutomatedDebit: boolean; customerAccountAutomatedDebit: boolean }) => {
                    this.isAutomatedDebitsEnabled =
                        value.customerAutomatedDebit && !value.customerAccountAutomatedDebit;
                },
            });
    }

    public createFile(): void {
        let openInvoiceIds = this.selectedInvoices.map((id: number) => {
            return id.toString();
        });

        if (!openInvoiceIds.length) return;

        this._ngxSpinnerService.show();
        this._cartService
            .createFile(openInvoiceIds)
            .pipe(
                take(1),
                finalize(() => this._ngxSpinnerService.hide())
            )
            .subscribe({
                next: () => {
                    this._getOpenInvoices();
                },
                error: (error) => {
                    const errorMessage = this._errorHandlerService.errorMsg(error.error);
                    Swal.fire({
                        html: `${errorMessage}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                },
            });
    }

    public getBankAccounts(): void {
        if (this.isAutomatedDebitsEnabled) {
            this._echecksService
                .hasBankAccounts$()
                .pipe(take(1))
                .subscribe({
                    next: (hasBankAccounts) => {
                        this.hasBankAccounts = hasBankAccounts;
                    },
                    error: (err: HttpErrorResponse): void => this._errorHandlerService.handleError(err),
                });
        }
    }
}
