<app-breadcrumb></app-breadcrumb>
<app-no-information
    *ngIf="noInformation === true"
    [error_message]="noInformationMessage"
    [paymentType]="'openInvoices'"
></app-no-information>
<!--START: OPEN INVOICES -->
<section id="open_invoices" *ngIf="noInformation === false">
    <div id="open_invoices__header" class="mb-3 pt-3">
        <!--START: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
        <div class="row">
            <div class="col-12 col-md-6 d-flex align-items-center justify-content-start">
                <h6>Open invoices</h6>
            </div>
            <div class="col-md-6 justify-content-end d-flex flex-column flex-md-row align-items-center gap-2 gap-md-0">
                <ng-container *ngIf="!isAutomatedDebitsEnabled; else createFileTemplate">
                    <div *ngxPermissionsOnly="addToCartSecurity" class="align-items-baseline me-3">
                        <button
                            mat-button
                            color="primary"
                            type="button"
                            (click)="addToCart()"
                            class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-end addToCart"
                        >
                            Add to cart
                        </button>
                    </div>
                </ng-container>
                <div class="align-items-baseline" *ngIf="downloadSettings">
                    <button
                        mat-button
                        color="primary"
                        type="button"
                        (click)="openDialog(download)"
                        class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-end download"
                    >
                        Download
                    </button>
                </div>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
    </div>
    <!--START: CALL AM TABLE CHILD COMPONENT -->
    <ng-container *ngIf="invoicesCompleted">
        <div id="open_invoices__container" class="container-fluid py-4 px-5">
            <div class="row">
                <div class="col-12">
                    <app-am-table
                        (ifItemSelected)="itemsSelected($event)"
                        (filter)="search($event)"
                        (nextPage)="search($event)"
                        (eventOnSort)="sort($event)"
                        [recordIndicators]="indicators"
                        [am_actions]="actions"
                        [am_filter]="amTable[0].filter"
                        [am_sorting]="amTable[0].sorting"
                        [am_pagination]="amTable[0].pagination"
                        [am_columns]="amTable[0].displayedColumns"
                        [am_columnsName]="amTable[0].displayedColumnsName"
                        [am_source]="amTable[0].dataSource"
                        [am_paginationSizes]="amTable[0].paginationSizes"
                        [am_totalRows]="amTable[0].totalElements"
                        [am_sortableColumns]="sortableColumns"
                    ></app-am-table>
                </div>
            </div>
        </div>
    </ng-container>
    <!--END: CALL AM TABLE CHILD COMPONENT -->
</section>
<!--END: OPEN INVOICES -->
<!--START: TEMPLATE ACTIVITY DOWNLOAD -->
<ng-template #download>
    <app-download
        [exportable]="downloadSettings"
        [recordSelected]="recordsSelected"
        (downloadRange)="downloadRecords($event)"
    ></app-download>
</ng-template>
<!--END: TEMPLATE ACTIVITY DOWNLOAD -->

<!--START: CREATE FILE TEMPLATE -->
<ng-template #createFileTemplate>
    <div class="align-items-baseline flex-column flex-md-row d-flex mt-3 mt-md-0 gap-2 gap-md-0">
        <button
            mat-button
            type="button"
            (click)="createFile()"
            [disabled]="!hasBankAccounts"
            class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 pay-with-bank-debit align-self-center"
        >
            Pay with Bank debit
        </button>
        <button
            mat-button
            type="button"
            class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 add_to_cart align-self-center"
            routerLink="../../bank-debits"
            *ngIf="!hasBankAccounts"
        >
            Add Bank Account
        </button>
    </div>
</ng-template>
<!--START: CREATE FILE TEMPLATE -->
