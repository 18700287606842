import { Balance } from './cartTypes';

export const calculateSprintpayCredit = (creditAvailable: number = 0, creditLimit: number = 0): [Balance, number] => {
    const availableCredit =
        creditAvailable <= 0 || creditLimit <= 0 ? 0 : creditAvailable > creditLimit ? creditLimit : creditAvailable;
    const creditPercentage =
        availableCredit === 0 || creditLimit <= 0 ? 0 : Math.round(((availableCredit * 100) / creditLimit) * 10) / 10;

    return [{ availableCredit, creditLimit }, creditPercentage];
};
