<!--START: Security form -->
<form class="row" [formGroup]="securityForm" (ngSubmit)="onSubmit()">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <!--START: Current password -->
        <div class="form-group">
            <mat-form-field class="d-block mb-2" appearance="outline">
                <mat-label>Current password</mat-label>
                <input
                    matInput
                    [type]="passwordHintHide ? 'password' : 'text'"
                    id="currentPassword"
                    name="currentPassword"
                    formControlName="currentPassword"
                    autocomplete="off"
                    placeholder="Enter your current password"
                    [errorStateMatcher]="matcher"
                />
                <mat-icon matSuffix (click)="passwordHintHide = !passwordHintHide"
                    >{{passwordHintHide ? 'visibility_off' : 'visibility'}}</mat-icon
                >
                <mat-error *ngIf="securityForm.controls['currentPassword'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: Current password -->
        <!--START: New password -->
        <div class="form-group">
            <mat-form-field class="d-block mb-2" appearance="outline">
                <mat-label>New password</mat-label>
                <input
                    matInput
                    [type]="newPasswordHintHide ? 'password' : 'text'"
                    id="newPassword"
                    name="newPassword"
                    formControlName="newPassword"
                    autocomplete="off"
                    placeholder="Enter your new password"
                    [errorStateMatcher]="matcher"
                />
                <mat-icon matSuffix (click)="newPasswordHintHide = !newPasswordHintHide"
                    >{{newPasswordHintHide ? 'visibility_off' : 'visibility'}}</mat-icon
                >
                <mat-error *ngIf="securityForm.controls['newPassword'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="securityForm.controls['newPassword'].hasError('minlength')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Minimum 8 characters
                </mat-error>
                <mat-error *ngIf="securityForm.controls['newPassword'].hasError('number')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>At lest one number
                </mat-error>
                <mat-error *ngIf="securityForm.controls['newPassword'].hasError('letter')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>At lest one letter
                </mat-error>
                <mat-error *ngIf="securityForm.controls['newPassword'].hasError('non-whitespaces')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Non whitepaces
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: New password -->
        <!--START: Confirm new password -->
        <div class="form-group">
            <mat-form-field class="d-block mb-2" appearance="outline">
                <mat-label>Confirm password</mat-label>
                <input
                    matInput
                    [type]="confirmPasswordHintHide ? 'password' : 'text'"
                    id="reNewPassword"
                    name="reNewPassword"
                    formControlName="reNewPassword"
                    autocomplete="off"
                    placeholder="Confirm your new password"
                    [errorStateMatcher]="matcher"
                />
                <mat-icon matSuffix (click)="confirmPasswordHintHide = !confirmPasswordHintHide"
                    >{{confirmPasswordHintHide ? 'visibility_off' : 'visibility'}}</mat-icon
                >
                <mat-error *ngIf="securityForm.controls['reNewPassword'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="securityForm.controls['reNewPassword'].hasError('matching')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. The password doesn't match, review your
                    information
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: Confirm new password -->
    </div>
    <div class="d-flex">
        <!--START: Submit button -->
        <button
            mat-button
            type="submit"
            class="d-block mx-auto border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green"
            [disabled]="!(securityForm.valid && (securityForm.touched || securityForm.dirty))"
        >
            Save security information
        </button>
        <!--END: Submit button -->
    </div>
</form>
<!--END: Security form -->
