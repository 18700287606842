<div class="row">
    <!-- START: DETAIL TITLE -->
    <div class="detail__sections mb-4">
        <div class="col-12">
            <div class="d-flex justify-content-between">
                <span class="my-3"><strong>Schenker Customer reference</strong></span>
                <ng-container
                    *ngIf="((paymentDetail?.approvalStatus === 'PENDING' 
        && paymentDetail?.uniqueRequestor && isStartingLevel && !paymentDetail?.ownerCanPay 
        && paymentDetail?.sameCustomerAndRequestor)
        || (paymentDetail?.approvalStatus === 'REJECTED' && isStartingLevel && paymentDetail?.sameCustomerAndRequestor)
        || (isStartingLevel && paymentDetail?.id === null )
        || (isStartingLevel && paymentDetail?.id !== null && !!paymentDetail?.fromCart)) && paymentDetail.sprintPaySource && !hasExternalLookup"
                >
                    <button
                        mat-button
                        class="my-2 px-0 text-uppercase text-decoration-none"
                        (click)="editPaymentRequest()"
                    >
                        Edit
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- END: DETAIL TITLE -->
    <!-- START: DETAIL ELEMENTS -->
    <div class="col-12 col-md-4 col-lg-2">
        <p class="card-subtitle me-2">
            <small>Cost Center</small>
        </p>
        <p>
            {{!!paymentDetail?.costCenter ? paymentDetail.costCenter :
            paymentDetail?.schenkerCustomerReference?.costCenter ? paymentDetail.schenkerCustomerReference.costCenter :
            'N/A'}}
        </p>
    </div>
    <ng-container *ngIf="(!hasExternalLookup &&  !id) || id">
        <div class="col-12 col-md-4 col-lg-2">
            <p class="card-subtitle me-2"><small>Voucher #</small></p>
            <p>
                {{!!paymentDetail?.voucher ? paymentDetail.voucher : paymentDetail?.schenkerCustomerReference?.voucher ?
                paymentDetail.schenkerCustomerReference.voucher : 'N/A'}}
            </p>
        </div>
    </ng-container>
    <ng-container *ngIf="(!hasExternalLookup &&  !id) || id">
        <div class="col-12 col-md-4 col-lg-2">
            <p class="card-subtitle me-2">
                <small>Payment Amount</small>
            </p>
            <p>
                {{!!paymentDetail?.externalDataType ? paymentDetail.externalDataType : !!paymentDetail?.paymentAmount ?
                paymentDetail.paymentAmount : paymentDetail?.schenkerCustomerReference?.paymentAmount ?
                paymentDetail.schenkerCustomerReference.paymentAmount : 'N/A'}}
            </p>
        </div>
    </ng-container>
    <ng-container *ngIf="(!hasExternalLookup &&  !id) || id">
        <div class="col-12 col-md-4 col-lg-2">
            <p class="card-subtitle me-2"><small>Type</small></p>
            <p>
                {{!!paymentDetail?.payType ? paymentDetail.payType : !!paymentDetail?.type ? paymentDetail.type :
                paymentDetail?.schenkerCustomerReference?.type ? paymentDetail.schenkerCustomerReference.type : 'N/A'}}
            </p>
        </div>
    </ng-container>
    <div class="col-12 col-md-4 col-lg-2">
        <p class="card-subtitle me-2">
            <small>Location</small>
        </p>
        <p>
            {{!!paymentDetail?.location ? paymentDetail.location :
            paymentDetail?.schenkerCustomerReference?.locationName ?
            paymentDetail.schenkerCustomerReference.locationName : 'N/A'}}
        </p>
    </div>
    <!-- END: DETAIL ELEMENTS -->
</div>
