<!--START: Forgot password -->
<section id="forgot-password">
    <div class="forgot-password__container d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <div class="container">
            <div class="row">
                <app-password-form></app-password-form>
            </div>
        </div>
    </div>
</section>
