export type TypeModuleMFA =
    | 'REGISTRATION'
    | 'GUEST_PAYMENT'
    | 'ACTIVATE_WITH_PASSWORD_UPDATE'
    | 'FORGOT_PASSWORD'
    | 'EMAIL_VALIDATION';
export interface ResponseMFA {
    isVerified: boolean;
    dynamicResponseParameter?: {
        activationKey: string;
    };
}

export enum ModuleMFAEnum {
    REGISTRATION = 'REGISTRATION',
    GUEST_PAYMENT = 'GUEST_PAYMENT',
    ACTIVATE_WITH_PASSWORD_UPDATE = 'ACTIVATE_WITH_PASSWORD_UPDATE',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    EMAIL_VALIDATION = 'EMAIL_VALIDATION',
}
