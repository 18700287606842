export enum StepperSteps {
    STEP_ZERO = 'STEP_ZERO',
    STEP_FIRST = 'STEP_FIRST',
    STEP_SECOND = 'STEP_SECOND',
}

export type StepperNavigationLink = {
    label: string;
    link: string;
    index: number;
    formName: string;
};
