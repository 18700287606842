import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { PaidRoutingModule } from './paid-routing.module';

//COMPONENTS

import { PaidComponent } from './paid.component';

//MODULES

import { SharedModule } from '../../../../shared/shared.module';

@NgModule({
    declarations: [PaidComponent],
    imports: [CommonModule, PaidRoutingModule, RouterModule, ReactiveFormsModule, FormsModule, SharedModule],
})
export class PaidModule {
    constructor() {}
}
