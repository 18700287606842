import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { RequestOptions } from '../models/auth/request.model';
import { TokenService } from './utils/token.service';

@Injectable({
    providedIn: 'root',
})
export class RestService {
    private _mimeTypes: any;

    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenService
    ) {
        this._mimeTypes = [
            { type: 'application/octet.stream', ext: '.mcs' },
            { type: 'application/json', ext: '.json' },
            { type: 'application/jwt', ext: '.jwt' },
            { type: 'application/multipart-core', ext: '.multipart-core' },
            { type: 'application/ogg', ext: '.ogg' },
            { type: 'application/pdf', ext: '.pdf' },
            { type: 'application/rtx', ext: '.rtx' },
            { type: 'application/sql', ext: '.sqls' },
            { type: 'application/xml', ext: '.xml' },
            { type: 'application/zip', ext: '.zip' },
            { type: 'application/zlib', ext: '.zlib' },
            { type: 'image/bmp', ext: '.bmp' },
            { type: 'image/gif', ext: '.gif' },
            { type: 'image/x-icon', ext: '.ico' },
            { type: 'image/jpeg', ext: '.jpg' },
            { type: 'image/png', ext: '.png' },
            { type: 'image/svg+xml', ext: '.svg' },
            { type: 'image/tiff', ext: '.tiff' },
            { type: 'image/webp', ext: '.webp' },
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', ext: '.xlsx' },
            { type: 'text/css', ext: '.css' },
            { type: 'text/csv', ext: '.csv' },
            { type: 'text/csv-schema', ext: '.csv-schema' },
            { type: 'text/html', ext: '.html' },
            { type: 'text/javascript', ext: '.javascript' },
            { type: 'text/rtf', ext: '.rtf' },
            { type: 'text/rtx', ext: '.rtx' },
        ];
    }

    /**
     * @method _extractData()
     * @param (response: any)
     * @description
     * @return
     */

    private _extractData(response: any) {
        return response || {};
    }

    /**
     * @method httpOptions()
     * @description
     * @return
     */

    private _httpOptions() {
        if (this._tokenService.getCurrentUser()) {
            return {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this._tokenService.getCurrentUser()}`,
                }),
            };
        } else {
            return {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                }),
            };
        }
    }

    /**
     * @method buildEndPoint()
     * @param (uri: string)
     * @param (params: any)
     * @description Endpoint builder
     * @return
     */

    public buildEndPoint(uri: string, params: any): string {
        let finalParams: string = '';
        for (let key in params) {
            if (finalParams != '') {
                finalParams += '&';
            }
            finalParams += key + '=' + encodeURIComponent(params[key]);
        }

        return finalParams ? `${uri}?${finalParams}` : uri;
    }

    /**
     * @method post()
     * @param (endpoint: string)
     * @param (params: RequestOptions)
     * @description Post data using promise functionality such as next, error complete, resolve and reject
     * @return A promise with the data from the API call
     */

    post(endpoint: string, params: RequestOptions): any {
        return new Promise<void>((resolve, reject) => {
            let httpOptions: any;

            if (params && params.options) {
                httpOptions = {};

                if (params.options.headers) {
                    const header = new HttpHeaders().set(
                        'Authorization',
                        `Bearer ${this._tokenService.getCurrentUser()}`
                    );
                    httpOptions.headers = header;
                }

                if (params.options.responseType) {
                    httpOptions.responseType = params.options.responseType;
                }
            } else {
                httpOptions = this._httpOptions();
            }

            this._httpClient.post(endpoint, params.data, httpOptions).subscribe({
                next: (result: any) => {
                    resolve(result);
                },
                error: (error: any) => {
                    reject(error);
                },
            });
        });
    }

    /**
     * @method post()
     * @param (endpoint: string)
     * @param (params: RequestOptions)
     * @description Post data using promise functionality such as next, error complete, resolve and reject
     * @return A promise with the data from the API call
     */

    // postSignUp(endpoint: string, params: RequestOptions): any {
    //     return new Promise<void>((resolve, reject) => {
    //         let httpOptions: any;

    //         if (params && params.options) {
    //             httpOptions = {};

    //             if (params.options.headers) {
    //                 const header = new HttpHeaders().set(
    //                     'Authorization',
    //                     `Bearer ${this._tokenService.getCurrentUser()}`
    //                 );
    //                 httpOptions.headers = header;
    //             }

    //             if (params.options.responseType) {
    //                 httpOptions.responseType = params.options.responseType;
    //             }
    //         } else {
    //             httpOptions = this._httpOptions();
    //         }

    //         this._httpClient.post(endpoint, params.data, httpOptions).subscribe({
    //             next: (result: any) => {
    //                 resolve(result);
    //             },
    //             error: (error: any) => {
    //                 reject(error);
    //             },
    //         });
    //     });
    // }

    /**
     * @method get()
     * @param (endpoint: string)
     * @param (params: RequestOptions)
     * @description Retrieves data using promise functionality such as next, error complete, resolve and reject
     * @return A promise with the data from the API call
     */

    get<T>(endpoint: string, params: any): Promise<T> {
        return new Promise<T>((resolve, reject): void => {
            let httpOptions: any;

            if (params && params.options && params.options.headers) {
                httpOptions = {
                    headers: params.options.headers,
                    responseType: params.options.responseType,
                };
            } else {
                httpOptions = this._httpOptions();
            }

            this._httpClient.get<T>(endpoint, httpOptions).subscribe({
                next: (result: any): void => {
                    resolve(result);
                },
                error: (error: any): void => {
                    reject(error);
                },
            });
        });
    }

    /**
     * @method getExtension()
     * @param (contentType: any)
     * @description Retrieves file extension
     * @return A file extension's name
     */
    getExtension(contentType: any): string {
        return this._mimeTypes
            .filter((e: any): boolean => e.type == contentType)
            .map((mime: any) => {
                return mime['ext'];
            });
    }

    /**
     * @method getFile()
     * @param (endpoint: string)
     * @param (params: RequestOptions)
     * @description Retrieves data using promise functionality such as next, error complete, resolve and reject
     * @return A promise with the data from the API call
     */

    getFile(endpoint: string, params: any): any {
        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-type', 'application/pdf')
            .set('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`)
            .set('Accept', 'application/vnd.ms-excel, application/json, application/pdf');
        const finalEndPoint: string = this.buildEndPoint(endpoint, params);
        return this._httpClient.get(finalEndPoint, {
            headers: headers,
            responseType: 'blob',
        });
    }

    /**
     * @method getFiles()
     * @param (endpoint: string)
     * @param (params: RequestOptions)
     * @description Retrieves data using promise functionality such as next, error complete, resolve and reject
     * @return A promise with the data from the API call
     */

    getFiles(endpoint: string, params: any): any {
        return new Promise<void>((resolve, reject): void => {
            let httpOptions: any;

            if (params && params.options && params.options.headers) {
                httpOptions = {
                    headers: params.options.headers,
                    responseType: 'arraybuffer',
                };
            } else {
                httpOptions = {
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this._tokenService.getCurrentUser()}`,
                    }),
                    responseType: 'arraybuffer',
                };
            }

            this._httpClient.get(endpoint, httpOptions).subscribe({
                next: (result: any): void => {
                    resolve(result);
                },
                error: (error: any): void => {
                    reject(error);
                },
            });
        });
    }

    /**
     * @method getFileByPost()
     * @param (endpoint: string)
     * @param (params: RequestOptions)
     * @description Retrieves data using promise functionality such as next, error complete, resolve and reject
     * @return A promise with the data from the API call
     */

    getFileByPost(endpoint: string, params: any): any {
        return new Promise<void>((resolve, reject): void => {
            let httpOptions: any;
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this._tokenService.getCurrentUser()}`,
                }),
                responseType: 'blob',
            };
            this._httpClient.post(endpoint, params, httpOptions).subscribe({
                next: (result: any): void => {
                    resolve(result);
                },
                error: (error: any): void => {
                    reject(error);
                },
            });
        });
    }

    /**
     * @method postFiles()
     * @param (endpoint: string)
     * @param (params: RequestOptions)
     * @description Retrieves data using promise functionality such as next, error complete, resolve and reject
     * @return A promise with the data from the API call
     */

    postFiles(endpoint: string, params: any): any {
        return new Promise<any>((resolve, reject): void => {
            let httpOptions: any;

            const header: HttpHeaders = new HttpHeaders().set(
                'Authorization',
                `Bearer ${this._tokenService.getCurrentUser()}`
            );
            httpOptions = { headers: header, responseType: 'text' };

            this._httpClient.post(endpoint, params, httpOptions).subscribe({
                next: (result: any): void => {
                    resolve(result);
                },
                error: (error: any): void => {
                    reject(error);
                },
            });
        });
    }

    /**
     * @method postFileWithProgress()
     * @param (endpoint: string)
     * @param (formData: any)
     * @returns A promise with the data from the API call
     */

    postFileWithProgress(endpoint: string, formData: any): any {
        let headers: HttpHeaders = new HttpHeaders({
            Authorization: `Bearer ${this._tokenService.getCurrentUser()}`,
        });
        return this._httpClient.post(endpoint, formData, {
            headers: headers,
            observe: 'events',
            reportProgress: true,
            responseType: 'text',
        });
    }

    /**
     * @method delete()
     * @param (endpoint: string)
     * @description Deletes data using promise functionality such as next, error complete, resolve and reject
     * @return A promise with the data from the API call
     */

    delete(endpoint: string): any {
        return new Promise<void>((resolve, reject): void => {
            this._httpClient.delete(endpoint, this._httpOptions()).subscribe({
                next: (result: any): void => {
                    resolve(result);
                },
                error: (error: any): void => {
                    reject(error);
                },
            });
        });
    }

    /**
     * @method postData()
     * @param (endpoint: string)
     * @param (formData: any)
     * @param (headers?: any)
     * @description Subscription POST
     * @return A subscribe with the data from the API call
     */

    postData(endpoint: string, formData: any, headers?: any): Observable<any> {
        let headersObj: any = headers ? headers : this._httpOptions();
        return this._httpClient.post(endpoint, formData, headersObj).pipe(map(this._extractData));
    }

    /**
     * @method getData()
     * @param (endpoint: string)
     * @param (params: any)
     * @param (responseType: any)
     * @returns A promise with the data from the API call
     */

    getData(endpoint: string, params: any, responseType?: any): Observable<any> {
        let date = new Date().valueOf();
        params['date'] = date;

        let finalEndPoint = this.buildEndPoint(endpoint, params);
        let options = responseType ? responseType : this._httpOptions();
        return this._httpClient.get(finalEndPoint, options).pipe(map(this.extractData));
    }

    /**
     * @method extractData()
     * @param (res: any)
     * @returns
     */

    private extractData(res: any) {
        let body = res;
        return body || {};
    }
}
