import { CommonModule } from '@angular/common';
import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { Subject } from 'rxjs';
import { ModuleMFAEnum } from 'src/app/modules/two-factor/verification-code/models/two-factor-models';

export interface VerificationMethodType {
    type: string;
    value: string;
}
@Component({
    selector: 'app-verification-method',
    standalone: true,
    templateUrl: './verification-method.component.html',
    imports: [CommonModule, MatCheckboxModule],
})
export class VerificationMethodComponent implements AfterViewChecked, OnDestroy {
    public ModuleMFAEnum = ModuleMFAEnum;
    public verificationMethod: string = '';
    private unsubscribe$: Subject<void>;
    private verificationMethodMessages = new Map<string, string>([
        [ModuleMFAEnum.EMAIL_VALIDATION, 'We will send an email with a code to your email address.'],
        ['default', 'We will send an email with a code to your email address on file.'],
    ]);

    @Input() email = '';
    @Input() phoneNumber = '';
    @Input() message = '';
    @Input() module = '';
    @Input() set resetValue(reset: boolean) {
        if (reset) {
            this.verificationMethod = '';
        }
    }
    @Output() eventOnVerification = new EventEmitter<VerificationMethodType>();

    constructor() {
        this.unsubscribe$ = new Subject<void>();
    }

    ngAfterViewChecked(): void {
        if (this.email && !this.phoneNumber) {
            this.eventOnVerification.emit({ type: 'email', value: this.email });
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    onChange(event: MatCheckboxChange, type: string): void {
        if (event?.checked) {
            this.verificationMethod = type;
            const value: string = type === 'email' ? this.email : this.phoneNumber;
            this.eventOnVerification.emit({ type, value });
        } else {
            this.eventOnVerification.emit({ type: '', value: '' });
        }
    }

    get verificationMethodMessage(): string {
        const module = this.module === ModuleMFAEnum.EMAIL_VALIDATION ? this.module : 'default';
        return this.verificationMethodMessages.get(module) || '';
    }
}
