<ng-container *ngIf="data.paymentMethod !== 'N/A'; else noPaymentMethod">
    <div class="d-flex gap-1">
        <img src="assets/images/credit-card-brand/echeck.svg" alt="echeck card logo" width="32px" height="20px" />
        <p class="m-0">
            Account ending ···
            <span>{{accountEnding}}</span>
        </p>
    </div>
</ng-container>

<ng-template #noPaymentMethod> {{data.paymentMethod}} </ng-template>
