<ng-container>
    <!--START: INSTRUCTIONS SECTION -->
    <div class="row">
        <div class="col-12">
            <div class="form-group payment-instructions">
                <h5>Instructions</h5>
                <p>Please download the template below, complete the fields and upload the XLS file.</p>
                <button
                    class="border-0 p-0 d-flex align-items-center fw-bold text-uppercase payment-instructions-download-csv"
                    (click)="downLoadExcel()"
                >
                    <span class="material-symbols-outlined">download</span>
                    Download Template
                </button>
            </div>
        </div>
    </div>
    <!--END: INSTRUCTIONS SECTION -->
    <!--START: UPLOAD FILE SECTION -->
    <app-file-handler [removedFile]="removedFile"> </app-file-handler>
    <!--END: UPLOAD FILE SECTION -->
    <!--START: PROGRESSBAR SECTION -->
    <ng-container *ngIf="selectedFile">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="payment-uploaded-csv">
                    <div class="form-group d-flex justify-content-center align-items-center">
                        <div class="file-icon">
                            <img src="assets/images/payments/icon-csv-file.svg" alt="CSV file icon" />
                        </div>
                        <div class="file-info-area">
                            <div class="d-flex flex-column justify-content-center">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="file-info-area-body_left">
                                        <p class="file-name">{{ selectedFile.name }}</p>
                                        <p class="file-size">{{ selectedFileSize }} kb</p>
                                    </div>
                                    <div class="file-info-area-body_right">
                                        <a class="pointer" (click)="removeFile()">
                                            <i class="material-icons main-icon close">close</i>
                                        </a>
                                    </div>
                                </div>

                                <ng-container *ngIf="progressBarPercentage > 0">
                                    <div class="progress">
                                        <div
                                            class="progress-bar"
                                            role="progressbar"
                                            style="width: {{progressBarPercentage.toString()}}%;"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                        >
                                            {{progressBarPercentage.toString()}} %
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <!--END: PROGRESSBAR SECTION -->
</ng-container>
