<app-breadcrumb></app-breadcrumb>
<app-no-information
    *ngIf="noInformation === true"
    [error_message]="noInformationMessage"
    [paymentType]="'voided'"
></app-no-information>
<!--START: VOIDED PAYMENTS -->
<section id="voided_payments" *ngIf="noInformation === false">
    <div id="voided_payments__header" class="mb-3 pt-3">
        <!--START: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
        <div class="row">
            <div class="col-12 col-md-6 d-flex align-items-center justify-content-start">
                <h6>Voided payment(s)</h6>
            </div>
            <div class="col-6 justify-content-end d-none d-md-flex align-items-center">
                <div class="align-items-baseline">
                    <button
                        mat-button
                        color="primary"
                        type="button"
                        (click)="openDialog(download)"
                        class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-end voided_payments__submit"
                    >
                        Download
                    </button>
                </div>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
        <!--START: HEADER AND ACTION BUTTONS FOR SMALL SCREEN -->
        <div class="row">
            <div class="col-12 justify-content-end d-flex d-md-none align-items-center">
                <button
                    mat-button
                    color="primary"
                    type="button"
                    (click)="openDialog(download)"
                    class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-start voided_payments__submit"
                >
                    Download
                </button>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS FOR SMALL SCREEN -->
    </div>
    <!--START: CALL AM TABLE CHILD COMPONENT -->
    <ng-container *ngIf="voidedPayments">
        <div id="voided_payments__container" class="container-fluid py-4 px-5">
            <div class="row">
                <div class="col-12">
                    <app-am-table
                        (ifItemSelected)="itemsSelected($event)"
                        (filter)="search($event)"
                        (nextPage)="getVoidedPayments($event)"
                        (eventOnSort)="sort($event)"
                        [am_filter]="amTable[0].filter"
                        [am_sorting]="amTable[0].sorting"
                        [am_pagination]="amTable[0].pagination"
                        [am_columns]="amTable[0].displayedColumns"
                        [am_columnsName]="amTable[0].displayedColumnsName"
                        [am_source]="amTable[0].dataSource"
                        [am_paginationSizes]="amTable[0].paginationSizes"
                        [am_totalRows]="amTable[0].totalElements"
                        [am_sortableColumns]="sortableColumns"
                    ></app-am-table>
                </div>
            </div>
        </div>
    </ng-container>
    <!--END: CALL AM TABLE CHILD COMPONENT -->
</section>
<!--END: PENDING APPROVAL -->
<!--START: TEMPLATE ACTIVITY DOWNLOAD -->
<ng-template #download>
    <app-download
        [exportable]="downloadSettings"
        [recordSelected]="recordsSelected"
        (downloadRange)="downloadRecords($event)"
    ></app-download>
</ng-template>
<!--END: TEMPLATE ACTIVITY DOWNLOAD -->
