import { Injectable } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { CustomerService } from '../../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../../services/utils/payment-flux.service';
import { SessionService } from '../../../../../services/utils/session.service';
import { companyName, regex } from '../../../../../utils/constants';

@Injectable({
    providedIn: 'root',
})
export class PaymentDetailService {
    private _typeOptionsRestrictions: any;
    private _geodisDomain: boolean;
    private _companyName: string;
    private _startingLevel: boolean;
    private _minAmount: number;

    constructor(
        private _customerService: CustomerService,
        private _sessionService: SessionService,
        private _paymentFluxService: PaymentFluxService
    ) {
        const customer = this._customerService.getCustomer();
        this._companyName = customer?.approvalLevels?.company?.name || '';
        this._startingLevel = !!this._sessionService.getElement('startingLevel');
        this._minAmount = this._startingLevel && this._companyName === companyName.schenker ? 0.25 : 1.01;
        this._typeOptionsRestrictions = ['Storage/Demurrage', 'ISC + Storage', 'Other***'];
        this._geodisDomain = this._customerService.isUserDomain(companyName.geodis.toLowerCase());
    }

    /**
     * @method
     * @param ()
     * @description initialize the shared variables
     */
    initialize() {
        const customer = this._customerService.getCustomer();
        this._companyName = customer?.approvalLevels?.company?.name || '';
        this._startingLevel = !!this._sessionService.getElement('startingLevel');
        this._minAmount = this._startingLevel && this._companyName === companyName.schenker ? 0.25 : 1.01;
        this._typeOptionsRestrictions = ['Storage/Demurrage', 'ISC + Storage', 'Other***'];
        this._geodisDomain = this._customerService.isUserDomain(companyName.geodis.toLowerCase());
    }

    /**
     * @method validateForm()
     * @param (form: FormGroup)
     * @description return form with the validators
     *
     */

    validateForm(form: FormGroup): FormGroup {
        Object.keys(form.controls).forEach((key: string) => {
            let validator = this.requiredField(key);
            form.get(key)?.setValidators(validator);
        });
        return form;
    }

    /**
     * @method validateCompanyForm()
     * @param (form: FormGroup)
     * @description return form with the validators
     *
     */

    validateCompanyForm(form: FormGroup): FormGroup {
        Object.keys(form.controls).forEach((key: string) => {
            let validator = this.requiredCompanyField(key);
            form.get(key)?.setValidators(validator);
        });
        return form;
    }

    /**
     * @method validateCompanyForm()
     * @param (form: FormGroup)
     * @description return form with the validators
     *
     */

    validatePaymentDetailWithExternalLookup(field: string): Boolean {
        switch (field) {
            case 'paymentType' || 'type':
                return false;
            case 'paymentAmount':
                return false;
            case 'amount':
                return false;
            case 'awb':
                return false;
            case 'hawb':
                return false;
        }
        return false;
    }

    /**
     * @method requiredField()
     * @param (field: string)
     * @returns current validations by controlName
     */

    requiredField(field: string): any {
        switch (field) {
            case 'paymentType':
                return this._validatorsForPaymentTypes();
            case 'amount':
                return this._validatorsForAmount();
            case 'awb':
                return this._validatorsForAWB();
            case 'number':
                return this._validatorsForNumber();
            case 'prefix':
                return this._validatorsForPrefix();
            case 'paidTo':
                return this._validatorsForPaidTo();
            case 'eiReferenceFileNumber':
                return this._validatorsForEiReference();
            case 'nameFiles':
                return this._validatorsForNameFiles();
            case 'hawb':
                return this._validatorsForHawb();
            case 'pickupDate':
                return this._validatorsForPickupDate();
            case 'customerReference':
                return this._validatorsForCustomerReference();
            case 'notificationEmail':
                return this._validatorsForNotificationEmail();
            default:
                return false;
        }
    }

    /**
     * @method requiredCompanyField()
     * @param (field: string)
     * @returns current validations by controlName
     */

    requiredCompanyField(field: string): any {
        switch (field) {
            case 'paymentAmount':
                return this._validatorsForPaymentAmount();
            case 'type':
                return this._validatorsForType();
            case 'locationId':
                return this._validatorsForLocationId();
            case 'locationName':
                return this._validatorsForLocationName();
            case 'costCenter':
                return this._validatorsForCostCenter();
            case 'voucher':
                return this._validatorsForVoucher();
            default:
                return false;
        }
    }

    /**
     * @method _validatorsForPaymentAmount()
     * @returns Validations for paymentTypes field
     */

    private _validatorsForPaymentAmount(): Validators | null {
        this.initialize();
        const facility = this._paymentFluxService.getCurrentPayment()?.facility;
        if (!!facility.externalLookup) {
            return null;
        } else {
            return [Validators.required];
        }
    }

    /**
     * @method _validatorsForType()
     * @returns Validations for paymentTypes field
     */

    private _validatorsForType(): Validators | null {
        this.initialize();
        const facility = this._paymentFluxService.getCurrentPayment()?.facility;
        if (!!facility.externalLookup) {
            return null;
        } else {
            return [Validators.required];
        }
    }

    /**
     * @method _validatorsForLocationId()
     * @returns Validations for paymentTypes field
     */

    private _validatorsForLocationId(): Validators | null {
        this.initialize();
        const facility = this._paymentFluxService.getCurrentPayment()?.facility;
        if (!!facility.externalLookup) {
            return null;
        } else {
            return [Validators.required];
        }
    }

    /**
     * @method _validatorsForLocationName()
     * @returns Validations for paymentTypes field
     */

    private _validatorsForLocationName(): Validators | null {
        this.initialize();
        const facility = this._paymentFluxService.getCurrentPayment()?.facility;
        if (!!facility.externalLookup) {
            return null;
        } else {
            return [Validators.required];
        }
    }

    /**
     * @method _validatorsForCostCenter()
     * @returns Validations for paymentTypes field
     */

    private _validatorsForCostCenter(): Validators {
        this.initialize();
        return [Validators.required, Validators.pattern(regex.alphanumeric)];
    }

    /**
     * @method _validatorsForVoucher()
     * @returns Validations for paymentTypes field
     */

    private _validatorsForVoucher(): Validators | null {
        this.initialize();
        const facility = this._paymentFluxService.getCurrentPayment()?.facility;
        if (!!facility.externalLookup) {
            return null;
        } else {
            return [Validators.required, Validators.pattern(regex.schenkerVoucher)];
        }
    }

    /**
     * @method validatorsForPaymentTypes()
     * @returns Validations for paymentTypes field
     */

    private _validatorsForPaymentTypes(): Validators | null {
        this.initialize();
        const facility = this._paymentFluxService.getCurrentPayment()?.facility;
        if (!!facility.externalLookup) {
            return null;
        } else {
            return this._startingLevel ? null : [Validators.required];
        }
    }

    /**
     * @method validatorsForAWB()
     * @returns Validations for awb field
     */

    private _validatorsForAWB(): Validators | null {
        const facility = this._paymentFluxService.getCurrentPayment()?.facility;
        if (!!facility.externalLookup) {
            return null;
        } else {
            if (facility && facility.awbFieldType !== 'AWB') {
                return [
                    Validators.required,
                    Validators.minLength(3),
                    CustomValidators.preventHTMLContent(),
                    Validators.maxLength(225),
                ];
            } else {
                return [Validators.required, CustomValidators.preventHTMLContent()];
            }
        }
    }

    /**
     * @method validatorsForPrefix()
     * @returns Validations for prefix field
     */

    private _validatorsForPrefix(): Validators | null {
        const facility = this._paymentFluxService.getCurrentPayment()?.facility;
        if (!!facility.externalLookup) {
            return null;
        } else {
            if (facility && facility.awbFieldType !== 'AWB') {
                return null;
            } else {
                return [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(3),
                    Validators.pattern(regex.numeric),
                ];
            }
        }
    }

    /**
     * @method validatorsForNumber()
     * @returns Validations for number field
     */

    private _validatorsForNumber(): Validators | null {
        const facility = this._paymentFluxService.getCurrentPayment()?.facility;
        if (!!facility.externalLookup) {
            return null;
        } else {
            if (facility && facility.awbFieldType !== 'AWB') {
                return null;
            } else {
                return [
                    Validators.required,
                    Validators.maxLength(8),
                    Validators.minLength(8),
                    Validators.pattern(regex.numeric),
                    CustomValidators.awbValidator(),
                ];
            }
        }
    }

    /**
     * @method validatorsForAmount()
     * @returns Validations for amount field
     */

    private _validatorsForAmount(): Validators | null {
        this.initialize();
        const facility = this._paymentFluxService.getCurrentPayment()?.facility;
        if (!!facility.externalLookup) {
            return null;
        } else {
            return [Validators.required, Validators.min(this._minAmount), Validators.pattern(regex.numericDecimals)];
        }
    }

    /**
     * @method validatorsForPaidTo()
     * @returns Validations for paidTo field
     */

    private _validatorsForPaidTo(): Validators {
        return [Validators.required];
    }

    /**
     * @method validatorsForEiReference()
     * @returns Validations for eiReferenceFileNumber field
     */

    private _validatorsForEiReference(): Validators | null {
        this.initialize();
        if ((this._startingLevel && this._companyName === companyName.geodis) || this._geodisDomain) {
            return [Validators.required];
        }
        return null;
    }

    /**
     * @method validatorsForNameFiles()
     * @returns Validations for nameFiles field
     */

    private _validatorsForNameFiles(): Validators | null {
        this.initialize();
        const currentPayment = this._paymentFluxService.getCurrentPayment();
        if (this._startingLevel && this._companyName !== '') {
            if (
                this._companyName === companyName.dhl ||
                (this._companyName === companyName.kn &&
                    currentPayment.knCustomerReference &&
                    this._typeOptionsRestrictions.includes(currentPayment.knCustomerReference.type)) ||
                this._companyName === companyName.geodis ||
                this._companyName === companyName.rhenus
            ) {
                return [Validators.required];
            }
        }
        return false;
    }

    /**
     * @method validatorsForHawb()
     * @returns Validations for hawb field
     */

    private _validatorsForHawb(): Validators | null {
        const facility = this._paymentFluxService.getCurrentPayment()?.facility;
        if (!!facility.externalLookup) {
            return null;
        } else {
            return [CustomValidators.preventHTMLContent()];
        }
    }

    /**
     * @method _validatorsForPickupDate()
     * @returns Validations for pickupDate field
     */
    private _validatorsForPickupDate(): Validators | null {
        const facility = this._paymentFluxService.getCurrentPayment()?.facility;
        if (!!facility.externalLookup) {
            return null;
        } else {
            return [Validators.required];
        }
    }
    /**
     * @method _validatorsForCustomerReference()
     * @returns Validations for customerReference field
     */
    private _validatorsForCustomerReference(): Validators | null {
        return [CustomValidators.preventHTMLContent()];
    }
    /**
     * @method _validatorsForNotificationEmail()
     * @returns Validations for notificationEmail field
     */
    private _validatorsForNotificationEmail(): Validators | null {
        return [Validators.pattern(regex.email)];
    }
}
