import { Directive, HostListener, OnDestroy } from '@angular/core';
import * as dayjs from 'dayjs';
import { Subject, Subscription, take, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/utils/auth.service';
import Swal from 'sweetalert2';
import { SignUpAPIService } from '../services/requests/signup-api.service';
import { CustomerService } from '../services/utils/customer-handler.service';
import { IdleService } from '../services/utils/idle.service';
import { SessionService } from '../services/utils/session.service';
import { TokenService } from '../services/utils/token.service';
import { UserSessionService } from '../services/utils/user-session.service';
import { companyName } from '../utils/constants';

@Directive({
    selector: '[appCatchEvents]',
})
export class CatchEventsDirective implements OnDestroy {
    private _userActivity?: ReturnType<typeof setTimeout>;
    private _endActivity?: ReturnType<typeof setTimeout>;
    private _userInactive: Subject<void>;
    private _isTokenRefreshed?: ReturnType<typeof setInterval>;
    private _subscription: Subscription;

    constructor(
        private _idleService: IdleService,
        private _authService: AuthService,
        private _tokenService: TokenService,
        private _customerService: CustomerService,
        private _sessionService: SessionService,
        private _signUpAPIService: SignUpAPIService,
        private _userSessionService: UserSessionService
    ) {
        this._userInactive = new Subject<void>();
        this._subscription = Subscription.EMPTY;
        this._setIntervalToken();
        this._idleService.initListener();
        this._idleService.setActivity();
        this._listenerUserActivity();
        this._subscribeUserActivity();
    }

    ngOnDestroy(): void {
        this._removeInterval();
        this._subscription.unsubscribe();
    }

    @HostListener('click', ['$event'])
    @HostListener('window:mousemove', ['$event'])
    refreshUserState(): void {
        this._listenerUserActivity();
        this._idleService.setActivity();
    }

    @HostListener('window:beforeunload', ['$event']) async beforeUnloadHandler(event: BeforeUnloadEvent) {
        const pageReloaded = window.performance
            .getEntriesByType('navigation')
            .map((nav) => (nav as PerformanceNavigationTiming).type)
            .includes('reload');
        const navigationPage = window.performance
            .getEntriesByType('navigation')
            .map((nav) => (nav as PerformanceNavigationTiming).type)
            .includes('navigate');
        const company: string = this._customerService.getCompanyName();
        if (!pageReloaded && !navigationPage && company === companyName.kn) {
            var e = event || window.event;
            await this._logout();
            e.preventDefault();
            e.returnValue = false;
        }
    }

    /**
     * @method _listenerUserActivity()
     * @description Update user activity
     */

    private _listenerUserActivity(): void {
        clearTimeout(this._userActivity);
        this._userActivity = setTimeout(() => this._userInactive.next(), 300000);
    }

    /**
     * @method _setIntervalToken()
     * @description Validate each minute token expiration
     */

    private _setIntervalToken(): void {
        this._removeInterval();
        this._isTokenRefreshed = setInterval((): void => {
            this._validateToken();
        }, 60000);
    }

    /**
     * @method _subscribeUserActivity()
     * @description Validate token on user activity
     */

    private _subscribeUserActivity(): void {
        this._subscription = this._userInactive.subscribe({
            next: (): void => {
                this._validateToken();
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
    }

    /**
     * @method _removeInterval()
     * @description Remove the interval
     */

    private _removeInterval(): void {
        clearInterval(this._isTokenRefreshed);
    }

    /**
     * @method _validateToken()
     * @description Validate one minute before of token expiration
     */

    private _validateToken(): void {
        const currentDate = dayjs();
        const token = this._tokenService.getDecodedAccessToken();
        if (token?.exp && !Number.isNaN(Number(token.exp))) {
            const tokenExpDate = dayjs.unix(token.exp);
            if (tokenExpDate.diff(currentDate, 'minute') === 1) {
                if (this._customerHasActivity(currentDate) || this._customerService.getCustomer().isGuest) {
                    this._refreshToken();
                } else {
                    const company = this._customerService.getCompanyName();
                    const instant_payCount = this._sessionService.instant_payCount;

                    if (company && !!instant_payCount) {
                        this._showContinueModalWithTimer();
                    } else {
                        this._showContinueModal();
                    }
                }
            }
        } else {
            this._logout();
        }
    }

    /**
     * @method _customerHasActivity()
     * @param (currentDate: dayjs.Dayjs)
     * @description Validate user activity
     */

    private _customerHasActivity(currentDate: dayjs.Dayjs): boolean {
        return dayjs(currentDate).diff(parseInt(this._idleService.getLastActivity()), 'minute') < 5;
    }

    /**
     * @method _logout()
     * @description Clear all the stored data of the user and takes the user to the login page
     */

    private _logout(): void {
        this._userSessionService.logout();
    }

    /**
     * @method _refreshToken()
     * @description Call to refreshToken petition
     */

    private _refreshToken(): void {
        this._userSessionService
            .refreshToken()
            .pipe(take(1))
            .subscribe({
                error: (): void => {
                    this._logout();
                },
            });
    }

    /**
     * @method _showContinueModalWithTimer()
     * @description Show popup to continue or logout with timer
     */

    private _showContinueModalWithTimer(): void {
        this._endActivity = setTimeout(() => {
            this._logout();
        }, 59000);
        let timerInterval: ReturnType<typeof setInterval>;
        let contador = 60;
        Swal.fire({
            title: 'Hey, psst psst!',
            html: `<div> You have been inactive for a long time. Would you like to continue?</div>
      <p style="margin: 10px 0; font-size:24px; font-weight: 500">${contador} s</p>
      <div style="font-size: 14px; margin-top:10px" class="text-muted"> Note: If you decide to log out, the payment requests that are in your cart will be updated to pending status</div>
      `,
            timer: 60000,
            timerProgressBar: false,
            icon: 'info',
            allowOutsideClick: false,
            focusConfirm: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: '#1dd3b0',
            cancelButtonText: 'No, Log out',
            confirmButtonText: 'Yes, continue',
            showCloseButton: false,
            reverseButtons: true,
            allowEscapeKey: false,
            didOpen: () => {
                const b: HTMLElement = Swal.getHtmlContainer()?.querySelector('p') as HTMLElement;
                timerInterval = setInterval(() => {
                    contador--;
                    b.textContent = contador.toString() + ' s';
                }, 1000);
            },
            willClose: () => {
                clearInterval(timerInterval);
            },
        }).then((result): void => {
            clearTimeout(this._endActivity);
            if (result.isConfirmed) {
                this._refreshToken();
            } else if (result.dismiss) {
                this._logout();
            }
        });
    }

    /**
     * @method _showContinueModal()
     * @description Show popup to continue or logout
     */

    private _showContinueModal(): void {
        this._endActivity = setTimeout(() => {
            this._logout();
        }, 59000);

        Swal.fire({
            title: 'Hey, psst psst!',
            text: 'You have been inactive for a long time. Would you like to continue?',
            icon: 'info',
            allowOutsideClick: false,
            focusConfirm: false,
            showCancelButton: true,
            confirmButtonColor: '#1dd3b0',
            cancelButtonText: 'No, Log out',
            confirmButtonText: 'Yes, continue',
            showCloseButton: false,
            reverseButtons: true,
            allowEscapeKey: false,
        }).then((result): void => {
            clearTimeout(this._endActivity);
            if (result.isConfirmed) {
                this._refreshToken();
            } else if (result.dismiss) {
                this._logout();
            }
        });
    }
}
