<app-breadcrumb></app-breadcrumb>
<!-- START: CART -->
<section id="cart">
    <div class="row">
        <!-- START: PAYMENT REQUESTS LIST AND DETAILS OR EMPTY CART -->
        <ng-container *ngIf="noInformation && !paymentConfirmation && !paymentResponse" [ngSwitch]="paymentsInCart">
            <ng-container *ngSwitchCase="true">
                <div class="col-12">
                    <h6 class="pt-3">Cart</h6>
                </div>
                <!-- START: PAYMENT REQUESTS LIST -->
                <div class="col-sm-12 col-md-7">
                    <app-invoices-list (returnEmptyCart)="hidePaymentEmptyCart($event)"></app-invoices-list>
                </div>
                <!-- END: PAYMENT REQUESTS LIST -->
                <div class="col-sm-12 col-md-5">
                    <ng-container *ngIf="hasRequestWithExternalLookup else detailV1">
                        <app-payment-detail-v2 (payCart)="showTermsOfUse($event)" (payPaypal)="showTermsOfUse($event)">
                        </app-payment-detail-v2>
                    </ng-container>
                    <ng-template #detailV1>
                        <app-payment-detail
                            (payCart)="showTermsOfUse($event)"
                            (payPaypal)="showTermsOfUse($event)"
                        ></app-payment-detail>
                    </ng-template>
                </div>
                <!-- START: PAYMENT DETAILS -->
                <!-- END: PAYMENT DETAILS -->
            </ng-container>
            <!-- START: EMPTY CART -->
            <ng-container *ngSwitchCase="false">
                <div class="col-12">
                    <app-empty-cart></app-empty-cart>
                </div>
            </ng-container>
            <!-- END: EMPTY CART -->
        </ng-container>
        <!-- END: PAYMENT REQUESTS LIST AND DETAILS OR EMPTY CART -->
        <!-- START: PAYMENT RESULT FOR AUTHENTICATED CUSTOMER -->
        <ng-container *ngxPermissionsExcept="'GUEST'">
            <ng-container *ngIf="paymentConfirmation && payResult">
                <div class="col-12">
                    <app-payment-confirmation [payResult]="payResult"></app-payment-confirmation>
                </div>
            </ng-container>
        </ng-container>
        <!-- END: PAYMENT RESULT FOR AUTHENTICATED CUSTOMER -->
        <!-- START: PAYMENT RESULT FOR GUEST CUSTOMER -->
        <ng-container *ngxPermissionsOnly="'GUEST'">
            <ng-container *ngIf="paymentResponse">
                <div class="col-12">
                    <app-guest-payment-confirmation></app-guest-payment-confirmation>
                </div>
            </ng-container>
        </ng-container>
        <!-- END: PAYMENT RESULT FOR GUEST CUSTOMER -->
    </div>
</section>
<!-- END: CART -->
