import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// COMPONENTS

import { PaymentHistoryComponent } from './payment-history.component';

const routes: Routes = [
    {
        path: '',
        title: 'SprintPay | Payment history',
        data: { breadcrumb: { alias: 'payment-history' } },
        children: [
            {
                path: '',
                redirectTo: '/admin/facilityPayments/paymentHistory/paymentList',
                pathMatch: 'full',
            },
            {
                path: 'paymentDetail/:id',
                loadChildren: () => import('./payment-detail/payment-detail.module').then((m) => m.PaymentDetailModule),
            },
            {
                path: 'paymentList',
                component: PaymentHistoryComponent,
                data: { breadcrumb: { skip: true, alias: 'payment-list' } },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PaymentHistoryRoutingModule {}
