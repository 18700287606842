import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewPasswordModule } from 'src/app/pages/auth/password/new-password/new-password.module';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: NewPasswordModule,
})
export class AuthAPIService {
    private _authentication: string;

    constructor(private _httpClient: HttpClient) {
        this._authentication = environment.uris.method.authentication;
    }

    validateActivationKeyRequest(activationKey: string): Observable<boolean> {
        const url: string = `${this._authentication}/validateActivationKey?activationKey=${activationKey}`;
        return this._httpClient.post<boolean>(url, {});
    }
}
