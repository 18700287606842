import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatPaymentReference',
    standalone: true,
})
export class FormatPaymentReferencePipe implements PipeTransform {
    transform(vendorInvoiceNumber?: string, id?: string): string {
        if (vendorInvoiceNumber) {
            if (vendorInvoiceNumber.includes('VIN-')) {
                return vendorInvoiceNumber;
            } else {
                return 'VIN-' + vendorInvoiceNumber;
            }
        } else if (id) {
            id = id.toString();
            if (id.includes('R-')) {
                return id;
            } else {
                return 'R-' + id;
            }
        }

        return 'N/A';
    }
}
