import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ValidateEmailFormGroupType } from '../models/validate-email-form';

@Injectable({
    providedIn: 'root',
})
export class EmailFormService {
    private emailForm: BehaviorSubject<ValidateEmailFormGroupType> = new BehaviorSubject<ValidateEmailFormGroupType>(
        {} as ValidateEmailFormGroupType
    );
    private email: string = '';

    get instant_emailForm(): ValidateEmailFormGroupType {
        return this.emailForm.value;
    }

    setEmailForm(emailForm: ValidateEmailFormGroupType): void {
        this.emailForm.next(emailForm);
    }

    getEmailForm(): Observable<ValidateEmailFormGroupType> {
        return this.emailForm.asObservable();
    }

    markEmailAsTouched(): void {
        this.instant_emailForm?.markAllAsTouched();
    }

    setEmail(email: string): void {
        this.email = email;
    }

    getEmail(): string {
        return this.email;
    }
}
