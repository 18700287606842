import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BraintreeException, Exception } from '@cargos/sprintpay-models';
import { ErrorBraintreeHandlerService } from '@cargos/sprintpay-services';
import { deleteCard } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/credit-card/deleteCreditCard';
import { getCards } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/credit-card/getCreditCards';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-credit-card',
    templateUrl: './credit-card.component.html',
})
export class CreditCardComponent implements OnInit {
    creditCards: any[];
    noCreditCards: boolean | undefined;
    currentMonth: number;
    currentYear: number;

    constructor(
        private _ngxSpinnerService: NgxSpinnerService,
        private _errorBraintreeHandlerService: ErrorBraintreeHandlerService,
        private router: Router
    ) {
        this.creditCards = [];
        this.currentMonth = new Date().getMonth() + 1;
        this.currentYear = new Date().getFullYear();
    }

    ngOnInit(): void {
        this._loadCreditCards();
    }

    /**
     * @method _loadCreditCards()
     * @description Return the user credit cards
     */

    private _loadCreditCards(): void {
        this._ngxSpinnerService.show();
        getCards()
            .toPromise()
            .then((result: any): void => {
                this.creditCards = result.map((item: any) => {
                    return {
                        cardHolderName: item.accountingDetails.cardHolderName || 'N/A',
                        cardType: item.accountingDetails.cardType || 'N/A',
                        expirationMonth: item.accountingDetails.expirationMonth || 'N/A',
                        expirationYear: item.accountingDetails.expirationYear || 'N/A',
                        lastFourDigits: item.accountingDetails.lastFourDigits || 'N/A',
                        paymentToken: item.paymentToken || 'N/A',
                    };
                });
            })
            .finally((): void => {
                this._ngxSpinnerService.hide();
                this.creditCards.length >= 1 ? (this.noCreditCards = false) : (this.noCreditCards = true);
            });
    }

    /**
     * @method deleteCard()
     * @param (card: any)
     * @description Deletes the card using the payment token provided on the getCards item
     */

    deleteCard(card: any): void {
        Swal.fire({
            text: 'You are about to delete a credit card',
            title: 'Did you want to continue?',
            icon: 'info',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            allowOutsideClick: false,
        }).then((result): void => {
            if (result.isConfirmed) {
                deleteCard(card)
                    .toPromise()
                    .then((): void => {
                        this._loadCreditCards();
                    })
                    .catch((err: BraintreeException): void => {
                        const error: Exception = this._errorBraintreeHandlerService.handlerError(err);
                        Swal.fire({
                            title: error.title,
                            text: error.description,
                            icon: 'error',
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: 'Cancel',
                            allowOutsideClick: false,
                        });
                    });
            }
        });
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method openWarningPopup()
     * @description Shows a warning popup before adding a new credit card
     */
    openWarningPopup(): void {
        Swal.fire({
            title: 'Warning...',
            html: `<div style="margin:0 20px 40px 20px; text-align: left;"> Please only add your credit card and not your customers' credit card as you will be held liable if your customer disputes the charges.</div>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
            customClass: {
                confirmButton: 'btn__sprintpay btn__sprintpay--green',
                cancelButton: 'btn__sprintpay btn__sprintpay--gray me-4',
                closeButton: 'mat-icon-button',
                title: 'swal2-title-custom',
            },
            buttonsStyling: false,
            showCloseButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                this.router.navigate(['admin/facilityPayments/paymentMethods/newPaymentMethods/credit-card']);
            }
        });
    }
}
