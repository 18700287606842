import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { SignupRoutingModule } from './signup-routing.module';

//COMPONENTS

import { SignupComponent } from './signup.component';

//MODULE

import { CatchEventsModule } from 'src/app/directives/catch-events.module';
import { SharedModule } from '../../../shared/shared.module';
import { FormSignupModule } from './form-signup/form-signup.module';
import { FormSignupService } from './services/form-signup.service';

@NgModule({
    declarations: [SignupComponent],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SignupRoutingModule,
        FormSignupModule,
        SharedModule,
        CatchEventsModule,
    ],
    providers: [FormSignupService],
})
export class SignupModule {
    constructor() {}
}
