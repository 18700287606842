import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { calculateSprintpayCredit } from 'src/app/utils/calculateSprintpayCredit';
import { Balance } from 'src/app/utils/cartTypes';
import { CSCreditAPIService } from './requests/cs-credit-api.service';

@Injectable({
    providedIn: 'root',
})
export class CSCreditService {
    private _csCreditAmount: BehaviorSubject<Balance | null> = new BehaviorSubject<Balance | null>(null);
    private _isProcessing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private _csCreditApiService: CSCreditAPIService) {}

    get instant_cs_credit_amount(): Balance | null {
        return this._csCreditAmount.value;
    }

    setIsProcessing(value: boolean): void {
        this._isProcessing.next(value);
    }

    getIsProcessing$(): Observable<boolean> {
        return this._isProcessing.asObservable();
    }

    setCSCreditAmount(creditAmount: Balance): void {
        this._csCreditAmount.next(creditAmount);
    }

    getCSCreditAmount$(): Observable<Balance | null> {
        return this._csCreditAmount.asObservable();
    }

    getCSCreditAmountRequest$(): Observable<Balance> {
        return this._csCreditApiService.getSprintPayCredit().pipe(
            map((credit: Balance) => {
                const [creditAmount, creditPercentage] = calculateSprintpayCredit(
                    credit?.availableCredit,
                    credit?.creditLimit
                );
                this.setCSCreditAmount({ ...creditAmount, creditPercentage });
                return { ...creditAmount, creditPercentage };
            })
        );
    }
}
