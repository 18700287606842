import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DynamicFormComponent } from 'src/app/standalone-components/dynamic-form/dynamic-form.component';
import { Domains } from 'src/app/utils/constants';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { StepperSteps } from 'src/app/utils/stepperTypes';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../../../../../../services/payment.service';
import { RestService } from '../../../../../../services/rest.service';
import { CustomerService } from '../../../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../../../services/utils/payment-flux.service';
import { SessionService } from '../../../../../../services/utils/session.service';
import { customerReferenceEmotrans, customerReferenceEmotransData } from '../../../../../../utils/companies/emotrans';
import { customerReferenceGeodis, customerReferenceGeodisData } from '../../../../../../utils/companies/geodis';
import { customerReferenceKN } from '../../../../../../utils/companies/kn';
import { customerReferenceRhenus, customerReferenceRhenusData } from '../../../../../../utils/companies/rhenus';
import { customerReferenceSchenker } from '../../../../../../utils/companies/schenker';
import {
    CW1,
    MASK_CW1,
    companyName,
    customerReference,
    customerReferenceDSV,
    regex,
} from '../../../../../../utils/constants';
import { ErrorMatcher } from '../../../../../../utils/errorMatcher';

import { Subject, takeUntil } from 'rxjs';
import { CodaFluxFormsService } from 'src/app/services/coda-flux-form.service';
import { Ceva } from 'src/app/utils/companies/ceva';
import {
    customerReferenceExpeditors,
    customerReferenceExpeditorsData,
} from '../../../../../../utils/facilities/expeditors';
import { Sections } from '../../../../../../utils/sections';
import { StepperService } from '../services/stepper.service';

@Component({
    selector: 'app-standard-reference',
    templateUrl: './standard-reference.component.html',
})
export class StandardReferenceComponent implements OnInit, AfterViewChecked, OnDestroy {
    public hasExternalLookup: boolean = false;
    public referenceForm!: FormGroup;
    public currentPayment: any;
    public startingLevel: boolean;
    public glCodes: any;
    public knUser: boolean;
    public dhlUser: boolean;
    public schenkerUser: boolean;
    public geodisUser: boolean;
    public emotransUser: boolean;
    public rhenusUser: boolean;
    public sourceSystem: any;
    public cw1_mask: any;
    public labelCustomerRef: string;
    public labelNotificationEmail: string;
    public customerReferencePlaceholder: string;
    public notificationEmailPlaceholder: string;
    public tooltipCustomerRef: string;
    public tooltipCustomerRefVisible: boolean;
    public customer: any;
    public productOptions: string[];
    public matcher: ErrorMatcher;
    public dynamicFormValid: boolean;
    public customerRefRequiredErrorMessage?: string;
    public customerRefPatternErrorMessage?: string;
    public dynamic_minlength: any;
    public dynamic_maxlength: any;
    public dsvDomain: boolean;
    readonly geodisDomain: boolean;
    readonly isUserCevaDomain: boolean;
    private _dynamicObject: any;
    private _companiesArray: { company: string; turnOn: boolean }[];
    private _customerRefValidation: boolean;
    private _customerReferenceValidation: any[];
    private _dynamicFormValue: any;
    private _dynamicFieldsSection: boolean;
    private readonly _paymentsURL: string;
    private readonly _companyName: string;
    private _unsubscribe$: Subject<void>;

    @ViewChild(DynamicFormComponent) private _dynamicFormComponent?: DynamicFormComponent;

    constructor(
        private _router: Router,
        private _paymentFluxService: PaymentFluxService,
        private _formBuilder: FormBuilder,
        private _customerService: CustomerService,
        private _sessionService: SessionService,
        private _restService: RestService,
        private _stepperService: StepperService,
        private _codaFluxService: CodaFluxFormsService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _paymentService: PaymentService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this.dsvDomain = this._customerService.isUserDomain(Domains.DSV);
        this.currentPayment = this._paymentFluxService.getCurrentPayment();
        if (!this.currentPayment) {
            this._router.navigate([`/admin/facilityPayments/newPayment`]);
        }
        this.hasExternalLookup = !!this.currentPayment.facility?.externalLookup;
        this._dynamicFieldsSection = false;
        this.dynamicFormValid = false;
        this.matcher = new ErrorMatcher();
        this.startingLevel = !!this._sessionService.getElement('startingLevel');
        this.customer = this._customerService.getCustomer();
        this._companyName = this.customer?.approvalLevels?.company?.name || '';
        this._paymentsURL = environment.uris.method.payments;
        this.geodisDomain = this._customerService.isUserDomain(companyName.geodis.toLowerCase());
        this.isUserCevaDomain = this._customerService.isCevaDomain();
        this._customerRefValidation = false;
        this.dhlUser = this._companyName === companyName.dhl;
        this.knUser = this._companyName === companyName.kn;
        this.emotransUser = this._companyName === companyName.emotrans;
        this.rhenusUser = this._companyName === companyName.rhenus;
        this.schenkerUser = this._companyName === companyName.schenker;
        this.geodisUser = this._companyName === companyName.geodis;
        this._companiesArray = [
            { company: companyName.kn, turnOn: this.knUser },
            { company: companyName.schenker, turnOn: this.schenkerUser },
            { company: companyName.dhl, turnOn: this.dhlUser },
            { company: companyName.geodis, turnOn: this.geodisUser },
            { company: companyName.emotrans, turnOn: this.emotransUser },
            { company: companyName.rhenus, turnOn: this.rhenusUser },
        ];
        this._customerReferenceValidation = [];
        this.cw1_mask = null;
        this.labelCustomerRef = '';
        this.labelNotificationEmail = '';
        this.customerReferencePlaceholder = '';
        this.tooltipCustomerRef =
            'Your own personal reference # so you can remember this payment. For example your file #.';
        this.notificationEmailPlaceholder = 'Email payment notification';
        this.tooltipCustomerRefVisible = false;
        this.productOptions = ['Ocean', 'Air', 'Road'];
        this.dynamic_minlength = 0;
        this.dynamic_maxlength = 524288;
    }

    ngOnInit(): void {
        this._setFromBuilder();
        if (
            (this.startingLevel &&
                (this.schenkerUser || this.knUser || this.emotransUser || this.rhenusUser || this.geodisUser)) ||
            this.geodisDomain ||
            this.isUserCevaDomain ||
            this.dsvDomain
        ) {
            this._customerReferenceValidation = this.knUser
                ? customerReferenceKN
                : this.schenkerUser
                  ? customerReferenceSchenker
                  : this.emotransUser
                    ? customerReferenceEmotrans
                    : this.rhenusUser
                      ? customerReferenceRhenus
                      : this.geodisDomain
                        ? customerReferenceGeodis
                        : this.isUserCevaDomain
                          ? Ceva.customerReferenceCeva
                          : customerReferenceDSV;
        }
        if (this.currentPayment && this.currentPayment.reference) {
            this.referenceForm.controls['glCode'].setValue('');
            this._validateForm();
            this.customerRef.addValidators([Validators.required, CustomValidators.preventHTMLContent()]);
            if (this.customer && this.customer.hasGlCodes) {
                this.glCode ? this.glCode.disable() : '';
                if (this.currentPayment.reference.customerRef) {
                    this.getGLCodesActives(
                        this.currentPayment.reference.customerRef.substring(
                            0,
                            this.currentPayment.reference.customerRef.length > 3
                                ? 3
                                : this.currentPayment.reference.customerRef.length
                        )
                    );
                }
            }
            this.validateReferenceNumber(this.referenceForm);
        } else {
            if (this._companyName !== companyName.geodis && !this.customer.isGuest) {
                this.email.setValue(
                    this.customer && this.customer.notificationEmail
                        ? this.customer.notificationEmail
                        : this.customer.email
                );
            }
        }
        this._isStartingLevel();
        this.getFormFields();
        this.markAllFormAsTouched();
    }

    ngAfterViewChecked(): void {
        this._changeDetectorRef.detectChanges();
        if (this.hasExternalLookup) {
            this._codaFluxService.setCustomerReferenceForm(this.referenceForm);
            let standardReference = this.referenceForm.getRawValue();
            this._paymentFluxService.setData('reference', standardReference);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    markAllFormAsTouched(): void {
        this._codaFluxService
            .getCustomerReferenceAsTouched()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe({
                next: (touched: boolean) => {
                    if (touched) {
                        this.referenceForm.markAllAsTouched();
                    }
                },
            });
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.referenceForm = this._formBuilder.group({
            customerRef: new FormControl<any | null>(null, [Validators.required, Validators.maxLength(255)]),
            externalReferenceNum: new FormControl<any | null>(null, [CustomValidators.preventHTMLContent()]),
            email: new FormControl<any | null>(null, [Validators.minLength(5), Validators.pattern(regex.email)]),
            glCode: new FormControl<any | null>({ value: null, disabled: true }, []),
            notes: new FormControl<any | null>(null, [CustomValidators.preventHTMLContent()]),
        });
        if (!this.knUser) {
            this.referenceForm.controls['customerRef'].addValidators([CustomValidators.preventHTMLContent()]);
        }
        if (!this.customer.isGuest) {
            this.referenceForm.controls['email'].addValidators([Validators.required]);
        }
    }

    /**
     * @method customerRef()
     * @description: Convenience getter for easy access to form fields
     */

    get customerRef(): FormGroup {
        return this.referenceForm.get('customerRef') as FormGroup;
    }

    /**
     * @method externalReferenceNum()
     * @description: Convenience getter for easy access to form fields
     */

    get externalReferenceNum(): FormGroup {
        return this.referenceForm.get('externalReferenceNum') as FormGroup;
    }

    /**
     * @method email()
     * @description: Convenience getter for easy access to form fields
     */

    get email(): FormGroup {
        return this.referenceForm.get('email') as FormGroup;
    }

    /**
     * @method glCode()
     * @description: Convenience getter for easy access to form fields
     */

    get glCode(): FormGroup {
        return this.referenceForm.get('glCode') as FormGroup;
    }

    /**
     * @method notes()
     * @description: Convenience getter for easy access to form fields
     */

    get notes(): FormGroup {
        return this.referenceForm.get('notes') as FormGroup;
    }

    /**
     * @method _isStartingLevel()
     * @description
     */

    private _isStartingLevel(): void {
        if (this.startingLevel) {
            this._companiesArray.every((company: any) => {
                this.labelCustomerRef = Sections.customerReferenceStartingLevelLabelTags(company);
                this.customerReferencePlaceholder = Sections.customerReferenceStartingLevelPlaceholderTags(company);
                return !(this.labelCustomerRef && this.customerReferencePlaceholder);
            });
            this.labelNotificationEmail = customerReference.labelNotificationEmailStartingLevel;

            if (this.geodisUser) {
                this.labelNotificationEmail = customerReferenceGeodisData.titleNotificationEmail;
                this.notificationEmailPlaceholder = customerReferenceGeodisData.placeholderNotificationEmail;
                this.externalReferenceNum.setValidators([
                    Validators.required,
                    Validators.maxLength(30),
                    CustomValidators.preventHTMLContent(),
                ]);
            }
            if (this.emotransUser) {
                this.labelNotificationEmail = customerReferenceEmotransData.titleNotificationEmail;
                this.notificationEmailPlaceholder = customerReferenceEmotransData.placeholderNotificationEmail;
                this.externalReferenceNum.setValidators([
                    Validators.required,
                    Validators.maxLength(30),
                    CustomValidators.preventHTMLContent(),
                ]);
            }
            if (this.rhenusUser) {
                this.labelNotificationEmail = customerReferenceRhenusData.titleNotificationEmail;
                this.notificationEmailPlaceholder = customerReferenceRhenusData.placeholderNotificationEmail;
                this.externalReferenceNum.setValidators([Validators.required, CustomValidators.preventHTMLContent()]);
            }
            if (this.dhlUser) {
                this.sourceSystem = this.currentPayment.customerDHL.sourceSystem;
                this.glCode ? this.glCode.setValidators([]) : '';
                if (this.sourceSystem == 'CW1') {
                    this.cw1_mask = MASK_CW1;
                    this.customerRef.setValidators([
                        Validators.required,
                        Validators.maxLength(11),
                        Validators.pattern(CW1),
                    ]);
                    this.dynamic_maxlength = 11;
                }
            }
        } else {
            this.labelNotificationEmail = customerReference.labelNotificationEmail;
            if (this.customer.hasGlCodes) {
                this.glCode ? this.glCode.setValidators([Validators.required]) : '';
                this.labelCustomerRef = this.geodisDomain
                    ? customerReferenceGeodisData.titleCustomerReference
                    : this.isUserCevaDomain
                      ? Ceva.customerReferenceCevaData.titleCustomerReference
                      : customerReferenceExpeditorsData.titleCustomerReference;
                this.customerReferencePlaceholder = this.geodisDomain
                    ? customerReferenceGeodisData.placeholderCustomerReference
                    : this.isUserCevaDomain
                      ? Ceva.customerReferenceCevaData.placeholderCustomerReference
                      : customerReferenceExpeditorsData.placeholderCustomerReferenceGlCode;
                this.tooltipCustomerRef = this.geodisDomain
                    ? customerReferenceGeodisData.tooltip
                    : this.isUserCevaDomain
                      ? Ceva.customerReferenceCevaData.tooltip
                      : customerReferenceExpeditorsData.tooltip;
                if (!!this.customer.expeditors) {
                    this.customerReferencePlaceholder = customerReferenceExpeditorsData.placeholderCustomerReference;
                    this.customerRef.setValidators([
                        Validators.required,
                        Validators.minLength(1),
                        Validators.pattern(customerReferenceExpeditors),
                        CustomValidators.preventHTMLContent(),
                    ]);
                    this.dynamic_minlength = 1;
                }

                return;
            }

            if (this.geodisDomain) {
                this.labelCustomerRef = customerReferenceGeodisData.titleCustomerReference;
                this.customerReferencePlaceholder = customerReferenceGeodisData.placeholderCustomerReference;
                this.externalReferenceNum.setValidators([
                    Validators.required,
                    Validators.maxLength(30),
                    CustomValidators.preventHTMLContent(),
                ]);

                return;
            }

            if (this.isUserCevaDomain) {
                this.labelCustomerRef = Ceva.customerReferenceCevaData.titleCustomerReference;
                this.customerReferencePlaceholder = Ceva.customerReferenceCevaData.placeholderCustomerReference;
                this.tooltipCustomerRef = Ceva.customerReferenceCevaData.tooltip;

                return;
            }

            this.labelCustomerRef = customerReference.titleCustomerReference;
            this.customerReferencePlaceholder = customerReference.placeholderCustomerReference;
        }
    }

    /**
     * @method getGLCodesActives()
     * @param (event: string)
     * @description Validate if the string has HTML or URL
     */

    getGLCodesActives(event: string): void {
        if (this.customer.hasGlCodes && event && event.length > 0) {
            const glCode: string = event.substring(0, event.length > 3 ? 3 : event.length);
            this.glCode.enable();
            this._restService.get(this._paymentsURL + '/glCodes/search/' + glCode, {}).then((result: any): void => {
                this.glCodes = result;
            });
        } else {
            this.referenceForm.controls['glCode']?.setValue('');
            this.glCode?.disable();
            this.glCodes = null;
        }
    }

    /**
     * @method _validateForm()
     * @description
     */

    private _validateForm(): void {
        Object.keys(this.referenceForm.controls).forEach((key: string): void => {
            !this.currentPayment.reference[key]
                ? this.referenceForm.controls[key].setValue(null)
                : this.referenceForm.controls[key].setValue(this.currentPayment.reference[key]);
        });
    }

    /**
     * @method validateReferenceNumber()
     * @param (form: any)
     * @description
     */

    validateReferenceNumber(form: any): void {
        const referenceNum = this.customerRef.value;
        if (
            (this.startingLevel &&
                (this.schenkerUser || this.emotransUser || this.knUser || this.geodisUser || this.rhenusUser)) ||
            this.geodisDomain ||
            this.isUserCevaDomain ||
            this.dsvDomain
        ) {
            if (this._regExpToValidate(referenceNum, this._customerReferenceValidation)) {
                setTimeout(() => {
                    this.customerRef.markAsDirty();
                    this.customerRef.markAsTouched();
                    if (this.geodisUser || this.geodisDomain) {
                        this.customerRef.setErrors({ minlength: true });
                    }
                    if (this.isUserCevaDomain) {
                        this.customerRef.setErrors({ minlength: true });
                    } else {
                        this.customerRef.setErrors({ pattern: true });
                    }
                });
            }
        } else {
            /**Expeditors case, has glCodes and is not part of a company**/
            if (this.customer.expeditors && !this._companyName) {
                if (!!this._hasWhiteSpace(referenceNum)) {
                    this.customerRef.markAsDirty();
                    this.customerRef.setErrors({ whiteSpace: true });
                }
            }
            if (this._hasHTMLOrURlStr(referenceNum) && !this._companyName) {
                this.customerRef.markAsDirty();
                this.customerRef.setErrors({ preventedHTMLStr: true });
            }
        }
    }

    /**
     * @method _regExpToValidate()
     * @param (refNumber: string)
     * @param (regExpValidations: any[])
     * @description
     */

    private _regExpToValidate(refNumber: string, regExpValidations: any[]): boolean {
        let validation: boolean = false;
        regExpValidations.forEach((expression: any): void => {
            const validateRegExp: RegExp = new RegExp(expression.source);
            if (validateRegExp.test(refNumber)) {
                validation = true;
                return;
            }
        });
        this._customerRefValidation = !validation;
        return this._customerRefValidation;
    }

    /**
     * @method _hasWhiteSpace()
     * @param (value: string)
     * @description
     */

    private _hasWhiteSpace(value: string): boolean | null {
        return !value ? null : value.indexOf(' ') >= 0;
    }

    /**
     * @method _hasHTMLOrURlStr()
     * @param (value: string)
     * @description Validate if the string has HTML or URL
     */

    private _hasHTMLOrURlStr(value: string): boolean {
        const hasHTMLContent = regex.isHTMLContent.test(value);
        const hasURLStr = regex.isUrlStr.test(value);
        if (value) {
            const hasURLHttps: RegExpMatchArray | null = value.match(
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            );
            const hasURLHttp: RegExpMatchArray | null = value.match(/(http|https):\/\/([^.]+[\.][\S]+)/);

            return hasHTMLContent || hasURLStr || hasURLHttps || hasURLHttp;
        } else {
            return hasHTMLContent || hasURLStr;
        }
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method backStep()
     * @description Return into the customer reference for companies
     */

    backStep(): void {
        this._paymentFluxService.setData('reference', this.referenceForm.getRawValue());
        this._router.navigate(['/admin/facilityPayments/newPayment/flux/paymentDetails']);
    }

    /**
     * @method checkout()
     * @description removed the drag files
     */

    checkout(): void {
        if (this.referenceForm.invalid || !this.dynamicFormValid) {
            this.referenceForm.markAllAsTouched();
            if (this._dynamicFormComponent?.paymentForm) {
                this._dynamicFormComponent?.paymentForm.markAllAsTouched();
            }
        } else {
            let standardReference = this.referenceForm.getRawValue();
            if (this._dynamicFieldsSection) {
                const dynamicValues = this._paymentFluxService.getDynamicValues();
                Object.keys(this._dynamicFormValue).forEach((key: any): void => {
                    standardReference[key] = this._dynamicFormValue[key];
                });
                this._paymentFluxService.setDynamicValues(Object.assign({}, dynamicValues, this._dynamicObject));
            }
            if (!this.hasExternalLookup) {
                const step = this._companyName ? StepperSteps.STEP_SECOND : StepperSteps.STEP_FIRST;
                this._stepperService[step].next(true);
                const redirect: string = this.currentPayment.redirectRoute
                    ? this.currentPayment.redirectRoute
                    : '/admin/facilityPayments/newPayment/flux/summary';
                this._router.navigate([redirect]);
            }
            this._paymentFluxService.setData('reference', standardReference);
        }
    }

    dynamicValues(form: any): void {
        if (form.status === 'VALID' || form.status === 'DISABLED') {
            this._dynamicFormValue = form.value;
            this.dynamicFormValid = true;
            this._dynamicObject = form.dynamic;
            const dynamicValues = this._paymentFluxService.getDynamicValues();
            this._paymentFluxService.setDynamicValues(Object.assign({}, dynamicValues, this._dynamicObject));
        } else if (form.status === 'INVALID' || form.status === 'PENDING') {
            this.dynamicFormValid = false;
        }
    }

    hasDynamicFields(fields: number): void {
        this._dynamicFieldsSection = fields > 0 ? true : false;
        this.dynamicFormValid = !this._dynamicFieldsSection;
    }

    /**
     * @method getFormFields()
     * @description Review if dynamic fields exits and if they do, we review if we have any isReplaced field
     */

    getFormFields(): void {
        if (this._customerService.getCustomer()?.approvalLevels?.company || '' !== '') {
            this._paymentService.getFormFields('2').then((result: any): void => {
                let dynamicLengthIsReplacement: number = 0;

                result.map((field: any) => {
                    const validations = field.validations
                        .filter((validation: any): boolean => validation.name !== 'tooltip')
                        .reduce((acc: any, item: any) => {
                            acc[item.name] = {
                                value: item.value === 'true' ? true : item.value,
                                errorMessage: item.message,
                            };
                            return acc;
                        }, {});
                    field['type'] = 'text';
                    field['disabled'] = field.isDisabled;
                    field['tooltip'] = field.validations.find((item: any): boolean => item.name === 'tooltip');
                    field['validations'] = validations;
                    return field;
                });

                result.forEach((field: any): void => {
                    if (field.isReplacement === true) {
                        if (field.name === 'customerRef') {
                            dynamicLengthIsReplacement = dynamicLengthIsReplacement + 1;
                            this.labelCustomerRef = field.label;
                            this.customerReferencePlaceholder = field.label;
                            this.customerRefRequiredErrorMessage = field.validations.required?.errorMessage;
                            this.customerRefPatternErrorMessage = field.validations.pattern?.errorMessage;
                            field.tooltip?.message ? (this.tooltipCustomerRef = field.tooltip.message) : null;
                            field.validations ? this._setDynamicValidations(field) : null;
                        }
                    }
                });
            });
        }
    }

    /**
     * @method setDynamicValidations()
     * @param (field: any)
     * @description Review if dynamic fields exits and if they do, we review if we have any isReplaced field
     */

    private _setDynamicValidations(field: any): void {
        this.referenceForm.get(field.name)!.setValidators(null);
        this.referenceForm.get(field.name)!.setErrors(null);

        if (field.validations.required) {
            if (field.validations.required.value === true) {
                this.referenceForm.get(field.name)!.addValidators(Validators.required);
            }
        }

        this.dynamic_minlength = field.validations.minlength ? field.validations.minlength.value : 0;
        this.dynamic_maxlength = field.validations.maxlength ? field.validations.maxlength.value : 524288;

        field.validations.minlength
            ? this.referenceForm.get(field.name)!.addValidators(Validators.minLength(field.validations.minlength.value))
            : null;
        field.validations.maxlength
            ? this.referenceForm.get(field.name)!.addValidators(Validators.maxLength(field.validations.maxlength.value))
            : null;
        field.validations.pattern
            ? this.referenceForm.get(field.name)!.addValidators(Validators.pattern(field.validations.pattern.value))
            : null;

        this.referenceForm.get(field.name)!.updateValueAndValidity();
    }

    /**
     * @method change()
     * @param (change: any)
     * @description Save all the changes in object
     */

    change(form: FormGroup): void {
        this._paymentFluxService.setData('reference', form.getRawValue());
        this._paymentFluxService.setCustomerReference(this.customerRef?.value || '');
    }
}
