import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    standalone: true,
    selector: 'app-awb-fee',
    templateUrl: './awb-fee.component.html',
    styleUrls: ['./awb-fee.component.scss'],
    imports: [CommonModule, MatIconModule, MatTooltipModule],
})
export class AwbFeeComponent {
    @Input() feeLabel: string = '';
    @Input() feeValue!: number;
    @Input() labelBold = false;
    @Input() valueBold = false;
}
