import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, filter, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HomeService {
    private _hasFacilities: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
    private _hasPendingApproval: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
    private _hasInvoices: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
    private _hasRejected: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
    private _hasWallet: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);

    constructor() {}

    setWallet(hasWallet: boolean): void {
        this._hasWallet.next(hasWallet);
    }

    getWallet(): Observable<boolean | null> {
        return this._hasWallet.asObservable();
    }

    setFacilities(hasFacilities: boolean): void {
        this._hasFacilities.next(hasFacilities);
    }

    getFacilities(): Observable<boolean | null> {
        return this._hasFacilities.asObservable();
    }

    setPendingApproval(hasPendingApproval: boolean): void {
        this._hasPendingApproval.next(hasPendingApproval);
    }

    getPendingApproval(): Observable<boolean | null> {
        return this._hasPendingApproval.asObservable();
    }

    setInvoices(hasInvoices: boolean): void {
        this._hasInvoices.next(hasInvoices);
    }

    getInvoices(): Observable<boolean | null> {
        return this._hasInvoices.asObservable();
    }

    setRejected(hasRejected: boolean): void {
        this._hasRejected.next(hasRejected);
    }

    getRejected(): Observable<boolean | null> {
        return this._hasRejected.asObservable();
    }

    userIsEmpty(): Observable<boolean> {
        return combineLatest([
            this.getWallet(),
            this.getFacilities(),
            this.getPendingApproval(),
            this.getInvoices(),
            this.getRejected(),
        ]).pipe(
            filter((response) => response.every((value) => typeof value == 'boolean')),
            map((response) => response.every((value) => value === true)),
            distinctUntilChanged()
        );
    }
}
