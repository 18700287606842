import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControlStatus,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Subject, takeUntil } from 'rxjs';
import { PipesSharedModule } from 'src/app/pipes/pipes.module';
import { TermOfUseFormGroupType } from './models/term-of-use-form';
@Component({
    selector: 'app-term-of-use',
    standalone: true,
    templateUrl: './term-of-use.component.html',
    imports: [CommonModule, FormsModule, MatFormFieldModule, ReactiveFormsModule, PipesSharedModule, MatCheckboxModule],
})
export class TermOfUseComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void>;
    public termsOfUseForm!: TermOfUseFormGroupType;

    @Input() showError = false;
    @Input() set resetValue(reset: boolean) {
        if (reset) {
            this.createForm();
        }
    }
    @Output() eventOnTermsOfUse = new EventEmitter<boolean>();

    get termsOfUse(): AbstractControl | null {
        return this.termsOfUseForm.get('termsOfUse');
    }

    constructor(private fb: FormBuilder) {
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this.createForm();
        this.subscribeFormChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createForm(): void {
        this.termsOfUseForm = this.fb.group({
            termsOfUse: ['', Validators.required],
        });
    }

    subscribeFormChanges(): void {
        this.termsOfUseForm.statusChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((statusChanges: FormControlStatus) => {
                this.eventOnTermsOfUse.emit(statusChanges === 'VALID');
            });
    }
}
