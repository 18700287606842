import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { permissionsSection } from '../../../../utils/constants';

// COMPONENTS

import { OpenComponent } from './open.component';

const routes: Routes = [
    {
        path: '',
        title: 'SprintPay | Open invoices',
        component: OpenComponent,
        canLoad: [NgxPermissionsGuard],
        data: {
            breadcrumb: { alias: 'open' },
            permissions: {
                only: permissionsSection.invoices,
                redirectTo: 'admin/home',
            },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class OpenRoutingModule {}
