<!--START: PAYMENT STATUS -->
<section id="payment_status">
    <div id="payment_status__container" class="container-fluid">
        <div class="mb-4 py-2">
            <!--START: PAYMENT TYPE STARTED, APPROVED -->
            <ng-container
                *ngIf="paymentDetail.approvalStatus === 'STARTED' || paymentDetail.approvalStatus === 'APPROVED' || paymentDetail.approvalStatus === 'PENDING'"
            >
                <div class="row">
                    <div class="col-12">
                        <div class="p-2">
                            <span id="payment_status__container--started" class="d-block p-3"
                                ><mat-icon class="material-symbols-outlined align-middle me-2">check_circle</mat-icon
                                ><strong
                                    >{{paymentDetail.createdDate | date: "longDate"}}, {{paymentDetail.createdDate |
                                    date: "mediumTime"}}</strong
                                ></span
                            >
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--END: PAYMENT TYPE STARTED, APPROVED -->
            <!--START: PAYMENT TYPE CANCELLED -->
            <ng-container *ngIf="paymentDetail.approvalStatus === 'CANCELLED'">
                <div class="row">
                    <div class="col-12 col-lg-9">
                        <div class="px-4 py-2 mt-2">
                            <span id="payment_status__container--cancelled" class="d-block p-3"
                                ><mat-icon class="material-symbols-outlined align-middle me-2">warning</mat-icon> Your
                                request has been voided:&nbsp;<strong
                                    >{{paymentDetail.lastModifiedDate | date: "longDate"}},
                                    {{paymentDetail.lastModifiedDate | date: "mediumTime"}}</strong
                                ></span
                            >
                            <span id="payment_status__container--info" class="d-block mt-4 mb-2 p-3">
                                <span class="d-block mb-1"><strong>Voided by:</strong></span>
                                <span class="d-block mb-1">{{paymentDetail?.name}}</span>
                                <span class="d-block mb-1"><strong>Voided reason:</strong></span>
                                <span class="d-block">{{paymentDetail?.approvalReason}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <img
                            class="mx-auto d-block h-100 w-75 py-4 py-lg-0"
                            src="assets/images/payments/rejected-payments.svg"
                            alt="Rejected payment illustration"
                        />
                    </div>
                </div>
            </ng-container>
            <!--END: PAYMENT TYPE CANCELLED -->
            <!--START: PAYMENT TYPE REJECTED -->
            <ng-container *ngIf="paymentDetail.approvalStatus === 'REJECTED'">
                <div class="row">
                    <div class="col-12 col-lg-9">
                        <div class="px-4 py-2 mt-2">
                            <span id="payment_status__container--rejected" class="d-block p-3"
                                ><mat-icon class="material-symbols-outlined align-middle me-2">warning</mat-icon> Your
                                request has been rejected:&nbsp;<strong
                                    >{{paymentDetail.lastModifiedDate | date: "longDate"}},
                                    {{paymentDetail.lastModifiedDate | date: "mediumTime"}}</strong
                                ></span
                            >
                            <span id="payment_status__container--info" class="d-block mt-4 mb-2 p-3">
                                <span class="d-block mb-1"><strong>Rejected by:</strong></span>
                                <span class="d-block mb-1">{{paymentDetail.customer.name}}</span>
                                <span class="d-block mb-1"><strong>Rejection reason:</strong></span>
                                <span class="d-block">{{paymentDetail.approvalReason}}</span>
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-lg-3">
                        <img
                            class="mx-auto d-block h-100 w-75 py-4 py-lg-0"
                            src="assets/images/payments/rejected-payments.svg"
                            alt="Rejected payment illustration"
                        />
                    </div>
                </div>
            </ng-container>
            <!--END: PAYMENT TYPE REJECTED -->
        </div>
    </div>
</section>
<!--END: PAYMENT STATUS -->
