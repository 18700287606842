import { Component } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
})
export class SettingsComponent {
    constructor(private _breadcrumbService: BreadcrumbService) {
        this._breadcrumbService.set('@settings', 'Settings');
    }
}
