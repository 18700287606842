import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, isObservable, map, of, take } from 'rxjs';
import { regex } from './constants';

export class CustomValidators {
    constructor() {}

    /**
     * @description Validator to detect if the value of an input includes html or url tags
     * @returns Returns a map of validation errors if present, otherwise null.
     */
    static preventHTMLContent(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) {
                return null;
            }
            let contentNotValid =
                this.isEmail(value) ||
                this.hasHTMLOrURlStr(value) ||
                value.match(
                    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
                ) ||
                value.match(/(http|https):\/\/([^.]+[\.][\S]+)/);

            if (this.isEmail(value)) {
                contentNotValid = false;
            }
            return !!contentNotValid ? { preventedHTMLStr: true } : null;
        };
    }

    /**
     *
     * @param value
     * @description Function that allows typing an email
     * @returns Return true o false
     */
    static isEmail(value: string): boolean {
        const emailRegex = regex.email;
        return emailRegex.test(value);
    }

    /**
     *
     * @param value
     * @description Function that looks for html tags or urls within a string
     * @returns Return true o false
     */
    static hasHTMLOrURlStr(value: string): boolean {
        const hasHTMLContent = regex.isHTMLContent.test(value.toString());
        const hasURLStr = regex.isUrlStr.test(value.toString());
        return hasHTMLContent || hasURLStr;
    }

    /**
     *
     * @param pattern
     * @description Function that validates a regex expression
     */
    static regex(pattern: RegExp): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value) {
                return control.value.match(pattern) ? null : { regex: true };
            }

            return null;
        };
    }

    /**
     *
     * @param asyncPattern
     * @description Function that validates a regex expression and returns an asynchronous validator
     */
    static asyncRegex(asyncPattern: RegExp | Observable<RegExp>): AsyncValidatorFn {
        return (control: AbstractControl) => {
            if (control.value) {
                asyncPattern = isObservable(asyncPattern) ? asyncPattern : of(asyncPattern);
                return asyncPattern.pipe(
                    take(1),
                    map((pattern) => (control.value.match(pattern) ? null : { regex: true }))
                );
            }
            return of(null);
        };
    }

    /**
     *
     * @param awb
     * @description Function that validates if an awb is correctly built
     */
    static awbValidator(awb?: string): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value && control.value.match(new RegExp(awb || ''))) {
                const testDigit = Number(control.value.substring(control.value.length - 1));
                let awbString: string;
                if (control.value.length >= 11) {
                    awbString = control.value.substring(4, control.value.length - 1);
                } else {
                    awbString = control.value.substring(0, control.value.length - 1);
                }
                const checkDigit = Number(awbString) % 7;
                const returnValue = checkDigit === testDigit;
                return !returnValue ? { invalid: true } : null;
            }
            return null;
        };
    }

    /**
     *
     * @param amount
     * @description Function that validates if an awb is correctly built
     */
    static higherValueThanAmount(amount?: number): ValidatorFn {
        return (control: AbstractControl) => {
            const inValidAmount =
                control.value &&
                Number(String(control.value).replace('$', '')) > Number(String(amount).replace('$', ''));

            return inValidAmount ? { invalid: true } : null;
        };
    }
}
