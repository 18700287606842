<!--START: ABW -->
<form *ngIf="currentPayment.facility" class="row" [formGroup]="paymentForm">
    <!--START: FOR OCEAN CARRIER, CFE AND FORWARDERS-->
    <ng-template [ngIf]="!standardFacility">
        <div class="col-12">
            <div class="form-group">
                <mat-form-field class="d-block" appearance="outline">
                    <mat-label>{{currentPayment.facility.awbFieldLabel}}</mat-label>
                    <input
                        matInput
                        type="text"
                        id="awb"
                        name="awb"
                        formControlName="awb"
                        autocomplete="off"
                        (blur)="validateFreeText()"
                        (focus)="resetValidation()"
                        placeholder="Enter {{currentPayment.facility.awbFieldLabel}}"
                        [errorStateMatcher]="matcher"
                    />
                    <mat-error *ngIf="paymentForm.controls['awb'].hasError('required')">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                    </mat-error>
                    <mat-error *ngIf="paymentForm.controls['awb'].hasError('minlength')">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Minimum of 3 digits
                    </mat-error>
                    <mat-error *ngIf="paymentForm.controls['awb'].hasError('preventedHTMLStr')">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>HTML tags or URLs are not allowed
                    </mat-error>
                    <mat-error *ngIf="paymentForm.controls['awb'].hasError('maxlength')">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Maximum of 225 characters
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </ng-template>
    <!--END: FOR OCEAN CARRIER, CFE AND FORWARDERS-->
    <!--START: MAWB -->
    <ng-template [ngIf]="standardFacility">
        <!--START: PREFIX -->
        <div class="col-12 col-lg-3">
            <div class="form-group">
                <mat-form-field class="d-block" appearance="outline">
                    <mat-label>Prefix</mat-label>
                    <input
                        matInput
                        type="text"
                        id="prefix"
                        [matAutocomplete]="prefix"
                        name="listPrefix"
                        maxlength="3"
                        formControlName="prefix"
                        autocomplete="off"
                        placeholder="Enter Prefix"
                        (input)="prefixKeypress($event)"
                        [errorStateMatcher]="matcher"
                        [appDisableControl]="prefixes.length === 1"
                        (paste)="paste($event)"
                    />
                    <mat-autocomplete #prefix="matAutocomplete">
                        <mat-option *ngFor="let option of prefixes" [value]="option"> {{option}} </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="paymentForm.controls['prefix'].hasError('required')">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                    </mat-error>
                    <mat-error *ngIf="paymentForm.controls['prefix'].hasError('pattern')">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>We only allow numbers
                    </mat-error>
                    <mat-error *ngIf="paymentForm.controls['prefix'].hasError('minlength')">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Minimum of 3 digits
                    </mat-error>
                    <mat-error *ngIf="paymentForm.controls['prefix'].hasError('invalid')">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Prefix invalid
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <!--END: PREFIX -->
        <!--START: AWB NUMBER -->
        <div class="col-12 col-lg-9">
            <div class="form-group">
                <mat-form-field class="d-block" appearance="outline">
                    <mat-label>{{currentPayment.facility.awbFieldLabel}}</mat-label>
                    <input
                        matInput
                        type="text"
                        id="number"
                        mask="00000000"
                        maxlength="8"
                        name="number"
                        formControlName="number"
                        autocomplete="off"
                        placeholder="Enter {{currentPayment.facility.awbFieldLabel}}"
                        (paste)="paste($event)"
                        (keyup)="awbKeypress()"
                        [errorStateMatcher]="matcher"
                        [appDisableControl]="!!prefixes.length && paymentForm.controls['prefix'].invalid"
                    />
                    <mat-icon matSuffix
                        ><mat-spinner *ngIf="loadingSpinner" color="primary" diameter="25"> </mat-spinner
                    ></mat-icon>
                    <mat-hint *ngIf="loadingSpinner"> Searching AWB, please wait.</mat-hint>
                    <mat-error *ngIf="paymentForm.controls['number'].hasError('required')">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                    </mat-error>
                    <mat-error *ngIf="paymentForm.controls['number'].hasError('pattern')">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>We only allow numbers
                    </mat-error>
                    <mat-error *ngIf="paymentForm.controls['number'].hasError('minlength')">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Minimum of 8 digits
                    </mat-error>
                    <mat-error *ngIf="paymentForm.controls['number'].hasError('invalid')">
                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Invalid awb
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <!--END: AWB NUMBER -->
    </ng-template>
    <div class="col-12 col-lg-9 offset-lg-3">
        <ng-container *ngIf="tooltipCustomerRefVisible">
            <span class="cs__tooltip cs__tooltip--warning">
                <strong class="d-inline-block mb-3">Possible Duplicate Payment!</strong>
                <span class="d-block mb-2"
                    >A payment with {{awbLabel}}&nbsp;<strong>{{paymentForm.controls['awb'].value}}</strong> was
                    previously submitted.<br />In order to enable and continue with the payment request you need to
                    agree with the duplicate.<br />Do you wish to proceed with this payment anyway?. If you agree we
                    will remove this tooltip and you will be able to continue with the payment</span
                >
                <button
                    mat-button
                    type="button"
                    class="d-block text-uppercase btn__sprintpay btn__sprintpay--linkBlack p-0 m-0"
                    (click)="closeTooltip()"
                >
                    I agree
                </button>
            </span>
        </ng-container>
    </div>
    <!--END: MAWB -->
</form>
<!--END: For ABW -->
