import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { TokenService } from '../utils/token.service';

@Injectable({ providedIn: 'root' })
export class UploadFileAPIService {
    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenService
    ) {}

    /**
     * @method postFileWithProgress()
     * @param (endpoint: string)
     * @param (formData: FormData)
     * @returns A observable with the data from the API call
     */
    postFileWithProgress(url: string, formData: FormData): Observable<any> {
        const headers: HttpHeaders = new HttpHeaders().set(
            'Authorization',
            `Bearer ${this._tokenService.getCurrentUser()}`
        );
        const observe = 'events';
        const responseType = 'text';
        const reportProgress = true;
        return this._httpClient.post(url, formData, {
            headers,
            observe,
            responseType,
            reportProgress,
        });
    }

    /**
     * @method getTemplate()
     * @param (endpoint: string)
     * @returns A observable with the data from the API call
     */
    getTemplate(url: string): Observable<Blob> {
        const headers: HttpHeaders = new HttpHeaders()
            .set('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`)
            .set('Content-type', 'application/pdf')
            .set('Accept', 'application/pdf');
        const responseType = 'blob';
        return this._httpClient
            .get(url, {
                headers,
                responseType,
            })
            .pipe(
                catchError(() =>
                    throwError(() => ({
                        error: { errors: ['An error occurred while processing your request, please try again'] },
                    }))
                )
            );
    }
}
