import { AbstractControl, ValidatorFn } from '@angular/forms';

export class MatchPassword {
    constructor() {}

    /**
     * @method match()
     * @param (controlName: string)
     * @param (checkControlName: string)
     * @description Validate if the password and confirm password are the same
     * @return Return true or false
     */

    static match(controlName: string, checkControlName: string): ValidatorFn {
        return (controls: AbstractControl) => {
            const checkControl = controls.get(checkControlName);
            if (checkControl?.errors && !checkControl.errors['matching']) {
                return null;
            }
            if (controls.get(controlName)?.value !== checkControl?.value) {
                controls.get(checkControlName)?.setErrors({ matching: true });
                return { matching: true };
            } else {
                controls.get(checkControlName)?.setErrors(null);
                return null;
            }
        };
    }
}
