import { Injectable } from '@angular/core';
import { Customer } from '@cargos/sprintpay-models';
import { Observable, of, switchMap } from 'rxjs';
import { NotificationsType } from '../models/notifications/notifications';
import { profileComponents } from '../utils/constants';
import { CustomerAPIService } from './requests/customer-api.service';
import { CustomerService } from './utils/customer-handler.service';
import { SecurityService } from './utils/security.service';

@Injectable({
    providedIn: 'root',
})
export class CustomerFeaturesService {
    constructor(
        private _customerAPIService: CustomerAPIService,
        private _customerService: CustomerService,
        private _securityService: SecurityService
    ) {}

    getCustomerNotifications(): Observable<NotificationsType[]> {
        return of(this._customerService.isAllowedDisplayNotifications()).pipe(
            switchMap((isAllowed) => {
                if (!isAllowed) {
                    return of([]);
                }

                return this._customerAPIService.getCustomerNotificationsRequest();
            })
        );
    }

    isCustomerOnHold(): boolean {
        const customer: Customer = this._customerService.getCustomer();

        return !!(
            this._securityService.verifyComponentsSecurity(profileComponents.homeCreditHold) || customer.isCreditHold
        );
    }
}
