<!-- START: PAYMENT METHOD TEMPLATES GUEST -->
<div class="mx-2 mb-2">
    <div class="d-flex mx-2 mb-1 flex-column flex-lg-row justify-content-between align-items-center">
        <!-- START: CREDIT CARD TEMPLATE -->
        <div *ngIf="creditCard">
            <mat-radio-button
                class="d-inline-block guest-payment-method-card"
                color="primary"
                [checked]="paymentMethod?.method === paymentMethods.CREDIT_CARD"
                (change)="selectCreditCard()"
                [disabled]="loadingPayPalRequest"
            >
                <span class="payment-detail--method d-block">
                    <img
                        *ngIf="creditCard.details?.cardType === 'Visa'"
                        src="assets/images/credit-card-brand/types/visa.svg"
                        alt="Visa logo"
                        class="me-3"
                    />
                    <img
                        *ngIf="creditCard.details?.cardType === 'MasterCard'"
                        src="assets/images/credit-card-brand/types/mastercard.svg"
                        alt="Mastercard logo"
                        class="me-3"
                    />
                    <img
                        *ngIf="creditCard.details?.cardType === 'Discover'"
                        src="assets/images/credit-card-brand/types/discover.svg"
                        alt="Discover logo"
                        class="me-3"
                    />
                    <img
                        *ngIf="creditCard.details?.cardType === 'American Express'"
                        src="assets/images/credit-card-brand/types/american-express.svg"
                        alt="Amex logo"
                        class="me-3"
                    />
                    <img
                        *ngIf="creditCard.details?.cardType === 'JCB'"
                        src="assets/images/credit-card-brand/types/jcb.svg"
                        alt="JCB logo"
                        class="me-3"
                    />
                    Ending ···
                    <strong class="fw-bold">{{creditCard.details?.lastFour}}</strong>
                </span>
            </mat-radio-button>
        </div>
        <!-- END: CREDIT CARD TEMPLATE -->
        <!-- START: NO CREDIT CARD TEMPLATE -->
        <div *ngIf="!creditCard">
            <i class="bi bi-exclamation-circle-fill align-items-center me-2 d-inline-block"></i>
            <button
                mat-button
                type="button"
                class="btn__sprintpay btn__sprintpay--link payment-detail--guestCardChange"
                routerLink="newPaymentMethods/credit-card"
                (click)="selectCreditCard()"
                [disabled]="loadingPayPalRequest"
            >
                Add new credit card
            </button>
        </div>
        <!-- END: NO CREDIT CARD TEMPLATE -->
        <button
            *ngIf="creditCard"
            mat-button
            type="button"
            class="btn__sprintpay btn__sprintpay--link payment-detail--guestCardChange text-uppercase"
            routerLink="newPaymentMethods/credit-card"
        >
            Edit
        </button>
    </div>
    <!-- START: PAYPAL TEMPLATE -->
    <div class="mx-2">
        <mat-radio-button
            class="d-inline-block"
            color="primary"
            [checked]="paymentMethod?.method === paymentMethods.PAYPAL"
            (click)="selectPayPal()"
        >
            <span class="payment-detail--method d-block">Use PayPal</span>
        </mat-radio-button>
    </div>
    <!-- END: PAYPAL TEMPLATE -->
</div>
<!-- END: PAYMENT METHOD TEMPLATES GUEST -->
