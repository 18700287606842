<app-breadcrumb></app-breadcrumb>
<app-no-information
    *ngIf="noInformation"
    [paymentType]="'voided'"
    [error_message]="noInformationMessage"
></app-no-information>
<section id="facility_contacts" *ngIf="!noInformation">
    <div id="facility_contacts__header" class="mb-3 pt-3">
        <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
                <h6>Facility Details: &nbsp;{{facilityDetail?.name}}</h6>
            </div>
        </div>
    </div>
    <div id="facility_contacts__container" class="container-fluid">
        <div class="container-fluid pt-4 px-5" *ngIf="showPayFacility">
            <div class="row">
                <div class="col-sm-12 col-md-6 w-100">
                    <div class="d-flex justify-content-end">
                        <button
                            mat-flat-button
                            color="primary"
                            (click)="selectFacility()"
                            type="button"
                            class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                        >
                            PAY THIS FACILITY
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-4 py-2">
            <div class="col-sm-12">
                <div class="p-4">
                    <!-- START: DETAIL CONTAINER -->
                    <!--START: Facility genral Information -->
                    <div class="row mb-4">
                        <div class="detail__sections mb-4">
                            <div class="col-12">
                                <div class="d-flex justify-content-between">
                                    <span class="my-3"><strong>Facility General Information</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>Facility Name</small>
                            </p>
                            <p>{{ facilityDetail?.name | notAvailable }}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>Address</small>
                            </p>
                            <p>
                                {{facilityDetail?.address }} {{facilityDetail?.city }} {{facilityDetail?.state}}
                                {{facilityDetail?.zip }}
                            </p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>Cargo Recovery Address</small>
                            </p>
                            <p>{{facilityDetail?.pickupAddress | notAvailable }}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>Phone Number</small>
                            </p>
                            <p>{{facilityDetail?.phone | notAvailable }}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>Alt Phone</small>
                            </p>
                            <p>{{facilityDetail?.alternatePhone | notAvailable }}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>Email</small>
                            </p>
                            <p>{{facilityDetail?.facilityNotificationEmail | notAvailable }}</p>
                        </div>
                    </div>
                    <!--END: Facility genral Information -->

                    <!--START: Facility Details -->
                    <div class="row mb-4">
                        <div class="detail__sections mb-4">
                            <div class="col-12">
                                <div class="d-flex justify-content-between">
                                    <span class="my-3"><strong>Facility Details</strong></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>AirPort Code</small>
                            </p>
                            <p>{{facilityDetail?.airportCode | notAvailable }}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>Firms Code</small>
                            </p>
                            <p>{{facilityDetail?.firmsCode | notAvailable }}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>Airline Prefix</small>
                            </p>
                            <p>{{facilityDetail?.airlinePrefix | notAvailable }}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>Carrier Code</small>
                            </p>
                            <p>{{facilityDetail?.carrierCode | notAvailable }}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>Delivery Type</small>
                            </p>
                            <p>{{facilityDetail?.deliveryType | notAvailable }}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>Prefixes</small>
                            </p>
                            <p>{{facilityDetail?.prefixes | notAvailable }}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="card-subtitle me-2">
                                <small>Handling Fee</small>
                            </p>
                            <p>{{facilityDetail?.handlingFee | notAvailable }}</p>
                        </div>
                    </div>
                    <!-- END: DETAIL CONTAINER -->
                </div>
            </div>
        </div>
    </div>
</section>
