import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyReferenceComponent } from '../payment-flux/company-customer-reference/company-reference/company-reference.component';
import { EmotransReferenceComponent } from '../payment-flux/company-customer-reference/emotrans-reference/emotrans-reference.component';
import { GeodisReferenceComponent } from '../payment-flux/company-customer-reference/geodis-reference/geodis-reference.component';
import { KnReferenceComponent } from '../payment-flux/company-customer-reference/kn-reference/kn-reference.component';
import { RhenusReferenceComponent } from '../payment-flux/company-customer-reference/rhenus-reference/rhenus-reference.component';
import { SchenkerReferenceComponent } from '../payment-flux/company-customer-reference/schenker-reference/schenker-reference.component';
import { CodaFacilityComponent } from './coda-facility.component';
import { PaymentDetailComponent } from './payment-detail/payment-detail.component';
import { PaymentSummaryComponent } from './payment-summary/payment-summary.component';
const routes: Routes = [
    {
        path: '',
        component: CodaFacilityComponent,
        children: [
            { path: 'knReference', component: KnReferenceComponent },
            { path: 'schenkerReference', component: SchenkerReferenceComponent },
            { path: 'geodisReference', component: GeodisReferenceComponent },
            { path: 'emotransReference', component: EmotransReferenceComponent },
            { path: 'rhenusReference', component: RhenusReferenceComponent },
            { path: ':company-name/Reference', component: CompanyReferenceComponent },
            { path: 'paymentDetails', component: PaymentDetailComponent },
            { path: 'summary', component: PaymentSummaryComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CodafacilityRoutingModule {}
