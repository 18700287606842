<!--START: CONTAINER WALLET -->
<section id="card-wallet">
    <div class="row">
        <ng-container *ngxPermissionsOnly="csCardOnHoldSecurity">
            <app-credit-card-hold class="col-12 mb-3 pb-2"></app-credit-card-hold>
        </ng-container>

        <ng-container *ngxPermissionsOnly="csCreditSecurity">
            <app-sp-credit-widget class="col-12 col-md-8 mb-3 pb-2"></app-sp-credit-widget>
        </ng-container>
    </div>
</section>
<!--END: CONTAINER INVOICE-->
