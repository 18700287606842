import { Injectable, OnDestroy } from '@angular/core';
import { CredentialsTokensType } from '@cargos/sprintpay-models';
import { TokenService } from '@cargos/sprintpay-services';
import { BehaviorSubject, Observable, filter, from, fromEvent, map, of, take } from 'rxjs';
import { SessionWorkerMessage, SessionWorkerMessageTypes } from './session.worker';

@Injectable({
    providedIn: 'root',
})
export class SessionWorkerService implements OnDestroy {
    private sharedWorker!: SharedWorker;
    private isInitialized: boolean = false;
    public updateTokens$: BehaviorSubject<CredentialsTokensType | null>;
    public sessionsClosed$: BehaviorSubject<boolean>;

    constructor(private tokenService: TokenService) {
        this.updateTokens$ = new BehaviorSubject<CredentialsTokensType | null>(null);
        this.sessionsClosed$ = new BehaviorSubject<boolean>(false);
        if (window.SharedWorker) {
            this.sharedWorker = new SharedWorker(new URL('./session.worker', import.meta.url));
            this.sharedWorker.port.onmessage = this.handleMessage;
            this.subscribeInitializeSession();
            this.subscribePageHide();
        }
    }

    ngOnDestroy(): void {
        this.updateTokens$?.complete();
    }

    private handleMessage = (event: MessageEvent<SessionWorkerMessage>): void => {
        switch (event.data.type) {
            case SessionWorkerMessageTypes.UPDATE_TOKENS:
                const tokens = event.data?.tokens ?? null;
                this.updateTokens$?.next(tokens);
                break;
            case SessionWorkerMessageTypes.CLOSE_SESSIONS:
                this.sessionsClosed$?.next(true);
                break;
        }
    };

    private subscribeInitializeSession(): void {
        this.tokenService
            .getRefreshToken$()
            .pipe(
                take(1),
                filter((tokens) => !!tokens),
                map(() => {
                    this.isInitialized = true;
                })
            )
            .subscribe();
    }

    private subscribePageHide(): void {
        fromEvent(window, 'pagehide')
            .pipe(
                take(1),
                map(() => {
                    this.postMessage({
                        type: SessionWorkerMessageTypes.CLOSE_CONNECTION,
                    });
                })
            )
            .subscribe();
    }

    private postMessage(message: SessionWorkerMessage): void {
        this.sharedWorker.port.postMessage(message);
    }

    private postPromisifiedMessage(message: SessionWorkerMessage): Promise<SessionWorkerMessage> {
        return new Promise((resolve) => {
            const channel = new MessageChannel();
            channel.port1.onmessage = ({ data }) => {
                channel.port1.close();
                resolve(data);
            };

            this.sharedWorker.port.postMessage(message, [channel.port2]);
        });
    }

    public requestTokens(): Observable<SessionWorkerMessage> {
        if (!this.sharedWorker) {
            return of({
                type: SessionWorkerMessageTypes.REQUEST_TOKENS,
                tokens: null,
                isRequestAllowed: true,
            });
        }

        return from(
            this.postPromisifiedMessage({
                type: SessionWorkerMessageTypes.REQUEST_TOKENS,
                isInitialized: this.isInitialized,
            })
        );
    }

    public isTokenRefreshAllowed(): Observable<SessionWorkerMessage> {
        if (!this.sharedWorker) {
            return of({
                type: SessionWorkerMessageTypes.IS_TOKEN_REFRESH_ALLOWED,
                tokens: null,
                isRequestAllowed: true,
            });
        }

        return from(
            this.postPromisifiedMessage({
                type: SessionWorkerMessageTypes.IS_TOKEN_REFRESH_ALLOWED,
            })
        );
    }

    public postTokens(tokens: CredentialsTokensType | null): void {
        if (this.sharedWorker) {
            this.postMessage({
                type: SessionWorkerMessageTypes.UPDATE_TOKENS,
                tokens,
            });
        }
    }

    public closeSessions(): void {
        if (this.sharedWorker) {
            this.postMessage({
                type: SessionWorkerMessageTypes.CLOSE_SESSIONS,
                tokens: null,
            });
        }
    }
}
