<!--START: Forgot password -->
<section id="new-password" *ngIf="isValidActivationKey; else notActivationKey">
    <div class="new-password__container d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4 offset-md-3 offset-lg-4">
                    <!--START: SprintPay Logo -->
                    <figure>
                        <img
                            class="d-block mx-auto my-0"
                            alt="SprintPay logo powered by CargoSprint"
                            src="./assets/images/sprintpay-horizontal-logo-powered.svg"
                        />
                    </figure>
                    <!--END: SprintPay Logo -->
                    <!--START: Headers -->
                    <div class="new-password__headers">
                        <h6 class="text-center">Reset your password</h6>
                    </div>
                    <!--EDN: Headers -->
                    <!--START: Forgot Password form -->
                    <form class="row" [formGroup]="newPasswordForm" (ngSubmit)="onSubmit()">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <!--START: Password -->
                            <div class="form-group">
                                <mat-form-field class="d-block mb-2" appearance="outline">
                                    <mat-label>New password</mat-label>
                                    <input
                                        matInput
                                        [type]="passwordHintHide ? 'password' : 'text'"
                                        id="password"
                                        name="password"
                                        data-cy="password"
                                        formControlName="password"
                                        autocomplete="off"
                                        placeholder="Enter your new password"
                                        [errorStateMatcher]="matcher"
                                    />
                                    <button
                                        type="button"
                                        data-cy="passwordHint_password"
                                        mat-icon-button
                                        matSuffix
                                        (click)="passwordHintHide = !passwordHintHide"
                                        [attr.aria-label]="'Hide password'"
                                        [attr.aria-pressed]="passwordHintHide"
                                    >
                                        <mat-icon>{{passwordHintHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                    <mat-error *ngIf="newPasswordForm.controls['password'].hasError('required')">
                                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                    </mat-error>
                                    <mat-error *ngIf="newPasswordForm.controls['password'].hasError('minlength')">
                                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Minimum 8 characters
                                    </mat-error>
                                    <mat-error *ngIf="newPasswordForm.controls['password'].hasError('number')">
                                        <i class="bi bi-exclamation-triangle-fill me-2"></i>At lest one number
                                    </mat-error>
                                    <mat-error *ngIf="newPasswordForm.controls['password'].hasError('letter')">
                                        <i class="bi bi-exclamation-triangle-fill me-2"></i>At lest one letter
                                    </mat-error>
                                    <mat-error *ngIf="newPasswordForm.controls['password'].hasError('non-whitespaces')">
                                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Non whitepaces
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!--END: Password -->
                            <!--START: Confirm password -->
                            <div class="form-group">
                                <mat-form-field class="d-block mb-2" appearance="outline">
                                    <mat-label>Confirm password</mat-label>
                                    <input
                                        matInput
                                        [type]="passwordHintHide ? 'password' : 'text'"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        data-cy="confirmPassword"
                                        formControlName="confirmPassword"
                                        autocomplete="off"
                                        placeholder="Confirm your new password"
                                        [errorStateMatcher]="matcher"
                                    />
                                    <button
                                        type="button"
                                        data-cy="passwordHint_confirm"
                                        mat-icon-button
                                        matSuffix
                                        (click)="passwordHintHide = !passwordHintHide"
                                        [attr.aria-label]="'Hide password'"
                                        [attr.aria-pressed]="passwordHintHide"
                                    >
                                        <mat-icon>{{passwordHintHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                    <mat-error *ngIf="newPasswordForm.controls['confirmPassword'].hasError('required')">
                                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                    </mat-error>
                                    <mat-error *ngIf="newPasswordForm.controls['confirmPassword'].hasError('matching')">
                                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. The password doesn't
                                        match, review your information
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!--END: Confirm password -->
                        </div>
                        <div class="col-sm-12 col-md-10 col-lg-10 offset-md-1 offset-lg-1">
                            <!--START: Submit button -->
                            <button
                                mat-button
                                type="submit"
                                class="d-block mx-auto border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green"
                                [disabled]="newPasswordForm.invalid || newPasswordForm.pristine"
                            >
                                Reset password
                            </button>
                            <!--END: Submit button -->
                        </div>
                    </form>
                    <!--END: Forgot Password form -->
                    <div class="text-center mb-2 mt-3 fw-normal">
                        <span class="existing__account">Do you already have an account?</span><span>&nbsp;&nbsp;</span
                        ><a
                            class="existing__account existing__account--link text-decoration-none"
                            [routerLink]="['/password.new-password']"
                            >Sign up</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--END: Forgot password -->
<!--START: Forgot password no activation key -->
<ng-template #notActivationKey></ng-template>
<!--END: Forgot password no activation key -->
