import { profileTypes } from './constants';

export const SWTICH_PROFILES_ACTIVE = ['FORWARDER_FACILITY', 'FORWARDER'];

export const PORTALS_ROUTES = [
    {
        name: 'Forwarder',
        description: 'Switch To Cargo Facility Profile',
        route: 'admin/home',
        activeProfileView: 'FORWARDER',
    },
    {
        name: 'Cargo facility',
        description: 'Switch To Cargo Forwarder Profile',
        route: 'super-admin/payments',
        activeProfileView: 'FACILITY',
    },
    {
        name: 'Forwarders admin',
        description: 'Switch Profile',
        route: 'forwarder-admin',
        activeProfileView: 'FORWARDER_ADMIN',
    },
];

export const PROFILES_PORTALS_OPTIONS = new Map([
    [profileTypes.FORWARDER_FACILITY, [PORTALS_ROUTES[0], PORTALS_ROUTES[1]]],
    [profileTypes.FORWARDER, [PORTALS_ROUTES[0]]],
    [profileTypes.FACILITY, [PORTALS_ROUTES[1]]],
]);
