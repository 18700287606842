<!-- START: DOWNLOAD -->
<section id="download" class="w-100 p-4">
    <div id="download__modal" class="container-fluid">
        <div class="row header">
            <!-- START: HEADER -->
            <div class="text-start">
                <h4 class="fs-1 lh-1">Download</h4>
            </div>
            <!-- END: HEADER -->
            <!-- START: EXPORTABLE -->
            <div *ngIf="exportable && !exportable.flux" class="row p-0 ps-4 text-center">
                <ng-container *ngFor="let kind of exportable.kind">
                    <span
                        class="align-items-center col-lg-6 pb-3"
                        [ngClass]="{ isActive: kind.label === fileKind.label }"
                    >
                        <button class="button block" (click)="selectFormat(kind)">{{kind.label}}</button>
                    </span>
                </ng-container>
            </div>
            <!-- END: EXPORTABLE -->
        </div>
        <div class="row">
            <div class="col-12">
                <div class="information p-4">
                    <ng-template [ngIf]="exportable && exportable.flux === 'GHA' && !isSuper">
                        <div class="row text-center pt-4">
                            <div class="col-12">
                                <label class="title"
                                    >3 months maximum date range allowed per download&nbsp;{{exportable.paymentName ?
                                    exportable.paymentName : 'invoices'}}&nbsp;in .xlsx format.</label
                                ><br />
                                <label class="subtitle"
                                    >{{ recordSelected.length }} records selected or select range.</label
                                >
                            </div>
                        </div>
                    </ng-template>
                    <ng-container *ngIf="!(exportable && exportable.flux === 'GHA') && !isSuper">
                        <div class="row text-center pt-4">
                            <div class="col-12">
                                <label class="title"
                                    >3 months maximum date range allowed per download&nbsp;{{exportable.paymentName ?
                                    exportable.paymentName : 'invoices'}}&nbsp;in.&nbsp;{{fileKind ? fileKind.type : ""
                                    }}&nbsp;format.</label
                                ><br />
                                <label class="subtitle"
                                    >{{ recordSelected.length }}&nbsp;{{fileKind ? fileKind.label : "" }}&nbsp;selected
                                    or select range.</label
                                >
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isSuper">
                        <div class="row text-center pt-4">
                            <div class="col-12">
                                <ng-container
                                    *ngIf="!exportable.paymentName || exportable.paymentName !== 'refunds'; else refundsTitle"
                                >
                                    <label class="title"
                                        >Download&nbsp;{{exportable.paymentName ? exportable.paymentName :
                                        'invoices'}}&nbsp;for 3 months maximum date range allowed per download</label
                                    >
                                </ng-container>
                                <ng-template #refundsTitle>
                                    <label class="title">Refund requests: Select up to a 3 month date range</label>
                                </ng-template>
                                <br />
                                <ng-container *ngIf="recordSelected.length">
                                    <label class="subtitle">{{ recordSelected.length }} records selected</label>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                    <!-- START: FORM -->
                    <ng-container *ngIf="!isSuper || (isSuper && !recordSelected.length)">
                        <div [formGroup]="dateRangeForm" class="row py-3">
                            <div class="col-12 col-md-6">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>From</mat-label>
                                    <input
                                        #fromInput
                                        matInput
                                        [matDatepicker]="from"
                                        placeholder="MM/DD/YYYY"
                                        formControlName="fromInput"
                                        [max]="maxDateFrom"
                                        [min]="minDateFrom"
                                        (dateChange)="onDateChangedFrom($event)"
                                    />
                                    <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
                                    <mat-datepicker #from (closed)="fromInput.blur()"></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-12 col-md-6">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>to</mat-label>
                                    <input
                                        #toInput
                                        matInput
                                        placeholder="MM/DD/YYYY"
                                        formControlName="toInput"
                                        [matDatepicker]="to"
                                        [max]="maxDateTo"
                                        [min]="minDateTo"
                                        (dateChange)="onDateChangedTo($event)"
                                    />
                                    <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
                                    <mat-datepicker #to (closed)="toInput.blur()"></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
                    <!-- END: FORM -->
                    <!-- START: SELECT FILE FORMAT -->
                    <ng-container *ngIf="isSuper">
                        <div class="row text-center">
                            <div class="col-12">
                                <mat-label class="mb-3 d-block title">Select Format</mat-label>
                                <mat-radio-group
                                    class="d-flex justify-content-center mb-4 w-100"
                                    [(ngModel)]="fileFormat"
                                >
                                    <mat-radio-button class="d-inline-block subtitle me-2" color="primary" value="XLSX"
                                        >Download Excel</mat-radio-button
                                    >
                                    <mat-radio-button class="d-inline-block subtitle me-2" color="primary" value="CSV"
                                        >Download .CSV</mat-radio-button
                                    >
                                    <ng-container *ngIf="recordSelected.length">
                                        <mat-radio-button class="d-inline-block subtitle" color="primary" value="PDF"
                                            >Download .PDF</mat-radio-button
                                        >
                                    </ng-container>
                                </mat-radio-group>
                            </div>
                        </div>
                    </ng-container>
                    <!-- END: SELECT FILE FORMAT -->
                </div>
            </div>
        </div>

        <!-- START: BUTTON ACTIONS -->
        <div class="row">
            <div class="col-12">
                <div id="download__actions" mat-dialog-actions class="pb-4 text-end d-block pt-1 pt-md-3">
                    <button
                        mat-button
                        type="button"
                        (click)="closeDialog()"
                        class="btn__sprintpay btn__sprintpay--link p-0 m-0 border-0 text-uppercase text-center"
                    >
                        Cancel
                    </button>
                    <button
                        mat-button
                        type="button"
                        color="primary"
                        (click)="download()"
                        class="btn__sprintpay btn__sprintpay--green p-0 m-0 border-0 rounded-0 text-uppercase text-center"
                        [disabled]="!hasValid()"
                    >
                        Download
                    </button>
                </div>
            </div>
        </div>
        <!-- END: BUTTON ACTIONS -->
    </div>
</section>
<!-- END: DOWNLOAD -->
