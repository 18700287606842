<form [formGroup]="securityForm">
    <!--START: Password -->
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>Password</mat-label>
            <input
                matInput
                [type]="hidePassword ? 'password' : 'text'"
                id="password"
                name="password"
                data-cy="password"
                formControlName="password"
                autocomplete="off"
                minlength="8"
                placeholder="Enter your password"
                [errorStateMatcher]="matcher"
            />

            <mat-icon matSuffix (click)="hidePassword = !hidePassword"
                >{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon
            >
            <mat-error *ngIf="securityForm.controls['password'].hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
            <mat-error *ngIf="securityForm.controls['password'].hasError('minlength')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Minimum 8 characters
            </mat-error>
            <mat-error *ngIf="securityForm.controls['password'].hasError('regex')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Minimum 8 characters, At least one number, At least
                one capital letter
            </mat-error>
        </mat-form-field>
    </div>
    <!--END: Password -->
    <!--START: Confirm password -->
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>Confirm password</mat-label>

            <input
                matInput
                [type]="hidePasswordConfirm ? 'password' : 'text'"
                id="confirm_password"
                name="confirm_password"
                data-cy="confirm_password"
                formControlName="confirmPassword"
                autocomplete="off"
                minlength="8"
                placeholder="Enter your confirm password"
                [errorStateMatcher]="matcher"
            />

            <mat-icon matSuffix (click)="hidePasswordConfirm = !hidePasswordConfirm"
                >{{hidePasswordConfirm ? 'visibility_off' : 'visibility'}}</mat-icon
            >
            <mat-error *ngIf="securityForm.controls['confirmPassword'].hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
            <mat-error *ngIf="securityForm.controls['password'].hasError('minlength')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Minimum 8 characters
            </mat-error>
            <mat-error *ngIf="securityForm.controls['confirmPassword'].hasError('matching')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. The password doesn't match, review your
                information.
            </mat-error>
        </mat-form-field>
    </div>
    <!--END: Confirm password -->
</form>
