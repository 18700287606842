import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Balance } from '@cargos/sprintpay-models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';

@Injectable({ providedIn: 'root' })
export class CSCreditAPIService {
    private _cartUrl: string;

    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenService
    ) {
        this._cartUrl = environment.uris.method.cart;
    }

    getSprintPayCredit(): Observable<Balance> {
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<Balance>(`${this._cartUrl}/getBalance?date=${new Date().valueOf()}`, { headers });
    }
}
