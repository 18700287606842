import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FileHandlerService } from 'src/app/services/utils/file-handler.service';
import { FileComponent } from './file/file.component';

@Component({
    selector: 'app-files-container',
    standalone: true,
    templateUrl: './files-container.component.html',
    imports: [CommonModule, FileComponent],
})
export class FilesContainerComponent {
    @Input() set filesPath(filesPath: string[]) {
        if (filesPath) {
            this.files = filesPath;
            this.nameFiles = filesPath.map((item: string): string => {
                return this._fileService.getNameOfFile(item);
            });
        }
    }
    @Input() title?: string = '';

    nameFiles!: string[];
    files!: string[];
    constructor(private _fileService: FileHandlerService) {}
}
