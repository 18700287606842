<!--START: DYNAMIC DROPDOWN FIELD-->
<form [formGroup]="dynamicFormGroup" class="row">
    <div class="col-12 col-lg-9">
        <div class="mb-3">
            <mat-form-field class="d-block w-100" appearance="outline">
                <mat-label>{{field.label}}</mat-label>
                <mat-select
                    [id]="field.name"
                    [formControlName]="field.name"
                    [required]="dynamic_required"
                    [errorStateMatcher]="matcher"
                    (mouseover)="showTooltip = true"
                    (mouseleave)="showTooltip = false"
                >
                    <mat-option *ngFor="let option of field.options" [value]="option.value">
                        {{option.displayValue}}
                    </mat-option>
                </mat-select>
                <!--START: ERRORS-->
                <mat-error *ngIf="dynamicFormGroup.controls[field.name].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>{{
                    field.inputValidations?.['required']?.errorMessage || 'This field it\'s required'}}
                </mat-error>
                <!--END: ERRORS-->
            </mat-form-field>
        </div>
    </div>
    <!--START: DYNAMIC TOOLTIP-->
    <ng-container *ngIf="field?.tooltipTitle || field?.tooltipMessage">
        <ng-container *ngIf="showTooltip === true">
            <div class="col-12 col-lg-9">
                <span class="cs__tooltip cs__tooltip--warning">
                    <strong class="d-inline-block mb-3" *ngIf="field?.tooltipTitle">{{field.tooltipTitle}}</strong>
                    <span class="d-block mb-2" *ngIf="field?.tooltipMessage">{{field.tooltipMessage}}</span>
                </span>
            </div>
        </ng-container>
    </ng-container>
    <!--END: DYNAMIC TOOLTIP-->
</form>
<!--END: DYNAMIC DROPDOWN FIELD-->
