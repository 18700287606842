import { Directive, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomersType } from '@cargos/sprintpay-models';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { RedirectGuard } from 'src/app/guards/redirect.guard';
// COMPONENTS
import { AdminComponent } from './admin.component';

@Directive()
class RedirectComponent {}

const routes: Routes = [
    {
        path: '',
        component: AdminComponent,
        children: [
            {
                path: '',
                redirectTo: '/admin/home',
                pathMatch: 'full',
            },
            {
                path: 'home',
                title: 'SprintPay | Home',
                loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },
            {
                path: 'facilityPayments',
                loadChildren: () =>
                    import('./facility-payments/facility-payments.module').then((m) => m.FacilityPaymentsModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, CustomersType.GUEST, 'BUSINESS'],
                        redirectTo: '/',
                    },
                },
            },
            {
                path: 'invoices',
                loadChildren: () => import('./invoices/invoices.module').then((m) => m.InvoicesModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },
            {
                path: 'users',
                loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },
            {
                path: 'cart',
                loadChildren: () => import('./cart/cart.module').then((m) => m.CartModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, CustomersType.GUEST, 'BUSINESS'],
                    },
                },
            },
            {
                path: 'refunds-requests',
                loadChildren: () =>
                    import('src/app/modules/refunds-requests/refunds-requests.module').then(
                        (m) => m.RefundsRequestsModule
                    ),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },
            {
                path: 'facilityContacts',
                loadChildren: () =>
                    import('./facility-contacts/facility-contacts.module').then((m) => m.FacilityContactsModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },
            {
                path: 'settings',
                loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },

            {
                path: 'bank-debits',
                title: 'SprintPay | My bank debits',
                loadChildren: () =>
                    import('../../modules/bank-debits/bank-debits.module').then((m) => m.BankDebitsModule),
            },
            {
                path: '**',
                canActivate: [RedirectGuard],
                component: RedirectComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule {}
