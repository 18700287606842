import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { DataSecurityService } from '../data-security.service';

@Injectable({
    providedIn: 'root',
})
export class IdleService {
    private _activity: ReplaySubject<number>;
    private _setNextActivity = () => {
        this._activity.next(parseInt(this.getLastActivity()));
    };
    private readonly _itemName: string;

    constructor(private _dataSecurityService: DataSecurityService) {
        this._itemName = 'onboard-service';
        this._activity = new ReplaySubject(1);
    }

    /**
     * @method getLastActivity
     * @description Return the last time of activity
     */

    getLastActivity(): string {
        let lastActivity = localStorage.getItem(this._itemName);
        if (lastActivity) {
            lastActivity = this._dataSecurityService.decryptString(lastActivity);
        }
        return lastActivity ?? String(new Date().getTime());
    }

    /**
     * @method removeListener
     * @description Remove the last user activity
     */

    removeListener(): void {
        window.removeEventListener('storage', this._setNextActivity);
    }

    /**
     * @method initListener
     * @description
     */

    initListener(): void {
        this.removeListener();
        window.addEventListener('storage', this._setNextActivity);
    }

    /**
     * @method setActivity()
     * @description Save the user activity
     */

    setActivity(): void {
        localStorage.setItem(this._itemName, this._dataSecurityService.encrypt(String(new Date().getTime())));
        this._setNextActivity();
    }
}
