<!--START: REJECTED REQUEST -->
<section id="rejected-request" class="w-100" *ngIf="rejectedRequest.length > 0">
    <div class="row">
        <div class="col-12">
            <div id="rejected-request__header">
                <!--START: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
                <div class="row">
                    <div class="col-8 col-md-6 d-flex align-items-center justify-content-start">
                        <h6>Rejected request</h6>
                    </div>
                    <div class="col-4 col-md-6 justify-content-end d-flex align-items-center">
                        <div class="align-items-baseline">
                            <button
                                mat-button
                                type="button"
                                class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase rejected-request__all"
                                [routerLink]="['/admin/facilityPayments/rejectedPayment']"
                            >
                                View All
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!--END: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
            <!--START: CARD REJECTED REQUEST-->
            <div id="rejected-request__container" class="row">
                <article
                    *ngFor="let item of rejectedRequest; trackBy: trackBy"
                    class="col-sm-12 col-md-6 col-lg-3 mb-3 d-block mt-3 justify-content-between align-items-start"
                >
                    <div class="card">
                        <div class="row">
                            <div class="card-body">
                                <div class="col-12 d-block ps-2 ms-1">
                                    <!--START: CARD REJECTED ITEM ID-->
                                    <div class="row">
                                        <div class="align-items-baseline">
                                            <span class="fw-bold d-block title">{{ item.id }}</span>
                                        </div>
                                    </div>
                                    <!--START: CARD REJECTED ITEM ID-->
                                    <!--END: CARD REJECTED ITEM FACILITY NAME-->
                                    <div class="row">
                                        <div class="align-items-baseline">
                                            <span class="d-block subtitle">{{ item.facility?.name }}</span>
                                        </div>
                                    </div>
                                    <!--END: CARD REJECTED FACILITY NAME-->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="card-body rejected-notes">
                                    <!--START: CARD REJECTED ITEM REQUEST TITLE-->
                                    <div class="row">
                                        <div class="col-12 d-flex ps-1">
                                            <span class="d-block title">Rejected description:</span>
                                        </div>
                                    </div>
                                    <!--END: CARD REJECTED ITEM REQUEST TITLE-->
                                    <!--START: CARD REJECTED ITEM APPROVAL REASON-->
                                    <div class="row">
                                        <div class="col-12 d-flex ps-1">
                                            <span class="d-block notes"
                                                >{{item.approvalReason | slice: 0:50}}{{item.approvalReason?.length > 50
                                                ? '...' : ''}}</span
                                            >
                                        </div>
                                    </div>
                                    <!--END: CARD REJECTED ITEM APPROVAL REASON-->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="updatePayment d-grid align-items-center text-center">
                                <button
                                    mat-button
                                    type="button"
                                    class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase updatePayment_button"
                                    routerLink="../facilityPayments/rejectedPayment/paymentDetail/{{item.id}}/{{item.approvalStatus}}"
                                >
                                    {{isPaymentOwner(item) ? 'UPDATE PAYMENT' : 'REVIEW'}}
                                </button>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
            <!--END: CARD REJECTED REQUEST-->
        </div>
    </div>
</section>
<!--END: REJECTED REQUEST-->
