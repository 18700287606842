import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { Observable, Subject, filter, map, of, switchMap, take, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { NotificationsType, TypeNotificationType } from './models/notifications/notifications';
import { LoginService } from './pages/auth/services/login.service';
import { CustomerFeaturesService } from './services/customer.service';
import { CustomerService } from './services/utils/customer-handler.service';
import { SecurityService } from './services/utils/security.service';
import { StorageService } from './services/utils/storage.service';
import { TokenService } from './services/utils/token.service';
import { UserSessionService } from './services/utils/user-session.service';
import { BannerService } from './standalone-components/banner/services/banner.service';
import { permissionsName, profileTypes } from './utils/constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    private _unsubscribe$: Subject<void>;
    private redirectToPortalRoutes = [
        '/login',
        '/password',
        '/signup',
        '/signup-guest',
        '/activate',
        '/activate-guest',
    ];
    private activeProfileView = '';
    constructor(
        private _customerRequestsService: CustomerFeaturesService,
        private _tokenService: TokenService,
        private _bannerService: BannerService,
        private _customerService: CustomerService,
        private _securityService: SecurityService,
        private _rolesService: NgxRolesService,
        private _ngxPermissionsService: NgxPermissionsService,
        private _router: Router,
        private _loginService: LoginService,
        private _userSessionService: UserSessionService,
        private _storageService: StorageService,
        private _ngZone: NgZone
    ) {
        this._unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this._subscribeRouterEventsForPermissionsWorkaround();
        this.getCustomerConfiguration();
        this.checkSessionTokenChange();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    checkSessionTokenChange(): void {
        this._userSessionService
            .hasToken$()
            .pipe(
                filter((token) => token),
                switchMap(() => this._userSessionService.isAuthenticated$()),
                filter((authenticated) => authenticated),
                takeUntil(this._unsubscribe$)
            )
            .subscribe(() => this.redirectToAdminPortal());
    }

    private redirectToAdminPortal(): void {
        const route = `/${this._router.url.split('/')[1]}`;
        if (!this.redirectToPortalRoutes.includes(route)) return;
        const customer = this._customerService.getCustomer();
        const customerProfile =
            customer?.profileType && customer.profileType !== profileTypes.FORWARDER_FACILITY
                ? customer.profileType
                : profileTypes.FORWARDER;
        this._customerService.setActiveProfileView(customerProfile);
        const url = this._loginService.getRedirectLink();
        this._ngZone.run(() => this._router.navigate([url]));
    }

    private _subscribeRouterEventsForPermissionsWorkaround(): void {
        this._router.events.pipe(takeUntil(this._unsubscribe$)).subscribe((event) => {
            if (event instanceof NavigationStart && event.url.includes('admin/facilityPayments/newPayment?')) {
                this._ngxPermissionsService.addPermission(permissionsName.guest);
            }
        });
    }

    getCustomerTypeForPermissionsRoute(): void {
        this._customerService
            .getActiveProfileView$()
            .pipe(
                switchMap((profile: string) => {
                    this._handleRolesAndPermissions(profile);
                    return profile;
                }),
                takeUntil(this._unsubscribe$)
            )
            .subscribe({});
    }

    getCustomerConfiguration(): void {
        this._tokenService
            .isAuthenticated$()
            .pipe(
                switchMap((isAuthenticated) => {
                    if (isAuthenticated || this._customerService.isGuest()) {
                        this.getCustomerTypeForPermissionsRoute();
                    }
                    if (!isAuthenticated || this._customerService.isGuest()) {
                        return of([]);
                    }
                    return this.getCustomerNotifications();
                }),
                takeUntil(this._unsubscribe$)
            )
            .subscribe();
    }

    getCustomerNotifications(): Observable<string> {
        return this._customerRequestsService.getCustomerNotifications().pipe(
            take(1),
            switchMap((notifications) => {
                return this._customerService.getActiveProfileView$().pipe(
                    map((activeProfileView) => {
                        this.activeProfileView = activeProfileView;
                        const alerts = this.getAlertsAllowedToBeDisplayed(notifications);
                        const banners = notifications.filter((notification) => {
                            return (
                                notification.type === TypeNotificationType.banner &&
                                this.isAllowedToBeDisplayBanner(notification)
                            );
                        });
                        this._bannerService.setNotifications(banners || []);

                        if (alerts?.length) {
                            const listAlerts = this.getListItemsForModal(alerts);

                            Swal.fire({
                                title: 'Alert!',
                                html: listAlerts,
                                icon: 'info',
                                showConfirmButton: true,
                                confirmButtonText: 'Continue',
                                confirmButtonColor: '#14bb9c',
                                allowOutsideClick: false,
                            });
                        }

                        return 'Complete';
                    })
                );
            })
        );
    }

    isAllowedToBeDisplayBanner(notification: NotificationsType): boolean {
        if (!notification.message.toLowerCase().includes('alliance ground')) {
            return true;
        }
        return this.activeProfileView === profileTypes.FORWARDER;
    }

    getAlertsAllowedToBeDisplayed(notifications: NotificationsType[]): NotificationsType[] {
        const commonAlerts = notifications.filter(
            (notification) => notification.type === TypeNotificationType.alert && notification.name !== 'VIN'
        );

        const vinAlert = notifications.filter(
            (notification) => notification.type === TypeNotificationType.alert && notification.name === 'VIN'
        );

        if (vinAlert?.length && this.isAllowedToDisplayVinMessage()) {
            const currentCount = this.getCountVinMessageDisplayed() + 1;
            this._storageService.saveElement('displayMessage', currentCount.toString());

            return [...commonAlerts, ...vinAlert];
        }

        return commonAlerts;
    }

    isAllowedToDisplayVinMessage(): boolean {
        const count = this.getCountVinMessageDisplayed();

        return Number(count) < 2;
    }

    getCountVinMessageDisplayed(): number {
        const count = this._storageService.getElement('displayMessage') || 0;

        return Number(count);
    }

    getListItemsForModal(alerts: NotificationsType[]): string {
        let listItem: string = `<div style='justify-content:center; display:flex;'>`;

        alerts.length === 1 ? (listItem += `<ul class='px-3 py-0' style='list-style: none;'>`) : (listItem += `<ul>`);

        alerts.forEach((item: NotificationsType): void => {
            listItem += `<li style='font-weight: 400;'>` + item.message + `</li>`;
        });

        listItem += `</ul></div>`;

        return listItem;
    }

    /**
     * @method _handleRolesAndPermissions()
     * @description Handles the logic for the security based on user and roles
     */

    private _handleRolesAndPermissions(profile: string): void {
        const type =
            profile === profileTypes.FORWARDER || profile === ''
                ? this._securityService.getUserType()
                : this._securityService.getProfileType();
        const customer = this._customerService.getCustomer();
        const permissionsComponents: Array<string> = this.assignPermissionsComponentsToRole(customer, profile);
        this._rolesService.flushRoles();
        this._ngxPermissionsService.flushPermissions();
        this._ngxPermissionsService.addPermission(type);
        const customerType = this._customerService.getCustomerType();
        if (customerType) {
            this._ngxPermissionsService.loadPermissions([customerType]);
        }
        this._rolesService.addRole(type, permissionsComponents);
        this._ngxPermissionsService.addPermission(permissionsComponents);
    }

    /**
     * @method assignPermissionsToRole()
     * @description
     */
    private assignPermissionsComponentsToRole(customer: any, profile: string): string[] {
        let components: Array<string>;
        if (profile === profileTypes.FORWARDER || profile === '') {
            components = this._securityService.getComponentsByUser();
            if (customer && !customer.isGuest) {
                components.push('home');
                components.push('refunds-requests');
                components.push('facility-contacts');
                components.push('settings');
            }
            if (customer && customer.isMegafileEnabled) {
                components.push('upload-request-file');
            } else {
                components = components.filter((item: any): boolean => item !== 'upload-request-file');
            }
        } else {
            components = this._securityService.getPermissionsBySuperUser();
            // START: adding super admin components statically
            components.push('payments');
            // ENDING: adding super admin components statically
        }
        if (this._customerService.isAgiAeroDomain()) {
            components.push('auto-pay-subscription');
        }
        if (customer && customer.isUserManagementEnabled) {
            components.push('users');
        } else if (customer && customer.paymentsNotInvoicedEnabled) {
            components.push('payment-not-invoiced');
        } else {
            components = components.filter((item: any) => item !== 'payment-not-invoiced');
        }

        if (customer?.isAutomatedDebitEnabled && !customer?.customerAccount?.isAutomatedDebitEnabled) {
            components.push('bank-debits');
            components.push('bank-info');
            components.push('debit-history');
        }

        if (customer?.customerAccount?.isAutomatedDebitEnabled) {
            components.push('bank-debits-admin');
            components.push('bank-info');
            components.push('debit-history');
        }

        return components;
    }
}
