import { MediaMatcher } from '@angular/cdk/layout';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class HelpContactusService {
    constructor(
        private _domSanitizer: DomSanitizer,
        private _mediaMatcher: MediaMatcher
    ) {}

    /**
     * @method _isMobile()
     * @description Getter to check screen resolution
     */

    private get _isMobile(): boolean {
        return this._mediaMatcher.matchMedia('(max-width: 599.98px)').matches;
    }

    /**
     * @method showHelpSwal()
     * @description: Sweet alert call that displays the information relate to help and contact as a reusable logic for the system
     */

    showHelpSwal(): void {
        const supportEmail: string | null = this._domSanitizer.sanitize(
            SecurityContext.URL,
            this._domSanitizer.bypassSecurityTrustUrl(`mailto:support@cargosprint.com`)
        );
        const accountingEmail: string | null = this._domSanitizer.sanitize(
            SecurityContext.URL,
            this._domSanitizer.bypassSecurityTrustUrl(`mailto:accounting@cargosprint.com`)
        );
        const collectionsEmail: string | null = this._domSanitizer.sanitize(
            SecurityContext.URL,
            this._domSanitizer.bypassSecurityTrustUrl(`mailto:collections@cargosprint.com`)
        );
        const remittanceEmail: string | null = this._domSanitizer.sanitize(
            SecurityContext.URL,
            this._domSanitizer.bypassSecurityTrustUrl(`mailto:ar@cargosprint.com`)
        );
        const tosUrl: string | null = this._domSanitizer.sanitize(
            SecurityContext.RESOURCE_URL,
            this._domSanitizer.bypassSecurityTrustResourceUrl('https://cargosprint.com/legal/terms-of-use/sprintpay')
        );
        const privacyPolicyUrl: string | null = this._domSanitizer.sanitize(
            SecurityContext.RESOURCE_URL,
            this._domSanitizer.bypassSecurityTrustResourceUrl('https://cargosprint.com/legal/privacy-policy')
        );

        Swal.fire({
            html: `
    <div class='helpContactAlert'>
      <h6>Help / Contact US</h6>
      <p class='fw-light'>Payment request to cargo facility / Cancellations<a class='d-block mt-1 mb-3' href='${supportEmail}'>support@cargosprint.com</a></p>
      <p class='fw-light'>Follow-up on a refund or credit note request<a class='d-block mt-1 mb-3' href='${accountingEmail}'>accounting@cargosprint.com</a></p>
      <p class='fw-light'>AR Limits / AR Limit holds<a class='d-block mt-1 mb-3' href='${collectionsEmail}'>collections@cargosprint.com</a></p>
      <p class='fw-light'>Payment remittance<a class='d-block mt-1 mb-3' href='${remittanceEmail}'>ar@cargosprint.com</a></p>
      <hr class="d-block d-md-none" />
      <h6>Address</h6>
      <p class='fw-light'>21 Eastbrook Bend Suite 202 Peachtree City, GA 30269</p>
      <hr class="d-block d-md-none" />
      <h6>Legal</h6>
      <p class='fw-light'><a target='_blank' href='${tosUrl}'>Terms of Use</a> and <a target='_blank' href='${privacyPolicyUrl}'>Privacy Policy</a></p>
    </div>`,
            icon: !this._isMobile ? 'info' : undefined,
            showConfirmButton: !this._isMobile,
            confirmButtonText: 'Close',
            allowOutsideClick: false,
            showCloseButton: this._isMobile,
        });
    }
}
