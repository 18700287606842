import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from '@cargos/sprintpay-models';
import { Observable } from 'rxjs';
import { DebitAccount } from 'src/app/models/debits/debitAccounts.model';
import { Filters } from 'src/app/models/ui/table.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';

@Injectable({
    providedIn: 'root',
})
export class EChecksAPIService {
    private _echecksUrl: string;

    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenService
    ) {
        this._echecksUrl = environment.uris.method.echecks;
    }

    getBankAccounts(activeProfileView: string, filters: Filters): Observable<Pagination<DebitAccount>> {
        const url = `${this._echecksUrl}/bank-accounts/retrieve`;
        let headers = new HttpHeaders();
        let params = new HttpParams();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        params = params.append('activeProfileView', activeProfileView);

        Object.entries(filters).forEach(([key, value]) => {
            params = params.append(key, value);
        });

        return this._httpClient.get<Pagination<DebitAccount>>(url, { headers, params });
    }

    createBankAccount(account: DebitAccount, activeProfileView: string): Observable<any> {
        const url = `${this._echecksUrl}/bank-accounts/create`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.post(
            url,
            {
                activeProfileView,
                accountNumber: account.accountNumber,
                routingNumber: account.routingNumber,
            },
            { headers, responseType: 'text' }
        );
    }

    deleteBankAccount(accountId: number, activeProfileView: string): Observable<any> {
        const url = `${this._echecksUrl}/bank-accounts/delete?accountId=${accountId}&activeProfileView=${activeProfileView}`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.delete(url, { headers, responseType: 'text' });
    }

    selectMainBankAccount(accountId: number, activeProfileView: string): Observable<any> {
        const url = `${this._echecksUrl}/bank-accounts/update?accountId=${accountId}&activeProfileView=${activeProfileView}`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.post(url, {}, { headers, responseType: 'text' });
    }
}
