<app-breadcrumb></app-breadcrumb>
<!--START: SETTINGS COMPONENT -->
<section id="settings">
    <div id="settings__header" class="mb-3 pt-3">
        <!--START: HEADER AND ACTION -->
        <div class="row">
            <div class="col-12 col-md-6 d-flex align-items-center justify-content-start">
                <h6>Settings</h6>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS -->
    </div>
    <ng-container>
        <div id="settings__container" class="container-fluid py-4 px-5">
            <div class="row">
                <div class="col-12">
                    <mat-tab-group mat-stretch-tabs animationDuration="0ms" disableRipple>
                        <mat-tab label="Account">
                            <app-account></app-account>
                        </mat-tab>
                        <mat-tab label="Security">
                            <app-security></app-security>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </ng-container>
</section>
<!--START: SETTINGS COMPONENT -->
