import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { getCards } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/credit-card/getCreditCards';
import { getEChecks } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/e-checks/e-check';
import { Subject, Subscription, take, takeUntil, throwError } from 'rxjs';
import { CSCreditService } from 'src/app/services/cs-credit.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { Balance, PaymentMethods, PaymentMethodsCartBill, PaymentMethodsKeys } from 'src/app/utils/cartTypes';
import { profiles } from 'src/app/utils/constants';
import { CartService } from '../../../../services/utils/cart.service';
import { CustomerService } from '../../../../services/utils/customer-handler.service';
import { SessionService } from '../../../../services/utils/session.service';

@Component({
    selector: 'app-payment-methods',
    templateUrl: './payment-methods.component.html',
})
export class PaymentMethodsComponent implements OnInit, OnDestroy {
    creditCard: boolean;
    eCheck: boolean;
    paypal: boolean;
    sprintPayCredit: boolean;
    sprintPayCreditMsg: boolean;
    creditCards?: any[];
    eChecks?: any[];
    sprintPayCreditAmount?: Balance;
    isPaymentsNotInvoicedEnabled!: boolean;
    isNewPayment: boolean;
    paymentMethodSelected: PaymentMethods | null;
    paymentMethods: any;
    invoicesInCart: boolean;
    currentMonth: number;
    currentYear: number;
    noCreditCards: boolean | undefined;
    noEchecks: boolean | undefined;
    isLoading: boolean;
    private _paymentRequestCount: number;
    private _userProfile: any;
    private _subscriptions: Subscription;
    private _unsubscribe$: Subject<void>;
    public loadingCreditCards = false;
    public loadingEchecks = false;

    @Output() paymentMethod: EventEmitter<any> = new EventEmitter();

    constructor(
        private _sessionService: SessionService,
        private _securityService: SecurityService,
        private _customerService: CustomerService,
        private _matDialog: MatDialog,
        private _cartService: CartService,
        private _csCreditService: CSCreditService
    ) {
        this.currentMonth = new Date().getMonth() + 1;
        this.currentYear = new Date().getFullYear();
        this._subscriptions = new Subscription();
        this.paymentMethods = PaymentMethods;
        this.creditCard = false;
        this.isNewPayment = false;
        this.eCheck = false;
        this.paypal = false;
        this.sprintPayCredit = false;
        this.sprintPayCreditMsg = false;
        this.invoicesInCart = false;
        this._paymentRequestCount = 0;
        this.paymentMethodSelected = null;
        this.isLoading = false;
        this._unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this._getPaymentRequestCount();
        const customer = this._customerService.getCustomer();
        this._userProfile = this._customerService.getCustomerProfile();
        this.sprintPayCredit = this._isCargoSprintCreditEnabled;
        this.sprintPayCreditMsg = this.sprintPayCredit;
        this.creditCard = this._userProfile.components.some(
            (type: any): boolean => type.name === PaymentMethods.CREDIT_CARD
        );
        this.eCheck = this._userProfile.components.some((type: any): boolean => type.name === PaymentMethods.ECHECK);
        this.paypal = this._userProfile.components.some((type: any): boolean => type.name === PaymentMethods.PAYPAL);
        this.isNewPayment = this._userProfile.name === profiles.ACHDebit && this._paymentRequestCount > 0;
        this.isPaymentsNotInvoicedEnabled = !!(
            customer.isPaymentsNotInvoicedEnabled && this._paymentRequestCount === 0
        );

        if (this.creditCard && this._securityService.verifyComponentsSecurity(PaymentMethods.CREDIT_CARD)) {
            this._loadCreditCards();
        }
        if (this.eCheck && this._securityService.verifyComponentsSecurity(PaymentMethods.ECHECK)) {
            this._loadEChecks();
        }
        if (this.sprintPayCredit && this._securityService.verifyComponentsSecurity(PaymentMethods.CARGOSPRINT_CREDIT)) {
            this._loadSprintPayCredit();
        }

        this._reviewInvoicesOrRequests();
    }

    ngOnDestroy(): void {
        this.closeDialog();
        this._subscriptions.unsubscribe();
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    /**
     * @method _isCargoSprintCreditEnabled()
     * @description Getter to check if there is cargo-credit in components section of customer profile
     */

    private get _isCargoSprintCreditEnabled(): boolean {
        return this._userProfile?.components?.some(
            (type: any): boolean => type.name === PaymentMethods.CARGOSPRINT_CREDIT
        );
    }

    /**
     * @method _reviewInvoicesOrRequests()
     * @description Review the payment if it's a invoice we will not preselect the SPCredit as payment method
     */

    private _reviewInvoicesOrRequests(): void {
        this.isLoading = true;
        this._cartService
            .getCartBill$()
            .pipe(take(1))
            .subscribe({
                next: (response) => {
                    if (response && response.cart && response.cart.length > 0) {
                        if (response.cart[0].originalInvoice !== undefined) {
                            this.sprintPayCredit = false;
                            this.invoicesInCart = true;
                            this.sprintPayCreditMsg = this._isCargoSprintCreditEnabled;
                        } else {
                            this.sprintPayCredit = this._isCargoSprintCreditEnabled;
                            this.sprintPayCreditMsg = this.sprintPayCredit;
                            this.invoicesInCart = false;
                        }
                    }

                    this.isLoading = false;
                    this.paymentMethodSelected = this._assignPaymentMethod();
                },
            });
    }

    /**
     * @method _getPaymentRequestCount()
     * @description Get payment requests count from session storage
     */

    private _getPaymentRequestCount(): void {
        const subscription = this._sessionService.getPaymentRequestCount().subscribe({
            next: (count: number): void => {
                this._paymentRequestCount = count;
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });

        this._subscriptions.add(subscription);
    }

    /**
     * @method _assignPaymentMethod()
     * @description If cart already has selected payment method returns it, then try to return default payment method from user frofile. If above both are false try to return any of available payment methods
     */

    private _assignPaymentMethod(): PaymentMethods | null {
        if (this._cartService.instant_payment_method?.method) {
            if (
                !this.invoicesInCart ||
                (this.invoicesInCart &&
                    this._cartService.instant_payment_method?.method !== PaymentMethodsCartBill.CARGOSPRINT_CREDIT)
            ) {
                return PaymentMethods[this._cartService.instant_payment_method.method];
            }
        }

        if (this._paymentRequestCount > 0 && this._userProfile.paymentMethodForNewPayments) {
            if (
                this._userProfile.paymentMethodForNewPayments === PaymentMethods.CARGOSPRINT_CREDIT ||
                this._userProfile.paymentMethodForNewPayments === PaymentMethods.PAYPAL
            ) {
                return this._userProfile.paymentMethodForNewPayments;
            } else {
                if (this.isPaymentsNotInvoicedEnabled) {
                    return this._userProfile.paymentMethodForNewPayments;
                }
            }
        } else {
            if (this._userProfile.paymentMethodForOpenInvoices && this.invoicesInCart) {
                return this._userProfile.paymentMethodForOpenInvoices;
            }
        }

        if (!this.paymentMethodSelected) {
            if (this.sprintPayCredit && !this.invoicesInCart) {
                return PaymentMethods.CARGOSPRINT_CREDIT;
            } else if (this.creditCard) {
                return PaymentMethods.CREDIT_CARD;
            } else if (this.eCheck) {
                return PaymentMethods.ECHECK;
            } else if (this.paypal) {
                return PaymentMethods.PAYPAL;
            }
        }

        return null;
    }

    /**
     * @method _loadCreditCards()
     * @description Return the user credit cards
     */

    private _loadCreditCards(): void {
        this.loadingCreditCards = true;
        const subscription = getCards().subscribe({
            next: (result: any): void => {
                this.creditCards = result;
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
            complete: (): void => {
                this.loadingCreditCards = false;
                this.creditCards!.length >= 1 ? (this.noCreditCards = false) : (this.noCreditCards = true);
            },
        });

        this._subscriptions.add(subscription);
    }

    /**
     * @method _loadEChecks()
     * @description Return the user eChecks
     */

    private _loadEChecks(): void {
        this.loadingEchecks = true;
        const subscription = getEChecks().subscribe({
            next: (result: any): void => {
                this.eChecks = result;
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
            complete: (): void => {
                this.loadingEchecks = false;
                this.eChecks!.length >= 1 ? (this.noEchecks = false) : (this.noEchecks = true);
            },
        });

        this._subscriptions.add(subscription);
    }

    /**
     * @method _loadSprintPayCredit()
     * @description Return the user SprintPay Credit
     */

    private _loadSprintPayCredit(): void {
        this._csCreditService
            .getIsProcessing$()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe((isProcessing) => {
                const creditBalance = this._csCreditService.instant_cs_credit_amount;
                if (!isProcessing && creditBalance) {
                    this.sprintPayCreditAmount = {
                        availableCredit: creditBalance?.availableCredit,
                        creditLimit: creditBalance?.creditLimit,
                    };
                }
            });
    }

    /**
     * @method moreThanOnePaymentOption()
     * @description Show message if there is more than one payment options are available on selected tab
     */

    get moreThanOnePaymentOption(): boolean {
        return (
            !!(
                this.paymentMethodSelected === PaymentMethods.CREDIT_CARD &&
                this.creditCard &&
                this.creditCards &&
                this.creditCards.length > 1
            ) ||
            !!(
                this.paymentMethodSelected === PaymentMethods.ECHECK &&
                this.eCheck &&
                this.eChecks &&
                this.eChecks.length > 1
            )
        );
    }

    /**
     * @method selectPaymentMethod()
     * @param (paymentAccount: any) Object in array
     * @param (method: keyof typeof PaymentMethods) Method of payment
     * @description: Triggers an observable in the SUMMARY.ts that handles the logic to change the payment summary in relation to the desired payment method
     */

    selectPaymentMethod(paymentAccount: any, method: PaymentMethodsKeys): void {
        this._cartService.showPayMethod({ paymentAccount, method });
        this.paymentMethod.emit({ paymentAccount, method });
        this.closeDialog();
    }

    /**
     * @method closeDialog()
     * @description Close the dialog in this case menu right sidebar for the activity log
     */

    closeDialog(): void {
        this._matDialog.closeAll();
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }
}
