import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { Locations } from 'src/app/models/utils/locations.model';
import { FacilityService } from 'src/app/services/facility.service';
import { Schenker } from 'src/app/utils/companies/schenker';
import { Domains, paymentTypesDSV, regex } from 'src/app/utils/constants';
import { ErrorMatcher } from 'src/app/utils/errorMatcher';
import { sortPaymentTypes } from 'src/app/utils/ordered-data';
import { StepperSteps } from 'src/app/utils/stepperTypes';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { RestService } from '../../../../../../../services/rest.service';
import { CustomerService } from '../../../../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { PaymentDetailService } from '../../payment-detail.service';
import { StepperService } from '../../services/stepper.service';

@Component({
    selector: 'app-schenker-reference',
    templateUrl: './schenker-reference.component.html',
})
export class SchenkerReferenceComponent implements OnInit {
    hasExternalLookup: boolean = false;
    schenkerReferenceForm!: FormGroup;
    facility: any;
    paymentAmountOptions: any[];
    typeOptions!: string[];
    urlNextStep: string;
    onEdition: boolean;
    locationAssigned: any;
    locations: Locations[];
    matcher: ErrorMatcher = new ErrorMatcher();
    private _customer: any;
    private readonly _currentPayment: any;
    private readonly _paymentsURL: String;
    private readonly _companyName: string;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _paymentFluxService: PaymentFluxService,
        private _formBuilder: FormBuilder,
        private _restService: RestService,
        private _customerService: CustomerService,
        private _paymentDetailService: PaymentDetailService,
        private _router: Router,
        private _stepperService: StepperService,
        private _facilityService: FacilityService
    ) {
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.hasExternalLookup = !!this._currentPayment.facility?.externalLookup;
        if (!this._currentPayment) {
            this._router.navigate([`/admin/facilityPayments/newPayment`]);
        }
        this._customer = this._customerService.getCustomer();
        this.locationAssigned =
            this._customer.approvalLevels && this._customer.approvalLevels.company
                ? this._customer.approvalLevels.company.firstLocation
                : null;
        const approvalLevels: any = this._customer.approvalLevels
            ? this._customer.approvalLevels.company.paymentReference.filter((item: any) => {
                  if (item.name === 'Type') {
                      return item;
                  }
              })
            : '';
        this.paymentAmountOptions = approvalLevels[0].paymentReferenceLookups.map((item: any) => {
            return item.type;
        });
        const facility = this._currentPayment.facility;
        this._facilityService
            .getPaymentTypes(facility.id)
            .pipe(take(1))
            .subscribe({
                next: (paymentTypesResult) => {
                    if (paymentTypesResult.length) {
                        this.typeOptions = sortPaymentTypes(paymentTypesResult);
                    } else {
                        this.typeOptions = this._customerService.isUserDomain(Domains.DSV)
                            ? JSON.parse(JSON.stringify(paymentTypesDSV))
                            : JSON.parse(JSON.stringify(Schenker.paymentTypes));
                    }
                },
            });
        this._paymentsURL = environment.uris.method.payments;
        this._companyName = this._customer?.approvalLevels?.company?.name || '';
        this.urlNextStep =
            this._currentPayment && this._currentPayment.redirectRoute
                ? this._currentPayment.redirectRoute
                : this._companyName
                  ? '../paymentDetails'
                  : '../customerReference';
        this.onEdition =
            (this._currentPayment && this._currentPayment.redirectRoute) ||
            (this._currentPayment && this._currentPayment.id)
                ? true
                : false;
        this.locations = [];
    }

    ngOnInit(): void {
        this._setFromBuilder();
        if (this.locationAssigned) {
            this.schenkerReferenceForm.get('locationId')!.setValue(this.locationAssigned.id);
            this.schenkerReferenceForm.get('locationName')!.setValue(this.locationAssigned.name);
        } else {
            this.getLocations();
        }
        if (this._currentPayment && this._currentPayment.schenkerCustomerReference) {
            this.schenkerReferenceForm.patchValue(this._currentPayment.schenkerCustomerReference);
            this.type && !this.hasExternalLookup ? this.type.setValidators([Validators.required]) : '';
        }
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.schenkerReferenceForm = this._formBuilder.group({
            costCenter: new FormControl<string | null>(null),
            voucher: new FormControl<string | null>(null),
            paymentAmount: new FormControl<string | null>(null),
            type: new FormControl<string | null>(null),
            locationId: new FormControl<string | null>(null),
            locationName: new FormControl<string | null>(null),
        });
        this.schenkerReferenceForm = this._paymentDetailService.validateCompanyForm(this.schenkerReferenceForm);
        this.schenkerReferenceForm.updateValueAndValidity();
    }

    /**
     * @method costCenter()
     * @description: Convenience getter for easy access to form fields
     */

    get costCenter(): FormGroup {
        return this.schenkerReferenceForm.get('costCenter') as FormGroup;
    }

    /**
     * @method voucher()
     * @description: Convenience getter for easy access to form fields
     */

    get voucher(): FormGroup {
        return this.schenkerReferenceForm.get('voucher') as FormGroup;
    }

    /**
     * @method locationName()
     * @description: Convenience getter for easy access to form fields
     */

    get locationName(): FormGroup {
        return this.schenkerReferenceForm.get('locationName') as FormGroup;
    }

    /**
     * @method locationId()
     * @description: Convenience getter for easy access to form fields
     */

    get locationId(): FormGroup {
        return this.schenkerReferenceForm.get('locationId') as FormGroup;
    }

    /**
     * @method setLocationId()
     * @param (value: any)
     * @description Convenience setter for easy access to form fields
     */
    set setLocationId(value: any) {
        this.locationId!.setValue(value);
    }

    /**
     * @method setLocationName()
     * @param (value: any)
     * @description Convenience setter for easy access to form fields
     */
    set setLocationName(value: any) {
        this.locationName!.setValue(value);
    }

    /**
     * @method paymentAmount()
     * @description: Convenience getter for easy access to form fields
     */

    get paymentAmount(): FormGroup {
        return this.schenkerReferenceForm.get('paymentAmount') as FormGroup;
    }

    /**
     * @method type()
     * @description: Convenience getter for easy access to form fields
     */

    get type(): FormGroup {
        return this.schenkerReferenceForm.get('type') as FormGroup;
    }

    /**
     * @method getSchenkerValues()
     * @description
     */

    getSchenkerValues(): void {
        if (this.schenkerReferenceForm.invalid) {
            this.schenkerReferenceForm.markAllAsTouched();
        } else {
            if (
                this._currentPayment.id &&
                this._currentPayment.schenkerCustomerReference.paymentAmount !==
                    this.schenkerReferenceForm.get('paymentAmount')?.value
            ) {
                this._currentPayment.externalData
                    ? (this._currentPayment.externalData.type = this.schenkerReferenceForm.get('paymentAmount')!.value)
                    : '';
                this._paymentFluxService.setData('schenkerCustomerReference', this.schenkerReferenceForm.getRawValue());
                Swal.fire({
                    title: 'Important !',
                    icon: 'info',
                    text: 'You have changed some key information! Please review your request before continuing.',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#14bb9c',
                    allowOutsideClick: false,
                }).then((): void => {
                    this._stepperService[StepperSteps.STEP_ZERO].next(true);
                    this._router.navigate(['/admin/facilityPayments/newPayment/flux/paymentDetails']);
                });
            } else {
                this._stepperService[StepperSteps.STEP_ZERO].next(true);
                if (this.onEdition) {
                    this._stepperService[StepperSteps.STEP_FIRST].next(true);
                    this._stepperService[StepperSteps.STEP_SECOND].next(true);
                }
                this._paymentFluxService.setData('schenkerCustomerReference', this.schenkerReferenceForm.getRawValue());
                this._router.navigate([this.urlNextStep], { relativeTo: this._activatedRoute });
            }
        }
    }

    /**
     * @method getLocations()
     * @description
     */

    getLocations(): void {
        const url: string = this._paymentsURL + '/customer/approval-flow/information';
        this._restService.get<{ locations: Locations[] }>(url, {}).then((result): void => {
            this.locations = result.locations;
        });
    }

    /**
     * @method getLocations()
     * @description
     */

    locationSelected(item: any, event: any): void {
        if (event.isUserInput) {
            this.schenkerReferenceForm.get('locationName')!.setValue(item.name);
        }
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method validateCostCenter()
     * @param (event: any)
     * @description
     */

    validateCostCenter(event: any): void {
        if (regex.alphanumeric.test(event.target.value) && event.target.value.length > 0) {
            const url: string =
                this._paymentsURL +
                '/isValidCostCenter?name=' +
                encodeURIComponent(event.target.value) +
                '&company=Schenker';
            this._restService.get(url, {}).then((result: any): void => {
                !result.valid ? this.costCenter.setErrors({ incorrect: true }) : '';
            });
        }
    }

    /**
     * @method validateVoucherNumber()
     * @param (event: any)
     * @description
     */

    validateVoucherNumber(event: any): void {
        const voucherValue: string = event.target.value;
        let voucherNumberExp: RegExp = new RegExp(regex.schenkerVoucher);
        if (!voucherNumberExp.test(voucherValue)) {
            this.voucher.setErrors({ incorrect: true });
        }
    }
}
