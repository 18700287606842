import { Injectable, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { StandardReferenceComponent } from '../pages/admin/facility-payments/new-payment/payment-flux/standard-reference/standard-reference.component';
export type CustomerReferenceFormGroupType = FormGroup<any>;
export type PaymentDetailsFormGroupType = FormGroup<any>;

@Injectable({
    providedIn: 'root',
})
export class CodaFluxFormsService {
    @ViewChild(StandardReferenceComponent) standardReferenceComponent!: StandardReferenceComponent;
    private _customerReferenceForm = new BehaviorSubject<CustomerReferenceFormGroupType | null>(null);
    private _customerReferenceFormTouched = new BehaviorSubject<boolean>(false);
    private _paymentDetailsForm = new BehaviorSubject<PaymentDetailsFormGroupType | null>(null);
    private _paymentDetailsFormTouched = new BehaviorSubject<boolean>(false);

    constructor() {}

    setCustomerReferenceForm(form: FormGroup): void {
        this._customerReferenceForm.next(form);
    }

    getCustomerReferenceForm(): Observable<any> {
        return this._customerReferenceForm.asObservable();
    }

    setPaymentDetailsForm(form: FormGroup): void {
        this._paymentDetailsForm.next(form);
    }

    getPaymentDetailsForm(): Observable<any> {
        return this._paymentDetailsForm.asObservable();
    }

    setCustomerReferenceFormAsTouched(touched: boolean): void {
        this._customerReferenceFormTouched.next(touched);
    }

    getCustomerReferenceAsTouched(): Observable<boolean> {
        return this._customerReferenceFormTouched.asObservable();
    }

    setPaymentDetailsFormAsTouched(touched: boolean): void {
        this._paymentDetailsFormTouched.next(touched);
    }

    getPaymentDetailsFormAsTouched(): Observable<boolean> {
        return this._paymentDetailsFormTouched.asObservable();
    }
}
