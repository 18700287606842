import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Customer, DynamicField } from '@cargos/sprintpay-models';
import { DynamicFieldsService } from '@cargos/sprintpay-services';
import { Observable, Subject, filter, map, take, takeUntil } from 'rxjs';
import { Facility } from 'src/app/models/payments/facility.model';
import { CartService } from 'src/app/services/utils/cart.service';
import { StepperNavigationLink, StepperSteps } from 'src/app/utils/stepperTypes';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { environment } from '../../../../../../environments/environment';
import { RestService } from '../../../../../services/rest.service';
import { CustomerService } from '../../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../../services/utils/payment-flux.service';
import { SessionService } from '../../../../../services/utils/session.service';
import { companyNameForCoda } from '../../../../../utils/constants';
import { StepperService } from '../payment-flux/services/stepper.service';

@Component({
    selector: 'app-coda-facility',
    templateUrl: './coda-facility.component.html',
    providers: [StepperService],
})
export class CodaFacilityComponent implements OnInit, OnDestroy {
    selectedIndex: number = 0;
    companyNameObj: Object = companyNameForCoda;
    companyName: string;
    companyCodaFlux: boolean = false;
    isDynamicCompany: boolean = false;
    hasDynamicFieldsFirstSection: boolean = false;
    facilityData!: Facility;
    stepperOrientation: Observable<StepperOrientation>;
    navigationLinks: StepperNavigationLink[] = [];
    selectedStepIndex: number = 0;
    steps: boolean[] = [];
    labelsSteps: string[] = [];
    private _customer: Customer;
    private _bootstrap_lg_screen_size: number = 992;
    private _currentPayment: any;
    private _unsubscribe$: Subject<void> = new Subject<void>();
    private readonly _cartUrl: string = environment.uris.method.cart;

    @ViewChild('stepper') stepper!: MatStepper;

    @Output() returnToFacilities: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _paymentFluxService: PaymentFluxService,
        private _customerService: CustomerService,
        private _router: Router,
        private _breakpointObserver: BreakpointObserver,
        private _sessionService: SessionService,
        private dynamicService: DynamicFieldsService,
        private _restService: RestService,
        private _stepperService: StepperService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _cartService: CartService
    ) {
        this._customer = this._customerService.getCustomer();
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.companyName = this._customerService.getCompanyName();
        this.isDynamicCompany = this._customerService.isDynamicCompany();
        this.companyCodaFlux = Object.keys(this.companyNameObj).indexOf(this.companyName.toLowerCase()) !== -1;
        this.stepperOrientation = _breakpointObserver
            .observe(`(min-width: ${this._bootstrap_lg_screen_size}px)`)
            .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
        this.facilityData = this._currentPayment?.facility;
    }

    ngOnInit(): void {
        this.getConfiguration();
        this._cartService.getCart();
    }

    createSteppers(): void {
        if (this._currentPayment?.step) {
            this.selectedStepIndex = this._currentPayment.step;
            this.selectedIndex = this._currentPayment.step;
        } else {
            const redirectUrl = !this.companyName
                ? '/admin/facilityPayments/newPayment/codafacility/paymentDetails'
                : this.companyCodaFlux
                  ? 'admin/facilityPayments/newPayment/codafacility/' +
                    encodeURIComponent(this.companyName.toLowerCase() + 'Reference')
                  : this.isDynamicCompany && this.hasDynamicFieldsFirstSection
                    ? 'admin/facilityPayments/newPayment/codafacility/' +
                      encodeURIComponent(this.companyName.toLowerCase()) +
                      '/Reference'
                    : '/admin/facilityPayments/newPayment/codafacility/paymentDetails';
            this._router.navigate([redirectUrl]);
        }
    }

    ngOnDestroy(): void {
        this._stepperService.resetSteps();
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }
    /**
     * @method createNavigationLinks()
     * @description Function to check if a user is dynamic and get the labels
     */
    createNavigationLinks(): void {
        const companyId = this._customerService.getCompanyId();
        this.labelsSteps = [
            this.companyCodaFlux || (this.isDynamicCompany && this.hasDynamicFieldsFirstSection)
                ? `${this.companyName} Customer Reference`
                : '',
            this.companyCodaFlux || (this.isDynamicCompany && this.hasDynamicFieldsFirstSection)
                ? 'Payment Details'
                : '',
        ];
        if (this.isDynamicCompany && companyId && this.hasDynamicFieldsFirstSection) {
            const url: string = `${environment.uris.method.payments}/company/dynamic-sections?company=${companyId}`;
            this._restService.get(url, {}).then((result: any): void => {
                if (result.length > 0) {
                    this.labelsSteps = result
                        .map((item: any) => item)
                        .filter((item: any) => item.label !== 'Customer Reference')
                        .sort((a: any, b: any) => a.orderNumber - b.orderNumber)
                        .map((itemSort: any) =>
                            itemSort.label
                                .split(' ')
                                .map((label: string) => {
                                    if (label.length) {
                                        return label[0].toUpperCase() + label.substring(1);
                                    }
                                    return label;
                                })
                                .toString()
                                .replace(/,/g, ' ')
                        );
                    this.buildNavigationLinks();
                }
                this.buildNavigationLinks();
            });
        } else {
            this.buildNavigationLinks();
        }
    }

    /**
     * @method buildNavigationLinks()
     * @description Function to build navigation labels
     */
    buildNavigationLinks(): void {
        this.navigationLinks = [
            {
                label: this.labelsSteps[0] || 'Payment Details',
                link:
                    this.companyName && this.hasDynamicFieldsFirstSection
                        ? `./${encodeURIComponent(this.companyName.toLocaleLowerCase())}${
                              this.companyNameObj.hasOwnProperty(this.companyName.toLocaleLowerCase()) ? '' : '/'
                          }Reference`
                        : './paymentDetails',
                index: 0,
                formName: `${this.companyName.toLocaleLowerCase()}ReferenceForm`,
            },
            {
                label: this.labelsSteps[1] || 'Payment Summary',
                link: this.companyName && this.hasDynamicFieldsFirstSection ? './paymentDetails' : './summary',
                index: 1,
                formName: 'paymentForm',
            },
        ];
        this.companyCodaFlux || (this.isDynamicCompany && this.hasDynamicFieldsFirstSection)
            ? this.navigationLinks.push({
                  label: this.labelsSteps[4] ?? 'Payment Summary',
                  link: './summary',
                  index: 2,
                  formName: '',
              })
            : '';
        if (this._currentPayment && this._currentPayment.step && this.stepper) {
            setTimeout(() => {
                this.stepper!.linear = false;
                this.stepper.selectedIndex = this._currentPayment.step;
            }, 1);
        }
        this._subscribeRouteChange();
    }

    /**
     * @method _subscribeStepsComplited()
     * @description Subscriptions on stepper service to toggle stepper steps completion
     */

    private _subscribeStepsComplited(): void {
        this._stepperService[StepperSteps.STEP_ZERO]
            .pipe(
                takeUntil(this._unsubscribe$),
                map((isStepComplited: boolean): void => {
                    this.steps[0] = isStepComplited;
                    this._changeDetectorRef.detectChanges();
                })
            )
            .subscribe();

        this._stepperService[StepperSteps.STEP_FIRST]
            .pipe(
                takeUntil(this._unsubscribe$),
                map((isStepComplited: boolean): void => {
                    this.steps[1] = isStepComplited;
                    this._changeDetectorRef.detectChanges();
                })
            )
            .subscribe();

        this._stepperService[StepperSteps.STEP_SECOND]
            .pipe(
                takeUntil(this._unsubscribe$),
                map((isStepComplited: boolean): void => {
                    this.steps[2] = isStepComplited;
                    this._changeDetectorRef.detectChanges();
                })
            )
            .subscribe();
    }

    /**
     * @method _subscribeRouteChange()
     * @description Watch for route change to select proper stepper step
     */

    private _subscribeRouteChange(): void {
        this._router.events
            .pipe(
                takeUntil(this._unsubscribe$),
                filter((event): event is NavigationEnd => event instanceof NavigationEnd),
                map(({ url }): void => {
                    const paths: string[] = url.split('/');
                    const targetIndex: number = this.navigationLinks.findIndex(
                        (link): boolean => link?.link === `./${paths[paths.length - 1]}`
                    );
                    if (targetIndex !== -1) {
                        this.selectedIndex = targetIndex;
                    }
                    if (url === '/admin/facilityPayments/newPayment/codafacility') {
                        this._router.navigate(['/admin/facilityPayments/newPayment']);
                    }
                })
            )
            .subscribe();
    }

    /**
     * @method stepChanged()
     * @param (event: any)
     * @description Handler for change stepper step event
     */

    stepChanged(event: any): void {
        this.selectedStepIndex = event.selectedIndex;
        this.selectedIndex = event.selectedIndex;
        this._router.navigate([this.navigationLinks[this.selectedIndex].link], {
            relativeTo: this._activatedRoute,
        });
    }

    /**
     * @method returnToFacility()
     * @description
     */

    returnToFacility(): void {
        Swal.fire({
            title: '¡W A R N I N G!',
            text: 'Your payment request will be restarted, Are you sure?',
            icon: 'warning',
            confirmButtonText: 'Restart payment',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'No',
        }).then((value: SweetAlertResult<any>): void => {
            if (value.value && value.value === true) {
                let files =
                    this._currentPayment.details &&
                    this._currentPayment.details.nameFiles &&
                    this._currentPayment.details.nameFiles.split(',');
                if (files) {
                    files.forEach((element: any): void => {
                        this._restService.delete(
                            this._cartUrl + '/removePaymentRequestAttachment' + '?attachmentLocation=' + element
                        );
                    });
                }
                this._clearInformation();
                this._sessionService.removeElement('payments_selected');
                this._router.navigate(['/admin/facilityPayments/newPayment']);
            }
        });
    }

    /**
     * @method _clearInformation()
     * @description
     */

    private _clearInformation(): void {
        if (this._currentPayment && !this._currentPayment.id) {
            this._paymentFluxService.removeCurrentPayment();
            this._paymentFluxService.removeDynamicFields();
            this._paymentFluxService.removeDynamicValues();
            this._cartService.reset3rdPartyItems();
            this._cartService.resetSubmittedItems();
            this._sessionService.removeElement('HBL');
        }
    }

    getConfiguration(): void {
        if (this.isDynamicCompany) {
            this.dynamicService
                .getDynamicFieldsBySection(0)
                .pipe(take(1))
                .subscribe({
                    next: (response: DynamicField[]) => {
                        this.hasDynamicFieldsFirstSection =
                            !!response.length || !this._customer?.approvalLevels?.company?.firstLocation;
                        this.createSteppers();
                        this.createNavigationLinks();
                        this._subscribeStepsComplited();
                    },
                });
        } else {
            this.createSteppers();
            this.createNavigationLinks();
            this._subscribeStepsComplited();
        }
    }
}
