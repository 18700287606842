<app-breadcrumb></app-breadcrumb>
<div id="home" class="row">
    <div class="col-12">
        <h4 class="heading pt-4">Home</h4>
    </div>
    <ng-container *ngxPermissionsOnly="facilitySecurity">
        <div class="col-12">
            <button
                *ngIf="showNewPayment"
                mat-button
                type="button"
                class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green me-4 mb-3 admin__toolbar--btnPayment"
                data-cy="toolbar_btnPayment"
                routerLink="../facilityPayments/newPayment"
            >
                New Payment
            </button>
        </div>
    </ng-container>
    <app-card-wallet *ngxPermissionsOnly="walletSecurity"></app-card-wallet>
    <app-facilities *ngxPermissionsOnly="facilitySecurity"></app-facilities>
    <ng-container *ngIf="!hasCompany">
        <app-card-invoice *ngxPermissionsOnly="openInvoiceSecurity"></app-card-invoice>
    </ng-container>
    <app-rejected-request *ngxPermissionsOnly="rejectedRequestSecurity"></app-rejected-request>
    <app-pending-request *ngxPermissionsOnly="pendingApprovalSecurity"></app-pending-request>
    <ng-container *ngIf="isNewUser">
        <app-new-user></app-new-user>
    </ng-container>
</div>
