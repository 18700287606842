import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { FacilityPaymentsRoutingModule } from './facility-payments-routing.module';

//COMPONENTS

import { FacilityPaymentsComponent } from './facility-payments.component';

//MODULES

import { NgxPermissionsModule } from 'ngx-permissions';
import { SharedModule } from '../../../shared/shared.module';
import { NewPaymentModule } from './new-payment/new-payment.module';
import { PaymentHistoryModule } from './payment-history/payment-history.module';
import { PaymentMethodsModule } from './payment-methods/payment-methods.module';
import { PendingApprovalModule } from './pending-approval/pending-approval.module';
import { RejectedPaymentModule } from './rejected-payment/rejected-payment.module';
import { UploadFileByPortModule } from './upload-file-by-port/upload-file-by-port.module';
import { UploadRequestModule } from './upload-request/upload-request.module';
import { VoidedPaymentsModule } from './voided-payments/voided-payments.module';

@NgModule({
    declarations: [FacilityPaymentsComponent],
    imports: [
        CommonModule,
        FacilityPaymentsRoutingModule,
        NgxPermissionsModule.forChild(),
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        NewPaymentModule,
        PaymentHistoryModule,
        PaymentMethodsModule,
        PendingApprovalModule,
        RejectedPaymentModule,
        VoidedPaymentsModule,
        UploadRequestModule,
        UploadFileByPortModule,
    ],
})
export class FacilityPaymentsModule {
    constructor() {}
}
