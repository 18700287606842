<!--START: PAYMENT NOTIFICATIONS -->
<section id="payment_status">
    <div id="payment_status__container" class="container-fluid notifications">
        <div class="mb-4 py-2">
            <!--START: LOCATION NON-EDITABLE SUMMARY -->
            <ng-container *ngIf="section === 'SUMMARY'">
                <div class="row">
                    <div class="col-12">
                        <div class="p-2">
                            <p id="payment_status__container--location">
                                Note: The Location field is non-editable.<br />
                                <span
                                    >If your request needs an update to the “location” field, please fully void this
                                    request, and then start the process to create a new request from the homepage.</span
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--END: LOCATION NON-EDITABLE SUMMARY -->

            <!--START: LOCATION NON-EDITABLE PENDING -->
            <ng-container *ngIf="section === 'PENDING'">
                <div class="row">
                    <div class="col-12">
                        <div class="p-2">
                            <p id="payment_status__container--location">
                                Note: The Location field is non-editable. <br />
                                <span
                                    >In order to proceed, please void this request and then start the process to create
                                    a new request from the homepage.</span
                                >
                            </p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--END: LOCATION NON-EDITABLE SUMMARY -->
        </div>
    </div>
</section>
<!--END: PAYMENT NOTIFICATIONA -->
