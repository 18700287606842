<!--START: ADD ONE OR MORE CONTAINER -->
<form class="row" [formGroup]="paymentForm">
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>{{dynamicField ? dynamicField.label : labelForInput}}</mat-label>
            <mat-chip-grid #hawbGrid aria-label="Enter - container #" multiple>
                @for (hawbItem of hawbItems; track hawbItem) {
                <mat-chip (removed)="remove(hawbItem)">
                    {{hawbItem}}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
                }
                <input
                    #hawbInput
                    matInput
                    formControlName="hawb"
                    placeholder="{{dynamicField ? dynamicField.label : labelForInput}}"
                    [matChipInputFor]="hawbGrid"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event)"
                    [errorStateMatcher]="matcher"
                    style="width: 275px"
                />
            </mat-chip-grid>
            <mat-error *ngIf="paymentForm.controls['hawb'].hasError('preventedHTMLStr')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>{{dynamicField?.validations?.required?.errorMessage
                ? dynamicField.validations.required.errorMessage : 'HTML tags or URLs are not allowed'}}
            </mat-error>
        </mat-form-field>
    </div>
</form>
<!--END: ADD ONE OR MORE CONTAINER -->
