<div class="row mb-4" *ngIf="paymentDetails && values">
    <!-- START: DETAIL TITLE -->

    <app-details-header>
        <span class="my-3"><strong>Payment details</strong></span>
        <ng-container
            *ngIf="((paymentDetails?.approvalStatus === 'PENDING' && paymentDetails?.uniqueRequestor && isStartingLevel && !paymentDetails?.ownerCanPay && paymentDetails?.sameCustomerAndRequestor) || (paymentDetails?.approvalStatus === 'REJECTED' && isStartingLevel && paymentDetails?.sameCustomerAndRequestor) || (paymentDetails?.id === null) || (paymentDetails?.id !== null && !!paymentDetails?.fromCart)) && paymentDetails.sprintPaySource && !hasExternalLookup"
        >
            <button mat-button class="my-2 px-0 text-uppercase text-decoration-none" (click)="editPaymentRequest()">
                Edit
            </button>
        </ng-container>
    </app-details-header>
    <!-- END: DETAIL TITLE -->
    <!-- START: DETAIL ELEMENTS -->
    <ng-container *ngFor="let item of values;">
        <app-details-field *ngIf="item.show" class="col-12 col-md-4" [label]="item.label" [value]="item.value">
        </app-details-field>
    </ng-container>
    <!-- END: DETAIL ELEMENTS -->
    <!-- START: DYNAMIC FIELDS -->
    <ng-container *ngFor="let key of keys();">
        <app-details-field class="col-12 col-md-4" [label]="key" [value]="dynamicValuesDetails[key]">
        </app-details-field>
    </ng-container>
    <!-- END: DYNAMIC FIELDS -->
    <!-- START: ATTACHMENT DOWNLOADED LINK -->
    <app-files-container
        [filesPath]="files"
        [title]="dynamicLabels?.nameFiles ? dynamicLabels.nameFiles : 'Attach File'"
    ></app-files-container>
    <!-- END: ATTACHMENT DOWNLOADED LINK -->
</div>
