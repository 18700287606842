import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, FormGroup, Validators } from '@angular/forms';
import { Subject, of, switchMap, takeUntil } from 'rxjs';

import { MatRadioChange } from '@angular/material/radio';
import { CompanyDetails } from 'src/app/models/utils/company';
import { accountTypeItems } from 'src/app/utils/constants';
import { restrictedBranch } from 'src/app/validators/restricted-branch';
import { Countries as countries } from '../../../../../models/utils/countries.model';
import { AddressInformationFormType } from '../../../../../models/utils/forms/address-information-form';
import { ErrorMatcher } from '../../../../../utils/errorMatcher';
import { FormSignupService } from '../../services/form-signup.service';

@Component({
    selector: 'app-step-two',
    templateUrl: './step-two.component.html',
})
export class StepTwoComponent implements OnInit, OnDestroy, AfterContentChecked {
    public countries!: countries[];
    public matcher: ErrorMatcher;
    public accountForm!: FormGroup;
    public addAddtionalAddress: boolean;
    public emailHasDomain!: boolean;
    public brachLocations: CompanyDetails[];
    public selectedBranchLocation!: CompanyDetails | undefined;
    public accountTypes = accountTypeItems;
    private _unsubscribe$: Subject<void>;

    constructor(
        private _controlContainer: ControlContainer,
        private _formSignupService: FormSignupService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.matcher = new ErrorMatcher();
        this.addAddtionalAddress = false;
        this._unsubscribe$ = new Subject<void>();
        this.brachLocations = [];
    }

    ngOnInit(): void {
        this.accountForm = this._controlContainer.control as FormGroup;
        this.addAddtionalAddress = this.accountForm.controls['billingAddress'].value;
        if (!this.addAddtionalAddress) {
            this._setBilingAddressValidators();
        }
        this.subscribeEmailDomain();
        this.onChangesUseBillingAddress();
    }

    ngAfterContentChecked(): void {
        this._changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    get billingAddress(): AbstractControl<string> | null {
        return this.accountForm.get('billingAddress');
    }
    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.id;
    }

    subscribeEmailDomain(): void {
        this._formSignupService
            .getEmailDomainExist()
            .pipe(
                switchMap((emailHasDomain: boolean) => {
                    this.emailHasDomain = emailHasDomain;
                    if (!emailHasDomain) {
                        return of(null);
                    }
                    return this._formSignupService.getBrachLocation();
                }),
                takeUntil(this._unsubscribe$)
            )
            .subscribe({
                next: (completeBranches: CompanyDetails[] | null) => {
                    if (this.emailHasDomain && completeBranches) {
                        const { parentCompany, branches } = this.getBranchesAndParentCompany(completeBranches);
                        this.brachLocations = branches || [];
                        this.companyNameControl?.setValue(parentCompany?.accountName || '');
                        this.companyIdControl?.setValue(parentCompany?.id || -1);
                        this.setAccountType({ value: true } as MatRadioChange);
                        // this.companyNameControl?.disable();
                        if (this.brachLocations.length) {
                            this.branchLocationControl?.addValidators([Validators.required, restrictedBranch()]);
                            this.accountForm.updateValueAndValidity();
                        }
                        this.clearValidators();
                        this._clearBillingAddressValidators();
                        this.updateValueAndValidityAccountForm();
                        this.setAccountRestricted(parentCompany?.accountSignUpRestricted || false);
                    }
                },
            });
    }

    clearValidators(): void {
        this.accountForm.controls['street'].clearValidators();
        this.accountForm.controls['city'].clearValidators();
        this.accountForm.controls['state'].clearValidators();
        this.accountForm.controls['country'].clearValidators();
        this.accountForm.controls['zipCode'].clearValidators();
    }

    updateValueAndValidityAccountForm(): void {
        this.accountForm.controls['street'].updateValueAndValidity();
        this.accountForm.controls['city'].updateValueAndValidity();
        this.accountForm.controls['state'].updateValueAndValidity();
        this.accountForm.controls['country'].updateValueAndValidity();
        this.accountForm.controls['zipCode'].updateValueAndValidity();
        this.accountForm.controls['billingStreet'].updateValueAndValidity();
        this.accountForm.controls['billingCity'].updateValueAndValidity();
        this.accountForm.controls['billingState'].updateValueAndValidity();
        this.accountForm.controls['billingCountry'].updateValueAndValidity();
        this.accountForm.controls['billingZipCode'].updateValueAndValidity();
    }

    private _setBilingAddressValidators(): void {
        this.accountForm.controls['billingStreet'].addValidators([Validators.required]);
        this.accountForm.controls['billingCity'].addValidators([Validators.required]);
        this.accountForm.controls['billingState'].addValidators([
            Validators.required,
            Validators.maxLength(2),
            Validators.minLength(2),
        ]);
        this.accountForm.controls['billingCountry'].addValidators([Validators.required]);
        this.accountForm.controls['billingZipCode'].addValidators([Validators.required]);
    }

    private _clearBillingAddressValidators(): void {
        this.accountForm.controls['billingStreet'].clearValidators();
        this.accountForm.controls['billingCity'].clearValidators();
        this.accountForm.controls['billingState'].clearValidators();
        this.accountForm.controls['billingCountry'].clearValidators();
        this.accountForm.controls['billingZipCode'].clearValidators();
    }

    onChangesUseBillingAddress(): void {
        this.billingAddress?.valueChanges.pipe(takeUntil(this._unsubscribe$)).subscribe((valueChanges) => {
            this.addAddtionalAddress = !!valueChanges;
            if (!this.addAddtionalAddress) {
                this.addAddtionalAddress = false;
                this.accountForm.patchValue({
                    billingStreet: '',
                    billingStreet2: '',
                    billingCity: '',
                    billingState: '',
                    billingCountry: '',
                    billingZipCode: '',
                });
                this._setBilingAddressValidators();
            } else {
                this._clearBillingAddressValidators();
            }
            this.updateValueAndValidityAccountForm();
        });
    }

    next(): void {
        if (this.addAddtionalAddress) {
            this.accountForm.patchValue({
                billingStreet: this.accountForm.controls['street'].value,
                billingStreet2: this.accountForm.controls['street2'].value,
                billingCity: this.accountForm.controls['city'].value,
                billingState: this.accountForm.controls['state'].value,
                billingCountry: this.accountForm.controls['country'].value,
                billingZipCode: this.accountForm.controls['zipCode'].value,
            });
            this.accountForm.updateValueAndValidity();
        }
    }

    setAddress(address: AddressInformationFormType): void {
        this.accountForm.patchValue({
            street: address.street,
            street2: address.street2,
            city: address.city,
            state: address.state,
            country: address.country,
            zipCode: address.zipCode,
        });
        this.accountForm.updateValueAndValidity();
    }

    setBillingAddress(address: AddressInformationFormType): void {
        this.accountForm.patchValue({
            billingStreet: address.street,
            billingStreet2: address.street2,
            billingCity: address.city,
            billingState: address.state,
            billingCountry: address.country,
            billingZipCode: address.zipCode,
        });
        this.accountForm.updateValueAndValidity();
    }

    saveInformation(): void {
        const branchLocation = this.branchLocationControl?.value;
        const accountForm = { ...this.accountForm.getRawValue(), branchLocation };
        this._formSignupService.setAccountInformation(accountForm);
    }

    getBranchesAndParentCompany(completeBranches: CompanyDetails[]): {
        branches: CompanyDetails[];
        parentCompany: CompanyDetails | undefined;
    } {
        const parentCompany = completeBranches.find((branch) => branch.isParent);

        let branches =
            completeBranches.length === 1
                ? completeBranches.filter((branch) => branch.id !== parentCompany?.id)
                : completeBranches;

        if (branches?.length > 1) {
            branches = branches.map((branch) =>
                branch.id === parentCompany?.id ? { ...branch, accountName: 'Headquarter' } : branch
            );
        }

        return {
            branches,
            parentCompany,
        };
    }

    get branchLocationControl(): AbstractControl<CompanyDetails> | null {
        return this.accountForm.get('branchLocation');
    }

    get companyNameControl(): AbstractControl<string> | null {
        return this.accountForm.get('companyName');
    }

    get companyIdControl(): AbstractControl<number> | null {
        return this.accountForm.get('companyId');
    }

    get selectedAccountType(): number | null {
        return this.accountForm?.get('accountType')?.value !== '' ? +this.accountForm?.get('accountType')?.value : null;
    }

    setAccountType(accountType: MatRadioChange): void {
        if (accountType.value) {
            this.companyNameControl?.setValidators(Validators.required);
        } else {
            this.companyNameControl?.removeValidators(Validators.required);
        }
        this.companyNameControl?.updateValueAndValidity();
    }

    setAccountRestricted(isRestricted: boolean): void {
        this.companyNameControl?.markAsUntouched();

        if (isRestricted) {
            this.companyNameControl?.setErrors({ restrictedBranch: true });
        }
        this.companyNameControl?.markAsTouched();
        this._changeDetectorRef.detectChanges();
    }
}
