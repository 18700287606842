<!-- START: ATTACHMENT DOWNLOADED LINK -->
<ng-container *ngIf="nameFiles?.length">
    <div class="col-12">
        <p class="card-subtitle me-2">
            <small>{{title ? title : 'Attach File'}}</small>
        </p>
        <app-file [filesPath]="files" [nameFiles]="nameFiles"></app-file>
    </div>
</ng-container>
<!-- END: ATTACHMENT DOWNLOADED LINK -->
