import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CustomStatuses, RefundStatusIcon } from 'src/app/models/refunds/refunds';
import { PipesSharedModule } from 'src/app/pipes/pipes.module';

@Component({
    selector: 'app-refund-status',
    standalone: true,
    templateUrl: './refund-status.component.html',
    imports: [CommonModule, MatIconModule, PipesSharedModule],
})
export class RefundStatusComponent {
    customStatus = CustomStatuses;

    @Input() status: CustomStatuses = {} as CustomStatuses;

    constructor() {}

    getIcon(): string {
        return RefundStatusIcon[this.status];
    }
}
