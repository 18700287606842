import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';

// COMPONENTS

import { UsersComponent } from './users.component';
import { permissionsName } from '../../../utils/constants';

const routes: Routes = [
    {
        path: '',
        title: 'SprintPay | Users',
        component: UsersComponent,
        canLoad: [NgxPermissionsGuard],
        data: {
            permissions: {
                only: [permissionsName.knRequestor],
                except: [permissionsName.ordinary],
                redirectTo: '/admin/home',
            },
            breadcrumb: { alias: 'users' },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UsersRoutingModule {}
