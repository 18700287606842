import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';

@Component({
    selector: 'app-loader',
    standalone: true,
    imports: [CommonModule, NgxSpinnerModule],
    templateUrl: './loader.component.html',
})
export class LoaderComponent {
    constructor() {}
}
