<!--START: DYNAMIC RADIO BUTTON FIELD-->
<form [formGroup]="dynamicFormGroup" class="row">
    <div class="col-12 col-lg-9">
        <div class="mb-3">
            <mat-label>{{field.label}}</mat-label>
            <mat-radio-group
                class="d-flex flex-column w-100 mt-2"
                [id]="field.name"
                [name]="field.name"
                [formControlName]="field.name"
                [required]="dynamic_required"
            >
                <mat-radio-button
                    class="d-inline-block"
                    color="primary"
                    *ngFor="let option of field.options"
                    [value]="option.value"
                >
                    {{option.displayValue}}
                </mat-radio-button>
            </mat-radio-group>
            <!--START: ERRORS-->
            <mat-error
                *ngIf="dynamicFormGroup.controls[field.name].invalid && (dynamicFormGroup.controls[field.name].dirty || dynamicFormGroup.controls[field.name].touched)"
            >
                <mat-error *ngIf="dynamicFormGroup.controls[field.name].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i
                    >{{field.inputValidations?.['required']?.errorMessage || 'This field it\'s required'}}
                </mat-error>
            </mat-error>
            <!--END: ERRORS-->
        </div>
    </div>
</form>
<!--END: DYNAMIC RADIO BUTTON FIELD-->
