import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CompanyDetails } from 'src/app/models/utils/company';
import { PasswordFormType } from 'src/app/standalone-components/form-password/models/password-form';
import { AccountFormType } from '../form-signup/models/account-form';
import { PersonalFormType } from '../form-signup/models/personal-form';

@Injectable()
export class FormSignupService {
    private _emailDomainExist: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _email: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private _phoneNumber: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private _countryCode: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private _personalInformation!: PersonalFormType;
    private _accountInformation!: AccountFormType;
    private _securityInformation!: PasswordFormType;
    private _branchLocation: BehaviorSubject<CompanyDetails[]> = new BehaviorSubject<CompanyDetails[]>([]);

    constructor() {}

    setEmailDomainExist(_emailDomainExist: any): void {
        this._emailDomainExist.next(_emailDomainExist);
    }

    getEmailDomainExist(): Observable<boolean> {
        return this._emailDomainExist.asObservable();
    }

    setPersonalInformation(_personalInformation: PersonalFormType): void {
        this._personalInformation = _personalInformation;
    }

    getPersonalInformation(): PersonalFormType {
        return this._personalInformation;
    }

    setAccountInformation(_accountInformation: AccountFormType): void {
        this._accountInformation = _accountInformation;
    }

    getAccountInformation(): AccountFormType {
        return this._accountInformation;
    }

    setSecurityInformation(_securityInformation: PasswordFormType): void {
        this._securityInformation = _securityInformation;
    }

    getSecurityInformation(): PasswordFormType {
        return this._securityInformation;
    }

    setBrachLocation(_branchLocation: CompanyDetails[]): void {
        this._branchLocation.next(_branchLocation);
    }

    getBrachLocation(): Observable<CompanyDetails[]> {
        return this._branchLocation.asObservable();
    }

    setEmail(email: string): void {
        this._email.next(email);
    }

    getEmail(): Observable<string> {
        return this._email.asObservable();
    }

    setPhoneNumber(phone: string): void {
        this._phoneNumber.next(phone);
    }

    getPhoneNumber(): Observable<string> {
        return this._phoneNumber.asObservable();
    }

    setCountryCode(countryCode: string): void {
        this._countryCode.next(countryCode);
    }

    getCountryCode(): Observable<string> {
        return this._countryCode.asObservable();
    }
}
