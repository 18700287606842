import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
    FormControlStatus,
    FormsModule,
    NonNullableFormBuilder,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { GooglePlacesAutocompleteDirective } from '@cargos/sprintpay-services';
import { Subject, takeUntil } from 'rxjs';
import { Countries } from 'src/app/models/utils/countries.model';
import { CartService } from 'src/app/services/utils/cart.service';
import { countriesService } from 'src/app/services/utils/countries.service';
import { ErrorMatcher } from 'src/app/utils/errorMatcher';
import { environment } from 'src/environments/environment';
import {
    AddressInformationFormControls,
    AddressInformationFormGroupType,
    AddressInformationFormType,
} from '../../models/utils/forms/address-information-form';

@Component({
    selector: 'app-address-information',
    templateUrl: './address-information.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        GooglePlacesAutocompleteDirective,
    ],
})
export class AddressInformationComponent implements OnInit, OnDestroy {
    public addressForm!: AddressInformationFormGroupType;
    public matcher: ErrorMatcher;
    public countries!: Countries[];
    @Output() eventOnChangeAddress = new EventEmitter<AddressInformationFormType>();

    private _unsubscribe$: Subject<void>;
    private readonly _authentication: string;

    constructor(
        private _formBuilder: NonNullableFormBuilder,
        private _cartService: CartService,
        private _countriesService: countriesService
    ) {
        this.matcher = new ErrorMatcher();
        this._unsubscribe$ = new Subject<void>();
        this._authentication = environment.uris.method.authentication;
    }

    ngOnInit(): void {
        this._setFromBuilder();
        this._getCountries();
        this.onChanges();

        if (this._cartService.instant_guest_address_information) {
            this.addressForm.patchValue(this._cartService.instant_guest_address_information);
            Object.entries(this.addressForm.controls).forEach(([key, value]) => {
                if (!!value.value) {
                    this.addressForm.controls[key as keyof AddressInformationFormControls].disable();
                }
            });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    private _setFromBuilder(): void {
        this.addressForm = this._formBuilder.group({
            street: ['', [Validators.required]],
            street2: ['', []],
            city: ['', [Validators.required]],
            state: ['', [Validators.required, Validators.maxLength(2), Validators.minLength(2)]],
            country: ['', [Validators.required]],
            zipCode: ['', [Validators.required]],
        });
    }

    /**
     * @description function that is pending changes that will emit a value each time the form changes
     */
    onChanges(): void {
        this.addressForm.statusChanges
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe((statusChanges: FormControlStatus) => {
                this.eventOnChangeAddress.emit(this.addressForm.getRawValue());
            });
    }

    /**
     * @description Get the place details from the autocomplete object
     * @param (place: google.maps.places.PlaceResult)
     */
    fillInAddress(place: google.maps.places.PlaceResult): void {
        let address1 = '';
        let postcode = '';
        const address: AddressInformationFormType = {
            street: '',
            street2: '',
            country: '',
            zipCode: '',
            state: '',
            city: '',
        };

        if (place?.address_components) {
            for (const component of place.address_components) {
                const componentType = component.types[0];
                switch (componentType) {
                    case 'street_number': {
                        address1 = `${component.long_name} ${address1}`;
                        break;
                    }

                    case 'route': {
                        address1 += component.short_name;
                        address.street = address1;
                        break;
                    }

                    case 'postal_code': {
                        postcode = `${component.long_name}${postcode}`;
                        address.zipCode = postcode.replace(/[- ]/g, '');
                        break;
                    }

                    case 'postal_code_suffix': {
                        postcode = `${postcode}-${component.long_name}`;
                        break;
                    }

                    case 'locality': {
                        address.city = component.long_name;
                        break;
                    }

                    case 'administrative_area_level_1': {
                        address.state = component.short_name;
                        break;
                    }

                    case 'country': {
                        address.country = component.short_name;
                        break;
                    }
                }
            }
            const country = this.getAlphaCodeByName(address.country);
            this.addressForm.patchValue({
                street: address.street,
                city: address.city,
                state: address.state,
                country: address.country,
                zipCode: address.zipCode,
            });
            this.eventOnChangeAddress.emit(this.addressForm.getRawValue());
        }
    }

    /**
     * @method getCountries()
     * @description Return a list of countries for the dropdown
     */
    private _getCountries(): void {
        this._countriesService
            .getCountriesActives()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe({
                next: (result: Countries[]) => {
                    this.countries = result;
                },
            });
    }

    /**
     * @description Function that returns the alpha2Code by name
     * @param (name: string)
     */
    getAlphaCodeByName(name: string): string {
        const country = this.countries.find((country) => country.name === name);
        return country?.alpha2Code || '';
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.id;
    }
}
