import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'app-redirect-column',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './redirect-column.component.html',
})
export class RedirectColumnComponent {
    data!: any;

    constructor() {}
}
