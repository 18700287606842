import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BrandDomainType, ThemeType } from 'src/app/models/ui/themes';
import { CustomerService } from './customer-handler.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private _theme: BehaviorSubject<ThemeType> = new BehaviorSubject<ThemeType>(ThemeType.sprintpay);

    constructor(
        private _storageService: StorageService,
        private _customerService: CustomerService
    ) {
        if (!!this._theme) {
            const themeLocal: ThemeType = this._storageService.getElement('theme') as ThemeType;
            const theme: ThemeType = !!themeLocal ? themeLocal : ThemeType.sprintpay;
            this._theme.next(theme);
        }
    }

    setTheme(theme: ThemeType): void {
        this._storageService.saveElement('theme', theme);
        this._theme.next(theme);
    }

    setAGIMode(): void {
        this._theme.next(ThemeType.agi);
    }

    setSprintpayMode(): void {
        this._theme.next(ThemeType.sprintpay);
    }

    getTheme(): Observable<ThemeType> {
        return this._theme.asObservable();
    }

    get instant_theme(): ThemeType {
        return this._theme.value;
    }

    getThemeBrand(): ThemeType {
        const customer = this._customerService.getCustomer();
        const { emailDomain } = customer || {};

        if (emailDomain === BrandDomainType.agi) {
            return ThemeType.agi;
        } else if (!emailDomain || emailDomain === BrandDomainType.sprintpay) {
            return ThemeType.sprintpay;
        }
        return ThemeType.sprintpay;
    }
}
