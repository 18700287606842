import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//MODULES
import { SharedModule } from '../../../../shared/shared.module';
// ROUTING
import { NewPasswordRoutingModule } from './new-password-routing.module';
//COMPONENTS
import { NewPasswordComponent } from './new-password.component';

@NgModule({
    declarations: [NewPasswordComponent],
    imports: [CommonModule, NewPasswordRoutingModule, RouterModule, ReactiveFormsModule, FormsModule, SharedModule],
})
export class NewPasswordModule {
    constructor() {}
}
