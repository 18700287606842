import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { DynamicFieldDTO } from 'src/app/models/ui/dynamic-field-common.model';

@Component({
    selector: 'app-radio-button',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatRadioModule, MatInputModule],
    templateUrl: './radio-button.component.html',
})
export class RadioButtonComponent implements OnInit {
    dynamic_required!: boolean;

    @Input() field!: DynamicFieldDTO;
    @Input() dynamicFormGroup!: FormGroup;

    constructor() {}

    ngOnInit(): void {
        this._checkValidations();
    }

    /**
     * @method _checkValidations()
     * @description Review and set the validators for the field
     */

    private _checkValidations(): void {
        this.dynamic_required = (this.field?.inputValidations?.['required']?.value as boolean) || false;
    }
}
