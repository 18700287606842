<form [formGroup]="addressForm">
    <!--START: Street address -->
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>Address 1</mat-label>
            <input
                appGooglePlacesAutocomplete
                (eventOnAddressChange)="fillInAddress($event)"
                matInput
                type="text"
                name="street"
                data-cy="street"
                formControlName="street"
                placeholder="Enter your street address"
                [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="addressForm.controls['street'].hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
        </mat-form-field>
    </div>
    <!--END: Street address -->
    <!--START: Street address 2 optional -->
    <ng-container address-optional>
        <div class="form-group">
            <mat-form-field class="d-block" appearance="outline">
                <mat-label>Address 2 (optional)</mat-label>
                <input
                    matInput
                    type="text"
                    name="street2"
                    data-cy="street2"
                    formControlName="street2"
                    placeholder="Enter your address"
                    [errorStateMatcher]="matcher"
                />
            </mat-form-field>
        </div>
    </ng-container>
    <!--END: Street address 2 optional -->
    <!--START: City -->
    <div class="flex-column flex-lg-row d-flex justify-content-between gap-lg-1">
        <div class="form-group col-12 col-lg-6">
            <mat-form-field class="d-block" appearance="outline">
                <mat-label>City</mat-label>
                <input
                    matInput
                    type="phone"
                    name="city"
                    data-cy="city"
                    formControlName="city"
                    placeholder="Enter your city"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="addressForm.controls['city'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: City -->
        <!--START: State -->
        <div class="form-group col-12 col-lg-6">
            <mat-form-field class="d-block" appearance="outline">
                <mat-label>State</mat-label>
                <input
                    matInput
                    type="text"
                    name="state"
                    data-cy="state"
                    formControlName="state"
                    placeholder="Enter your state"
                    [errorStateMatcher]="matcher"
                />
                <mat-hint align="start"><strong>e.g. CA for California</strong></mat-hint>
                <mat-error *ngIf="addressForm.controls['state'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="addressForm.controls['state'].hasError('minlength')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. Should have at least 2 digits
                </mat-error>
                <mat-error *ngIf="addressForm.controls['state'].hasError('maxlength')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Maximum of 2 digits
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <!--END: State -->
    <!--START: Country -->
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>Country</mat-label>
            <mat-select formControlName="country" data-cy="country" [errorStateMatcher]="matcher">
                <mat-option *ngFor="let country of countries; trackBy: trackBy" [value]="country.alpha2Code">
                    {{country.name}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="addressForm.controls['country'].hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
        </mat-form-field>
    </div>
    <!--END: Country -->
    <!--START: Zip code -->
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>Zip code</mat-label>
            <input
                matInput
                type="text"
                name="zip_code"
                data-cy="zip_code"
                formControlName="zipCode"
                placeholder="Enter your zip code"
                [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="addressForm.controls['zipCode'].hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
        </mat-form-field>
    </div>
    <!--END: Zip code -->
</form>
