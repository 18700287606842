import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { NgxPermissionsModule } from 'ngx-permissions';
import { CartRoutingModule } from './cart-routing.module';

//COMPONENTS

import { AddressInformationComponent } from 'src/app/standalone-components/address-information/address-information.component';
import { CompanyFormComponent } from 'src/app/standalone-components/company-form/company-form.component';
import { FormHeaderComponent } from 'src/app/standalone-components/form-header/form-header.component';
import { FormPasswordComponent } from 'src/app/standalone-components/form-password/form-password.component';
import { PersonalInformationFormComponent } from 'src/app/standalone-components/personal-information-form/personal-information-form.component';
import { TermOfUseComponent } from 'src/app/standalone-components/term-of-use/term-of-use.component';
import { VerificationMethodComponent } from 'src/app/standalone-components/verification-method/verification-method.component';
import { AwbFeeComponent } from './awb-fee/awb-fee.component';
import { CartComponent } from './cart.component';
import { EmptyCartComponent } from './empty-cart/empty-cart.component'; //
import { GuestPaymentConfirmationComponent } from './guest-payment-confirmation/guest-payment-confirmation.component'; //
import { AwbSplitComponent } from './invoices-list/awb-split/awb-split.component';
import { InvoicesListComponent } from './invoices-list/invoices-list.component'; //
import { PaymentConfirmationComponent } from './payment-confirmation/payment-confirmation.component'; //
import { PaymentDetailV2Component } from './payment-detail-v2/payment-detail-v2.component';
import { GuestPaymentMethodComponent } from './payment-detail/guest-payment-method/guest-payment-method.component'; //
import { PaymentDetailComponent } from './payment-detail/payment-detail.component'; //
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component'; //
import { PromoCodeComponent } from './promo-code/promo-code.component';
import { TermsOfUseLinkComponent } from './terms-of-use-link/terms-of-use-link.component'; //
//MODULES

import { TwoFactorModule } from 'src/app/modules/two-factor/two-factor.module';
import { SharedModule } from '../../../shared/shared.module';

// SERVICES

import { NgSelectModule } from '@ng-select/ng-select';
import { AuthenticationFluxService } from 'src/app/modules/two-factor/authentication-flux/authentication-flux.service';
import { PaymentMethodsService } from 'src/app/services/payment-methods.service';
import { CartEmailValidationService } from './services/cart-email-validation.service';
import { CartPayService } from './services/cart-pay.service';
import { CartPaymentMethodsService } from './services/cart-payment-methods.service';
import { PaymentConfirmationService } from './services/payment-confirmation.service';

@NgModule({
    declarations: [
        CartComponent,
        InvoicesListComponent,
        PaymentDetailComponent,
        EmptyCartComponent,
        PaymentMethodsComponent,
        PaymentConfirmationComponent,
        GuestPaymentMethodComponent,
        TermsOfUseLinkComponent,
        GuestPaymentConfirmationComponent,
        PromoCodeComponent,
        AwbSplitComponent,
        PaymentDetailV2Component,
    ],
    imports: [
        AwbFeeComponent,
        CommonModule,
        CartRoutingModule,
        NgxPermissionsModule.forChild(),
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        PersonalInformationFormComponent,
        FormPasswordComponent,
        TermOfUseComponent,
        VerificationMethodComponent,
        CompanyFormComponent,
        FormHeaderComponent,
        AddressInformationComponent,
        TwoFactorModule,
        NgSelectModule,
    ],
    providers: [
        PaymentConfirmationService,
        CartPaymentMethodsService,
        CartPayService,
        PaymentMethodsService,
        CartEmailValidationService,
        AuthenticationFluxService,
    ],
})
export class CartModule {
    constructor() {}
}
