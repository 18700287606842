import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CompanyDetails } from 'src/app/models/utils/company';

@Injectable({
    providedIn: 'root',
})
export class BranchSelectorService {
    private branchesSubject: BehaviorSubject<CompanyDetails[]> = new BehaviorSubject<CompanyDetails[]>([]);
    private branchesInstance: Observable<CompanyDetails[]> = this.branchesSubject.asObservable();

    constructor() {}

    get branches(): Observable<CompanyDetails[]> {
        return this.branchesInstance;
    }

    saveBranches(branches: CompanyDetails[]): void {
        this.branchesSubject.next(branches);
    }
}
