import { ComponentType } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, Renderer2, SecurityContext, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, finalize, take, throwError } from 'rxjs';
import { PaymentRequest } from 'src/app/models/payments/payment-request.model';
import { Actions } from 'src/app/models/utils/actions.model';
import { FileAPIService } from 'src/app/services/requests/file-api.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { SessionService } from 'src/app/services/utils/session.service';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../../environments/environment';
import { Table } from '../../../../models/ui/table.model';
import { PaymentService } from '../../../../services/payment.service';
import { RestService } from '../../../../services/rest.service';
import { CustomerService } from '../../../../services/utils/customer-handler.service';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';
import { companyName, paginatorSize, profileTypes } from '../../../../utils/constants';
import { Sections } from '../../../../utils/sections';

@Component({
    selector: 'app-payment-history',
    templateUrl: './payment-history.component.html',
})
export class PaymentHistoryComponent implements OnInit, OnDestroy {
    @ViewChild('refundModal') refundModal?: ComponentType<unknown>;
    @ViewChild('refundModalFromChoice') refundModalFromChoice?: ComponentType<unknown>;

    public noInformation: boolean | undefined;
    public noInformationMessage: string;
    public amTable!: Table[];
    public paymentsCompleted: any;
    public paymentsCompletedOriginal: any;
    public actions: Actions[] = [];
    public actionRefund: Actions = {};
    public recordsSelected: any;
    public sortableColumns: string[];
    public selectedPayment: any;
    public vendorInvoiceNumber?: string;
    public refundURL: string;
    readonly companyName: string;
    readonly startingLevel: boolean;
    readonly downloadSettings: object;
    private _unsubscribe$: Subject<void>;
    private _customer: any;
    private readonly _cartUrl: string;
    private readonly _paymentsUrl: string;
    private readonly _geodisDomain: boolean;
    private _dynamicColumnsName: [];
    private _dynamicFields: any;
    private _dynamicColumns: [];
    private _typeSort: string;
    private _fieldToSort: string;
    private _isSorting: boolean;
    private _isRefundEligible: boolean;
    private _profileType: string;
    private _isForwarderFacility: boolean;
    private _dynamicFileFields: { id: number; name: string }[];
    private _activeProfile = '';
    private isUserCevaDomain: boolean = false;

    constructor(
        private _sessionService: SessionService,
        private _customerService: CustomerService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _breadcrumbService: BreadcrumbService,
        private _errorHandlerService: ErrorHandlerService,
        private _restService: RestService,
        private _matDialog: MatDialog,
        private _renderer: Renderer2,
        private _domSanitizer: DomSanitizer,
        private _paymentService: PaymentService,
        private _securityService: SecurityService,
        private _fileService: FileAPIService
    ) {
        this._dynamicFileFields = [];
        this._unsubscribe$ = new Subject<void>();
        this._dynamicColumns = [];
        this._dynamicColumnsName = [];
        this._dynamicFields = {};
        this._breadcrumbService.set('@payment-history', 'Payment history');
        this._breadcrumbService.set('@payment-list', 'Payment list');
        this.noInformation = undefined;
        this.noInformationMessage = '';
        this._cartUrl = environment.uris.method.cart;
        this._paymentsUrl = environment.uris.method.payments;
        this._geodisDomain = this._customerService.isUserDomain(companyName.geodis.toLowerCase());
        this.isUserCevaDomain = this._customerService.isCevaDomain();
        this._customer = this._customerService.getCustomer();
        this.companyName = this._customerService.getCompanyName();
        this.startingLevel = this._customerService.getStartingLevel();
        this.recordsSelected = [];
        this.downloadSettings = {
            flux: 'GHA',
            paymentName: 'payment histories',
            kind: [
                {
                    type: 'xlsx',
                    default: true,
                },
            ],
        };
        this._typeSort = '';
        this._fieldToSort = '';
        this.sortableColumns = [];
        this._isSorting = false;
        this._isRefundEligible = false;
        this.refundURL = '';
        this._profileType = '';
        this._isForwarderFacility = false;
        this._activeProfile = this._customerService.getActiveProfileView();
    }

    ngOnInit(): void {
        this._profileType = this._securityService.getProfileType();
        this._isForwarderFacility = this._profileType
            ? this._profileType.includes(profileTypes.FORWARDER_FACILITY)
            : false;
        if (this._customerService.getCompanyId()) {
            this.getAllFields();
        }
        this.getCompletedPayments();
        this.setActions();
    }

    ngOnDestroy(): void {
        this._matDialog.closeAll();
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    /**
     * @method setActions()
     * @description We set the actions that will be trigger when the user clicks the vertical triple dot on the last item row from the table
     */

    setActions(): void {
        this.actionRefund = {
            key: 'refund',
            title: 'Refund',
            icon: 'monetization_on',
            function: {
                name: 'refundPaymentRow',
                prototype: this,
            },
            refundEnabled: true,
            condition: 'hasRenderableFunc',
            hasRenderableFunc: {
                name: 'handleRenderableRefund',
                prototype: this,
            },
        };
        this.actions = [
            {
                key: 'paymentConfirmation',
                title: 'Payment confirmation',
                icon: 'download',
                function: {
                    name: 'getPaymentConfirmation',
                    prototype: this,
                },
                condition: `item['paymentConfirmation']===true`,
            },
            {
                key: 'externalPaymentReceipt',
                title: 'External payment receipt',
                icon: 'save_alt',
                function: {
                    name: 'getExternalPayment',
                    prototype: this,
                },
                condition: `item['externalPaymentConfirmation'] === true`,
            },
            {
                title: 'POD: ',
                icon: '',
                field: 'podName',
                condition: `item['podName']`,
            },
            {
                key: 'paymentAttachments',
                title: 'Download payment attachment(s)',
                icon: 'download',
                function: {
                    name: 'getPaymentAttachments',
                    prototype: this,
                },
                condition: `item['attachments']`,
            },
        ];
    }

    /**
     * @method getCompletedPayments()
     * @param (item?: any)
     * @description Generates the URL based on customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    getCompletedPayments(item?: any): void {
        let pageSize: number;
        let currentSize: number;
        if (item && item['paginator'] !== undefined) {
            pageSize = item.paginator.pageSize;
            currentSize = item.paginator.currentPage;
        } else {
            pageSize = item !== undefined ? item.pageSize : paginatorSize[0];
            currentSize = item !== undefined ? item.currentPage : 0;
        }
        const url: string =
            this.startingLevel || this.companyName
                ? this._cartUrl + '/getCompletedPaymentsRequest'
                : this._paymentsUrl + '/getCompletedPayments';
        let longUrl: string = `${url}/?page=${currentSize}&size=${pageSize}`;
        if (this._typeSort && this._fieldToSort) {
            longUrl = longUrl + `&sort=${this._fieldToSort}-${this._typeSort}`;
        }

        if (this._isForwarderFacility) {
            longUrl = longUrl + `&activeProfileView=${profileTypes.FORWARDER}`;
        }

        this._ngxSpinnerService.show();
        this._getCompleteData(longUrl);
    }

    /**
     * @method search()
     * @param (filter: any)
     * @description Search over all the items on the table
     */

    search(filter: any): void {
        if (filter.value) {
            const pageSize: number = filter.paginator.pageSize !== undefined ? filter.paginator.pageSize : 10;
            const currentSize: number = filter.paginator.currentPage !== undefined ? filter.paginator.currentPage : 0;
            const longUrl: string = `${this._paymentsUrl}/search/?searchTerms=${filter.value}&page=${currentSize}&size=${pageSize}&activeProfileView=${this._activeProfile}`;

            this._ngxSpinnerService.show();
            this._getCompleteData(longUrl, 'search');
        } else {
            this.getCompletedPayments(filter);
        }
    }

    /**
     * @method ()
     * @param (event: any)
     * @description Order by table column on the table
     */

    sort(event: any): void {
        let typeToSort: any = event.direction;
        if (event.typeSort == '') {
            this._typeSort = '';
            this._fieldToSort = '';
        } else {
            this._typeSort = typeToSort.toUpperCase();
            this._fieldToSort = event.field;
        }

        this._isSorting = true;
        this.getCompletedPayments();
    }

    /**
     * @method _getCompleteData()
     * @param (url: string)
     * @description Call the APU and return the data
     */

    private _getCompleteData(url: string, type?: string): void {
        this.paymentsCompleted = [];
        this.paymentsCompletedOriginal = [];
        let companyNameColumns: any = [];
        let companyNameColumnsName: any = [];
        this._restService
            .get(url, {})
            .then((result: any): void => {
                this.paymentsCompletedOriginal = result.content.length > 0 ? result.content : [];
                const companyType: string =
                    this.companyName || this.startingLevel
                        ? this.companyName
                        : this._geodisDomain
                          ? 'ordinaryGeodis'
                          : this.isUserCevaDomain
                            ? 'ordinaryCeva'
                            : 'ordinary';
                const dynamicCompany: boolean = JSON.parse(JSON.stringify(this._customerService.isDynamicCompany()));
                const historySection = JSON.parse(JSON.stringify(Object.keys(Sections.historySection)));
                historySection.forEach((item: any, index: any): void => {
                    if (
                        item === `${companyType}_ColumnsHistory` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsHistory')
                    ) {
                        companyNameColumns = JSON.parse(JSON.stringify(Object.values(Sections.historySection)[index]));
                        if (!!dynamicCompany && this._dynamicColumns) {
                            this._dynamicColumns.map((item: any): void => {
                                companyNameColumns.splice(7, 0, item);
                            });
                        }
                    } else if (
                        item === `${companyType}_ColumnsNameHistory` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsNameHistory')
                    ) {
                        companyNameColumnsName = JSON.parse(
                            JSON.stringify(Object.values(Sections.historySection)[index])
                        );
                        if (!!dynamicCompany && this._dynamicColumnsName) {
                            this._dynamicColumnsName.map((item: any): void => {
                                companyNameColumnsName.splice(7, 0, item);
                            });

                            for (let i: number = 0; i < companyNameColumns.length; i++) {
                                if (
                                    companyNameColumns.indexOf(companyNameColumns[i]) !==
                                    companyNameColumns.lastIndexOf(companyNameColumns[i])
                                ) {
                                    companyNameColumnsName[companyNameColumns.indexOf(companyNameColumns[i])] =
                                        companyNameColumnsName[companyNameColumns.lastIndexOf(companyNameColumns[i])];
                                }
                            }
                        }
                    } else if (
                        item === `${companyType}_getCompletedPaymentsHistory` ||
                        (!!dynamicCompany && item === 'Dynamic_getCompletedPaymentsHistory')
                    ) {
                        this.paymentsCompleted =
                            result.content.length > 0
                                ? result.content.map((elements: any) => {
                                      return JSON.parse(
                                          JSON.stringify(Object.values(Sections.historySection)[index](elements))
                                      );
                                  })
                                : [];
                        if (!!dynamicCompany && this._dynamicColumns) {
                            let encounteredIndices: any = {};
                            for (let i: number = 0; i < companyNameColumns.length; i++) {
                                if (encounteredIndices[companyNameColumns[i]]) {
                                    companyNameColumns.splice(i, 1);
                                    companyNameColumnsName.splice(i, 1);
                                } else encounteredIndices[companyNameColumns[i]] = 1;
                            }
                            this.paymentsCompleted?.map((item: any): void => {
                                this._dynamicColumns.map((column: any): void => {
                                    column = column === 'facilityName' ? '' : column;
                                    column = column === 'awb' ? '' : column;
                                    column = column === 'hawb' ? '' : column;
                                    column = column === 'location' ? '' : column;
                                    column = column === 'customerRef' ? '' : column;
                                    column = column === 'amount' ? '' : column;
                                    column = column === 'paymentConfirmation' ? '' : column;
                                    column = column === 'externalPaymentConfirmation' ? '' : column;
                                    column = column === 'externalReferenceNum' ? '' : column;
                                    column = column === 'externalPaymentConfirmation' ? '' : column;
                                    column = column === 'nameFiles' ? '' : column;

                                    if (
                                        item.dynamicValues.filter(
                                            (values: any): boolean => values.dynamicField.name === column
                                        ).length === 0
                                    ) {
                                        item[column] = 'N/A';
                                    } else {
                                        item[column] = item.dynamicValues
                                            .filter((values: any): boolean => values.dynamicField.name === column)
                                            .map((item: any) => {
                                                const fileField: { id: number; name: string }[] =
                                                    this._paymentService.verifyIfDynamicFieldisFile(
                                                        item.dynamicField.id,
                                                        this._dynamicFileFields
                                                    );
                                                if (fileField.length) {
                                                    const filePath = item.value.split('/');
                                                    item.value = filePath[filePath.length - 1];
                                                }
                                                return item.value !== '' &&
                                                    item.value !== null &&
                                                    item.value !== undefined
                                                    ? item.value
                                                    : 'N/A';
                                            });
                                    }
                                });
                            });
                        }
                    } else if (
                        item === `${companyType}_ColumnsSortableHistory` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsSortableHistory')
                    ) {
                        this.sortableColumns = JSON.parse(
                            JSON.stringify(Object.values(Sections.historySection)[index])
                        );
                    }
                });
                this.noInformation = type === 'search' ? false : !(this.paymentsCompleted.length > 0);
                this._getCompletedPaymentsTableRender(
                    this.paymentsCompleted,
                    companyNameColumns,
                    companyNameColumnsName,
                    result.totalElements
                );

                if (this._isSorting) {
                    this._isSorting = false;
                }
            })
            .catch((error: any): void => {
                if (this.paymentsCompleted !== undefined) {
                    this.noInformationMessage = error.error && error.error.errors ? error.error.errors : '';
                }
                if (this._isSorting) {
                    this._isSorting = false;
                } else {
                    this.noInformation = true;
                }
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method _get_getCompletedPaymentsTableRender()
     * @param (source: any)
     * @param (columns: any)
     * @param (columnsName: any)
     * @param (totalElements: number)
     * @description Render the table using the data that we retrieve from the URL
     */

    private _getCompletedPaymentsTableRender(source: any, columns: any, columnsName: any, totalElements: number): void {
        source = this._paymentService.filterSource(source);
        this.amTable = [
            new Table({
                sorting: true,
                pagination: true,
                filter: true,
                dataSource: source,
                displayedColumns: columns,
                displayedColumnsName: columnsName,
                paginationSizes: paginatorSize,
                totalElements: totalElements,
            }),
        ];
        this._ngxSpinnerService.hide();
    }

    /**
     * @method getPaymentConfirmation()
     * @param (id: any)
     * @description
     */

    getPaymentConfirmation(id: any): void {
        this._ngxSpinnerService.show();

        const recordID: string = id.toString().slice(0, 2) === 'R-' ? id.toString().substring(2) : id.toString();
        this._restService
            .getFileByPost(`${this._paymentsUrl}/admin/paymentConfirmationWithData`, { id: recordID })
            .then((fileResponse: any) => {
                const link: HTMLAnchorElement = document.createElement('a');
                const file: Blob = new Blob([fileResponse], { type: fileResponse.type });
                const url: any = this._domSanitizer.sanitize(
                    SecurityContext.URL,
                    this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                );
                const extension = this._restService.getExtension(fileResponse.type);
                link.href = url;
                link.download = `payment-confirmation-${recordID}` + extension;
                link.click();
                window.URL.revokeObjectURL(url);

                this._ngxSpinnerService.hide();
            })
            .catch((err: any) => {
                this._ngxSpinnerService.hide();
                Swal.fire({
                    html: `${this._errorHandlerService.errorMsg(err.error)}`,
                    icon: 'error',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Close',
                    allowOutsideClick: false,
                });
            });
    }

    /**
     * @method getExternalPayment()
     * @param (id: any)
     * @description
     */

    getExternalPayment(id: any): void {
        this._ngxSpinnerService.show();
        const newId = id.toString().slice(0, 2) === 'R-' ? id.toString().substring(2) : id.toString();
        this._restService.getFile(`${this._paymentsUrl}/downloadExternalReceipt/${newId}`, {}).subscribe({
            next: (fileResponse: any): void => {
                const link: HTMLAnchorElement = document.createElement('a');
                const file: Blob = new Blob([fileResponse], { type: fileResponse.type });
                const url: any = this._domSanitizer.sanitize(
                    SecurityContext.URL,
                    this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                );
                const extension: string = this._restService.getExtension(fileResponse.type);
                link.href = url;
                link.download = `payment-confirmation-external-payment-${newId}` + extension;
                link.click();
                window.URL.revokeObjectURL(url);

                this._ngxSpinnerService.hide();
            },
            error: (error: any): void => {
                this._ngxSpinnerService.hide();
                Swal.fire({
                    html: `${this._errorHandlerService.errorMsg(error.error)}`,
                    icon: 'error',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Close',
                    allowOutsideClick: false,
                });
            },
        });
    }

    /**
     * @method getPaymentAttachments()
     * @param (id: number | string)
     * @description Download payment request attachments
     */

    getPaymentAttachments(id: number | string): void {
        this._ngxSpinnerService.show();
        const newId = id.toString().slice(0, 2) === 'R-' ? id.toString().substring(2) : id.toString();
        const attachedFiles = this.paymentsCompleted.find(
            (paymentItem: PaymentRequest) => paymentItem.id === id
        )?.attachments;

        if (attachedFiles) {
            this._fileService
                .downloadPaymentRequestAttachment(attachedFiles.split(','))
                .pipe(take(1))
                .subscribe({
                    next: (fileResponse): void => {
                        this._handleDownloadFile(fileResponse, `payment-request-attachments-${newId}`);
                        this._ngxSpinnerService.hide();
                    },
                    error: (error): void => {
                        this._ngxSpinnerService.hide();
                        Swal.fire({
                            html: `${this._errorHandlerService.errorTemplate(error)}`,
                            icon: 'error',
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: 'Cancel',
                            allowOutsideClick: false,
                        });
                    },
                });
        }
    }

    /**
     * @method _handleDownloadFile()
     * @param (fileResponse: Blob)
     * @param (fileName: string)
     * @description Create download link and download file
     */

    private _handleDownloadFile(fileResponse: Blob, fileName: string): void {
        const link: HTMLAnchorElement = document.createElement('a');
        const file: Blob = new Blob([fileResponse], { type: fileResponse.type });
        const url: string | null =
            this._domSanitizer.sanitize(
                SecurityContext.URL,
                this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
            ) || '';
        const extension: string = this._restService.getExtension(fileResponse.type);
        link.href = url;
        link.download = fileName + extension;
        link.click();
        window.URL.revokeObjectURL(url);
    }

    /**
     * @method openDialog()
     * @description Opens the dialog in this case menu right sidebar for the activity log
     */

    openDialog(templateRef: any): void {
        this._matDialog.open(templateRef, {
            id: 'download',
            disableClose: true,
        });
    }

    /**
     * @method itemsSelected()
     * @param (items: any)
     * @description Get the items array of selected items
     */

    itemsSelected(items: any): void {
        this.recordsSelected = items;
        if (items) {
            items
                .map((item: any) => {
                    return item.id;
                })
                .join(',');
        }
    }

    /**
     * @method download()
     * @param (event: any)
     * @description Generate the id and dates for download invoices
     */

    downloadRecords(event: any): void {
        let ids: string = '';
        let dates: string = '';
        if (event) {
            ids = this.getIds();
            dates = event.from && event.to ? 'startDate=' + event.from + '&endDate=' + event.to : '';
            this.getOpenInvoiceCSV(dates ? dates : ids);
        }
    }

    /**
     * @method getIds()
     * @description Get the ID of the invoices
     */

    getIds(): string {
        let items: string = '';
        if (this.recordsSelected && this.recordsSelected.length > 0) {
            items = 'paymentsIds=';
            items += this.recordsSelected
                .map((item: any) => {
                    return item.id.includes('R-') ? item.id.split('-')[1] : item.id;
                })
                .join(',');
        }
        return items;
    }

    /**
     * @method getOpenInvoiceCSV()
     * @param (items: any)
     * @description Download the open invoices CSV
     */

    getOpenInvoiceCSV(dates: string): void {
        this._ngxSpinnerService.show();

        this._fileService
            .downloadApprovalPayments(dates)
            .pipe(
                take(1),
                finalize(() => {
                    this._ngxSpinnerService.hide();
                })
            )
            .subscribe({
                next: (result: Blob) => {
                    const now: Date = new Date();
                    const fileName: string = `payments-${now.getDate()}-${now.getMonth()}-${now.getFullYear()}`;
                    const link = this._renderer.createElement('a');
                    const file: Blob = new Blob([result], { type: result.type });
                    const url: string =
                        this._domSanitizer.sanitize(
                            SecurityContext.URL,
                            this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                        ) || '';
                    const extension = this._restService.getExtension(result.type);
                    this._renderer.setAttribute(link, 'href', url);
                    this._renderer.setAttribute(link, 'download', fileName);
                    link.download = fileName + extension;
                    link.click();
                    window.URL.revokeObjectURL(url);
                },
                error: (error: HttpErrorResponse): void => {
                    const response = new Response(error.error).text();
                    response.then((res: string) => {
                        const errors = JSON.parse(res);
                        const message = errors.errors[0];
                        Swal.fire({
                            html: message,
                            icon: 'error',
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: 'Close',
                            allowOutsideClick: false,
                        });
                    });
                },
            });
    }

    /**
     * @method getAllFields()
     * @description
     */

    getAllFields(): void {
        this._dynamicColumns = [];
        this._dynamicColumnsName = [];
        this._dynamicFields = [];
        this._paymentService.getDynamicFieldsColumns().subscribe({
            next: (response: any): void => {
                this._dynamicFields = response.fields;
                this._dynamicColumns = response.dynamicColumns.map((item: any) => {
                    return item.column;
                });
                this._dynamicColumnsName = response.dynamicColumns.map((item: any) => {
                    return item.columnName;
                });
                this._dynamicFileFields = this._paymentService.getFileFieldsDynamic([
                    ...response.fields[0],
                    ...response.fields[1],
                    ...response.fields[2],
                ]);
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
    }

    /**
     * @method refundPaymentRow()
     * @param (id: string)
     * @description Saves selected record into selectedPayment property and opens refund form dialog
     */

    refundPaymentRow(id: string): void {
        const currentPayment = this.paymentsCompleted.filter((item: any) => item.id === id)[0];

        let rowId = id;
        if (String(rowId).includes('-')) {
            rowId = rowId.split('-')[1];
        }

        this.selectedPayment = this.paymentsCompleted.filter((item: any) => String(item.id) === String(id))[0];
        let filteredId = String(id);
        if (filteredId.includes('R-')) {
            filteredId = filteredId.replace('R-', '');
        }
        this.vendorInvoiceNumber = this.paymentsCompletedOriginal.filter(
            (item: any) => String(item.id) === String(filteredId)
        )[0]?.vendorInvoiceNumber;
        const selectedPaymentOriginal = this.paymentsCompletedOriginal.filter(
            (item: any) => String(item.id) === String(rowId)
        )[0];
        this._isRefundEligible = selectedPaymentOriginal.isRefundEligible;
        this.refundURL = selectedPaymentOriginal.refundUrl;

        if (!!this._isRefundEligible && !!this.refundURL && Number(currentPayment.amount.replace('$', '')) >= 0) {
            this._openDialogRefundFromChoice();
        }

        if (!!this._isRefundEligible && !this.refundURL && Number(currentPayment.amount.replace('$', '')) >= 0) {
            this.openModalNotes();
        }
    }

    /**
     * @method hasRenderableRefund()
     * @param (id: string)
     * @description Saves selected record into selectedPayment property and opens refund form dialog
     */

    handleRenderableRefund(id: string): boolean {
        let rowId = id;
        if (String(rowId).includes('-')) {
            rowId = rowId.split('-')[1];
        }

        const selectedPaymentOriginal = this.paymentsCompletedOriginal.filter(
            (item: any) => String(item.id) === String(rowId)
        )[0];
        this._isRefundEligible = selectedPaymentOriginal?.isRefundEligible || false;

        return this._isRefundEligible;
    }

    openModalNotes(): void {
        Swal.fire({
            icon: 'info',
            title: 'NOTE REGARDING REFUND OF CARGOSPRINT HANDLING FEE:',
            html: `<div styles="font-weight: 400; font-size: 18px; line-height: 25px">
                Sometimes but not always CargoSprint refunds the original CargoSprint handling fee. In general
                <strong>if you request a refund by 3pm EST on the same day you placed the original payment request we
                    WILL refund the original CargoSprint handling fee.</strong>
                If actual funds have left our hands we do NOT refund the handling fee. Please refer to our
                <a href='https://cargosprint.com/legal/terms-of-use/sprintpay' target="_blank">
                    Terms of Use
                </a>
                for more details.
            
        </div>`,
            width: '35em',
            showCancelButton: true,
            iconColor: '#FACEA8',
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'CONTINUE',
            cancelButtonText: 'CLOSE',
            reverseButtons: true,
            customClass: {
                title: 'lh-35',
            },
        }).then((result): void => {
            if (result.isConfirmed) {
                this._openDialogRefund();
            }
        });
    }
    /**
     * @method _openDialogRefund()
     * @description Opens the dialog with refund form
     */

    private _openDialogRefund(): void {
        this._matDialog.open(this.refundModal as ComponentType<unknown>, {
            id: 'refund-request',
            disableClose: true,
        });
    }

    /**
     * @method _openDialogRefund()
     * @description Opens the dialog with refund form
     */

    private _openDialogRefundFromChoice(): void {
        this._matDialog.open(this.refundModalFromChoice as ComponentType<unknown>, {
            id: 'refund-request-choice',
            disableClose: true,
        });
    }

    /**
     * @method refundPayment()
     * @param (event: any)
     * @description
     */

    refundPayment(event: any): void {
        this._ngxSpinnerService.show();

        const param = {
            payment: {
                id: this.selectedPayment?.id?.toString().includes('R-')
                    ? this.selectedPayment.id.split('-')[1]
                    : this.selectedPayment.id,
            },
            requestedAmount: Number(event.amount),
            refundReason: event.refundReason,
            customerNotes: event.customerNotes,
            notificationEmail: event.notificationEmail,
        };

        const url = `${this._paymentsUrl}/payment/refund/?activeProfileView=${event.activeProfileView}`;

        this._restService
            .post(url, { data: param })
            .then(() => {
                this.getCompletedPayments();

                this._ngxSpinnerService.hide();
                Swal.fire({
                    title: 'Success!',
                    text: 'Your refund request has been submitted successfully',
                    icon: 'success',
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Close',
                    allowOutsideClick: false,
                });
            })
            .catch((err: any) => {
                this._ngxSpinnerService.hide();
                Swal.fire({
                    html: `${this._errorHandlerService.errorMsg(err.error)}`,
                    icon: 'error',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Close',
                    allowOutsideClick: false,
                });
            });
    }
}
