<form [formGroup]="parentForm" class="row">
    <div class="col-12">
        <!--START: First name -->
        <span class="text-muted mb-4 d-block fw-bolder">Personal information</span>
        <div class="form-group">
            <mat-form-field class="d-block" appearance="outline">
                <mat-label>First name</mat-label>
                <input
                    matInput
                    type="text"
                    id="firstName"
                    name="firstName"
                    data-cy="firstName"
                    formControlName="firstName"
                    placeholder="Enter your first name"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="parentForm.controls['firstName'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="parentForm.controls['firstName'].hasError('minlength')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. Should have at least 3 characters.
                </mat-error>
                <mat-error *ngIf="parentForm.controls['firstName'].hasError('pattern')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. We allow only alphanumeric characters.
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: First name -->
        <!--START: Last name -->
        <div class="form-group">
            <mat-form-field class="d-block" appearance="outline">
                <mat-label>Last name</mat-label>
                <input
                    matInput
                    type="phone"
                    id="lastName"
                    name="lastName"
                    data-cy="lastName"
                    formControlName="lastName"
                    placeholder="Enter your last name"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="parentForm.controls['lastName'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="parentForm.controls['lastName'].hasError('minlength')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. Should have at least 3 characters.
                </mat-error>
                <mat-error *ngIf="parentForm.controls['firstName'].hasError('pattern')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. We allow only alphanumeric characters.
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: Last name -->
        <!--START: Phone -->
        <lib-phone-input [isFormEnabled]="true" [blackListCountryCodes]="blackListedCountryCodes"></lib-phone-input>
        <!--END: Phone -->
        <!--START: Email -->
        <div class="form-group">
            <mat-form-field class="d-block" appearance="outline">
                <mat-label>Email</mat-label>
                <input
                    matInput
                    type="email"
                    id="email"
                    name="email"
                    data-cy="email"
                    formControlName="email"
                    placeholder="Enter your email"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="parentForm.controls['email'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="parentForm.controls['email'].hasError('pattern')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. The email address was malformed! Please
                    try again
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: Email -->
        <div class="row">
            <div class="col-sm-12 col-md-6 offset-md-6">
                <button
                    mat-flat-button
                    color="primary"
                    type="button"
                    class="d-block border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green"
                    data-cy="next_stepOne"
                    (click)="validateEmail()"
                >
                    Next
                </button>
            </div>
        </div>
    </div>
</form>

<ng-template #authenticationFlux>
    <app-authentication-flux
        [messageAuthentication]="messageAuthentication"
        [module]="module"
    ></app-authentication-flux>
</ng-template>
