import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { LoginRoutingModule } from './login-routing.module';

//COMPONENTS

import { LoginComponent } from './login.component';

//MODULES

import { TwoFactorModule } from 'src/app/modules/two-factor/two-factor.module';
import { SharedModule } from '../../../shared/shared.module';

//DIRECTIVES

import { AutofillRequiredValidatorPatchDirective } from 'src/app/directives/autofill-required-validator-patch.directive';
import { DisableControlDirective } from 'src/app/directives/disable-control.directive';
import { RedirectSsoEmailComponent } from './redirect-sso-email/redirect-sso-email.component';

@NgModule({
    declarations: [LoginComponent],
    imports: [
        CommonModule,
        LoginRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        AutofillRequiredValidatorPatchDirective,
        TwoFactorModule,
        DisableControlDirective,
        RedirectSsoEmailComponent,
    ],
})
export class LoginModule {
    constructor() {}
}
