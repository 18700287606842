import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-timer',
    templateUrl: './timer.component.html',
    styleUrls: ['./timer.component.scss'],
    imports: [CommonModule],
})
export class TimerComponent implements OnInit {
    public time = '';

    @Input() minutes = 0;
    @Output() eventOnTimeCompleted: EventEmitter<boolean> = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        this.timer();
    }

    timer(): void {
        let seconds: number = this.minutes * 60;
        let textSec: string = '0';
        let statSec: number = 60;

        const prefix = this.minutes < 10 ? '0' : '';

        const timer = setInterval(() => {
            seconds--;

            if (statSec != 0) {
                statSec--;
            } else {
                statSec = 59;
            }

            if (statSec < 10) {
                textSec = '0' + statSec;
            } else {
                textSec = statSec.toString();
            }

            this.time = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

            if (seconds == 0) {
                this.eventOnTimeCompleted.emit(true);
                clearInterval(timer);
            }
        }, 1000);
    }
}
