import { Directive, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActiveSessionGuard } from './guards/active-session.guard';
import { AuthGuard } from './guards/auth.guard';
import { RedirectGuard } from './guards/redirect.guard';

@Directive()
class RedirectComponent {}

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/auth/login/login.module').then((m) => m.LoginModule),
        data: { breadcrumb: { skip: true, alias: 'login' } },
        canActivate: [ActiveSessionGuard],
    },
    {
        path: 'admin',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule),
        data: {
            breadcrumb: { skip: true, alias: 'admin' },
        },
    },
    {
        path: 'super-admin',
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/super-admin/super-admin.modules').then((m) => m.SuperAdminModule),
        data: {
            breadcrumb: { skip: true, alias: 'super-admin' },
        },
    },
    {
        path: 'forwarder-admin',
        canActivate: [AuthGuard],
        loadChildren: () =>
            import('./pages/forwarder-admin/forwarder-admin.module').then((m) => m.ForwarderAdminModule),
        data: { breadcrumb: { skip: true, alias: 'forwarder-admin' } },
    },
    {
        path: 'password',
        loadChildren: () => import('./pages/auth/password/password.module').then((m) => m.PasswordModule),
        data: { breadcrumb: { skip: true, alias: 'password' } },
        canActivate: [ActiveSessionGuard],
    },
    {
        path: 'signup',
        loadChildren: () => import('./pages/auth/signup/signup.module').then((m) => m.SignupModule),
        data: { breadcrumb: { skip: true, alias: 'signup' } },
        canActivate: [ActiveSessionGuard],
    },
    {
        path: 'signup-guest',
        loadChildren: () => import('./pages/auth/signup-guest/signup-guest.module').then((m) => m.SignupGuestModule),
        data: { breadcrumb: { skip: true, alias: 'signup-guest' } },
        canActivate: [ActiveSessionGuard],
    },
    {
        path: 'activate/:activation_type/:authorization_token/:refresh_token',
        loadChildren: () => import('./pages/auth/activate/activate.module').then((m) => m.ActivateModule),
        data: { breadcrumb: { skip: true, alias: 'activate' } },
        canActivate: [ActiveSessionGuard],
    },
    {
        path: 'login/sso' || 'saml/SSO',
        loadChildren: () => import('./pages/auth/activate/activate.module').then((m) => m.ActivateModule),
        data: { breadcrumb: { skip: true, alias: 'activate' } },
        canActivate: [ActiveSessionGuard],
    },
    {
        path: '**',
        canActivate: [RedirectGuard],
        component: RedirectComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
