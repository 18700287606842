import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar, MatDateRangePicker } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/** Custom header component for datepicker. */
@Component({
    selector: 'app-calendar-header',
    styles: [
        `
            .calendar-header {
                display: flex;
                align-items: center;
                padding: 0.5em;
                justify-content: space-between;
            }

            .icon-button {
                width: 30px;
            }

            .year-btn {
                width: auto;
            }

            .calendar-header-label {
                flex: 1;
                height: 1em;
                font-weight: 500;
                text-align: center;
            }
        `,
    ],
    templateUrl: './calendar-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonModule, MatIconModule],
})
export class CalendarHeaderComponent<D> implements OnDestroy {
    private _destroyed = new Subject<void>();

    constructor(
        private _dateRangePicker: MatDateRangePicker<D>,
        private _calendar: MatCalendar<D>,
        private _dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
        cdr: ChangeDetectorRef
    ) {
        _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    get periodLabel() {
        return this._dateAdapter
            .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
            .toLocaleUpperCase();
    }

    startView(): void {
        this._calendar._goToDateInView(this._dateAdapter.today(), 'multi-year');
    }

    todayClicked(): void {
        this._calendar.activeDate = this._dateAdapter.today();
        this._dateRangePicker.select(this._dateAdapter.today());
        if (this._dateRangePicker?.startAt) {
            const sameDate = this._dateAdapter.compareDate(this._dateAdapter.today(), this._dateRangePicker.startAt);
            if (sameDate !== 0) {
                this._dateRangePicker.select(this._dateAdapter.today());
                this._dateRangePicker.select(this._dateAdapter.today());
            } else {
                this._dateRangePicker.select(this._dateAdapter.today());
            }
            this._dateRangePicker.close();
        }
    }

    previousClicked(mode: 'month' | 'year'): void {
        this._calendar.activeDate =
            mode === 'month'
                ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
                : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    }

    nextClicked(mode: 'month' | 'year'): void {
        this._calendar.activeDate =
            mode === 'month'
                ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
                : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    }
}
