import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { permissionsName } from '../../../../utils/constants';

// COMPONENTS

import { UploadRequestComponent } from './upload-request.component';

const routes: Routes = [
    {
        path: '',
        title: 'SprintPay | Upload request',
        canLoad: [NgxPermissionsGuard],
        component: UploadRequestComponent,
        data: {
            breadcrumb: { alias: 'upload-request' },
            permissions: {
                only: [permissionsName.knRequestor],
                except: [permissionsName.ordinary],
                redirectTo: '/admin/home',
            },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UploadRequestRoutingModule {}
