<!--START: AMOUNT TO PAY -->
<form class="row" [formGroup]="paymentForm">
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>Amount to pay</mat-label>
            <input
                maxlength="15"
                matInput
                thousandSeparator=","
                mask="separator.2"
                step=".01"
                prefix="$"
                type="text"
                id="amount"
                name="amount"
                formControlName="amount"
                (keyup)="hasAmountRestrictions()"
                (ngModelChange)="returnAmount()"
                autocomplete="off"
                placeholder="Enter - Amount to pay"
                [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="paymentForm.controls['amount'].hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
            <mat-error *ngIf="paymentForm.controls['amount'].hasError('min')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>The minimum amount to pay it's {{this.minAmount}}
            </mat-error>
        </mat-form-field>
    </div>
</form>

<div class="row">
    <div class="col-12">
        <ng-container *ngIf="hasRestrictions && amount?.value !== null">
            <span class="cs__tooltip cs__tooltip--warning">{{amountRestriction}}</span>
        </ng-container>
    </div>
</div>

<!--END: AMOUNT TO PAY -->
