<div class="row mb-4" *ngIf="paymentDetail && values">
    <!-- START: DETAIL TITLE -->
    <app-details-header>
        <span class="my-3"><strong>Customer reference</strong></span>
        <ng-container
            *ngIf="((paymentDetail?.approvalStatus === 'PENDING' && paymentDetail?.uniqueRequestor && isStartingLevel && !paymentDetail?.ownerCanPay && paymentDetail?.sameCustomerAndRequestor) || (paymentDetail?.approvalStatus === 'REJECTED' && isStartingLevel && paymentDetail?.sameCustomerAndRequestor) || (paymentDetail?.id === null ) || (paymentDetail?.id !== null && !!paymentDetail?.fromCart)) && paymentDetail.sprintPaySource && !hasExternalLookup"
        >
            <button mat-button class="my-2 px-0 text-uppercase text-decoration-none" (click)="editPaymentRequest()">
                Edit
            </button>
        </ng-container>
    </app-details-header>
    <!-- END: DETAIL TITLE -->
    <!-- START: DETAIL ELEMENTS -->
    <ng-container *ngFor="let item of values">
        <app-details-field *ngIf="item.show" class="col-12 col-md-4" [label]="item.label" [value]="item.value">
        </app-details-field>
    </ng-container>
    <!-- END: DETAIL ELEMENTS -->
    <!--<app-details-field
        [ngClass]="(geodisUser || geodisDomain) || isExpeditors || emotransUser || rhenusUser ? 'col-12 col-md-3' : 'col-12 col-md-4'"
        [label]="customerReference"
        [value]="customerReferenceValue"
    >
    </app-details-field> -->
    <!-- START: DYNAMIC FIELDS -->
    <ng-container *ngFor="let key of keys();">
        <app-details-field class="col-12 col-md-4" [label]="key" [value]="dynamicValuesReference[key]">
        </app-details-field>
    </ng-container>
    <!-- END: DYNAMIC FIELDS -->
    <!-- END: DETAIL ELEMENTS -->
    <!-- START: ATTACHMENT DOWNLOADED LINK -->
    <app-files-container [filesPath]="pathFiles" [title]="titleFile"></app-files-container>
    <!-- END: ATTACHMENT DOWNLOADED LINK -->
</div>
