import { Pipe, PipeTransform } from '@angular/core';
import { CustomStatuses, RefundStatusDescription } from '../models/refunds/refunds';
@Pipe({
    name: 'refundStatus',
})
export class RefundStatusPipe implements PipeTransform {
    transform(status: CustomStatuses | undefined): string {
        if (status) {
            return RefundStatusDescription[status];
        }
        return '';
    }
}
