<!-- START: EMPTY CART -->
<section id="empty-cart" class="container-fluid">
    <div class="d-flex flex-column h-100 justify-content-center align-items-center empty-cart--height">
        <div class="row">
            <div class="col-12">
                <img
                    class="mx-auto d-block"
                    src="assets/images/home/frequent-facilities.svg"
                    alt="New user illustration"
                />
            </div>
            <div class="col-12">
                <h4 class="d-block text-center mt-4 mb-3 fs-1 lh-sm">Whoops!</h4>
                <p class="d-block text-center mt-3 mb-4 fs-6">
                    In order to make a payment request you must first search and select a cargo facility
                </p>
            </div>
            <ng-container *ngxPermissionsOnly="newPaymentSecurity">
                <div class="col-12">
                    <button
                        mat-button
                        type="button"
                        class="text-uppercase btn__sprintpay btn__sprintpay--green d-block mx-auto mb-3"
                        routerLink="../facilityPayments/newPayment"
                    >
                        Start a new payment
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</section>
<!-- END: EMPTY CART -->
