import { Injectable } from '@angular/core';
import { TokenService } from '@cargos/sprintpay-services';
import { CanCreateApprovalFlow, PaymentReferenceLookups } from '../models/requestor-approver/approvalFlowLevels';
import { CustomerService } from './utils/customer-handler.service';

@Injectable({
    providedIn: 'root',
})
export class ApprovalFlowLevelsService {
    constructor(
        private tokenService: TokenService,
        private customerService: CustomerService
    ) {}

    isChargeToPayAvailable(chargeAmount: number): boolean {
        const levelsByFlow = this.getLevelsOfApprovalFlowByAmount(chargeAmount);
        if (levelsByFlow === 1) {
            return true;
        }
        return false;
    }

    getPaymentAmounts(): PaymentReferenceLookups[] {
        const customer = this.customerService.getCustomer();
        if (customer) {
            return customer.approvalLevels?.company?.paymentReference?.find(
                (reference: any) => reference?.name?.toLowerCase() === 'type'
            )?.paymentReferenceLookups;
        }
        return [];
    }

    getLevelsOfApprovalFlowByAmount(amount: number): number {
        const type = this.getTypeByAmount(amount);
        if (type) {
            return this.getApprovalFlow(type).approvalFlowLevels?.length;
        }
        return 0;
    }

    getTypeByAmount(amount: number): string {
        const paymentType = this.getPaymentAmounts();
        let type: string = '';
        paymentType?.forEach((paymentAmount: PaymentReferenceLookups) => {
            const criteriaToValidate = paymentAmount?.criteria?.split(',');
            const min = Number(criteriaToValidate[0]);
            const max = Number(criteriaToValidate[1]);
            if (amount >= min && amount <= max) {
                type = paymentAmount.type;
                return;
            }
        });
        return type;
    }

    getApprovalFlow(type: string): CanCreateApprovalFlow {
        const customer = this.customerService.getCustomer();
        return (customer.approvalLevels?.canCreateApprovalFlow).find((item: CanCreateApprovalFlow) =>
            item.name?.includes(type)
        );
    }
}
