import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface VerificationState {
    requestPassword: boolean;
    requestMFA: boolean;
    redirectSignin?: boolean;
    activationUuid?: string;
    termsOfUse?: boolean;
    activeAccount?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class HandlerVerificationService {
    private _activationUuid: BehaviorSubject<string | null> = new BehaviorSubject<string | null>('');
    private _accountVerified: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {}

    getVerificationState(error: {
        error: {
            title: string;
            body: string;
            data: {
                dynamicParameter?: {
                    activationUuid: string;
                };
                isGuest: boolean;
                isActive: boolean;
            };
        };
    }): VerificationState {
        if (!error?.error?.data?.isActive && !error?.error?.data?.isGuest) {
            return {
                requestPassword: false,
                requestMFA: true,
                redirectSignin: false,
                activationUuid: error?.error?.data?.dynamicParameter?.activationUuid,
            };
        }

        if (error?.error?.data?.isActive && !error?.error?.data?.isGuest) {
            return {
                requestPassword: false,
                requestMFA: false,
                redirectSignin: true,
                activationUuid: error?.error?.data?.dynamicParameter?.activationUuid,
            };
        }

        if (!error?.error?.data?.isActive && error?.error?.data?.isGuest) {
            return {
                requestPassword: true,
                requestMFA: false,
                redirectSignin: false,
                activationUuid: error?.error?.data?.dynamicParameter?.activationUuid,
            };
        }

        return {
            requestPassword: false,
            requestMFA: false,
            redirectSignin: false,
        };
    }

    getStateForResetPassword(error: {
        error: {
            title: string;
            body: string;
            data: {
                dynamicParameter?: {
                    activationUuid: string;
                };
                isGuest: boolean;
                isActive: boolean;
            };
        };
    }): VerificationState {
        if (!error?.error?.data?.isActive && !error?.error?.data?.isGuest) {
            return {
                requestPassword: false,
                requestMFA: true,
                activationUuid: error?.error?.data?.dynamicParameter?.activationUuid,
                termsOfUse: false,
                activeAccount: error?.error?.data?.isActive,
            };
        }

        if (error?.error?.data?.isActive && !error?.error?.data?.isGuest) {
            return {
                requestPassword: false,
                requestMFA: true,
                activationUuid: error?.error?.data?.dynamicParameter?.activationUuid,
                termsOfUse: false,
                activeAccount: error?.error?.data?.isActive,
            };
        }

        if (!error?.error?.data?.isActive && error?.error?.data?.isGuest) {
            return {
                requestPassword: true,
                requestMFA: true,
                activationUuid: error?.error?.data?.dynamicParameter?.activationUuid,
                termsOfUse: true,
                activeAccount: error?.error?.data?.isActive,
            };
        }

        return {
            requestPassword: false,
            requestMFA: false,
            termsOfUse: false,
        };
    }

    setActivationUuid(activationUuid: string): void {
        this._activationUuid.next(activationUuid);
    }

    get instant_activationUuid(): string {
        return this._activationUuid.value || '';
    }

    setAccountVerified(isVerified: boolean): void {
        this._accountVerified.next(isVerified);
    }

    getAccountVerified(): Observable<boolean> {
        return this._accountVerified.asObservable();
    }

    get instant_accountVerified(): boolean {
        return this._accountVerified.value;
    }
}
