import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ErrorMatcher } from 'src/app/utils/errorMatcher';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { PaymentDetailService } from '../../payment-detail.service';

@Component({
    selector: 'app-pickup-date',
    standalone: true,
    imports: [CommonModule, MatInputModule, FormsModule, MatFormFieldModule, ReactiveFormsModule, MatDatepickerModule],
    templateUrl: './pickup-date.component.html',
})
export class PickupDateComponent implements OnInit {
    form!: FormGroup;
    matcher: ErrorMatcher = new ErrorMatcher();
    private readonly _currentPayment: any;
    @Output() returnPickupDate: EventEmitter<string | null> = new EventEmitter<string | null>();

    constructor(
        private _formBuilder: FormBuilder,
        private _paymentFluxService: PaymentFluxService,
        private _paymentDetailService: PaymentDetailService
    ) {
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
    }

    ngOnInit(): void {
        this._setFormBuilder();
        if (this._currentPayment?.details?.pickupDate) {
            this.pickupDate.setValue(this._currentPayment.details.pickupDate);
            this._emitPickupDate();
        }
    }

    /**
     * @method _setFormBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFormBuilder(): void {
        this.form = this._formBuilder.group({
            pickupDate: new FormControl<Date | null>(null),
        });
        this.form = this._paymentDetailService.validateForm(this.form);
    }

    /**
     * @method pickupDate()
     * @description: Convenience getter for easy access to form fields
     */

    get pickupDate(): FormControl<Date | null> {
        return this.form.get('pickupDate') as FormControl;
    }

    /**
     * @method setPickupDate()
     * @param (event: MatDatepickerInputEvent<Date>)
     * @description Sets pickupDate value and emits it to parent
     */

    setPickupDate(event: MatDatepickerInputEvent<Date>): void {
        this.pickupDate.setValue(event.target.value);
        this._emitPickupDate();
    }

    /**
     * @method _emitPickupDate()
     * @description Emits form value
     */

    private _emitPickupDate(): void {
        this.returnPickupDate.emit(this.form.valid ? this.form.value.pickupDate : null);
    }
}
