import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { NgxPermissionsModule } from 'ngx-permissions';
import { StepperService } from '../payment-flux/services/stepper.service';

import { DisableControlDirective } from 'src/app/directives/disable-control.directive';
import { SharedModule } from 'src/app/shared/shared.module';
import { UploadFileComponent } from '../payment-flux/payment-detail/upload-file/upload-file.component';
import { CodaFacilityComponent } from './coda-facility.component';
import { PaymentDetailComponent } from './payment-detail/payment-detail.component';

import { PaymentDetailModule } from '../payment-flux/payment-detail/payment-detail.module';
import { StandardReferenceModule } from '../payment-flux/standard-reference/standard-reference.module';
import { CodafacilityRoutingModule } from './codafacility-routing.module';
import { ButtonChargeComponent } from './payment-detail/button-charge/button-charge.component';
import { VoucherRequestComponent } from './payment-detail/button-charge/voucher-request/voucher-request.component';
import { HouseChargesComponent } from './payment-detail/house-charges/house-charges.component';
import { ChargesTableComponent } from './payment-summary/charges-table/charges-table.component';
import { PaymentSummaryComponent } from './payment-summary/payment-summary.component';
@NgModule({
    declarations: [
        CodaFacilityComponent,
        PaymentDetailComponent,
        PaymentSummaryComponent,
        HouseChargesComponent,
        ButtonChargeComponent,
        ChargesTableComponent,
        VoucherRequestComponent,
    ],
    imports: [
        MatListModule,
        UploadFileComponent,
        CommonModule,
        CodafacilityRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        DisableControlDirective,
        NgxPermissionsModule.forChild(),
        StandardReferenceModule,
        PaymentDetailModule,
    ],
    providers: [StepperService],
})
export class CodafacilityModule {
    constructor() {}
}
