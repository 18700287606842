import { CommonModule } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormBuilder,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SharedModule } from 'src/app/shared/shared.module';
import Swal from 'sweetalert2';
import { environment } from '../../../../../../../../environments/environment';
import { ModalService } from '../../../../../../../services/modal.service';
import { RestService } from '../../../../../../../services/rest.service';
import { CustomerService } from '../../../../../../../services/utils/customer-handler.service';
import { ErrorHandlerService } from '../../../../../../../services/utils/error-handler.service';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { SessionService } from '../../../../../../../services/utils/session.service';
import { blackListFiles } from '../../../../../../../utils/constants';
import { PaymentDetailService } from '../../payment-detail.service';

@Component({
    selector: 'app-upload-file-flux',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule],
    templateUrl: './upload-file.component.html',
})
export class UploadFileComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;
    paymentForm!: FormGroup;
    nameFilesArr: any[];
    attachFileLabel: string;
    isFileLoading: BehaviorSubject<boolean>;
    dynamicField: any;
    private _nameFailedFilesArr: any[];
    private _loadingFile: boolean;
    private _currentPayment: any;
    private _customer: any;
    private _pathFileArr: any[];
    private _typeOptionsRestrictions: any[];
    private _blackListFile: any;
    private readonly _companyName: any;
    private readonly _startingLevel: boolean;
    private readonly _cartURL: String;

    @Input() formValidState: any;
    @Output() returnUploadFile: EventEmitter<any> = new EventEmitter<any>();

    @Input() set isDinamycField(isDinamycField: any) {
        this.dynamicField = isDinamycField;
        if (isDinamycField) {
            this._setDynamicValidations(isDinamycField);
        }
    }

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _restService: RestService,
        private _customerService: CustomerService,
        private _sessionService: SessionService,
        private _errorHandlerService: ErrorHandlerService,
        private _modalService: ModalService,
        private _paymentDetailService: PaymentDetailService,
        private _paymentFluxService: PaymentFluxService
    ) {
        this._loadingFile = false;
        this.nameFilesArr = [];
        this._pathFileArr = [];
        this._nameFailedFilesArr = [];
        this._typeOptionsRestrictions = ['Storage/Demurrage', 'ISC + Storage', 'Other***'];
        this.dynamicField = '';
        this.attachFileLabel = '';
        this.attachFileLabel = 'Attach files (Optional)';
        this._blackListFile = blackListFiles;
        this._customer = this._customerService.getCustomer();
        this._companyName = this._customer?.approvalLevels?.company?.name || '';
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this._startingLevel = !!this._sessionService.getElement('startingLevel');
        this._cartURL = environment.uris.method.cart;
        this.isFileLoading = new BehaviorSubject<boolean>(false);
    }

    ngOnInit(): void {
        this._setFromBuilder();
        this._startingLevelConfiguration();
        if (this._currentPayment.details && this._currentPayment.details.nameFiles != null) {
            let path = (this._pathFileArr = this._currentPayment.details.nameFiles
                ? this._currentPayment.details.nameFiles.split(',')
                : []);
            this.paymentForm.get('nameFiles')!.setValue(path.join(','));
            path.forEach((element: any) => {
                let aux = element.split('/');
                this.nameFilesArr.push(aux[aux.length - 1]);
            });
            this.returnUploadFile.emit(this.paymentForm.value);
        }
    }

    ngOnDestroy(): void {
        this.isFileLoading.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formValidState'] !== undefined) {
            if (changes['formValidState'].currentValue !== undefined) {
                if (changes['formValidState'].currentValue !== changes['formValidState'].previousValue) {
                    if (this.paymentForm !== undefined && !this.formValidState) {
                        this.nameFiles.markAsTouched();
                    }
                }
            }
        }
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.paymentForm = this._formBuilder.group({
            nameFiles: new FormControl<string | null>(null, []),
        });
        this.paymentForm = this._paymentDetailService.validateForm(this.paymentForm);
    }

    /**
     * @method nameFiles()
     * @description: Convenience getter for easy access to form fields
     */

    get nameFiles(): FormGroup {
        return this.paymentForm.get('nameFiles') as FormGroup;
    }

    /**
     * @method __startingLevelConfiguration()
     * @description Set the form requirements to be a valid submission
     */

    private _startingLevelConfiguration(): void {
        const validator: any = this.paymentForm.get('nameFiles')?.hasValidator(Validators.required);
        if (this._startingLevel) {
            if (!!validator) {
                this.attachFileLabel = 'Please attach the required document(s)';
            }
        }
    }

    /**
     * @method dropHandler()
     * @param (event: any)
     * @description drop files into area
     */

    dropHandler(event: any) {
        event.preventDefault();
        if (event.dataTransfer.files) {
            this.handleFileInput(event.dataTransfer);
        }
        this._removeDragData(event);
    }

    /**
     * @method _removeDragData()
     * @param (event: any)
     * @description removed the drag files
     */

    private _removeDragData(event: any): void {
        event.dataTransfer.items ? event.dataTransfer.items.clear() : event.dataTransfer.clearData();
    }

    /**
     * @method handleFileInput()
     * @param (event: any)
     * @description Save and handle the files
     */

    handleFileInput(event: any): void {
        event = event['files'];
        let nameTempFilesArr: any[] = [];
        this._nameFailedFilesArr = [];
        if (event.length > 3 || event.length + this.nameFilesArr.length > 3) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You may upload at most 3 files .doc, .docx, .pdf or image (jpeg, jpg, png), of max 1MB each.',
            });
        } else {
            for (let i = 0; i < event.length; i++) {
                if (!this._verifyFileExtensions(event.item(i).name)) {
                    nameTempFilesArr.push(event.item(i).name.replace(/ /g, '_'));
                    if (event[i].size / 1024 >= 2000) {
                        Swal.fire({
                            html: `${this._errorHandlerService.errorTemplate({
                                errors: ['Please select a file with less than 1 MB'],
                            })}`,
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonText: 'OK',
                            showCancelButton: false,
                            allowOutsideClick: false,
                        });
                        this._nameFailedFilesArr.push(nameTempFilesArr.shift());
                        continue;
                    }
                    let formData = new FormData();
                    formData.append('file', event.item(i));
                    this._loadingFile = true;
                    this.isFileLoading.next(true);

                    this._restService
                        .postFiles(`${this._cartURL}/uploadPaymentRequestAttachment`, formData)
                        .then((path: any): void => {
                            if (path) {
                                this._pathFileArr.push(path);
                                this.paymentForm.get('nameFiles')!.setValue(this._pathFileArr.join(','));
                                this.nameFiles.markAsTouched();
                                let arrayPath = path.split('/');
                                this.nameFilesArr.push(arrayPath[arrayPath.length - 1]);
                                nameTempFilesArr.shift();
                                this.returnUploadFile.emit(this.paymentForm.value);
                            }
                            this._loadingFile = false;
                            this.isFileLoading.next(false);
                        })
                        .catch((error: any): void => {
                            if (error.error && typeof error.error == 'string') {
                                error.error = JSON.parse(error.error);
                            }
                            if (error.error.errors && error.error.errors.length >= 1) {
                                nameTempFilesArr.shift();
                                Swal.fire({
                                    html: `${this._errorHandlerService.errorTemplate(error.error)}`,
                                    icon: 'error',
                                    showConfirmButton: true,
                                    confirmButtonText: 'OK',
                                    showCancelButton: false,
                                    allowOutsideClick: false,
                                });
                            } else {
                                Swal.fire({
                                    html: `${this._errorHandlerService.errorMsg(error.error)}`,
                                    icon: 'error',
                                    showConfirmButton: true,
                                    confirmButtonText: 'OK',
                                    showCancelButton: false,
                                    allowOutsideClick: false,
                                });
                                this._nameFailedFilesArr.push(nameTempFilesArr.shift());
                            }
                            this._loadingFile = false;
                            this.isFileLoading.next(false);
                        });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You file contains different extensions.',
                    });
                }
            }
        }
    }

    /**
     * @method _verifyFileExtensions()
     * @param (fileName: any)
     * @description Verify the file extensions
     */

    private _verifyFileExtensions(fileName: any): boolean {
        let extensionContainsBlackListFile: boolean = false;
        fileName.split('.').map((item: string): void => {
            if (this._blackListFile.indexOf(item.toLowerCase()) !== -1) {
                extensionContainsBlackListFile = true;
            }
        });
        return extensionContainsBlackListFile;
    }

    /**
     * @method dragOverHandler()
     * @param (event: any)
     * @description drag files
     */

    dragOverHandler(event: any): void {
        event.preventDefault();
    }

    /**
     * @method getFormControls()
     * @description
     */

    getFormControls() {
        if (this._loadingFile) {
            Object.keys(this.paymentForm.controls).forEach((key) => {
                const controlErrors: ValidationErrors | null | undefined = this.paymentForm.get(key)?.errors;
                if (controlErrors != null) {
                    Object.keys(controlErrors).forEach((): void => {
                        this._loadingFile = false;
                    });
                }
            });
        }
        return this.paymentForm.controls;
    }

    /**
     * @method removeFile()
     * @param (index: number)
     * @description removed the drag files
     */

    removeFile(index: number): void {
        this._restService
            .delete(
                this._cartURL + '/removePaymentRequestAttachment' + '?attachmentLocation=' + this._pathFileArr[index]
            )
            .then((): void => {
                this.nameFilesArr.splice(index, 1);
                this._pathFileArr.splice(index, 1);
                if (this.nameFilesArr.length < 1) {
                    this.nameFilesArr = [];
                    this._pathFileArr = [];
                    this.paymentForm.get('nameFiles')!.setValue(null);
                    this.paymentForm.value.nameFiles = null;
                    if (this.fileInput?.nativeElement) {
                        this.fileInput.nativeElement.value = '';
                    }
                } else {
                    this.paymentForm.get('nameFiles')!.setValue(this._pathFileArr.join(','));
                }
                this.returnUploadFile.emit(this.paymentForm.value);
            })
            .catch((err: any): void => {
                Swal.fire({
                    html: `${this._errorHandlerService.errorMsg(err.error)}`,
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    allowOutsideClick: false,
                });
            });
    }

    /**
     * @method setDynamicValidations()
     * @param (field: any)
     * @description Review if dynamic fields exits and if they do, we review if we have any isReplaced field
     */

    private _setDynamicValidations(field: any): void {
        this.paymentForm.get(field.name)!.setValidators(null);
        this.paymentForm.get(field.name)!.setErrors(null);
        if (field.validations.required) {
            if (field.validations.required.value === true) {
                this.paymentForm.get(field.name)!.addValidators(Validators.required);
            }
        }
        field.validations.minlength
            ? this.paymentForm.get(field.name)!.addValidators(Validators.minLength(field.validations.minlength.value))
            : null;
        field.validations.maxlength
            ? this.paymentForm.get(field.name)!.addValidators(Validators.maxLength(field.validations.maxlength.value))
            : null;
        field.validations.pattern
            ? this.paymentForm.get(field.name)!.addValidators(Validators.pattern(field.validations.pattern.value))
            : null;
        this.paymentForm.get(field.name)!.updateValueAndValidity();
    }
}
