<!--START: BULK PAYMENT -->
<div id="standard_flux" class="mt-4">
    <div id="standard_flux--container">
        <div class="row">
            <div class="col-sm-12 col-md-3 offset-md-9">
                <button
                    mat-button
                    type="button"
                    color="warn"
                    class="d-block border-0 rounded-0 w-100 py-3 mt-4 text-uppercase btn__sprintpay btn__sprintpay--linkDelete"
                    style="text-align: end; padding: 0 24px 0 0 !important"
                    (click)="returnToFacility()"
                >
                    Cancel process
                </button>
            </div>
        </div>
        <div class="mt-4 d-block" style="padding: 0 24px 24px 24px">
            <!--START: FACILITY DATA COMPONENT -->
            <app-facility-data [facility]="facility"></app-facility-data>
            <!--END: FACILITY DATA COMPONENT -->
            <!--START: FORM -->
            <form class="row" [formGroup]="paymentForm">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <!--START: NOTIFICATION EMAIL FIELD -->
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <mat-form-field class="d-block" appearance="outline">
                                    <mat-label>Payment notification email</mat-label>
                                    <input
                                        matInput
                                        type="email"
                                        id="notificationEmail"
                                        name="notificationEmail"
                                        formControlName="notificationEmail"
                                        autocomplete="off"
                                        placeholder="Payment notification email"
                                        data-cy="notificationEmail"
                                        [errorStateMatcher]="matcher"
                                    />
                                    <mat-error *ngIf="paymentForm.controls['notificationEmail'].hasError('required')">
                                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                    </mat-error>
                                    <mat-error *ngIf="paymentForm.controls['notificationEmail'].hasError('pattern')">
                                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Invalid email
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <!--END: NOTIFICATION EMAIL FIELD -->
                    <!--START: UPLOAD FILE FIELD -->
                    <app-upload-file
                        [removingFile]="removingFile"
                        [downloadTemplateURL]="templateURL"
                        [templateName]="'bulk-payment-template'"
                        [progressBarPercentage]="progressBarPercentage"
                        (fileEvent)="handleFileEvent($event)"
                    ></app-upload-file>
                    <!--END: UPLOAD FILE FIELD -->
                </div>
            </form>
            <!--END: FORM -->
            <!--START: STEPPER NEXT AND BACK BUTTON -->
            <div class="row">
                <div class="col-sm-12 col-md-6 mb-3 mb-lg-0">
                    <div class="d-flex justify-content-start w-100">
                        <button
                            mat-button
                            type="button"
                            (click)="returnToFacility()"
                            class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                        >
                            Back
                        </button>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div class="d-flex justify-content-end w-100">
                        <button
                            mat-flat-button
                            color="primary"
                            [disabled]="paymentForm.invalid || formSubmitted"
                            (click)="addToCart(paymentForm)"
                            type="button"
                            class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                        >
                            Continue
                        </button>
                    </div>
                </div>
            </div>
            <!--END: STEPPER NEXT AND BACK BUTTON -->
        </div>
    </div>
</div>
<!--END: BULK PAYMENT -->
