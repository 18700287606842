import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { Payment } from 'src/app/models/payments/payment.model';
import { SessionService } from 'src/app/services/utils/session.service';
import { PaymentToRequestEditService } from '../../../services/payment-to-request-edit.service';
import { PaymentFluxService } from '../../../services/utils/payment-flux.service';

@Component({
    selector: 'app-schenker-customer-reference',
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: './schenker-customer-reference.component.html',
})
export class SchenkerCustomerReferenceComponent implements OnInit {
    hasExternalLookup: boolean = false;
    id: string;
    isStartingLevel: any;
    private readonly _currentPayment: Payment;

    @Input() paymentDetail: any;

    constructor(
        private _paymentToRequestEditService: PaymentToRequestEditService,
        private _router: Router,
        private _paymentFluxService: PaymentFluxService,
        private _sessionService: SessionService
    ) {
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.id = '';
        this.isStartingLevel = !!this._sessionService.getElement('startingLevel');
    }

    ngOnInit(): void {
        this.hasExternalLookup =
            this.paymentDetail && this.paymentDetail.externalLookup
                ? this.paymentDetail.externalLookup
                : this._currentPayment && this._currentPayment.facility
                  ? this._currentPayment.facility.externalLookup
                  : false;
        this.id =
            this.paymentDetail && this.paymentDetail.id
                ? this.paymentDetail.id
                : this._paymentFluxService.getCurrentPayment() !== undefined
                  ? this._paymentFluxService.getCurrentPayment().id
                  : '';
    }

    /**
     * @method editPaymentRequest()
     * @description First we review if the payment request has an ID, if we have and ID we call the service to generate the necessary data for the payment request process
     */

    editPaymentRequest(): void {
        const redirect: string = this.paymentDetail.redirectRoute ? this.paymentDetail.redirectRoute : this._router.url;
        this._paymentFluxService.setCurrentPayment(this.paymentDetail);
        this._router.navigate(['/admin/facilityPayments/newPayment/flux/schenkerReference']);
    }
}
