import { Injectable } from '@angular/core';
import {
    Cart,
    CartAWB,
    CartBill,
    ComponentPaymentMethodName,
    FlowType,
    PayloadPayPal,
    PaymentCart,
    PaymentMetaData,
    PaymentMethods,
    PaymentMethodsType,
    PaymentRequest,
    PaymentResponse,
    PaymentToken,
    ProcessorName,
} from '@cargos/sprintpay-models';
import { PaymentService } from '@cargos/sprintpay-services';
import { Config } from '@cargos/sprintpay_frontend_core_api/lib/models';
import { Observable } from 'rxjs';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { CartPayRequest, PayloadGuest } from 'src/app/utils/cartTypes';

interface EnabledPaymentType {
    paymentMethod: { name: ComponentPaymentMethodName };
}

@Injectable()
export class CartPayService {
    constructor(
        private _paymentService: PaymentService,
        private _customerService: CustomerService,
        private _paymentFluxService: PaymentFluxService
    ) {}

    /**
     * @method pay()
     * @param (request: CartPayRequest)
     * @description Pay cart
     */
    pay(request: CartPayRequest, isCartBillV2: boolean): Observable<PaymentResponse> {
        let currentCart: PaymentCart[];
        const { paymentMethod, cartBill, payload } = request;
        if (isCartBillV2) {
            const paymentRequest = this.getPaymentRequestV2(cartBill as Cart);
            const openInvoices = this.getOpenInvoicesV2(cartBill as Cart);
            currentCart = [...paymentRequest, ...openInvoices];
        } else {
            currentCart = (cartBill as CartBill).cart;
        }
        const clientCart = this._buildClientCart(currentCart);
        const paymentRequest =
            paymentMethod.method === PaymentMethods.PAYPAL
                ? this._buildPaypalPaymentRequest(
                      paymentMethod.method,
                      cartBill,
                      payload as PayloadPayPal,
                      currentCart,
                      (<PayloadGuest>payload).paymentMetadata
                  )
                : this._customerService.getCustomer().isGuest
                  ? this._buildGuestPaymentRequest(currentCart, payload as PayloadGuest)
                  : this._buildPaymentRequest(paymentMethod.method, currentCart, payload as PaymentToken);
        const config: Config = {
            token: this._customerService.getCurrentUser(),
        };

        return this._paymentService.pay(paymentMethod.method, clientCart, paymentRequest, config);
    }

    getPaymentRequestV2(cartBill: Cart): any {
        return cartBill?.cartBillResponseDTOList
            .map((cartBillResponse: CartAWB) => {
                return cartBillResponse.paymentRequest;
            })
            .flat()
            .filter((paymentCart: PaymentCart) => !paymentCart.originalInvoice);
    }

    getOpenInvoicesV2(cartBill: Cart): any {
        return cartBill?.cartBillResponseDTOList
            .map((cartBillResponse: CartAWB) => {
                return cartBillResponse.paymentRequest;
            })
            .flat()
            .filter((paymentCart: PaymentCart) => paymentCart.originalInvoice);
    }

    /**
     * @method _buildClientCart()
     * @param (cart: PaymentCart[])
     * @description Prepare client cart to make payment
     */

    private _buildClientCart(cart: PaymentCart[]): PaymentCart[] {
        return cart.map((item) => {
            const clientObject = {
                ...item,
                cargoFacility: item.facility?.name || '',
                paidTo: item.facility?.paidTo || '',
            };

            if (this._customerService.getCustomer().isGuest) {
                delete clientObject.customer;
                delete clientObject.originalRequestor;
            }

            return clientObject;
        });
    }

    /**
     * @method _buildPaymentRequest()
     * @param (paymentMethod: PaymentMethodsType)
     * @param (cartBill: CartBill)
     * @param (payload: PaymentToken)
     * @description Prepare payment request for CreditCard, Echeck and CargosprintCredit payment
     */

    private _buildPaymentRequest(
        paymentMethod: PaymentMethodsType,
        currentCart: PaymentCart[],
        payload: PaymentToken
    ): PaymentRequest {
        const customerReference = this._paymentFluxService.instant_customer_reference;
        const paymentToken = JSON.parse(this._customerService.getDecodedToken().SOURCE).enabledPaymentMethods.find(
            ({ paymentMethod }: EnabledPaymentType) =>
                paymentMethod.name === ComponentPaymentMethodName.CARGOSPRINT_CREDIT
        ).paymentMethod.paymentToken;
        const paymentRequest: PaymentRequest = {
            paymentToken,
            paymentMethod,
            clientCart: this._buildClientCart(currentCart),
            customerReference,
        };

        if (paymentMethod === PaymentMethods.CREDIT_CARD || paymentMethod === PaymentMethods.ECHECK) {
            paymentRequest.paymentToken = payload;
        }

        return paymentRequest;
    }

    /**
     * @method _buildPaypalPaymentRequest()
     * @param (paymentMethod: PaymentMethodsType)
     * @param (cartBill: CartBill)
     * @param (payload: PayloadPayPal)
     * @description Prepare payment request for Paypal payment
     */

    private _buildPaypalPaymentRequest(
        paymentMethod: PaymentMethodsType,
        cartBill: CartBill | Cart,
        payload: PayloadPayPal,
        currentCart: PaymentCart[],
        paymentMetadata?: PaymentMetaData
    ): PaymentRequest {
        const customerReference = this._paymentFluxService.instant_customer_reference;
        const paymentToken = JSON.parse(this._customerService.getDecodedToken().SOURCE).enabledPaymentMethods.find(
            ({ paymentMethod }: EnabledPaymentType) => paymentMethod.name === ComponentPaymentMethodName.PAYPAL
        ).paymentMethod.paymentToken;

        return {
            paymentMetaData: paymentMetadata,
            payPalPaymentDataRequest: {
                accountType: FlowType.Checkout,
                amount: cartBill.total,
                email: payload.details?.email || '',
                firstName: payload.details?.firstName || '',
                fullName: payload.details?.shippingAddress?.recipientName,
                lastName: payload.details?.lastName || '',
                payerId: payload.details?.payerId || '',
                paymentNonce: payload.nonce || '',
                phoneNumber: this._customerService.getCustomer().phone,
            },
            paymentToken,
            clientCart: this._buildClientCart(currentCart),
            paymentMethod,
            customerReference,
        };
    }

    /**
     * @method _buildGuestPaymentRequest()
     * @param (cartBill: CartBill)
     * @param (payload: PayloadGuest)
     * @description Prepare payment request for guest customer
     */

    private _buildGuestPaymentRequest(currentCart: PaymentCart[], payload: PayloadGuest): PaymentRequest {
        const customerReference = this._paymentFluxService.instant_customer_reference;

        return {
            paymentMetaData: {
                ...payload.paymentMetadata,
                customerReference,
            },
            braintreePaymentRequest: {
                ...payload.paymentMetadata,
                customerReference,
                paymentNonce: payload.nonce,
            },
            customerReference,
            paymentNonce: payload.nonce,
            paymentToken: {
                paymentMethod: {
                    name: ComponentPaymentMethodName.CREDIT_CARD,
                    logTime: 10,
                    componentName: PaymentMethods.CREDIT_CARD,
                },
                paymentProcessor: {
                    name: ProcessorName.Braintree,
                },
                token: null,
                paymentMethodNumber: payload.paymentMethodNumber,
                paymentInstitution: payload.paymentInstitution,
                paymentMethodExpirationMonth: payload.paymentMethodExpirationMonth,
                paymentMethodExpirationYear: payload.paymentMethodExpirationYear,
            },
            clientCart: this._buildClientCart(currentCart),
            paymentMethod: PaymentMethods.CREDIT_CARD,
        };
    }
}
