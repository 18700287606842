import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { permissionsSection } from '../../../../utils/constants';
import { NgxPermissionsGuard } from 'ngx-permissions';

// COMPONENTS

import { PaidComponent } from './paid.component';

const routes: Routes = [
    {
        path: '',
        title: 'SprintPay | Paid invoices',
        component: PaidComponent,
        canLoad: [NgxPermissionsGuard],
        data: {
            breadcrumb: { alias: 'paid' },
            permissions: {
                only: permissionsSection.invoices,
                redirectTo: 'admin/home',
            },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PaidRoutingModule {}
