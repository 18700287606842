<!--START: DYNAMIC CHECKBOX FIELD-->
<form [formGroup]="dynamicFormGroup" class="row">
    <div class="col-12 col-lg-9">
        <div class="mb-3">
            <mat-label>{{field.label}}</mat-label>
            <div class="d-flex flex-column w-100 mt-2">
                <mat-checkbox
                    [required]="dynamic_required"
                    *ngFor="let option of field.options"
                    [id]="option.value"
                    [name]="option.value"
                    [formControlName]="option.value"
                    class="d-inline-block"
                    color="primary"
                    >{{option.displayValue}}</mat-checkbox
                >
            </div>
            <!--START: ERRORS-->
            <mat-error
                *ngIf="dynamicFormGroup.controls[field.name].invalid && (dynamicFormGroup.controls[field.name].dirty || dynamicFormGroup.controls[field.name].touched)"
            >
                <mat-error *ngIf="dynamicFormGroup.controls[field.name].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i
                    >{{field.inputValidations?.['required']?.errorMessage || 'This field it\'s required'}}
                </mat-error>
            </mat-error>
            <!--END: ERRORS-->
        </div>
    </div>
</form>
<!--END: DYNAMIC CHECKBOX FIELD-->
