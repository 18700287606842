import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisableControlDirective } from 'src/app/directives/disable-control.directive';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddMoreContainersComponent } from './add-more-containers/add-more-containers.component';
import { AmountToPayComponent } from './amount-to-pay/amount-to-pay.component';
import { AwbComponent } from './awb/awb.component';
import { PaymentDetailComponent } from './payment-detail.component';
import { PaymentTypeComponent } from './payment-type/payment-type.component';
import { PickupDateComponent } from './pickup-date/pickup-date.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { VinEdocNextgenComponent } from './vin-edoc-nextgen/vin-edoc-nextgen.component';

@NgModule({
    imports: [
        UploadFileComponent,
        VinEdocNextgenComponent,
        AddMoreContainersComponent,
        AmountToPayComponent,
        AwbComponent,
        PaymentTypeComponent,
        PickupDateComponent,
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        DisableControlDirective,
    ],
    declarations: [PaymentDetailComponent],
    exports: [PaymentDetailComponent],
})
export class PaymentDetailModule {}
