<mat-dialog-content id="refund-amount-update-modal">
    <!-- START: REFUND AMOUNT UPDATE POPUP -->
    <section id="refund-amount-update" class="w-100">
        <div id="refund-amount-update__modal" class="container-fluid">
            <div class="row header">
                <!-- START: HEADER -->
                <div class="text-center">
                    <h4 class="mb-0">{{popupData.title || ''}}</h4>
                </div>
                <!-- END: HEADER -->
            </div>
            <div class="refund-logo d-flex justify-content-center align-items-center">
                <img src="{{popupData.icon}}" alt="Refund amount update" height="auto" width="auto" />
            </div>
            <ng-container *ngIf="!popupData?.hasRefundFee else refundFee">
                <div class="info-container">
                    <div class="reference-number text-center justify-content-center align-items-center">
                        <h6 class="description mb-0 text-center">Payment Reference #:</h6>
                        <div class="reference-number__info">
                            {{popupData.vendorInvoiceNumber | formatPaymentReference: popupData.id?.toString()}}
                        </div>
                    </div>
                </div>
                <div class="info-container mb-4" *ngIf="activeProfileView === profileTypes.FORWARDER">
                    <div class="reference-number refund-fee">
                        <div class="mb-0 d-flex">
                            <p class="title"><mat-icon class="icon-display">info</mat-icon> &nbsp; Refund fee:</p>
                            <div class="info px-3">
                                This facility may charge a refund fee.
                                <strong>CargoSprint never charges a refund fee</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-template #refundFee>
                <div class="text-center header-info my-4">
                    <p class="label-amount">Original amount:</p>
                    <div class="amount mb-2">USD {{popupData.amount | currency }}</div>
                    <div class="refund-reason">Refund reason: <strong>{{popupData.refundReason}}</strong></div>
                </div>
                <div class="info-container">
                    <div class="reference-number text-center justify-content-center align-items-center">
                        <h6 class="description mb-0 text-center">Payment Reference #:</h6>
                        <div class="reference-number__info">
                            {{popupData.vendorInvoiceNumber | formatPaymentReference: popupData.id?.toString()}}
                        </div>
                    </div>
                </div>
                <div class="info-container">
                    <div class="reference-number text-center justify-content-center align-items-center">
                        <h6 class="description mb-0 text-center">Email:</h6>
                        <div class="reference-number__info">{{popupData.notificationEmail}}</div>
                    </div>
                </div>
            </ng-template>

            <form class="row" [formGroup]="refundForm">
                <div class="col-12">
                    <div class="my-4" *ngIf="popupData?.hasRefundFee">
                        <mat-checkbox
                            class="terms-of-use-checkbox"
                            color="primary"
                            [checked]=""
                            (change)="haveRefundFee($event.checked)"
                        ></mat-checkbox>
                        Do you have a refund fee?
                    </div>
                    <div class="form-group" *ngIf="hasRefundFee">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>Fee amount</mat-label>
                            <input
                                matInput
                                type="text"
                                id="refundFee"
                                formControlName="refundFee"
                                autocomplete="off"
                                placeholder="Fee amount"
                                data-cy="refundFee"
                                [errorStateMatcher]="matcher"
                                [allowNegativeNumbers]="false"
                                mask="separator.2"
                                thousandSeparator=","
                                prefix="$"
                                decimalMarker="."
                            />
                            <mat-error *ngIf="refundForm.controls['refundFee'].hasError('invalid')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Refund fee cannot be greater than
                                the original amount
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>Refund {{popupData.hasRefundFee ? 'approved' : 'request'}} amount</mat-label>
                            <input
                                matInput
                                type="text"
                                id="amount"
                                name="amount"
                                formControlName="amount"
                                autocomplete="off"
                                placeholder="Amount"
                                data-cy="amount"
                                [errorStateMatcher]="matcher"
                                [allowNegativeNumbers]="false"
                                mask="separator.2"
                                thousandSeparator=","
                                prefix="$"
                                decimalMarker="."
                            />
                            <mat-error *ngIf="refundForm.controls['amount'].hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Amount is required
                            </mat-error>
                            <mat-error *ngIf="refundForm.controls['amount'].hasError('min')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>The amount needs to be > 0
                            </mat-error>
                            <mat-error *ngIf="refundForm.controls['amount'].hasError('invalid')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Refund amount cannot be greater than
                                the original amount
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="!popupData?.hasRefundFee">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>Refund reason</mat-label>
                            <mat-select
                                id="refundReason"
                                name="refundReason"
                                formControlName="refundReason"
                                data-cy="refundReason"
                                [errorStateMatcher]="matcher"
                                (selectionChange)="resetCustomerNotes($event)"
                                [(ngModel)]="selected"
                            >
                                <mat-option *ngFor="let reason of refundReasons" [value]="reason">
                                    {{reason}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div
                        class="form-group"
                        *ngIf="showNotes || (!popupData.hasRefundFee && refundReason?.value === 'Other')"
                    >
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>{{popupData.hasRefundFee ? 'Notes': 'Reason'}}</mat-label>
                            <textarea
                                matInput
                                id="customerNotes"
                                name="customerNotes"
                                formControlName="customerNotes"
                                autocomplete="off"
                                placeholder="{{popupData.customNotesPlaceholder || ''}}"
                                data-cy="customerNotes"
                                [errorStateMatcher]="matcher"
                            ></textarea>
                            <mat-error *ngIf="refundForm.controls['customerNotes'].hasError('maxlength')">
                                Custom reason cannot be greater than the max length of 255
                            </mat-error>
                            <mat-error *ngIf="refundForm.controls['customerNotes'].hasError('minlength')">
                                Minimum of 5 symbols
                            </mat-error>
                            <mat-error *ngIf="refundForm.controls['customerNotes'].hasError('required')">
                                Required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <ng-container *ngIf="hasRefundFee">
                        <div class="info-container mb-4">
                            <div class="reference-number refund-fee">
                                <div class="mb-0 d-flex">
                                    <p class="title">
                                        <mat-icon class="icon-display">info</mat-icon>
                                    </p>
                                    <div class="info px-3 w-100">
                                        <strong>*Refund fee will be deducted from the Approved amount</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </form>

            <!-- START: BUTTON ACTIONS -->
            <div class="row">
                <div class="col-12">
                    <div id="download__actions" mat-dialog-actions class="pb-4 text-end d-block pt-1 pt-md-3">
                        <button
                            mat-button
                            type="button"
                            (click)="closeDialog()"
                            class="btn__sprintpay btn__sprintpay--link p-0 m-0 border-0 text-uppercase text-center"
                        >
                            {{popupData.cancelButtonText}}
                        </button>
                        <button
                            mat-button
                            type="button"
                            (click)="refund()"
                            class="btn__sprintpay btn__sprintpay--green p-0 m-0 border-0 rounded-0 text-uppercase text-center"
                            [disabled]="refundForm.invalid"
                        >
                            {{popupData.confirmButtonText}}
                        </button>
                    </div>
                </div>
            </div>
            <!-- END: BUTTON ACTIONS -->
        </div>
    </section>
    <!-- END: REFUND AMOUNT UPDATE POPUP POPUP -->
</mat-dialog-content>
