<!-- START: FREQUENT FACILITIES -->
<section id="new_user" class="container-fluid">
    <div class="d-flex flex-column h-100 justify-content-center align-items-center new_user--height">
        <div class="row">
            <div class="col-12">
                <img
                    class="mx-auto d-block"
                    src="assets/images/home/frequent-facilities.svg"
                    alt="New user illustration"
                />
            </div>
            <div class="col-12">
                <h4 class="d-block text-center mt-4 mb-3">Hello !</h4>
                <p class="d-block text-center mt-3 mb-4">
                    You don't have any data yet.<span *ngIf="isValidNewPayment">
                        Would you like to make a new payment?</span
                    >
                </p>
            </div>
            <div class="col-12" *ngIf="isValidNewPayment">
                <button
                    [routerLink]="['../facilityPayments/newPayment']"
                    mat-button
                    type="button"
                    class="text-uppercase btn__sprintpay btn__sprintpay--green d-block mx-auto mb-3"
                >
                    Make new payment
                </button>
            </div>
        </div>
    </div>
</section>
<!-- END: FREQUENT FACILITIES -->
