import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { PaymentToRequestEditService } from '../../../services/payment-to-request-edit.service';
import { PaymentFluxService } from '../../../services/utils/payment-flux.service';

@Component({
    selector: 'app-geodis-customer-reference',
    standalone: true,
    imports: [CommonModule, MatButtonModule],
    templateUrl: './geodis-customer-reference.component.html',
})
export class GeodisCustomerReferenceComponent implements OnInit {
    hasExternalLookup: boolean = false;
    id: string;
    isStartingLevel: boolean;
    private _currentPayment: any;

    @Input() paymentDetail: any;

    constructor(
        private _paymentToRequestEditService: PaymentToRequestEditService,
        private _router: Router,
        private _paymentFluxService: PaymentFluxService,
        private _customerService: CustomerService
    ) {
        this.id = '';
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.isStartingLevel = this._customerService.getStartingLevel();
    }

    ngOnInit(): void {
        this.id =
            this.paymentDetail && this.paymentDetail.id
                ? this.paymentDetail.id
                : this._paymentFluxService.getCurrentPayment() !== undefined
                  ? this._paymentFluxService.getCurrentPayment().id
                  : '';
        this.hasExternalLookup =
            this.paymentDetail && this.paymentDetail.externalLookup
                ? this.paymentDetail.externalLookup
                : this._currentPayment && this._currentPayment.facility
                  ? this._currentPayment.facility.externalLookup
                  : false;
    }

    /**
     * @method editPaymentRequest()
     * @description First we review if the payment request has an ID, if we have and ID we call the service to generate the necessary data for the payment request process
     */

    editPaymentRequest() {
        const redirect: string = this.paymentDetail.redirectRoute ? this.paymentDetail.redirectRoute : this._router.url;
        this._paymentFluxService.setCurrentPayment(this.paymentDetail);
        this._router.navigate(['/admin/facilityPayments/newPayment/flux/geodisReference']);
    }
}
