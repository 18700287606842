import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';

@Injectable({ providedIn: 'root' })
export class FileAPIService {
    private _cartUrl: string;

    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenService
    ) {
        this._cartUrl = environment.uris.method.cart;
    }

    downloadFileRequest(path: string): Observable<Blob> {
        const responseType = 'blob';
        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this._tokenService.getCurrentUser()}`,
        });

        return this._httpClient
            .get(this._cartUrl + '/getPaymentRequestAttachment/?attachmentLocation=' + path, {
                headers,
                responseType,
            })
            .pipe(
                catchError(() =>
                    throwError(() => ({
                        error: { errors: ['An error occurred while processing your request, please try again'] },
                    }))
                )
            );
    }

    uploadFileRequest(formData: FormData): Observable<string> {
        const headers: HttpHeaders = new HttpHeaders().set(
            'Authorization',
            `Bearer ${this._tokenService.getCurrentUser()}`
        );
        const responseType = 'text';
        return this._httpClient.post(this._cartUrl + '/uploadPaymentRequestAttachment', formData, {
            headers,
            responseType,
        });
    }

    removeFileRequest(path: string): Observable<string> {
        const header: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this._tokenService.getCurrentUser()}`,
        });
        return this._httpClient
            .delete(this._cartUrl + '/removePaymentRequestAttachment?attachmentLocation=' + path, {
                headers: header,
            })
            .pipe(map(() => 'Complete'));
    }

    downloadPaymentRequestAttachment(attachmentLocations: string[]): Observable<Blob> {
        const responseType = 'blob';
        const headers: HttpHeaders = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
        headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient
            .post(
                this._cartUrl + '/getPaymentRequestAttachment/zip',
                { attachmentLocations },
                {
                    headers,
                    responseType,
                }
            )
            .pipe(
                catchError(() =>
                    throwError(() => ({
                        error: { errors: ['An error occurred while processing your request, please try again'] },
                    }))
                )
            );
    }
    downloadApprovalPayments(dates: string): Observable<Blob> {
        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-type', 'application/pdf')
            .set('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`)
            .set('Accept', 'application/vnd.ms-excel, application/json, application/pdf');
        return this._httpClient.get(this._cartUrl + '/downloadApprovalPayments/?' + dates, {
            headers,
            responseType: 'blob',
        });
    }
}
