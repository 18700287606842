<div id="bulkPaymentResult">
    <h5>{{data.requestTitleTxt}}</h5>
    <p *ngIf="data.requestStatusTxt">
        {{data.requestStatusCountTxt}} <span class="text-muted">{{data.requestStatusTxt}}</span>
    </p>
    <p *ngIf="data.requestHelpTxt">{{data.requestHelpTxt}}</p>
    <p *ngIf="data.requestHelpNoteTxt" class="font-italic">
        Note: <span class="text-muted">{{data.requestHelpNoteTxt}}</span>
    </p>
    <br />

    <div class="tableHeader">
        <table class="w-100 mb-0">
            <thead>
                <tr>
                    <th scope="col" class="col-3 mat-caption text-start pb-3 fw-bold text-uppercase">{{awbHeader}}</th>
                    <th scope="col" class="col-3 mat-caption text-start pb-3 fw-bold text-uppercase">{{hawbHeader}}</th>
                    <th
                        scope="col"
                        class="col-3 mat-caption text-start pb-3 fw-bold text-uppercase"
                        style="line-height: 24px"
                    >
                        {{customerRefHeader}}
                    </th>
                    <th scope="col" class="col-3 mat-caption text-start pb-3 fw-bold text-uppercase">Status</th>
                </tr>
            </thead>
        </table>
    </div>

    <ng-container *ngFor="let element of data.data">
        <mat-expansion-panel
            class="mat-elevation-z0"
            togglePosition="before"
            [disabled]="!element.errors || (element.errors && element.errors.length < 2)"
        >
            <mat-expansion-panel-header collapsedHeight="max-content" expandedHeight="max-content">
                <table class="w-100">
                    <tbody>
                        <tr>
                            <td class="col-3 mat-body-2 text-start fw-normal">
                                <mat-icon
                                    *ngIf="element.status && statusIconMap[element.status.trim().toLowerCase()]"
                                    class="statusIcon material-symbols-outlined"
                                    [class.warning]="element.status.trim().toLowerCase() === 'failed'"
                                    >{{statusIconMap[element.status.trim().toLowerCase()]}}</mat-icon
                                >
                                <span>{{element.awb | notAvailable}}</span>
                            </td>
                            <td class="col-3 mat-body-2 text-start fw-normal">{{element.hawb | notAvailable}}</td>
                            <td class="col-3 mat-caption text-start fw-bold">
                                <ng-container *ngIf="element.errors && element.errors.length"
                                    >{{element.errors[0].column | notAvailable}}</ng-container
                                >
                                <ng-container *ngIf="!element.errors"
                                    >{{element.customerReference | notAvailable}}</ng-container
                                >
                            </td>
                            <td class="col-3 mat-body-2 text-start text-muted fw-normal">
                                <ng-container *ngIf="element.errors && element.errors.length"
                                    >{{element.errors[0].message | notAvailable}}</ng-container
                                >
                                <ng-container *ngIf="!element.errors">{{element.status | notAvailable}}</ng-container>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-expansion-panel-header>
            <table *ngIf="element.errors && element.errors.length > 1">
                <tbody>
                    <tr *ngFor="let errorItem of element.errors.slice(1)">
                        <td class="col-6">&nbsp;</td>
                        <td class="col-3 mat-caption text-start fw-bold">{{errorItem.column | notAvailable}}</td>
                        <td class="col-3 mat-body-2 text-start text-muted fw-normal">
                            {{errorItem.message | notAvailable}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </mat-expansion-panel>
    </ng-container>

    <div class="row">
        <div class="col-12 d-flex justify-content-center actions">
            <button
                *ngIf="data.uploadAgainOutlinedBtnCondition"
                mat-stroked-button
                type="button"
                class="d-block text-uppercase btn__sprintpay mx-2 outlinedBtn bulkUploadAgain"
                (click)="emitAction('uploadAgain')"
            >
                Upload again
            </button>
            <button
                *ngIf="data.goToPendingApprovalsBtnCondition"
                mat-stroked-button
                type="button"
                class="upload-again-btn d-block text-uppercase btn__sprintpay btn__sprintpay--green mx-2 outlinedBtn bulkGoToPendingApproval"
                (click)="emitAction('goToPendingApprovals')"
            >
                Go to pending approvals
            </button>
            <button
                *ngIf="data.backToHomeBtnCondition"
                mat-button
                type="button"
                class="rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green mx-2 bulkGoToHome"
                (click)="emitAction('goToHome')"
            >
                Back To Home
            </button>
            <button
                *ngIf="data.goToHomeBtnCondition"
                mat-button
                type="button"
                class="rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green mx-2 bulkGoToHome"
                (click)="emitAction('goToHome')"
            >
                Go to Home
            </button>
            <button
                *ngIf="data.goToCartBtnCondition"
                mat-button
                type="button"
                class="d-block text-uppercase btn__sprintpay btn__sprintpay--green mx-2 bulkGoToCart"
                (click)="emitAction('forceToNext')"
            >
                Go To Cart
            </button>
            <button
                *ngIf="data.uploadAgainBtnCondition"
                mat-button
                type="button"
                class="d-block text-uppercase btn__sprintpay btn__sprintpay--green mx-2 bulkUploadAgain"
                (click)="emitAction('uploadAgain')"
            >
                Upload again
            </button>
        </div>
    </div>
</div>
