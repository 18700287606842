<section id="payment-detail" class="my-3 py-4">
    <div class="row">
        <div class="col-12">
            <span class="payment-detail--titles d-block mx-3 mb-3"> Payment method(s) </span>
            <!-- START: PAYMENT METHOD TEMPLATES USER -->
            <ng-container *ngxPermissionsExcept="'GUEST'">
                <!-- START: PAYMENT METHOD HOLDER FOR CART -->
                <div
                    *ngIf="!isSelectingPaymentMethod; else loader"
                    class="d-flex justify-content-start flex-row align-items-center mx-2 mb-2"
                >
                    <div class="d-flex flex-column flex-lg-row align-items-center mx-2 w-100">
                        <div class="flex-grow-1" *ngIf="paymentMethod?.method">
                            <div *ngIf="paymentMethods[paymentMethod.method] === paymentMethods.CREDIT_CARD">
                                <span
                                    class="payment-detail--method d-block mb-1"
                                    *ngIf="accountingDetails?.accountingDetails"
                                >
                                    <img
                                        *ngIf="accountingDetails.accountingDetails?.cardType === 'VISA'"
                                        src="assets/images/credit-card-brand/types/visa.svg"
                                        alt="Visa logo"
                                        class="me-3"
                                    />
                                    <img
                                        *ngIf="accountingDetails.accountingDetails?.cardType === 'MASTERCARD'"
                                        src="assets/images/credit-card-brand/types/mastercard.svg"
                                        alt="Mastercard logo"
                                        class="me-3"
                                    />
                                    <img
                                        *ngIf="accountingDetails.accountingDetails?.cardType === 'DISCOVER'"
                                        src="assets/images/credit-card-brand/types/discover.svg"
                                        alt="Discover logo"
                                        class="me-3"
                                    />
                                    <img
                                        *ngIf="accountingDetails.accountingDetails?.cardType === 'AMERICAN EXPRESS'"
                                        src="assets/images/credit-card-brand/types/american-express.svg"
                                        alt="Amex logo"
                                        class="me-3"
                                    />
                                    <img
                                        *ngIf="accountingDetails.accountingDetails?.cardType === 'JCB'"
                                        src="assets/images/credit-card-brand/types/jcb.svg"
                                        alt="JCB logo"
                                        class="me-3"
                                    />

                                    Ending ···
                                    <strong class="fw-bold"
                                        >{{accountingDetails.accountingDetails?.lastFourDigits}}</strong
                                    >
                                </span>
                                <div class="w-100" *ngIf="!accountingDetails?.accountingDetails">
                                    <span class="d-inline-block"
                                        ><i
                                            class="bi bi-exclamation-circle-fill align-items-center me-2 d-inline-block"
                                        ></i
                                        ><small>Please select a credit card</small></span
                                    >
                                </div>
                            </div>
                            <div *ngIf="paymentMethods[paymentMethod.method] === paymentMethods.ECHECK ">
                                <span
                                    class="payment-detail--method d-block mb-1"
                                    *ngIf="accountingDetails?.accountingDetails"
                                >
                                    <img
                                        src="assets/images/credit-card-brand/echeck.svg"
                                        alt="eCheck logo"
                                        class="me-3"
                                    />
                                    Account ending ···
                                    <strong class="fw-bold"
                                        >{{accountingDetails?.accountingDetails?.accountLastFourDigits}}</strong
                                    >
                                </span>

                                <div class="w-100" *ngIf="!accountingDetails?.accountingDetails">
                                    <span class="d-inline-block"
                                        ><i
                                            class="bi bi-exclamation-circle-fill align-items-center me-2 d-inline-block"
                                        ></i
                                        ><small>Please select an e-check</small></span
                                    >
                                </div>
                            </div>
                            <div *ngIf="paymentMethods[paymentMethod.method] === paymentMethods.CARGOSPRINT_CREDIT">
                                <span class="payment-detail--method d-block">
                                    <img
                                        src="assets/images/sprintpay-horizontal-logo.svg"
                                        alt="Cargo sprint credit logo"
                                        class="imgMain me-3"
                                    />
                                    Bill my SprintPay account
                                </span>
                            </div>
                            <div *ngIf="paymentMethods[paymentMethod.method] === paymentMethods.PAYPAL">
                                <span class="payment-detail--method d-block">
                                    <img src="assets/images/paypal.svg" alt="Paypal logo" class="imgMain me-3" />
                                    Paypal payment
                                </span>
                            </div>
                            <div class="w-100" *ngIf="!paymentMethod?.method">
                                <span class="d-inline-block"
                                    ><i class="bi bi-exclamation-circle-fill align-items-center me-2 d-inline-block"></i
                                    ><small>You don't have any payment method yet</small></span
                                >
                            </div>
                        </div>
                        <!-- START: CHANGE/SELECT PAYMENT METHOD BUTTON -->
                        <ng-container *ngIf="isChangePaymentMethodButtonAvailable">
                            <div>
                                <button
                                    mat-button
                                    type="submit"
                                    color="primary"
                                    class="btn__sprintpay btn__sprintpay--link payment-detail--methodChange text-uppercase align-items-center d-block float-start float-lg-end"
                                    (click)="openDialog(paymentMethodsCart)"
                                >
                                    {{paymentMethod && paymentMethod.method ? 'CHANGE' : 'SELECT PAYMENT METHOD'}}
                                </button>
                            </div>
                        </ng-container>
                        <!-- END: CHANGE/SELECT PAYMENT METHOD BUTTON -->
                        <!-- START: ADD PAYMENT METHOD BUTTON -->
                        <ng-container *ngIf="!paymentMethod && !isChangePaymentMethodButtonAvailable">
                            <div class="w-100">
                                <button
                                    mat-button
                                    type="submit"
                                    class="btn__sprintpay btn__sprintpay--link payment-detail--methodChange text-uppercase align-items-center d-block float-start float-lg-end"
                                    (click)="addNewPaymentMethod()"
                                >
                                    ADD PAYMENT METHOD
                                </button>
                            </div>
                        </ng-container>
                        <!-- END: ADD PAYMENT METHOD BUTTON -->
                    </div>
                    <!-- END: PAYMENT METHOD HOLDER FOR CART -->
                </div>
                <!--START: LOADER -->
                <ng-template #loader>
                    <div class="d-flex justify-content-center">
                        <mat-spinner [diameter]="30"></mat-spinner>
                    </div>
                </ng-template>
                <!--END: LOADER -->
                <!-- START: SPCREDIT FOR INVOICES MESSAGE -->
                <ng-container
                    *ngIf="invoicesInCart && paymentMethod && paymentMethods[paymentMethod.method] === paymentMethods.CARGOSPRINT_CREDIT"
                >
                    <div class="col-12">
                        <div class="d-flex justify-content-start flex-row align-items-center mx-2">
                            <div class="d-flex flex-column flex-lg-row align-items-center mx-2 w-100">
                                <div class="d-block mb-2 w-100">
                                    <small class="text-muted mt-2 d-block"
                                        ><strong>SprintPay Credit</strong>&nbsp;is unavailable because you have open
                                        invoices in your cart. If you want to use your credit, please empty your
                                        cart.</small
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- END: SPCREDIT FOR INVOICES MESSAGE -->
                <!-- START: SPCREDIT AVAILABLE CREDIT -->
                <ng-container
                    *ngIf="paymentMethod && (paymentMethods[paymentMethod.method] === paymentMethods.CARGOSPRINT_CREDIT)"
                >
                    <span class="payment-detail--method d-block mb-1 px-3 text-muted payment-detail--split--titles"
                        >Credit available: {{sprintPayCreditAmount.availableCredit | currency}}</span
                    >
                </ng-container>
                <!-- END: SPCREDIT  AVAILABLE CREDIT -->
            </ng-container>
            <!-- END: PAYMENT METHOD TEMPLATES USER -->
            <!-- START: PAYMENT METHOD TEMPLATES GUEST -->
            <ng-container *ngxPermissionsOnly="'GUEST'">
                <app-guest-payment-method
                    [loadingPayPalRequest]="loadingPayPalRequest"
                    [paymentMethod]="paymentMethod"
                    (selectPaymentMethod)="onPaymentMethodChange($event)"
                ></app-guest-payment-method>
            </ng-container>
            <!-- END: PAYMENT METHOD TEMPLATES GUEST -->
            <mat-divider class="m-3"></mat-divider>
            <span class="payment-detail--titles d-block mx-3 mb-3">Balance</span>
            <!-- START: SUBTOTAL -->
            <div *ngIf="subTotal" class="d-flex justify-content-start flex-row align-items-center mx-2">
                <div class="d-flex flex-column flex-lg-row align-items-center mx-2 w-100">
                    <span class="payment-detail--split--titles d-block mb-2 w-100">Subtotal</span>
                    <span class="payment-detail--split--amounts mb-1 fw-bold w-100 text-end"
                        >USD {{ subTotal | currency }}</span
                    >
                </div>
            </div>
            <!-- END: SUBTOTAL -->
            <!-- START: ISC FEE -->
            <div
                *ngIf="cartBill && cartBill.iscFee"
                class="d-flex justify-content-start flex-row align-items-center mx-2"
            >
                <div class="d-flex flex-column flex-lg-row align-items-center mx-2 w-100">
                    <span class="payment-detail--split--titles d-block mb-2 w-100"
                        >{{hasAGIPayments ? 'AGI Convenience Fee (ISC)':'ISC'}}</span
                    >
                    <span class="payment-detail--split--amounts mb-1 fw-bold w-100 text-end"
                        >USD {{ cartBill.iscFee | currency }}</span
                    >
                </div>
            </div>
            <!-- END: ISC FEE -->
            <!-- START: STORAGE -->
            <div
                *ngIf="cartBill && cartBill.nonIscFee"
                class="d-flex justify-content-start flex-row align-items-center mx-2"
            >
                <div class="d-flex flex-column flex-lg-row align-items-center mx-2 w-100">
                    <span class="payment-detail--split--titles d-block mb-2 w-100"
                        >{{hasAGIPayments ? 'AGI Convenience Fee (Storage/Others)':'Storage/Others'}}</span
                    >
                    <span class="payment-detail--split--amounts mb-1 fw-bold w-100 text-end"
                        >USD {{ cartBill.nonIscFee | currency }}</span
                    >
                </div>
            </div>
            <!-- END: STORAGE -->
            <!-- START: CUSTOMS FEE -->
            <div
                *ngIf="cartBill && cartBill.customsFee"
                class="d-flex justify-content-start flex-row align-items-center mx-2"
            >
                <div class="d-flex flex-column flex-lg-row align-items-center mx-2 w-100">
                    <span class="payment-detail--split--titles d-block mb-2 w-100">{{ cartBill.customsFeeLabel }}</span>
                    <span class="payment-detail--split--amounts mb-1 fw-bold w-100 text-end"
                        >USD {{ cartBill.customsFee | currency }}</span
                    >
                </div>
            </div>
            <!-- END: CUSTOMS FEE -->
            <!-- START: STATION FEE -->
            <ng-container *ngIf="cartBill && cartBill.stationFees">
                <div
                    *ngFor="let stationFee of cartBill.stationFees"
                    class="d-flex justify-content-start flex-row align-items-center mx-2"
                >
                    <div class="d-flex flex-column flex-lg-row align-items-center mx-2 w-100">
                        <span class="payment-detail--split--titles d-block mb-2 w-100">{{ stationFee.label }}</span>
                        <span class="payment-detail--split--amounts mb-1 fw-bold w-100 text-end"
                            >USD {{ stationFee.amount | currency }}</span
                        >
                    </div>
                </div>
            </ng-container>

            <!-- END: STATION FEE -->
            <!-- START: NON RESERVATION FEE -->
            <div
                *ngIf="cartBill?.nonReservationFee"
                class="d-flex justify-content-start flex-row align-items-center mx-2"
            >
                <div class="d-flex flex-column flex-lg-row align-items-center mx-2 w-100">
                    <span class="payment-detail--split--titles d-block mb-2 w-100">Front Counter Service Fee</span>
                    <span class="payment-detail--split--amounts mb-1 fw-bold w-100 text-end"
                        >USD {{ nonReservationFee | currency }}</span
                    >
                </div>
            </div>
            <!-- END: NON RESERVATION FEE -->
            <!-- START: HANDLINGFEE -->
            <div
                *ngIf="cartBill && cartBill.handlingFee >= 0 && !invoicesInCart"
                class="d-flex justify-content-start flex-row align-items-center mx-2"
            >
                <div class="d-flex flex-column flex-lg-row align-items-center mx-2 w-100">
                    <span class="payment-detail--split--titles d-block mb-2 w-100">Handling fee</span>
                    <span class="payment-detail--split--amounts mb-1 fw-bold w-100 text-end"
                        >USD {{ cartBill.handlingFee | currency }}</span
                    >
                </div>
            </div>
            <!-- END: HANDLINGFEE -->
            <!-- START: CREDIT CARD FEE -->
            <div
                *ngIf="cartBill && cartBill.creditCardFee >= 0 && (paymentMethods?.[paymentMethod.method] === paymentMethods.CREDIT_CARD || paymentMethods?.[paymentMethod.method] === paymentMethods.PAYPAL)"
                class="d-flex justify-content-start flex-row align-items-center mx-2"
            >
                <div class="d-flex flex-column flex-lg-row align-items-center mx-2 w-100">
                    <span class="payment-detail--split--titles d-block mb-2 w-100">Credit card fee</span>
                    <span class="payment-detail--split--amounts mb-1 fw-bold w-100 text-end"
                        >USD {{ cartBill.creditCardFee | currency }}</span
                    >
                </div>
            </div>
            <!-- END: CREDIT CARD FEE -->
            <mat-divider class="m-3"></mat-divider>
            <!-- START: TOTAL -->
            <div
                *ngIf="cartBill && cartBill.total && paymentMethod.method"
                class="d-flex justify-content-start flex-row align-items-center mx-2"
            >
                <div class="d-flex flex-column flex-lg-row align-items-center mx-2 w-100">
                    <span class="payment-detail--split--titles d-block mb-2 w-100">Total</span>
                    <span class="payment-detail--split--amounts mb-1 fw-bold w-100 text-end"
                        >USD {{ cartBill.total | currency }}</span
                    >
                </div>
            </div>
            <!-- END: TOTAL -->
            <!-- START: GUEST TERMS OF USE CHECKBOX -->
            <ng-container *ngxPermissionsOnly="'GUEST'">
                <div class="mx-3 my-3">
                    <mat-checkbox
                        class="terms-of-use-checkbox"
                        color="primary"
                        [disabled]="loadingPayPalRequest"
                        [checked]="paymentConfirmation"
                        (change)="confirmTermsOfUse()"
                    >
                        <small>To continue, please accept our <app-terms-of-use-link></app-terms-of-use-link></small>
                    </mat-checkbox>
                </div>
            </ng-container>
            <!-- END: GUEST TERMS OF USE CHECKBOX -->
            <!-- START: PAY BUTTON AND WARNING -->
            <div
                class="mx-3 mt-3"
                *ngIf="paymentMethod && paymentMethods[paymentMethod.method] !== paymentMethods.PAYPAL"
            >
                <button
                    (click)="pay()"
                    [disabled]="!(spVsTotal === true) || (invoiceLimit === true) || (invoicesInCart && paymentMethods[paymentMethod.method] === paymentMethods.CARGOSPRINT_CREDIT) || (isGuest && !paymentConfirmation) || (!this.isGuest && paymentMethods[paymentMethod.method] === paymentMethods.CREDIT_CARD && !accountingDetails?.accountingDetails) || (this.isGuest && this.paymentMethods[this.paymentMethod.method] === this.paymentMethods.CREDIT_CARD && !this.accountingDetails?.details)  || (paymentMethods[paymentMethod.method] === paymentMethods.ECHECK && !accountingDetails?.accountingDetails)"
                    mat-flat-button
                    color="primary"
                    type="submit"
                    class="d-block btn__sprintpay btn__sprintpay--green w-100 text-uppercase"
                >
                    Pay now
                </button>
                <span
                    class="d-flex align-items-center justify-content-center p-3 mt-3 fw-normal payment-warning__msg"
                    *ngIf="spVsTotalAmount < 0 && paymentMethod.method === paymentMethodsCartBill.CARGOSPRINT_CREDIT"
                    ><span class="material-symbols-outlined me-1">exclamation</span>You do not have enough credit to
                    make your payment.</span
                >
                <span
                    class="d-flex align-items-center justify-content-center p-3 mt-3 fw-normal payment-warning__msg"
                    *ngIf="invoiceLimit"
                    ><span class="material-symbols-outlined me-1">exclamation</span>Your total request is over the limit
                    of your total balance available limit, please send an email to: support&#64;cargosprint.com</span
                >
            </div>
            <!-- END: PAY BUTTON AND WARNING -->
            <!-- START: PAYPAL -->
            <div *ngIf="paymentMethod && paymentMethods[paymentMethod.method] === paymentMethods.PAYPAL" class="my-3">
                <div id="dropin-container"></div>
                <div
                    id="paypal-button"
                    class="w-100 d-flex justify-content-center px-3"
                    [ngClass]="{disabled: isGuest && !paymentConfirmation}"
                ></div>
                <div id="paypal-button-processing" class="d-none">
                    <button class="btn__sprintpay--gray primary-gray block card-summary__btnPay">
                        <span role="status" aria-hidden="true" style="margin-left: 10px"></span>
                    </button>
                </div>
            </div>
            <!-- END: PAYPAL -->
        </div>
    </div>
</section>

<ng-template #authenticationFlux>
    <app-authentication-flux></app-authentication-flux>
</ng-template>

<ng-template #paymentMethodsCart>
    <div style="padding: 10px">
        <app-payment-methods (paymentMethod)="onPaymentMethodChange($event)"></app-payment-methods>
    </div>
</ng-template>
