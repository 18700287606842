import { Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    standalone: true,
    selector: '[appDisableControl]',
})
export class DisableControlDirective implements OnInit {
    disabled!: boolean;

    @Input() set appDisableControl(condition: boolean) {
        if (this.disabled !== condition) {
            this.toggleForm(condition);
        }
        this.disabled = condition;
    }

    constructor(private ngControl: NgControl) {}

    ngOnInit(): void {
        this.toggleForm(this.disabled);
    }

    toggleForm(condition: boolean): void {
        const action = condition ? 'disable' : 'enable';
        this.ngControl.control && this.ngControl.control[action]();
    }
}
