import { Injectable } from '@angular/core';
import {
    BraintreeException,
    ComponentPaymentMethodName,
    PaymentCart,
    PaymentMethod,
    PaymentMethodItem,
    PaymentMethods,
    PaymentMethodsType,
    ProcessorName,
} from '@cargos/sprintpay-models';
import {
    BraintreeService,
    CartService as CartRequestService,
    ErrorBraintreeHandlerService,
} from '@cargos/sprintpay-services';
import { getCards } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/credit-card/getCreditCards';
import { getEChecks } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/e-checks/e-check';
import { PayPalCheckout } from 'braintree-web';
import { Observable, catchError, defaultIfEmpty, forkJoin, map, of, switchMap, take, throwError } from 'rxjs';
import {
    Card,
    Echeck,
    LatestPaymentMethodDetails,
    PaymentMethodsLatest,
} from 'src/app/models/payments/payment-methods';
import { CSCreditService } from 'src/app/services/cs-credit.service';
import { PaymentMethodsService } from 'src/app/services/requests/payment-methods.service';
import { CartService } from 'src/app/services/utils/cart.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { calculateSprintpayCredit } from 'src/app/utils/calculateSprintpayCredit';
import { Balance, CartSelectedPaymentMethod } from 'src/app/utils/cartTypes';
import { environment } from 'src/environments/environment';

@Injectable()
export class CartPaymentMethodsService {
    constructor(
        private _cartService: CartService,
        private _customerService: CustomerService,
        private _cartRequestService: CartRequestService,
        private _braintreeService: BraintreeService,
        private _errorBraintreeHandlerService: ErrorBraintreeHandlerService,
        private _paymentMethodsService: PaymentMethodsService,
        private _csCreditService: CSCreditService
    ) {}

    /**
     * @method getPaymentMethodByDefault()
     * @description Return one of available payment methods. If selected method exists return it, else tries to preselect cargosprint credit. If not available to select cargosprint credit tries to select one of credit card, echeck or paypal. Return null if all checks fail
     */

    getPaymentMethodByDefault(): Observable<CartSelectedPaymentMethod | null> {
        const paymentMethodForNewPayments: PaymentMethodsType = this._customerService.getPaymentMethodForNewPayments();
        const paymentMethodForOpenInvoices: PaymentMethodsType =
            this._customerService.getPaymentMethodForOpenInvoices();

        return this._cartService.getPayMethod().pipe(
            switchMap((paymentMethod) => {
                if (!paymentMethod) {
                    return forkJoin([
                        this.getAvailablePaymentMethods().pipe(take(1)),
                        this._checkCartBill().pipe(take(1)),
                        this._getPaymentMethods().pipe(take(1)),
                    ]).pipe(
                        switchMap(([availablePaymentMethods, { isInvoice, subTotal }, paymentMethods]) => {
                            if (paymentMethods.length === 1) {
                                return this._setPaymentMethodValidated(
                                    paymentMethods[0].name,
                                    paymentMethods,
                                    isInvoice,
                                    subTotal
                                );
                            }
                            if (
                                paymentMethods.length === 2 &&
                                isInvoice &&
                                availablePaymentMethods.includes(PaymentMethods.CARGO_CREDIT)
                            ) {
                                return this._setPaymentMethodValidated(
                                    paymentMethods.filter(
                                        (paymentMethod: PaymentMethod) =>
                                            paymentMethod.name !== PaymentMethods.CARGO_CREDIT
                                    )[0]?.name,
                                    paymentMethods,
                                    isInvoice,
                                    subTotal
                                );
                            }

                            return this._getPaymentMethodLatest().pipe(
                                take(1),
                                switchMap((paymentMethodsLatest) => {
                                    return this._setFrequentPaymentMethods(
                                        paymentMethodsLatest,
                                        availablePaymentMethods,
                                        paymentMethods,
                                        isInvoice,
                                        subTotal
                                    ).pipe(
                                        switchMap((method) => {
                                            if (method) {
                                                return of(method);
                                            }
                                            if (
                                                paymentMethodForNewPayments &&
                                                !isInvoice &&
                                                availablePaymentMethods.includes(paymentMethodForNewPayments)
                                            ) {
                                                return this._setPaymentMethodValidated(
                                                    paymentMethodForNewPayments,
                                                    paymentMethods,
                                                    isInvoice,
                                                    subTotal
                                                );
                                            }
                                            if (
                                                paymentMethodForOpenInvoices &&
                                                isInvoice &&
                                                availablePaymentMethods.includes(paymentMethodForOpenInvoices)
                                            ) {
                                                return this._setPaymentMethodValidated(
                                                    paymentMethodForOpenInvoices,
                                                    paymentMethods,
                                                    isInvoice,
                                                    subTotal
                                                );
                                            }
                                            return of(null);
                                        })
                                    );
                                }),
                                catchError(() => of(null))
                            );
                        })
                    );
                }

                return of(paymentMethod);
            })
        );
    }

    /**
     * @method getAvailablePaymentMethods()
     * @description Return available payment methods from customer
     */

    getAvailablePaymentMethods(): Observable<PaymentMethodsType[]> {
        return of(this._customerService.getAvailablePaymentMethods());
    }

    /**
     * @method _checkCartBill()
     * @description Check if there are invoices in cart
     */

    private _checkCartBill(): Observable<{ isInvoice: boolean; subTotal: number }> {
        return this._cartRequestService.getCartWithoutFees().pipe(
            switchMap((cartBill) => {
                let subTotal = 0;
                const isInvoice = !!cartBill.cart[0].originalInvoice;
                cartBill.cart.map((item: PaymentCart) => {
                    subTotal += item.amount ?? 0;
                });
                return of({ isInvoice, subTotal });
            })
        );
    }

    /**
     * @method getAvailablePaymentMethods()
     * @description Return available payment methods from customer
     */

    private _getPaymentMethodLatest(): Observable<PaymentMethodsLatest[]> {
        return this._paymentMethodsService.getPaymentMethodLatest();
    }

    /**
     * @method _getPaymentMethods()
     * @description Get customer's all payment methods
     */

    private _getPaymentMethods(): Observable<PaymentMethod[]> {
        return this.getAvailablePaymentMethods().pipe(
            switchMap((methods: PaymentMethodsType[]) => {
                return forkJoin(this.requestPaymentMethods(methods)).pipe(defaultIfEmpty([]));
            })
        ) as Observable<PaymentMethod[]>;
    }

    requestPaymentMethods(methods: PaymentMethodsType[]): Observable<PaymentMethod[]>[] {
        let request: any[] = [];
        methods.forEach((paymentMethod) => {
            if (paymentMethod === PaymentMethods.CREDIT_CARD) {
                request.push(
                    this._loadCreditCards().pipe(
                        take(1),
                        map((cards) => {
                            return PaymentMethod.fromJson({
                                id: 92,
                                name: PaymentMethods.CREDIT_CARD,
                                paymentToken: {
                                    token: null,
                                    paymentMethod: null,
                                    paymentProcessor: null,
                                },
                                items: cards as PaymentMethodItem[],
                            });
                        })
                    )
                );
            }
            if (paymentMethod === PaymentMethods.ECHECK) {
                request.push(
                    this._loadEChecks().pipe(
                        take(1),
                        map((echecks) => {
                            return PaymentMethod.fromJson({
                                id: 93,
                                name: PaymentMethods.ECHECK,
                                paymentToken: {
                                    token: null,
                                    paymentMethod: null,
                                    paymentProcessor: null,
                                },
                                items: echecks as PaymentMethodItem[],
                            });
                        })
                    )
                );
            }
            if (paymentMethod === PaymentMethods.CARGO_CREDIT) {
                request.push(
                    this._loadSprintPayCredit().pipe(
                        map((balance: Balance | null) => {
                            return PaymentMethod.fromJson({
                                id: 94,
                                balance: {
                                    avaliableCredit: balance?.availableCredit,
                                    creditLimit: balance?.creditLimit,
                                },
                                name: PaymentMethods.CARGO_CREDIT,
                                paymentToken: {
                                    token: 'com.cargosprint.model.payment.cargosprintcredit.CargoSprintCreditToken',
                                    paymentMethod: {
                                        name: ComponentPaymentMethodName.CARGOSPRINT_CREDIT,
                                        logTime: 2,
                                        componentName: PaymentMethods.CARGO_CREDIT,
                                    },
                                    paymentProcessor: {
                                        name: ComponentPaymentMethodName.CARGOSPRINT_CREDIT as unknown as ProcessorName,
                                    },
                                },
                            });
                        })
                    )
                );
            }

            if (paymentMethod === PaymentMethods.PAYPAL) {
                const paymentTokens = this._customerService.getPaymentToken(ComponentPaymentMethodName.PAYPAL);
                request.push(
                    of(
                        PaymentMethod.fromJson({
                            id: 95,
                            name: PaymentMethods.PAYPAL,
                            paymentToken: paymentTokens,
                        })
                    )
                );
            }
        });
        return request;
    }

    /**
     * @method connectWithPaypal()
     * @description Create paypalCheckoutInstanse and load paypal script
     */

    connectWithPaypal(): Observable<PayPalCheckout> {
        return this._braintreeService.getBraintreeApiKey().pipe(
            switchMap((braintreeToken) =>
                this._braintreeService.getBraintreeClient({
                    authorization: braintreeToken.clientToken,
                })
            ),
            switchMap((client) =>
                this._braintreeService.getPayPalInstance({
                    client,
                    options: { 'client-id': environment.paypalClientId },
                })
            ),
            catchError((error: BraintreeException) =>
                throwError(() => {
                    this._errorBraintreeHandlerService.handlerError(error);
                })
            )
        );
    }

    /**
     * @method getAvailablePaymentMethods()
     * @description Return available payment methods from customer
     */

    private _setPaymentMethodValidated(
        method: PaymentMethodsType,
        paymentMethods: PaymentMethod[],
        isInvoice: boolean,
        subTotal: number,
        latestPaymentMethod?: LatestPaymentMethodDetails
    ): Observable<CartSelectedPaymentMethod | null> {
        if (method === PaymentMethods.CARGO_CREDIT && !isInvoice) {
            const cargosprintCredit = paymentMethods.find((method) => method.name === PaymentMethods.CARGO_CREDIT);
            const availableBalance = cargosprintCredit
                ? calculateSprintpayCredit(
                      cargosprintCredit.balance.avaliableCredit,
                      cargosprintCredit.balance.creditLimit
                  )[0].availableCredit || 0
                : 0;

            const isRequestorOrApprover =
                this._customerService.isRequestor() ||
                this._customerService.isApprover() ||
                (this._customerService.isACompanyCustomer() && !this._customerService.isFinancie());

            if (availableBalance >= subTotal || isRequestorOrApprover) {
                return of({
                    paymentAccount: availableBalance,
                    method: ComponentPaymentMethodName.CARGOSPRINT_CREDIT,
                });
            }
        }

        if (method === PaymentMethods.CREDIT_CARD) {
            const creditCards = paymentMethods.find((method) => method.name === PaymentMethods.CREDIT_CARD);
            if (creditCards && creditCards?.items.length === 1) {
                return of({
                    paymentAccount: {
                        ...creditCards.items[0],
                        paymentToken: {
                            ...creditCards.items[0].paymentToken,
                            paymentMethod: {
                                name: ComponentPaymentMethodName.CREDIT_CARD,
                                logTime: 2,
                                componentName: PaymentMethods.CREDIT_CARD,
                            },
                        },
                    },
                    method: ComponentPaymentMethodName.CREDIT_CARD,
                });
            } else if (latestPaymentMethod && creditCards && creditCards?.items.length > 0) {
                const lastCreditCard = creditCards.items.find(
                    (card) =>
                        card.accountingDetails?.lastFourDigits === latestPaymentMethod.last4 &&
                        card.accountingDetails?.expirationYear === latestPaymentMethod.expirationYear &&
                        card.accountingDetails?.expirationMonth === latestPaymentMethod.expirationMonth
                );
                return of({
                    paymentAccount: {
                        ...lastCreditCard,
                        paymentToken: {
                            ...lastCreditCard?.paymentToken,
                            paymentMethod: {
                                name: ComponentPaymentMethodName.CREDIT_CARD,
                                logTime: 2,
                                componentName: PaymentMethods.CREDIT_CARD,
                            },
                        },
                    },
                    method: ComponentPaymentMethodName.CREDIT_CARD,
                });
            }

            return of({
                paymentAccount: null,
                method: ComponentPaymentMethodName.CREDIT_CARD,
            });
        }

        if (method === PaymentMethods.ECHECK) {
            const echecks = paymentMethods.find((method) => method.name === PaymentMethods.ECHECK);
            if (echecks?.items.length === 1) {
                return of({
                    paymentAccount: {
                        ...echecks.items[0],
                        paymentToken: {
                            ...echecks.items[0].paymentToken,
                            paymentMethod: {
                                name: ComponentPaymentMethodName.ECHECK,
                                logTime: 2,
                                componentName: PaymentMethods.ECHECK,
                            },
                        },
                    },
                    method: ComponentPaymentMethodName.ECHECK,
                });
            }

            return of({
                paymentAccount: null,
                method: ComponentPaymentMethodName.ECHECK,
            });
        }

        if (method === PaymentMethods.PAYPAL) {
            return of({ paymentAccount: true, method: ComponentPaymentMethodName.PAYPAL });
        }

        return of(null);
    }

    private _setFrequentPaymentMethods(
        method: PaymentMethodsLatest[],
        availablePaymentMethods: PaymentMethodsType[],
        paymentMethods: PaymentMethod[],
        isInvoice: boolean,
        subTotal: number
    ): Observable<CartSelectedPaymentMethod | null> {
        const currentMethod = method.find((item: any) => {
            const paymentMethodName: PaymentMethodsType = item.paymentMethod.componentName;
            const hasPaymentMethod = paymentMethods.find((method) => method.name === paymentMethodName);
            return (
                item.paymentMethod &&
                availablePaymentMethods.includes(item.paymentMethod.componentName) &&
                (hasPaymentMethod?.items?.length || (hasPaymentMethod?.balance?.avaliableCredit && !isInvoice))
            );
        });

        if (currentMethod) {
            const paymentMethodName = currentMethod.paymentMethod.componentName;
            const latestPaymentMethod = currentMethod.latestPaymentMethodDetails;
            return this._setPaymentMethodValidated(
                paymentMethodName,
                paymentMethods,
                isInvoice,
                subTotal,
                latestPaymentMethod
            );
        }
        return of(null);
    }

    /**
     * @method _loadCreditCards()
     * @description Return the user credit cards
     */
    _loadCreditCards(): Observable<Card[]> {
        return getCards() as unknown as Observable<Card[]>;
    }
    /**
     * @method _loadEChecks()
     * @description Return the user eChecks
     */
    _loadEChecks(): Observable<Echeck[]> {
        return getEChecks() as unknown as Observable<Echeck[]>;
    }
    /**
     * @method _loadSprintPayCredit()
     * @description Return the user SprintPay Credit
     */
    private _loadSprintPayCredit(): Observable<Balance | null> {
        return this._csCreditService.getCSCreditAmountRequest$();
    }
}
