<!--START: SCHENKER CUSTOMER REFERENCE -->
<div id="company_reference" class="mt-4">
    <div id="company_reference--container">
        <div class="mt-4 d-block">
            <h6 class="step_section mb-4">DB Schenker Customer Reference</h6>
            <!--START: FORM -->
            <form class="row" [formGroup]="schenkerReferenceForm">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <!--START: COST CENTER -->
                    <div class="form-group">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>Cost Center</mat-label>
                            <input
                                matInput
                                type="text"
                                id="costCenter"
                                (change)="validateCostCenter($event)"
                                name="costCenter"
                                formControlName="costCenter"
                                autocomplete="off"
                                placeholder="Enter - Cost Center"
                                [errorStateMatcher]="matcher"
                            />
                            <mat-error *ngIf="schenkerReferenceForm.controls['costCenter'].hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                            </mat-error>
                            <mat-error *ngIf="schenkerReferenceForm.controls['costCenter'].hasError('pattern')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. We allow only alphanumeric
                                characters.
                            </mat-error>
                            <mat-error *ngIf="schenkerReferenceForm.controls['costCenter'].hasError('incorrect')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. The Cost Center format is not
                                valid, enter as 2 Alpha + 5 Numeric + 2 alpha + 1 numeric.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--END: COST CENTER  -->
                    <!--START: VOUCHER -->
                    <ng-container *ngIf="!hasExternalLookup">
                        <div class="form-group">
                            <mat-form-field class="d-block" appearance="outline">
                                <mat-label>Schenker Voucher #</mat-label>
                                <input
                                    matInput
                                    type="text"
                                    id="voucher"
                                    (change)="validateVoucherNumber($event)"
                                    name="voucher"
                                    formControlName="voucher"
                                    autocomplete="off"
                                    placeholder="Enter - Schenker Voucher #"
                                    [errorStateMatcher]="matcher"
                                />
                                <mat-error *ngIf="schenkerReferenceForm.controls['voucher'].hasError('required')">
                                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                </mat-error>
                                <mat-error *ngIf="schenkerReferenceForm.controls['voucher'].hasError('incorrect')">
                                    <i class="bi bi-exclamation-triangle-fill me-2"></i>
                                    Oops. The Voucher # should start with a '5' (for USA offices) or a '6' (for Canada
                                    offices) and must be all numeric, with mininum 9 characters and maximum 10
                                    characters. Or may start with a '9' (must be a number between 900000000 and
                                    959999999)
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <!--END: VOUCHER  -->
                    <!--START: LOCATION -->
                    <ng-container *ngIf="!locationAssigned">
                        <div class="form-group">
                            <mat-form-field class="d-block" appearance="outline">
                                <mat-label>Location</mat-label>
                                <mat-select
                                    id="locationId"
                                    [disabled]="onEdition"
                                    formControlName="locationId"
                                    [errorStateMatcher]="matcher"
                                >
                                    <mat-option
                                        (onSelectionChange)="locationSelected(item, $event)"
                                        *ngFor="let item of locations; trackBy: trackBy"
                                        [value]="item.id"
                                    >
                                        {{item.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schenkerReferenceForm.controls['locationId'].hasError('required')">
                                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <!--END: LOCATION  -->
                    <!--START: PAYMENT AMOUNT -->
                    <ng-container *ngIf="!hasExternalLookup">
                        <div class="form-group">
                            <mat-form-field class="d-block" appearance="outline">
                                <mat-label>Payment Amount</mat-label>
                                <mat-select
                                    id="paymentAmount"
                                    formControlName="paymentAmount"
                                    [errorStateMatcher]="matcher"
                                >
                                    <mat-option
                                        *ngFor="let item of paymentAmountOptions; trackBy: trackBy"
                                        [value]="item"
                                    >
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schenkerReferenceForm.controls['paymentAmount'].hasError('required')">
                                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <!--END: PAYMENT AMOUNT -->
                    <!--START: TYPE -->
                    <ng-container *ngIf="!hasExternalLookup">
                        <div class="form-group">
                            <mat-form-field class="d-block" appearance="outline">
                                <mat-label>Type</mat-label>
                                <mat-select id="type" formControlName="type" [errorStateMatcher]="matcher">
                                    <mat-option *ngFor="let item of typeOptions; trackBy: trackBy" [value]="item">
                                        {{item}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schenkerReferenceForm.controls['type'].hasError('required')">
                                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </ng-container>
                    <!--END: TYPE -->
                </div>
            </form>
            <!--END: FORM -->
        </div>
        <!--START: STEPPER NEXT BUTTON -->
        <div class="row">
            <div class="col-sm-12 col-md-3 offset-md-9">
                <div class="d-flex justify-content-end w-100">
                    <button
                        (click)="getSchenkerValues()"
                        [ngClass]="schenkerReferenceForm.invalid ? 'btn__sprintpay--greenDisabled' : ''"
                        mat-flat-button
                        color="primary"
                        type="button"
                        class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
        <!--END: STEPPER NEXT BUTTON -->
    </div>
</div>
<!--END: SCHENKER CUSTOMER REFERENCE -->
