import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-input-code',
    templateUrl: './input-code.component.html',
    styleUrls: ['./input-code.component.scss'],
})
export class InputCodeComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() digits!: number;
    @Input() disabled = false;
    @Output() verifyCode: EventEmitter<string> = new EventEmitter();
    @Output() eventOnChange: EventEmitter<boolean> = new EventEmitter();
    @ViewChildren('inputs') inputs!: QueryList<any>;

    public isFulfilled: boolean = false;
    public confirmCodeForm: FormGroup;
    public alive: boolean = true;
    private subscriptions: Subscription[] = [];
    private _pinCode: string = '';

    constructor(private _formBuilder: FormBuilder) {
        this.confirmCodeForm = this._formBuilder.group({
            digits: this._formBuilder.array([]),
        });
        this.digits = 6;
    }

    ngOnInit(): void {
        const digitsArray = this.confirmCodeForm.get('digits') as FormArray;

        for (let i = 0; i < this.digits; i++) {
            digitsArray.push(this._formBuilder.control(null));
        }

        // Create subscription for the last input field
        this.subscribeToLastControlChange(this.digits - 1);
    }

    ngAfterViewInit(): void {
        // Necessary Code to focus first input after component is rendered and not provoke errors/NG0100
        setTimeout(() => this.inputs.toArray()[0].nativeElement.focus(), 200);
    }

    private subscribeToLastControlChange(index: number): void {
        const control = (this.confirmCodeForm.get('digits') as FormArray).at(index);

        const sub = control.valueChanges.subscribe((newValue) => {
            const values = this.confirmCodeForm.value.digits;
            this._pinCode = values.join('');

            if (!isNaN(parseInt(newValue, 10))) {
                this.isFulfilled = this._pinCode.length === this.digits - 1;
            } else {
                this.isFulfilled = false;
            }
        });

        this.subscriptions.push(sub);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    check(index: number, field: AbstractControl<any>, event: any): void {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }

        if (!isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                setTimeout(() => {
                    this.inputs.toArray()[index + 1].nativeElement.focus();
                }, 100);
            }
        } else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null);
                setTimeout(() => {
                    this.inputs.toArray()[index - 1].nativeElement.focus();
                }, 100);
            }
        }

        this.eventOnChange.emit(true);
    }

    getDigitsArrayControl(): AbstractControl<any>[] {
        return (this.confirmCodeForm.get('digits') as FormArray).controls;
    }

    confirmCode(value: any): void {
        const values = value.digits;
        this._pinCode = values.join('');
        if (this._pinCode.length === this.digits) {
            const values = value.digits;
            this.verifyCode.emit(values.join(''));
        }
    }
}
