import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { take, throwError } from 'rxjs';
import { PaymentService } from 'src/app/services/payment.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { SessionService } from 'src/app/services/utils/session.service';
import { Sections } from 'src/app/utils/sections';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../../../environments/environment';
import { PaymentToRequestEditService } from '../../../../../services/payment-to-request-edit.service';
import { RequestService } from '../../../../../services/requests.service';
import { RestService } from '../../../../../services/rest.service';
import { CustomerService } from '../../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../../services/utils/payment-flux.service';
import { companyName, sprintPaySource } from '../../../../../utils/constants';

@Component({
    selector: 'app-payment-detail',
    templateUrl: './payment-detail.component.html',
})
export class PaymentDetailComponent implements OnInit, OnDestroy {
    id: string;
    paymentDetail: any;
    paymentLogs: any;
    paymentDetailFinish: boolean;
    buttonLabel: string;
    files: any;
    nameFiles: any = [];
    noInformation: boolean | undefined;
    noInformationMessage: string;
    isSameCustomerAndRequestor: boolean;
    paymentDetailsSection: any;
    paymentCustomerReference: any;
    readonly requestorUser: boolean;
    readonly companyNames: any;
    readonly isStaringLevel: boolean;
    readonly isSchenker: boolean;
    readonly isKN: boolean;
    private dynamicFieldsReplaced: any;
    private _approvalStatus!: string;
    private _submitToApprover: boolean;
    private readonly _customer: any;
    private _currentPayment: any;
    private readonly _companyType: string;
    private readonly _cartUrl: string;

    constructor(
        private _paymentToRequestEditService: PaymentToRequestEditService,
        private _breadcrumbService: BreadcrumbService,
        private _activatedRoute: ActivatedRoute,
        private _restService: RestService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _matDialog: MatDialog,
        private _customerService: CustomerService,
        private _router: Router,
        private _errorHandlerService: ErrorHandlerService,
        private _sessionService: SessionService,
        private _paymentFluxService: PaymentFluxService,
        private _paymentService: PaymentService,
        private _requestService: RequestService
    ) {
        this._breadcrumbService.set('@rejected-payment', 'Rejected payment(s)');
        this._breadcrumbService.set('@payment-detail', 'Payment detail');
        this.isSameCustomerAndRequestor = false;
        this.buttonLabel = '';
        this._submitToApprover = true;
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.id = '';
        this.noInformation = undefined;
        this.noInformationMessage = '';
        this.isStaringLevel = !!this._sessionService.getElement('startingLevel');
        this._customer = this._customerService.getCustomer();
        this.requestorUser = !!this._customerService.getCustomer().userType.includes('REQUESTOR');
        this._companyType = this._customer.approvalLevels ? this._customer.approvalLevels.company.name : '';
        this.paymentDetailFinish = false;
        this._cartUrl = environment.uris.method.cart;
        this.companyNames = companyName;
        this.isSchenker = this._companyType === companyName.schenker;
        this.isKN = this._companyType === companyName.kn;
        this.getAllFieldsWithReplacement();
    }

    ngOnInit(): void {
        this._activatedRoute.params.pipe(take(1)).subscribe({
            next: (params: any): void => {
                this.id = params['id'];
                this._approvalStatus = params['approvalStatus'];
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
        if (this._currentPayment) {
            this.submitToApproverFlux(this._customer.approvalLevels, this._currentPayment);
            const files: any = this._currentPayment.details?.nameFiles;
            this.paymentDetail = Object.assign({}, this._currentPayment);
            this.isSameCustomerAndRequestor = this.paymentDetail.originalRequestor.id === this._customer.id;
            this.files = files ? files.split(',') : '';
            files
                ? files.split(',').map((item: any): void => {
                      this.nameFiles.push(item.split('/')[5]);
                  })
                : '';
            this._getPaymentLogs();
            const dynamicValues = this._paymentFluxService.getDynamicValues();
            if (dynamicValues) {
                this.paymentDetail['dynamicValues'] = dynamicValues;
            }
            this.getPaymentCustomerReferenceSection();
            this.getPaymentDetailsSection();
        } else {
            this._getPaymentDetail();
        }
    }

    ngOnDestroy(): void {
        this._matDialog.closeAll();
    }

    /**
     * @method _getPaymentDetail()
     * @description Generates the URL based on customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    private _getPaymentDetail(): void {
        const url: string = `${this._cartUrl}/payment-request/details/${this.id}?approvalStatus=${this._approvalStatus}`;
        const companyType: string = this._customerService.getCompanyName() || '';
        this._ngxSpinnerService.show();
        this._restService
            .get(url, {})
            .then((result: any): void => {
                this.paymentDetail = this._paymentToRequestEditService.getPaymentRequestDataCart(
                    result,
                    '/admin/facilityPayments/rejectedPayment/paymentDetail/' + this.id + '/' + result.approvalStatus,
                    companyType.toString()
                );
                this.paymentDetail.approvalReason = result['approvalReason'];
                this.paymentDetail.approvalStatus = result['approvalStatus'];
                this.paymentDetail.hawb = result['hawb'];
                this.paymentDetail.companyType = companyType.toString();
                this.paymentDetail.createdDate = new Date(result['createdDate']);
                this.paymentDetail.lastModifiedDate = result['lastModifiedDate'];
                this.paymentDetail.customer = result['customer'];
                this.paymentDetail.approvalFlowLevel = result['approvalFlowLevel']['approvalFlow']['location']['name'];
                this.paymentDetail.location = result['location']['name'];
                this.paymentDetail.externalData = result['externalData'];
                this.paymentDetail.originalRequestor = result['originalRequestor'];
                this.paymentDetail.sprintPaySource = sprintPaySource === result['source']?.['name'].toLowerCase();
                this.paymentDetail.externalLookup = result['facility'] ? result['facility']['externalLookup'] : false;

                this.isSameCustomerAndRequestor =
                    result.originalRequestor.id === this._customer.id &&
                    result.originalRequestor.name === this._customer.name;
                this.paymentDetail.sameCustomerAndRequestor = this.isSameCustomerAndRequestor;
                this.submitToApproverFlux(this._customer.approvalLevels, this.paymentDetail);
                this.files = result.attachments ? result.attachments.split(',') : '';
                result.attachments
                    ? result.attachments.split(',').map((item: any): void => {
                          this.nameFiles.push(item.split('/')[5]);
                      })
                    : '';
                this._paymentFluxService.setCurrentPayment(this.paymentDetail);
                this.getPaymentCustomerReferenceSection();
                this.getPaymentDetailsSection();
                this._getPaymentLogs();
            })
            .catch((error: any): void => {
                this.noInformationMessage = error?.error?.errors ? error.error.errors : '';
                this.noInformation = true;
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method _getPaymentLogs()
     * @description Generates the URL based on customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    private _getPaymentLogs(): void {
        const url: string = `${this._cartUrl}/getLogHistoryByPaymentRequest/${
            this.id
        }?page=0&size=30&date=${new Date().valueOf()}`;

        this._ngxSpinnerService.show();
        this._restService
            .get(url, {})
            .then((result: any): void => {
                this.paymentLogs = result.content.map((item: any) => {
                    return {
                        approval: `${item.approvalLevel}: ${item.firstName} ${item.lastName}`,
                        date: moment(item.date).format('MMM DD, YYYY, h:mm:ss a'),
                        event: item.event,
                        reason: item.reason,
                    };
                });
                this.noInformation = false;
                this._ngxSpinnerService.hide();
            })
            .catch(() => {
                this._ngxSpinnerService.hide();
            })
            .finally((): void => {
                this.paymentDetailFinish = true;
            });
    }

    /**
     * @method openDialog()
     * @param (templateRef: any)
     * @description Opens the dialog in this case menu right sidebar for the activity log
     */

    openDialog(templateRef: any): void {
        this._matDialog.open(templateRef, {
            id: 'timeline',
            disableClose: true,
        });
    }

    /**
     * @method cancelRequest()
     * @description
     */

    cancelRequest(): void {
        Swal.fire({
            confirmButtonText: 'Void request',
            confirmButtonColor: 'rgba(255, 77, 77, 1)',
            width: '35em',
            showCancelButton: true,
            title: 'W A R N I N G!',
            text: 'Do you really want to void this request? This process cannot be undone',
            input: 'textarea',
            icon: 'warning',
            inputPlaceholder: 'Type your reason here',
            reverseButtons: true,
            inputAttributes: {
                maxlength: '180',
            },
            preConfirm: function (result): boolean | any {
                if (result.value == '' || !result) {
                    Swal.showValidationMessage('You need to write something!');
                    return false;
                }
                return result;
            },
        }).then((result: any): void => {
            if (result.value) {
                this._ngxSpinnerService.show();
                const reason = encodeURIComponent(result.value);
                const id: number = Number.parseFloat(this.id);
                const url: string = `${this._cartUrl}/cancelPaymentRequest?id=${id}&reason=${reason}`;
                this._restService
                    .post(url, {})
                    .then((): void => {
                        setTimeout(() => {
                            this._ngxSpinnerService.hide();
                            this._router.navigate(['admin/facilityPayments/pendingApproval/paymentList']);
                        }, 2000);
                    })
                    .catch((error: any): void => {
                        this._ngxSpinnerService.hide();
                        Swal.fire({
                            html: `${this._errorHandlerService.errorMsg(error.error)}`,
                            icon: 'error',
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: 'Close',
                            allowOutsideClick: false,
                        }).then((result: any) => {
                            if (result.value) {
                                this._router.navigate(['/admin/facilityPayments/rejectedPayment/paymentList']);
                            } else {
                                Swal.close();
                            }
                        });
                    });
            }
        });
    }

    /**
     * @method updateRejectedPayment()
     * @description
     */
    updateRejectedPayment(): void {
        const payment = this._paymentFluxService.getCurrentPayment();
        const dynamicValues = this._paymentFluxService.getDynamicValues();
        const editRequestURL: string = `${this._cartUrl}/editApprovalPaymentRequest`;
        const approveRequestURL: string = `${this._cartUrl}/approvePaymentRequest?id=${payment.id}`;
        const request: object = this._requestService.getRequestData(payment, this._companyType, dynamicValues);
        Swal.fire({
            title: 'Please wait...',
            text: 'We are sending your request',
            showConfirmButton: false,
            allowOutsideClick: false,
        });
        this._restService
            .post(editRequestURL, { data: request })
            .then((): void => {
                this._restService
                    .post(approveRequestURL, {})
                    .then((): void => {
                        this._paymentFluxService.removeCurrentPayment();
                        this._paymentFluxService.removeDynamicFields();
                        this._paymentFluxService.removeDynamicValues();
                        this._sessionService.removeElement('payments_selected');
                        setTimeout((): void => {
                            Swal.close();
                            this._router.navigate([
                                this._submitToApprover
                                    ? '/admin/facilityPayments/rejectedPayment/paymentList'
                                    : '/admin/cart',
                            ]);
                        }, 2000);
                    })
                    .catch((error: any): void => {
                        Swal.close();
                        Swal.fire({
                            html: `${this._errorHandlerService.errorMsg(error.error)}`,
                            text: 'Something went wrong, try again later',
                            icon: 'error',
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: 'Close',
                            allowOutsideClick: false,
                        });
                    });
            })
            .catch((error: any): void => {
                Swal.close();
                Swal.fire({
                    title: 'Oops!',
                    html: `${this._errorHandlerService.errorMsg(error.error)}`,
                    text: 'Something went wrong, try again later',
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: 'Close',
                    allowOutsideClick: false,
                }).then((result: any) => {
                    if (result.value) {
                        this._router.navigate(['/admin/facilityPayments/rejectedPayment/paymentList']);
                    } else {
                        Swal.close();
                    }
                });
            });
    }

    /**
     * @method updateRejectedPayment()
     * @param (approvalLevels: any)
     * @description
     */

    submitToApproverFlux(approvalLevels: any, paymentDetail: any): void {
        const type = paymentDetail.externalData.type;
        const canCreate = approvalLevels.canCreateApprovalFlow.filter((item: any) => item.name.includes(type));
        this._submitToApprover = canCreate[0] ? canCreate[0].approvalFlowLevels.length > 1 : true;
        this.buttonLabel = this._submitToApprover ? 'Submit to approver' : 'Add to cart';
    }

    /**
     * @method getAllFieldsWithReplacement()
     * @description
     */

    getAllFieldsWithReplacement(): void {
        if (this._companyType && Object.keys(this.companyNames).indexOf(this._companyType.toLowerCase()) === -1) {
            this.dynamicFieldsReplaced = [];
            this._paymentService.getAllDynamicFieldsWithReplacement().subscribe({
                next: (fieldsReplaced: any): void => {
                    this.dynamicFieldsReplaced = fieldsReplaced;
                },
            });
        }
    }

    getPaymentDetailsSection(): void {
        const companyType: string = this._customerService.getCompanyName();
        this.paymentDetailsSection = Sections.getDetailsOfPaymentDetailsSummarySection(this.paymentDetail, companyType);
    }

    getPaymentCustomerReferenceSection(): void {
        const customerReferenceLabel = this.dynamicFieldsReplaced?.find((item) => item.name === 'customerRef');
        const companyType: string = this._customerService.getCompanyName();
        this.paymentCustomerReference = Sections.getDetailsOfCustomerReferenceSummarySection(
            this.paymentDetail.reference,
            this._customer,
            customerReferenceLabel?.label || '',
            companyType
        );
    }
}
