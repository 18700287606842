<div class="container-fluid">
    <div class="row" *ngIf="notifications.length">
        <div class="col-12">
            <div class="banner" *ngFor="let notification of notifications">
                <div class="row">
                    <div class="col-12">
                        <div class="text-white">
                            <div
                                class="alert alert-dismissible fade show p-0 m-0 d-flex align-content-center align-items-center"
                                role="alert"
                            >
                                <span
                                    class="p-0 my-1 w-100"
                                    [innerHTML]="notification.message | sanitizer: 'html'"
                                ></span>
                                <button
                                    type="button"
                                    class="banner__btn--close"
                                    aria-label="Close"
                                    (click)="closeBanner(notification)"
                                >
                                    <span class="material-symbols-outlined">close</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
