import { HttpEventType } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, catchError, map, throwError } from 'rxjs';
import { UploadFileAPIService } from '../requests/upload-file-api.service';
import { RestService } from '../rest.service';
import { ErrorHandlerService } from '../utils/error-handler.service';

@Injectable({
    providedIn: 'root',
})
export class UploadFileHandlerService {
    constructor(
        private _uploadFileService: UploadFileAPIService,
        private _domSanitizer: DomSanitizer,
        private _restService: RestService,
        private _errorHandlerService: ErrorHandlerService
    ) {}

    /**
     * @method downloadFile()
     * @param path
     * @param nameFile
     * @description Function that download the file
     */
    downloadTemplate(url: string, nameFile: string): Observable<Blob> {
        return this._uploadFileService.getTemplate(url).pipe(
            map((fileResponse: Blob) => {
                const link: HTMLAnchorElement = document.createElement('a');
                const file: Blob = new Blob([fileResponse], { type: fileResponse.type });
                const url: string =
                    this._domSanitizer.sanitize(
                        SecurityContext.URL,
                        this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                    ) ?? '';
                const extension = this._restService.getExtension(fileResponse.type);
                link.href = url;
                link.download = nameFile + extension;
                link.click();
                window.URL.revokeObjectURL(url);
                return fileResponse;
            })
        );
    }

    submitUploadedFile(url: string, file: FormData): Observable<any> {
        return this._uploadFileService.postFileWithProgress(url, file).pipe(
            map((response: any) => {
                if (response.type === HttpEventType.Response) {
                    const payments: any = JSON.parse(response.body);
                    const failed = payments.filter((item: any) => item.status.trim().toLowerCase() === 'failed');
                    const submitted = payments.filter(
                        (item: any) => item.status.trim().toLowerCase() === 'submitted to approver'
                    );
                    const addedToCart = payments.filter(
                        (item: any) => item.status.trim().toLowerCase() === 'added to cart'
                    );
                    response['status'] = {
                        failed: failed.length,
                        submitted: submitted.length,
                        addedToCart: addedToCart.length,
                    };
                    return response;
                }
                return response;
            }),
            catchError((errors: any) => {
                return throwError(() => {
                    if (errors.error && typeof errors.error === 'string') {
                        errors.error = JSON.parse(errors.error);
                    }
                    if (errors.error && errors.error.length) {
                        const customError: any[] = errors.error;
                        if (Array.isArray(customError) && customError.some((error: any) => error.errors?.length)) {
                            let $listItem: string = '<div class="d-flex justify-content-center">';
                            customError.length === 1
                                ? ($listItem += `<ul class="list-unstyled">`)
                                : ($listItem += `<ul>`);
                            customError.map((error: any) => {
                                let master: string;
                                error.errors.map((item: { message: string }) => {
                                    master = item.message;
                                    $listItem += '<li class="fw-normal mb-3"">' + master + '</li>';
                                });
                            });
                            $listItem += `</ul></div>`;
                            return $listItem;
                        }
                    }
                    if (errors.error.errors && errors.error.errors.length > 1) {
                        return this._errorHandlerService.errorTemplate(errors.error);
                    }
                    return errors;
                });
            })
        );
    }
}
