import { CellContent } from 'src/app/directives/change-cell-content.directive';
import { CompanyDetails } from '../utils/company';

export class Table {
    public filter?: boolean;
    public sorting?: boolean;
    public pagination?: boolean;
    public dataSource: Array<any>;
    public displayedColumns: string[];
    public customColumns?: CellContent[];
    public displayedColumnsName: string[];
    public paginationSizes?: number[];
    public totalElements?: number;
    public expandedDetail?: boolean;

    constructor(table: Table) {
        this.filter = table.filter;
        this.sorting = table.sorting;
        this.pagination = table.pagination;
        this.dataSource = table.dataSource;
        this.displayedColumns = table.displayedColumns;
        this.customColumns = table.customColumns;
        this.displayedColumnsName = table.displayedColumnsName;
        this.paginationSizes = table.paginationSizes;
        this.totalElements = table.totalElements;
        this.expandedDetail = true;
    }
}

export interface FilterByColumn {
    filterLabel: string;
    name: string;
    listItems: any[];
}
export interface Filters {
    page?: number;
    size?: number;
    startDate?: string;
    endDate?: string;
    timeZone?: string;
    searchTerms?: string;
    searchQuery?: string;
    status?: string;
    sort?: string;
    includeInactive?: boolean;
    roleIds?: string;
    branchIds?: string;
    statuses?: string;
    activeProfileView?: string | null;
    ids?: string;
    id?: string;
    invoiceIds?: string;
    email?: string;
}

export interface SearchEvent {
    value?: string;
    paginator?: PaginatorEvent;
    start?: string;
    end?: string;
    direction?: string;
    roleIds?: string;
    statuses?: string;
    branchIds?: CompanyDetails[] | string[];
    field?: string;
}

export interface PaginatorEvent {
    pageSize: number;
    currentPage: number;
}

export interface DateRangeEvent {
    start: string;
    end: string;
}
