import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Subject, takeUntil } from 'rxjs';
import { blackListFiles } from 'src/app/utils/constants';
import Swal from 'sweetalert2';
import { FileService } from './services/file.service';

@Component({
    selector: 'app-file-handler',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule],
    templateUrl: './file-handler.component.html',
    styleUrls: ['./file-handler.component.scss'],
})
export class FileHandlerComponent implements OnInit, OnDestroy {
    @Input() set removedFile(removed: boolean) {
        removed && this.removeFile();
    }
    @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;

    uploadForm!: FormGroup;
    uploading = false;
    private _maxFilesAllowed: number;
    private _selectedFileSize: number;
    private __blackListFile: string[];
    private _unsubscribe$: Subject<void>;

    constructor(
        private _formBuilder: FormBuilder,
        private _fileService: FileService
    ) {
        this.__blackListFile = blackListFiles;
        this._selectedFileSize = 0;
        this._maxFilesAllowed = 1;
        this._unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this._setFromBuilder();
        this._subscribeCheckFormStatus();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.uploadForm = this._formBuilder.group({
            nameFiles: ['', [Validators.required]],
        });
    }

    /**
     * @method nameFiles()
     * @description: Convenience getter for easy access to form fields
     */

    get nameFiles(): FormGroup {
        return this.uploadForm.get('nameFiles') as FormGroup;
    }

    /**
     * @method handleFileInput()
     * @param (event: any)
     * @description save and handle the files
     */

    handleFileInput(event: any): void {
        const fileEvent: FileList = event.files;
        if (fileEvent.length) {
            if (fileEvent.length > this._maxFilesAllowed) {
                this.showErrorPopup('You may upload at most only ' + this._maxFilesAllowed + ' xls file, of max 1MB');
                return;
            }
            const file = fileEvent.item(0);
            this._selectedFileSize = (file && Math.round(file.size / 1024)) || 0;
            if (file?.name && !this.verifyFileExtensions(file?.name)) {
                if (this._selectedFileSize >= 1000) {
                    this.showErrorPopup('Please select a file with less than 1 MB');
                } else {
                    const selectedFile = fileEvent && fileEvent.item(0);
                    this.nameFiles.markAsTouched();
                    selectedFile && this._fileService.setFile(selectedFile);
                    this.uploadForm.controls['nameFiles'].setValue(selectedFile?.name);
                }
            } else {
                this.showErrorPopup('You file contains different extensions');
            }
        }
    }
    /**
     * @method dropHandler()
     * @param (event: any)
     * @description drop files into area
     */

    dropHandler(event: any): void {
        event.preventDefault();
        if (event.dataTransfer.files) {
            this.handleFileInput(event.dataTransfer);
        }
        this.removeDragData(event);
    }

    /**
     * @method dragOverHandler()
     * @param (event: any)
     * @description drag files
     */

    dragOverHandler(event: any): void {
        event.preventDefault();
    }

    /**
     * @method removeDragData()
     * @param (event: any)
     * @description removed the drag files
     */

    removeDragData(event: any): void {
        event.dataTransfer.items ? event.dataTransfer.items.clear() : event.dataTransfer.clearData();
    }

    /**
     * @method verifyFileExtensions()
     * @param (fileName: string)
     * @description verify uploaded a file based on acceptable file's extensions
     */

    verifyFileExtensions(fileName: string): boolean {
        const extensions: string[] = fileName.split('.').map((item) => item.toLowerCase());
        return extensions.some((item) => this.__blackListFile.includes(item));
    }

    /**
     * @method showErrorPopup()
     * @param (message: string)
     * @description save the changed form values
     */

    showErrorPopup(message: string): void {
        Swal.fire({
            title: 'Oops...',
            text: message,
            icon: 'error',
        });
    }

    /**
     * @method removeFile()
     * @param (index: number)
     * @description removed the drag files
     */

    removeFile(): void {
        if (this.fileInput?.nativeElement) {
            this.fileInput.nativeElement.value = '';
        }
        this.uploadForm.get('nameFiles')!.setValue(null);
        this.uploadForm.value.nameFiles = null;
    }

    /**
     * @method _subscribeCheckFormStatus()
     * @description Subscribe check form status event
     */

    private _subscribeCheckFormStatus(): void {
        this._fileService
            .getCheckFileFormStatus()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe({
                next: (): void => {
                    this.nameFiles.markAsTouched();
                },
            });
    }
}
