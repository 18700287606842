import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { CodaFluxFormsService } from 'src/app/services/coda-flux-form.service';
import { DynamicFormComponent } from 'src/app/standalone-components/dynamic-form/dynamic-form.component';
import { companyName } from 'src/app/utils/constants';
import { StepperSteps } from 'src/app/utils/stepperTypes';
import { Facility } from '../../../../../../models/payments/facility.model';
import { PaymentService } from '../../../../../../services/payment.service';
import { CustomerService } from '../../../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../../../services/utils/payment-flux.service';
import { SessionService } from '../../../../../../services/utils/session.service';
import { PaymentDetailService } from '../payment-detail.service';
import { StepperService } from '../services/stepper.service';
import { SummaryDetailService } from '../summary-detail.service';

@Component({
    selector: 'app-payment-detail-flux',
    templateUrl: './payment-detail.component.html',
    providers: [SummaryDetailService],
})
export class PaymentDetailComponent implements OnInit, AfterViewChecked, OnDestroy {
    loading: boolean = false;
    hasExternalLookup: boolean = false;
    paymentForm!: FormGroup;
    facilityData: any;
    onEdition: boolean;
    fieldsPayment: any[];
    dynamicFormValid: boolean;
    nameFilesIsReplacement!: Observable<any>;
    hawbIsReplacement!: Observable<any>;
    formValidStateParent: boolean | undefined;
    private _dynamicObject: any;
    readonly startingLevel: boolean;
    readonly companyName: any;
    readonly companyId: any;
    readonly companyNameConst: any;
    readonly geodisDomain: boolean;
    private _customer: any;
    private readonly _currentPayment: any;
    private _dynamicFormValue: any;
    private _dynamicFieldsSection: boolean;
    private _unsubscribe$: Subject<void>;
    @Input() newPaymentData!: Facility;

    @ViewChild(DynamicFormComponent) private _dynamicFormComponent?: DynamicFormComponent;

    constructor(
        private _router: Router,
        private _paymentFluxService: PaymentFluxService,
        private _formBuilder: FormBuilder,
        private _customerService: CustomerService,
        private _sessionService: SessionService,
        private _paymentDetailService: PaymentDetailService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _codaFluxService: CodaFluxFormsService,
        private _stepperService: StepperService,
        private _paymentService: PaymentService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this._dynamicFieldsSection = false;
        this._dynamicObject = {};
        this.dynamicFormValid = false;
        this.fieldsPayment = [];
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        if (!this._currentPayment) {
            this._router.navigate([`/admin/facilityPayments/newPayment`]);
        }
        this.hasExternalLookup = !!this._currentPayment.facility.externalLookup;
        this.companyNameConst = companyName;
        this._customer = this._customerService.getCustomer();
        this.startingLevel = !!this._sessionService.getElement('startingLevel');
        this.companyName = this._customer.approvalLevels ? this._customer.approvalLevels.company.name : '';
        this.companyId = this._customer.approvalLevels ? this._customer.approvalLevels.company.id : '';
        this.facilityData =
            this._currentPayment && this._currentPayment.facility ? this._currentPayment.facility : null;
        this.geodisDomain = this._customerService.isUserDomain(companyName.geodis.toLowerCase());
        this.onEdition = this._currentPayment && this._currentPayment.redirectRoute ? true : false;
        this.formValidStateParent = undefined;
    }

    ngOnInit(): void {
        this._setFromBuilder();
        if (this._currentPayment && this._currentPayment.details) {
            this.paymentForm.patchValue(this._currentPayment.details);
        }
        if (!(this._currentPayment && this._currentPayment.id)) {
            this._paymentFluxService.setData('id', null);
        }
        if (this.facilityData) {
            this.facilityData?.paidTo ? this.paymentForm.get('paidTo')?.setValue(this.facilityData.paidTo) : '';
        }
        this.getFormFields();
        this.markAllFormAsTouched();
    }

    ngAfterViewChecked(): void {
        if (this.hasExternalLookup) {
            this._codaFluxService.setPaymentDetailsForm(this.paymentForm);
            this._paymentFluxService.setData('details', this.paymentForm.getRawValue());
        }
        this._changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    markAllFormAsTouched(): void {
        this._codaFluxService
            .getCustomerReferenceAsTouched()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe({
                next: (touched: boolean) => {
                    if (touched) {
                        this.formValidStateParent = false;
                        this.paymentForm.markAllAsTouched();
                    }
                },
            });
    }

    updateLoading(loading: boolean): void {
        this.loading = loading;
    }
    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.paymentForm = this._formBuilder.group({
            nameFiles: new FormControl<any | null>(''),
            prefix: new FormControl<any | null>(null),
            awb: new FormControl<any | null>(null),
            hawb: new FormControl<any | null>(null),
            number: new FormControl<any | null>(null),
            paymentType: new FormControl<any | null>(''),
            amount: new FormControl<any | null>(null),
            paidTo: new FormControl<any | null>(''),
            eiReferenceFileNumber: new FormControl<any | null>(null),
        });
        this.paymentForm = this._paymentDetailService.validateForm(this.paymentForm);
    }

    /**
     * @method nameFiles()
     * @description: Convenience getter for easy access to form fields
     */

    get nameFiles(): FormGroup {
        return this.paymentForm.get('nameFiles') as FormGroup;
    }

    /**
     * @method prefix()
     * @description: Convenience getter for easy access to form fields
     */

    get prefix(): FormGroup {
        return this.paymentForm.get('prefix') as FormGroup;
    }

    /**
     * @method awb()
     * @description: Convenience getter for easy access to form fields
     */

    get awb(): FormGroup {
        return this.paymentForm.get('awb') as FormGroup;
    }

    /**
     * @method hawb()
     * @description: Convenience getter for easy access to form fields
     */

    get hawb(): FormGroup {
        return this.paymentForm.get('hawb') as FormGroup;
    }

    /**
     * @method number()
     * @description: Convenience getter for easy access to form fields
     */

    get number(): FormGroup {
        return this.paymentForm.get('number') as FormGroup;
    }

    /**
     * @method paymentType()
     * @description: Convenience getter for easy access to form fields
     */

    get paymentType(): FormGroup {
        return this.paymentForm.get('paymentType') as FormGroup;
    }

    /**
     * @method amount()
     * @description: Convenience getter for easy access to form fields
     */

    get amount(): AbstractControl<string | null> | null {
        return this.paymentForm.get('amount');
    }

    /**
     * @method paidTo()
     * @description: Convenience getter for easy access to form fields
     */

    get paidTo(): FormGroup {
        return this.paymentForm.get('paidTo') as FormGroup;
    }

    /**
     * @method eiReferenceFileNumber()
     * @description: Convenience getter for easy access to form fields
     */

    get eiReferenceFileNumber(): FormGroup {
        return this.paymentForm.get('eiReferenceFileNumber') as FormGroup;
    }

    /**
     * @method setPaymentType()
     * @param (event: any)
     * @description Gets the PaymentType from the standalone component
     */
    setPaymentType(event: any): void {
        this.paymentType!.setValue(event);
        this.change(this.paymentForm);
    }

    /**
     * @method setAWB()
     * @param (event: any)
     * @description Gets the AWB value from the standalone component
     */
    setAWB(event: any): void {
        this.awb!.setValue(event && event.awb ? event.awb : null);
        this.number!.setValue(event && event.number ? event.number : null);
        this.prefix!.setValue(event && event.prefix ? event.prefix : null);
        this.change(this.paymentForm);
    }

    /**
     * @method setAmount()
     * @param (event: any)
     * @description Gets the AMount value from the standalone component
     */
    setAmount(amount: string | null): void {
        this.amount!.setValue(amount);
        this.change(this.paymentForm);
    }

    /**
     * @method setHAWB()
     * @param (event: any)
     * @description Gets the HAWB value from the standalone component
     */
    setHAWB(event: any): void {
        this.hawb!.setValue(event.hawb);
        this.change(this.paymentForm);
    }

    /**
     * @method setVinEdocNextgen()
     * @param (event: any)
     * @description Gets the data from the standalone component
     */

    setVinEdocNextgen(event: any): void {
        this.eiReferenceFileNumber!.setValue(event.eiReferenceFileNumber);
        this.change(this.paymentForm);
    }

    /**
     * @method setUploadFile()
     * @param (event: any)
     * @description
     */

    setUploadFile(event: any): void {
        this.nameFiles!.setValue(event.nameFiles);
        this.change(this.paymentForm);
    }

    /**
     * @method backStep()
     * @description Return into the customer reference for companies
     */

    backStep(): void {
        this._paymentFluxService.setData('details', this.paymentForm.getRawValue());
        if (this.companyName) {
            this._router.navigate([
                Object.keys(this.companyNameConst).indexOf(this.companyName.toLowerCase()) === -1
                    ? `/admin/facilityPayments/newPayment/flux/${encodeURIComponent(
                          this.companyName.toLowerCase()
                      )}+'/Reference`
                    : `/admin/facilityPayments/newPayment/flux/${encodeURIComponent(
                          this.companyName.toLowerCase()
                      )}Reference`,
            ]);
        } else {
            this._paymentFluxService.goBackToFacilitySearch();
        }
    }

    /**
     * @method checkout()
     * @description Send the user to the customer reference
     */

    checkout(): void {
        if (this.paymentForm.invalid || !this.dynamicFormValid) {
            this.formValidStateParent = false;
            this.paymentForm.markAllAsTouched();
            if (this._dynamicFormComponent?.paymentForm) {
                this._dynamicFormComponent?.paymentForm.markAllAsTouched();
            }
        } else {
            this.formValidStateParent = true;
            let paymentDetails = this.paymentForm.getRawValue();
            const step = this.companyName ? StepperSteps.STEP_FIRST : StepperSteps.STEP_ZERO;
            this._stepperService[StepperSteps.STEP_ZERO].next(true);
            if (step === StepperSteps.STEP_FIRST) {
                this._stepperService[StepperSteps.STEP_FIRST].next(true);
                this._stepperService[StepperSteps.STEP_SECOND].next(true);
            }
            const redirect: string =
                this._currentPayment && this._currentPayment.id && this.startingLevel && this._currentPayment.fromCart
                    ? '/admin/facilityPayments/newPayment/flux/summary'
                    : this._currentPayment && this._currentPayment.redirectRoute
                      ? this._currentPayment.redirectRoute
                      : '/admin/facilityPayments/newPayment/flux/customerReference';
            if (this._dynamicFieldsSection) {
                const dynamicValues = this._paymentFluxService.getDynamicValues();
                Object.keys(this._dynamicFormValue).forEach((key: any): void => {
                    paymentDetails[key] = this._dynamicFormValue[key];
                });
                this._paymentFluxService.setDynamicValues(Object.assign({}, dynamicValues, this._dynamicObject));
            }
            this._paymentFluxService.setData('details', paymentDetails);
            this._router.navigate([redirect]);
        }
    }

    /**
     * @method dynamicValues()
     * @param (form: any)
     * @description
     */

    dynamicValues(form: any): void {
        if (form.status === 'VALID' || form.status === 'DISABLED') {
            this._dynamicFormValue = form.value;
            this.dynamicFormValid = true;
            this._dynamicObject = form.dynamic;
            const dynamicValues = this._paymentFluxService.getDynamicValues();
            this._paymentFluxService.setDynamicValues(Object.assign({}, dynamicValues, this._dynamicObject));
        } else if (form.status === 'INVALID' || form.status === 'PENDING') {
            this.dynamicFormValid = false;
        }
    }

    /**
     * @method hasDynamicFields()
     * @param (fields: number)
     * @description
     */

    hasDynamicFields(fields: number): void {
        this._dynamicFieldsSection = fields > 0;
        this.dynamicFormValid = !this._dynamicFieldsSection;
    }

    /**
     * @method getFormFields()
     * @description Review if dynamic fields exits and if they do, we review if we have any isReplaced field
     */

    getFormFields(): void {
        if (this._customerService.getCustomer()?.approvalLevels?.company || '' !== '') {
            this._paymentService.getFormFields('1').then((result): void => {
                result.map((field) => {
                    const validations = field.validations
                        ?.filter((validation): boolean => validation.name !== 'tooltip')
                        .reduce((acc: any, item) => {
                            acc[item.name] = {
                                value: item.value === 'true' ? true : item.value,
                                errorMessage: item.message,
                            };
                            return acc;
                        }, {});
                    field['type'] = 'text';
                    field['disabled'] = field.isDisabled;
                    field['validations'] = validations;
                    return field;
                });

                result.forEach((field) => {
                    if (field.isReplacement === true) {
                        if (field.name === 'nameFiles') {
                            this.nameFilesIsReplacement = of(field);
                            field.validations ? this._setDynamicValidations(field) : null;
                        }
                        if (field.name === 'prefix') {
                            field.validations ? this._setDynamicValidations(field) : null;
                        }
                        if (field.name === 'awb') {
                            field.validations ? this._setDynamicValidations(field) : null;
                        }
                        if (field.name === 'hawb') {
                            this.hawbIsReplacement = of(field);
                            field.validations ? this._setDynamicValidations(field) : null;
                        }
                        if (field.name === 'number') {
                            field.validations ? this._setDynamicValidations(field) : null;
                        }
                        if (field.name === 'paymentType') {
                            field.validations ? this._setDynamicValidations(field) : null;
                        }
                        if (field.name === 'amount') {
                            field.validations ? this._setDynamicValidations(field) : null;
                        }
                        if (field.name === 'paidTo') {
                            field.validations ? this._setDynamicValidations(field) : null;
                        }
                        if (field.name === 'eiReferenceFileNumber') {
                            field.validations ? this._setDynamicValidations(field) : null;
                        }
                    }
                });
            });
        }
    }

    /**
     * @method setDynamicValidations()
     * @param (field: any)
     * @description Review if dynamic fields exits and if they do, we review if we have any isReplaced field
     */

    private _setDynamicValidations(field: any): void {
        this.paymentForm.get(field.name)!.setValidators(null);
        this.paymentForm.get(field.name)!.setErrors(null);
        if (field.validations.required) {
            if (field.validations.required.value === true) {
                this.paymentForm.get(field.name)!.addValidators(Validators.required);
            }
        }
        field.validations.minlength
            ? this.paymentForm.get(field.name)!.addValidators(Validators.minLength(field.validations.minlength.value))
            : null;
        field.validations.maxlength
            ? this.paymentForm.get(field.name)!.addValidators(Validators.maxLength(field.validations.maxlength.value))
            : null;
        field.validations.pattern
            ? this.paymentForm.get(field.name)!.addValidators(Validators.pattern(field.validations.pattern.value))
            : null;
        this.paymentForm.get(field.name)!.updateValueAndValidity();
    }

    /**
     * @method change()
     * @param (change: any)
     * @description Save all the changes in object
     */

    change(form: FormGroup): void {
        this._paymentFluxService.setData('details', form.getRawValue());
    }
}
