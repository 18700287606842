import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-no-information',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './no-information.component.html',
})
export class NoInformationComponent {
    @Input() error_message: any;
    @Input() paymentType?: any;

    constructor() {}
}
