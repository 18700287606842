import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { FacilityContactsRoutingModule } from './facility-contacts-routing.module';

//COMPONENTS

import { FacilityContactsComponent } from './facility-contacts.component';

//MODULES

import { SharedModule } from '../../../shared/shared.module';
import { FacilityContactDetailsComponent } from './facility-contact-details/facility-contact-details.component';
import { NotAvailablePipe } from '../../../pipes/not-available.pipe';

@NgModule({
    declarations: [FacilityContactsComponent, FacilityContactDetailsComponent],
    imports: [
        CommonModule,
        FacilityContactsRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        NotAvailablePipe,
    ],
})
export class FacilityContactsModule {
    constructor() {}
}
