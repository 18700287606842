import * as moment from 'moment';
import { permissionsName } from '../constants';

export const customerReferenceDhlData = {
    title: 'Logis File # / CW1 Shipment / Consol #',
    placeholder: 'Enter your Logis File # / CW1 Shipment / Consol #',
};

export class DHL {
    static readonly userTypeDHL = {
        requestor: permissionsName.dhlRequestor,
        approver: permissionsName.dhlApprover,
        finance: permissionsName.dhlLastApprover,
    };

    /*
     * REJECTED PAYMENTS
     */

    static readonly dhl_ColumnsRejectedPayment: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'customerReference',
        'amount',
        'awb',
        'sourceSystem',
    ];
    static readonly dhl_ColumnsNameRejectedPayment: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'LOGIS FILE# / CW1 SHIPMENT/Consol#',
        'Amount',
        'AWB',
        'Source system',
    ];
    static readonly dhl_getCompletedPaymentsRejectedPayment = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        sourceSystem: item.externalData ? item.externalData.sourceSystem : 'N/A',
    });
    static readonly dhl_ColumnsSortableRejectedPayment: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'customerReference',
        'amount',
        'sourceSystem',
    ];

    /*
     * VOIDED PAYMENTS
     */

    static readonly dhl_ColumnsVoidedPayment: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'customerReference',
        'amount',
        'awb',
        'sourceSystem',
    ];
    static readonly dhl_ColumnsNameVoidedPayment: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'LOGIS FILE# / CW1 SHIPMENT/Consol#',
        'Amount',
        'AWB',
        'Source system',
    ];
    static readonly dhl_getCompletedPaymentsVoidedPayment = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        sourceSystem: item.externalData ? item.externalData.sourceSystem : 'N/A',
    });
    static readonly dhl_ColumnsSortableVoidedPayment: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'customerReference',
        'amount',
        'sourceSystem',
    ];

    /*
     * HISTORY
     */

    static readonly dhl_ColumnsHistory: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'customerReference',
        'amount',
        'awb',
        'sourceSystem',
        'facilityPaymentDeliveryNotificationShort',
    ];
    static readonly dhl_ColumnsNameHistory: string[] = [
        'select',
        'Reference#',
        'Date',
        'Cargo facility',
        'LOGIS FILE# / CW1 SHIPMENT/Consol#',
        'Amount',
        'AWB',
        'Source system',
        'Delivery type',
    ];
    static readonly dhl_getCompletedPaymentsHistory = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        facilityName: item.facility ? item.facility.name : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        sourceSystem: item.externalData ? item.externalData.sourceSystem : 'N/A',
        paymentConfirmation: item.isPaymentConfirmationAvailable ? item.isPaymentConfirmationAvailable : '',
        externalPaymentConfirmation: item.isExternalPaymentConfirmationAvailable
            ? item.isExternalPaymentConfirmationAvailable
            : '',
        attachments: item.attachments ? item.attachments : null,
        hasDownloadableContent: item.hasDownloadableContent ?? true,
        facilityPaymentDeliveryNotificationShort:
            item.facility && item.facility.facilityPaymentDeliveryNotificationShort
                ? item.facility.facilityPaymentDeliveryNotificationShort
                : 'N/A',
    });
    static readonly dhl_ColumnsSortableHistory: string[] = [
        'createdDate',
        'customerReference',
        'awb',
        'amount',
        'sourceSystem',
    ];

    /*
     * PENDING APPROVAL
     */

    static readonly dhl_ColumnsPendingApproval: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'customerReference',
        'amount',
        'awb',
        'sourceSystem',
    ];
    static readonly dhl_ColumnsNamePendingApproval: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'LOGIS FILE# / CW1 SHIPMENT/Consol#',
        'Amount',
        'AWB',
        'Source system',
    ];
    static readonly dhl_getCompletedPaymentsPendingApproval = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        sourceSystem: item.externalData ? item.externalData.sourceSystem : 'N/A',
    });
    static readonly dhl_ColumnsSortablePendingApproval: string[] = [
        'createdDate',
        'facilityName',
        'customerReference',
        'awb',
        'sourceSystem',
        'amount',
    ];

    /*
     * PENDING APPROVAL HOME COMPONENT
     */

    static readonly dhl_ColumnsPendingApprovalHomeComponent: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'customerReference',
        'amount',
        'awb',
        'sourceSystem',
    ];
    static readonly dhl_ColumnsNamePendingApprovalHomeComponent: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'LOGIS FILE# / CW1 SHIPMENT/Consol#',
        'Amount',
        'AWB',
        'Source system',
    ];
    static readonly dhl_getCompletedPaymentsPendingApprovalHomeComponent = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../facilityPayments/pendingApproval/paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        sourceSystem: item.externalData ? item.externalData.sourceSystem : 'N/A',
    });
    static readonly dhl_ColumnsSortablePendingApprovalHomeComponent: string[] = [
        'createdDate',
        'facilityName',
        'customerReference',
        'awb',
        'sourceSystem',
        'amount',
    ];

    /*
     * USERS
     */

    static readonly dhl_ColumnsUsers: string[] = [
        'firstName',
        'lastName',
        'email',
        'homeLocation',
        'approverType',
        'approverLocations',
        'notificationEmail',
        'isMegafileEnabled',
    ];
    static readonly dhl_ColumnsNameUsers: string[] = [
        'First name',
        'Last name',
        'Email address',
        'Home location',
        'Approver type',
        'Locations for approver visibility',
        'Email notifications',
        'Megafile',
    ];
    static readonly dhl_getCompletedPaymentsUsers = (item: any) => ({
        firstName: item.firstName ? item.firstName : 'N/A',
        lastName: item.lastName ? item.lastName : 'N/A',
        email: item.email ? item.email : 'N/A',
        homeLocation: item.homeLocation ? item.homeLocation : 'N/A',
        approverType: item.approverType ? item.approverType : 'N/A',
        approverLocations: item.approverLocations ? item.approverLocations : 'N/A',
        notificationEmail: item.notificationEmail ? item.notificationEmail : 'N/A',
        isMegafileEnabled: item.isMegafileEnabled ? 'Yes' : 'N/A',
    });
}
