<mat-dialog-content id="refund-status-update-modal">
    <!-- START: REFUND STATUS UPDATE POPUP -->
    <section id="refund-status-update" class="w-100">
        <div id="refund-status-update__modal" class="container-fluid">
            <div class="refund-logo d-flex justify-content-center align-items-center">
                <img src="{{popupData.icon}}" alt="Refund status update" height="auto" width="auto" />
            </div>
            <div class="row header">
                <!-- START: HEADER -->
                <div class="text-center">
                    <h4 class="mb-0">{{popupData.title}}</h4>
                </div>
                <!-- END: HEADER -->
            </div>
            <form class="row" [formGroup]="refundForm">
                <div class="col-12">
                    <div class="form-group">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>{{popupData.reasonSelectLabel}}</mat-label>
                            <mat-select
                                id="refundReason"
                                name="refundReason"
                                formControlName="refundReason"
                                data-cy="refundReason"
                                [errorStateMatcher]="matcher"
                                (selectionChange)="resetCustomerNotes($event)"
                            >
                                <mat-option *ngFor="let reason of popupData.reasons" [value]="reason">
                                    {{reason}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="refundForm.controls['refundReason'].hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Cancellation reason is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="refundReason?.value === 'Other'">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>{{popupData.customNotesLabel}}</mat-label>
                            <textarea
                                matInput
                                id="customerNotes"
                                name="customerNotes"
                                formControlName="customerNotes"
                                autocomplete="off"
                                placeholder="{{popupData.customNotesPlaceholder}}"
                                data-cy="customerNotes"
                                [errorStateMatcher]="matcher"
                            ></textarea>
                            <mat-error *ngIf="refundForm.controls['customerNotes'].hasError('maxlength')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Custom reason cannot be greater than
                                the max length of 255
                            </mat-error>
                            <mat-error *ngIf="refundForm.controls['customerNotes'].hasError('minlength')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Minimum of 5 symbols
                            </mat-error>
                            <mat-error *ngIf="refundForm.controls['customerNotes'].hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>You need to write something!
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>

            <!-- START: BUTTON ACTIONS -->
            <div class="row">
                <div class="col-12">
                    <div id="download__actions" mat-dialog-actions class="pb-4 text-end d-block pt-1 pt-md-3">
                        <button
                            mat-button
                            type="button"
                            (click)="closeDialog()"
                            class="btn__sprintpay btn__sprintpay--link p-0 m-0 border-0 text-uppercase text-center"
                        >
                            {{popupData.cancelButtonText}}
                        </button>
                        <button
                            mat-button
                            type="button"
                            (click)="refund()"
                            class="btn__sprintpay btn__sprintpay--green p-0 m-0 border-0 rounded-0 text-uppercase text-center"
                            [disabled]="refundForm.invalid"
                        >
                            {{popupData.confirmButtonText}}
                        </button>
                    </div>
                </div>
            </div>
            <!-- END: BUTTON ACTIONS -->
        </div>
    </section>
    <!-- END: REFUND STATUS UPDATE POPUP POPUP -->
</mat-dialog-content>
