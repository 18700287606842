import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from '@cargos/sprintpay-models';
import { Observable } from 'rxjs';
import { Facility } from 'src/app/models/payments/facility.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';

@Injectable({ providedIn: 'root' })
export class FacilityAPIService {
    private _facilitiesUrl: string;

    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenService
    ) {
        this._facilitiesUrl = environment.uris.method.facilities;
    }

    /**
     * @method getFacilityDetailsRequest()
     * @param (facilityId: string)
     * @description Get facility details API call
     */

    getFacilityDetailsRequest(facilityId: string): Observable<Facility> {
        const url = `${this._facilitiesUrl}/facilities/${facilityId}/details`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<Facility>(url, { headers });
    }

    /**
     * @method getFrequentFacilities()
     * @param (facilitiesCount: number)
     * @description Get most frequent facilities API call
     */

    getFrequentFacilities(facilitiesCount: number): Observable<Facility[]> {
        const date = new Date().valueOf();
        const url = `${this._facilitiesUrl}/getMostFrequentFacilities/${facilitiesCount}?date=${date}`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<Facility[]>(url, { headers });
    }

    /**
     * @method searchFacilities()
     * @param (searchTerms: string)
     * @param (page: number)
     * @param (size: number)
     * @description Search facilities API call
     */

    searchFacilities(searchTerms: string, page: number, size: number): Observable<Pagination<Facility>> {
        const date = new Date().valueOf();
        const url = `${this._facilitiesUrl}/searchFacilities?searchTerms=${encodeURIComponent(
            searchTerms
        )}&page=${page}&size=${size}&date=${date}`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<Pagination<Facility>>(url, { headers });
    }

    facilityLookup(searchValue: string, facilityId: string, searchType: string, pickupDate?: string): Observable<any> {
        const queryParams = new URLSearchParams({
            operation: searchType,
            queryFields: pickupDate ? 'fileNumber,pickupDate' : 'fileNumber',
            queryValues: pickupDate ? searchValue + ',' + pickupDate : searchValue,
        });

        const url = `${this._facilitiesUrl}/facility/${facilityId}/lookup?${queryParams.toString()}`;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<Facility>(url, { headers });
    }
}
