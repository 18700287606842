import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, from, map, of, switchMap, take } from 'rxjs';
import Swal from 'sweetalert2';
import { Facility } from '../models/payments/facility.model';
import { FacilityService } from './facility.service';
import { CartAPIService } from './requests/cart-api.service';
import { FacilityAPIService } from './requests/facility-api.service';
import { SummaryValidationService } from './summary-validation.service';
import { PaymentFluxService } from './utils/payment-flux.service';
import { SessionService } from './utils/session.service';

@Injectable({
    providedIn: 'root',
})
export class PayToFacilityService {
    private _notes: string[];

    constructor(
        private _sessionService: SessionService,
        private _router: Router,
        private _paymentFluxService: PaymentFluxService,
        private _summaryValidationService: SummaryValidationService,
        private _facilityAPIService: FacilityAPIService,
        private _facilityService: FacilityService,
        private _cartApiService: CartAPIService
    ) {
        this._notes = [];
    }

    facilityHasRedirect(facility: any): boolean {
        return facility.payUrl && facility.awbLookupData == null;
    }

    modalRedirect(nameFacility: string, payUrl: string): void {
        Swal.fire({
            html: `<h5 class="mt-3" style='text-align: center; color: #032747;'>Please wait...Hey, psst psst...</h5>
           <div style='padding: 1rem 1rem 0 1rem; text-align: center;'>
              In order to complete a payment to <strong> ${nameFacility} </strong> you'll be redirected to its site
           </div>`,
            icon: 'info',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'Redirect',
            cancelButtonText: 'Cancel',
            allowOutsideClick: false,
            reverseButtons: true,
        }).then((result: any) => {
            if (result.isConfirmed) {
                window.open(payUrl, '_blank');
            }
        });
    }

    validateIfAgreeToContinuesWithNotes(facility: any): Observable<boolean> {
        const notes = facility.notesForClients;
        const notification = facility.facilityPaymentDeliveryNotification;
        const hasNotesOrNotification = !!notes || !!notification;
        return of(hasNotesOrNotification).pipe(
            switchMap((isThereNotesOrNotification) => {
                if (isThereNotesOrNotification) {
                    const facilityNotes = this.facilityNotes(facility);
                    const notificationMessage = notification ? `<p>${notification}</p>` : '';
                    return from(
                        Swal.fire({
                            html: `<h5 class="mt-3" style='text-align: center; color: #032747;'>Hey, Important notice...</h5>
                            <h6> ${notificationMessage} </h6>
                            <ul style="list-style: none; text-align:left">
                            ${facilityNotes}
                            </ul>`,
                            icon: 'info',
                            showCancelButton: true,
                            showCloseButton: true,
                            confirmButtonText: 'Continue',
                            cancelButtonText: 'Cancel',
                            allowOutsideClick: false,
                            reverseButtons: true,
                        })
                    ).pipe(map((result) => result.isConfirmed));
                }
                return of(true);
            })
        );
    }

    modalItemsInCart(message: string): Observable<boolean> {
        return from(
            Swal.fire({
                html: '<h5>Hey, psst psst...</h5>' + '<p>' + message + '</p>',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                allowOutsideClick: false,
                reverseButtons: true,
            })
        ).pipe(map((result) => result.isConfirmed));
    }

    payToFacility(facility: any): void {
        this._facilityAPIService.getFacilityDetailsRequest(facility.id).subscribe((facilityDetail: any) => {
            const facilitySelected: Facility = this._facilityService.facilityValidation({
                ...facility,
                ...facilityDetail,
            });
            if (this.facilityHasRedirect(facilitySelected)) {
                this.modalRedirect(facilitySelected.name, facilitySelected.payUrl || '');
            } else {
                this.validateNotesAndCart(facilitySelected)
                    .pipe(take(1))
                    .subscribe({
                        next: (response: any) => {
                            if (response) {
                                this._paymentFluxService.removeCurrentPayment();
                                this._paymentFluxService.removeDynamicFields();
                                this._paymentFluxService.removeDynamicValues();
                                this._paymentFluxService.setCurrentPayment({ facility: facilitySelected });

                                this._router.navigate([
                                    facilitySelected.externalLookup
                                        ? '/admin/facilityPayments/newPayment/codafacility'
                                        : `/admin/facilityPayments/newPayment/flux`,
                                ]);
                            }
                        },
                    });
            }
        });
    }

    validateNotesAndCart(facility: any): Observable<boolean> {
        return this._summaryValidationService.addToCartValidate('paymentRequest', facility).pipe(
            switchMap((response: any) => {
                if (response.continue) {
                    return this.validateIfAgreeToContinuesWithNotes(facility);
                }
                return this.modalItemsInCart(response.message).pipe(
                    switchMap((result) => {
                        if (result) {
                            return this.validateIfAgreeToContinuesWithNotes(facility).pipe(
                                switchMap((isAgreeToContinueWithNotes: boolean) => {
                                    if (isAgreeToContinueWithNotes) {
                                        //toDo: Change call from this._cartApiService.deleteCart() to this._cartService.deleteAndGetCart()
                                        return this._cartApiService.deleteCart().pipe(
                                            map(() => {
                                                this._sessionService.setPayCount(0);
                                                return true;
                                            })
                                        );
                                    }
                                    return of(false);
                                })
                            );
                        }
                        return of(false);
                    })
                );
            })
        );
    }

    facilityNotes(facility: any): string {
        if (
            !facility.notesForClients ||
            !Array.isArray(facility.notesForClients) ||
            facility.notesForClients.length === 0
        ) {
            return '';
        }
        facility.notesForClients = Array.isArray(facility.notesForClients)
            ? JSON.stringify(facility.notesForClients)
            : facility.notesForClients;
        this._notes =
            typeof facility.notesForClients === 'string'
                ? JSON.parse(facility.notesForClients)
                : facility.notesForClients;
        let $listItem = '';
        for (let index = 0; index < this._notes.length; index++) {
            if (facility.isWFS && facility.airportCode && facility.airportCode.toLowerCase() === 'lax') {
                this._notes[index] = this._convertUrl(this._notes[index]);
            }
            $listItem += '<li style="font-weight: 400">' + this._notes[index] + '</li>';
        }
        return $listItem;
    }

    /**
     * @method _convertUrl()
     * @param (data: any)
     * @description Transform the data into an URL
     */

    private _convertUrl(data: any) {
        if (data.includes('https://')) {
            const arrayData = data.split(' ');
            for (let index = 0; index < arrayData.length; index++) {
                let element = arrayData[index];
                if (element.startsWith('https://') && arrayData[index - 1].toLowerCase().includes('here')) {
                    let a = `<a href = '${element}' target = '_NEW'>`;
                    arrayData.splice(index - 2, 0, a);
                    arrayData.splice(index + 1, 1);
                    arrayData.splice(index + 1, 0, '</a>');
                }
            }
            return arrayData.join(' ');
        }
        return data;
    }
}
