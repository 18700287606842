<!--START: QR SCANNER COMPONENT -->
<div id="qr_scanner__container" class="d-flex flex-column align-items-center">
    <!--START: HEADING -->
    <div class="mb-3">
        <h6 class="mb-0">Scan your code</h6>
        <span class="d-block fw-lighter">Your scanning starts automatically.</span>
    </div>
    <!--END: HEADING -->

    <div class="form-group" *ngIf="cameras.length > 1">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>Select camera</mat-label>
            <mat-select [(ngModel)]="selectedDevice" [compareWith]="objectComparisonFunction">
                <mat-option *ngFor="let camera of cameras" [value]="camera"> {{camera.label}} </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!--START: QR SCANNER -->
    <div class="scanner d-flex justify-content-center align-items-center mb-3 p-3 w-100">
        <zxing-scanner
            class="h-100"
            #scanner
            previewFitMode="cover"
            [device]="selectedDevice"
            [delayBetweenScanSuccess]="DELAY_BETWEEN_SCAN_SUCCESS"
            [formats]="formats"
            (scanSuccess)="handleScanSuccess($event)"
            (camerasFound)="handleCamerasFound($event)"
            (camerasNotFound)="handleCamerasNotFound()"
            (permissionResponse)="handlePermissionResponse($event)"
        ></zxing-scanner>
    </div>
    <!--END: QR SCANNER -->
    <!--START: ERROR -->
    <div *ngIf="error" class="error">{{error}}</div>
    <!--END: ERROR -->
    <div class="d-flex justify-content-center align-items-center w-100">
        <span>OR</span>
    </div>
    <!--START: NAVIGATE TO DEFAULT SEARCH BUTTON -->
    <button
        mat-button
        type="button"
        class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0"
        (click)="showDefaultSearch()"
    >
        SEARCH CARGO FACILITY
    </button>
    <!--END: NAVIGATE TO DEFAULT SEARCH BUTTON -->
</div>
<!--END: QR SCANNER COMPONENT -->
