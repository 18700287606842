import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
    Cart,
    CartAWB,
    CartBill,
    ComponentPaymentMethodName,
    PayloadPayPal,
    PaymentCart,
    PaymentMetaData,
    PaymentResponse,
    SaleStatus,
} from '@cargos/sprintpay-models';
import { pay } from '@cargos/sprintpay_frontend_core_api/lib/pay/pay';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, combineLatest, map, of, switchMap, take, takeUntil } from 'rxjs';
import { RequestService } from 'src/app/services/requests.service';
import { CartAPIService } from 'src/app/services/requests/cart-api.service';
import { SummaryValidationService } from 'src/app/services/summary-validation.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { CartPayRequest, CartSelectedPaymentMethod, PaymentMethods } from 'src/app/utils/cartTypes';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';
import { CartService } from '../../../services/utils/cart.service';
import { SessionService } from '../../../services/utils/session.service';
import { StorageService } from '../../../services/utils/storage.service';
import { TokenService } from '../../../services/utils/token.service';
import { CartPayService } from './services/cart-pay.service';
import { PaymentConfirmationService } from './services/payment-confirmation.service';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
})
export class CartComponent implements OnInit, OnDestroy {
    payResult: any;
    paymentRequest: any;
    openInvoices: any;
    cartBill: any;
    shouldEnableButton: boolean;
    paymentSummaryHasErrors: boolean;
    isWarningMessageError: boolean;
    openInvoicesSubTotal: number;
    paymentRequestSubTotal: number;
    total: number;
    paymentsInCart: boolean;
    noInformation: boolean;
    paymentConfirmation: boolean;
    paymentResponse: PaymentResponse | null;
    hasRequestWithExternalLookup!: boolean;
    private _paymentMethodSelected!: CartSelectedPaymentMethod;
    private _unsubscribe$: Subject<void>;
    private readonly _isGuest: boolean;

    constructor(
        private _breadcrumbService: BreadcrumbService,
        private _storageService: StorageService,
        private _sessionService: SessionService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _cartService: CartService,
        private _domSanitizer: DomSanitizer,
        private _tokenService: TokenService,
        private _errorHandlerService: ErrorHandlerService,
        private _cartPayService: CartPayService,
        private _paymentConfirmationService: PaymentConfirmationService,
        private _customerService: CustomerService,
        private _requestService: RequestService,
        private _summaryService: SummaryValidationService,
        private _cartAPIService: CartAPIService
    ) {
        this._breadcrumbService.set('@cart', 'Cart');
        this.openInvoicesSubTotal = 0;
        this.paymentRequestSubTotal = 0;
        this.total = 0;
        this.shouldEnableButton = false;
        this.paymentSummaryHasErrors = false;
        this.isWarningMessageError = false;
        this.paymentsInCart = false;
        this.noInformation = false;
        this.paymentConfirmation = false;
        this.paymentResponse = null;
        this._unsubscribe$ = new Subject<void>();
        this._isGuest = this._customerService.getCustomer().isGuest;
    }

    ngOnInit(): void {
        this._getCart();
        this.getRequestType();
        this._subscribePaymentResponse();
        this._subscribeGetCartUpdate();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    getRequestType(): void {
        this._summaryService
            .hasRequestWithExternalLookup()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe({
                next: (result) => {
                    this.hasRequestWithExternalLookup = !!result.length;
                },
            });
    }

    /**
     * @method _getCart()
     * @description
     */

    private _getCart(displayLoading = true): void {
        if (displayLoading) {
            this._ngxSpinnerService.show();
        }
        combineLatest([this._cartService.getPayMethod(), this._summaryService.hasRequestWithExternalLookup()])
            .pipe(
                map(([selectedPayment, requestWithExternalLookup]) => {
                    this.hasRequestWithExternalLookup = !!requestWithExternalLookup.length;
                    this._paymentMethodSelected = selectedPayment;
                    if (this.hasRequestWithExternalLookup) {
                        return this.getCartBillV2(displayLoading, selectedPayment);
                    }
                    return this.getCartBillV1(displayLoading, selectedPayment);
                }),
                take(1)
            )
            .subscribe();
    }

    getCartBillV2(displayLoading: boolean, selectedPayment?: CartSelectedPaymentMethod): void {
        const paymentMethod: ComponentPaymentMethodName | undefined = selectedPayment
            ? (selectedPayment.method as ComponentPaymentMethodName)
            : undefined;
        this._cartAPIService
            .getCartBillV2(paymentMethod)
            .pipe(take(1))
            .subscribe({
                next: (result) => {
                    this.cartBill = result.cartBillResponseDTOList;
                    this._cartService.setCartBillV2(result);

                    this.paymentRequest = this.getPaymentRequestV2(result);
                    this.openInvoices = this.getOpenInvoicesV2(result);

                    if (this.cartBill?.length > 0) {
                        this.paymentsInCart = true;
                    }
                    this.noInformation = true;
                    this._sessionService.setPayCount(this.cartBill?.length);
                    this._sessionService.setPaymentRequest(this.paymentRequest.length);

                    if (displayLoading) {
                        this._ngxSpinnerService.hide();
                    }
                },
            });
    }

    getCartBillV1(displayLoading: boolean, selectedPayment?: CartSelectedPaymentMethod): void {
        of(selectedPayment)
            .pipe(
                switchMap((selectedPayment) => {
                    if (!selectedPayment) {
                        return this._cartAPIService.getCart().pipe(take(1));
                    }
                    return this._cartAPIService.getCartBillV1(selectedPayment.method).pipe(take(1));
                })
            )
            .subscribe({
                next: (result: CartBill) => {
                    this.cartBill = result.cart;
                    this._cartService.setCartBill(result);

                    this.paymentRequest = this.cartBill
                        .filter((item: any) => !item.originalInvoice)
                        .map((e: any) => {
                            this.paymentRequestSubTotal = this.paymentRequestSubTotal + e.amount;
                            return { e };
                        });
                    this.openInvoices = this.cartBill
                        .filter((item: any) => item.originalInvoice)
                        .map((e: any) => {
                            this.openInvoicesSubTotal = this.openInvoicesSubTotal + e.amount;
                            return { e };
                        });

                    if (this.cartBill?.length > 0) {
                        this.paymentsInCart = true;
                    }
                    this.noInformation = true;
                    this._sessionService.setPayCount(this.cartBill?.length);
                    this._sessionService.setPaymentRequest(this.paymentRequest.length);

                    if (displayLoading) {
                        this._ngxSpinnerService.hide();
                    }
                },
            });
    }

    /**
     * @method payCart()
     * @param (response: any)
     * @description
     */

    payCart(response: any): void {
        const cartSelectedPaymentMethod: CartSelectedPaymentMethod = response.paymentMethod;
        const cartBill = response.cartBill;
        this._ngxSpinnerService.show();
        let currentUserCart: any;
        if (this.hasRequestWithExternalLookup) {
            this.paymentRequest = this.getPaymentRequestV2(cartBill);
            this.openInvoices = this.getOpenInvoicesV2(cartBill);
            const newCart = [...this.paymentRequest, ...this.openInvoices];
            currentUserCart = this._requestService.updateRequestBeforePay(newCart);
        } else {
            currentUserCart = this._requestService.updateRequestBeforePay(cartBill.cart);
            this.paymentRequest = cartBill.cart.filter((item: any) => !item.originalInvoice);
            this.openInvoices = cartBill.cart.filter((item: any) => item.originalInvoice);
        }
        const tokenUser: string | null = this._storageService.getElement('TOKEN');
        let requestToPay: any = {
            token: tokenUser,
            paymentMethod: {
                paymentType: PaymentMethods[cartSelectedPaymentMethod.method],
            },
            clientCart: currentUserCart,
        };
        if (
            PaymentMethods[cartSelectedPaymentMethod.method] === PaymentMethods.CREDIT_CARD ||
            PaymentMethods[cartSelectedPaymentMethod.method] === PaymentMethods.ECHECK
        ) {
            requestToPay.paymentToken = {
                paymentToken: {
                    ...cartSelectedPaymentMethod.paymentAccount.paymentToken,
                    paymentMethodNumber:
                        PaymentMethods[cartSelectedPaymentMethod.method] === PaymentMethods.ECHECK
                            ? response.paymentMethod.paymentAccount?.accountingDetails?.accountLastFourDigits
                            : response.paymentMethod.paymentAccount?.accountingDetails?.lastFourDigits,
                },
            };

            if (PaymentMethods[cartSelectedPaymentMethod.method] === PaymentMethods.CREDIT_CARD) {
                requestToPay.paymentToken.paymentToken.paymentInstitution =
                    response.paymentMethod.paymentAccount?.accountingDetails?.cardType;
                requestToPay.paymentToken.paymentToken.paymentMethodExpirationMonth =
                    response.paymentMethod.paymentAccount?.accountingDetails?.expirationMonth;
                requestToPay.paymentToken.paymentToken.paymentMethodExpirationYear =
                    response.paymentMethod.paymentAccount?.accountingDetails?.expirationYear;
            }
        }

        pay(
            requestToPay.paymentMethod.paymentType,
            requestToPay.clientCart,
            { token: tokenUser },
            requestToPay.paymentToken
        ).subscribe({
            next: (payResult: any): void => {
                this.payResult = payResult;
                if ('SUCCESS' === payResult.saleStatus) {
                    this._ngxSpinnerService.hide();
                    this.paymentConfirmation = true;
                    this._sessionService.saveElement('payment_request', this.paymentRequest);
                    this._sessionService.saveElement('open_invoice', this.openInvoices);
                    this._sessionService.saveElement('paymentMethodApplied', requestToPay.paymentMethod);
                    payResult.authenticationToken
                        ? this._tokenService.setCurrentUser(payResult.authenticationToken)
                        : '';
                    this._sessionService.setPayCount(0);
                    this._sessionService.setPaymentRequest(0);
                }
            },
            error: (error: any): void => {
                this._ngxSpinnerService.hide();
                const template =
                    error && error.response && error.response.data
                        ? this._errorHandlerService.errorMsg(error.response.data)
                        : this._errorHandlerService.errorMsg(error);
                Swal.fire({
                    title: 'Oops...',
                    html: template,
                    icon: 'error',
                    customClass: {
                        htmlContainer: 'swal-container',
                    },
                    allowOutsideClick: false,
                    confirmButtonText: 'Ok',
                });
            },
        });
    }

    /**
     * @method hidePaymentEmptyCart()
     * @param (response: any)
     * @description Display empty cart and introduce the user to create new payment when the users click delete all
     */

    hidePaymentEmptyCart(response: any): void {
        if (response === true) {
            this.paymentsInCart = false;
            this.noInformation = true;
            this.paymentConfirmation = false;
        }
    }

    /**
     * @method showTermsOfUse()
     * @param (response: CartPayRequest)
     * @description
     */

    showTermsOfUse(response: CartPayRequest): void {
        if (response && response.paymentMethod) {
            if (this._isGuest) {
                this._payByPaypal(response);
                return;
            }

            const tosUrl = this._domSanitizer.sanitize(
                SecurityContext.RESOURCE_URL,
                this._domSanitizer.bypassSecurityTrustResourceUrl(
                    'https://cargosprint.com/legal/terms-of-use/sprintpay'
                )
            );

            Swal.fire({
                icon: 'info',
                html: `<p class="d-flex justify-content-center fw-500 my-3" style="font-size: 36px; text-align: left; color: #000000; margin: 0;">Continue to pay</p>
                <p class="text-align-center mt-3 mb-0" style="font-weight:400; color: #7D7C7C; font-size: 18px;">By clicking <b>I agree</b>, you are accepting our <a style="color:#14BB9C; text-decoration: underline;" href="${tosUrl}" target="_blank"><b>terms of use</b>.</a></p>`,
                width: '35em',
                showCancelButton: true,
                iconColor: '$info-color',
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: 'I agree',
                cancelButtonText: 'Cancel',
                cancelButtonColor: '#14bb9c',
                reverseButtons: true,
            }).then((result): void => {
                if (result.isConfirmed) {
                    this.shouldEnableButton = false;
                    if (response.paymentMethod.method === PaymentMethods.PAYPAL) {
                        this._payByPaypal(response);
                    } else {
                        this.payCart(response);
                    }
                }
            });
        }
    }

    /**
     * @method _payByPaypal()
     * @param (request: CartPayRequest)
     * @description
     */

    private _payByPaypal(request: CartPayRequest): void {
        this._ngxSpinnerService.show();

        let cartBill: CartBill | Cart;
        if (this.hasRequestWithExternalLookup) {
            cartBill = request.cartBill as Cart;
            this.paymentRequest = this.getPaymentRequestV2(cartBill);
            this.openInvoices = this.getOpenInvoicesV2(cartBill);
        } else {
            cartBill = request.cartBill as CartBill;
            this.paymentRequest = cartBill.cart.filter((item: any) => !item.originalInvoice);
            this.openInvoices = cartBill.cart.filter((item: any) => item.originalInvoice);
        }
        if (this._isGuest && request.paymentMethod.method !== PaymentMethods.PAYPAL) {
            request.payload = {
                customerReference: '',
                nonce: this._cartService.instant_guest_credit_card?.nonce,
                paymentMethodNumber: request.paymentMethod.paymentAccount?.details?.lastFour,
                paymentInstitution: request.paymentMethod.paymentAccount?.details?.cardType,
                paymentMethodExpirationMonth: request.paymentMethod.paymentAccount?.details?.expirationMonth,
                paymentMethodExpirationYear: request.paymentMethod.paymentAccount?.details?.expirationYear,
            };
        }

        request.payload = {
            ...request.payload,
            paymentMetadata: this.buildPaymentMetadata(request),
        };

        this._cartPayService
            .pay(request, this.hasRequestWithExternalLookup)
            .pipe(take(1))
            .subscribe({
                next: (payResult): void => {
                    if (request.paymentMethod.method === PaymentMethods.PAYPAL) {
                        const customerInformation = (<PayloadPayPal>request.payload).details;
                        this._cartService.setGuestCustomerInformation({
                            firstName: customerInformation?.firstName || '',
                            lastName: customerInformation?.lastName || '',
                            email: customerInformation?.email || '',
                            phone: customerInformation?.phone || '',
                        });
                        this._cartService.setGuestAddressInformation({
                            street: customerInformation?.shippingAddress?.line1 || '',
                            street2: '',
                            state: customerInformation?.shippingAddress?.state || '',
                            city: customerInformation?.shippingAddress?.city || '',
                            country: customerInformation?.shippingAddress?.countryCode || '',
                            zipCode: customerInformation?.shippingAddress?.postalCode || '',
                        });
                    }
                    this.payResult = payResult;
                    if (payResult.saleStatus === SaleStatus.SALE_SUCCESS) {
                        this._ngxSpinnerService.hide();
                        this.paymentConfirmation = true;
                        this._sessionService.saveElement('payment_request', this.paymentRequest);
                        this._sessionService.saveElement('open_invoice', this.openInvoices);
                        this._sessionService.saveElement('paymentMethodApplied', {
                            paymentType: request.paymentMethod,
                        });
                        if (payResult.authenticationToken) {
                            this._tokenService.setCurrentUser(payResult.authenticationToken);
                        }
                        this._sessionService.setPayCount(0);
                        this._sessionService.setPaymentRequest(0);
                        if (this._isGuest) {
                            this._paymentConfirmationService.setPaymentResponse(payResult);
                        }
                    }
                },
                error: (error: any): void => {
                    this._ngxSpinnerService.hide();
                    const template =
                        error && error.response && error.response.status == 400
                            ? this._errorHandlerService.errorMsg(error.response.data)
                            : this._errorHandlerService.errorMsg(error);
                    Swal.fire({
                        title: 'Oops...',
                        html: template,
                        icon: 'error',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok',
                    });
                },
            });
    }

    buildPaymentMetadata(request: CartPayRequest): PaymentMetaData {
        if (request.paymentMethod.method !== PaymentMethods.PAYPAL) {
            const personalInformation = this._cartService.instant_guest_personal_information;
            const addressInformation = this._cartService.instant_guest_address_information;

            return PaymentMetaData.fromJson({
                ...personalInformation,
                countryCode: personalInformation?.countryCode,
                phoneNumber: personalInformation?.phone,
                city: addressInformation?.city,
                state: addressInformation?.state,
                postalCode: addressInformation?.zipCode,
                country: addressInformation?.country,
                street: addressInformation?.street,
                street2: addressInformation?.street2,
            });
        }

        const customerInformation = (<PayloadPayPal>request.payload).details;

        return PaymentMetaData.fromJson({
            firstName: customerInformation?.firstName || '',
            lastName: customerInformation?.lastName || '',
            email: customerInformation?.email || '',
            phoneNumber: customerInformation?.phone || '',
            state: customerInformation?.shippingAddress?.state || '',
            city: customerInformation?.shippingAddress?.city || '',
            country: customerInformation?.shippingAddress?.countryCode || '',
            postalCode: customerInformation?.shippingAddress?.postalCode || '',
            street: customerInformation?.shippingAddress?.line1,
        });
    }

    /**
     * @method _subscribePaymentResponse()
     * @description Get paymentResponse value from service
     */

    private _subscribePaymentResponse(): void {
        this._paymentConfirmationService
            .getPaymentResponse()
            .pipe(
                map((paymentResponse) => {
                    this.paymentResponse = paymentResponse;
                }),
                takeUntil(this._unsubscribe$)
            )
            .subscribe();
    }

    /**
     * @method _subscribeGetCartUpdate()
     * @description
     */
    private _subscribeGetCartUpdate(): void {
        this._cartService
            .getUpdate()
            .pipe(
                map(() => {
                    if (this.hasRequestWithExternalLookup) {
                        return this.getCartBillV2(false, this._paymentMethodSelected);
                    }
                    return this.getCartBillV1(false, this._paymentMethodSelected);
                }),
                takeUntil(this._unsubscribe$)
            )
            .subscribe();
    }

    getPaymentRequestV2(cartBill: Cart): any {
        return cartBill?.cartBillResponseDTOList
            .map((cartBillResponse: CartAWB) => {
                return cartBillResponse.paymentRequest;
            })
            .flat()
            .filter((paymentCart: PaymentCart) => !paymentCart.originalInvoice);
    }

    getOpenInvoicesV2(cartBill: Cart): any {
        return cartBill?.cartBillResponseDTOList
            .map((cartBillResponse: CartAWB) => {
                return cartBillResponse.paymentRequest;
            })
            .flat()
            .filter((paymentCart: PaymentCart) => paymentCart.originalInvoice);
    }
}
