import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsTokensType, CustomersType } from '@cargos/sprintpay-models';
import {
    Observable,
    Subject,
    catchError,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    of,
    switchMap,
    take,
    takeUntil,
    throwError,
} from 'rxjs';
import { BannerService } from 'src/app/standalone-components/banner/services/banner.service';
import { SignUpAPIService } from '../requests/signup-api.service';
import { AuthService } from './auth.service';
import { CartService } from './cart.service';
import { CustomerService } from './customer-handler.service';
import { PaymentFluxService } from './payment-flux.service';
import { SessionWorkerService } from './session-worker';
import { StorageService } from './storage.service';
import { TokenService } from './token.service';

@Injectable({
    providedIn: 'root',
})
export class UserSessionService {
    private unsubscribe$: Subject<void>;
    private authenticatedCustomerTypes = [CustomersType.SALESFORCE_AUTHENTICATED, CustomersType.BUSINESS];

    constructor(
        private sessionWorkerService: SessionWorkerService,
        private authService: AuthService,
        private tokenService: TokenService,
        private signUpAPIService: SignUpAPIService,
        private customerService: CustomerService,
        private router: Router,
        private storageService: StorageService,
        private paymentFluxService: PaymentFluxService,
        private cartService: CartService,
        private ngZone: NgZone,
        private bannerService: BannerService
    ) {
        this.unsubscribe$ = new Subject<void>();
        this.subscribeUpdateTokens();
        this.subscribeCloseSessions();
    }

    signInAsGuest(): Observable<CredentialsTokensType | null | undefined> {
        return this.sessionWorkerService.requestTokens().pipe(
            switchMap(({ tokens: workerTokens, isRequestAllowed }) => {
                if (!workerTokens && isRequestAllowed) {
                    return this.authService.signInAsGuest().pipe(
                        map((tokens: CredentialsTokensType) => {
                            this.authService.initConfigTokens(tokens);
                            this.postTokens(tokens);
                            return tokens;
                        })
                    );
                } else if (workerTokens) {
                    this.authService.initConfigTokens(workerTokens);
                }

                return of(workerTokens);
            })
        );
    }

    refreshToken(): Observable<string> {
        return this.sessionWorkerService.isTokenRefreshAllowed().pipe(
            switchMap(({ isRequestAllowed }) => {
                if (isRequestAllowed) {
                    return this.signUpAPIService.refreshToken(this.tokenService.instant_refresh_token).pipe(
                        map((tokens: CredentialsTokensType) => {
                            this.authService.initConfigTokens(tokens);
                            this.postTokens(tokens);
                            return tokens.authorizationToken;
                        }),
                        catchError(() => {
                            this.postTokens(null);
                            return throwError(() => new Error('Refresh token error'));
                        })
                    );
                }

                return of('');
            })
        );
    }

    subscribeUpdateTokens(): void {
        this.sessionWorkerService.updateTokens$
            .pipe(
                takeUntil(this.unsubscribe$),
                map((tokens) => {
                    if (tokens) {
                        this.authService.initConfigTokens(tokens);
                    }
                })
            )
            .subscribe();
    }

    subscribeCloseSessions(): void {
        this.sessionWorkerService.sessionsClosed$.pipe(filter((closed) => closed)).subscribe((closed) => {
            if (closed) {
                this.finishSession();
                this.router.navigate(['/login']);
            }
        });
    }

    hasToken$(): Observable<boolean> {
        return this.tokenService.getCurrentToken$().pipe(
            map((token) => !!token),
            distinctUntilChanged()
        );
    }

    isAuthenticated(): boolean {
        return this.authenticatedCustomerTypes.includes(this.customerService.getCustomerType());
    }

    isAuthenticated$(): Observable<boolean> {
        return this.tokenService.getCurrentToken$().pipe(
            map(() => {
                return this.authenticatedCustomerTypes.includes(this.customerService?.getCustomerType());
            }),
            distinctUntilChanged()
        );
    }

    /**
     * @method logout()
     * @description Ends the session of the users, deletes the storage (session and local) and takes the user to the login page
     */

    logout(): void {
        this.authService
            .reviewBeforeLogout()
            .pipe(
                take(1),
                finalize(() => {
                    this.ngZone.run(() => this.router.navigate(['/login']));
                    this.sessionWorkerService.closeSessions();
                })
            )
            .subscribe({
                next: (): void => {
                    this.storageService.cleanStorage();
                    this.finishSession();
                },
            });
    }

    finishSession(): void {
        this.tokenService.resetAuthTokens();
        this.paymentFluxService.removeCurrentPayment();
        this.paymentFluxService.removeDynamicValues();
        this.paymentFluxService.removeDynamicFields();
        this.cartService.showPayMethod(null);
        this.cartService.clearGuestData();
        this.bannerService.setNotifications([]);
    }

    postTokens(tokens: CredentialsTokensType | null): void {
        this.sessionWorkerService.postTokens(tokens);
    }
}
