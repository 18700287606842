<ng-container *ngIf="facilities.length > 0">
    <!--START: FREQUENT FACILITIES -->
    <section id="frequent_facilities" class="container-fluid my-4">
        <div class="row">
            <!--START: FACILITIES SVG AND TITLE -->
            <div class="col-12 col-lg-4">
                <div
                    class="d-flex align-items-sm-start justify-content-sm-start align-items-md-center justify-content-md-center h-100"
                >
                    <div class="row">
                        <!--START: FACILITIES SVG -->
                        <div class="col-12">
                            <h6 class="text-sm-start text-md-center mb-3 mt-3 mt-lg-0">
                                Pay one of your frequent facilities
                            </h6>
                        </div>
                        <!--END: FACILITIES SVG -->
                        <!--START: FACILITIES TITLE -->
                        <div class="col-12">
                            <img
                                class="mx-auto my-3 d-block image"
                                src="assets/images/home/frequent-facilities.svg"
                                alt="Frequent facilities illustration"
                            />
                        </div>
                        <!--END: FACILITIES TITLE -->
                    </div>
                </div>
            </div>
            <!--END: FACILITIES SVG AND TITLE -->
            <!--START: FACILITIES CONTAINER -->
            <div class="col-12 col-lg-8">
                <div class="row">
                    <!--START: FACILITIES ITEM -->
                    <ng-container *ngFor="let item of facilities; trackBy: trackBy">
                        <article class="col-12 col-md-6 mb-3 mt-3">
                            <div class="facilities_item h-100 d-flex flex-column">
                                <h6>{{item.name}}</h6>
                                <p class="facilities_item--address d-block fw-normal flex-grow-1">
                                    {{item.airportCode}}, {{item.address}}
                                </p>
                                <!--START: FACILITIES DELIVERY TYPE -->
                                <p class="facilities_item--type d-flex align-items-center">
                                    <ng-container
                                        *ngIf="
                                    item.facilityPaymentDeliveryNotificationShort
                                    else staticMessage"
                                    >
                                        <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
                                        <span>{{ item.facilityPaymentDeliveryNotificationShort }}</span>
                                    </ng-container>
                                    <ng-template #staticMessage>
                                        <ng-container *ngIf="item?.deliveryType?.toLowerCase() === 'electronic'">
                                            <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
                                            Electronic less than 1 hour
                                        </ng-container>
                                        <ng-container *ngIf="item?.deliveryType?.toLowerCase() === 'messenger'">
                                            <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
                                            Messenger < 3 hour
                                        </ng-container>
                                        <ng-container *ngIf="item?.deliveryType?.toLowerCase() === 'fedex'">
                                            <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
                                            FedEx = Next business day by 10:30 AM
                                        </ng-container>
                                    </ng-template>
                                </p>
                                <!--END: FACILITIES DELIVERY TYPE -->
                                <button
                                    mat-flat-button
                                    color="primary"
                                    type="button"
                                    class="text-uppercase btn__sprintpay btn__sprintpay--green"
                                    (click)="payToFacility(item)"
                                >
                                    Pay again
                                </button>
                            </div>
                        </article>
                    </ng-container>
                    <!--START: FACILITIES ITEM -->
                </div>
            </div>
            <!--END: FACILITIES CONTAINER -->
        </div>
    </section>
    <!--END: FREQUENT FACILITIES -->
</ng-container>
