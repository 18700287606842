import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../pages/auth/services/login.service';
import { CustomerService } from '../services/utils/customer-handler.service';
import { TokenService } from '../services/utils/token.service';

@Injectable({
    providedIn: 'root',
})
export class ActiveSessionGuard {
    constructor(
        private tokenService: TokenService,
        private router: Router,
        private loginService: LoginService,
        private customerService: CustomerService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.tokenService.isAuthenticated() && !this.customerService.isGuest()) {
            const url = this.loginService.getRedirectLink();
            this.router.navigate([url]);
            return true;
        }

        return true;
    }
}
