import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { Facility } from 'src/app/models/payments/facility.model';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class NewPaymentService {
    private _selectedPayment: BehaviorSubject<Facility | undefined> = new BehaviorSubject<Facility | undefined>(
        undefined
    );
    private readonly _paymentsUrl: string;

    constructor(
        private _router: Router,
        private _customerService: CustomerService,
        private _ngxSpinnerService: NgxSpinnerService
    ) {
        this._paymentsUrl = environment.uris.method.payments;
    }

    /**
     * @method setSelectedPayment()
     * @param (facility: Facility | undefined)
     * @description
     */
    setSelectedPayment(facility: Facility | undefined): void {
        this._selectedPayment.next(facility);
    }

    /**
     * @method getSelectedPayment()
     * @description
     */

    getSelectedPayment(): Observable<Facility | undefined> {
        return this._selectedPayment.asObservable();
    }

    /**
     * @method newPaymentDataShare()
     * @param (facility: Facility)
     * @param (paymentType: string)
     * @description Shares the data between Facility and Payment Flux
     */

    newPaymentDataShare(facility: Facility, paymentType: string): void {
        if (facility && paymentType) {
            this.setSelectedPayment(facility);

            if (paymentType === 'individual') {
                this._router.navigate(['/admin/facilityPayments/newPayment/flux']);
            } else {
                this._router.navigate(['/admin/facilityPayments/newPayment/bulk-payment']);
            }
        }
    }

    /**
     * @method swalFacilitySelect()
     * @param (facility: Facility)
     * @description Display the Swal for Bulk or individual payment
     */

    swalFacilitySelect(facility: Facility): void {
        const bulkActive: boolean | undefined = this._customerService
            .getCustomer()
            ?.featureFlags?.find((featureFlag: string) => featureFlag === 'megaFileUploadToCart');

        if (bulkActive) {
            Swal.fire({
                icon: 'info',
                html:
                    '<h5>How would you like to pay?</h5>' +
                    '<p>Choose your preferred option</p>' +
                    '<br>' +
                    "<div class='row'>" +
                    "<div class='col-12 col-md-6'>" +
                    "<small class='d-block text-center text-muted mb-2'>Individual payments</small>" +
                    '<button type="button" role="button" class="btn btn-link d-block mx-auto text-decoration-none text-uppercase btn__sprintpay btn__sprintpay--link newPaymentIndividual">' +
                    'Pay individual' +
                    '</button>' +
                    '</div>' +
                    "<div class='col-12 col-md-6'>" +
                    "<small class='d-block text-center text-muted mb-2'>Multiple payments</small>" +
                    '<button type="button" role="button" class="btn btn-link d-block mx-auto text-decoration-none text-uppercase btn__sprintpay btn__sprintpay--link newPaymentBulk">' +
                    'Pay bulk' +
                    '</button>' +
                    '</div>' +
                    '</div>',
                showCancelButton: false,
                showConfirmButton: false,
                didOpen: () => {
                    const newPaymentIndividual: any = document.querySelector('.newPaymentIndividual');
                    const newPaymentBulk: any = document.querySelector('.newPaymentBulk');

                    newPaymentIndividual.addEventListener('click', () => {
                        this._ngxSpinnerService.show();
                        this.newPaymentDataShare(facility, 'individual');
                        Swal.close();
                    });

                    newPaymentBulk.addEventListener('click', () => {
                        this._ngxSpinnerService.show();
                        this.newPaymentDataShare(facility, 'bulk');
                        Swal.close();
                    });
                },
            });
        } else {
            this.newPaymentDataShare(facility, 'individual');
        }
    }
}
