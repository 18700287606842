<!--START: PAYMENT METHODS -->
<section id="paymentMethods-cart" #paymentMethodsCart>
    <!--START: CLOSE BUTTON -->
    <div class="d-flex justify-content-end">
        <button (click)="closeDialog()" matSuffix mat-icon-button aria-label="Clear">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <!--END: CLOSE BUTTON -->
    <!--START: TITLE -->
    <div id="paymentMethods-cart__header" class="mb-1 pt-3">
        <div class="row">
            <div class="col-12">
                <h6 class="ms-2">Payment method(s)</h6>
            </div>
            <ng-container *ngIf="moreThanOnePaymentOption">
                <div class="col-12">
                    <small class="text-muted mt-2 d-block"
                        >* If you have more than one eCheck or credit card payment option, you need to select one from
                        the dropdown.</small
                    >
                </div>
            </ng-container>
            <ng-container *ngIf="invoicesInCart && sprintPayCreditMsg">
                <div class="col-12">
                    <small class="text-muted mt-2 d-block"
                        >* <strong>SprintPay Credit</strong>&nbsp;is unavailable because you have open invoices in your
                        cart. If you want to use your credit, please empty your cart.</small
                    >
                </div>
            </ng-container>
        </div>
    </div>
    <!--END: TITLE -->
    <!--START: PAYMENT METHODS TABS -->
    <ng-container>
        <div id="paymentMethods-cart__container" class="container-fluid mb-4 pb-4">
            <div class="row">
                <div class="col-12">
                    <!--START: PAYMENT METHODS TABS CONTROLS -->
                    <ul class="nav nav-tabs" style="overflow-x: auto; overflow-y: hidden; flex-wrap: nowrap">
                        <li
                            id="credit-card"
                            class="nav-item me-3 me-lg-0"
                            style="white-space: nowrap"
                            *ngIf="creditCard"
                            (click)="paymentMethodSelected = paymentMethods.CREDIT_CARD"
                        >
                            <span
                                id="nav-item_tab1"
                                class="nav-link text-uppercase"
                                data-bs-target="#tab1"
                                data-bs-toggle="tab"
                                [ngClass]="{'active' : paymentMethodSelected === paymentMethods.CREDIT_CARD}"
                                >Credit cards</span
                            >
                        </li>
                        <li
                            id="echeck"
                            class="nav-item me-3 me-lg-0"
                            style="white-space: nowrap"
                            *ngIf="eCheck"
                            (click)="paymentMethodSelected = paymentMethods.ECHECK"
                        >
                            <span
                                id="nav-item_tab2"
                                class="nav-link text-uppercase"
                                data-bs-target="#tab2"
                                data-bs-toggle="tab"
                                [ngClass]="{'active' : paymentMethodSelected === paymentMethods.ECHECK}"
                                >Echecks</span
                            >
                        </li>
                        <li
                            id="cargo-credit"
                            class="nav-item me-3 me-lg-0"
                            style="white-space: nowrap"
                            *ngIf="sprintPayCredit"
                            (click)="paymentMethodSelected = paymentMethods.CARGOSPRINT_CREDIT"
                        >
                            <span
                                id="nav-item_tab3"
                                class="nav-link text-uppercase"
                                data-bs-target="#tab3"
                                data-bs-toggle="tab"
                                [ngClass]="{'active' : paymentMethodSelected === paymentMethods.CARGOSPRINT_CREDIT}"
                                >SprintPay Credit</span
                            >
                        </li>
                        <li
                            id="paypal"
                            class="nav-item me-3 me-lg-0"
                            style="white-space: nowrap"
                            *ngIf="paypal"
                            (click)="paymentMethodSelected = paymentMethods.PAYPAL"
                        >
                            <span
                                id="nav-item_tab5"
                                class="nav-link text-uppercase"
                                data-bs-target="#tab5"
                                data-bs-toggle="tab"
                                [ngClass]="{'active' : paymentMethodSelected === paymentMethods.PAYPAL}"
                                >PayPal</span
                            >
                        </li>
                    </ul>
                    <!--END: PAYMENT METHODS TABS CONTROLS -->
                    <!--START: PAYMENT METHODS TAB CONTENT -->
                    <div class="tab-content" *ngIf="!isLoading; else loader">
                        <div
                            class="tab-pane"
                            id="tab1"
                            *ngIf="creditCard"
                            [ngClass]="{'active' : paymentMethodSelected === paymentMethods.CREDIT_CARD}"
                        >
                            <ng-container *ngTemplateOutlet="creditCardTemplate"></ng-container>
                            <!--START: NO CREDIT CARD -->
                            <ng-container *ngIf="noCreditCards">
                                <section class="container-fluid">
                                    <div
                                        class="d-flex flex-column h-100 justify-content-center align-items-center mt-5"
                                    >
                                        <div class="row">
                                            <div class="col-12">
                                                <img
                                                    class="mx-auto d-block noPaymentMethod"
                                                    src="assets/images/home/frequent-facilities.svg"
                                                    alt="New user illustration"
                                                />
                                            </div>
                                            <div class="col-12">
                                                <h6 class="d-block text-center mt-5 mb-3">
                                                    It looks like you don't have any cards added yet
                                                </h6>
                                                <p class="d-block text-center mt-3 mb-4">
                                                    Click the new credit card button to add one
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </ng-container>
                            <!--END: NO CREDIT CARD -->
                        </div>
                        <div
                            class="tab-pane"
                            id="tab2"
                            *ngIf="eCheck"
                            [ngClass]="{'active' : paymentMethodSelected === paymentMethods.ECHECK}"
                        >
                            <ng-container *ngTemplateOutlet="eCheckTemplate"></ng-container>
                            <!--START: NO ECHEKS -->
                            <ng-container *ngIf="noEchecks">
                                <section class="container-fluid">
                                    <div
                                        class="d-flex flex-column h-100 justify-content-center align-items-center mt-5"
                                    >
                                        <div class="row">
                                            <div class="col-12">
                                                <img
                                                    class="mx-auto d-block noPaymentMethod"
                                                    src="assets/images/home/frequent-facilities.svg"
                                                    alt="New user illustration"
                                                />
                                            </div>
                                            <div class="col-12">
                                                <h6 class="d-block text-center mt-5 mb-3">
                                                    It looks like you don't have any eCheck added yet
                                                </h6>
                                                <p class="d-block text-center mt-3 mb-4">
                                                    Click the new eCheck button to add one
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </ng-container>
                            <!--END: NO ECHEKS -->
                        </div>
                        <div
                            class="tab-pane"
                            id="tab3"
                            *ngIf="sprintPayCredit"
                            [ngClass]="{'active' : paymentMethodSelected === paymentMethods.CARGOSPRINT_CREDIT}"
                        >
                            <ng-container *ngTemplateOutlet="cargoCreditTemplate"></ng-container>
                        </div>
                        <div
                            class="tab-pane"
                            id="tab5"
                            *ngIf="paypal"
                            [ngClass]="{'active' : paymentMethodSelected === paymentMethods.PAYPAL}"
                        >
                            <ng-container *ngTemplateOutlet="paypalTemplate"></ng-container>
                        </div>
                    </div>
                    <!--END: PAYMENT METHODS TAB CONTENT -->
                    <!--START: LOADER -->
                    <ng-template #loader>
                        <div class="tab-content">
                            <div class="d-flex justify-content-center">
                                <mat-spinner></mat-spinner>
                            </div>
                        </div>
                    </ng-template>
                    <!--END: LOADER -->
                </div>
            </div>
        </div>
    </ng-container>
    <!--END: PAYMENT METHODS TABS -->
</section>
<!--END: PAYMENT METHODS -->
<!--START: CREDIT CARD TEMPLATE -->
<ng-template #creditCardTemplate>
    <mat-list class="scrollable">
        <div mat-subheader class="mb-3">
            <div class="d-flex w-100 mat-subheader__container">
                <span
                    class="d-flex align-items-center justify-content-start w-50 mat-subheader__title d-none d-md-block"
                    >Credit card</span
                >
                <div class="d-flex align-items-center justify-content-end w-100 w-md-50 mat-subheader__button">
                    <button
                        mat-flat-button
                        color="primary"
                        type="button"
                        class="btn__sprintpay btn__sprintpay--green p-0 mt-0 border-0 rounded-0 text-uppercase text-center"
                        routerLink="newPaymentMethods/credit-card"
                    >
                        New credit card
                    </button>
                </div>
            </div>
        </div>
        <ng-container *ngFor="let card of creditCards; trackBy: trackBy">
            <ng-container *ngIf="card.accountingDetails.expirationYear >= currentYear">
                <ng-container
                    *ngIf="card.accountingDetails.expirationMonth >= currentMonth || card.accountingDetails.expirationYear > currentYear"
                >
                    <mat-list-item class="my-3 h-auto overflow-y">
                        <div class="d-flex flex-row justify-content-start align-items-center listItemWithScrollPadding">
                            <mat-checkbox
                                class="me-3"
                                (click)="selectPaymentMethod(card, 'CREDIT_CARD')"
                                [color]="'primary'"
                            ></mat-checkbox>
                            <img
                                *ngIf="card.accountingDetails?.cardType === 'VISA'"
                                src="assets/images/credit-card-brand/types/visa.svg"
                                alt="Visa logo"
                                class="me-3"
                            />
                            <img
                                *ngIf="card.accountingDetails?.cardType === 'MASTERCARD'"
                                src="assets/images/credit-card-brand/types/mastercard.svg"
                                alt="Mastercard logo"
                                class="me-3"
                            />
                            <img
                                *ngIf="card.accountingDetails?.cardType === 'DISCOVER'"
                                src="assets/images/credit-card-brand/types/discover.svg"
                                alt="Discover logo"
                                class="me-3"
                            />
                            <img
                                *ngIf="card.accountingDetails?.cardType === 'AMERICAN EXPRESS'"
                                src="assets/images/credit-card-brand/types/american-express.svg"
                                alt="Amex logo"
                                class="me-3"
                            />
                            <img
                                *ngIf="card.accountingDetails?.cardType === 'JCB'"
                                src="assets/images/credit-card-brand/types/jcb.svg"
                                alt="JCB logo"
                                class="me-3"
                            />
                            <img
                                *ngIf="card.accountingDetails?.cardType === 'UNIONPAY'"
                                src="assets/images/credit-card-brand/types/unionpay.svg"
                                alt="UNIONPAY logo"
                                class="me-3"
                            />
                            <div class="payment__ending" mat-line>
                                <span class="d-block"
                                    ><strong>{{card.accountingDetails.cardType}}:</strong>
                                    {{card.accountingDetails.lastFourDigits}}</span
                                >
                                <span class="d-block"
                                    ><strong>Exp. date:</strong>
                                    {{card.accountingDetails.expirationMonth}}/{{card.accountingDetails.expirationYear}}</span
                                >
                            </div>
                        </div>
                    </mat-list-item>
                </ng-container>
            </ng-container>
        </ng-container>
        <!--START: LOADER -->
        <div
            *ngIf="loadingCreditCards && (!creditCards || (creditCards && creditCards.length < 1))"
            class="tab-content"
        >
            <div class="d-flex justify-content-center">
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <!--END: LOADER -->
    </mat-list>
</ng-template>
<!--END: CREDIT CARD TEMPLATE -->
<!--START: ECHECK TEMPLATE -->
<ng-template #eCheckTemplate>
    <mat-list class="scrollable">
        <div mat-subheader class="mb-3">
            <div class="d-flex w-100 mat-subheader__container">
                <span class="d-flex align-items-center justify-content-start w-50 mat-subheader__title">Echeck</span>
                <div class="d-flex align-items-center justify-content-end w-50 mat-subheader__button">
                    <button
                        mat-flat-button
                        color="primary"
                        type="button"
                        class="btn__sprintpay btn__sprintpay--green p-0 mt-0 border-0 rounded-0 text-uppercase text-center"
                        routerLink="newPaymentMethods/echeck"
                    >
                        New echeck
                    </button>
                </div>
            </div>
        </div>
        <mat-list-item class="my-3" *ngFor="let eCheck of eChecks; trackBy: trackBy">
            <div class="d-flex justify-content-start d-flex flex-row align-items-center listItemWithScrollPadding">
                <mat-checkbox
                    class="me-3"
                    (click)="selectPaymentMethod(eCheck, 'ECHECK')"
                    [color]="'primary'"
                ></mat-checkbox>
                <img src="assets/images/credit-card-brand/echeck.svg" alt="eCheck logo" class="me-3" />
                <div class="payment__ending" mat-line>
                    <span class="d-block"><strong>Echeck</strong></span>
                    <span class="d-block">Account ending ---- {{eCheck.accountingDetails.accountLastFourDigits}}</span>
                </div>
            </div>
        </mat-list-item>
        <!--START: LOADER -->
        <div *ngIf="loadingEchecks && (!eChecks || (eChecks && eChecks.length < 1))" class="tab-content">
            <div class="d-flex justify-content-center">
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <!--END: LOADER -->
    </mat-list>
</ng-template>
<!--END: ECHECK TEMPLATE -->
<!--START: CARGO CREDIT TEMPLATE -->
<ng-template #cargoCreditTemplate>
    <div class="row">
        <div class="col-sm-12 col-md-6 offset-sm-0 offset-md-3">
            <div class="text-center">
                <img class="my-3" src="assets/images/sprintpay-horizontal-logo.svg" alt="SprintPay logo" />
                <span class="sprintPayCredit__total d-block mb-3"
                    ><strong
                        >Total available credit: {{sprintPayCreditAmount?.availableCredit | currency}}</strong
                    ></span
                >
                <button
                    mat-button
                    [disabled]="!sprintPayCreditAmount"
                    (click)="selectPaymentMethod(sprintPayCreditAmount?.availableCredit, 'CARGOSPRINT_CREDIT')"
                    type="button"
                    class="selectPaymentButton btn__sprintpay btn__sprintpay--green p-0 mt-0 border-0 rounded-0 text-uppercase text-center"
                >
                    SELECT THIS PAYMENT
                </button>
            </div>
        </div>
    </div>
</ng-template>
<!--END: CARGO CREDIT TEMPLATE -->
<!--START: PAYPAL TEMPLATE -->
<ng-template #paypalTemplate>
    <div class="row">
        <div class="col-sm-12 col-md-6 offset-sm-0 offset-md-3">
            <div class="text-center">
                <img class="my-5" src="assets/images/paypal.svg" alt="Paypal logo" />
                <span class="sprintPayCredit__total d-block mb-3"></span>
                <button
                    mat-button
                    type="button"
                    (click)="selectPaymentMethod(true, 'PAYPAL')"
                    class="selectPaymentButton btn__sprintpay btn__sprintpay--green p-0 mt-0 border-0 rounded-0 text-uppercase text-center"
                >
                    SELECT THIS PAYMENT
                </button>
            </div>
        </div>
    </div>
</ng-template>
<!--END: PAYPAL TEMPLATE -->
