import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { UploadRequestRoutingModule } from './upload-request-routing.module';

//COMPONENTS

import { UploadRequestComponent } from './upload-request.component';

//MODULES

import { UploadPaymentRequestService } from 'src/app/services/requests/upload-payment-request.service';
import { SharedModule } from '../../../../shared/shared.module';

@NgModule({
    declarations: [UploadRequestComponent],
    imports: [CommonModule, UploadRequestRoutingModule, RouterModule, ReactiveFormsModule, FormsModule, SharedModule],
    providers: [UploadPaymentRequestService],
})
export class UploadRequestModule {
    constructor() {}
}
