import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Cart, CartAWB, CartBill, ComponentPaymentMethodName, PaymentCart } from '@cargos/sprintpay-models';
import { BehaviorSubject, Observable, Subject, distinctUntilChanged, map, switchMap, take } from 'rxjs';
import { PersonalInformation } from 'src/app/models/auth/signup.model';
import { AddressInformationFormType } from 'src/app/models/utils/forms/address-information-form';
import { ConfirmationDialogComponent } from 'src/app/standalone-components/confirmation-dialog/confirmation-dialog.component';
import { CartSelectedPaymentMethod } from 'src/app/utils/cartTypes';
import { CartAPIService } from '../requests/cart-api.service';
import { CustomerService } from './customer-handler.service';
import { ErrorHandlerService } from './error-handler.service';
import { SessionService } from './session.service';
export type CartGroupByFlight = PaymentCart[][];
@Injectable({
    providedIn: 'root',
})
export class CartService {
    private _subject: BehaviorSubject<CartSelectedPaymentMethod | null> =
        new BehaviorSubject<CartSelectedPaymentMethod | null>(null);
    private _update: Subject<any>;
    private _currentCart: BehaviorSubject<any> = new BehaviorSubject(null);
    private _3rdPartyCharges: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    private _guestCreditCard: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
    private _guestPersonalInformation: BehaviorSubject<PersonalInformation | null> =
        new BehaviorSubject<PersonalInformation | null>(null);
    private _guestAddressInformation: BehaviorSubject<AddressInformationFormType | null> =
        new BehaviorSubject<AddressInformationFormType | null>(null);
    // This is a BehaviorSubject to handle the cart _submitted information
    private _cartBill = new BehaviorSubject<CartBill | null>(null);
    private _cartBillV2 = new BehaviorSubject<Cart | null>(null);
    private submittedItems: PaymentCart[] = [];
    private _3rdPartyItems: any = [];
    private _submitted: BehaviorSubject<PaymentCart[]> = new BehaviorSubject<PaymentCart[]>([]);

    constructor(
        private _customerService: CustomerService,
        private _cartApiService: CartAPIService,
        private _sessionService: SessionService,
        private _errorHandlerService: ErrorHandlerService,
        private _dialog: MatDialog
    ) {
        this._update = new Subject<any>();
        const submitted: PaymentCart[] | null = this._sessionService.getElement('submittedItems');
        const charges: [] | null = this._sessionService.getElement('3rdPartyCharges');
        if (submitted) {
            this.submittedItems = submitted;
            this._submitted.next(submitted);
        }
        if (charges) {
            this._3rdPartyItems = charges;
            this._3rdPartyCharges.next(charges);
        }
    }

    getCart(): void {
        this._cartApiService
            .getCart()
            .pipe(take(1))
            .subscribe({
                next: (response) => {
                    if (response?.cart?.length) {
                        this.setCurrentCart(response.cart);
                    }
                },
            });
    }

    /**
     * @method showPayMethod()
     * @param (masked: any)
     * @description
     */

    showPayMethod(masked: any) {
        this._subject.next(masked);
    }

    /**
     * @method instant_payment_method()
     * @description
     */

    get instant_payment_method(): CartSelectedPaymentMethod | null {
        return this._subject.value;
    }

    /**
     * @method getPayMethod()
     * @description
     */

    getPayMethod(): Observable<any> {
        return this._customerService.getCustomer().isGuest
            ? this._guestCreditCard.asObservable().pipe(distinctUntilChanged())
            : this._subject.asObservable().pipe(distinctUntilChanged());
    }

    /**
     * @method setUpdate()
     * @param (action: any)
     * @description
     */

    setUpdate(action: any) {
        this._update.next(action);
    }

    /**
     * @method getUpdate()
     * @description
     */

    getUpdate() {
        return this._update.asObservable();
    }

    /**
     * @method setCurrentCart()
     * @param (cart: any)
     * @description
     */

    setCurrentCart(cart: any): void {
        this._currentCart.next(cart);
    }

    /**
     * @method getCurrentCart()
     * @description
     */

    getCurrentCart$() {
        return this._currentCart.asObservable();
    }

    /**
     * @method instant_payment_method()
     * @description
     */

    get instant_current_cart() {
        return this._currentCart.value;
    }

    /**
     * @method instant_guest_credit_card()
     * @description Return current guestCreditCard value
     */

    get instant_guest_credit_card(): any | null {
        return this._guestCreditCard.value;
    }

    /**
     * @method setGuestCreditCard()
     * @param (card: CreditCard | null)
     * @description Set guestCreditCard value
     */

    setGuestCreditCard(card: any | null): void {
        this._guestCreditCard.next(card);
    }

    /**
     * @method setGuestCustomerInformation()
     * @param (customerInformation: PersonalInformation | null)
     * @description Set guestCustomerInformation value
     */

    setGuestCustomerInformation(customerInformation: PersonalInformation | null): void {
        this._guestPersonalInformation.next(customerInformation);
    }

    /**
     * @method guestCustomerInformation()
     * @description Return guestCustomerInformation value
     */

    getGuestCustomerInformation(): Observable<PersonalInformation | null> {
        return this._guestPersonalInformation.asObservable();
    }

    /**
     * @method instant_guest_personal_information()
     * @description Return current guestPersonalInformation value
     */

    get instant_guest_personal_information(): PersonalInformation | null {
        return this._guestPersonalInformation.value;
    }

    /**
     * @method setGuestAddressInformation()
     * @param (customerInformation: PersonalInformation | null)
     * @description Set guestAddressInformation value
     */

    setGuestAddressInformation(addressInformation: AddressInformationFormType | null): void {
        this._guestAddressInformation.next(addressInformation);
    }

    /**
     * @method guestAddressInformation()
     * @description Return guestAddressInformation value
     */

    guestAddressInformation(): Observable<AddressInformationFormType | null> {
        return this._guestAddressInformation.asObservable();
    }

    /**
     * @method instant_guest_address_information()
     * @description Return current guestAddressInformation value
     */

    get instant_guest_address_information(): AddressInformationFormType | null {
        return this._guestAddressInformation.value;
    }

    /*
     * @method deleteCart()
     * @description Clear the cart so the user can add a new payment
     */
    deleteCart(): Observable<boolean> {
        return this._cartApiService.deleteCart().pipe(
            map(() => {
                this._sessionService.setPayCount(0);
                this._sessionService.setPaymentRequest(0);

                return true;
            })
        );
    }

    /*
     * @method clearGuestData()
     * @description Clear guest information
     */

    clearGuestData(): void {
        this.setGuestCreditCard(null);
        this.setGuestCustomerInformation(null);
        this.setGuestAddressInformation(null);
    }

    setCartBill(cart: CartBill | null): void {
        this._cartBill.next(cart);
    }

    getCartBill$(): Observable<CartBill | null> {
        return this._cartBill.asObservable();
    }

    setCartBillV2(cart: Cart | null): void {
        this._cartBillV2.next(cart);
    }

    getCartBillV2$(): Observable<Cart | null> {
        return this._cartBillV2.asObservable();
    }

    getCartFilterByAWB$(awb: string): Observable<PaymentCart[]> {
        return this.getCart$().pipe(
            map((cart) => cart?.filter((paymentRequest: PaymentCart) => paymentRequest.awb === awb))
        );
    }

    getCart$(): Observable<PaymentCart[]> {
        return this.getCartBill$().pipe(map((cartBill: CartBill | null) => cartBill?.cart || []));
    }

    getCartFilterByAWB(awb: string): PaymentCart[] {
        return this._cartBill.value?.cart?.filter((paymentRequest: PaymentCart) => paymentRequest.awb === awb) || [];
    }

    createFile(openInvoiceIds: string[]): Observable<string> {
        const activeProfileView = this._customerService.getActiveProfileView();
        return this._customerService.getEnabledPaymentMethodByName$(ComponentPaymentMethodName.BANK_DEBIT).pipe(
            switchMap((enabledPaymentMethod) => {
                const paymentMethod = enabledPaymentMethod?.paymentMethod.paymentToken.paymentMethod;
                const paymentProcessors =
                    enabledPaymentMethod?.paymentProcessors && enabledPaymentMethod?.paymentProcessors[0];

                if (!paymentMethod || !paymentProcessors) return 'Failed';

                return this._cartApiService
                    .payOpenInvoices(
                        { activeProfileView, openInvoiceIds },
                        { paymentMethod, paymentProcessor: paymentProcessors }
                    )
                    .pipe(
                        map(() => {
                            this._dialog.open(ConfirmationDialogComponent, {
                                data: {
                                    title: 'We are processing your request',
                                    description:
                                        'This typically takes less than 5 min and once completed, <br> you will see the file in the Debit history section ',
                                    confirmText: 'CONTINUE',
                                    icon: 'floating-folder',
                                },
                            });

                            return 'Complete';
                        })
                    );
            })
        );
    }

    resetSubmittedItems(): void {
        this.submittedItems = [];
        this.saveSubmittedItems([]);
    }

    setAndSaveSubmittedItems(submitted: PaymentCart): void {
        this.setSubmittedItems(submitted);
        this.saveSubmittedItems(this.submittedItems);
    }

    getSubmittedItems$(): Observable<PaymentCart[]> {
        return this._submitted.asObservable();
    }

    private setSubmittedItems(submitted: PaymentCart): void {
        this.submittedItems.push(submitted);
    }

    private saveSubmittedItems(submitted: PaymentCart[]): void {
        this._submitted.next(submitted);
        this._sessionService.saveElement('submittedItems', submitted);
    }

    reset3rdPartyItems(): void {
        this._3rdPartyItems = [];
        this.save3rdPartyItems([]);
    }

    setAndSave3rdPartyItems(charges: any): void {
        this.set3rdPartyItems(charges);
        this.save3rdPartyItems(this._3rdPartyItems);
    }

    get3rdPartyItems$(): Observable<any[]> {
        return this._3rdPartyCharges.asObservable();
    }

    has3rdPartyItems(): boolean {
        return !!this._3rdPartyCharges.value.length;
    }

    hasSubmittedItems(): boolean {
        return !!this._submitted.value.length;
    }

    get3rdPartyItemsByHBL(): [] {
        let chargesGrouped: any = [];
        if (this._3rdPartyCharges.value) {
            const charges = this._3rdPartyCharges.value;
            const groupedByHBL = charges.reduce((acc: any, item: any) => {
                acc[item['MBL'] + item['HBL']] = acc[item['MBL'] + item['HBL']] || [];
                acc[item['MBL'] + item['HBL']].hbl = item['HBL'];
                acc[item['MBL'] + item['HBL']].mbl = item['MBL'];
                acc[item['MBL'] + item['HBL']].push(item);
                return acc;
            }, []);
            chargesGrouped = Object.keys(groupedByHBL).map((key: any) => groupedByHBL[key]);
        }
        return chargesGrouped;
    }

    private set3rdPartyItems(charges: any): void {
        this._3rdPartyItems.push(charges);
    }

    private save3rdPartyItems(charges: any[]): void {
        this._3rdPartyCharges.next(charges);
        this._sessionService.saveElement('3rdPartyCharges', charges);
    }

    remove3rdPartyItems(amount: number, description: string, hbl: string): Observable<any> {
        return this.get3rdPartyItems$().pipe(
            map((charges) => {
                return charges.filter((item: any) => {
                    return (
                        item['HBL'] !== hbl ||
                        (item['Amount to pay'] !== amount && item['Description'] !== description) ||
                        (item['Amount to pay'] !== amount && item['Description'] === description)
                    );
                });
            }),
            map((newCharges: any) => {
                return newCharges;
            })
        );
    }

    get3rdPartyItems(): any {
        return this._3rdPartyItems;
    }

    getSubmittedItems(): PaymentCart[] {
        return this.submittedItems;
    }

    getChargeInPaymentCartByAmountAndType(amount: number, type: string, hawb: string): Observable<PaymentCart> {
        return this.getCurrentCart$().pipe(
            map((cart) => {
                return cart?.filter((paymentRequest: PaymentCart) => {
                    return (
                        paymentRequest.amount === amount &&
                        paymentRequest.paymentType === type &&
                        hawb === paymentRequest.hawb
                    );
                });
            }),
            map((paymentCart: PaymentCart[]) => {
                return paymentCart[0] ?? null;
            })
        );
    }

    getChargeSubmittedByAmountAndType(amount: number, type: string, hawb: string): Observable<PaymentCart> {
        return this.getSubmittedItems$().pipe(
            map((cart) => {
                return cart?.filter((submitted: PaymentCart) => {
                    return submitted.amount === amount && submitted.paymentType === type && hawb === submitted.hawb;
                });
            }),
            map((paymentCart: PaymentCart[]) => {
                return paymentCart[0] ?? null;
            })
        );
    }

    getCartV2$(): Observable<PaymentCart[]> {
        return this.getCartBillV2$().pipe(
            map((cart: Cart | null) => {
                if (cart) {
                    return cart?.cartBillResponseDTOList
                        .map((cartBillResponse: CartAWB) => {
                            return cartBillResponse.paymentRequest;
                        })
                        .flat();
                }
                return [];
            })
        );
    }
}
