<app-breadcrumb></app-breadcrumb>
<!--START: UPLOAD REQUEST-->
<section id="upload-request">
    <div id="upload-request__header" class="mb-3 pt-3">
        <!--START: HEADER AND ACTION BUTTONS-->
        <div class="row">
            <div class="col-12 col-md-6 d-flex align-items-center justify-content-start">
                <h6>Upload Request(s)</h6>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS-->
    </div>
    <div id="upload-request__container">
        <!--START: INSTRUCTIONS SECTION -->
        <div class="row">
            <div class="col-12">
                <div class="form-group payment-instructions">
                    <h5>Instructions:</h5>
                    <p>Please download the template below, complete the fields and upload the XLS file.</p>
                    <button
                        class="border-0 p-0 d-flex align-items-center fw-bold text-uppercase payment-instructions-download-csv"
                        (click)="downLoadExcel()"
                    >
                        <span class="material-symbols-outlined">download</span>
                        Download Template
                    </button>
                </div>
            </div>
        </div>
        <!--END: INSTRUCTIONS SECTION -->

        <!--START: UPLOAD REQUEST FORM-->
        <form [formGroup]="uploadForm" novalidate>
            <div class="row my-3 d-flex justify-content-center">
                <div class="col-12">
                    <div
                        id="dragDrop"
                        class="form-group formFile drop-zone-area"
                        (drop)="dropHandler($event)"
                        (dragover)="dragOverHandler($event)"
                    >
                        <div class="row">
                            <div class="col-12 d-block text-center">
                                <mat-icon class="material-symbols-outlined align-middle me-2">cloud_upload</mat-icon>
                                <p>Drag and drop files here</p>
                                <p>Or</p>
                            </div>
                            <div class="col-12 my-2 d-flex justify-content-center">
                                <div class="upload-button d-flex justify-content-center align-items-center">
                                    Browse File
                                    <input
                                        #fileInput
                                        type="file"
                                        (change)="handleFileInput($event.target)"
                                        multiple="multiple"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-error *ngIf="getNameFiles?.touched && getNameFiles?.errors">
                        <mat-error *ngIf="getNameFiles.hasError('required')">
                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Please attach the required document
                        </mat-error>
                    </mat-error>
                </div>
            </div>
            <div class="row">
                <div class="col-12 files my-2">
                    <div class="card p-1" [ngClass]="{'card' : nameFilesArr.length > 0}">
                        <ng-container *ngFor="let item of nameFilesArr; let i = index; trackBy: trackBy">
                            <div class="d-flex align-items-center 3 ps-2">
                                <span>{{ item }}</span>
                                <button matSuffix mat-icon-button (click)="removeFile(i)">
                                    <mat-icon class="material-symbols-outlined">cancel</mat-icon>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="col-12 files my-2">
                    <div>
                        <button
                            mat-button
                            type="submit"
                            [disabled]="uploadForm.invalid"
                            (click)="submitToApprover()"
                            class="text-uppercase btn__sprintpay btn__sprintpay--green mb-3 float-none float-md-end"
                        >
                            Submit to approver
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <!--START: UPLOAD REQUEST FORM-->
    </div>
</section>
<!--START: UPLOAD REQUEST-->
