import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-form-header',
    templateUrl: './form-header.component.html',
    standalone: true,
})
export class FormHeaderComponent {
    @Input() facilityLogo: string = './assets/images/sprintpay-horizontal-logo.svg';
    @Input() facility: string = 'sprintpay';
    @Input() title: string = 'Join SprintPay';
}
