<form [formGroup]="accountForm" (submit)="saveInformation()" class="row">
    <div class="col-12">
        <ng-container *ngIf="!emailHasDomain; else emailHasDomainTemplate">
            <ng-container>
                <div class="mb-4">
                    <h3 class="fs-6 lh-1 text-muted">New account type</h3>
                    <mat-radio-group formControlName="accountType">
                        <mat-radio-button
                            class="me-2 mb-2"
                            color="primary"
                            *ngFor="let accountType of accountTypes"
                            [value]="accountType.value"
                            (change)="setAccountType($event)"
                        >
                            {{accountType.name}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="selectedAccountType">
                    <h3 class="fs-6 lh-1 text-muted">Company information</h3>
                    <div class="form-group">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>Company name</mat-label>
                            <input
                                matInput
                                type="text"
                                id="companyName"
                                name="companyName"
                                data-cy="companyName"
                                formControlName="companyName"
                                placeholder="Enter the company name"
                                [errorStateMatcher]="matcher"
                            />
                            <mat-error *ngIf="companyNameControl?.hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                            </mat-error>
                            <mat-error *ngIf="companyNameControl?.hasError('duplicatedCompany')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Update your Company name and
                                resubmit
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>Tax ID (optional)</mat-label>
                            <input
                                matInput
                                type="text"
                                id="taxID"
                                name="taxID"
                                data-cy="taxID"
                                formControlName="taxID"
                                placeholder="Tax ID (optional)"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedAccountType !== null">
                <!--START: Address -->
                <section>
                    <span class="text-muted mb-4 d-block fw-bolder">Address information</span>
                    <app-address-information class="mt-4 d-block" (eventOnChangeAddress)="setAddress($event)">
                    </app-address-information>
                </section>
                <!--END: Address -->
                <!--START: checkBox-->
                <div class="align-items-baseline mb-4">
                    <mat-checkbox formControlName="billingAddress" class="me-3" [color]="'primary'">
                        Use as billing address
                    </mat-checkbox>
                </div>
                <!--END: checkBox-->
                <!--START: Billing address -->
                <section *ngIf="!addAddtionalAddress">
                    <span class="text-muted mb-4 d-block fw-bolder">Billing address</span>
                    <app-address-information class="mt-4 d-block" (eventOnChangeAddress)="setBillingAddress($event)">
                    </app-address-information>
                </section>
                <!--END: Billing address -->
            </ng-container>
        </ng-container>
        <ng-template #emailHasDomainTemplate>
            <section>
                <span class="text-muted mb-4 d-block fw-bolder">Company information</span>
                <!--START: Company name -->
                <div class="form-group">
                    <mat-form-field class="d-block" appearance="outline">
                        <mat-label>Company name</mat-label>
                        <input
                            matInput
                            type="text"
                            id="companyName"
                            name="companyName"
                            data-cy="companyName"
                            formControlName="companyName"
                            placeholder="Enter the company name"
                            readonly="true"
                            [errorStateMatcher]="matcher"
                        />
                        <mat-error *ngIf="companyNameControl?.hasError('restrictedBranch')">
                            <i class="bi bi-exclamation-triangle-fill me-2"></i>
                            Your Company requires additional authorization. Please reach out to your Company CargoSprint
                            representative or email us at
                            <a href="mailto:support@cargosprint.com">support&#64;cargosprint.com</a>
                        </mat-error>
                        <mat-error *ngIf="companyNameControl?.hasError('required')">
                            <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                        </mat-error>
                    </mat-form-field>
                </div>
                <!--END: Company name -->
                <ng-container *ngIf="brachLocations.length">
                    <div class="form-group">
                        <mat-form-field class="d-block mb-2" appearance="outline">
                            <mat-label>Branch location</mat-label>
                            <mat-select
                                formControlName="branchLocation"
                                id="brachLocation"
                                name="brachLocation"
                                [errorStateMatcher]="matcher"
                            >
                                <mat-option *ngFor="let item of brachLocations" [value]="item">
                                    {{item.accountName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="branchLocationControl?.hasError('restrictedBranch')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Your Company requires additional
                                authorization. Please reach out to your Company CargoSprint representative or email us
                                at <a href="mailto:support@cargosprint.com">support&#64;cargosprint.com</a>
                            </mat-error>
                            <mat-error *ngIf="branchLocationControl?.hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Branch location is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <p>
                        If you do not see your branch, please select the headquarter branch. If you would like to have a
                        branch setup, please reach out to support
                        <a href="mailto:support@cargosprint.com">support&#64;cargosprint.com</a>
                    </p>
                </ng-container>
            </section>
        </ng-template>
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <button
                    mat-button
                    matStepperPrevious
                    type="button"
                    class="d-block border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green mb-3 mb-md-0"
                >
                    Previous
                </button>
            </div>
            <div class="col-sm-12 col-md-6">
                <button
                    mat-flat-button
                    color="primary"
                    matStepperNext
                    type="submit"
                    class="d-block border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green"
                    data-cy="next_stepTwo"
                    (click)="next()"
                    [disabled]="accountForm.invalid"
                >
                    Next
                </button>
            </div>
        </div>
    </div>
</form>
