<!--START: DYNAMIC FIELD SWITCH CASE-->
<div [ngSwitch]="field.type">
    <app-input
        *ngSwitchCase="'text'"
        [id]="field.id"
        [field]="field"
        [dynamicFormGroup]="dynamicFormGroup.get(dynamicFormGroupName)"
    ></app-input>
    <app-input
        *ngSwitchCase="'password'"
        [field]="field"
        [dynamicFormGroup]="dynamicFormGroup.get(dynamicFormGroupName)"
    ></app-input>
    <app-input
        *ngSwitchCase="'email'"
        [field]="field"
        [dynamicFormGroup]="dynamicFormGroup.get(dynamicFormGroupName)"
    ></app-input>
    <app-input
        *ngSwitchCase="'tel'"
        [field]="field"
        [dynamicFormGroup]="dynamicFormGroup.get(dynamicFormGroupName)"
    ></app-input>
    <app-dropdown
        *ngSwitchCase="'dropdown'"
        [field]="field"
        [dynamicFormGroup]="dynamicFormGroup.get(dynamicFormGroupName)"
    ></app-dropdown>
    <app-checkbox
        *ngSwitchCase="'checkbox'"
        [field]="field"
        [dynamicFormGroup]="dynamicFormGroup.get(dynamicFormGroupName)"
    ></app-checkbox>
    <app-radio-button
        *ngSwitchCase="'radioButton'"
        [field]="field"
        [dynamicFormGroup]="dynamicFormGroup.get(dynamicFormGroupName)"
    ></app-radio-button>
    <app-textbox
        *ngSwitchCase="'textbox'"
        [field]="field"
        [dynamicFormGroup]="dynamicFormGroup.get(dynamicFormGroupName)"
    ></app-textbox>
    <app-file
        *ngSwitchCase="'file'"
        [id]="field.id"
        [field]="field"
        [dynamicFormGroup]="dynamicFormGroup.get(dynamicFormGroupName)"
    ></app-file>
</div>
<!--END: DYNAMIC FIELD SWITCH CASE-->
