<!--START: SPRINTPAY CREDIT CARD -->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4 offset-sm-0 offset-md-3 offset-lg-4">
            <div class="text-center">
                <img class="my-3" src="assets/images/sprintpay-horizontal-logo.svg" alt="SprintPay logo" />
                <div class="d-flex justify-content-center align-items-center mb-3 mt-2">
                    <div class="spinner-container">
                        <div class="spinner-background"><strong>{{credit.creditPercentage}}%</strong></div>
                        <mat-progress-spinner
                            #spinner
                            diameter="150"
                            strokeWidth="15"
                            mode="determinate"
                            [value]="credit.creditPercentage"
                        ></mat-progress-spinner>
                    </div>
                </div>
                <span
                    class="sprintPayCredit__title d-flex align-items-center justify align-middle justify-content-center"
                    ><strong>Credit available</strong></span
                >
                <h6 class="sprintPayCredit__available d-block my-2">{{credit.availableCredit | currency}}</h6>
                <span class="sprintPayCredit__limit d-block"
                    ><strong>Total credit: {{credit.creditLimit | currency}}</strong></span
                >
            </div>
        </div>
    </div>
</div>
<!--END: SPRINTPAY CREDIT CARD -->
