<!--START: PAYMENT SUMMARY -->
<div id="payment-summary" class="mt-4 mx-2">
    <div id="payment-summary--container">
        <div class="mt-4 d-block">
            <h6 class="step_section mb-4">Payment summary</h6>
            <!--PaymentDetails-->
            <div class="row p-1">
                <div class="col-12">
                    <app-payment-details
                        [paymentDetail]="payment"
                        [replacementFields]="dynamicFieldsReplaced"
                        [files]="files"
                        [nameFiles]="nameFiles"
                        [values]="paymentDetailsSection"
                    ></app-payment-details>
                    <app-charges-table (currentCharges)="currentCharges($event)"></app-charges-table>
                </div>
            </div>
            <!-- START: CUSTOMER REFERENCE -->
            <div class="row p-1">
                <div class="col-12">
                    <app-customer-reference
                        [values]="paymentCustomerReference"
                        [paymentDetail]="payment"
                    ></app-customer-reference>
                </div>
            </div>
            <!-- END: CUSTOMER REFERENCE -->
            <!--START: COMPANY CUSTOMER REFERENCE-->
            <div class="col-12 p-1">
                <!-- START: DETAIL CONTAINER -->
                <ng-container *ngIf="startingLevel">
                    <ng-container [ngSwitch]="companyName">
                        <!--START: TEMPLATE KN USERS -->
                        <app-kn-customer-reference
                            *ngSwitchCase="companyNames.kn"
                            [paymentDetail]="payment"
                        ></app-kn-customer-reference>
                        <!--END: TEMPLATE KN USERS -->
                        <!--START: TEMPLATE SCHENKER USERS -->
                        <app-schenker-customer-reference
                            *ngSwitchCase="companyNames.schenker"
                            [paymentDetail]="payment"
                        ></app-schenker-customer-reference>
                        <!--END: TEMPLATE SCHENKER USERS -->
                        <!--START: TEMPLATE DHL USERS -->
                        <app-dhl-customer-reference
                            *ngSwitchCase="companyNames.dhl"
                            [paymentDetail]="payment"
                        ></app-dhl-customer-reference>
                        <!--END: TEMPLATE KN USERS -->
                        <!--START: TEMPLATE GEODIS USERS -->
                        <app-geodis-customer-reference
                            *ngSwitchCase="companyNames.geodis"
                            [paymentDetail]="payment"
                        ></app-geodis-customer-reference>
                        <!--END: TEMPLATE GEODIS USERS -->
                        <!--START: TEMPLATE EMOTRANS USERS -->
                        <app-emotrans-customer-reference
                            *ngSwitchCase="companyNames.emotrans"
                            [paymentDetail]="payment"
                        ></app-emotrans-customer-reference>
                        <!--END: TEMPLATE EMOTRANS USERS -->
                        <!--START: TEMPLATE RHENUS USERS -->
                        <app-rhenus-customer-reference
                            *ngSwitchCase="companyNames.rhenus"
                            [paymentDetail]="payment"
                        ></app-rhenus-customer-reference>
                        <!--END: TEMPLATE RHENUS USERS -->
                        <!--START: TEMPLATE DYNAMIC COMPANY -->
                        <app-company-customer-reference
                            [paymentDetail]="payment"
                            *ngSwitchDefault
                        ></app-company-customer-reference>
                        <!--END: TEMPLATE DYNAMIC COMPANY -->
                    </ng-container>
                </ng-container>
                <!-- END: DETAIL CONTAINER -->
            </div>
            <!--END: COMPANY CUSTOMER REFERENCE-->
        </div>
    </div>
    <!--START: STEPPER NEXT AND BACK BUTTON -->
    <div class="stepper-button-group d-flex justify-content-between mb-3 mt-4">
        <div class="mb-3 mb-lg-0">
            <button
                mat-button
                matStepperPrevious
                type="button"
                (click)="backStep()"
                class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
            >
                Back
            </button>
        </div>
        <ng-container *ngIf="hasCurrentCharges">
            <div class="mb-3 mb-lg-0">
                <button
                    mat-button
                    type="button"
                    (click)="checkout()"
                    class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                >
                    Continue
                </button>
            </div>
        </ng-container>
    </div>
    <!--END: STEPPER NEXT AND BACK BUTTON -->
</div>
<!--END: PAYMENT SUMMARY -->
