import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CartService } from 'src/app/services/utils/cart.service';
import { CartSelectedPaymentMethod, PaymentMethodsCartBill } from 'src/app/utils/cartTypes';

@Component({
    selector: 'app-guest-payment-method',
    templateUrl: './guest-payment-method.component.html',
})
export class GuestPaymentMethodComponent implements OnInit {
    readonly paymentMethods = PaymentMethodsCartBill;
    creditCard: any | null;
    @Input() paymentMethod?: CartSelectedPaymentMethod;
    @Input() loadingPayPalRequest?: boolean;
    @Output() selectPaymentMethod: EventEmitter<CartSelectedPaymentMethod> = new EventEmitter();

    constructor(private _cartService: CartService) {
        this.creditCard = this._cartService.instant_guest_credit_card;
    }

    ngOnInit(): void {
        // return the last payment method used
        if (this.paymentMethod?.method === this.paymentMethods.PAYPAL) {
            this.selectPayPal();
        } else if (this.paymentMethod?.method === this.paymentMethods.CREDIT_CARD) {
            this.selectCreditCard();
        }
    }

    /**
     * @method selectPayPal()
     * @description Emit PayPal as selected paymentMethod
     */

    selectPayPal(): void {
        this.selectPaymentMethod.emit({ paymentAccount: true, method: PaymentMethodsCartBill.PAYPAL });
    }

    /**
     * @method selectCreditCard()
     * @description Emit CreditCard as selected paymentMethod
     */

    selectCreditCard(): void {
        this.selectPaymentMethod.emit({ paymentAccount: this.creditCard, method: PaymentMethodsCartBill.CREDIT_CARD });
    }
}
