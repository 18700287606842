import { Component, OnInit } from '@angular/core';
import { ForteException } from '@cargos/sprintpay-models';
import { ErrorForteHandlerService } from '@cargos/sprintpay-services';
import { deleteEcheck, getEChecks } from '@cargos/sprintpay_frontend_core_api/lib/payment-methods/e-checks/e-check';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-echeck',
    templateUrl: './echeck.component.html',
})
export class EcheckComponent implements OnInit {
    eChecks: any[];
    noEchecks: boolean | undefined;

    constructor(
        private _ngxSpinnerService: NgxSpinnerService,
        private _errorForteHandlerService: ErrorForteHandlerService
    ) {
        this.eChecks = [];
    }

    ngOnInit(): void {
        this._loadEChecks();
    }

    /**
     * @method _loadEChecks()
     * @description Return the user eChecks
     */

    private _loadEChecks(): void {
        this._ngxSpinnerService.show();
        getEChecks()
            .toPromise()
            .then((result: any) => {
                this.eChecks = result.map((item: any) => {
                    return {
                        accountHolder: item.accountingDetails.accountHolder || 'N/A',
                        accountLastFourDigits: item.accountingDetails.accountLastFourDigits || 'N/A',
                        accountType: item.accountingDetails.accountType || 'N/A',
                        routingNumber: item.accountingDetails.routingNumber || 'N/A',
                        fullECheck: item || 'N/A',
                    };
                });
            })
            .finally(() => {
                this._ngxSpinnerService.hide();
                this.eChecks.length >= 1 ? (this.noEchecks = false) : (this.noEchecks = true);
            });
    }

    /**
     * @method deleteECheck()
     * @param (eCheck: any)
     * @description Deletes the eChecks using the payment token provided on the getEChecks item
     */

    deleteECheck(eCheck: any): void {
        Swal.fire({
            text: 'You are about to delete an eCheck',
            title: 'Did you want to continue?',
            icon: 'info',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Continue',
            cancelButtonText: 'Cancel',
            allowOutsideClick: false,
        }).then((result): void => {
            if (result.isConfirmed) {
                deleteEcheck(eCheck)
                    .toPromise()
                    .then((): void => {
                        this._loadEChecks();
                    })
                    .catch((err: ForteException): void => {
                        const error = this._errorForteHandlerService.handlerError(err);
                        Swal.fire({
                            title: error.title,
                            text: error.description,
                            icon: 'error',
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: 'Cancel',
                            allowOutsideClick: false,
                        });
                    });
            }
        });
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }
}
