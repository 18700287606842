import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

export class ErrorMatcher implements ErrorStateMatcher {
    /**
     * @method isErrorState()
     * @param (control: FormControl | null)
     * @param (form: FormGroupDirective | NgForm | null)
     * @description Error when invalid control is dirty, touched, or submitted
     */
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}
