import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StepperSteps } from 'src/app/utils/stepperTypes';

@Injectable()
export class StepperService {
    [StepperSteps.STEP_ZERO]: BehaviorSubject<boolean>;
    [StepperSteps.STEP_FIRST]: BehaviorSubject<boolean>;
    [StepperSteps.STEP_SECOND]: BehaviorSubject<boolean>;

    constructor() {
        this[StepperSteps.STEP_ZERO] = new BehaviorSubject<boolean>(false);
        this[StepperSteps.STEP_FIRST] = new BehaviorSubject<boolean>(false);
        this[StepperSteps.STEP_SECOND] = new BehaviorSubject<boolean>(false);
    }

    /**
     * @method resetSteps()
     * @description Set stepper steps completion to false (block step selection)
     */

    resetSteps(): void {
        this[StepperSteps.STEP_ZERO].next(false);
        this[StepperSteps.STEP_FIRST].next(false);
        this[StepperSteps.STEP_SECOND].next(false);
    }
}
