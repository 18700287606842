<app-breadcrumb></app-breadcrumb>
<!--START: PAYMENT METHODS -->
<section id="paymentMethods">
    <!--START: PAYMENT METHODS TITLE -->
    <div id="paymentMethods__header" class="mb-3 pt-3">
        <div class="row">
            <div class="col-12 col-md-6 d-flex align-items-center justify-content-start">
                <h6>Payment methods</h6>
            </div>
        </div>
    </div>
    <!--END: PAYMENT METHODS TITLE -->
    <!--START: PAYMENT METHODS TABS -->
    <ng-container>
        <div id="paymentMethods__container" class="container-fluid py-4 px-5">
            <div class="row">
                <div class="col-12">
                    <mat-tab-group mat-stretch-tabs animationDuration="0ms" disableRipple>
                        <mat-tab *ngFor="let tab of tabs" [label]="tab.label">
                            <ng-container [ngSwitch]="tab.type">
                                <app-credit-card *ngSwitchCase="PaymentMethods.CREDIT_CARD"></app-credit-card>
                                <app-echeck *ngSwitchCase="PaymentMethods.ECHECK"></app-echeck>
                                <app-sprintpay-credit
                                    *ngSwitchCase="PaymentMethods.CARGOSPRINT_CREDIT"
                                ></app-sprintpay-credit>
                            </ng-container>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </ng-container>
    <!--END: PAYMENT METHODS TABS -->
</section>
<!--END: PAYMENT METHODS -->
