import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ThemeType } from 'src/app/models/ui/themes';
import { ThemeService } from 'src/app/services/utils/theme.service';

@Component({
    standalone: true,
    selector: 'app-logo-by-brand',
    templateUrl: './logo-by-brand.component.html',
    imports: [CommonModule],
})
export class LogoByBrandComponent implements OnInit {
    themeType: ThemeType;
    logo: string;

    constructor(private _themeService: ThemeService) {
        this.themeType = this._themeService.instant_theme;
        this.logo = '';
    }

    ngOnInit(): void {
        this.themeType = this._themeService.instant_theme;
        this.logo = `sidenav-logo-${this.themeType}`;
    }
}
