<!-- <form [formGroup]="securityForm" (submit)="saveInformation()" class="row"> -->
<div class="col-12">
    <span class="text-muted mb-4 d-block fw-bolder">Security</span>
    <!--START: Password -->
    <app-form-password [resetValue]="!notCompleted" (eventOnPassword)="onSetPassword($event)"></app-form-password>
    <!--END: Password -->
    <!--START: Two factor authentication-->
    <span class="subTitle d-block"> TWO FACTOR AUTHENTICATION </span>
    <app-verification-method
        [email]="email"
        [message]="messageAuthentication"
        [resetValue]="!notCompleted"
        [module]="ModuleMFAEnum.EMAIL_VALIDATION"
        (eventOnVerification)="onSetVerificationMethod($event)"
    ></app-verification-method>
    <!--END: Two factor authentication-->
    <!--START: Terms and privacy -->
    <div class="form-group">
        <app-term-of-use [resetValue]="!notCompleted" (eventOnTermsOfUse)="onSetTermsOfUse($event)"></app-term-of-use>
    </div>
    <!--END: Terms and privacy -->
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <button
                [disabled]="!notCompleted"
                mat-button
                matStepperPrevious
                type="button"
                class="d-block border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green mb-3 mb-md-0"
            >
                Previous
            </button>
        </div>
        <div class="col-sm-12 col-md-6">
            <button
                mat-flat-button
                color="primary"
                type="submit"
                class="d-block border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green"
                [disabled]="!termsOfUse || this.verificationMethod.type === '' || !password"
                (click)="saveInformation()"
            >
                Next
            </button>
        </div>
    </div>
</div>
<!-- </form> -->
