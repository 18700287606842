import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxMaskModule } from 'ngx-mask';
import { Subject, take, takeUntil } from 'rxjs';
import { ErrorMatcher } from 'src/app/utils/errorMatcher';
import { CustomerService } from '../../../../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { SessionService } from '../../../../../../../services/utils/session.service';
import { WarningService } from '../../../../../../../services/warning.service';
import { PaymentDetailService } from '../../payment-detail.service';
import { SummaryDetailService } from '../../summary-detail.service';

export interface Warning {
    label: string;
    required: boolean;
}

@Component({
    selector: 'app-amount-to-pay',
    standalone: true,
    imports: [CommonModule, MatInputModule, FormsModule, MatFormFieldModule, ReactiveFormsModule, NgxMaskModule],
    templateUrl: './amount-to-pay.component.html',
})
export class AmountToPayComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    paymentForm!: FormGroup;
    minAmount: number;
    hasRestrictions: boolean;
    amountRestriction: string;
    matcher: ErrorMatcher = new ErrorMatcher();
    private _prevPaymentType: string;
    private _customer: any;
    private _currentPayment: any;
    private _unsubscribe$: Subject<void>;
    private readonly _facilityData: any;
    private readonly _startingLevel: boolean;
    private readonly _companyName: any;
    private readonly _uniqueRequestor: boolean;
    private _paymentType: string | null = '';

    @Input() formValidState: any;

    @Output() returnAmountToPay: EventEmitter<string | null> = new EventEmitter<string | null>();

    /**
     * @method amount()
     * @description: Convenience getter for easy access to form fields
     */

    get amount(): AbstractControl<string | null> | null {
        return this.paymentForm.get('amount');
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _paymentFluxService: PaymentFluxService,
        private _customerService: CustomerService,
        private _sessionService: SessionService,
        private _warningService: WarningService,
        private _paymentDetailService: PaymentDetailService,
        private _summaryDetailService: SummaryDetailService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this.minAmount = 1.01;
        this.amountRestriction = '';
        this.hasRestrictions = false;
        this._customer = this._customerService.getCustomer();
        this._uniqueRequestor = !!this._customerService.getCustomer().userType.includes('UNIQUE_REQUESTOR');
        this._startingLevel = !!this._sessionService.getElement('startingLevel');
        this._companyName = this._customer?.approvalLevels?.company?.name || '';
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this._facilityData = this._currentPayment && this._currentPayment.facility ? this._currentPayment.facility : '';
        this._prevPaymentType = '';
    }

    ngOnInit(): void {
        this._setFromBuilder();
        this._currentPayment = this._paymentFluxService.getCurrentPayment();

        if (this._currentPayment?.details?.amount) {
            const defaultAmount = this.convertAmount(this._currentPayment.details.amount);
            this.amount?.setValue(defaultAmount);
            this.hasAmountRestrictions();
            this.returnAmount();
        } else {
            if (this._facilityData?.handlingFee) {
                const handlingFee = this.convertAmount(this._facilityData?.handlingFee);
                this._facilityData?.handlingFee ? this.amount?.setValue(handlingFee) : '';
                this.hasAmountRestrictions();
                this.returnAmount();
            }
        }
        this.watchPaymentType();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    ngAfterViewInit(): void {
        if (this._currentPayment && this._currentPayment.details && this._currentPayment.details.amount) {
            this.hasAmountRestrictions();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formValidState'] !== undefined) {
            if (changes['formValidState'].currentValue !== undefined) {
                if (changes['formValidState'].currentValue !== changes['formValidState'].previousValue) {
                    if (this.paymentForm !== undefined && !this.formValidState) {
                        this.amount?.markAsTouched();
                    }
                }
            }
        }
    }

    watchPaymentType(): void {
        this._summaryDetailService
            .getPaymentType()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe((paymentType: string | null) => {
                this._paymentType = paymentType;

                if (this.paymentForm && this._prevPaymentType !== paymentType) {
                    this.hasAmountRestrictions();
                }
            });
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.paymentForm = this._formBuilder.group({
            amount: new FormControl<string | null>(null),
        });
        this.paymentForm = this._paymentDetailService.validateForm(this.paymentForm);
    }

    /**
     * @method amountLimit()
     * @description
     */

    amountLimit(): boolean {
        let amountForm: number | null = this.paymentForm.value.amount ? this._formatAmount(this.paymentForm) : null;
        if ((this.paymentForm.dirty && amountForm && amountForm < this.minAmount) || amountForm === 0) {
            let amountWarning: any = {
                label:
                    this.minAmount === 1.01
                        ? 'Value of payment must be above $1'
                        : 'Value of payment must be above or equal to $' + this.minAmount,
                required: true,
            };
            this._warningService.selectAlerts(amountWarning);
            return true;
        }
        this._advertisement();
        const customer = this._customerService.getCustomer();
        const customerProfile = this._customerService.getCustomerProfile();
        if (customer && customerProfile?.customerProfileRestriction) {
            if (
                amountForm &&
                customerProfile.customerProfileRestriction.creditCardLimit &&
                amountForm > customerProfile.customerProfileRestriction.creditCardLimit
            ) {
                let amountWarning: any = {
                    label: customerProfile.customerProfileRestriction.name,
                    required: true,
                };
                this._warningService.selectAlerts(amountWarning);
                return true;
            }
        }
        if (this._startingLevel) {
            return this._amountRestrictionsGHA(amountForm);
        }
        return false;
    }

    /**
     * @method _formatAmount()
     * @param (form: any)
     * @description
     */

    private _formatAmount(form: any): number {
        const formAmount = form.value.amount ? form.value.amount.toString() : '';
        let amount = formAmount;
        if (formAmount && formAmount.charAt(0) == '$') {
            amount = formAmount.substring(1);
        }
        return parseFloat(amount.replace(/,/g, ''));
    }

    /**
     * @method _advertisement()
     * @description
     */

    private _advertisement(): boolean {
        const upkeepFee: number = 10.5;
        this._prevPaymentType = this._paymentType || '';
        if (
            this._currentPayment &&
            this._currentPayment.facility &&
            this._currentPayment.facility.handlingFee &&
            this._currentPayment.facility.isWFS &&
            this._paymentType?.includes('ISC')
        ) {
            const amount: number = this._formatAmount(this.paymentForm);
            if (this._currentPayment.facility.handlingFee - amount === upkeepFee) {
                const wfsWarning: Warning = {
                    label: `Hey! It appears you forgot this facilities $10.50 'upkeep fee'.WFS (not us) charges a $10.50 upkeep fee on all payments (per hawb).`,
                    required: false,
                };
                this._warningService.selectAlerts(wfsWarning);
                return true;
            }
        }

        return false;
    }

    /**
     * @method _amountRestrictionsGHA()
     * @param (amountForm: any)
     * @description
     */

    private _amountRestrictionsGHA(amountForm: any): boolean {
        const companyReferenceValues: any =
            this._currentPayment[`${encodeURIComponent(this._companyName.toLowerCase())}CustomerReference`];
        const approvalLevels: any = this._customer.approvalLevels
            ? this._customer.approvalLevels.company.paymentReference.filter(
                  (item: any): boolean => item.name === 'Type'
              )
            : '';
        if (this._companyName && companyReferenceValues.paymentAmount) {
            let paymentAmount = approvalLevels[0].paymentReferenceLookups.filter(
                (item: any): boolean => item.type === companyReferenceValues.paymentAmount
            );
            let criteriaToValidate: any = paymentAmount[0].criteria.split(',');
            const min = criteriaToValidate[0];
            const max = criteriaToValidate[1];
            if (amountForm < min || amountForm > max) {
                this._warningService.selectAlerts({
                    label: 'Invalid amount. Please make sure to select ' + paymentAmount[0].type,
                    required: true,
                });
                return true;
            }
            return false;
        }
        return false;
    }

    /**
     * @method hasInvoiceCheckLimit()
     * @description
     */

    hasInvoiceCheckLimit(): boolean {
        let amountForm: number = this.paymentForm.value.amount ? this._formatAmount(this.paymentForm) : 0;
        if (
            this._customer.invoiceCheckLimit &&
            this._customer.invoiceCheckLimit < amountForm &&
            !this._uniqueRequestor
        ) {
            this._warningService.selectAlerts({
                label:
                    `Your requested $` +
                    amountForm +
                    ` amount is higher than your current allowed transaction limit. Please contact collections@cargosprint.com`,
                required: true,
            });
            return true;
        }
        return false;
    }

    /**
     * @method hasAmountRestrictions()
     * @description
     */
    hasAmountRestrictions(): void {
        this.hasRestrictions = false;
        if (this.hasInvoiceCheckLimit() || this._advertisement() || this.amountLimit()) {
            this.amount?.setErrors({ pattern: true });
            this._warningService.getAlertsSelected$.pipe(take(1)).subscribe((alert) => {
                let alertObject: Warning | undefined = this._warningService.getAlerts();
                this.amountRestriction = alertObject ? alertObject.label : '';
                this.hasRestrictions = true;
                return alert;
            });
        } else {
            this.amount?.setErrors(null);
            this._warningService.updateTooltipAlerts();
            this.amountRestriction = '';
            this.hasRestrictions = false;
        }
        this.returnAmount();
    }

    /**
     * @method returnAmount()
     * @description
     */

    returnAmount(): void {
        this.returnAmountToPay.emit(this.paymentForm.valid && !this.hasRestrictions ? this.amount?.value : null);
    }

    convertAmount(value: number | string): string {
        const fixedValue = Number(value).toFixed(2);

        return fixedValue;
    }
}
