<!--START: CREDIT CARDS LIST -->
<mat-list>
    <!--START: CREDIT CARDS TITLE & CREATE BUTTON -->
    <div mat-subheader>
        <div class="d-flex w-100 mat-subheader__container d-flex justify-content-between align-items-center">
            <span class="d-flex align-items-center justify-content-start mat-subheader__title mt-3">Credit card</span>
            <div class="d-flex align-items-center justify-content-end mat-subheader__button">
                <button
                    mat-flat-button
                    color="primary"
                    type="button"
                    class="btn__sprintpay btn__sprintpay--green p-0 mt-0 border-0 rounded-0 text-uppercase text-center"
                    (click)="openWarningPopup()"
                >
                    New credit card
                </button>
            </div>
        </div>
    </div>
    <!--END: CREDIT CARDS TITLE & CREATE BUTTON -->
    <!--START: LOADER -->
    <div *ngIf="!noCreditCards && (!creditCards || (creditCards && creditCards.length < 1))">
        <div class="d-flex justify-content-center">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <!--END: LOADER -->
    <!--START: CREDIT CARD LIST ITEM -->
    <mat-list-item class="my-3" *ngFor="let card of creditCards; trackBy: trackBy">
        <div class="d-flex justify-content-between flex-row align-items-center w-100">
            <!--START: CREDIT CARD CARD -->
            <div class="d-flex justify-content-start flex-row align-items-center">
                <img
                    *ngIf="card.cardType === 'VISA'"
                    src="assets/images/credit-card-brand/visa.svg"
                    alt="Visa logo"
                    class="me-3"
                />
                <img
                    *ngIf="card.cardType === 'MASTERCARD'"
                    src="assets/images/credit-card-brand/mastercard.svg"
                    alt="Mastercard logo"
                    class="me-3"
                />
                <img
                    *ngIf="card.cardType === 'DISCOVER'"
                    src="assets/images/credit-card-brand/discover.svg"
                    alt="Discover logo"
                    class="me-3"
                />
                <img
                    *ngIf="card.cardType === 'AMERICAN EXPRESS'"
                    src="assets/images/credit-card-brand/amex.svg"
                    alt="Discover logo"
                    class="me-3"
                />
                <img
                    *ngIf="card.cardType === 'JCB'"
                    src="assets/images/credit-card-brand/types/jcb.svg"
                    alt="JCB logo"
                    class="me-3"
                />
                <img
                    *ngIf="card.cardType === 'UNIONPAY'"
                    src="assets/images/credit-card-brand/types/unionpay.svg"
                    alt="UNIONPAY logo"
                    class="me-3"
                />
                <div class="payment__ending" mat-line>
                    <span class="d-block"><strong>{{card.cardType}}:</strong> {{card.lastFourDigits}}</span>
                    <span class="d-block"
                        ><strong>Exp. date:</strong> {{card.expirationMonth}}/{{card.expirationYear}}</span
                    >
                    <ng-container *ngIf="card.expirationYear <= currentYear">
                        <ng-container *ngIf="card.expirationMonth < currentMonth || card.expirationYear < currentYear">
                            <span class="fw-bold payment__ending--expired">Expired</span>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <!--END: CREDIT CARD CARD -->
            <!--START: CREDIT CARD DELETE BUTTON -->
            <div class="d-flex justify-content-end">
                <div mat-line>
                    <button
                        mat-button
                        color="warn"
                        type="button"
                        class="mat-focus-indicator btn__sprintpay btn__sprintpay--linkDelete text-uppercase p-0 mt-0 border-0 rounded-0"
                        (click)="deleteCard(card.paymentToken)"
                    >
                        Delete
                    </button>
                </div>
            </div>
            <!--END: CREDIT CARD DELETE BUTTON -->
        </div>
    </mat-list-item>
    <!--END: CREDIT CARD LIST ITEM -->
</mat-list>
<!--END: CREDIT CARDS LIST -->
<!--START: NO CREDIT CARD -->
<ng-container *ngIf="noCreditCards">
    <section class="container-fluid">
        <div class="d-flex flex-column h-100 justify-content-center align-items-center mt-5">
            <div class="row">
                <div class="col-12">
                    <img
                        class="mx-auto d-block noPaymentMethod"
                        src="assets/images/home/frequent-facilities.svg"
                        alt="New user illustration"
                    />
                </div>
                <div class="col-12">
                    <h6 class="d-block text-center mt-5 mb-3">
                        Don’t fret! Click “NEW CREDIT CARD” to store your first payment method
                    </h6>
                </div>
            </div>
        </div>
    </section>
</ng-container>
<!--END: NO CREDIT CARD -->
