import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// i18 NGX TRANSLATE
import { NgxMaskModule } from 'ngx-mask';
import { AppHeaderComponent } from 'src/app/standalone-components/app-header/app-header.component';
import { AppMainComponent } from 'src/app/standalone-components/app-main/app-main.component';
import { BannerComponent } from 'src/app/standalone-components/banner/banner.component';
// DIRECTIVES
import { CatchEventsModule } from '../../directives/catch-events.module';
import { SharedModule } from '../../shared/shared.module';
// STANDALONE COMPONENTS
import { SidenavComponent } from '../../standalone-components/sidenav/sidenav.component';
// ROUTING
import { AdminRoutingModule } from './admin-routing.module';
//COMPONENTS
import { AdminComponent } from './admin.component';
import { CartModule } from './cart/cart.module';
import { FacilityContactsModule } from './facility-contacts/facility-contacts.module';
import { FacilityPaymentsModule } from './facility-payments/facility-payments.module';
//MODULE
import { CellEventHandlerService } from 'src/app/services/utils/cell-event-handler.service';
import { PortalSelectorComponent } from 'src/app/standalone-components/portal-selector/portal-selector.component';
import { HomeModule } from './home/home.module';
import { InvoicesModule } from './invoices/invoices.module';
import { SettingsModule } from './settings/settings.module';
import { UsersModule } from './users/users.module';

@NgModule({
    declarations: [AdminComponent],
    imports: [
        CatchEventsModule,
        CommonModule,
        AdminRoutingModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        SharedModule,
        FormsModule,
        HomeModule,
        FacilityPaymentsModule,
        InvoicesModule,
        UsersModule,
        CartModule,
        FacilityContactsModule,
        SettingsModule,
        NgxMaskModule.forChild(),
        SidenavComponent,
        BannerComponent,
        PortalSelectorComponent,
        AppHeaderComponent,
        AppMainComponent,
    ],
    providers: [CellEventHandlerService],
})
export class AdminModule {
    constructor() {}
}
