import { Component, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-terms-of-use-link',
    templateUrl: './terms-of-use-link.component.html',
})
export class TermsOfUseLinkComponent {
    readonly tosUrl: string | null;

    constructor(private _domSanitizer: DomSanitizer) {
        this.tosUrl = this._domSanitizer.sanitize(
            SecurityContext.RESOURCE_URL,
            this._domSanitizer.bypassSecurityTrustResourceUrl('https://cargosprint.com/legal/terms-of-use/sprintpay')
        );
    }
}
