import { ComponentType } from '@angular/cdk/overlay';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, NgZone, OnDestroy, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginAsCargoSprintUserResponse } from '@cargos/sprintpay-services/lib/apis/sprintpay/auth/models/auth-types';
import { NgxSpinnerService } from 'ngx-spinner';
import {
    Observable,
    Subject,
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    of,
    switchMap,
    take,
    takeUntil,
} from 'rxjs';
import { LoginForm } from 'src/app/models/auth/signup.model';
import { GuestService } from 'src/app/services/guest.service';
import { AuthService } from 'src/app/services/utils/auth.service';
import { CartService } from 'src/app/services/utils/cart.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { StorageService } from 'src/app/services/utils/storage.service';
import { TokenService } from 'src/app/services/utils/token.service';
import { UserSessionService } from 'src/app/services/utils/user-session.service';
import { profileTypes, regex, sprintPaySource } from 'src/app/utils/constants';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { HelpContactusService } from '../../../services/help-contactus.service';
import { ErrorHandlerService } from '../../../services/utils/error-handler.service';
import { ErrorMatcher } from '../../../utils/errorMatcher';
import { LoginService } from '../services/login.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
    loginForm!: FormGroup;
    passwordHintHide: boolean;
    matcher: ErrorMatcher;
    private _unsubscribe$: Subject<void>;
    public validatingEmail: boolean = false;
    public isSSOEmail = false;
    public redirectURL = '';

    @ViewChild('authenticationFlux') authenticationFlux?: ComponentType<unknown>;

    constructor(
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _authService: AuthService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _errorHandlerService: ErrorHandlerService,
        private _tokenService: TokenService,
        public helpContactusService: HelpContactusService,
        private _loginService: LoginService,
        private _activatedRoute: ActivatedRoute,
        private _matDialog: MatDialog,
        private _cartService: CartService,
        private _guestService: GuestService,
        private _domSanitizer: DomSanitizer,
        private _userSessionService: UserSessionService,
        private _storageService: StorageService,
        private _customerService: CustomerService,
        private _ngZone: NgZone
    ) {
        this.passwordHintHide = true;
        this.matcher = new ErrorMatcher();
        this._storageService.cleanStorage();
        this._unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this._setFromBuilder();
        this.subscribeParams();
        // Enable this line once BE is ready
        // this.onEmailChange();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.loginForm = this._formBuilder.group(
            {
                email: new FormControl<any | null>(null, [
                    Validators.required,
                    Validators.minLength(5),
                    Validators.pattern(regex.email),
                ]),
                password: new FormControl<any | null>(null, [Validators.required]),
            },
            { updateOn: 'change' }
        );
    }

    /**
     * @method email()
     * @description: Convenience getter for easy access to form fields
     */

    get getEmail(): FormGroup {
        return this.loginForm.get('email') as FormGroup;
    }

    /**
     * @method password()
     * @description: Convenience getter for easy access to form fields
     */

    get getPassword(): FormGroup {
        return this.loginForm.get('password') as FormGroup;
    }

    /**
     * @method setEmail()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setEmail(event: any) {
        this.getEmail!.setValue(event);
    }

    /**
     * @method setPassword()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setPassword(event: any) {
        this.getPassword!.setValue(event);
    }

    onEmailChange(): void {
        this.getEmail.valueChanges
            .pipe(
                filter(() => this.getEmail.status === 'VALID' && this.getEmail.value),
                debounceTime(500),
                distinctUntilChanged(),
                switchMap((value) => {
                    this.validatingEmail = true;
                    return this._authService.validateEmailSSO(value).pipe(
                        take(1),
                        finalize(() => (this.validatingEmail = false)),
                        catchError(() => of({ redirectUrl: '' }))
                    );
                }),
                takeUntil(this._unsubscribe$)
            )
            .subscribe((emailValidation) => {
                this.isSSOEmail = !!emailValidation.redirectUrl;
                this.redirectURL = emailValidation.redirectUrl;
            });
    }

    resetEmailSSOStatus(): void {
        this.isSSOEmail = false;
        this.redirectURL = '';
    }

    /**
     * @method onSubmit()
     * @description Submission action
     */

    onSubmit(): void {
        if (this.loginForm.invalid) {
            return;
        }

        this._ngxSpinnerService.show();

        const user: LoginForm = {
            username: this.getEmail.value,
            password: this.getPassword.value,
        };

        this._authService
            .signIn(user)
            .pipe(
                take(1),
                switchMap((result: LoginAsCargoSprintUserResponse) => {
                    return this.isNeededToRedirect(result).pipe(
                        switchMap((response: LoginAsCargoSprintUserResponse) => {
                            if (response.redirectUrl) {
                                return of(response);
                            }

                            return this._loginService
                                .initLogin(result.authorizationToken || '')
                                .pipe(map(() => response));
                        })
                    );
                }),
                finalize(() => {
                    this._tokenService.setAuthorizationTokenId(null);
                    this._ngxSpinnerService.hide();
                })
            )
            .subscribe({
                next: (response) => {
                    const { authorizationToken, refreshToken, redirectUrl } = response;

                    if (redirectUrl) {
                        const url: string | null = this._domSanitizer.sanitize(
                            SecurityContext.RESOURCE_URL,
                            this._domSanitizer.bypassSecurityTrustResourceUrl(
                                `${redirectUrl}?source=${sprintPaySource}` || ''
                            )
                        );

                        window.open(url || '');
                    } else if (authorizationToken && refreshToken) {
                        const spCustomer = JSON.parse(
                            JSON.stringify(this._tokenService.getDecodedAccessToken(authorizationToken))
                        )?.SPRINT_PAY_CUSTOMER;
                        const customer = JSON.parse(spCustomer)?.customer;
                        const customerProfile =
                            customer?.profileType && customer.profileType !== profileTypes.FORWARDER_FACILITY
                                ? customer.profileType
                                : profileTypes.FORWARDER;
                        this._tokenService.setProfileType(customerProfile);
                        this._customerService.setActiveProfileView(customerProfile);
                        this._authService.initConfigTokens({ authorizationToken, refreshToken });
                        this._userSessionService.postTokens({ authorizationToken, refreshToken });
                        const url = this._loginService.getRedirectLink();
                        this._ngZone.run(() => this._router.navigate([url]));
                    }
                },
                error: (error: HttpErrorResponse) => {
                    const errorData = error.error?.error?.data;
                    const isCredentialsInvalid =
                        error.error?.error?.title &&
                        error.error.error.title.toString().toLowerCase().includes('invalid credentials');

                    if (errorData && 'isGuest' in errorData && 'isActive' in errorData && !isCredentialsInvalid) {
                        const { isGuest, isActive, dynamicParameter } = errorData;
                        const email = user.username;

                        if (!isGuest && !isActive && dynamicParameter.activationUuid) {
                            this._handleInactiveAccount(email, dynamicParameter.activationUuid);
                        } else if (!isGuest && isActive) {
                            this._showErrorPopup(error);
                        } else if (isGuest && !isActive) {
                            this._cartService.setGuestCustomerInformation({ email });
                            this._router.navigate(['signup-guest']);
                        }
                    } else {
                        this._showErrorPopup(error);
                    }
                },
            });
    }

    // Enable the commented code once BE is ready
    isNeededToRedirect(
        result: LoginAsCargoSprintUserResponse
        // username: string
    ): Observable<LoginAsCargoSprintUserResponse> {
        if (result.redirectUrl) {
            return of({
                ...result,
                redirectUrl: result.redirectUrl,
            });
            // return this._authService.validateEmailSSO(username).pipe(
            //     map((validationResponse) => ({
            //         ...result,
            //         redirectUrl: validationResponse.redirectUrl,
            //     }))
            // );
        }

        return of(result);
    }

    /**
     * @method signInAsGuest()
     * @description Sign in as guest action
     */

    signInAsGuest(): void {
        this._guestService.showPaymentLimitPopup().then((result) => {
            if (result.isConfirmed) {
                this._ngxSpinnerService.show();
                this._userSessionService
                    .signInAsGuest()
                    .pipe(
                        take(1),
                        finalize(() => {
                            this._ngxSpinnerService.hide();
                        })
                    )
                    .subscribe({
                        next: (token) => {
                            if (token) {
                                const url = 'admin/facilityPayments/newPayment';
                                this._router.navigate([url]);
                            }
                        },
                        error: (error: HttpErrorResponse) => {
                            Swal.fire({
                                html: `${this._errorHandlerService.errorTemplate(error.error)}`,
                                icon: 'error',
                                showConfirmButton: false,
                                showCancelButton: true,
                                cancelButtonText: 'Close',
                                allowOutsideClick: false,
                            });
                        },
                    });
            }
        });
    }

    subscribeParams(): void {
        this.getParams()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe((params) => {
                if (params.type === 'guest') {
                    this.signInAsGuest();
                }
            });
    }

    getParams(): Observable<{
        type: string;
    }> {
        return this._activatedRoute.queryParams.pipe(
            map((params) => ({
                type: params['type'],
            }))
        );
    }

    private _handleInactiveAccount(email: string, activationUuid: string): void {
        this._userSessionService
            .signInAsGuest()
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this._matDialog.open(this.authenticationFlux as ComponentType<unknown>, {
                        disableClose: true,
                        width: '55em',
                        data: {
                            email,
                            activationUuid,
                            accountVerifiedHandler: () => this._userSessionService.logout(),
                        },
                    });
                },
            });
    }

    private _showErrorPopup(error: unknown): void {
        const swalOptions: SweetAlertOptions = {
            icon: 'error',
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Close',
            allowOutsideClick: false,
        };

        if (error instanceof HttpErrorResponse && error?.error?.error?.title) {
            swalOptions.title = 'There was an error with your information, please see below.';
            swalOptions.text = error?.error?.error?.body || 'Invalid username or password';
        } else if (error instanceof HttpErrorResponse) {
            swalOptions.html = `${this._errorHandlerService.errorTemplate(error.error)}`;
        }

        Swal.fire(swalOptions);
    }
}
