import { Injectable } from '@angular/core';
import { PaymentCart } from '@cargos/sprintpay-models';
import { CartService } from '@cargos/sprintpay-services';
import { Observable, map, of, switchMap, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApprovalFlowLevelsService } from './approval-flow-levels.service';
import { CartService as CartBillService } from './utils/cart.service';
import { SessionService } from './utils/session.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentRequestService {
    private readonly _cartUrl: string = environment.uris.method.cart;
    constructor(
        private _cartService: CartService,
        private _sessionService: SessionService,
        private _cartBillService: CartBillService,
        private _approvalFlowService: ApprovalFlowLevelsService
    ) {}

    /**
     * @method startApprovalFlow()
     * @param (paymentRequest?: PaymentCart)
     * @param (locationId?: number)
     * @description
     */
    startApprovalFlow(paymentRequest: PaymentCart, locationId: number): Observable<PaymentCart> {
        const requestAddedToCart = this._approvalFlowService.isChargeToPayAvailable(paymentRequest.amount || 0);
        return this._cartService.startApprovalflow(paymentRequest, locationId).pipe(
            take(1),
            switchMap((paymentCart: PaymentCart) => {
                if (requestAddedToCart) {
                    const paymentsCount = Number(this._sessionService.instant_payCount) || 0;
                    const paymentRequest = this._sessionService.getElement('paymentRequest') || 0;
                    const newCart = [...this._cartBillService.instant_current_cart, paymentCart];
                    this._cartBillService.setCurrentCart(newCart);
                    this._sessionService.setPayCount(paymentsCount + 1);
                    this._sessionService.setPaymentRequest(paymentRequest + 1);
                } else {
                    this._cartBillService.setAndSaveSubmittedItems(paymentCart);
                }
                return of(paymentCart);
            }),
            map((response) => {
                return response;
            })
        );
    }

    /**
     * @method addPamentRequest()
     * @param (paymentRequest?: PaymentCart)
     * @description
     */
    addPaymentRequest(paymentRequest: PaymentCart): Observable<PaymentCart[]> {
        return this._cartService.addPaymentRequest(paymentRequest).pipe(
            take(1),
            map((cart) => {
                this._cartBillService.setCurrentCart(cart);
                this._sessionService.setPayCount(cart.length);
                this._sessionService.setPaymentRequest(cart.length);
                return cart;
            })
        );
    }

    /**
     * @method removePamentRequest()
     * @param (paymentRequest?: PaymentCart)
     * @description
     */
    removePamentRequest(paymentRequestId: number): Observable<PaymentCart[]> {
        return this._cartService.removePaymentRequest(paymentRequestId).pipe(
            take(1),
            map((cart) => {
                this._cartBillService.setCurrentCart(cart);
                this._sessionService.setPayCount(cart.length);
                this._sessionService.setPaymentRequest(cart.length);
                return cart;
            })
        );
    }
}
