import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DisableControlDirective } from 'src/app/directives/disable-control.directive';
import { TimerComponent } from 'src/app/standalone-components/timer/timer.component';
import { VerificationMethodComponent } from 'src/app/standalone-components/verification-method/verification-method.component';
import { AuthenticationFluxComponent } from './authentication-flux/authentication-flux.component';
import { InputCodeComponent } from './verification-code/input-code/input-code.component';
import { TwoFactorComponent } from './verification-code/two-factor.component';

@NgModule({
    declarations: [TwoFactorComponent, InputCodeComponent, AuthenticationFluxComponent],
    exports: [TwoFactorComponent, AuthenticationFluxComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        ReactiveFormsModule,
        FormsModule,
        MatDividerModule,
        MatIconModule,
        MatProgressSpinnerModule,
        VerificationMethodComponent,
        TimerComponent,
        DisableControlDirective,
    ],
})
export class TwoFactorModule {}
