export const customerReferenceExpeditorsData = {
    titleCustomerReference: 'File #',
    placeholderCustomerReference: 'Maximum 10 alpha-numeric characters allowed',
    placeholderCustomerReferenceGlCode:
        'Your own personal reference # so you can remember this payment. For example your file #.',
    tooltip: 'Your own personal reference # so you can remember this payment. For example your file #.',
};

export const customerReferenceExpeditorsPattern: RegExp[] = [/^[a-zA-Z0-9]{1,10}$/];
export const customerReferenceExpeditors = /^[a-zA-Z0-9]{1,10}$/;
