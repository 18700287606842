import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PhoneInputComponent } from '@cargos/sprintpay-ui';
import { NgxMaskModule } from 'ngx-mask';
import { Subject, takeUntil } from 'rxjs';
import { PersonalInformationForm } from 'src/app/models/auth/signup.model';
import { blackListedCountryCodes } from 'src/app/utils/constants';
import { ErrorMatcher } from 'src/app/utils/errorMatcher';
import { ValidateEmailComponent } from '../validate-email/validate-email.component';

@Component({
    selector: 'app-personal-information-form[personalInformationForm]',
    templateUrl: './personal-information-form.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatFormFieldModule,
        NgxMaskModule,
        ValidateEmailComponent,
        PhoneInputComponent,
    ],
})
export class PersonalInformationFormComponent implements OnInit, AfterViewInit, OnDestroy {
    public matcher: ErrorMatcher;
    public initialCountryCode = '';
    public initialPhone = '';
    public blackListedCountryCodes = blackListedCountryCodes;
    private _unsubscribe$: Subject<void>;

    @Input() personalInformationForm!: FormGroup<PersonalInformationForm>;
    @ViewChild(PhoneInputComponent) PhoneInputComponent: PhoneInputComponent;

    constructor() {
        this.matcher = new ErrorMatcher();
        this._unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.initialCountryCode = this.code.value;
            this.initialPhone = this.phone.value;
        }, 0);
    }

    ngAfterViewInit(): void {
        this.PhoneInputComponent.phoneForm.valueChanges.pipe(takeUntil(this._unsubscribe$)).subscribe((value) => {
            this.code.patchValue(value.code || '');
            this.phone.patchValue(value.phone || '');
        });
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    /**
     * @method firstName()
     * @description: Convenience getter for easy access to form fields
     */

    get firstName(): FormControl<string> {
        return this.personalInformationForm.controls.firstName;
    }

    /**
     * @method lastName()
     * @description: Convenience getter for easy access to form fields
     */

    get lastName(): FormControl<string> {
        return this.personalInformationForm.controls.lastName;
    }

    /**
     * @method email()
     * @description: Convenience getter for easy access to form fields
     */

    get email(): FormControl<string> {
        return this.personalInformationForm.controls.email;
    }

    /**
     * @method phone()
     * @description: Convenience getter for easy access to form fields
     */

    get phone(): FormControl<string> {
        return this.personalInformationForm.controls.phone;
    }
    /**
     * @method phone()
     * @description: Convenience getter for easy access to form fields
     */

    get code(): FormControl<string> {
        return this.personalInformationForm.controls.countryCode;
    }

    validateEmail({ isValid, email }: { isValid: boolean; email: string }): void {
        if (isValid) {
            this.email.setValue(email);
        } else {
            this.email.setValue('');
        }
    }
}
