import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Countries } from 'src/app/models/utils/countries.model';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CountriesAPIService {
    private _authentication: string;

    constructor(private _httpClient: HttpClient) {
        this._authentication = environment.uris.method.authentication;
    }

    getCountries(): Observable<Countries[]> {
        const url: string = `${this._authentication}/countries/actives?date=${new Date().valueOf()}`;
        return this._httpClient.get<Countries[]>(url, {});
    }
}
