import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface CellEvent {
    component: string;
    value: unknown;
}

@Injectable()
export class CellEventHandlerService {
    private CellEvents: Subject<CellEvent> = new Subject<CellEvent>();
    private CellEventsInstance: Observable<CellEvent> = this.CellEvents.asObservable();

    constructor() {}

    get cellEvents(): Observable<CellEvent> {
        return this.CellEventsInstance;
    }

    fireEvent(CellEvent: CellEvent): void {
        this.CellEvents.next(CellEvent);
    }
}
