import { Component, OnInit } from '@angular/core';
import { SecurityService } from '../../../../services/utils/security.service';
import { profileComponents } from '../../../../utils/constants';

@Component({
    selector: 'app-empty-cart',
    templateUrl: './empty-cart.component.html',
})
export class EmptyCartComponent implements OnInit {
    newPaymentSecurity: Array<string>;

    constructor(private _securityService: SecurityService) {
        this.newPaymentSecurity = [''];
    }

    ngOnInit(): void {
        const userType: string = this._securityService.getUserType();

        if (this._securityService.verifyComponentsSecurity(profileComponents.newPaymentInEmptyCart)) {
            this.newPaymentSecurity.push(userType);
        }
    }
}
