import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControlStatus,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CustomValidators } from '@cargos/sprintpay-utils';
import { Subject, takeUntil } from 'rxjs';
import { ErrorMatcher } from 'src/app/utils/errorMatcher';
import { MatchPassword } from 'src/app/utils/matchPassword';
import { PasswordFormGroupType } from './models/password-form';
@Component({
    selector: 'app-form-password',
    standalone: true,
    templateUrl: './form-password.component.html',
    imports: [CommonModule, MatInputModule, MatIconModule, FormsModule, MatFormFieldModule, ReactiveFormsModule],
})
export class FormPasswordComponent implements OnInit, OnDestroy {
    public securityForm!: PasswordFormGroupType;
    public hidePassword = true;
    public hidePasswordConfirm = true;
    public matcher: ErrorMatcher;
    private unsubscribe$: Subject<void>;

    @Output() eventOnPassword = new EventEmitter<string>();
    @Input() set resetValue(reset: boolean) {
        if (reset) {
            this.createForm();
        }
    }

    get password(): AbstractControl<string> | null {
        return this.securityForm.get('password');
    }
    constructor(private fb: FormBuilder) {
        this.unsubscribe$ = new Subject<void>();
        this.matcher = new ErrorMatcher();
    }

    ngOnInit(): void {
        this.createForm();
        this.onChanges();
    }
    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    createForm(): void {
        this.securityForm = this.fb.group(
            {
                password: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(8),
                        CustomValidators.regex(new RegExp('^(?=.*[A-Za-z]).*$')),
                        CustomValidators.regex(new RegExp('^(?=.*?[0-9]).*$')),
                    ],
                ],
                confirmPassword: ['', [Validators.required]],
            },
            {
                validator: [MatchPassword.match('password', 'confirmPassword')],
            }
        );
    }

    onChanges(): void {
        this.securityForm.statusChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((statusChanges: FormControlStatus): void => {
                if (statusChanges === 'VALID') {
                    this.eventOnPassword.emit(this.password?.value);
                }
            });
    }
}
