import { Component, OnInit } from '@angular/core';
import { CustomerFeaturesService } from 'src/app/services/customer.service';
import { HomeService } from 'src/app/services/home.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { profileComponents } from '../../../../../utils/constants';

@Component({
    selector: 'app-card-wallet',
    templateUrl: './card-wallet.component.html',
})
export class CardWalletComponent implements OnInit {
    public csCreditSecurity: Array<string> = [''];
    public csCardOnHoldSecurity: Array<string> = [''];
    private isCustomerOnHold: boolean = false;

    constructor(
        private _homeService: HomeService,
        private _securityService: SecurityService,
        private _customerFeaturesService: CustomerFeaturesService
    ) {}

    ngOnInit(): void {
        this.isCustomerOnHold = this._customerFeaturesService.isCustomerOnHold();
        this.getConfig();
    }

    getConfig(): void {
        const userType = this._securityService.getUserType();
        if (this._securityService.verifyComponentsSecurity(profileComponents.homeSPCredit)) {
            this.csCreditSecurity.push(userType);
        }
        if (this.isCustomerOnHold) {
            this.csCardOnHoldSecurity.push(userType);
        }
        const showComponent =
            this._securityService.verifyComponentsSecurity(profileComponents.homeSPCredit) || this.isCustomerOnHold
                ? false
                : true;
        this._homeService.setWallet(showComponent);
    }
}
