import { ComponentType } from '@angular/cdk/overlay';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CartBill, Customer, PaymentCart } from '@cargos/sprintpay-models';
import { Subject, combineLatest, map, of, switchMap, take, takeUntil } from 'rxjs';
import { Payment } from 'src/app/models/payments/payment.model';
import { ApprovalFlowLevelsService } from 'src/app/services/approval-flow-levels.service';
import { CodaFluxFormsService } from 'src/app/services/coda-flux-form.service';
import { PaymentRequestService } from 'src/app/services/payment-request.service';
import { RequestService } from 'src/app/services/requests.service';
import { CartService } from 'src/app/services/utils/cart.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { companyName as companyNameObject } from 'src/app/utils/constants';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-button-charge',
    templateUrl: './button-charge.component.html',
})
export class ButtonChargeComponent implements OnInit, OnDestroy {
    @ViewChild('voucherNumberModal') voucherNumberModal?: ComponentType<unknown>;

    public isLoading = false;
    public isOnCart: boolean = false;
    public isSubmitted: boolean = false;
    public createApprovalFlow: number = 0;
    public buttonLabel: string = '';
    private paymentRequestId!: number | null;
    public dynamicValues: any;
    public paymentDetailForm!: FormGroup;
    public standardReferenceForm!: FormGroup;
    private unsubscribe$: Subject<void> = new Subject<void>();
    private currentCharge: any;
    private typeAmount: string = '';
    private _currentPayment: Payment;
    private _customer: Customer;
    private schenkerCompany: boolean = false;
    private request: any;
    readonly companyName: string;
    @Input() set charge(charge: any) {
        this.currentCharge = charge;
        this.createApprovalFlow = this._approvalFlowService.getLevelsOfApprovalFlowByAmount(charge['Amount to pay']);
        if (this.createApprovalFlow > 0) {
            this.typeAmount = this._approvalFlowService.getTypeByAmount(charge['Amount to pay']);
        }
        this.buttonLabel = this.createApprovalFlow <= 1 ? 'ADD TO CART' : 'SUBMIT TO APPROVER';
    }

    constructor(
        private _approvalFlowService: ApprovalFlowLevelsService,
        private _paymentRequestService: PaymentRequestService,
        private _customerService: CustomerService,
        private _paymentFluxService: PaymentFluxService,
        private _cartService: CartService,
        private _matDialog: MatDialog,
        private _codaFluxFormService: CodaFluxFormsService,
        private _createRequest: RequestService,
        private _errorHandlerService: ErrorHandlerService
    ) {
        this._customer = this._customerService.getCustomer();
        this.companyName = this._customerService.getCompanyName();
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.schenkerCompany = this.companyName === companyNameObject.schenker;
    }

    ngOnInit(): void {
        this.subscribeCurrentCart();
    }

    ngOnDestroy(): void {
        this._matDialog.closeAll();
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeCurrentCart(): void {
        this._cartService
            .getCurrentCart$()
            .pipe(
                switchMap((cart: PaymentCart[]) => {
                    if (cart && cart.length) {
                        return this._cartService.getChargeInPaymentCartByAmountAndType(
                            this.currentCharge['Amount to pay'],
                            this.currentCharge['Description'],
                            this.currentCharge['HBL']
                        );
                    }

                    return of(null);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (paymentCart) => {
                    if (paymentCart) {
                        this.isLoading = false;
                        this.paymentRequestId = paymentCart.id || null;
                        this.isOnCart = true;
                    } else {
                        this.paymentRequestId = null;
                        this.isOnCart = false;
                    }
                },
            });
        this._cartService
            .getSubmittedItems$()
            .pipe(
                switchMap((submitted) => {
                    if (submitted && submitted.length) {
                        return this._cartService.getChargeSubmittedByAmountAndType(
                            this.currentCharge['Amount to pay'],
                            this.currentCharge['Description'],
                            this.currentCharge['HBL']
                        );
                    }

                    return of(null);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (paymentCart) => {
                    if (paymentCart) {
                        this.isLoading = false;
                        this.isSubmitted = true;
                    } else {
                        this.isSubmitted = false;
                    }
                },
            });
    }

    handleChargeAction(): void {
        combineLatest([
            this._codaFluxFormService.getCustomerReferenceForm(),
            this._codaFluxFormService.getPaymentDetailsForm(),
        ])
            .pipe(
                map(([customerReferenceForm, paymentDetailsForm]) => {
                    return {
                        customerReferenceForm: customerReferenceForm.valid,
                        paymentdetailsForm: paymentDetailsForm.valid,
                    };
                }),
                take(1)
            )
            .subscribe({
                next: (form) => {
                    if (!form.paymentdetailsForm || !form.customerReferenceForm) {
                        this._codaFluxFormService.setPaymentDetailsFormAsTouched(true);
                        this._codaFluxFormService.setCustomerReferenceFormAsTouched(true);
                        return;
                    }
                    if (this.createApprovalFlow > 0) {
                        this.startApprovalFlowLevel(this.typeAmount);
                    } else {
                        this.addPaymentRequest(this.currentCharge);
                    }
                },
            });
    }

    removeCharge(): void {
        if (this.paymentRequestId) {
            if (this.isLoading) {
                return;
            }
            this.isLoading = true;
            this._paymentRequestService
                .removePamentRequest(this.paymentRequestId)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        this.isLoading = false;
                        this._cartService
                            .remove3rdPartyItems(
                                this.currentCharge['Amount to pay'],
                                this.currentCharge['Description'],
                                this.currentCharge['HBL']
                            )
                            .pipe(take(1))
                            .subscribe({
                                next: (result) => {
                                    this._cartService.reset3rdPartyItems();
                                    result?.forEach((item: any) => this._cartService.setAndSave3rdPartyItems(item));
                                },
                            });
                    },
                    error: (error: any) => {
                        this.isLoading = false;
                        const errorMsg = this._errorHandlerService.errorMsg(error.error);
                        Swal.fire({
                            html: errorMsg,
                            icon: 'error',
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: 'Close',
                            allowOutsideClick: false,
                        });
                    },
                });
        }
    }

    startApprovalFlowLevel(typeAmount: string): void {
        this.isLoading = true;
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.dynamicValues = this._paymentFluxService.getDynamicValues();
        const companyId = this._customerService.getCompanyId();
        const source = this._customer.approvalLevels?.company?.name;
        if (this._currentPayment && this._currentPayment.details) {
            const paymentDetailsSection = this._currentPayment.details;
            paymentDetailsSection.amount = this.currentCharge['Amount to pay'];
            paymentDetailsSection.paymentType = this.currentCharge['Description'];
            paymentDetailsSection.hawb = this.currentCharge['HBL'];
            paymentDetailsSection.awb = this.currentCharge['HBL'];
            paymentDetailsSection.externalData = { chargeId: this.currentCharge['Charge Code'] };
            this._paymentFluxService.setData('details', paymentDetailsSection);
        }
        this.request = this._createRequest.getRequestData(
            this._currentPayment,
            this.companyName,
            this.dynamicValues,
            companyId,
            typeAmount,
            { chargeId: this.currentCharge['Charge Code'] }
        );
        if (this.request.externalData) {
            this.request.externalData.source = this._currentPayment?.source || source;
        }
        if (this.schenkerCompany) {
            this.openDialog();
        } else {
            this.submitItem(this._currentPayment, this.request);
        }
    }

    setVoucherNumber(voucher: string) {
        this.request.externalData['voucher'] = voucher;
        this.submitItem(this._currentPayment, this.request);
    }

    submitItem(currentPayment: any, request: any): void {
        const requestAddedToCart = this._approvalFlowService.isChargeToPayAvailable(
            this.currentCharge['Amount to pay']
        );
        const companyNameObject = encodeURIComponent(this.companyName.toLowerCase());
        const locationId =
            this.companyName && currentPayment[`${companyNameObject}CustomerReference`]
                ? currentPayment[`${companyNameObject}CustomerReference`].locationId
                : this._customer.approvalLevels
                  ? this._customer.approvalLevels?.company?.firstLocation?.id
                  : 'N/A';
        this._paymentRequestService
            .startApprovalFlow(request, locationId)
            .pipe(take(1))
            .subscribe({
                next: (response) => {
                    this.isLoading = false;
                    if (requestAddedToCart) {
                        this.currentCharge['Voucher #'] = this.schenkerCompany ? response.externalData?.voucher : '';
                        this._cartService.setAndSave3rdPartyItems(this.currentCharge);
                    }
                },
                error: (error: any) => {
                    this.isLoading = false;
                    const errorMsg = this._errorHandlerService.errorMsg(error.error);
                    Swal.fire({
                        html: errorMsg,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                },
            });
    }

    addPaymentRequest(charge: any): void {
        this.isLoading = true;
        const currentPayment = this._paymentFluxService.getCurrentPayment();
        if (currentPayment && currentPayment.details) {
            const paymentDetailsSection = currentPayment.details;
            paymentDetailsSection.amount = charge['Amount to pay'];
            paymentDetailsSection.paymentType = charge['Description'];
            paymentDetailsSection.hawb = charge['HBL'];
            paymentDetailsSection.awb = charge['HBL'];
            paymentDetailsSection.externalData = { chargeId: charge['Charge Code'] || '' };
            this._paymentFluxService.setData('details', paymentDetailsSection);
        }
        let paymentRequest: PaymentCart = this._createRequest.getStandardRequestData(currentPayment);
        Object.entries(paymentRequest).map((prop): void => {
            if (!prop[1]) {
                delete paymentRequest[prop[0] as keyof PaymentCart];
            }
        });
        this._paymentRequestService
            .addPaymentRequest(paymentRequest)
            .pipe(
                map((cart) => {
                    return CartBill.fromJson({ cart });
                }),
                take(1)
            )
            .subscribe({
                next: (response) => {
                    this.isLoading = false;
                    this._cartService.setCartBill(response);
                    this._cartService.setAndSave3rdPartyItems(charge);
                },
                error: (error: any) => {
                    this.isLoading = false;
                    const errorMsg = this._errorHandlerService.errorMsg(error.error);
                    Swal.fire({
                        html: errorMsg,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                },
            });
    }

    /**
     * @method openDialog()
     * @description Opens the dialog in this case menu right sidebar for the activity log
     */

    openDialog(): void {
        this._matDialog.open(this.voucherNumberModal as ComponentType<unknown>, {
            id: 'voucher',
            disableClose: true,
            width: '50%',
            maxWidth: '50%',
        });
    }
}
