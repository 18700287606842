import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, distinctUntilChanged, finalize, map, switchMap } from 'rxjs';
import { Countries } from 'src/app/models/utils/countries.model';
import { CountriesAPIService } from '../requests/countries-api.service';

@Injectable({
    providedIn: 'root',
})
export class countriesService {
    private _isCountriesLoaded = new BehaviorSubject<boolean>(false);
    private _isLoadingCountries = new BehaviorSubject<boolean>(false);
    private _countriesData = new BehaviorSubject<Countries[]>([]);

    constructor(private _countriesAPIService: CountriesAPIService) {}

    getCountriesActives(): Observable<Countries[]> {
        if (this._isLoadingCountries.value) {
            return this.getCountries();
        }
        this.setIsLoading(true);
        return this.isCountriesLoaded().pipe(
            switchMap((isCountriesLoaded) => {
                if (isCountriesLoaded) {
                    return this.getCountries();
                }
                return this._countriesAPIService.getCountries().pipe(
                    map((countries: Countries[]) => {
                        this.setAPILoaded(true);
                        this.setCountries(countries);
                        return countries;
                    }),
                    catchError(() => {
                        this.setAPILoaded(false);
                        return [];
                    }),
                    finalize(() => {
                        this.setIsLoading(false);
                    })
                );
            })
        );
    }

    setCountries(countries: Countries[]): void {
        this._countriesData.next(countries);
    }

    getCountries(): Observable<Countries[]> {
        return this._countriesData.asObservable();
    }

    setIsLoading(isLoaded: boolean): void {
        this._isLoadingCountries.next(isLoaded);
    }

    isLoadingCountries(): Observable<boolean> {
        return this._isLoadingCountries.asObservable().pipe(distinctUntilChanged());
    }

    setAPILoaded(isLoaded: boolean): void {
        this._isCountriesLoaded.next(isLoaded);
    }

    isCountriesLoaded(): Observable<boolean> {
        return this._isCountriesLoaded.asObservable().pipe(distinctUntilChanged());
    }
}
