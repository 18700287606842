import { CommonModule } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { PaymentService } from '../../../services/payment.service';
import { CustomerService } from '../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../services/utils/payment-flux.service';
import { FilesContainerComponent } from '../../files/files-container';

@Component({
    selector: 'app-company-customer-reference',
    templateUrl: './company-customer-reference.component.html',
    standalone: true,
    imports: [CommonModule, MatButtonModule, FilesContainerComponent],
})
export class CompanyCustomerReferenceComponent implements OnInit, AfterViewInit, AfterViewChecked {
    id: string;
    hasExternalLookup: boolean = false;
    companyName: string;
    companyReferenceObj: any;
    companyReferenceObjCopy: any;
    companyReferenceTitle: any;
    locationAssigned: any;
    isStartingLevel: boolean;
    pathFiles: string[];
    titleFile: string;
    private _company: any;
    private _currentPayment: any;
    private _customer: any;
    private _dynamicFields: any;

    @Input() paymentDetail: any;

    constructor(
        private _router: Router,
        private _paymentFluxService: PaymentFluxService,
        private _customerService: CustomerService,
        private _paymentService: PaymentService,
        private _changeDetectorRef: ChangeDetectorRef
    ) {
        this.titleFile = '';
        this.pathFiles = [];
        this._dynamicFields = this._paymentFluxService.getDynamicFields();
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this._customer = this._customerService.getCustomer();
        this._company = this._customer.approvalLevels ? this._customer.approvalLevels.company : '';
        this.companyName = this._company ? this._company.name : '';
        this.companyReferenceTitle = {};
        this.id = '';
        this.isStartingLevel = this._customerService.getStartingLevel();
    }

    ngOnInit(): void {
        this.getFormFields();
        this.companyReferenceObj =
            this.paymentDetail &&
            this.paymentDetail[`${encodeURIComponent(this.companyName.toLowerCase())}CustomerReference`]
                ? this.paymentDetail[`${encodeURIComponent(this.companyName.toLowerCase())}CustomerReference`]
                : {};
        this.id =
            this.paymentDetail && this.paymentDetail.id
                ? this.paymentDetail.id
                : this._currentPayment !== undefined
                  ? this._currentPayment.id
                  : '';
        this.locationAssigned =
            this._customer.approvalLevels && this._customer.approvalLevels.company
                ? this._customer.approvalLevels.company.firstLocation
                : null;
        this.hasExternalLookup =
            this.paymentDetail && this.paymentDetail.externalLookup
                ? this.paymentDetail.externalLookup
                : this._currentPayment && this._currentPayment.facility
                  ? this._currentPayment.facility.externalLookup
                  : false;
    }

    ngAfterViewInit(): void {
        this.getLabels();
    }

    ngAfterViewChecked(): void {
        this._changeDetectorRef.detectChanges();
    }

    keys(): string[] {
        return Object.keys(this.companyReferenceObjCopy ? this.companyReferenceObjCopy : []);
    }

    getLabels(): void {
        let title: any;
        this.companyReferenceObjCopy = Object.assign({}, this.companyReferenceObj);
        delete this.companyReferenceObjCopy.locationId;
        if (this.companyReferenceObjCopy && this._hidePaymentAmount) {
            delete this.companyReferenceObjCopy.paymentAmount;
        }
        if (this.companyReferenceObjCopy && this._dynamicFields && this._dynamicFields[0]) {
            Object.keys(this.companyReferenceObjCopy).forEach((key: any): void => {
                title = this._dynamicFields[0].filter((item: any) => item.name === key);
                this.companyReferenceTitle[key] =
                    key === 'paymentAmount'
                        ? 'Payment Amount'
                        : key === 'type'
                          ? 'Type'
                          : key === 'locationName'
                            ? 'Location'
                            : title[0]?.label;
            });
        }
        if (this._dynamicFields?.[0] && this.companyReferenceObjCopy) {
            const fileInput = this._dynamicFields[0]
                .filter((item: any) => item.fieldType === 'file')
                .map((filteredItem: any) => {
                    this.titleFile = filteredItem.label;
                    return filteredItem.name;
                });
            if (this.companyReferenceObjCopy && fileInput && this.companyReferenceObjCopy[fileInput]) {
                this.pathFiles = this.companyReferenceObjCopy[fileInput]?.split(',');
                delete this.companyReferenceObjCopy[fileInput];
            }
        }
    }

    /**
     * @method editPaymentRequest()
     * @description First we review if the payment request has an ID, if we have and ID we call the service to generate the necessary data for the payment request process
     */

    editPaymentRequest(): void {
        const redirect: string = this.paymentDetail.redirectRoute ? this.paymentDetail.redirectRoute : this._router.url;
        this._paymentFluxService.setCurrentPayment(this.paymentDetail);
        if (!this._paymentFluxService.getDynamicValues()) {
            this._paymentFluxService.setDynamicValues(this.paymentDetail.dynamicValues);
        }
        this._router.navigate([
            `/admin/facilityPayments/newPayment/flux/${encodeURIComponent(this.companyName)}/Reference`,
        ]);
    }

    getFormFields(): void {
        if (this.companyName !== '') {
            this._dynamicFields = {};
            this._paymentService.getFormFields('0').then((result): void => {
                result.map((item) => {
                    if (this.paymentDetail.dynamicValues && item.id && !item.isReplacement) {
                        this.companyReferenceObj[item.name] =
                            this.paymentDetail.dynamicValues[item.id] !== ''
                                ? this.paymentDetail.dynamicValues[item.id] !== undefined
                                    ? this.paymentDetail.dynamicValues[item.id]
                                    : 'N/A'
                                : 'N/A';
                    }
                });
                this._dynamicFields[0] = result;
                this.getLabels();
            });
        }
    }

    /**
     * @method _hidePaymentAmount()
     * @description Getter to check if customer has only one amount threshold option to hide it in template
     */

    private get _hidePaymentAmount(): boolean {
        const paymentReference = this._customer.approvalLevels?.company?.paymentReference.find(
            (paymentRef: any) => paymentRef.name && paymentRef.name.toLowerCase() === 'type'
        );

        return paymentReference?.paymentReferenceLookups?.length <= 1;
    }
}
