<!--START: FACILITY SEARCH -->
<section id="search_facility" class="mt-4">
    <!--START: FACILITY SEARCH CONTAINER -->
    <div id="search_facility__container" class="container-fluid py-5 px-5">
        <div class="row">
            <!--START: HEADER -->
            <div id="search_facility__header" class="mb-3">
                <div class="col-12 d-flex align-items-center justify-content-start">
                    <h6 class="mx-auto">Search the NAME of the cargo facility you would like to pay</h6>
                </div>
            </div>
            <!--END: HEADER -->
            <!--START: SEARCH FACILITY -->
            <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
                <div class="col-sm-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
                    <mat-form-field class="d-block mb-4 w-100" appearance="outline">
                        <mat-label>Search cargo facility</mat-label>
                        <input
                            matInput
                            type="text"
                            id="search"
                            name="search"
                            data-cy="search"
                            formControlName="search"
                            autocomplete="off"
                            placeholder="Search cargo facility"
                            (keydown.enter)="$event.preventDefault(); onSubmit()"
                        />
                        <mat-icon class="me-2" matPrefix (click)="searchOnClick()">search</mat-icon>
                        <button
                            *ngIf="getSearchInput?.value"
                            matSuffix
                            mat-icon-button
                            aria-label="Clear"
                            (click)="clearSearch()"
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </form>
            <!--END: SEARCH FACILITY -->
            <!--START: FILTER FACILITY -->
            <!--
      <div class="col-12">
        <div class="row">
          <div class="col-6">
            <p class="justify-content-start d-flex mb-4">Number of elements: {{numberOfFacilities}}</p>
          </div>
          <div class="col-6">
            <p class="justify-content-end d-flex mb-4" (click)="showFilters = !showFilters"> Filter by
              <mat-icon class="ps-2 d-flex align-items-center me-2">filter_list</mat-icon>
            </p>
          </div>
        </div>
        <div *ngIf="showFilters" class="row mx-0 mb-4" style="background-color: #f7f7f7; cursor: pointer;">
          <div class="col-sm-12 col-md-6 d-md-flex">
            <div class="form-group w-100 mt-4">
              <mat-form-field class="d-block" appearance="outline">
                <mat-label>Airport code</mat-label>
                <input matInput type="text" [(ngModel)]="valueFilter" (keyup.enter)="filterByAirport($event)" id="airportCode" name="airportCode" autocomplete="off" placeholder="Airport code">
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 d-md-flex align-items-center">
            <div class="form-group w-100">
              <div class="row d-md-flex align-items-center">
                <div class="col-4 align-items-baseline">
                  <mat-checkbox (click)="filterFedex = !filterFedex; filterFacilities()" class="d-block me-3 mb-sm-3 mb-md-0" [color]="'primary'">Fedex</mat-checkbox>
                </div>
                <div class="col-4 align-items-baseline">
                  <mat-checkbox (click)="filterMessenger = !filterMessenger; filterFacilities()" class="d-block me-3 mb-sm-3 mb-md-0" [color]="'primary'">Messenger</mat-checkbox>
                </div>
                <div class="col-4 align-items-baseline">
                  <mat-checkbox (click)="filterElectronic = !filterElectronic; filterFacilities()" class="d-block me-3 mb-sm-3 mb-md-0" [color]="'primary'">Electronic</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      -->
            <!--END: FILTER FACILITY -->
            <!--START: NO FACILITY OR NO MATCH IN SEARCH -->
            <div class="col-12" *ngIf="noInformation || notRecordsFound">
                <div
                    class="h-100 d-flex flex-column justify-content-center align-items-center no_information--height my-5"
                >
                    <div *ngIf="noInformation && !notRecordsFound" class="row">
                        <div class="col-12">
                            <img
                                class="mx-auto d-block"
                                src="assets/images/facilities/warehouse.svg"
                                alt="No information illustration"
                            />
                        </div>
                        <div class="col-12">
                            <h6 class="d-block text-center mt-4 mb-3">
                                Are you paying an airline cargo facility? Pro tip: search by entering the MAWB prefix +
                                airport code.
                            </h6>
                        </div>
                    </div>
                    <div *ngIf="notRecordsFound" class="row">
                        <div class="col-12">
                            <img
                                class="mx-auto d-block"
                                src="assets/images/facilities/searchError.svg"
                                alt="No information illustration"
                            />
                        </div>
                        <div class="col-12">
                            <h4 class="d-block text-center mt-4 mb-3">
                                Oops... we don't have any facility matching this criteria!
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--END: NO FACILITY OR NO MATCH IN SEARCH -->
        <!--START: FACILITIES ITEMS -->
        <div *ngIf="!noInformation">
            <div class="row">
                <ng-container *ngIf="frequentFacilities">
                    <p>Pay one of your frequent facilities</p>
                </ng-container>
                <!--START: FACILITIES ITEM -->
                <article
                    *ngFor="let item of facilities; trackBy: trackBy"
                    @fadeIn
                    class="col-12 col-md-6 col-lg-4 mb-3 mt-3"
                >
                    <div class="facilities_item h-100 d-flex flex-column">
                        <h6>{{item.name}}</h6>
                        <p class="facilities_item--address d-block flex-grow-1">
                            {{item.airportCode}}, {{item.address}}
                        </p>
                        <!--START: FACILITIES DELIVERY TYPE -->
                        <p class="facilities_item--type d-flex align-items-center">
                            <ng-container
                                *ngIf="
                                item.facilityPaymentDeliveryNotificationShort
                                else staticMessage"
                            >
                                <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
                                {{ item.facilityPaymentDeliveryNotificationShort}}
                            </ng-container>
                            <ng-template #staticMessage>
                                <ng-container *ngIf="item?.deliveryType?.toLowerCase() === 'electronic'">
                                    <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
                                    Electronic less than 1 hour
                                </ng-container>
                                <ng-container *ngIf="item?.deliveryType?.toLowerCase() === 'messenger'">
                                    <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
                                    Messenger < 3 hour
                                </ng-container>
                                <ng-container *ngIf="item?.deliveryType?.toLowerCase() === 'fedex'">
                                    <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
                                    FedEx
                                </ng-container>
                            </ng-template>
                        </p>
                        <!--END: FACILITIES DELIVERY TYPE -->
                        <div>
                            <button
                                mat-flat-button
                                color="primary"
                                type="button"
                                class="text-uppercase btn__sprintpay btn__sprintpay--green btn__sprintpay--multiLine w-100"
                                (click)="selectFacility(item)"
                            >
                                Pay this cargo facility
                            </button>
                        </div>
                    </div>
                </article>
                <!--START: FACILITIES ITEM -->
            </div>
            <div class="row justify-content-center">
                <button
                    mat-button
                    type="button"
                    *ngIf="showMoreRecordsButton && !isSearching"
                    class="w-50 d-inline-block btn__sprintpay btn__sprintpay--link text-uppercase"
                    (click)="loadMoreFacilities()"
                >
                    Load more facilities
                </button>
            </div>
        </div>
        <!--END: FACILITIES ITEMS -->
    </div>
    <!--END: FACILITY SEARCH CONTAINER -->
</section>
<!--END: FACILITY SEARCH -->
