<!--START: PENDING APPROVAL -->
<section id="pending_approval" *ngIf="pendingPayments.length > 0">
    <div id="pending_approval__header" class="mb-3 pt-3">
        <!--START: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
        <div class="row">
            <div class="col-12 col-md-6 col-xs-12 d-flex align-items-center justify-content-start">
                <h6>Pending approval</h6>
                <div class="d-flex align-items-baseline">
                    <button
                        mat-button
                        type="button"
                        class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100"
                        data-cy="pending_approval_viewAll"
                        [routerLink]="['/admin/facilityPayments/pendingApproval']"
                    >
                        View All
                    </button>
                </div>
            </div>
            <div class="col-6 justify-content-end d-none d-md-flex align-items-center">
                <ng-template [ngIf]="cannotAddRequestOfTableToCart" [ngIfElse]="hasAprovalLevels">
                    <div class="align-items-baseline">
                        <button
                            *ngxPermissionsOnly="userPermissionsCart"
                            mat-button
                            type="button"
                            class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-end pending_approval__submit"
                        >
                            Some selected items cannot be added to the cart
                        </button>
                    </div>
                </ng-template>
                <ng-template #hasAprovalLevels>
                    <div class="align-items-baseline d-flex">
                        <button
                            *ngxPermissionsOnly="userPermissionsSubmit"
                            (click)="submitToApprover()"
                            mat-button
                            type="button"
                            class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-end"
                        >
                            Submit to approver
                        </button>
                        <button
                            *ngxPermissionsOnly="userPermissionsCart"
                            (click)="addToCart()"
                            mat-button
                            type="button"
                            class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-end pending_approval__submit"
                        >
                            Add to cart
                        </button>
                    </div>
                </ng-template>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
        <!--START: HEADER AND ACTION BUTTONS FOR SMALL SCREEN -->
        <div class="row">
            <div class="col-12 justify-content-end d-flex d-md-none align-items-center">
                <ng-template [ngIf]="cannotAddRequestOfTableToCart" [ngIfElse]="hasAprovalLevels">
                    <div class="align-items-baseline">
                        <button
                            *ngxPermissionsOnly="userPermissionsCart"
                            mat-button
                            type="button"
                            class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-end pending_approval__submit"
                        >
                            Some selected items cannot be added to the cart
                        </button>
                    </div>
                </ng-template>
                <ng-template #hasAprovalLevels>
                    <div class="align-items-baseline d-flex">
                        <button
                            *ngxPermissionsOnly="userPermissionsSubmit"
                            (click)="submitToApprover()"
                            mat-button
                            type="button"
                            class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-end"
                        >
                            Submit to approver
                        </button>
                        <button
                            *ngxPermissionsOnly="userPermissionsCart"
                            (click)="addToCart()"
                            mat-button
                            type="button"
                            class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-end pending_approval__submit"
                        >
                            Add to cart
                        </button>
                    </div>
                </ng-template>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS FOR SMALL SCREEN -->
    </div>
    <!--START: CALL AM TABLE CHILD COMPONENT -->
    <ng-container *ngIf="pendingPayments">
        <div id="pending_approval__container" class="container-fluid mb-4 pb-4">
            <div class="row">
                <div class="col-12">
                    <app-am-table
                        (ifItemSelected)="itemsSelected($event)"
                        (eventOnSort)="sort($event)"
                        [am_filter]="amTable[0].filter"
                        [am_sorting]="amTable[0].sorting"
                        [am_pagination]="amTable[0].pagination"
                        [am_columns]="amTable[0].displayedColumns"
                        [am_columnsName]="amTable[0].displayedColumnsName"
                        [am_source]="amTable[0].dataSource"
                        [am_paginationSizes]="amTable[0].paginationSizes"
                        [am_totalRows]="amTable[0].totalElements"
                        [am_sortableColumns]="sortableColumns"
                    ></app-am-table>
                </div>
            </div>
        </div>
    </ng-container>
    <!--END: CALL AM TABLE CHILD COMPONENT -->
</section>
<!--END: PENDING APPROVAL -->
