import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pagination } from '@cargos/sprintpay-models';
import { buildEndPoint } from '@cargos/sprintpay-services';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { DebitDetail } from 'src/app/models/debits/debit-history.model';
import { PaymentDetails, PaymentOverridePayload } from 'src/app/models/payments/payment-details.model';
import { Filters } from 'src/app/models/ui/table.model';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentsAPIService {
    private _paymentsURL: string;
    private _cartURL: string;
    private cancelPendingRequests$ = new Subject<void>();

    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenService
    ) {
        this._paymentsURL = environment.uris.method.payments;
        this._cartURL = environment.uris.method.cart;
    }

    downloadPaymentConfirmation(paymentsIds: string): Observable<Blob> {
        const url = `${this._paymentsURL}/admin/paymentConfirmationWithData`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.post(url, { id: paymentsIds }, { headers, responseType: 'blob' });
    }

    getPaymentsByHQ(filters: Filters = {}): Observable<any> {
        const url = `${this._paymentsURL}` + (filters.searchTerms ? '/search' : '/getCompletedPayments');
        let params: HttpParams = new HttpParams();
        this.cancelPendingRequests$.next();

        Object.entries(filters).forEach(([key, value]) => {
            params = params.append(key, value);
        });

        return this._httpClient.get(url, { params }).pipe(takeUntil(this.cancelPendingRequests$));
    }

    getAchDebits(filters: Filters = {}): Observable<Pagination<DebitDetail>> {
        const url = `${this._paymentsURL}/achDebit` + (filters.searchQuery ? '/search' : '');
        let params: HttpParams = new HttpParams();
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        Object.entries(filters).forEach(([key, value]) => {
            if (value) {
                params = params.append(key, value);
            }
        });

        return this._httpClient.get<Pagination<DebitDetail>>(url, { headers, params });
    }

    downloadDebits(filters: Filters = {}): Observable<Blob> {
        const url = `${this._paymentsURL}/achDebit/download`;
        let params: HttpParams = new HttpParams();
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        Object.entries(filters).forEach(([key, value]) => {
            if (value) {
                params = params.append(key, value);
            }
        });

        return this._httpClient.post(url, {}, { headers, params, responseType: 'blob' });
    }

    getInvoicesById(id: number, filters: Filters = {}): Observable<Pagination<DebitDetail>> {
        const url = `${this._paymentsURL}/achDebit/${id}/invoices`;
        let params: HttpParams = new HttpParams();
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        Object.entries(filters).forEach(([key, value]) => {
            params = params.append(key, value);
        });

        return this._httpClient.get<Pagination<DebitDetail>>(url, { headers, params });
    }

    getPaymentDetails(id: number, activeProfileView: string): Observable<PaymentDetails[]> {
        const url = `${this._paymentsURL}/payment/detail/${id}?activeProfileView=${activeProfileView}`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get<PaymentDetails[]>(url, { headers });
    }

    downloadDebitInvoicesById(achDebitId: string, filters: Filters = {}): Observable<Blob> {
        const url = `${this._paymentsURL}/achDebit/${achDebitId}/invoices/download`;
        let params: HttpParams = new HttpParams();
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        Object.entries(filters).forEach(([key, value]) => {
            params = params.append(key, value);
        });

        return this._httpClient.post(url, {}, { headers, params, responseType: 'blob' });
    }

    downloadPaymentDetailsById(filters: Filters = {}): Observable<string> {
        let url = `${this._paymentsURL}/paymentConfirmations/download`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        const params = { ...filters };
        url = buildEndPoint(url, params as { [key: string]: string | number | boolean });

        return this._httpClient.get(url, { headers, responseType: 'text' });
    }

    performPaymentOverride(payload: PaymentOverridePayload): Observable<string> {
        const url = `${this._paymentsURL}/payment-override`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this._httpClient.post(url, payload, { headers }).pipe(map(() => 'Complete'));
    }

    downloadPaymentsByIds(paymentsIds: string): Observable<Blob> {
        let url = `${this._cartURL}/downloadApprovalPayments?paymentsIds=${paymentsIds}`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.get(url, { headers, responseType: 'blob' });
    }

    getAwbDuplicateCount(awb: string, date: number): Observable<{ count: number }> {
        const url = `${this._paymentsURL}/awbDuplicateCountV2?date=${date}`;
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.post<{ count: number }>(url, { awb }, { headers });
    }
}
