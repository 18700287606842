import { Component } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
    selector: 'app-facility-payments',
    templateUrl: './facility-payments.component.html',
})
export class FacilityPaymentsComponent {
    constructor(private _breadcrumbService: BreadcrumbService) {
        this._breadcrumbService.set('@facility-payments', 'Facility payments');
    }
}
