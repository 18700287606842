import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export interface Warning {
    label: string;
    required: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class WarningService {
    private _tooltipAlerts: BehaviorSubject<Warning[]> = new BehaviorSubject<Warning[]>([]);
    private _alert_key: Warning | undefined;
    public getAlertsSelected$: Observable<Warning[]>;

    constructor() {
        this._tooltipAlerts = new BehaviorSubject<Warning[]>([]);
        this.getAlertsSelected$ = this._tooltipAlerts.asObservable().pipe(filter((terminal: any) => !!terminal));
    }

    /**
     * @method selectAlerts()
     * @param (alert: Warning)
     * @description
     */

    selectAlerts(alert: Warning) {
        const currentValue: Warning[] = this._tooltipAlerts.value;
        const updatedValue: Warning[] = currentValue ? [...currentValue, alert] : [alert];
        this._tooltipAlerts.next(updatedValue);
        this.setAlert(alert);
    }

    /**
     * @method updateTooltipAlerts()
     * @description
     */

    updateTooltipAlerts() {
        this._tooltipAlerts.next(null!);
    }

    /**
     * @method setAlert()
     * @param (alert: Warning)
     * @description
     */

    setAlert(alert: Warning) {
        this._alert_key = alert;
    }

    /**
     * @method getAlerts()
     * @description
     */

    getAlerts() {
        return this._alert_key;
    }
}
