import { CommonModule } from '@angular/common';
import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'app-details-header',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './details-header.component.html',
})
export class DetailsHeaderComponent {
    constructor() {}

    @HostBinding('class.px-0') get basicClasses(): boolean {
        return true;
    }
}
