import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SignUpAPIService } from '../services/requests/signup-api.service';
import { AuthService } from '../services/utils/auth.service';
import { TokenService } from '../services/utils/token.service';
import { UserSessionService } from '../services/utils/user-session.service';

@Injectable()
export class HttpUnauthorizedInterceptor implements HttpInterceptor {
    private _isRefreshingToken: boolean;
    private _tokenSubject: BehaviorSubject<string | null>;
    private _excludedUrls: string[];

    constructor(
        private _authService: AuthService,
        private _signUpAPIService: SignUpAPIService,
        private _tokenService: TokenService,
        private _userSessionService: UserSessionService
    ) {
        this._isRefreshingToken = false;
        this._tokenSubject = new BehaviorSubject<string | null>(null);
        this._excludedUrls = [environment.uris.method.authentication, 'api.ipify.org'];
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (
                    error instanceof HttpErrorResponse &&
                    [0, HttpStatusCode.Unauthorized].includes(error.status) &&
                    !this._excludedUrls.some((url) => error.url?.includes(url))
                ) {
                    return this._handleUnauthorizedError(request, next);
                }

                return throwError(() => error);
            })
        );
    }

    private _handleUnauthorizedError(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!this._isRefreshingToken) {
            this._isRefreshingToken = true;
            this._tokenSubject.next(null);

            return this._userSessionService.refreshToken().pipe(
                switchMap((authorizationToken) => {
                    if (authorizationToken) {
                        this._tokenSubject.next(authorizationToken);
                        return this._retryWithNewToken(request, next, authorizationToken);
                    } else {
                        throw Error('Unauthorized');
                    }
                }),
                catchError((error) => {
                    this._userSessionService.logout();
                    return throwError(() => error);
                }),
                finalize(() => {
                    this._isRefreshingToken = false;
                })
            );
        } else {
            return this._tokenSubject.pipe(
                filter((token) => !!token),
                take(1),
                switchMap((token) => {
                    return this._retryWithNewToken(request, next, token!);
                })
            );
        }
    }

    private _retryWithNewToken(
        req: HttpRequest<unknown>,
        next: HttpHandler,
        newToken: string
    ): Observable<HttpEvent<unknown>> {
        const request = req.clone({
            setHeaders: {
                Authorization: `Bearer ${newToken}`,
            },
        });

        return next.handle(request);
    }
}
