import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { SettingsRoutingModule } from './settings-routing.module';

//COMPONENTS

import { SettingsComponent } from './settings.component';

//MODULES

import { SharedModule } from '../../../shared/shared.module';
import { AccountComponent } from './account/account.component';
import { SecurityComponent } from './security/security.component';

@NgModule({
    declarations: [SettingsComponent, AccountComponent, SecurityComponent],
    imports: [CommonModule, SettingsRoutingModule, RouterModule, ReactiveFormsModule, FormsModule, SharedModule],
})
export class SettingsModule {
    constructor() {}
}
