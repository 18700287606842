import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// COMPONENTS

import { SignupComponent } from './signup.component';

const routes: Routes = [
    {
        path: '',
        component: SignupComponent,
        children: [
            {
                path: '',
                redirectTo: '/signup/sprintpay/account-type',
                pathMatch: 'full',
            },
            {
                path: ':facility/account-type',
                loadChildren: () => import('./form-signup/form-signup.module').then((m) => m.FormSignupModule),
            },
            // {
            //     path: ':facility/account-type-company',
            //     loadChildren: () =>
            //         import('./account-type-company/account-type-company.module').then(
            //             (m) => m.AccountTypeCompanyModule
            //         ),
            // },
            // {
            //     path: ':facility/account-type-individual',
            //     loadChildren: () =>
            //         import('./account-type-individual/account-type-individual.module').then(
            //             (m) => m.AccountTypeIndividualModule
            //         ),
            // },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SignupRoutingModule {}
