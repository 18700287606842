import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, map, of, switchMap, takeUntil } from 'rxjs';
import { Facility } from 'src/app/models/payments/facility.model';
import { PayToFacilityService } from 'src/app/services/pay-to-facility.service';
import { FacilityAPIService } from 'src/app/services/requests/facility-api.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { profileComponents } from 'src/app/utils/constants';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
    selector: 'app-facility-contact-details',
    templateUrl: './facility-contact-details.component.html',
})
export class FacilityContactDetailsComponent implements OnInit, OnDestroy {
    public noInformation: boolean = false;
    public facilityDetail?: Facility;
    public showPayFacility = false;
    public noInformationMessage: string = '';
    private unsubscribe$ = new Subject<void>();

    constructor(
        private _ngxSpinnerService: NgxSpinnerService,
        private _breadcrumbService: BreadcrumbService,
        private _payToFacilityService: PayToFacilityService,
        private _router: Router,
        private _facilityApi: FacilityAPIService,
        private _activatedRoute: ActivatedRoute,
        private _securityService: SecurityService
    ) {
        this._breadcrumbService.set('@facility-detail', 'Contact Detalils');
    }

    ngOnInit(): void {
        this.fetchFacilityDetail();
        if (this._securityService.verifyComponentsSecurity(profileComponents.newPaymentFacilityReferences)) {
            this.showPayFacility = true;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private fetchFacilityDetail(): void {
        this._ngxSpinnerService.show();
        this._activatedRoute.params
            .pipe(
                map((params) => Number(params['id'])),
                switchMap((id) => {
                    if (!isNaN(id)) {
                        return this._facilityApi.getFacilityDetailsRequest(id.toString());
                    } else {
                        return of<Facility | null>(null);
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (result: Facility | null) => {
                    if (result) {
                        this.facilityDetail = result;
                        this.noInformation = !result;
                        this._ngxSpinnerService.hide();
                    } else {
                        this._router.navigate(['/admin/facilityContacts']);
                    }
                },
                error: () => {
                    this._ngxSpinnerService.hide();
                    this.handleError();
                },
            });
    }

    private handleError(): void {
        this.noInformation = true;
        this.noInformationMessage = 'Something went wrong, try again later';
    }

    selectFacility(): void {
        this._payToFacilityService.payToFacility(this.facilityDetail);
    }
}
