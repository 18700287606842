import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { RestService } from '../services/rest.service';
import { PaymentFluxService } from '../services/utils/payment-flux.service';

export class DynamicEngine {
    private _dynamicForm!: FormGroup;

    constructor(
        private _restService: RestService,
        private _paymentFluxService: PaymentFluxService
    ) {}

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    setFormBuilder(dynamicFormName: string, fields: any[]): FormGroup<any> {
        this._dynamicForm = new FormGroup({});
        this._dynamicForm.addControl(dynamicFormName, new FormGroup({}));
        const dynamicValues: any = this._paymentFluxService.getDynamicValues();
        const group: FormGroup<any> = this._dynamicForm.get(dynamicFormName) as FormGroup;
        fields.forEach((item: any): void => {
            if (item.type === 'file') {
                const currentDynamicKey = dynamicValues
                    ? Object.keys(dynamicValues).find((key: any) => key === item.id.toString())
                    : null;
                const currentDynamicValue = currentDynamicKey ? dynamicValues[currentDynamicKey] : '';
                group.addControl(
                    item.name,
                    new FormControl({ value: currentDynamicValue, disabled: item.disabled }, this._setValidations(item))
                );
            } else {
                const currentDynamicKey = dynamicValues
                    ? Object.keys(dynamicValues).find((key: any) => key === item.id.toString())
                    : null;
                const currentDynamicValue = currentDynamicKey ? dynamicValues[currentDynamicKey] : '';
                group.addControl(
                    item.name,
                    new FormControl({ value: currentDynamicValue, disabled: item.disabled }, this._setValidations(item))
                );
            }
        });
        return this._dynamicForm;
    }

    /**
     * @method _setValidations()
     * @param (item: any)
     * @description Set the validators for each form control
     */

    private _setValidations(item: any): ValidatorFn | null {
        if (Object.keys(item.validations).length > 0) {
            const required = item?.validations?.required?.value ? Validators.required : null;
            const minLength: ValidatorFn | null = item?.validations?.minLength
                ? Validators.minLength(item.validations.minLength.value)
                : null;
            const maxLength: ValidatorFn | null = item?.validations?.maxLength
                ? Validators.maxLength(item.validations.maxLength.value)
                : null;
            const pattern: ValidatorFn | null = item?.validations?.pattern
                ? CustomValidators.regex(new RegExp(item.validations.pattern.value))
                : null;
            return Validators.compose([required, minLength, maxLength, pattern]);
        } else {
            return null;
        }
    }

    /**
     * @method setDynamicColumns()
     * @param (url: string)
     * @param (params: any)
     * @description Set the columns ID that we are going to create
     */

    setDynamicColumns(url: string, params: any): void {
        this._restService.get(url, params).then((result: any): void => {
            return result;
        });
    }

    /**
     * @method setDynamicColumnsName()
     * @param (url: string)
     * @param (params: any)
     * @description Set the columns NAMES that we are going to create
     */

    setDynamicColumnsName(url: string, params: any): void {
        this._restService.get(url, params).then((result: any): void => {
            return result;
        });
    }

    /**
     * @method setDynamicColumnsPaymentsValues()
     * @param (url: string)
     * @param (params: any)
     * @description Set the columns VALUES that we are going to create
     */

    setDynamicColumnsPaymentsValues(url: string, params: any): void {
        this._restService.get(url, params).then((result: any): void => {
            return result;
        });
    }
}
