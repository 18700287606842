import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CSCreditService } from 'src/app/services/cs-credit.service';
import { Balance } from 'src/app/utils/cartTypes';

@Component({
    selector: 'app-sprintpay-credit',
    templateUrl: './sprintpay-credit.component.html',
})
export class SprintpayCreditComponent implements OnInit, OnDestroy {
    public credit: Balance = {};
    public isLoading: boolean = false;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private _csCreditService: CSCreditService) {}

    ngOnInit(): void {
        this.getCSCreditBalance();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getCSCreditBalance(): void {
        this._csCreditService
            .getIsProcessing$()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (isProcessing) => {
                    this.isLoading = isProcessing;

                    const creditBalance = this._csCreditService.instant_cs_credit_amount;

                    if (!this.isLoading && creditBalance) {
                        this.credit = {
                            availableCredit: creditBalance?.availableCredit,
                            creditLimit: creditBalance?.creditLimit,
                            creditPercentage: Number(creditBalance?.creditPercentage),
                        };
                    }
                },
                error: () => {
                    this.isLoading = false;
                },
            });
    }
}
