import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Renderer2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, take, throwError } from 'rxjs';
import { PaymentsAPIService } from 'src/app/services/requests/payments-api.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { FileHandlerService } from 'src/app/services/utils/file-handler.service';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { RestService } from '../../../../services/rest.service';
import { CustomerService } from '../../../../services/utils/customer-handler.service';
import { SessionService } from '../../../../services/utils/session.service';

@Component({
    selector: 'app-payment-confirmation',
    templateUrl: './payment-confirmation.component.html',
})
export class PaymentConfirmationComponent implements OnInit {
    @Input() payResult: any;

    invoicesPaid: any[];
    requestPaid: any[];
    customer: any;
    readonly paymentMethod: string;
    private readonly _cartUrl: string;
    private readonly _invoiceUrl: string;

    constructor(
        private _customerService: CustomerService,
        private _restService: RestService,
        private _sessionService: SessionService,
        private _renderer: Renderer2,
        private _domSanitizer: DomSanitizer,
        private _paymentsAPIService: PaymentsAPIService,
        private _fileHandlerService: FileHandlerService,
        private _errorHandlerService: ErrorHandlerService,
        private _ngxSpinnerService: NgxSpinnerService
    ) {
        this._cartUrl = environment.uris.method.cart;
        this._invoiceUrl = environment.uris.method.invoices;
        this.customer = this._customerService.getCustomer();
        const paymentMethodApplied = this._sessionService.getElement('paymentMethodApplied');
        this.paymentMethod = paymentMethodApplied?.paymentType;
        this.invoicesPaid = [];
        this.requestPaid = [];
    }

    ngOnInit(): void {
        this.invoicesPaid = this._sessionService.getElement('open_invoice');
        this.requestPaid = this._sessionService.getElement('payment_request');
    }

    /**
     * @method downloadExcel()
     * @description
     */

    downloadExcel(): void {
        const url: string = `${this._invoiceUrl}/downloadInvoicesByConfirmationId?confirmationId=${this.payResult.transactionId}&downloadFormat=XLSX`;
        this._ngxSpinnerService.show();
        this._restService
            .getFiles(url, {})
            .then((result: any): void => {
                const $link = this._renderer.createElement('a');
                const file: Blob = new Blob([result], { type: result.type });
                const url: any = this._domSanitizer.sanitize(
                    SecurityContext.URL,
                    this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                );
                this._renderer.setAttribute($link, 'href', url);
                this._renderer.setAttribute($link, 'download', 'Statement-' + this.payResult.transactionId + '.xlsx');
                $link.click();
                window.URL.revokeObjectURL(url);
            })
            .finally(() => {
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method getPaymentReceipt()
     * @description
     */

    getPaymentReceipt(): void {
        const url: string = this._cartUrl + '/receiptByInvoices';
        let paymentIds = this.payResult.completedPayments.map((item: any) => item.invoice.id);

        this._ngxSpinnerService.show();
        this._restService
            .getFileByPost(url, { invoices: paymentIds, confirmationId: this.payResult.transactionId })
            .then((result: any): void => {
                const $link = this._renderer.createElement('a');
                const file: Blob = new Blob([result], { type: result.type });
                const url: any = this._domSanitizer.sanitize(
                    SecurityContext.URL,
                    this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                );
                const extension = this._restService.getExtension(result.type);
                this._renderer.setAttribute($link, 'href', url);
                this._renderer.setAttribute($link, 'download', 'Receipt-' + this.payResult.transactionId + extension);
                $link.click();
                window.URL.revokeObjectURL(url);
            })
            .finally(() => {
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method getPaymentConfirmation()
     * @description
     */

    getPaymentConfirmation(): void {
        const paymentsIds = this.payResult.completedPayments.map((item: unknown & { id: string }) => item.id);

        this._ngxSpinnerService.show();
        this._paymentsAPIService
            .downloadPaymentConfirmation(paymentsIds.join(', '))
            .pipe(
                take(1),
                finalize(() => this._ngxSpinnerService.hide())
            )
            .subscribe({
                next: (file) => {
                    const extension = this._restService.getExtension(file.type);
                    const fileName =
                        paymentsIds.length > 1
                            ? 'Payments Confirmation' + extension
                            : 'Payment Confirmation - ' + paymentsIds[0] + extension;

                    this._fileHandlerService.handleDownloadFile(file, fileName);
                },
                error: async (error: HttpErrorResponse) => {
                    let text = await new Response(error.error).text();
                    text = this._errorHandlerService.errorMsg(JSON.parse(text));

                    Swal.fire({
                        title: 'Oops...',
                        text,
                        icon: 'error',
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                },
            });
    }

    /**
     * @method downloadInvoices()
     * @description
     */

    downloadInvoices(): void {
        let invoicesIds: any[] = [];
        this._ngxSpinnerService.show();
        this.payResult.completedPayments.map((item: any) => {
            invoicesIds.push(item.invoice.id);
        });
        const url: string = `${this._invoiceUrl}/downloadInvoices?invoicesIds=${invoicesIds.join(
            ','
        )}&downloadFormat=PDF`;
        this._restService
            .getFile(url, {})
            .pipe(
                take(1),
                finalize(() => this._ngxSpinnerService.hide())
            )
            .subscribe({
                next: (result: any) => {
                    const $link: any = this._renderer.createElement('a');
                    const file: Blob = new Blob([result], { type: result.type });
                    const url: any = this._domSanitizer.sanitize(
                        SecurityContext.URL,
                        this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                    );
                    const extension = this._restService.getExtension(result.type);
                    this._renderer.setAttribute($link, 'href', url);
                    this._renderer.setAttribute(
                        $link,
                        'download',
                        invoicesIds.length > 1 ? 'Invoices' + extension : 'INV' + invoicesIds[0] + extension
                    );
                    $link.click();
                    window.URL.revokeObjectURL(url);
                },
                error: (error: Error) => {
                    throwError(() => error);
                },
            });
    }
}
