import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { Locations } from 'src/app/models/utils/locations.model';
import { FacilityService } from 'src/app/services/facility.service';
import { DynamicFormComponent } from 'src/app/standalone-components/dynamic-form/dynamic-form.component';
import { Dynamic } from 'src/app/utils/companies/dynamic';
import { Domains, paymentTypesDSV } from 'src/app/utils/constants';
import { ErrorMatcher } from 'src/app/utils/errorMatcher';
import { sortPaymentTypes } from 'src/app/utils/ordered-data';
import { StepperSteps } from 'src/app/utils/stepperTypes';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { RestService } from '../../../../../../../services/rest.service';
import { CustomerService } from '../../../../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { PaymentDetailService } from '../../payment-detail.service';
import { StepperService } from '../../services/stepper.service';

@Component({
    selector: 'app-company-reference',
    templateUrl: './company-reference.component.html',
})
export class CompanyReferenceComponent implements OnInit {
    hasExternalLookup: boolean = false;
    companyReferenceForm!: FormGroup;
    matcher: ErrorMatcher;
    onEdition: boolean;
    company: any;
    paymentMethodTypes: any[];
    typeOptions!: string[];
    urlNextStep: string;
    dynamicFormValid: boolean;
    locationAssigned: any;
    locations: Locations[];
    private _dynamicObject: any;
    private _dynamicFormValue: any;
    private _dynamicFieldsSection: boolean;
    private readonly _currentPayment: any;
    private readonly _paymentsUrl: string;
    private readonly _approvalLevels: any;

    @ViewChild(DynamicFormComponent) private _dynamicFormComponent?: DynamicFormComponent;

    constructor(
        private _restService: RestService,
        private _customerService: CustomerService,
        private _paymentFluxService: PaymentFluxService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _paymentDetailService: PaymentDetailService,
        private _stepperService: StepperService,
        private _formBuilder: FormBuilder,
        private _facilityService: FacilityService
    ) {
        this._dynamicObject = {};
        this._dynamicFieldsSection = false;
        this.dynamicFormValid = false;
        const customer = this._customerService.getCustomer();
        this.company = customer?.approvalLevels?.company || '';
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.onEdition = !!(this._currentPayment && this._currentPayment.redirectRoute);
        this._paymentsUrl = environment.uris.method.payments;
        this.matcher = new ErrorMatcher();
        this.paymentMethodTypes = [];
        this._approvalLevels = customer.approvalLevels
            ? customer.approvalLevels.company.paymentReference.map((item: any) => {
                  if (item.name === 'Type') {
                      return item;
                  }
              })
            : '';
        this.hasExternalLookup = !!this._currentPayment?.facility?.externalLookup;
        const facility = this._currentPayment.facility;
        this._facilityService
            .getPaymentTypes(facility.id)
            .pipe(take(1))
            .subscribe({
                next: (paymentTypesResult) => {
                    if (paymentTypesResult.length) {
                        this.typeOptions = sortPaymentTypes(paymentTypesResult);
                    } else {
                        this.typeOptions = this._customerService.isUserDomain(Domains.DSV)
                            ? JSON.parse(JSON.stringify(paymentTypesDSV))
                            : Dynamic.paymentTypesDynamic;
                    }
                },
            });
        this.locationAssigned =
            customer.approvalLevels && customer.approvalLevels.company
                ? customer.approvalLevels.company.firstLocation
                : null;
        this.locations = [];
        this.urlNextStep =
            this._currentPayment && this._currentPayment.redirectRoute
                ? this._currentPayment.redirectRoute
                : this.company.name && !this.hasExternalLookup
                  ? '/admin/facilityPayments/newPayment/flux/paymentDetails'
                  : this.company.name && this.hasExternalLookup
                    ? '/admin/facilityPayments/newPayment/codafacility/paymentDetails'
                    : '/admin/facilityPayments/newPayment/flux/customerReference';
    }

    ngOnInit(): void {
        this._getPaymentAmount(this._approvalLevels);
        this._setFromBuilder();
        if (this.locationAssigned) {
            this.companyReferenceForm.get('locationId')!.setValue(this.locationAssigned.id);
            this.companyReferenceForm.get('locationName')!.setValue(this.locationAssigned.name);
        } else {
            this.getLocations();
        }
        if (
            this._currentPayment &&
            this._currentPayment[`${encodeURIComponent(this.company.name.toLowerCase())}CustomerReference`]
        ) {
            this.companyReferenceForm.patchValue(
                this._currentPayment[`${encodeURIComponent(this.company.name.toLowerCase())}CustomerReference`]
            );
            this.type && !this.hasExternalLookup ? this.type.setValidators([Validators.required]) : '';
        }
        if (this.paymentMethodTypes.length <= 1) {
            this.paymentAmount.setValue(this.paymentMethodTypes[0]);
        }
    }

    /**
     * @method paymentAmount()
     * @description: Convenience getter for easy access to form fields
     */

    get paymentAmount(): FormGroup {
        return this.companyReferenceForm.get('paymentAmount') as FormGroup;
    }

    /**
     * @method type()
     * @description: Convenience getter for easy access to form fields
     */

    get type(): FormGroup {
        return this.companyReferenceForm.get('type') as FormGroup;
    }

    /**
     * @method locationId()
     * @description: Convenience getter for easy access to form fields
     */

    get locationId(): FormGroup {
        return this.companyReferenceForm.get('locationId') as FormGroup;
    }

    /**
     * @method _getPaymentAmount()
     * @param (approvalLevels: any)
     * @description Convenience setter for easy access to form fields
     */

    private _getPaymentAmount(approvalLevels: any): void {
        this.paymentMethodTypes = approvalLevels[0].paymentReferenceLookups.map((item: any) => {
            return item.type;
        });
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.label;
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.companyReferenceForm = this._formBuilder.group({
            paymentAmount: new FormControl<string | null>(null, [Validators.required]),
            type: new FormControl<string | null>(null, [Validators.required]),
            locationId: new FormControl<string | null>(null, [Validators.required]),
            locationName: new FormControl<string | null>(null, [Validators.required]),
        });
        this.companyReferenceForm = this._paymentDetailService.validateCompanyForm(this.companyReferenceForm);
    }

    /**
     * @method getValues()
     * @description
     */
    getValues(): void {
        if (this.companyReferenceForm.invalid || !this.dynamicFormValid) {
            this.companyReferenceForm.markAllAsTouched();
            if (this._dynamicFormComponent?.paymentForm) {
                this._dynamicFormComponent?.paymentForm.markAllAsTouched();
            }
        } else {
            if (
                this._currentPayment.id &&
                this._currentPayment[encodeURIComponent(this.company.name.trim().toLowerCase()) + 'CustomerReference']
                    .paymentAmount !== this.companyReferenceForm.get('paymentAmount')?.value
            ) {
                this._currentPayment.externalData
                    ? (this._currentPayment.externalData.type = this.companyReferenceForm.get('paymentAmount')!.value)
                    : '';
                this._paymentFluxService.setData(
                    encodeURIComponent(this.company.name.trim().toLowerCase()) + 'CustomerReference',
                    this.companyReferenceForm.getRawValue()
                );
                Swal.fire({
                    title: 'Important !',
                    icon: 'info',
                    text: 'You have changed some key information! Please review your request before continuing.',
                    showConfirmButton: true,
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#14bb9c',
                    allowOutsideClick: false,
                }).then((): void => {
                    this._router.navigate(['/admin/facilityPayments/newPayment/flux/paymentDetails']);
                });
            } else {
                let companyReference = this.companyReferenceForm.getRawValue();
                this._stepperService[StepperSteps.STEP_ZERO].next(true);
                if (this._dynamicFieldsSection) {
                    const dynamicValues = this._paymentFluxService.getDynamicValues();
                    Object.keys(this._dynamicFormValue).forEach((key: any): void => {
                        companyReference[key] = this._dynamicFormValue[key];
                    });
                    this._paymentFluxService.setDynamicValues(Object.assign({}, dynamicValues, this._dynamicObject));
                }
                this._paymentFluxService.setData(
                    encodeURIComponent(this.company.name.trim().toLowerCase()) + 'CustomerReference',
                    companyReference
                );
                this._router.navigate([this.urlNextStep], { relativeTo: this._activatedRoute });
            }
        }
    }

    /**
     * @method dynamicValues()
     * @param (form: any)
     * @description
     */

    dynamicValues(form: any): void {
        if (form.status === 'VALID' || form.status === 'DISABLED') {
            this._dynamicFormValue = form.value;
            this.dynamicFormValid = true;
            this._dynamicObject = form.dynamic;
            const dynamicValues = this._paymentFluxService.getDynamicValues();
            this._paymentFluxService.setDynamicValues(Object.assign({}, dynamicValues, this._dynamicObject));
        } else if (form.status === 'INVALID' || form.status === 'PENDING') {
            this.dynamicFormValid = false;
        }
    }

    /**
     * @method hasDynamicFields()
     * @param (fields: number)
     * @description
     */

    hasDynamicFields(fields: number): void {
        this._dynamicFieldsSection = fields > 0 ? true : false;
        this.dynamicFormValid = !this._dynamicFieldsSection;
    }

    /**
     * @method getLocations()
     * @description
     */

    getLocations(): void {
        const url: string = this._paymentsUrl + '/customer/approval-flow/information';
        this._restService.get<{ locations: Locations[] }>(url, {}).then((result): void => {
            this.locations = result.locations;
        });
    }

    /**
     * @method getLocations()
     * @description
     */

    locationSelected(item: any, event: any): void {
        if (event.isUserInput) {
            this.companyReferenceForm.get('locationName')!.setValue(item.name);
        }
    }
}
