import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, finalize, take, takeUntil } from 'rxjs';
import { UploadFileHandlerService } from 'src/app/services/utils/upload-file-handler.service';
import { FileService } from 'src/app/standalone-components/file-handler/services/file.service';
import Swal from 'sweetalert2';
import { ErrorHandlerService } from '../../services/utils/error-handler.service';
import { FileHandlerComponent } from '../file-handler/file-handler.component';

@Component({
    selector: 'app-upload-file',
    standalone: true,
    imports: [CommonModule, FileHandlerComponent],
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit, OnDestroy {
    @Input() set removingFile(removed: boolean) {
        removed && this.removeFile();
    }
    @Input() downloadTemplateURL!: string;
    @Input() templateName!: string;
    @Input() progressBarPercentage!: number;
    @Output() fileEvent: EventEmitter<any> = new EventEmitter();

    selectedFile!: File | null;
    selectedFileSize: number;
    removedFile!: boolean;
    private unsubscribe$: Subject<void>;
    constructor(
        private _uploadFileService: UploadFileHandlerService,
        private _errorHandlerService: ErrorHandlerService,
        private _fileService: FileService,
        private _ngxSpinnerService: NgxSpinnerService
    ) {
        this.selectedFileSize = 0;
        this.unsubscribe$ = new Subject<void>();
    }

    ngOnInit(): void {
        this.subscribeFiles();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeFiles(): void {
        this._fileService
            .getFiles()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (file: File | null): void => {
                    this.selectedFile = file;
                    this.selectedFileSize = file ? Math.round(file.size / 1024) : 0;
                    this.fileEvent.emit(file);
                },
            });
    }

    /**
     * @method downLoadExcel()
     * @description download excel template
     */
    downLoadExcel(): void {
        this._ngxSpinnerService.show();
        this._uploadFileService
            .downloadTemplate(this.downloadTemplateURL, this.templateName)
            .pipe(
                take(1),
                finalize(() => {
                    this._ngxSpinnerService.hide();
                })
            )
            .subscribe({
                error: (error: any) => {
                    let template: string = this._errorHandlerService.errorTemplate(error.error);
                    Swal.fire({
                        html: `${template}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Cancel',
                        allowOutsideClick: false,
                    });
                },
            });
    }
    /**
     * @method removeFile()
     * @description removed the uploaded files
     */
    removeFile(): void {
        this.selectedFile = null;
        this.removedFile = true;
        this.progressBarPercentage = 0;
        this._fileService.setFile(null);
    }
}
