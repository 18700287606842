import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { PaymentCart } from '@cargos/sprintpay-models';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, map, of, switchMap, takeUntil } from 'rxjs';
import { PaymentToRequestEditService } from 'src/app/services/payment-to-request-edit.service';
import { RestService } from 'src/app/services/rest.service';
import { CartService } from 'src/app/services/utils/cart.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { companyName, sprintPaySource } from 'src/app/utils/constants';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { PromoCodeByAWBService } from '../../services/promo-code.service';

@Component({
    selector: 'app-awb-split',
    templateUrl: './awb-split.component.html',
})
export class AwbSplitComponent implements OnInit, AfterViewInit, OnDestroy {
    public nonReservationFee = 0;
    public isAwbPaidWithPromoCode = false;
    public uniqueRequestor: boolean;
    public companyName: string;
    public sprintPaySource: string = sprintPaySource;
    public hasExternalLookup: boolean = false;
    private readonly _cartUrl: string;
    private unsubscribe$ = new Subject<void>();
    public customsFees: { customsFee: PaymentCart['customsFee']; customsFeeLabel: PaymentCart['customsFeeLabel'] }[] =
        [];
    public isOpenCloseDropDown = false;

    @Input() request: any;
    @Input() awb: any;
    @Input() facilityName: any;
    @Output() returnEmptyCart: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private _customerService: CustomerService,
        private _router: Router,
        private _changeDetectorRef: ChangeDetectorRef,
        private _cartService: CartService,
        private _restService: RestService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _paymentFluxService: PaymentFluxService,
        private _errorHandlerService: ErrorHandlerService,
        private _paymentToRequestEditService: PaymentToRequestEditService,
        private promoCodesService: PromoCodeByAWBService
    ) {
        this._cartUrl = environment.uris.method.cart;
        this.uniqueRequestor = !!this._customerService.getCustomer().userType.includes('UNIQUE_REQUESTOR');
        this.companyName = this._customerService.getCompanyName();
    }

    ngOnInit(): void {
        this.subscribeNonReservationFee();
        this.subscribeIsAWBPaidWithPromoCode();
        this.getFees();
    }

    ngAfterViewInit(): void {
        this.hasExternalLookup = !!this.request?.filter((item: any) => item.facility.externalLookup)?.length;
        this._changeDetectorRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeNonReservationFee(): void {
        this.promoCodesService
            .getPromoCodeValueByAWB$(this.request.awb || '')
            .pipe(
                switchMap((nonReservationFee) => {
                    if (!!nonReservationFee) {
                        return this.subscribeIsAWBPaidWithPromoCode().pipe(
                            map((isAwbPaidWithPromoCode) => ({
                                isAwbPaidWithPromoCode,
                                nonReservationFee,
                            }))
                        );
                    }

                    return of(null);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (promoCodeInfo) => {
                    this.nonReservationFee = promoCodeInfo?.nonReservationFee as number;
                    this.isAwbPaidWithPromoCode = promoCodeInfo?.isAwbPaidWithPromoCode as boolean;
                },
            });
    }

    subscribeIsAWBPaidWithPromoCode(): Observable<boolean> {
        return this.promoCodesService.isAwbPaidWithPromoCode$(this.request.awb || '');
    }

    /**
     * @method deletePaymentRequest()
     * @param (paymentRequest: any)
     * @description
     */

    deletePaymentRequest(paymentRequest: any): void {
        this._ngxSpinnerService.show();

        const url: string = `${this._cartUrl}/removePaymentRequest?id=${paymentRequest.id}`;

        this._restService
            .delete(url)
            .then((result: any) => {
                this._ngxSpinnerService.hide();
                this._cartService.setUpdate('DELETE');

                if (result.length === 0) {
                    this._cartService.setUpdate('Delete');

                    this.returnEmptyCart.emit(true);
                }
            })
            .catch((error: any): void => {
                this._ngxSpinnerService.hide();
                Swal.fire({
                    html: `${this._errorHandlerService.errorMsg(error.error)}`,
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    allowOutsideClick: false,
                });
            });
    }

    /**
     * @method editPaymentRequest()
     * @param (paymentRequest: any)
     * @description
     */

    editPaymentRequest(paymentRequest: any): void {
        const source = this.sprintPaySource === paymentRequest.source?.name.toLowerCase();
        if (source) {
            let redirect: string =
                this.companyName && this.uniqueRequestor
                    ? '/admin/facilityPayments/newPayment/flux/summary'
                    : '/admin/facilityPayments/newPayment/flux/paymentDetails';
            if (paymentRequest.facility && paymentRequest.facility.notesForClients) {
                paymentRequest.facility.notesForClients = JSON.stringify(paymentRequest.facility.notesForClients);
            }
            let currentPayment: any = this._paymentToRequestEditService.getPaymentRequestDataCart(
                paymentRequest,
                this.companyName ? redirect : '',
                this.companyName !== '' ? this.companyName : ''
            );
            this.companyName === companyName.schenker ? (currentPayment['step'] = 3) : '';
            currentPayment['fromCart'] = true;
            this._paymentFluxService.setDynamicValues(currentPayment.dynamicValues);
            this._paymentFluxService.setCurrentPayment(currentPayment);
            this._router.navigate([redirect]);
        }
    }

    getFees(): void {
        if (Array.isArray(this.request) && this.request.length > 0) {
            this.request.forEach((item: any) => {
                this.customsFees.push({
                    customsFee: item.customsFee,
                    customsFeeLabel: item.customsFeeLabel,
                });
            });
            return;
        }

        this.customsFees.push({
            customsFee: this.request?.customsFee,
            customsFeeLabel: this.request?.customsFeeLabel,
        });
    }

    onOpenCloseDropDown(): void {
        this.isOpenCloseDropDown = !this.isOpenCloseDropDown;
    }
}
