import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SummaryDetailService {
    private _paymentType = new BehaviorSubject<string | null>(null);

    setPaymentType(paymentType: string): void {
        return this._paymentType.next(paymentType);
    }

    getPaymentType(): Observable<string | null> {
        return this._paymentType.asObservable();
    }
}
