import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import {
    GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
    GOOGLE_ANALYTICS_ROUTER_INITIALIZER_PROVIDER,
    GOOGLE_ANALYTICS_SETTINGS_TOKEN,
} from './google-analytics.providers';
import { GoogleAnalyticsConfig } from './types';

@NgModule({})
export class GoogleAnalyticsModule {
    constructor(@Optional() @SkipSelf() parentModule?: GoogleAnalyticsModule) {
        if (parentModule) {
            throw new Error('GoogleAnalyticsModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(config?: GoogleAnalyticsConfig): ModuleWithProviders<GoogleAnalyticsModule> {
        return {
            ngModule: GoogleAnalyticsModule,
            providers: [
                { provide: GOOGLE_ANALYTICS_SETTINGS_TOKEN, useValue: config },
                GOOGLE_ANALYTICS_INITIALIZER_PROVIDER,
                GOOGLE_ANALYTICS_ROUTER_INITIALIZER_PROVIDER,
            ],
        };
    }
}
