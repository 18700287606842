import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomersType } from '@cargos/sprintpay-models';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { ByPortGuard } from 'src/app/guards/byPort.guard';

// COMPONENTS

import { CustomerOnHoldGuard } from 'src/app/guards/customer-on-hold.guard';
import { FacilityPaymentsComponent } from './facility-payments.component';

const routes: Routes = [
    {
        path: '',
        component: FacilityPaymentsComponent,
        data: { breadcrumb: { alias: 'facility-payments', disable: true } },
        children: [
            {
                path: '',
                redirectTo: 'newPayment',
                pathMatch: 'full',
            },
            {
                path: 'newPayment',
                loadChildren: () => import('./new-payment/new-payment.module').then((m) => m.NewPaymentModule),
                canLoad: [NgxPermissionsGuard],
                canActivate: [CustomerOnHoldGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, CustomersType.GUEST, 'BUSINESS'],
                        redirectTo: '/login',
                    },
                },
            },
            {
                path: 'newPayment/:id',
                loadChildren: () => import('./new-payment/new-payment.module').then((m) => m.NewPaymentModule),
            },
            {
                path: 'paymentHistory',
                loadChildren: () =>
                    import('./payment-history/payment-history.module').then((m) => m.PaymentHistoryModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },
            {
                path: 'paymentMethods',
                loadChildren: () =>
                    import('./payment-methods/payment-methods.module').then((m) => m.PaymentMethodsModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },
            {
                path: 'pendingApproval',
                loadChildren: () =>
                    import('./pending-approval/pending-approval.module').then((m) => m.PendingApprovalModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },
            {
                path: 'rejectedPayment',
                loadChildren: () =>
                    import('./rejected-payment/rejected-payment.module').then((m) => m.RejectedPaymentModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },
            {
                path: 'voidedPayments',
                loadChildren: () =>
                    import('./voided-payments/voided-payments.module').then((m) => m.VoidedPaymentsModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },
            {
                path: 'uploadRequest',
                loadChildren: () => import('./upload-request/upload-request.module').then((m) => m.UploadRequestModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },
            {
                path: 'uploadFileByPort',
                canActivate: [ByPortGuard],
                loadChildren: () =>
                    import('./upload-file-by-port/upload-file-by-port.module').then((m) => m.UploadFileByPortModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                        except: [CustomersType.GUEST],
                        redirectTo: '/admin/facilityPayments/newPayment',
                    },
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FacilityPaymentsRoutingModule {}
