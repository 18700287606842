<!--START: VIN EDOC NEXTGEN -->
<form class="row" [formGroup]="paymentForm">
    <div class="form-group">
        <mat-label class="mb-3 d-block"><strong>Is your VIN Edoc’d in Nextgen?</strong></mat-label>
        <mat-radio-group
            id="eiReferenceFileNumber"
            class="d-flex flex-column mb-4"
            name="eiReferenceFileNumber"
            formControlName="eiReferenceFileNumber"
            (ngModelChange)="returnVinEdocNextgenValue(paymentForm)"
        >
            <mat-radio-button id="true" class="d-inline-block mb-2" color="primary" value="true">Yes</mat-radio-button>
            <mat-radio-button id="null" class="d-inline-block" color="primary" value="null"
                >N/A - Airline Fee</mat-radio-button
            >
        </mat-radio-group>
        <mat-error
            *ngIf="paymentForm.controls['eiReferenceFileNumber'].invalid && (paymentForm.controls['eiReferenceFileNumber'].dirty || paymentForm.controls['eiReferenceFileNumber'].touched)"
        >
            <mat-error *ngIf="paymentForm.controls['eiReferenceFileNumber'].hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
        </mat-error>
    </div>
</form>
<!--END: VIN EDOC NEXTGEN -->
