import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { ForgotPasswordRoutingModule } from './forgot-password-routing.module';

//COMPONENTS

import { ForgotPasswordComponent } from './forgot-password.component';

//MODULES
import { TwoFactorModule } from 'src/app/modules/two-factor/two-factor.module';
import { FormPasswordComponent } from 'src/app/standalone-components/form-password/form-password.component';
import { TermOfUseComponent } from 'src/app/standalone-components/term-of-use/term-of-use.component';
import { VerificationMethodComponent } from 'src/app/standalone-components/verification-method/verification-method.component';
import { SharedModule } from '../../../../shared/shared.module';
import { PasswordFormComponent } from '../password-form/password-form.component';
import { SetPasswordComponent } from '../set-password/set-password.component';

@NgModule({
    declarations: [ForgotPasswordComponent, PasswordFormComponent, SetPasswordComponent],
    imports: [
        CommonModule,
        ForgotPasswordRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        TwoFactorModule,
        FormPasswordComponent,
        TermOfUseComponent,
        VerificationMethodComponent,
    ],
})
export class ForgotPasswordModule {
    constructor() {}
}
