import { Injectable } from '@angular/core';
import { PaymentCart, PromoCode, PromoCodeListResponse } from '@cargos/sprintpay-models';
import { PromoCodeService } from '@cargos/sprintpay-services';
import { BehaviorSubject, Observable, distinctUntilChanged, forkJoin, map, switchMap, take } from 'rxjs';
import { CartService } from 'src/app/services/utils/cart.service';

export interface AwbPromoCode {
    promoCodeId: string;
    awb: string;
    awb2: string;
}

@Injectable({
    providedIn: 'root',
})
export class PromoCodeByAWBService {
    private promoCode = new BehaviorSubject<AwbPromoCode[]>([]);
    private arrayPromoCode: AwbPromoCode[] = [];

    constructor(
        private promoCodeService: PromoCodeService,
        private cartBillService: CartService
    ) {}

    resetPromoCode(): void {
        this.arrayPromoCode = [];
        this.promoCode.next([]);
    }

    setPromoCodesApplied(awb: string, promoCodeId: string, awb2: string): void {
        const objIndex = this.arrayPromoCode.findIndex((obj) => obj.awb == awb);

        if (objIndex === -1) {
            this.arrayPromoCode.push({ awb, promoCodeId, awb2 });
        } else {
            this.arrayPromoCode[objIndex].promoCodeId = promoCodeId;
        }
        this.promoCode.next(this.arrayPromoCode);
    }

    getPromoCodeValueByAWB$(awb: string): Observable<number> {
        return this.cartBillService.getCartFilterByAWB$(awb).pipe(
            map((paymentCarts: PaymentCart[]) => {
                return paymentCarts?.find((paymentCart) => paymentCart.nonReservationFee)?.nonReservationFee as number;
            })
        );
    }

    getPromoCodeValueByAWB(awb: string): number {
        return this.cartBillService.getCartFilterByAWB(awb).find((paymentCart) => paymentCart.nonReservationFee)
            ?.nonReservationFee as number;
    }

    getAllPromoCodeApplied(): AwbPromoCode[] {
        return this.arrayPromoCode.filter((promoCode) => promoCode.promoCodeId);
    }

    getAllPromoCodeApplied$(): Observable<AwbPromoCode[]> {
        return this.promoCode.asObservable();
    }

    isAwbPaidWithPromoCode$(awb: string): Observable<boolean> {
        return this.getAllPromoCodeApplied$().pipe(
            map((allPromoCode: AwbPromoCode[]) => {
                return allPromoCode?.some((promoCode) => promoCode.awb === awb);
            })
        );
    }

    isAwbPaidWithPromoCode(awb: string): boolean {
        return this.arrayPromoCode.some((promoCode) => promoCode.awb === awb);
    }

    removePromoCode(awb: string, promoCodeId: string): void {
        const objIndex = this.arrayPromoCode.findIndex((obj) => obj.promoCodeId == promoCodeId && obj.awb == awb);

        if (objIndex > -1) {
            this.arrayPromoCode.splice(objIndex, 1);
        }

        this.promoCode.next(this.arrayPromoCode);
    }

    findPromoCode(promoCodeId: string): boolean {
        return this.arrayPromoCode.some((promoCode) => promoCodeId === promoCode.promoCodeId);
    }

    getPromoCodeByAWB(awb: string): string | undefined {
        const promoCodes = this.getAllPromoCodeApplied();

        return promoCodes.find((promoCodes) => {
            return promoCodes.awb === awb;
        })?.promoCodeId;
    }

    validatePromoCode(promoCodeId: string): Observable<PromoCode> {
        return this.promoCodeService.validatePromoCode(promoCodeId);
    }

    getPromoCodesByUser(): Observable<PromoCodeListResponse[]> {
        return this.promoCodeService.getPromoCodesByUser();
    }

    getAllNonReservationFee$(): Observable<number> {
        return this.getAllPromoCodeApplied$().pipe(
            switchMap(() => {
                return this.cartBillService.getCart$().pipe(
                    map((paymentCarts: PaymentCart[]) => {
                        return paymentCarts.filter((paymentCart) => paymentCart.nonReservationFee);
                    }),
                    switchMap((paymentCarts) => {
                        return this.isAllAwbPaidWithPromoCode(paymentCarts);
                    }),
                    map((allAwbWithPromoCode: boolean[]) => {
                        return allAwbWithPromoCode.filter((response) => response === false || response === undefined);
                    }),
                    map((allAwbWithPromoCode: boolean[]) => {
                        return allAwbWithPromoCode?.length * 8;
                    })
                );
            })
        );
    }

    isAllAwbPaidWithPromoCode(paymentCarts: PaymentCart[]): Observable<boolean[]> {
        return forkJoin(
            paymentCarts.map((paymentCart) => this.isAwbPaidWithPromoCode$(paymentCart.awb as string).pipe(take(1)))
        );
    }

    getNonReservationFeeApplied$(): Observable<number> {
        return this.getAllNonReservationFee$().pipe(distinctUntilChanged());
    }

    getPromoCodesApplied(): Observable<AwbPromoCode[]> {
        return this.getAllPromoCodeApplied$();
    }
}
