import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// COMPONENTS

import { FormSignupComponent } from './form-signup.component';

const routes: Routes = [
    {
        path: '',
        title: 'SprintPay | Account type company',
        component: FormSignupComponent,
        data: { breadcrumb: { alias: 'account-type-company' } },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FormSignupRoutingModule {}
