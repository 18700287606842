import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { TwoFactorService } from 'src/app/services/requests/two-factor.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { AuthenticationFluxService } from '../authentication-flux/authentication-flux.service';
import { ResponseMFA, TypeModuleMFA } from './models/two-factor-models';

@Component({
    selector: 'app-two-factor',
    templateUrl: './two-factor.component.html',
    styleUrls: ['./two-factor.component.scss'],
    providers: [TwoFactorService],
})
export class TwoFactorComponent implements OnInit, OnDestroy {
    @Input() digits!: number;
    @Input() vericationMethod!: string;
    @Input() module: TypeModuleMFA = 'REGISTRATION';
    @Input() enableChangeVerificationMethod = false;
    @Output() accountCreated: EventEmitter<ResponseMFA> = new EventEmitter();
    @Output() twoFactorClosed: EventEmitter<boolean> = new EventEmitter();

    public contactMask: string;
    public errorMsg!: string;
    public loading = false;
    public maxAttempsExceeded = false;
    private _mfaType: string;
    private contact: string;
    private _activationUuid: string;
    private _mfaUuId!: string;
    private _password!: string;

    constructor(
        private _matDialog: MatDialog,
        private _twoFactorService: TwoFactorService,
        private _errorHandlerService: ErrorHandlerService,
        private _authenticationService: AuthenticationFluxService,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private _router: Router
    ) {
        this._mfaType = data.mfaType.toUpperCase();
        this.contact = data.contact;
        const contactValue: string = data.contact;
        if (data.mfaType !== 'sms') {
            const emailArray: string[] = contactValue.split('@');
            this.contactMask = emailArray[0].replace(/(?<!^).(?!$)/g, '*') + '@' + emailArray[1];
        } else {
            this.contactMask = contactValue.replace(/[0-9](?=[0-9]{2})/g, '*');
        }
        this._activationUuid = data.activationUuid;
        this._password = data.password;
    }

    ngOnInit(): void {
        this.getPinCode();
    }

    ngOnDestroy(): void {
        this.closeDialog();
    }

    /**
     * @method closeDialog()
     * @description Close the dialog in this case menu right sidebar for the activity log
     */

    closeDialog(): void {
        this.errorMsg = '';
        this.twoFactorClosed.emit(true);
        this._matDialog.closeAll();
    }

    closeDialogFromModal(): void {
        if (this.module === 'REGISTRATION') {
            this._router.navigate(['./']);
            this.closeDialog();
        } else {
            this.closeDialog();
        }
    }

    updateVerificationMethod(): void {
        this.closeDialog();
        this._authenticationService.changeVerificationMethod.emit(true);
    }

    getPinCode(): void {
        this._twoFactorService
            .requestMFA(this._mfaType, this.contact, this.module, 'DEFAULT_MFA')
            .pipe(take(1))
            .subscribe({
                next: (response: any) => {
                    this._mfaUuId = response.mfaUuid;
                },
                error: (error: any) => {
                    this.maxAttempsExceeded = error.status === 403;
                    const errors = this._errorHandlerService.errorMsg(error.error);
                    this.errorMsg = errors;
                },
            });
    }

    verify(event: string): void {
        this.errorMsg = '';

        if (this.loading || !event) return;

        this.loading = true;

        this._twoFactorService
            .verifyMFA({
                mfaUuId: this._mfaUuId,
                mfaCode: event,
                activation: this._activationUuid,
                password: this._password,
            })
            .pipe(take(1))
            .subscribe({
                next: (response: ResponseMFA) => {
                    setTimeout(() => {
                        this.accountCreated.emit(response);
                        this._authenticationService.accountCreated.emit(response.isVerified);
                        this.closeDialog();
                    }, 3000);
                },
                error: (error: HttpErrorResponse) => {
                    this.maxAttempsExceeded = error.status === 403;
                    this.loading = false;
                    const errors = this._errorHandlerService.errorMsg(error.error);
                    this.errorMsg = errors;
                },
            });
    }

    eventOnChange(): void {
        this.errorMsg = '';
    }

    eventOnTimeCompleted(isCompleted: boolean): void {
        this.maxAttempsExceeded = !isCompleted;
        this.eventOnChange();
    }
}
