<button (click)="handleChargeAction()" *ngIf="!isOnCart && !isLoading && !isSubmitted" mat-button color="primary">
    {{buttonLabel}}
</button>
<button mat-button *ngIf="isSubmitted && !isLoading" color="error">SUBMITTED</button>
<button mat-button *ngIf="isOnCart && !isLoading" color="error" (click)="removeCharge()">REMOVE FROM CART</button>
<mat-spinner [diameter]="30" matSuffix *ngIf="isLoading"></mat-spinner>
<!-- START: PAYMENT REFUND REQUEST -->
<ng-template #voucherNumberModal>
    <app-voucher-request (voucherNumber)="setVoucherNumber($event)"></app-voucher-request>
</ng-template>
<!-- END: PAYMENT REFUND REQUEST -->
