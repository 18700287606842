import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SessionService } from 'src/app/services/utils/session.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../environments/environment';
import { Table } from '../../../models/ui/table.model';
import { RestService } from '../../../services/rest.service';
import { CustomerService } from '../../../services/utils/customer-handler.service';
import { paginatorSize } from '../../../utils/constants';
import { Sections } from '../../../utils/sections';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
    noInformation: boolean | undefined;
    noInformationMessage: string;
    amTable!: Table[];
    users: [];
    private readonly _customer: any;
    private readonly _companyName: string;
    private readonly _startingLevel: boolean;
    private readonly _searchTermChanged: Subject<string>;
    private readonly _authUrl: string;

    constructor(
        private _sessionService: SessionService,
        private _breadcrumbService: BreadcrumbService,
        private _customerService: CustomerService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _restService: RestService
    ) {
        this._breadcrumbService.set('@users', 'Users');
        this._searchTermChanged = new Subject<string>();
        this.noInformation = undefined;
        this.noInformationMessage = '';
        this._authUrl = environment.uris.method.authentication;
        this._searchTermChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe({
            next: (event: any): void => {
                this.searchUsers(event);
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
        this._customer = this._customerService.getCustomer();
        this._companyName = this._customer?.approvalLevels?.company?.name || '';
        this._startingLevel = !!this._sessionService.getElement('startingLevel');

        this.users = [];
    }

    ngOnInit(): void {
        this.getUsers();
    }

    /**
     * @method _getUsersTableRender()
     * @param (source: any)
     * @param (columns: any)
     * @param (columnsName: any)
     * @param (totalElements: number)
     * @description Render the table using the data that we retrieve from the URL
     */

    private _getUsersTableRender(source: any, columns: any, columnsName: any, totalElements: number): void {
        this.amTable = [
            new Table({
                sorting: true,
                pagination: true,
                filter: true,
                dataSource: source,
                displayedColumns: columns,
                displayedColumnsName: columnsName,
                paginationSizes: paginatorSize,
                totalElements: totalElements,
            }),
        ];
        this._ngxSpinnerService.hide();
    }

    /**
     * @method getUsers()
     * @param (item?: any)
     * @description Generates the URL based on _customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    getUsers(item?: any): void {
        let pageSize: number;
        let currentSize: number;
        if (item && item['paginator'] !== undefined) {
            pageSize = item.paginator.pageSize;
            currentSize = item.paginator.currentPage;
        } else {
            pageSize = item !== undefined ? item.pageSize : paginatorSize[0];
            currentSize = item !== undefined ? item.currentPage : 0;
        }
        const longUrl: string = `${
            this._authUrl
        }/getUsersManagement/?page=${currentSize}&size=${pageSize}&date=${new Date().valueOf()}`;

        this._ngxSpinnerService.show();
        this._getCompleteData(longUrl);
    }

    /**
     * @method search()
     * @param (filter: any)
     * @description
     */

    search(filter: any): void {
        this._searchTermChanged.next(filter);
    }

    /**
     * @method searchUsers()
     * @param (filter: any)
     * @description
     */

    searchUsers(filter: any): void {
        if (filter.value) {
            const pageSize: number =
                filter.paginator.pageSize !== undefined ? filter.paginator.pageSize : paginatorSize[0];
            const currentSize: number = filter.paginator.currentPage !== undefined ? filter.paginator.currentPage : 0;
            const longUrl: string = `${this._authUrl}/searchUserManagement/?searchTerms=${
                filter.value
            }&page=${currentSize}&size=${pageSize}&date=${new Date().valueOf()}`;

            this._ngxSpinnerService.show();
            this._getCompleteData(longUrl, 'search');
        } else {
            this.getUsers(filter);
        }
    }

    /**
     * @method _getCompleteData()
     * @param (url: string)
     * @description Call the APU and return the data
     */

    private _getCompleteData(url: string, type?: string): void {
        this._restService
            .get(url, {})
            .then((result: any): void => {
                let companyNameColumns: string = '';
                let companyNameColumnsName: string = '';
                const companyType: string = this._companyName || this._startingLevel ? this._companyName : 'ordinary';

                Object.keys(Sections.usersSection).forEach((item: any, index: any): void => {
                    if (item === `${companyType}_ColumnsUsers`) {
                        companyNameColumns = Object.values(Sections.usersSection)[index];
                    } else if (item === `${companyType}_ColumnsNameUsers`) {
                        companyNameColumnsName = Object.values(Sections.usersSection)[index];
                    } else if (item === `${companyType}_getCompletedPaymentsUsers`) {
                        if (result && result.content.length !== 0) {
                            this.users = result.content.map((elements: any) => {
                                return Object.values(Sections.usersSection)[index](elements);
                            });
                        } else if (result && result.content.length === 0 && this.users !== undefined) {
                            this.users = [];
                        }
                    }
                });

                this.noInformation = type === 'search' ? false : !(this.users.length > 0);
                this._getUsersTableRender(this.users, companyNameColumns, companyNameColumnsName, result.totalElements);
            })
            .catch((error: any): void => {
                if (this.users !== undefined) {
                    this.noInformationMessage = error?.error?.errors ? error.error.errors : '';
                }
                this.noInformation = true;
                this._ngxSpinnerService.hide();
            });
    }
}
