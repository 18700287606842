<div id="facility_data" class="p-3 mb-0 mb-md-4 d-flex justify-content-between flex-column">
    <div>
        <label [class.facility-label]="facility?.externalLookup">Cargo Facility</label>
        <h6>{{facility?.name ?? ''}}</h6>
        <p>
            {{facility?.address ? facility?.address : ''}} {{facility?.city ? ',&nbsp;' + facility?.city : ''
            }}{{facility?.state ? ',&nbsp;' + facility?.state : ''}} {{facility?.zip ? ',&nbsp;' + facility?.zip : ''}}
        </p>
        <span class="facilities-data--type d-block d-lg-none align-items-center h-100">
            <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'electronic'">
                <mat-icon class="me-2 align-middle">bolt</mat-icon>
                Electronic less than 1 hour
            </ng-container>
            <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'messenger'">
                <mat-icon class="me-2 align-middle">bolt</mat-icon>
                Messenger < 3 hour
            </ng-container>
            <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'fedex'">
                <mat-icon class="me-2 align-middle">bolt</mat-icon>
                FedEx = Next business day by 10:30 AM
            </ng-container>
        </span>
    </div>
    <span class="facilities-data--type d-none d-lg-flex align-items-center h-100">
        <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'electronic'">
            <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
            Electronic less than 1 hour
        </ng-container>
        <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'messenger'">
            <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
            Messenger < 3 hour
        </ng-container>
        <ng-container *ngIf="facility?.deliveryType?.toLowerCase() === 'fedex'">
            <mat-icon class="d-flex align-items-center me-2">bolt</mat-icon>
            FedEx = Next business day by 10:30 AM
        </ng-container>
    </span>
</div>
