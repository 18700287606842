import { Injectable } from '@angular/core';
import { Customer } from '@cargos/sprintpay-models';
import * as moment from 'moment';
import {
    customerReferenceExpeditorsData,
    customerReferenceExpeditorsPattern,
} from 'src/app/utils/facilities/expeditors';
import { Payment } from '../models/payments/payment.model';
import { DHL, customerReferenceDhlData } from './companies/dhl';
import { Dynamic } from './companies/dynamic';
import { Emotrans, customerReferenceEmotrans, customerReferenceEmotransData } from './companies/emotrans';
import { ForwarderAdminUser } from './companies/forwarderAdminUser';
import { Geodis, customerReferenceGeodis, customerReferenceGeodisData } from './companies/geodis';
import { KN, customerReferenceKN, customerReferenceKNData } from './companies/kn';
import { Ordinary } from './companies/ordinary';
import { Rhenus, customerReferenceRhenus, customerReferenceRhenusData } from './companies/rhenus';
import { Schenker, customerReferenceSchenker, customerReferenceSchenkerData } from './companies/schenker';
import { SuperUser } from './companies/superUser';
import { companyName, customerReferenceDSV } from './constants';

@Injectable({ providedIn: 'root' })
export class Sections {
    /*
     * CUSTOMER REFERENCE TITLE LABEL STARTING LEVEL
     * @method customerReferenceStartingLevelLabelTags()
     * @param (company: any)
     * @description
     */

    static customerReferenceStartingLevelLabelTags(company: { company: string; turnOn: boolean }): string {
        let labelCustomerRef: string = 'Customer Reference';
        switch (company.company) {
            case companyName.schenker:
                company.turnOn ? (labelCustomerRef = customerReferenceSchenkerData.title) : null;
                break;
            case companyName.dhl:
                company.turnOn ? (labelCustomerRef = customerReferenceDhlData.title) : null;
                break;
            case companyName.geodis:
                company.turnOn ? (labelCustomerRef = customerReferenceGeodisData.titleCustomerReference) : null;
                break;
            case companyName.kn:
                company.turnOn ? (labelCustomerRef = customerReferenceKNData.title) : null;
                break;
            case companyName.emotrans:
                company.turnOn ? (labelCustomerRef = customerReferenceEmotransData.title) : null;
                break;
            case companyName.rhenus:
                company.turnOn ? (labelCustomerRef = customerReferenceRhenusData.title) : null;
                break;
            default:
                return '';
        }
        return labelCustomerRef;
    }

    /*
     * CUSTOMER REFERENCE PLACEHOLDER STARTING LEVEL
     * @method customerReferenceStartingLevelPlaceholderTags()
     * @param (company: any)
     * @description
     */

    static customerReferenceStartingLevelPlaceholderTags(company: any): string {
        let labelCustomerRef: string = '';
        switch (company.company) {
            case companyName.schenker:
                company.turnOn ? (labelCustomerRef = customerReferenceSchenkerData.placeholder) : null;
                break;
            case companyName.dhl:
                company.turnOn ? (labelCustomerRef = customerReferenceDhlData.placeholder) : null;
                break;
            case companyName.geodis:
                company.turnOn ? (labelCustomerRef = customerReferenceGeodisData.placeholderCustomerReference) : null;
                break;
            case companyName.kn:
                company.turnOn ? (labelCustomerRef = customerReferenceKNData.placeholder) : null;
                break;
            case companyName.emotrans:
                company.turnOn ? (labelCustomerRef = customerReferenceEmotransData.placeholder) : null;
                break;
            case companyName.rhenus:
                company.turnOn ? (labelCustomerRef = customerReferenceRhenusData.placeholder) : null;
                break;
            default:
                return '';
        }
        return labelCustomerRef;
    }

    /*
     * CUSTOMER REFERENCE TITLE
     * @method labelCustomerRef()
     * @param (company: string)
     * @description
     */

    static customerReferenceTitle(company?: string): string {
        let labelCustomerRef: string = Ordinary.customerReference.title;
        switch (company) {
            case companyName.schenker.toLowerCase():
                labelCustomerRef = customerReferenceSchenkerData.title;
                break;
            case companyName.dhl.toLowerCase():
                labelCustomerRef = customerReferenceDhlData.title;
                break;
            case companyName.geodis.toLowerCase():
                labelCustomerRef = customerReferenceGeodisData.titleCustomerReference;
                break;
            case companyName.kn.toLowerCase():
                labelCustomerRef = customerReferenceKNData.title;
                break;
            case companyName.emotrans.toLowerCase():
                labelCustomerRef = customerReferenceEmotransData.title;
                break;
            case companyName.rhenus.toLowerCase():
                labelCustomerRef = customerReferenceRhenusData.title;
                break;
            case 'expeditors':
                labelCustomerRef = customerReferenceExpeditorsData.titleCustomerReference;
                break;
            default:
                break;
        }
        return labelCustomerRef;
    }

    /*
     * CUSTOMER REFERENCE PLACEHOLDER
     * @method customerReferencePlaceholder()
     * @param (company: any)
     * @description
     */

    static customerReferencePlaceholder(company?: string): string {
        let labelCustomerRef: string = Ordinary.customerReference.placeholder;
        switch (company) {
            case companyName.schenker.toLowerCase():
                labelCustomerRef = customerReferenceSchenkerData.placeholder;
                break;
            case companyName.dhl.toLowerCase():
                labelCustomerRef = customerReferenceDhlData.placeholder;
                break;
            case companyName.geodis.toLowerCase():
                labelCustomerRef = customerReferenceGeodisData.placeholderCustomerReference;
                break;
            case companyName.kn.toLowerCase():
                labelCustomerRef = customerReferenceKNData.placeholder;
                break;
            case companyName.emotrans.toLowerCase():
                labelCustomerRef = customerReferenceEmotransData.placeholder;
                break;
            case companyName.rhenus.toLowerCase():
                labelCustomerRef = customerReferenceRhenusData.placeholder;
                break;
            case 'expeditors':
                labelCustomerRef = customerReferenceExpeditorsData.placeholderCustomerReference;
                break;
            default:
                break;
        }
        return labelCustomerRef;
    }

    /*
     * CUSTOMER REFERENCE TITLE LABEL STARTING LEVEL
     * @method customerReferenceStartingLevelLabelTags()
     * @param (company: any)
     * @description
     */

    static customerReferencePattern(configName: string): RegExp[] {
        let pattern: RegExp[] = [];
        switch (configName) {
            case companyName.schenker.toLowerCase():
                pattern = customerReferenceSchenker;
                break;
            case companyName.geodis.toLowerCase():
                pattern = customerReferenceGeodis;
                break;
            case companyName.kn.toLowerCase():
                pattern = customerReferenceKN;
                break;
            case companyName.emotrans.toLowerCase():
                pattern = customerReferenceEmotrans;
                break;
            case companyName.rhenus.toLowerCase():
                pattern = customerReferenceRhenus;
                break;
            case 'expeditors':
                pattern = customerReferenceExpeditorsPattern;
                break;
            case 'dsv':
                pattern = customerReferenceDSV;
                break;
            default:
                break;
        }
        return pattern;
    }

    /*
     * CUSTOMER REFERENCE TITLE
     * @method labelCustomerRef()
     * @param (company: string)
     * @description
     */

    static customerReferenceTooltip(company?: string): string {
        let tooltipCustomerRef: string = Ordinary.customerReference.tooltip;
        switch (company) {
            case companyName.geodis.toLowerCase():
                tooltipCustomerRef = customerReferenceGeodisData.tooltip;
                break;
            case 'expeditors':
                tooltipCustomerRef = customerReferenceExpeditorsData.tooltip;
                break;
            default:
                break;
        }
        return tooltipCustomerRef;
    }

    /*
     * HISTORY
     */

    static readonly historySection: Object = {
        ordinary_ColumnsHistory: Ordinary.ordinary_ColumnsHistory,
        ordinary_ColumnsNameHistory: Ordinary.ordinary_ColumnsNameHistory,
        ordinary_getCompletedPaymentsHistory: Ordinary.ordinary_getCompletedPaymentsHistory,
        ordinary_ColumnsSortableHistory: Ordinary.ordinary_ColumnsSortableHistory,
        ordinaryGeodis_ColumnsHistory: Ordinary.ordinaryGeodis_ColumnsHistory,
        ordinaryGeodis_ColumnsNameHistory: Ordinary.ordinaryGeodis_ColumnsNameHistory,
        ordinaryGeodis_getCompletedPaymentsHistory: Ordinary.ordinaryGeodis_getCompletedPaymentsHistory,
        ordinaryGeodis_ColumnsSortableHistory: Ordinary.ordinaryGeodis_ColumnsSortableHistory,
        ordinaryCeva_ColumnsHistory: Ordinary.ordinaryCeva_ColumnsHistory,
        ordinaryCeva_ColumnsNameHistory: Ordinary.ordinaryCeva_ColumnsNameHistory,
        ordinaryCeva_getCompletedPaymentsHistory: Ordinary.ordinaryCeva_getCompletedPaymentsHistory,
        ordinaryCeva_ColumnsSortableHistory: Ordinary.ordinaryCeva_ColumnsSortableHistory,
        Schenker_ColumnsHistory: Schenker.schenker_ColumnsHistory,
        Schenker_ColumnsNameHistory: Schenker.schenker_ColumnsNameHistory,
        Schenker_getCompletedPaymentsHistory: Schenker.schenker_getCompletedPaymentsHistory,
        Schenker_ColumnsSortableHistory: Schenker.schenker_ColumnsSortableHistory,
        KN_ColumnsHistory: KN.kn_ColumnsHistory,
        KN_ColumnsNameHistory: KN.kn_ColumnsNameHistory,
        KN_getCompletedPaymentsHistory: KN.kn_getCompletedPaymentsHistory,
        KN_ColumnsSortableHistory: KN.kn_ColumnsSortableHistory,
        DHL_ColumnsHistory: DHL.dhl_ColumnsHistory,
        DHL_ColumnsNameHistory: DHL.dhl_ColumnsNameHistory,
        DHL_getCompletedPaymentsHistory: DHL.dhl_getCompletedPaymentsHistory,
        DHL_ColumnsSortableHistory: DHL.dhl_ColumnsSortableHistory,
        Geodis_ColumnsHistory: Geodis.geodis_ColumnsHistory,
        Geodis_ColumnsNameHistory: Geodis.geodis_ColumnsNameHistory,
        Geodis_getCompletedPaymentsHistory: Geodis.geodis_getCompletedPaymentsHistory,
        Geodis_ColumnsSortableHistory: Geodis.geodis_ColumnsSortableHistory,
        Emotrans_ColumnsHistory: Emotrans.emotrans_ColumnsHistory,
        Emotrans_ColumnsNameHistory: Emotrans.emotrans_ColumnsNameHistory,
        Emotrans_getCompletedPaymentsHistory: Emotrans.emotrans_getCompletedPaymentsHistory,
        Emotrans_ColumnsSortableHistory: Emotrans.emotrans_ColumnsSortableHistory,
        Rhenus_ColumnsHistory: Rhenus.rhenus_ColumnsHistory,
        Rhenus_ColumnsNameHistory: Rhenus.rhenus_ColumnsNameHistory,
        Rhenus_getCompletedPaymentsHistory: Rhenus.rhenus_getCompletedPaymentsHistory,
        Rhenus_ColumnsSortableHistory: Rhenus.rhenus_ColumnsSortableHistory,
        Dynamic_ColumnsHistory: Dynamic.dynamic_ColumnsHistory,
        Dynamic_ColumnsNameHistory: Dynamic.dynamic_ColumnsNameHistory,
        Dynamic_getCompletedPaymentsHistory: Dynamic.dynamic_getCompletedPaymentsHistory,
        Dynamic_ColumnsSortableHistory: Dynamic.dynamic_ColumnsSortableHistory,
    };

    /*
     * VOIDED PAYMENTS
     */

    static readonly voidedPaymentSection: Object = {
        Schenker_ColumnsVoidedPayment: Schenker.schenker_ColumnsVoidedPayment,
        Schenker_ColumnsNameVoidedPayment: Schenker.schenker_ColumnsNameVoidedPayment,
        Schenker_getCompletedPaymentsVoidedPayment: Schenker.schenker_getCompletedPaymentsVoidedPayment,
        Schenker_ColumnsSortableVoidedPayment: Schenker.schenker_ColumnsSortableVoidedPayment,
        KN_ColumnsVoidedPayment: KN.kn_ColumnsVoidedPayment,
        KN_ColumnsNameVoidedPayment: KN.kn_ColumnsNameVoidedPayment,
        KN_getCompletedPaymentsVoidedPayment: KN.kn_getCompletedPaymentsVoidedPayment,
        KN_ColumnsSortableVoidedPayment: KN.kn_ColumnsSortableVoidedPayment,
        DHL_ColumnsVoidedPayment: DHL.dhl_ColumnsVoidedPayment,
        DHL_ColumnsNameVoidedPayment: DHL.dhl_ColumnsNameVoidedPayment,
        DHL_getCompletedPaymentsVoidedPayment: DHL.dhl_getCompletedPaymentsVoidedPayment,
        DHL_ColumnsSortableVoidedPayment: DHL.dhl_ColumnsSortableVoidedPayment,
        Geodis_ColumnsVoidedPayment: Geodis.geodis_ColumnsVoidedPayment,
        Geodis_ColumnsNameVoidedPayment: Geodis.geodis_ColumnsNameVoidedPayment,
        Geodis_getCompletedPaymentsVoidedPayment: Geodis.geodis_getCompletedPaymentsVoidedPayment,
        Geodis_ColumnsSortableVoidedPayment: Geodis.geodis_ColumnsSortableVoidedPayment,
        Emotrans_ColumnsVoidedPayment: Emotrans.emotrans_ColumnsVoidedPayment,
        Emotrans_ColumnsNameVoidedPayment: Emotrans.emotrans_ColumnsNameVoidedPayment,
        Emotrans_getCompletedPaymentsVoidedPayment: Emotrans.emotrans_getCompletedVoidedPayment,
        Emotrans_ColumnsSortableVoidedPayment: Emotrans.emotrans_ColumnsSortableVoidedPayment,
        Rhenus_ColumnsVoidedPayment: Rhenus.rhenus_ColumnsVoidedPayment,
        Rhenus_ColumnsNameVoidedPayment: Rhenus.rhenus_ColumnsNameVoidedPayment,
        Rhenus_getCompletedPaymentsVoidedPayment: Rhenus.rhenus_getCompletedVoidedPayment,
        Rhenus_ColumnsSortableVoidedPayment: Rhenus.rhenus_ColumnsSortableVoidedPayment,
        Dynamic_ColumnsVoidedPayment: Dynamic.dynamic_ColumnsVoidedPayment,
        Dynamic_ColumnsNameVoidedPayment: Dynamic.dynamic_ColumnsNameVoidedPayment,
        Dynamic_getCompletedPaymentsVoidedPayment: Dynamic.dynamic_getCompletedVoidedPayment,
        Dynamic_ColumnsSortableVoidedPayment: Dynamic.dynamic_ColumnsSortableVoidedPayment,
    };

    /*
     * REJECTED PAYMENTS
     */

    static readonly rejectedPaymentSection: Object = {
        Schenker_ColumnsRejectedPayment: Schenker.schenker_ColumnsRejectedPayment,
        Schenker_ColumnsNameRejectedPayment: Schenker.schenker_ColumnsNameRejectedPayment,
        Schenker_getCompletedPaymentsRejectedPayment: Schenker.schenker_getCompletedPaymentsRejectedPayment,
        Schenker_ColumnsSortableRejectedPayment: Schenker.schenker_ColumnsSortableRejectedPayment,
        KN_ColumnsRejectedPayment: KN.kn_ColumnsRejectedPayment,
        KN_ColumnsNameRejectedPayment: KN.kn_ColumnsNameRejectedPayment,
        KN_getCompletedPaymentsRejectedPayment: KN.kn_getCompletedPaymentsRejectedPayment,
        KN_ColumnsSortableRejectedPayment: KN.kn_ColumnsSortableRejectedPayment,
        DHL_ColumnsRejectedPayment: DHL.dhl_ColumnsRejectedPayment,
        DHL_ColumnsNameRejectedPayment: DHL.dhl_ColumnsNameRejectedPayment,
        DHL_getCompletedPaymentsRejectedPayment: DHL.dhl_getCompletedPaymentsRejectedPayment,
        DHL_ColumnsSortableRejectedPayment: DHL.dhl_ColumnsSortableRejectedPayment,
        Geodis_ColumnsRejectedPayment: Geodis.geodis_ColumnsRejectedPayment,
        Geodis_ColumnsNameRejectedPayment: Geodis.geodis_ColumnsNameRejectedPayment,
        Geodis_getCompletedPaymentsRejectedPayment: Geodis.geodis_getCompletedPaymentsRejectedPayment,
        Geodis_ColumnsSortableRejectedPayment: Geodis.geodis_ColumnsSortableRejectedPayment,
        Emotrans_ColumnsRejectedPayment: Emotrans.emotrans_ColumnsRejectedPayment,
        Emotrans_ColumnsNameRejectedPayment: Emotrans.emotrans_ColumnsNameRejectedPayment,
        Emotrans_getCompletedPaymentsRejectedPayment: Emotrans.emotrans_getCompletedRejectedPayment,
        Emotrans_ColumnsSortableRejectedPayment: Emotrans.emotrans_ColumnsSortableRejectedPayment,
        Rhenus_ColumnsRejectedPayment: Rhenus.rhenus_ColumnsRejectedPayment,
        Rhenus_ColumnsNameRejectedPayment: Rhenus.rhenus_ColumnsNameRejectedPayment,
        Rhenus_getCompletedPaymentsRejectedPayment: Rhenus.rhenus_getCompletedRejectedPayment,
        Rhenus_ColumnsSortableRejectedPayment: Rhenus.rhenus_ColumnsSortableRejectedPayment,
        Dynamic_ColumnsRejectedPayment: Dynamic.dynamic_ColumnsRejectedPayment,
        Dynamic_ColumnsNameRejectedPayment: Dynamic.dynamic_ColumnsNameRejectedPayment,
        Dynamic_getCompletedPaymentsRejectedPayment: Dynamic.dynamic_getCompletedRejectedPayment,
        Dynamic_ColumnsSortableRejectedPayment: Dynamic.dynamic_ColumnsSortableRejectedPayment,
    };

    /*
     * PENDING APPROVAL
     */

    static readonly pendingApprovalSection: Object = {
        Schenker_ColumnsPendingApproval: Schenker.schenker_ColumnsPendingApproval,
        Schenker_ColumnsNamePendingApproval: Schenker.schenker_ColumnsNamePendingApproval,
        Schenker_getCompletedPaymentsPendingApproval: Schenker.schenker_getCompletedPaymentsPendingApproval,
        Schenker_ColumnsSortablePendingApproval: Schenker.schenker_ColumnsSortablePendingApproval,
        KN_ColumnsPendingApproval: KN.kn_ColumnsPendingApproval,
        KN_ColumnsNamePendingApproval: KN.kn_ColumnsNamePendingApproval,
        KN_getCompletedPaymentsPendingApproval: KN.kn_getCompletedPaymentsPendingApproval,
        KN_ColumnsSortablePendingApproval: KN.kn_ColumnsSortablePendingApproval,
        DHL_ColumnsPendingApproval: DHL.dhl_ColumnsPendingApproval,
        DHL_ColumnsNamePendingApproval: DHL.dhl_ColumnsNamePendingApproval,
        DHL_getCompletedPaymentsPendingApproval: DHL.dhl_getCompletedPaymentsPendingApproval,
        DHL_ColumnsSortablePendingApproval: DHL.dhl_ColumnsSortablePendingApproval,
        Geodis_ColumnsPendingApproval: Geodis.geodis_ColumnsPendingApproval,
        Geodis_ColumnsNamePendingApproval: Geodis.geodis_ColumnsNamePendingApproval,
        Geodis_getCompletedPaymentsPendingApproval: Geodis.geodis_getCompletedPaymentsPendingApproval,
        Geodis_ColumnsSortablePendingApproval: Geodis.geodis_ColumnsSortablePendingApproval,
        Emotrans_ColumnsPendingApproval: Emotrans.emotrans_ColumnsPendingApproval,
        Emotrans_ColumnsNamePendingApproval: Emotrans.emotrans_ColumnsNamePendingApproval,
        Emotrans_getCompletedPaymentsPendingApproval: Emotrans.emotrans_getCompletedPaymentsPendingApproval,
        Emotrans_ColumnsSortablePendingApproval: Emotrans.emotrans_ColumnsSortablePendingApproval,
        Rhenus_ColumnsPendingApproval: Rhenus.rhenus_ColumnsPendingApproval,
        Rhenus_ColumnsNamePendingApproval: Rhenus.rhenus_ColumnsNamePendingApproval,
        Rhenus_getCompletedPaymentsPendingApproval: Rhenus.rhenus_getCompletedPaymentsPendingApproval,
        Rhenus_ColumnsSortablePendingApproval: Rhenus.rhenus_ColumnsSortablePendingApproval,
        Dynamic_ColumnsPendingApproval: Dynamic.dynamic_ColumnsPendingApproval,
        Dynamic_ColumnsNamePendingApproval: Dynamic.dynamic_ColumnsNamePendingApproval,
        Dynamic_getCompletedPaymentsPendingApproval: Dynamic.dynamic_getCompletedPaymentsPendingApproval,
        Dynamic_ColumnsSortablePendingApproval: Dynamic.dynamic_ColumnsSortablePendingApproval,
    };

    /*
     * PENDING APPROVAL HOME COMPONENT
     */

    static readonly pendingApprovalHomeComponentSection: Object = {
        Schenker_ColumnsPendingApprovalHomeComponent: Schenker.schenker_ColumnsPendingApprovalHomeComponent,
        Schenker_ColumnsNamePendingApprovalHomeComponent: Schenker.schenker_ColumnsNamePendingApprovalHomeComponent,
        Schenker_getCompletedPaymentsPendingApprovalHomeComponent:
            Schenker.schenker_getCompletedPaymentsPendingApprovalHomeComponent,
        Schenker_ColumnsSortablePendingApprovalHomeComponent:
            Schenker.schenker_ColumnsSortablePendingApprovalHomeComponent,
        KN_ColumnsPendingApprovalHomeComponent: KN.kn_ColumnsPendingApprovalHomeComponent,
        KN_ColumnsNamePendingApprovalHomeComponent: KN.kn_ColumnsNamePendingApprovalHomeComponent,
        KN_getCompletedPaymentsPendingApprovalHomeComponent: KN.kn_getCompletedPaymentsPendingApprovalHomeComponent,
        KN_ColumnsSortablePendingApprovalHomeComponent: KN.kn_ColumnsSortablePendingApprovalHomeComponent,
        DHL_ColumnsPendingApprovalHomeComponent: DHL.dhl_ColumnsPendingApprovalHomeComponent,
        DHL_ColumnsNamePendingApprovalHomeComponent: DHL.dhl_ColumnsNamePendingApprovalHomeComponent,
        DHL_getCompletedPaymentsPendingApprovalHomeComponent: DHL.dhl_getCompletedPaymentsPendingApprovalHomeComponent,
        DHL_ColumnsSortablePendingApprovalHomeComponent: DHL.dhl_ColumnsSortablePendingApprovalHomeComponent,
        Geodis_ColumnsPendingApprovalHomeComponent: Geodis.geodis_ColumnsPendingApprovalHomeComponent,
        Geodis_ColumnsNamePendingApprovalHomeComponent: Geodis.geodis_ColumnsNamePendingApprovalHomeComponent,
        Geodis_getCompletedPaymentsPendingApprovalHomeComponent:
            Geodis.geodis_getCompletedPaymentsPendingApprovalHomeComponent,
        Geodis_ColumnsSortablePendingApprovalHomeComponent: Geodis.geodis_ColumnsSortablePendingApprovalHomeComponent,
        Emotrans_ColumnsPendingApprovalHomeComponent: Emotrans.emotrans_ColumnsPendingApprovalHomeComponent,
        Emotrans_ColumnsNamePendingApprovalHomeComponent: Emotrans.emotrans_ColumnsNamePendingApprovalHomeComponent,
        Emotrans_getCompletedPaymentsPendingApprovalHomeComponent:
            Emotrans.emotrans_getCompletedPaymentsPendingApprovalHomeComponent,
        Emotrans_ColumnsSortablePendingApprovalHomeComponent:
            Emotrans.emotrans_ColumnsSortablePendingApprovalHomeComponent,
        Rhenus_ColumnsPendingApprovalHomeComponent: Rhenus.rhenus_ColumnsPendingApprovalHomeComponent,
        Rhenus_ColumnsNamePendingApprovalHomeComponent: Rhenus.rhenus_ColumnsNamePendingApprovalHomeComponent,
        Rhenus_getCompletedPaymentsPendingApprovalHomeComponent:
            Rhenus.rhenus_getCompletedPaymentsPendingApprovalHomeComponent,
        Rhenus_ColumnsSortablePendingApprovalHomeComponent: Rhenus.rhenus_ColumnsSortablePendingApprovalHomeComponent,
        Dynamic_ColumnsPendingApprovalHomeComponent: Dynamic.dynamic_ColumnsPendingApprovalHomeComponent,
        Dynamic_ColumnsNamePendingApprovalHomeComponent: Dynamic.dynamic_ColumnsNamePendingApprovalHomeComponent,
        Dynamic_getCompletedPaymentsPendingApprovalHomeComponent:
            Dynamic.dynamic_getCompletedPaymentsPendingApprovalHomeComponent,
        Dynamic_ColumnsSortablePendingApprovalHomeComponent:
            Dynamic.dynamic_ColumnsSortablePendingApprovalHomeComponent,
    };

    /*
     * PAID INVOICES
     */

    static readonly paidInvoicesSection: Object = {
        ordinary_ColumnsPaidInvoices: Ordinary.ordinary_ColumnsPaidInvoices,
        ordinary_ColumnsNamePaidInvoices: Ordinary.ordinary_ColumnsNamePaidInvoices,
        ordinary_getCompletedPaymentsPaidInvoices: Ordinary.ordinary_getCompletedPaymentsPaidInvoices,
        ordinary_ColumnsSortablePaidInvoices: Ordinary.ordinary_ColumnsSortablePaidInvoices,
        KN_ColumnsPaidInvoices: KN.kn_ColumnsPaidInvoices,
        KN_ColumnsNamePaidInvoices: KN.kn_ColumnsNamePaidInvoices,
        KN_getCompletedPaymentsPaidInvoices: KN.kn_getCompletedPaymentsPaidInvoices,
        KN_ColumnsSortablePaidInvoices: KN.kn_ColumnsSortablePaidInvoices,
        Geodis_ColumnsPaidInvoices: Geodis.geodis_ColumnsPaidInvoices,
        Geodis_ColumnsNamePaidInvoices: Geodis.geodis_ColumnsNamePaidInvoices,
        Geodis_getCompletedPaymentsPaidInvoices: Geodis.geodis_getCompletedPaymentsPaidInvoices,
        Geodis_ColumnsSortablePaidInvoices: Geodis.geodis_ColumnsSortablePaidInvoices,
        Emotrans_ColumnsPaidInvoices: Emotrans.emotrans_ColumnsPaidInvoices,
        Emotrans_ColumnsNamePaidInvoices: Emotrans.emotrans_ColumnsNamePaidInvoices,
        Emotrans_getCompletedPaymentsPaidInvoices: Emotrans.emotrans_getCompletedPaidInvoices,
        Emotrans_ColumnsSortablePaidInvoices: Emotrans.emotrans_ColumnsSortablePaidInvoices,
        Rhenus_ColumnsPaidInvoices: Rhenus.rhenus_ColumnsPaidInvoices,
        Rhenus_ColumnsNamePaidInvoices: Rhenus.rhenus_ColumnsNamePaidInvoices,
        Rhenus_getCompletedPaymentsPaidInvoices: Rhenus.rhenus_getCompletedPaidInvoices,
        Rhenus_ColumnsSortablePaidInvoices: Rhenus.rhenus_ColumnsSortablePaidInvoices,
        Dynamic_ColumnsPaidInvoices: Dynamic.dynamic_ColumnsPaidInvoices,
        Dynamic_ColumnsNamePaidInvoices: Dynamic.dynamic_ColumnsNamePaidInvoices,
        Dynamic_getCompletedPaymentsPaidInvoices: Dynamic.dynamic_getCompletedPaidInvoices,
        Dynamic_ColumnsSortablePaidInvoices: Dynamic.dynamic_ColumnsSortablePaidInvoices,
    };

    /*
     * OPEN INVOICES
     */

    static readonly openInvoicesSection: Object = {
        ordinary_ColumnsOpenInvoices: Ordinary.ordinary_ColumnsOpenInvoices,
        ordinary_ColumnsNameOpenInvoices: Ordinary.ordinary_ColumnsNameOpenInvoices,
        ordinary_getCompletedPaymentsOpenInvoices: Ordinary.ordinary_getCompletedPaymentsOpenInvoices,
        ordinary_ColumnsSortableOpenInvoices: Ordinary.ordinary_ColumnsSortableOpenInvoices,
        KN_ColumnsOpenInvoices: KN.kn_ColumnsOpenInvoices,
        KN_ColumnsNameOpenInvoices: KN.kn_ColumnsNameOpenInvoices,
        KN_getCompletedPaymentsOpenInvoices: KN.kn_getCompletedPaymentsOpenInvoices,
        KN_ColumnsSortableOpenInvoices: KN.kn_ColumnsSortableOpenInvoices,
        Geodis_ColumnsOpenInvoices: Geodis.geodis_ColumnsOpenInvoices,
        Geodis_ColumnsNameOpenInvoices: Geodis.geodis_ColumnsNameOpenInvoices,
        Geodis_getCompletedPaymentsOpenInvoices: Geodis.geodis_getCompletedPaymentsOpenInvoices,
        Geodis_ColumnsSortableOpenInvoices: Geodis.geodis_ColumnsSortableOpenInvoices,
        Emotrans_ColumnsOpenInvoices: Emotrans.emotrans_ColumnsOpenInvoices,
        Emotrans_ColumnsNameOpenInvoices: Emotrans.emotrans_ColumnsNameOpenInvoices,
        Emotrans_getCompletedPaymentsOpenInvoices: Emotrans.emotrans_getCompletedOpenInvoices,
        Emotrans_ColumnsSortableOpenInvoices: Emotrans.emotrans_ColumnsSortableOpenInvoices,
        Rhenus_ColumnsOpenInvoices: Rhenus.rhenus_ColumnsOpenInvoices,
        Rhenus_ColumnsNameOpenInvoices: Rhenus.rhenus_ColumnsNameOpenInvoices,
        Rhenus_getCompletedPaymentsOpenInvoices: Rhenus.rhenus_getCompletedOpenInvoices,
        Rhenus_ColumnsSortableOpenInvoices: Rhenus.rhenus_ColumnsSortableOpenInvoices,
        Dynamic_ColumnsOpenInvoices: Dynamic.dynamic_ColumnsOpenInvoices,
        Dynamic_ColumnsNameOpenInvoices: Dynamic.dynamic_ColumnsNameOpenInvoices,
        Dynamic_getCompletedPaymentsOpenInvoices: Dynamic.dynamic_getCompletedOpenInvoices,
        Dynamic_ColumnsSortableOpenInvoices: Dynamic.dynamic_ColumnsSortableOpenInvoices,
    };

    /*
     * USERS
     */

    static readonly usersSection: Object = {
        ordinary_ColumnsUsers: Ordinary.ordinary_ColumnsUsers,
        ordinary_ColumnsNameUsers: Ordinary.ordinary_ColumnsNameUsers,
        ordinary_getCompletedPaymentsUsers: Ordinary.ordinary_getCompletedPaymentsUsers,
        Schenker_ColumnsUsers: Schenker.schenker_ColumnsUsers,
        Schenker_ColumnsNameUsers: Schenker.schenker_ColumnsNameUsers,
        Schenker_getCompletedPaymentsUsers: Schenker.schenker_getCompletedPaymentsUsers,
        KN_ColumnsUsers: KN.kn_ColumnsUsers,
        KN_ColumnsNameUsers: KN.kn_ColumnsNameUsers,
        KN_getCompletedPaymentsUsers: KN.kn_getCompletedPaymentsUsers,
        DHL_ColumnsUsers: DHL.dhl_ColumnsUsers,
        DHL_ColumnsNameUsers: DHL.dhl_ColumnsNameUsers,
        DHL_getCompletedPaymentsUsers: DHL.dhl_getCompletedPaymentsUsers,
        Geodis_ColumnsUsers: Geodis.geodis_ColumnsUsers,
        Geodis_ColumnsNameUsers: Geodis.geodis_ColumnsNameUsers,
        Geodis_getCompletedPaymentsUsers: Geodis.geodis_getCompletedPaymentsUsers,
        Emotrans_ColumnsUsers: Emotrans.emotrans_ColumnsUsers,
        Emotrans_ColumnsNameUsers: Emotrans.emotrans_ColumnsNameUsers,
        Emotrans_getCompletedPaymentsUsers: Emotrans.emotrans_getCompletedPaymentsUsers,
        Rhenus_ColumnsUsers: Rhenus.rhenus_ColumnsUsers,
        Rhenus_ColumnsNameUsers: Rhenus.rhenus_ColumnsNameUsers,
        Rhenus_getCompletedPaymentsUsers: Rhenus.rhenus_getCompletedPaymentsUsers,
    };

    /*
     * FACILITY CONTACTS
     */
    static readonly columnsFacilityContacts = [
        'name',
        'airportCode',
        'firmsCode',
        'phone',
        'address',
        'city',
        'deliveryType',
        'button',
    ];
    static readonly columnsNameFacilityContacts = [
        'Facility name',
        'Airport code',
        'Firms code',
        'Phone',
        'Address',
        'City',
        'Delivery',
        '',
    ];
    static readonly columnsSortableFacilityContacts = [
        'name',
        'deliveryType',
        'city',
        'address',
        'phone',
        'firmsCode',
        'airportCode',
    ];

    /*
     * SUPER ACH
     */
    static readonly superACHSection: Object = {
        superACH_ColumnsHistory: SuperUser.superACH_ColumnsHistory,
        superACH_ColumnsNameHistory: SuperUser.superACH_ColumnsNameHistory,
        superACH_getACHPaymentsHistory: SuperUser.superACH_getACHPaymentsHistory,
        superACH_ColumnsSortableACHPayments: SuperUser.superACH_ColumnsSortableACHPayments,
    };

    /*
     * SUPER ACH DETAILS
     */
    static readonly superACHDetailsSection: Object = {
        superACHDetails_ColumnsHistory: SuperUser.superACHDetails_ColumnsHistory,
        superACHDetails_ColumnsNameHistory: SuperUser.superACHDetails_ColumnsNameHistory,
        superACHDetails_getACHPaymentsHistory: SuperUser.superACHDetails_getACHPaymentsHistory,
    };

    /*
     * SUPER REFUND REQUEST
     */
    static readonly superRefundRequestSection: Object = {
        superRefundRequest_ColumnsHistory: SuperUser.superRefundRequest_ColumnsHistory,
        superRefundRequest_ColumnsNameHistory: SuperUser.superRefundRequest_ColumnsNameHistory,
        superRefundRequest_getRefundRequestPaymentsHistory:
            SuperUser.superRefundRequest_getRefundRequestPaymentsHistory,
        superRefundRequest_ColumnsSortableRefundRequestPayments:
            SuperUser.superRefundRequest_ColumnsSortableRefundRequestPayments,
    };

    /*
     * SUPER PAYMENTS
     */
    static readonly superPaymentsSection: Object = {
        superPaymentsFACILITY_ColumnsHistory: SuperUser.superPaymentsFACILITY_ColumnsHistory,
        superPaymentsFACILITY_ColumnsNameHistory: SuperUser.superPaymentsFACILITY_ColumnsNameHistory,
        superPaymentsFACILITY_getSuperPaymentsHistory: SuperUser.superPaymentsFACILITY_getSuperPaymentsHistory,
        superPaymentsFACILITY_ColumnsSortableSuperPayments:
            SuperUser.superPaymentsFACILITY_ColumnsSortableSuperPayments,

        superPaymentsACCOUNTING_ColumnsHistory: SuperUser.superPaymentsACCOUNTING_ColumnsHistory,
        superPaymentsACCOUNTING_ColumnsNameHistory: SuperUser.superPaymentsACCOUNTING_ColumnsNameHistory,
        superPaymentsACCOUNTING_getSuperPaymentsHistory: SuperUser.superPaymentsACCOUNTING_getSuperPaymentsHistory,
    };

    /*
     * PROMO CODE COLUMNS
     */
    static readonly promoCode_Columns = ['awb', 'customerEmail', 'statusFormatted', 'button'];
    static readonly promoCode_ColumnsName = ['AWB', 'Customer email', 'Status', ''];

    /*
     * FORWARDER REFUND REQUEST
     */
    static readonly forwarderAdminRefundRequestSection: Object = {
        forwarderAdminRefundRequest_ColumnsHistory: ForwarderAdminUser.forwarderAdminRefundRequest_ColumnsHistory,
        forwarderAdminRefundRequest_ColumnsNameHistory:
            ForwarderAdminUser.forwarderAdminRefundRequest_ColumnsNameHistory,
        forwarderAdminRefundRequest_getRefundRequestPaymentsHistory:
            ForwarderAdminUser.forwarderAdminRefundRequest_getRefundRequestPaymentsHistory,
        forwarderAdminRefundRequest_ColumnsSortableRefundRequestPayments:
            ForwarderAdminUser.forwarderAdminRefundRequest_ColumnsSortableRefundRequestPayments,
    };

    static readonly forwarderAdminInvoiceSection: Object = {
        forwarderAdminInvoiceOpen_Columns: ForwarderAdminUser.forwarderAdminInvoiceOpen_Columns,
        forwarderAdminInvoiceOpen_ColumnsName: ForwarderAdminUser.forwarderAdminInvoiceOpen_ColumnsName,
        forwarderAdminInvoiceOpen_SortableColumns: ForwarderAdminUser.forwarderAdminInvoiceOpen_SortableColumns,
        forwarderAdminInvoiceOpen__getCompletedOpenInvoices: ForwarderAdminUser.invoice_getCompletedOpenInvoices,
        forwarderAdminInvoicePaid_Columns: ForwarderAdminUser.forwarderAdminInvoicePaid_Columns,
        forwarderAdminInvoicePaid_ColumnsName: ForwarderAdminUser.forwarderAdminInvoicePaid_ColumnsName,
        forwarderAdminInvoicePaid_SortableColumns: ForwarderAdminUser.forwarderAdminInvoicePaid_SortableColumns,
        forwarderAdminInvoicePaid__getCompletedPaidInvoices: ForwarderAdminUser.invoice_getCompletedPaidInvoices,
    };

    /*
     * get payment details
     * @method labelCustomerRef()
     * @param (company: string)
     * @description
     */

    static getDetailsOfPaymentDetailsHistorySection(payment: Payment, company?: string): Object {
        let paymentDetails: { label: string; value: string }[] = Ordinary.getPaymentDetailsPaymentHistory(
            payment.details,
            payment.facility,
            payment.id
        );
        if (company !== '' && company) {
            switch (company && company?.toLowerCase()) {
                case companyName.schenker.toLowerCase():
                    paymentDetails = Schenker.getPaymentDetailsPaymentHistory(
                        payment.details,
                        payment.facility,
                        payment.id
                    );
                    break;
                case companyName.geodis.toLowerCase():
                    paymentDetails = Geodis.getPaymentDetailsPaymentHistory(
                        payment.details,
                        payment.facility,
                        payment.id
                    );
                    break;
                case companyName.kn.toLowerCase():
                    paymentDetails = KN.getPaymentDetailsPaymentHistory(payment.details, payment.facility, payment.id);
                    break;
                case companyName.emotrans.toLowerCase():
                    paymentDetails = Emotrans.getPaymentDetailsPaymentHistory(
                        payment.details,
                        payment.facility,
                        payment.id
                    );
                    break;
                case companyName.rhenus.toLowerCase():
                    paymentDetails = Rhenus.getPaymentDetailsPaymentHistory(
                        payment.details,
                        payment.facility,
                        payment.id
                    );
                    break;
                default:
                    paymentDetails = Dynamic.getPaymentDetailsPaymentHistory(
                        payment.details,
                        payment.facility,
                        payment.id
                    );
            }
        }
        return paymentDetails;
    }

    /*
     * get customer reference details
     * @method labelCustomerRef()
     * @param (company: string)
     * @description
     */

    static getDetailsOfCustomerReferenceHistorySection(
        item: any,
        customer: Customer,
        customerReferenceLabel: string = '',
        company?: string
    ): Object {
        let customerReference: { label: string; value: string }[] = Ordinary.getCustomerReferencePaymentHistory(
            item,
            customer
        );
        if (company !== '' && company) {
            switch (company?.toLowerCase()) {
                case companyName.schenker.toLowerCase():
                    customerReference = Schenker.getCustomerReferencePaymentHistory(item, customerReferenceLabel);
                    break;
                case companyName.geodis.toLowerCase():
                    customerReference = Geodis.getCustomerReferencePaymentHistory(item, customerReferenceLabel);
                    break;
                case companyName.kn.toLowerCase():
                    customerReference = KN.getCustomerReferencePaymentHistory(item, customerReferenceLabel);
                    break;
                case companyName.emotrans.toLowerCase():
                    customerReference = Emotrans.getCustomerReferencePaymentHistory(item, customerReferenceLabel);
                    break;
                case companyName.rhenus.toLowerCase():
                    customerReference = Rhenus.getCustomerReferencePaymentHistory(item, customerReferenceLabel);
                    break;
                default:
                    customerReference = Dynamic.getCustomerReferencePaymentHistory(item, customerReferenceLabel);
            }
        }
        return customerReference;
    }

    /*
     * get payment details
     * @method labelCustomerRef()
     * @param (company: string)
     * @description
     */

    static getDetailsOfPaymentDetailsSummarySection(payment: Payment, company?: string): Object {
        let paymentDetails: { label: string; value: string }[] = Ordinary.getPaymentDetailsPaymentSummary(
            payment.details,
            payment.facility,
            payment.id
        );
        if (company !== '' && company) {
            switch (company?.toLowerCase()) {
                case companyName.schenker.toLowerCase():
                    paymentDetails = Schenker.getPaymentDetailsPaymentSummary(
                        payment.details,
                        payment.facility,
                        payment.id
                    );
                    break;
                case companyName.geodis.toLowerCase():
                    paymentDetails = Geodis.getPaymentDetailsPaymentSummary(
                        payment.details,
                        payment.facility,
                        payment.id
                    );
                    break;
                case companyName.kn.toLowerCase():
                    paymentDetails = KN.getPaymentDetailsPaymentSummary(payment.details, payment.facility, payment.id);
                    break;
                case companyName.emotrans.toLowerCase():
                    paymentDetails = Emotrans.getPaymentDetailsPaymentSummary(
                        payment.details,
                        payment.facility,
                        payment.id
                    );
                    break;
                case companyName.rhenus.toLowerCase():
                    paymentDetails = Rhenus.getPaymentDetailsPaymentSummary(
                        payment.details,
                        payment.facility,
                        payment.id
                    );
                    break;
                default:
                    paymentDetails = Dynamic.getPaymentDetailsPaymentSummary(
                        payment.details,
                        payment.facility,
                        payment.id
                    );
            }
        }
        return paymentDetails;
    }

    /*
     * get customer reference details
     * @method labelCustomerRef()
     * @param (company: string)
     * @description
     */

    static getDetailsOfCustomerReferenceSummarySection(
        item: any,
        customer: Customer,
        customerReferenceLabel: string = '',
        company?: string
    ): Object {
        let customerReference: { label: string; value: string }[] = Ordinary.getCustomerReferencePaymentSummary(
            item,
            customer
        );
        if (company !== '' && company) {
            switch (company?.toLowerCase()) {
                case companyName.schenker.toLowerCase():
                    customerReference = Schenker.getCustomerReferencePaymentSummary(item, customerReferenceLabel);
                    break;
                case companyName.geodis.toLowerCase():
                    customerReference = Geodis.getCustomerReferencePaymentSummary(item, customerReferenceLabel);
                    break;
                case companyName.kn.toLowerCase():
                    customerReference = KN.getCustomerReferencePaymentSummary(item, customerReferenceLabel);
                    break;
                case companyName.emotrans.toLowerCase():
                    customerReference = Emotrans.getCustomerReferencePaymentSummary(item, customerReferenceLabel);
                    break;
                case companyName.rhenus.toLowerCase():
                    customerReference = Rhenus.getCustomerReferencePaymentSummary(item, customerReferenceLabel);
                    break;
                default:
                    customerReference = Dynamic.getCustomerReferencePaymentSummary(item, customerReferenceLabel);
            }
        }
        return customerReference;
    }

    static getCustomerInformationHistorySection(item: any): Object {
        return [
            {
                label: 'Customer Name',
                value: item.customer ? item.customer.name : 'N/A',
                show: true,
            },
            {
                label: 'Notification email',
                value: item?.notificationEmail ? item?.notificationEmail : 'N/A',
                show: true,
            },
            {
                label: 'Created Date',
                value: item?.createdDate ? moment(item?.createdDate).format('MMM DD, YYYY') : 'N/A',
                show: true,
            },
        ];
    }
}
