<!--START: PAYMENT FLUX -->
<div id="coda-facility" class="mt-4">
    <div id="coda-facility--container">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex justify-content-end w-100">
                        <button
                            mat-button
                            type="button"
                            color="warn"
                            class="d-block border-0 rounded-0 py-3 mt-4 text-uppercase btn__sprintpay btn__sprintpay--linkDelete btn__sprintpay--cancelProcess"
                            (click)="returnToFacility()"
                        >
                            Cancel process
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <!--START: STEPPER -->
                <ng-container *ngIf="navigationLinks.length > 0 ">
                    <mat-stepper
                        [linear]="true"
                        #stepper
                        (selectionChange)="stepChanged($event)"
                        [orientation]="(stepperOrientation | async)!"
                        [selectedIndex]="selectedIndex"
                        class="py-3"
                    >
                        <mat-step
                            label="{{item.index}}"
                            [editable]="true"
                            errorMessage="{{item.label}} required"
                            [completed]="steps[item.index]"
                            *ngFor="let item of navigationLinks; let i = index"
                        >
                            <ng-template matStepLabel>{{item.label}}</ng-template>
                            <div class="px-0 py-3">
                                <!--START: FACILITY DATA COMPONENT -->
                                <app-facility-data class="d-none d-md-block" [facility]="facilityData">
                                </app-facility-data>
                                <!--END: FACILITY DATA COMPONENT -->
                                <router-outlet *ngIf="i === selectedStepIndex"></router-outlet>
                            </div>
                        </mat-step>
                    </mat-stepper>
                </ng-container>
                <!--END: STEPPER -->
            </div>
        </div>
    </div>
</div>
<!--END: PAYMENT FLUX -->
