import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'vinEdocNextgen',
    standalone: true,
})
export class VinEdocNextgenPipe implements PipeTransform {
    /**
     * @method transform()
     * @param (value: string)
     * @description Review the value form BE and change into a more UX friendly message to the GEODIS users related to the VIN Edoc Nextgen radio button field
     */

    transform(value: string): string {
        switch (value) {
            case 'true':
                return 'Yes';
            case 'false':
                return 'No';
            case 'null':
                return 'N/A - Airline Fee';
            default:
                return 'N/A';
        }
    }
}
