<!-- START: TOP NAVBAR - THIS CAN BE PLACE HERE OR OUTSIDE THE MAT SIDENAV CONTAINER IF YOU WANT THE NAVBAR ON TOP OF ALL THE PAGE -->
<mat-toolbar class="admin__toolbar">
    <button
        mat-icon-button
        (click)="toggleMenu()"
        aria-label="Menu button"
        class="admin__toolbar--btnMenu"
        data-cy="toolbar_btnMenu"
    >
        <mat-icon>menu</mat-icon>
    </button>
    <!-- START: SWITCH PROFILE - THIS WILL BE USED TO SWITCH THE PROFILE BETWEEN FOWARDER AND FACILITY WHEN THE CUSTOMER PROFILE TYPE IS FORWARDER_FACILITY -->
    <div class="admin__toolbar--switch-profile">
        <app-portal-selector></app-portal-selector>
    </div>
    <!-- END: SWITCH PROFILE -->

    <span class="spacer"></span>
    <ng-container *ngxPermissionsOnly="newPaymentSecurity">
        <button
            *ngIf="showNewPayment"
            mat-flat-button
            color="primary"
            type="button"
            class="d-block border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green me-4 admin__toolbar--btnPayment"
            data-cy="toolbar_btnPayment"
            routerLink="./facilityPayments/newPayment"
        >
            NEW PAYMENT
        </button>
    </ng-container>
    <ng-container *ngxPermissionsOnly="cartSecurity">
        <button
            mat-icon-button
            *ngIf="showCart"
            aria-label="Shopping cart icon"
            class="admin__toolbar--btnCart"
            data-cy="toolbar_btnCart"
            (click)="goToCart('admin/cart')"
        >
            <mat-icon matBadge="{{elementsInCart}}" matBadgeColor="warn" class="material-symbols-outlined"
                >shopping_cart</mat-icon
            >
        </button>
    </ng-container>
</mat-toolbar>
<!-- END: TOP NAVBAR - THIS CAN BE PLACE HERE OR OUTSIDE THE MAT SIDENAV CONTAINER IF YOU WANT THE NAVBAR ON TOP OF ALL THE PAGE -->
