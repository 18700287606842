<form [formGroup]="emailForm">
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label for="email" class="label-lg text-nowrap"> Email </mat-label>
            <input
                matInput
                type="email"
                id="email"
                name="email"
                formControlName="email"
                placeholder="Enter your email"
                [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="email.hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
            <mat-error *ngIf="email.hasError('regex')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. The email address was malformed! Please try
                again
            </mat-error>
            <mat-error *ngIf="email.hasError('error')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. Your account is suspended. Please contact
                support&#64;cargosprint.com for assistance
            </mat-error>
        </mat-form-field>
    </div>
</form>
