import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { PasswordRoutingModule } from './password-routing.module';

//COMPONENTS

import { PasswordComponent } from './password.component';

//MODULE

import { SharedModule } from '../../../shared/shared.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { NewPasswordModule } from './new-password/new-password.module';

@NgModule({
    declarations: [PasswordComponent],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        PasswordRoutingModule,
        ForgotPasswordModule,
        NewPasswordModule,
        SharedModule,
    ],
})
export class PasswordModule {
    constructor() {}
}
