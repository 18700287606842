import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { NgxPermissionsModule } from 'ngx-permissions';
import { PaymentMethodsRoutingModule } from './payment-methods-routing.module';

// COMPONENTS

import { CreditCardComponent } from './credit-card/credit-card.component';
import { EcheckComponent } from './echeck/echeck.component';
import { PaymentMethodsComponent } from './payment-methods.component';
import { SprintpayCreditComponent } from './sprintpay-credit/sprintpay-credit.component';

// MODULES

import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from '../../../../shared/shared.module';

@NgModule({
    declarations: [PaymentMethodsComponent, CreditCardComponent, EcheckComponent, SprintpayCreditComponent],
    exports: [PaymentMethodsComponent],
    imports: [
        NgxPermissionsModule.forChild(),
        CommonModule,
        PaymentMethodsRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        NgxMaskModule.forChild(),
    ],
})
export class PaymentMethodsModule {
    constructor() {}
}
