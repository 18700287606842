import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { permissionsName, permissionsSection } from '../../../../utils/constants';

// COMPONENTS

import { RejectedPaymentComponent } from './rejected-payment.component';

const routes: Routes = [
    {
        path: '',
        title: 'SprintPay | Rejected payment',
        canLoad: [NgxPermissionsGuard],
        data: {
            breadcrumb: { alias: 'rejected-payment' },
            permissions: {
                only: permissionsSection.rejectedPayments,
                except: [permissionsName.ordinary],
                redirectTo: '/admin/home',
            },
        },
        children: [
            {
                path: '',
                redirectTo: '/admin/facilityPayments/rejectedPayment/paymentList',
                pathMatch: 'full',
            },
            {
                path: 'paymentDetail/:id/:approvalStatus',
                loadChildren: () => import('./payment-detail/payment-detail.module').then((m) => m.PaymentDetailModule),
            },
            {
                path: 'paymentList',
                component: RejectedPaymentComponent,
                data: { breadcrumb: { skip: true, alias: 'payment-list' } },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RejectedPaymentRoutingModule {}
