import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, Renderer2, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PaymentResponse } from '@cargos/sprintpay-models';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, finalize, map, take, takeUntil, throwError } from 'rxjs';
import { ResponseEmail, SignUpAPIService } from 'src/app/services/requests/signup-api.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { RestService } from '../../../../services/rest.service';
import { PaymentConfirmationService } from '../services/payment-confirmation.service';

@Component({
    selector: 'app-guest-payment-confirmation',
    templateUrl: './guest-payment-confirmation.component.html',
})
export class GuestPaymentConfirmationComponent implements OnInit, OnDestroy {
    notificationEmail: string;
    private _paymentResponse: PaymentResponse | null;
    private _unsubscribe$: Subject<void>;
    private readonly _cartUrl: string;

    constructor(
        private _restService: RestService,
        private _renderer: Renderer2,
        private _domSanitizer: DomSanitizer,
        private _paymentConfirmationService: PaymentConfirmationService,
        private _errorHandlerService: ErrorHandlerService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _signUpAPIService: SignUpAPIService,
        private _router: Router
    ) {
        this.notificationEmail = '';
        this._paymentResponse = null;
        this._unsubscribe$ = new Subject<void>();
        this._cartUrl = environment.uris.method.cart;
    }

    ngOnInit(): void {
        this._subscribePaymentResponse();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
        this._paymentConfirmationService.setPaymentConfirmation(false);
        this._paymentConfirmationService.setPaymentResponse(null);
    }

    /**
     * @method _subscribePaymentResponse()
     * @description Get paymentResponse value from service
     */

    private _subscribePaymentResponse(): void {
        this._paymentConfirmationService
            .getPaymentResponse()
            .pipe(
                takeUntil(this._unsubscribe$),
                map((paymentResponse) => {
                    this._paymentResponse = paymentResponse;
                    this.notificationEmail = paymentResponse?.notificationEmail || '';
                })
            )
            .subscribe();
    }

    /**
     * @method getPaymentReceipt()
     * @description Create link and download payment receipt in PDF format
     */

    getPaymentReceipt(): void {
        if (this._paymentResponse) {
            this._ngxSpinnerService.show();
            const paymentsIds = this._paymentResponse.completedPayments.map((item: any) => item.invoice.id);
            const url = `${this._cartUrl}/receiptByInvoices`;
            const data = { invoices: paymentsIds, confirmationId: this._paymentResponse.transactionId };
            this._restService
                .getFileByPost(url, data)
                .then((result: any) => {
                    this._ngxSpinnerService.hide();
                    const $link: any = this._renderer.createElement('a');
                    const file: Blob = new Blob([result], { type: result.type });
                    const url: string | null = this._domSanitizer.sanitize(
                        SecurityContext.URL,
                        this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                    );
                    if (url) {
                        this._renderer.setAttribute($link, 'href', url);
                        this._renderer.setAttribute(
                            $link,
                            'download',
                            paymentsIds.length > 1
                                ? 'Payments Confirmation.zip'
                                : 'Payment Confirmation -' + paymentsIds[0] + '.pdf'
                        );
                        $link.click();
                        window.URL.revokeObjectURL(url);
                    }
                })
                .catch((error: HttpErrorResponse) => {
                    this._ngxSpinnerService.hide();
                    const text = this._errorHandlerService.errorMsg(
                        JSON.parse(new TextDecoder('utf-8').decode(error.error))
                    );
                    Swal.fire({
                        title: 'Oops...',
                        text,
                        icon: 'error',
                        allowOutsideClick: false,
                        confirmButtonText: 'Ok',
                    });
                    throwError(() => error);
                });
        }
    }

    /**
     * @method navigateToSignUp()
     * @description Redirect customer to SignUp
     */

    navigateToSignUp(): void {
        this._router.navigate(['signup-guest']);
    }

    validateEmail(): void {
        if (this.notificationEmail) {
            this._ngxSpinnerService.show();
            this._signUpAPIService
                .validateEmail(this.notificationEmail)
                .pipe(
                    take(1),
                    finalize(() => this._ngxSpinnerService.hide())
                )
                .subscribe({
                    next: (response: ResponseEmail) => {},
                    error: (error: any) => {
                        if (error?.error?.error?.data) {
                            let template;
                            const guestData = error?.error?.error?.data;
                            const isActive = guestData?.isActive;
                            const isGuest = guestData?.isGuest;

                            if (!isActive && !!isGuest) {
                                this.navigateToSignUp();
                            }

                            if (error?.error?.error?.body) {
                                template = error.error.error.body;
                            } else {
                                template =
                                    error && error.response && error.response.status == 422
                                        ? this._errorHandlerService.errorMsg(error.response.data)
                                        : this._errorHandlerService.errorMsg(error);
                            }

                            if (isActive && !isGuest) {
                                Swal.fire({
                                    title: 'Oops...',
                                    html: `<div>${template}</div>
                                            <div class="mat-caption mt-3">Proceed to sign in or you may request a new password.</div>`,
                                    icon: 'error',
                                    allowOutsideClick: false,
                                    cancelButtonText: 'SIGN IN',
                                    confirmButtonText: 'FORGOT PASSWORD',
                                    showCancelButton: true,
                                    showConfirmButton: true,
                                    showCloseButton: true,
                                    customClass: {
                                        cancelButton: 'order-1 swal2-btn-sprint-pay',
                                        confirmButton: 'order-2',
                                    },
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this._router.navigate(['/password/forgot-password']);
                                    }
                                    if (result?.dismiss == Swal.DismissReason.cancel) {
                                        this._router.navigate(['/login']);
                                    }
                                });
                            }
                        }
                    },
                });
        }
    }
}
