import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { UsersRoutingModule } from './users-routing.module';

//COMPONENTS

import { UsersComponent } from './users.component';

//MODULES

import { SharedModule } from '../../../shared/shared.module';

@NgModule({
    declarations: [UsersComponent],
    imports: [CommonModule, UsersRoutingModule, RouterModule, ReactiveFormsModule, FormsModule, SharedModule],
})
export class UsersModule {
    constructor() {}
}
