import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BreadcrumbModule } from 'xng-breadcrumb';

@Component({
    selector: 'app-breadcrumb',
    standalone: true,
    imports: [CommonModule, BreadcrumbModule, MatIconModule],
    templateUrl: './breadcrumb.component.html',
})
export class BreadcrumbComponent {
    constructor() {}
}
