<div class="d-flex flex-column w-100">
    <mat-accordion class="mb-2">
        <mat-expansion-panel [expanded]="true" [ngClass]="{'overflow-visible': isOpenCloseDropDown}">
            <mat-expansion-panel-header class="bar-color px-3">
                <mat-panel-title
                    [ngClass]="facilityName ? 'my-1' : 'my-3'"
                    class="d-flex mat-subtitle-1 align-items-center"
                >
                    {{ facilityName?facilityName : '' }} <br />
                    {{hasExternalLookup ? 'File Number: ': 'MAWB/AWB/MBL: '}}{{awb}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="d-flex mt-1 flex-column">
                <ng-container *ngFor="let item of request; let index = index; first as isFirst;">
                    <div class="d-flex justify-content-start flex-column flex-lg-row mb-3">
                        <!-- START: TYPE TEMPLATE -->
                        <div class="w-100 pe-3" *ngIf="item.paymentType && item.paymentType !== ''">
                            <span class="d-block invoices-list__title mb-3">Type</span>
                            <span class="d-block invoices-list__value mb-3 mb-lg-0">{{ item.paymentType }}</span>
                        </div>
                        <!-- END: TYPE TEMPLATE -->
                        <!-- START: PICKUP DATE TEMPLATE -->
                        <div
                            class="w-100 pe-3"
                            *ngIf="item.externalData?.pickupDate && item.externalData.pickupDate !== ''"
                        >
                            <span class="d-block invoices-list__title mb-3">Pickup date</span>
                            <span class="d-block invoices-list__value mb-3 mb-lg-0"
                                >{{ item.externalData.pickupDate | date:'M/d/YYYY' }}</span
                            >
                        </div>
                        <!-- END: PICKUP DATE TEMPLATE -->
                        <!-- START: HAWB TEMPLATE -->
                        <div class="w-100 pe-3" *ngIf="item.hawb && item.hawb !== ''">
                            <span class="d-block invoices-list__title mb-3"
                                >{{item.facility?.externalLookup ? 'File Number':'HAWB/HBL/Container'}}</span
                            >
                            <span class="d-block invoices-list__value mb-3 mb-lg-0">{{ item.hawb }}</span>
                        </div>
                        <!-- END: HAWB TEMPLATE -->
                        <!-- START: AMOUNT TEMPLATE -->
                        <div class="w-100 d-flex flex-column align-items-end">
                            <span class="d-block invoices-list__title mb-3">Amount</span>
                            <span class="d-block invoices-list__value">{{ item.amount | currency }}</span>
                        </div>
                        <!-- END: AMOUNT TEMPLATE -->
                    </div>

                    <ng-container *ngIf="customsFees.length">
                        <ng-container *ngFor="let customsFee of customsFees">
                            <div class="col-12 d-flex flex-column flex-md-row" *ngIf="customsFee.customsFee">
                                <div class="col-12 col-md-6"></div>
                                <div class="col-12 col-md-6 d-flex flex-column flex-wrap-reverse">
                                    <app-awb-fee
                                        [feeLabel]="customsFee.customsFeeLabel || 'Custom Fee'"
                                        [feeValue]="customsFee.customsFee"
                                        [valueBold]="true"
                                    ></app-awb-fee>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div mat-line>
                        <div class="d-flex justify-content-start flex-row align-items-center mt-3 mb-4">
                            <button
                                *ngIf="!item.facility?.externalLookup && (!item.facility?.awbLookupData && ((uniqueRequestor && item.originalRequestor.id === item.customer.id) || (!uniqueRequestor && !companyName)) && sprintPaySource === item.source?.name.toLowerCase())"
                                mat-button
                                class="invoices-list__btn--edit text-uppercase px-0"
                                (click)="editPaymentRequest(item)"
                            >
                                Edit
                            </button>
                            <button
                                mat-button
                                class="invoices-list__btn--delete text-uppercase px-0"
                                (click)="deletePaymentRequest(item)"
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                </ng-container>

                <div class="col-12 d-flex flex-column flex-md-row" *ngIf="!!nonReservationFee">
                    <div class="col-12 col-md-6"></div>
                    <div class="col-12 col-md-6 d-flex flex-column flex-wrap-reverse">
                        <app-awb-fee
                            [feeLabel]="'Front Counter Service Fee'"
                            [feeValue]="nonReservationFee"
                        ></app-awb-fee>
                        <app-awb-fee
                            *ngIf="!!isAwbPaidWithPromoCode"
                            [feeLabel]="'Discount Front Counter Service Fee'"
                            [feeValue]="-nonReservationFee"
                            [valueBold]="true"
                            [labelBold]="true"
                        ></app-awb-fee>
                        <app-promo-code
                            class="mt-3"
                            [awbNumber]="request.awb"
                            (eventOnOpenDropDown)="onOpenCloseDropDown()"
                            (eventOnCloseDropDown)="onOpenCloseDropDown()"
                        ></app-promo-code>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
