import { Directive, ElementRef, Input, OnDestroy, OnInit, Self } from '@angular/core';
import { AutofillMonitor } from '@angular/cdk/text-field';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fromEvent, take, tap } from 'rxjs';

/**
 * Directive for fixing FormControl with Validators.required autofill behavior. Expects FormControl as argument.
 * Checks if FormControl has required validator and removes it from FormControl for first AutofillEvent,
 * adds validator back after user interaction with document.
 */
@Directive({
    selector: '[appAutofillPatch]',
    standalone: true,
})
export class AutofillRequiredValidatorPatchDirective implements OnInit, OnDestroy {
    private _autofilled: boolean;

    @Input() appAutofillPatch?: FormControl | FormGroup;

    constructor(
        private _autofillMonitor: AutofillMonitor,
        @Self() private _elementRef: ElementRef
    ) {
        this._autofilled = false;
    }

    ngOnInit(): void {
        this._autofillMonitor.monitor(this._elementRef).subscribe((event) => {
            if (event.isAutofilled && !this._autofilled) {
                this._autofilled = true;
                if (this.appAutofillPatch?.hasValidator(Validators.required)) {
                    this.appAutofillPatch?.removeValidators(Validators.required);
                    this.appAutofillPatch?.updateValueAndValidity();
                    fromEvent(document, 'click')
                        .pipe(
                            take(1),
                            tap(() => {
                                if (!this.appAutofillPatch?.hasValidator(Validators.required)) {
                                    this.appAutofillPatch?.addValidators(Validators.required);
                                    this.appAutofillPatch?.updateValueAndValidity();
                                }
                            })
                        )
                        .subscribe();
                }
            }
        });
    }

    ngOnDestroy(): void {
        this._autofillMonitor.stopMonitoring(this._elementRef);
    }
}
