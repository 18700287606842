import { Component, OnInit } from '@angular/core';
import { SecurityService } from 'src/app/services/utils/security.service';
import { profileComponents } from 'src/app/utils/constants';

@Component({
    selector: 'app-new-user',
    templateUrl: './new-user.component.html',
})
export class NewUserComponent implements OnInit {
    isValidNewPayment: boolean;
    constructor(private _securityService: SecurityService) {
        this.isValidNewPayment = false;
    }

    ngOnInit(): void {
        this.isValidNewPayment =
            this._securityService.verifyComponentsSecurity(profileComponents.newPaymentButton) &&
            this._securityService.verifyComponentsSecurity(profileComponents.newPayment);
    }
}
