import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { NgxMaskModule } from 'ngx-mask';
import { Subject, takeUntil } from 'rxjs';
import { FormatPaymentReferencePipe } from 'src/app/pipes/format-payment-reference.pipe';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { refundReasons } from 'src/app/utils/constants';
import { CustomValidators } from 'src/app/utils/custom-validators';
import { ErrorMatcher } from 'src/app/utils/errorMatcher';
import { ProfileTypes } from 'src/app/utils/profileTypes';
import { ImageByBrandComponent } from '../image-by-brand/image-by-brand.component';

export interface RefundAmountUpdateType {
    title?: string;
    icon?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    id?: number;
    amount?: number;
    maxAmount?: number;
    refundReason?: string;
    notificationEmail?: string;
    customerReference?: string;
    hasRefundFee?: boolean;
    vendorInvoiceNumber?: string;
    customNotesPlaceholder?: string;
}

@Component({
    selector: 'app-refund-amount-update',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatRadioModule,
        MatSelectModule,
        ImageByBrandComponent,
        NgxMaskModule,
        MatCheckboxModule,
        MatIconModule,
        FormatPaymentReferencePipe,
    ],
    templateUrl: './refund-amount-update.component.html',
})
export class RefundAmountUpdateComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() popupData!: RefundAmountUpdateType;
    @Output() handleAmountUpdate: EventEmitter<any> = new EventEmitter();

    public selected!: any;
    public matcher: ErrorMatcher;
    public refundForm!: FormGroup;
    public hasRefundFee = false;
    public refundReasons: string[];
    private _unsubscribe$: Subject<void>;
    private minAmount = 0.01;
    public disabledInput = true;
    public activeProfileView: string;
    private _profileType: string;
    public profileTypes = ProfileTypes;
    public showNotes = false;

    constructor(
        private _matDialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _customerService: CustomerService,
        private _securityService: SecurityService
    ) {
        this.matcher = new ErrorMatcher();
        this._unsubscribe$ = new Subject<void>();
        this.refundReasons = refundReasons;
        this._profileType = this._securityService.getProfileType();
        this.activeProfileView = this._customerService.getActiveProfileView();
    }

    ngOnInit(): void {
        this.selected = this.popupData?.refundReason;
        this._setFromBuilder();
    }

    ngAfterViewInit(): void {
        this.onChanges();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    /**
     * @description function that is pending changes that will emit a value each time the form changes
     */
    onChanges(): void {
        this.amount?.valueChanges.pipe(takeUntil(this._unsubscribe$)).subscribe({
            next: (amount: string) => {
                if (this.popupData?.hasRefundFee) {
                    if (Number(amount) !== Number(this.popupData?.amount)) {
                        this.showNotes = true;
                        this.customerNotes?.setValidators([Validators.required]);
                    } else {
                        this.showNotes = false;
                        this.customerNotes?.setValidators(null);
                    }
                    if (this.hasRefundFee) {
                        this.refundFee?.setValidators([
                            Validators.required,
                            CustomValidators.higherValueThanAmount(Number(this.amount?.value)),
                            Validators.min(this.minAmount),
                        ]);
                        this.refundFee?.updateValueAndValidity();
                    }
                    this.customerNotes?.updateValueAndValidity();
                }
            },
        });
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */
    private _setFromBuilder(): void {
        this.refundForm = this._formBuilder.group({
            refundReason: [this.popupData?.refundReason],
            customerNotes: [this.popupData?.customerReference || null],
            amount: [
                this.popupData.amount,
                [
                    Validators.required,
                    CustomValidators.higherValueThanAmount(this.popupData?.maxAmount),
                    Validators.min(this.minAmount),
                ],
            ],
            refundFee: [null],
        });
    }

    get amount(): AbstractControl<string> | null {
        return this.refundForm.get('amount');
    }

    get refundFee(): AbstractControl<string> | null {
        return this.refundForm.get('refundFee');
    }

    /**
     * @method refundReason()
     * @description: Convenience getter for easy access to form fields
     */
    get refundReason(): AbstractControl<string> | null {
        return this.refundForm?.get('refundReason');
    }

    /**
     * @method customerNotes()
     * @description: Convenience getter for easy access to form fields
     */
    get customerNotes(): AbstractControl<string> | null {
        return this.refundForm?.get('customerNotes');
    }

    /**
     * @method refund()
     * @description
     */

    refund(): void {
        if (this.refundForm.invalid) {
            return;
        }
        this.handleAmountUpdate.emit(this.refundForm.getRawValue());
        this.closeDialog();
    }

    /**
     * @method closeDialog()
     * @description Close the dialog in this case menu right sidebar for the activity log
     */

    closeDialog(): void {
        this._matDialog.closeAll();
    }

    /**
     * @method resetCustomerNotes()
     * @description
     */
    resetCustomerNotes(event: MatSelectChange): void {
        if (this.customerNotes) {
            this.customerNotes.setValue('');

            if (event.value === 'Other') {
                this.customerNotes.setValidators([
                    Validators.maxLength(255),
                    Validators.minLength(5),
                    Validators.required,
                ]);
                this.customerNotes.markAsPristine();
                this.customerNotes.markAsUntouched();
            } else {
                this.customerNotes.clearValidators();
            }

            this.customerNotes.updateValueAndValidity();
        }
    }

    haveRefundFee(checked: boolean): void {
        this.hasRefundFee = checked;
        if (this.hasRefundFee) {
            this.refundFee?.setValidators([
                Validators.required,
                CustomValidators.higherValueThanAmount(Number(this.amount?.value)),
                Validators.min(this.minAmount),
            ]);
        } else {
            this.refundFee?.clearValidators();
            this.refundFee?.reset();
        }
        this.refundFee?.updateValueAndValidity();
    }
}
