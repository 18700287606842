import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseMFA } from 'src/app/modules/two-factor/verification-code/models/two-factor-models';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';

@Injectable()
export class TwoFactorService {
    private _authentication;

    constructor(
        private _httpClient: HttpClient,
        private _tokenService: TokenService
    ) {
        this._authentication = environment.uris.method.authentication;
    }

    requestMFA(mfaType: string, contact: string, module: string, templateCode: string): Observable<any> {
        const url = `${this._authentication}/mfa/request`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);
        const request = {
            mfaType,
            contact,
            module,
            templateCode,
        };
        return this._httpClient.post(url, request, { headers });
    }

    verifyMFA({
        mfaUuId,
        mfaCode,
        activation,
        password,
    }: {
        mfaUuId: string;
        mfaCode: string;
        activation?: any;
        password?: string;
    }): Observable<ResponseMFA> {
        const url = `${this._authentication}/mfa/verify`;
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);
        let request: any = {
            mfaUuId,
            mfaCode,
        };
        if (activation) {
            request.dynamicParameter = {
                activationUuid: activation,
            };
        }
        if (password) {
            request.dynamicParameter = {
                password: password,
                confirmPassword: password,
            };
        }
        return this._httpClient.post<ResponseMFA>(url, request, { headers });
    }
}
