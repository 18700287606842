import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormControlStatus,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    ValidatorFn,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaskModule } from 'ngx-mask';
import { Subscription, debounceTime, take } from 'rxjs';
import { DisableControlDirective } from 'src/app/directives/disable-control.directive';
import { PaymentsAPIService } from 'src/app/services/requests/payments-api.service';
import { ErrorMatcher } from 'src/app/utils/errorMatcher';
import { environment } from 'src/environments/environment';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { PaymentDetailService } from '../../payment-detail.service';
@Component({
    selector: 'app-awb',
    standalone: true,
    imports: [
        CommonModule,
        MatInputModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        DisableControlDirective,
        NgxMaskModule,
        MatProgressSpinnerModule,
    ],
    templateUrl: './awb.component.html',
})
export class AwbComponent implements OnInit, OnDestroy, OnChanges {
    public paymentForm!: FormGroup;
    public prefixes: string[];
    public currentPayment: any;
    public tooltipCustomerRefVisible: boolean;
    public matcher: ErrorMatcher = new ErrorMatcher();
    public standardFacility: boolean;
    public existPayment: boolean;
    public awbLabel = '';
    public loadingSpinner: boolean = false;
    private _paymentsURL: string;
    private _subscription: Subscription;
    @Input() formValidState: any;
    @Output() returnAWB: EventEmitter<any> = new EventEmitter<any>();
    @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private _formBuilder: FormBuilder,
        private _paymentFluxService: PaymentFluxService,
        private _paymentDetailService: PaymentDetailService,
        private _paymentsApiService: PaymentsAPIService
    ) {
        this._paymentsURL = environment.uris.method.payments;
        this.currentPayment = this._paymentFluxService.getCurrentPayment();
        this.tooltipCustomerRefVisible = false;
        this.prefixes = [];
        this._subscription = Subscription.EMPTY;
        this.standardFacility = this.currentPayment.facility.awbFieldType === 'AWB';
        this.awbLabel = this.standardFacility ? 'AWB' : 'Invoice #';
        this.existPayment = false;
    }

    ngOnInit(): void {
        this._setFromBuilder();
        this.initData();
        this.onChanges();
        this.reviewPrefixes();
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formValidState'] !== undefined) {
            if (changes['formValidState'].currentValue !== undefined) {
                if (changes['formValidState'].currentValue !== changes['formValidState'].previousValue) {
                    if (this.paymentForm !== undefined && !this.formValidState) {
                        this.prefix.markAsTouched();
                        this.number.markAsTouched();
                        this.awb.markAsTouched();
                    }
                }
            }
        }
    }

    /**
     * @method initData()
     * @description Check if there is already information, if there is information patch the value.
     */
    initData(): void {
        if (this.currentPayment && this.currentPayment.details) {
            this.paymentForm.patchValue({
                prefix: this.currentPayment.details.prefix,
                number: this.currentPayment.details.number,
                awb: this.currentPayment.details.awb,
            });
            this.existPayment = true;
            this.returnAWB.emit(this.paymentForm.getRawValue());
        }
    }

    /**
     * @method reviewPrefixes()
     * @description Function that sets the first value of the prefix or adds the validation
     */
    reviewPrefixes(): void {
        this.prefixes = this.getPrefixes();
        if (this.standardFacility && !!this.prefixes.length) {
            if (this.prefixes.length === 1) {
                this.paymentForm.patchValue({
                    prefix: this.prefixes[0],
                });
            } else {
                this.paymentForm.controls['prefix'].addValidators([this.prefixValidator(this.prefixes)]);
            }
        }
    }

    /**
     * @method getPrefixes()
     * @description Function that returns the prefixes of the currentPayment
     */
    getPrefixes(): string[] {
        if (this.currentPayment?.facility?.prefixes) {
            return this.currentPayment.facility.prefixes.split(',').map((prefix: string) => prefix.replace(/ /g, ''));
        }
        return [];
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.paymentForm = this._formBuilder.group({
            prefix: new FormControl<string | null>(null),
            number: new FormControl<string | null>(null),
            awb: new FormControl<string | null>(null),
        });
        this.paymentForm = this._paymentDetailService.validateForm(this.paymentForm);
    }

    /**
     * @method prefix()
     * @description: Convenience getter for easy access to form fields
     */

    get prefix(): FormGroup {
        return this.paymentForm.get('prefix') as FormGroup;
    }

    /**
     * @method number()
     * @description: Convenience getter for easy access to form fields
     */

    get number(): FormGroup {
        return this.paymentForm.get('number') as FormGroup;
    }

    /**
     * @method awb()
     * @description: Convenience getter for easy access to form fields
     */

    get awb(): FormControl {
        return this.paymentForm.get('awb') as FormControl;
    }

    /**
     * @method onChanges()
     * @description Function that returns the prefixes of the currentPayment
     */
    onChanges(): void {
        this._subscription = this.paymentForm.statusChanges
            .pipe(debounceTime(200))
            .subscribe((statusChanges: FormControlStatus): void => {
                if (statusChanges === 'VALID') {
                    if (this.standardFacility && !this.existPayment) {
                        this.getAwbDuplicate(this.awb.value);
                    }
                } else {
                    this.existPayment = false;
                    this.tooltipCustomerRefVisible = false;
                    this.returnAWB.emit(null);
                }
            });
    }

    prefixValidator(prefixes: string[]): ValidatorFn {
        return (control: AbstractControl) => {
            if (control.value && control.value.length === 3 && !!prefixes.length) {
                return !!prefixes.find((prefix: string): boolean => prefix === control.value)
                    ? null
                    : { invalid: true };
            }
            return null;
        };
    }

    /**
     * @method paste()
     * @param (event: ClipboardEvent)
     * @description
     */

    paste(event: ClipboardEvent): void {
        let pastedText: string | undefined = event.clipboardData?.getData('text');
        pastedText = pastedText?.replace(/[^0-9]/g, '');
        if (pastedText && pastedText.length >= 11) {
            let prefix: string;
            prefix = this.prefixes.length === 1 ? this.prefix.value : pastedText.substring(0, 3);
            const number: string = pastedText.substring(3, 11);
            this.setAwbNumber(number, prefix);
        } else if (pastedText && pastedText.length >= 8 && this.prefix.value) {
            const number: string = pastedText.substring(0, 8);
            this.setAwbNumber(number);
        }
    }

    /**
     * @method setAwbNumber()
     * @param (num: any)
     * @param (prefix?: any)
     * @description
     */

    setAwbNumber(num: any, prefix?: any): void {
        prefix ? this.prefix.setValue(prefix) : '';
        this.number.setValue(num);
        this.number.markAllAsTouched();
        this.paymentForm.get('awb')?.setValue(this.prefix.value + '-' + this.number.value);
    }

    /**
     * @method getAwbDuplicate()
     * @param (awb: any)
     * @param (msn?: any)
     * @description
     */

    getAwbDuplicate(awb: any, msn?: any): void {
        this.tooltipCustomerRefVisible = false;
        this.loading.emit(true);
        this.loadingSpinner = true;
        this._paymentsApiService
            .getAwbDuplicateCount(awb, new Date().valueOf())
            .pipe(take(1))
            .subscribe((response) => {
                this.loading.emit(false);
                this.loadingSpinner = false;
                if (response.count > 0) {
                    if (msn) {
                        let element: HTMLInputElement = <HTMLInputElement>document.getElementById('msnDuplicate');
                        if (typeof element !== 'undefined' && element !== null) {
                            element.classList.add('show');
                        }
                    } else {
                        this.tooltipCustomerRefVisible = true;
                    }
                } else {
                    this.returnAWB.emit(this.paymentForm.getRawValue());
                }
            });
    }

    /**
     * @method closeTooltip()
     * @description
     */

    closeTooltip(): void {
        this.tooltipCustomerRefVisible = false;
        this.returnAWB.emit(this.paymentForm.getRawValue());
    }

    /**
     * @method prefixKeypress()
     * @param (event: any)
     * @description
     */

    prefixKeypress(event: any): void {
        const value: any = event.target.value;
        let numbers: any = value.replace(/[^0-9]/g, '');
        event.target.value = numbers;
        if (this.number?.value?.length >= 8 && this.prefix?.value?.length === 3) {
            this.awb?.setValue(this.prefix.value + '-' + this.number.value);
        }
    }

    /**
     * @method awbKeypress()
     * @description
     */

    awbKeypress(): void {
        if (this.number.value) {
            if (this.number.value.length >= 8 && this.prefix.value) {
                this.awb?.setValue(this.prefix.value + '-' + this.number.value);
            }
        }
    }

    validateFreeText(): void {
        this.getAwbDuplicate(this.awb.value);
    }

    resetValidation(): void {
        this.returnAWB.emit(null);
    }
    /**
     * @method awbChange()
     * @description
     */

    awbChange(): void {
        this.returnAWB.emit(
            this.paymentForm.valid && !this.tooltipCustomerRefVisible ? this.paymentForm.getRawValue() : null
        );
    }
}
