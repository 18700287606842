import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class DataSecurityService {
    constructor() {}

    /**
     *  EXAMPLES:
     *
     *  console.log('OBJECT EXAMPLE');
     *
     *  const objectToEncrypt = {
     *    name: 'Alejandro Valadez Bastien',
     *    age: 32
     *  };
     *  const objectToEncryptSecurity = this._dataSecurityService.encrypt(objectToEncrypt);
     *
     *  console.table({
     *    data: objectToEncrypt,
     *    encrypt: objectToEncryptSecurity,
     *    decrypt: JSON.parse(this._dataSecurityService.decrypt(objectToEncryptSecurity))
     *  });
     *
     *  console.log('STRING EXAMPLE');
     *
     *  const stringToEncrypt = 'Alejandro Valadez Bastien';
     *  const stringToEncryptSecurity = this._dataSecurityService.encrypt(stringToEncrypt);
     *
     *  console.table({
     *    data: stringToEncrypt,
     *    encrypt: stringToEncryptSecurity,
     *    decrypt: this._dataSecurityService.decrypt(stringToEncryptSecurity)
     *  });
     *
     *  console.log('ARRAY EXAMPLE');
     *
     *  const arrayToEncrypt = ['element no.1', 'element no.2', 'element no.3'];
     *  const arrayToEncryptSecurity = this._dataSecurityService.encrypt(arrayToEncrypt);
     *
     *  console.table({
     *    data: arrayToEncrypt,
     *    encrypt: arrayToEncryptSecurity,
     *    decrypt: this._dataSecurityService.decrypt(arrayToEncryptSecurity).split(',')
     *  });
     */

    /**
     * @method encrypt()
     * @param (data: any)
     * @description Using the CRYPTO-JS Library we will transform the data into an encrypt and secure information
     */

    encrypt(data: any): string {
        if (typeof data === 'string') {
            return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data)).toString();
        } else if (data instanceof Array) {
            return CryptoJS.AES.encrypt(data.toString(), environment.cryptoKey).toString();
        } else if (typeof data === 'object') {
            return CryptoJS.AES.encrypt(JSON.stringify(data), environment.cryptoKey).toString();
        } else {
            return CryptoJS.AES.encrypt(data, environment.cryptoKey).toString();
        }
    }

    /**
     * @method decryptString()
     * @param (data: any)
     * @description Using the CRYPTO-JS Library we will transform the data into a decrypt and secure information. This will return a string value
     */

    decryptString(data: string): string | null {
        try {
            return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
        } catch (error) {
            return null;
        }
    }

    /**
     * @method decryptArray()
     * @param (data: any)
     * @description Using the CRYPTO-JS Library we will transform the data into a decrypt and secure information. This will return an array value
     */

    decryptArray(data: string): Array<any> {
        return CryptoJS.AES.decrypt(data.toString(), environment.cryptoKey).toString(CryptoJS.enc.Utf8).split(',');
    }

    /**
     * @method decryptObject()
     * @param (data: any)
     * @description Using the CRYPTO-JS Library we will transform the data into a decrypt and secure information. This will return an object value
     */

    decryptObject(data: string): Object {
        return JSON.parse(CryptoJS.AES.decrypt(data, environment.cryptoKey).toString(CryptoJS.enc.Utf8));
    }

    /**
     * @method decryptOther()
     * @param (data: any)
     * @description Using the CRYPTO-JS Library we will transform the data into a decrypt and secure information. This will return an any value
     */

    decryptOther(data: string): any {
        return CryptoJS.AES.decrypt(data, environment.cryptoKey).toString(CryptoJS.enc.Utf8);
    }
}
