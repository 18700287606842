import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Facility } from 'src/app/models/payments/facility.model';
import { Payment } from 'src/app/models/payments/payment.model';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { PaymentDetailComponent } from '../../../payment-flux/payment-detail/payment-detail.component';
import { StandardReferenceComponent } from '../../../payment-flux/standard-reference/standard-reference.component';
@Component({
    selector: 'app-house-charges',
    templateUrl: './house-charges.component.html',
})
export class HouseChargesComponent implements OnInit {
    @ViewChild(PaymentDetailComponent) paymentDetailsComponent!: PaymentDetailComponent;
    @ViewChild(StandardReferenceComponent) standardReferenceComponent!: StandardReferenceComponent;
    @Input() set dataSource(source: MatTableDataSource<any[]>) {
        this.datasourceHouse = source;
        this.getTotalDues();
    }
    public datasourceHouse: MatTableDataSource<any[]> = new MatTableDataSource();
    public displayedColumnsNameHBL: string[] = [];
    public dynamicValues: any;
    public totalDues: number = 0;
    private facilityData: Facility;
    private _currentPayment: Payment;

    constructor(
        private _formBuilder: NonNullableFormBuilder,
        private _paymentFluxService: PaymentFluxService
    ) {
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.facilityData = this._currentPayment.facility;
    }

    ngOnInit(): void {
        this.getHouseColumns();
    }

    getHouseColumns(): void {
        const dynamicField = this.facilityData?.externalLookupDetails?.dynamicField;
        if (dynamicField) {
            this.displayedColumnsNameHBL = dynamicField?.house
                .filter((house: any) => house.type !== 'request')
                ?.map((item: any) => {
                    return item.label;
                });
            this.displayedColumnsNameHBL.push('Select');
        }
    }

    getTotalDues(): void {
        this.totalDues = 0;
        if (this.datasourceHouse.filteredData && this.datasourceHouse.filteredData.length > 0) {
            this.datasourceHouse.filteredData.forEach((item: any) => {
                this.totalDues += item['Amount to pay'];
            });
        }
    }
}
