import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-confirmation-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<unknown>,
        @Inject(MAT_DIALOG_DATA)
        public data: { title: string; description: string; icon?: string; confirmText?: string; cancelText?: string }
    ) {}

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }
}
