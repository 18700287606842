import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Observable, Subject, Subscription, filter, map, takeUntil, throwError } from 'rxjs';
import { SideNavEndService } from 'src/app/services/sidenav-end.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { SessionService } from 'src/app/services/utils/session.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { profileComponents } from 'src/app/utils/constants';
import { ProfileTypes } from 'src/app/utils/profileTypes';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/utils/auth.service';
import { PortalSelectorComponent } from '../portal-selector/portal-selector.component';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    standalone: true,
    imports: [CommonModule, SharedModule, PortalSelectorComponent, MatToolbarModule, MatIconModule, RouterModule],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
    @Output() toggleSidenav: EventEmitter<void> = new EventEmitter();
    @Output() redirectLink: EventEmitter<string> = new EventEmitter<string>();

    ProfileTypesEnum = ProfileTypes;
    profileType: string;
    newPaymentSecurity: Array<string>;
    cartSecurity: Array<string>;
    showNewPayment: boolean;
    showCart: boolean;
    elementsInCart: number;
    public hasMultiPortalAccess$: Observable<boolean>;
    private _userType: string;
    private readonly _customer: any;
    private _customerSubscription: Subscription = new Subscription();
    private _subscription: Subscription;
    private _unsubscribe$: Subject<void>;

    constructor(
        private _authService: AuthService,
        private _customerService: CustomerService,
        private _securityService: SecurityService,
        private _router: Router,
        private _sessionService: SessionService,
        private _sidenavEndService: SideNavEndService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this._subscription = Subscription.EMPTY;
        this.profileType = this._authService.getSprintPayProfileType();
        this.newPaymentSecurity = [''];
        this.cartSecurity = [''];
        this._userType = '';
        this.elementsInCart = 0;
        this.showNewPayment = String(this._router.url).indexOf('newPayment') <= 0;
        this.showCart = String(this._router.url).indexOf('cart') <= 0;
    }

    ngOnInit(): void {
        this._handleSecurity();
        this._getCartCount();
        this._loadCustomer();
        this._subscription = this._router.events
            .pipe(
                filter((event): boolean => event instanceof NavigationEnd),
                map((event) => event as NavigationEnd)
            )
            .subscribe({
                next: (event): void => {
                    this.showCart = String(event.url).indexOf('cart') <= 0;
                    this.showNewPayment = String(event.url).indexOf('newPayment') <= 0;
                },
                error: (error: Error): void => {
                    throwError(() => error);
                },
            });
    }

    ngOnDestroy(): void {
        Swal.close();
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
        this._customerSubscription?.unsubscribe();
    }

    toggleMenu(): void {
        this.toggleSidenav.emit();
    }

    goToCart(path: string): void {
        this.redirectLink.emit(path);
    }

    /**
     * @method _handleSecurity()
     * @description Handles the logic for the security based on user and roles
     */

    private _handleSecurity(): void {
        this._userType = this._securityService.getUserType();
        if (this._securityService.verifyComponentsSecurity(profileComponents.newPaymentButton)) {
            this.newPaymentSecurity.push(this._userType);
        }
        if (this._securityService.verifyComponentsSecurity(profileComponents.cart)) {
            this.cartSecurity.push(this._userType);
        }
    }

    /**
     * @method _getCartCount()
     * @description Return the number of element on the shopping cart
     */

    private _getCartCount(): void {
        this._sessionService
            .getPayCount()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe({
                next: (result: number | null): void => {
                    this.elementsInCart = result || 0;
                },
                error: (error: Error): void => {
                    throwError(() => error);
                },
            });
    }

    private _loadCustomer(): void {
        this._customerSubscription.add(
            this._customerService.getProfileType$().subscribe((profileType) => {
                this.profileType = profileType;
            })
        );
    }
}
