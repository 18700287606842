import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { PaymentDetailRoutingModule } from './payment-detail-routing.module';

//COMPONENTS

import { PaymentDetailComponent } from './payment-detail.component';

//MODULES

import { SharedModule } from '../../../../../shared/shared.module';

@NgModule({
    declarations: [PaymentDetailComponent],
    exports: [PaymentDetailComponent],
    imports: [CommonModule, PaymentDetailRoutingModule, RouterModule, ReactiveFormsModule, FormsModule, SharedModule],
})
export class PaymentDetailModule {
    constructor() {}
}
