<!--START: PICKUP DATE -->
<form class="row" [formGroup]="form">
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>Pickup date</mat-label>
            <input
                #pickupDateInputRef
                matInput
                [matDatepicker]="picker"
                [formControl]="pickupDate"
                (focus)="picker.open()"
                (dateChange)="setPickupDate($event)"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker (closed)="pickupDateInputRef.blur()"></mat-datepicker>
            <mat-error *ngIf="pickupDate.hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
        </mat-form-field>
    </div>
</form>
<!--END: PICKUP DATE -->
