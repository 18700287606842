import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

export interface ErrorInputFormComponent {
    code: string;
    message: string;
}

@Component({
    standalone: true,
    selector: 'app-form-error',
    templateUrl: './form-error.component.html',
    styleUrls: ['./form-error.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    imports: [CommonModule],
})
export class InvalidFormComponent {
    constructor(private parent: FormGroupDirective) {}

    @Input() messageList: ErrorInputFormComponent[] = [];

    get form(): any {
        return this.parent.form;
    }

    get errorVisible(): boolean {
        if (!this.parent.form.errors) {
            return false;
        }

        return true;
    }
}
