import { Component, OnInit } from '@angular/core';
import { PaymentMethods, PaymentTab } from 'src/app/utils/cartTypes';
import { BreadcrumbService } from 'xng-breadcrumb';
import { CustomerService } from '../../../../services/utils/customer-handler.service';
import { SecurityService } from '../../../../services/utils/security.service';

@Component({
    selector: 'app-payment-methods',
    templateUrl: './payment-methods.component.html',
})
export class PaymentMethodsComponent implements OnInit {
    tabs: PaymentTab[];
    PaymentMethods = PaymentMethods;

    constructor(
        private _breadcrumbService: BreadcrumbService,
        private _customerService: CustomerService,
        private _securityService: SecurityService
    ) {
        this._breadcrumbService.set('@payment-methods', 'Payment methods');
        this._breadcrumbService.set('@payment-methods-list', 'Payment methods list');
        this.tabs = [];
    }

    ngOnInit(): void {
        const components = this._customerService.getCustomerProfile().components;

        if (
            components.some((type: any): boolean => type.name === PaymentMethods.CREDIT_CARD) &&
            this._verifySecurity(PaymentMethods.CREDIT_CARD)
        ) {
            this.tabs.push({ type: PaymentMethods.CREDIT_CARD, label: 'Credit cards' });
        }

        if (
            components.some((type: any): boolean => type.name === PaymentMethods.ECHECK) &&
            this._verifySecurity(PaymentMethods.ECHECK)
        ) {
            this.tabs.push({ type: PaymentMethods.ECHECK, label: 'Echeck' });
        }

        if (
            components.some((type: any): boolean => type.name === PaymentMethods.CARGOSPRINT_CREDIT) &&
            this._verifySecurity(PaymentMethods.CARGOSPRINT_CREDIT)
        ) {
            this.tabs.push({ type: PaymentMethods.CARGOSPRINT_CREDIT, label: 'SprintPay Credit' });
        }
    }

    /**
     * @method _verifySecurity()
     * @param (paymentMethod: PaymentMethods)
     * @description Check payment method availability
     */

    private _verifySecurity(paymentMethod: PaymentMethods): boolean {
        return this._securityService.verifyComponentsSecurity(paymentMethod);
    }
}
