<ng-container *ngIf="invoices.length > 0">
    <!--START:  CONTAINER INVOICE -->
    <section id="card-invoice" class="w-100">
        <div class="row">
            <div class="col-12">
                <!--START: HEADER-->
                <!--START: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
                <div class="row">
                    <div class="col-12 col-md-5 d-flex align-items-center justify-content-start">
                        <h6>Open Invoices</h6>
                        <div class="d-none d-md-flex align-items-baseline" *ngIf="selectedInvoices.length > 0">
                            <span>&nbsp;{{ selectedInvoices.length}}&nbsp;invoice(s) selected</span>
                        </div>
                    </div>
                    <ng-container *ngxPermissionsOnly="addToCartCapability">
                        <div class="col-7 justify-content-end d-none d-md-flex align-items-center">
                            <div class="align-items-baseline">
                                <mat-checkbox
                                    class="d-block me-3"
                                    [checked]="isAllSelected"
                                    [indeterminate]="someSelected()"
                                    (change)="selectAllInvoices($event.checked)"
                                    data-cy="open_invoices_selectAll"
                                    [color]="'primary'"
                                    >Select all</mat-checkbox
                                >
                            </div>
                            <div
                                class="align-items-baseline"
                                *ngIf="!isAutomatedDebitsEnabled; else createFileTemplate"
                            >
                                <button
                                    mat-button
                                    type="button"
                                    (click)="addInvoiceToCart()"
                                    class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 add_to_cart"
                                >
                                    ADD TO CART
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!--END: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
                <!--START: HEADER AND ACTION BUTTONS FOR SMALL SCREEN -->
                <ng-container *ngxPermissionsOnly="addToCartCapability">
                    <div class="row">
                        <div class="d-flex d-md-none">
                            <div class="col-6 d-flex align-items-center justify-content-start">
                                <div class="align-items-baseline">
                                    <mat-checkbox
                                        class="d-block"
                                        [checked]="isAllSelected"
                                        [indeterminate]="someSelected()"
                                        (change)="selectAllInvoices($event.checked)"
                                        data-cy="open_invoices_selectAll"
                                        [color]="'primary'"
                                        >Select all</mat-checkbox
                                    >
                                </div>
                            </div>
                            <div class="col-6 justify-content-end d-flex align-items-center">
                                <div class="align-items-baseline">
                                    <button
                                        mat-button
                                        type="button"
                                        (click)="addInvoiceToCart()"
                                        class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 add_to_cart"
                                    >
                                        ADD TO CART
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!--END: HEADER AND ACTION BUTTONS FOR SMALL SCREEN -->
                <!--START: CARD INVOICES-->
                <div class="row">
                    <article
                        *ngFor="let item of invoices; trackBy: trackBy"
                        class="col-sm-12 col-md-6 col-lg-3 mb-3 d-block invoice mt-3 justify-content-between align-items-start"
                    >
                        <div
                            class="card"
                            id="invoice-{{ item.id }}"
                            [ngClass]="{selected: selectedInvoices.includes(item.id)}"
                        >
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-8 d-flex align-items-center justify-content-start">
                                        <div class="align-items-baseline">
                                            <span class="fw-bold d-block title">{{item.name}}</span>
                                        </div>
                                    </div>
                                    <ng-container *ngxPermissionsOnly="addToCartCapability">
                                        <div class="col-4 justify-content-end d-flex align-items-center p-0">
                                            <div class="align-items-baseline d-flex">
                                                <button
                                                    mat-button
                                                    type="button"
                                                    class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 select_invoice"
                                                    data-cy="open_invoices_item"
                                                    (click)="selectedInvoice(item)"
                                                    [disabled]="!!item?.achDebitId"
                                                >
                                                    {{selectedInvoices.includes(item.id) ? "REMOVE" : "ADD"}}
                                                </button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="row">
                                    <!--START: CARD INVOICES BALANCE-->
                                    <div class="col-6 mb-3">
                                        <span class="d-block subtitle">Balance due</span>
                                        <span class="d-block information">{{item.balanceDue | currency}}</span>
                                    </div>
                                    <!--END: CARD INVOICES BALANCE-->
                                    <!--START: CARD INVOICES DUE DATE-->
                                    <div class="col-6">
                                        <span class="d-block subtitle">Due date</span>
                                        <span class="d-block information">{{item.dueDate | date: "MM/dd/yyyy"}}</span>
                                    </div>
                                    <!--END: CARD INVOICES DUE DATE-->
                                </div>
                                <div class="row">
                                    <div class="d-grid align-items-center text-center overdue">
                                        <span class="mt-2 mb-2"
                                            >{{item.overdueMessage}} <label *ngIf="item.overdue">overdue</label></span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
                <!--END: CARD INVOICES-->
            </div>
        </div>
    </section>
    <!--END: CONTAINER INVOICE-->
</ng-container>

<!--START: CREATE FILE TEMPLATE -->
<ng-template #createFileTemplate>
    <div class="align-items-baseline d-flex flex-column flex-md-row gap-2 gap-md-0">
        <button
            mat-button
            type="button"
            (click)="createFile()"
            class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 pay-with-bank-debit"
            [disabled]="!hasBankAccounts"
        >
            Pay with Bank debit
        </button>
        <button
            mat-button
            type="button"
            class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 align-self-center"
            routerLink="../bank-debits"
            *ngIf="!hasBankAccounts"
        >
            Add Bank Account
        </button>
    </div>
</ng-template>
<!--START: CREATE FILE TEMPLATE -->
