<!--START: PAYMENT TYPE -->
<ng-container *ngIf="paymentForm">
    <form class="row" [formGroup]="paymentForm">
        <!--START: DYNAMIC FIELDS-->
        <div *ngFor="let field of fieldsPaymentDetails">
            <app-dynamic-container
                [field]="field"
                [dynamicFormGroupName]="formNamePaymentDetails"
                [dynamicFormGroup]="paymentForm"
            ></app-dynamic-container>
        </div>
        <!--END: DYNAMIC FIELDS-->
    </form>
</ng-container>
<!--END: PAYMENT TYPE-->
