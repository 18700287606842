import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, take } from 'rxjs';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { FacilitySearchService } from '../services/facility-search.service';

@Component({
    selector: 'app-qr-scanner',
    templateUrl: './qr-scanner.component.html',
    styleUrls: ['./qr-scanner.scss'],
})
export class QRScannerComponent implements OnDestroy {
    DELAY_BETWEEN_SCAN_SUCCESS: number = 2000;
    formats: BarcodeFormat[];
    cameras: MediaDeviceInfo[];
    selectedDevice?: MediaDeviceInfo;
    error?: string;

    @ViewChild(ZXingScannerComponent) scanner?: ZXingScannerComponent;

    constructor(
        private _router: Router,
        private _paymentFluxService: PaymentFluxService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _facilitySearchService: FacilitySearchService
    ) {
        this.formats = [BarcodeFormat.QR_CODE];
        this.cameras = [];
    }

    ngOnDestroy(): void {
        if (this.scanner) {
            this.scanner.enable = false;
        }
    }

    /**
     * @method handleScanSuccess()
     * @param (urlString: string)
     * @description Get payment request data from QR code
     */

    handleScanSuccess(urlString: string): void {
        this.error = undefined;
        const url = new URL(urlString);
        const token = url.search.substring(1);

        this._ngxSpinnerService.show();

        this._facilitySearchService
            .decodePaymentFromToken(token)
            .pipe(
                take(1),
                finalize(() => {
                    this._ngxSpinnerService.hide();
                })
            )
            .subscribe({
                next: (currentPayment): void => {
                    if (currentPayment.facility.payUrl && currentPayment.facility.awbLookupData === null) {
                        this._facilitySearchService
                            .showExternalFacilityRedirectPopup(currentPayment.facility.name)
                            .then((result): void => {
                                if (result.isConfirmed) {
                                    window.open(currentPayment.facility.payUrl, '_blank');
                                }
                            });
                    } else if (currentPayment.facility.notesForClients) {
                        this._facilitySearchService
                            .showNotesForClientsPopup(currentPayment.facility)
                            .then((result): void => {
                                if (result.isConfirmed) {
                                    this._paymentFluxService.setCurrentPayment(currentPayment);
                                    this._router.navigate(['/admin/facilityPayments/newPayment/flux']);
                                }
                            });
                    } else {
                        this._paymentFluxService.setCurrentPayment(currentPayment);
                        this._router.navigate(['/admin/facilityPayments/newPayment/flux']);
                    }
                },
                error: (error: HttpErrorResponse | string): void => {
                    this.error = error instanceof HttpErrorResponse ? 'Wrong QR code' : error;
                },
            });
    }

    /**
     * @method handleCamerasFound()
     * @param (cameras: MediaDeviceInfo[])
     * @description
     */

    handleCamerasFound(cameras: MediaDeviceInfo[]): void {
        this.cameras = cameras;
        this.selectedDevice = cameras[0];
    }

    /**
     * @method handleCamerasNotFound()
     * @description Handle error when camera not available
     */

    handleCamerasNotFound(): void {
        this.error = 'Camera not available';
    }

    /**
     * @method handlePermissionResponse()
     * @param (hasPermission: boolean)
     * @description Get facility details API call
     */

    handlePermissionResponse(hasPermission: boolean): void {
        if (!hasPermission) {
            this.error = `You didn't provide permissions for camera`;
        }
    }

    /**
     * @method showDefaultSearch()
     * @description Switch to default search
     */

    showDefaultSearch(): void {
        this._facilitySearchService.setShowDefaultSearch(true);
    }

    /**
     * @method objectComparisonFunction()
     * @param (option: MediaDeviceInfo)
     * @param (value: MediaDeviceInfo)
     * @description
     */

    objectComparisonFunction(option: MediaDeviceInfo, value: MediaDeviceInfo): boolean {
        return option.deviceId === value.deviceId;
    }
}
