import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { NotificationsType } from 'src/app/models/notifications/notifications';
import { PipesSharedModule } from 'src/app/pipes/pipes.module';
import { BannerService } from './services/banner.service';

@Component({
    standalone: true,
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    imports: [CommonModule, PipesSharedModule],
})
export class BannerComponent implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();
    public notifications: NotificationsType[] = [];

    constructor(private _bannerService: BannerService) {}

    ngOnInit(): void {
        this.getNotifications();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getNotifications(): void {
        this._bannerService
            .getNotifications()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (notifications) => {
                    this.notifications = notifications;
                },
            });
    }

    closeBanner(notification: NotificationsType): void {
        const filteredNotifications = this.notifications.filter((record) => record !== notification);
        this._bannerService.setNotifications(filteredNotifications);
    }
}
