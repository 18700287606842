import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//MODULES
import { SharedModule } from '../../../shared/shared.module';
import { CardInvoiceComponent } from './components/card-invoice/card-invoice.component';
import { CardWalletComponent } from './components/card-wallet/card-wallet.component';
import { CreditCardHoldComponent } from './components/card-wallet/credit-card-hold/credit-card-hold.component';
import { SpCreditWidgetComponent } from './components/card-wallet/sp-credit-widget/sp-credit-widget.component';
import { FacilitiesComponent } from './components/facilities/facilities.component';
import { NewUserComponent } from './components/new-user/new-user.component';
import { PendingApprovalComponent } from './components/pending-approval/pending-approval.component';
import { RejectedRequestComponent } from './components/rejected-request/rejected-request.component';
// ROUTING
import { HomeRoutingModule } from './home-routing.module';
//COMPONENTS
import { HomeComponent } from './home.component';

@NgModule({
    declarations: [
        HomeComponent,
        CardInvoiceComponent,
        FacilitiesComponent,
        CardWalletComponent,
        NewUserComponent,
        PendingApprovalComponent,
        RejectedRequestComponent,
        CreditCardHoldComponent,
        SpCreditWidgetComponent,
    ],
    imports: [CommonModule, HomeRoutingModule, RouterModule, ReactiveFormsModule, FormsModule, SharedModule],
})
export class HomeModule {
    constructor() {}
}
