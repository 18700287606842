import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import * as moment from 'moment/moment';
import { downloadFormats } from 'src/app/utils/constants';

@Component({
    selector: 'app-download',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatRadioModule,
        ReactiveFormsModule,
    ],
    templateUrl: './download.component.html',
})
export class DownloadComponent implements OnInit, OnDestroy {
    @Input() exportable: any;
    @Input() recordSelected: any = [];
    @Input() isSuper?: boolean;
    @Output() downloadRange: EventEmitter<any> = new EventEmitter();

    fileKind: any;
    minDateFrom: any;
    minDateTo: any;
    maxDateFrom: any;
    maxDateTo: any;
    fileFormat: string;
    dateRangeForm: FormGroup;
    private _dateFrom: any;
    private _dateTo: any;
    private readonly _dateRange: number;
    private readonly _defaultDateRange: number;

    constructor(private _matDialog: MatDialog) {
        this._dateFrom = null;
        this._dateTo = null;
        this._dateRange = 90;
        this.maxDateFrom = new Date();
        this.minDateFrom = new Date('2011-01-02');
        this.maxDateTo = new Date();
        this.minDateTo = new Date('2011-01-02');
        this.fileFormat = downloadFormats.XLSX;
        this._defaultDateRange = 7;
        this.dateRangeForm = new FormGroup({
            fromInput: new FormControl<Date | null>(null),
            toInput: new FormControl<Date | null>(null),
        });
    }

    ngOnInit(): void {
        if (this.exportable && Object.entries(this.exportable).length > 0) {
            this.exportable.kind.map((item: any) => {
                if (item.default) {
                    this.fileKind = item;
                }
            });
        }
        if (!!this.isSuper) {
            this.initDateRange();
        }
    }

    ngOnDestroy(): void {
        this.closeDialog();
    }

    /**
     * @method download()
     * @description
     */

    download() {
        if (this.fileKind) {
            let downloadObject: any = { type: this.fileKind.type, format: this.fileFormat };
            if (this._dateFrom !== null && this._dateTo !== null) {
                downloadObject = {
                    from: moment(this._dateFrom).format('YYYY-MM-DD'),
                    to: moment(this._dateTo).format('YYYY-MM-DD'),
                    type: this.fileKind.type,
                    format: this.fileFormat,
                };
            }
            this.downloadRange.emit(downloadObject);
            this.closeDialog();
        }
    }

    /**
     * @method onDateChangedFrom()
     * @param (event?: MatDatepickerInputEvent<Date>)
     * @description
     */

    onDateChangedFrom(event?: MatDatepickerInputEvent<Date>) {
        const diffDays: number = 1000 * 60 * 60 * 24 * this._dateRange;
        if (event && event.target.value) {
            if (this._dateFrom) {
                this._dateTo = null;
                this.dateRangeForm.setValue({
                    fromInput: event.target.value,
                    toInput: this._dateTo,
                });
            }

            this._dateFrom = event.target.value;
            const dateTo: Date = new Date(event.target.value);
            let daysAfter = new Date(dateTo.getTime() + diffDays);
            const today: Date = new Date();
            if (daysAfter >= today) {
                daysAfter = today;
            }

            this.maxDateTo = daysAfter;
            this.minDateTo = this._dateFrom;
        } else {
            this.maxDateFrom = new Date();
            this.minDateFrom = new Date('2011-01-02');
        }
    }

    /**
     * @method onDateChangedTo()
     * @param (event: MatDatepickerInputEvent<Date>)
     * @description
     */

    onDateChangedTo(event: MatDatepickerInputEvent<Date>) {
        const date: Date | null = event.target.value;
        if (date! > this._dateFrom) {
            this._dateTo = date;
        } else {
            this._dateTo = this._dateFrom;
            this._dateFrom = date;
        }
        this.onDateChangedFrom();
    }

    /**
     * @method initDateRange()
     * @param ()
     * @description
     */

    initDateRange() {
        const today = new Date();
        const diffDays: number = 1000 * 60 * 60 * 24 * this._defaultDateRange;
        const startDate: Date = new Date(today.getTime() - diffDays);

        this.dateRangeForm.setValue({
            fromInput: startDate,
            toInput: today,
        });

        this._dateFrom = startDate;
        this._dateTo = today;
    }

    /**
     * @method closeDialog()
     * @description Close the dialog in this case menu right sidebar for the activity log
     */

    closeDialog(): void {
        this._matDialog.closeAll();
    }

    /**
     * @method selectFormat()
     * @param (kind: any)
     * @description Select file kind to download
     */

    selectFormat(kind: any) {
        this.fileKind = kind;
    }

    /**
     * @method hasValid()
     * @description
     */

    hasValid() {
        if (!!this.isSuper) {
            return this.recordSelected.length > 0 ? true : !!this._dateTo && !!this._dateFrom;
        }
        return true;
    }
}
