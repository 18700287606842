export interface PaymentRefund {
    id?: number;
    requestedBy?: RequestedBy;
    requestedAmount?: number;
    approvedAmount?: number;
    payment?: Payment;
    refundReason?: string;
    statusReason?: string | null;
    status?: CustomStatuses;
    customerNotes?: string | null;
    notificationEmail?: string;
    refundFee?: string | null;
    totalRefundedAmount?: string | null;
    dateRequested?: number;
    createdDate?: number;
    lastModifiedDate?: number;
    salesforceId?: string;
    customerReference?: string;
    rejectedReason?: string;
}

export interface UpdatePaymentRefund {
    paymentRefundId: number;
    rejectedReason: string;
    status: string;
    refundReason?: string;
    notificationEmail?: string;
    customerNotes?: string;
    approvedAmount?: number;
    requestedAmount?: number;
    refundFee?: number;
}

interface RequestedBy {
    id?: number;
    email?: string;
    lastName?: string;
    firstName?: string;
}

interface Payment {
    id?: number;
    facility?: Facility;
    awb?: string;
    hawb?: string | null;
    paymentType?: string;
    paidTo?: string;
    amount?: number;
    vendorInvoiceNumber?: string;
}

interface Facility {
    id?: number;
    name?: string;
}

export enum CustomStatuses {
    REQUESTED = 'REQUESTED',
    APPROVED = 'APPROVED',
    FINALIZED = 'FINALIZED',
    DENIED = 'DENIED',
    REQUESTED_CARGO_FACILITY = 'REQUESTED_CARGO_FACILITY',
    REQUEST_CANCELED_BY_CUSTOMER = 'REQUEST_CANCELED_BY_CUSTOMER',
    IN_PROGRESS = 'IN_PROGRESS',
}

export const RefundStatusDescription = Object.freeze({
    [CustomStatuses.REQUESTED]: 'Requested by customer',
    [CustomStatuses.APPROVED]: 'Approved by cargo facility',
    [CustomStatuses.FINALIZED]: 'Finalized - Credit issued',
    [CustomStatuses.DENIED]: 'Denied by cargo facility',
    [CustomStatuses.REQUESTED_CARGO_FACILITY]: 'Requested by cargo facility',
    [CustomStatuses.REQUEST_CANCELED_BY_CUSTOMER]: 'Request canceled by the customer',
    [CustomStatuses.IN_PROGRESS]: 'Request submitted to cargo facility',
});

export const RefundStatusIcon = Object.freeze({
    [CustomStatuses.REQUESTED]: 'front_hand',
    [CustomStatuses.APPROVED]: 'task_alt',
    [CustomStatuses.FINALIZED]: 'done_all',
    [CustomStatuses.DENIED]: 'cancel',
    [CustomStatuses.REQUESTED_CARGO_FACILITY]: 'warehouse',
    [CustomStatuses.REQUEST_CANCELED_BY_CUSTOMER]: 'cancel',
    [CustomStatuses.IN_PROGRESS]: 'pending',
});

export const StatusEnabledForUpdateAmount = [
    CustomStatuses.IN_PROGRESS,
    CustomStatuses.REQUESTED_CARGO_FACILITY,
    CustomStatuses.REQUESTED,
];
