import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'notAvailable',
    standalone: true,
})
export class NotAvailablePipe implements PipeTransform {
    constructor() {}

    /**
     * @method transform()
     * @param (value: any)
     * @description If no value provided, replace it with 'N/A' in template
     */

    transform(value: any): string {
        return value && value.toString().trim().length ? value : 'N/A';
    }
}
