import { Overlay } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CSCreditService } from 'src/app/services/cs-credit.service';
import { Balance } from 'src/app/utils/cartTypes';

@Component({
    selector: 'app-sp-credit-widget',
    templateUrl: './sp-credit-widget.component.html',
})
export class SpCreditWidgetComponent implements OnInit, OnDestroy {
    sPCredit: Balance;
    sPCreditPercentage: number;
    isLoading: boolean;
    private _unsubscribe$: Subject<void>;

    constructor(
        private _csCreditService: CSCreditService,
        private overlay: Overlay
    ) {
        this.sPCredit = {};
        this.sPCreditPercentage = 0;
        this._unsubscribe$ = new Subject<void>();
        this.isLoading = false;
    }

    ngOnInit(): void {
        this.getCSCreditBalance();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    getCSCreditBalance(): void {
        this._csCreditService
            .getIsProcessing$()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe({
                next: (isProcessing) => {
                    this.isLoading = isProcessing;
                    const creditBalance = this._csCreditService.instant_cs_credit_amount;
                    if (!this.isLoading && creditBalance) {
                        this.sPCredit = {
                            availableCredit: creditBalance?.availableCredit,
                            creditLimit: creditBalance?.creditLimit,
                        };
                        this.sPCreditPercentage = Number(creditBalance?.creditPercentage);
                    }
                },
                error: (error) => {
                    this.isLoading = false;
                },
            });
    }
}
