import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, finalize, take } from 'rxjs';
import { GuestService } from 'src/app/services/guest.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { UserSessionService } from 'src/app/services/utils/user-session.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { FacilitySearchService } from './services/facility-search.service';

@Component({
    selector: 'app-new-payment',
    templateUrl: './new-payment.component.html',
})
export class NewPaymentComponent implements OnInit, OnDestroy {
    showDefaultSearch$: Observable<boolean>;
    isProcessing: boolean;

    constructor(
        private _breadcrumbService: BreadcrumbService,
        private _facilitySearchService: FacilitySearchService,
        private _activatedRoute: ActivatedRoute,
        private _ngxSpinnerService: NgxSpinnerService,
        private _paymentFluxService: PaymentFluxService,
        private _router: Router,
        private _guestService: GuestService,
        private _userSessionService: UserSessionService
    ) {
        this.isProcessing = false;
        this.showDefaultSearch$ = this._facilitySearchService.getShowDefaultSearch();
        this._breadcrumbService.set('@new-payment', 'New payment');
    }

    ngOnInit(): void {
        if (Object.keys(this._activatedRoute.snapshot.queryParams).length) {
            this._subscribeDecodePaymentFromToken();
        }
    }

    ngOnDestroy(): void {
        localStorage.removeItem('lastAWB');
        this._facilitySearchService.setShowDefaultSearch(false);
    }

    _subscribeDecodePaymentFromToken(): void {
        const token = Object.keys(this._activatedRoute.snapshot.queryParams)[0];
        this.isProcessing = true;

        this._guestService.showPaymentLimitPopup().then(({ isConfirmed }) => {
            if (isConfirmed) {
                this._ngxSpinnerService.show();

                this._facilitySearchService
                    .decodePaymentFromToken(token)
                    .pipe(
                        take(1),
                        finalize(() => {
                            this._ngxSpinnerService.hide();
                        })
                    )
                    .subscribe({
                        next: (currentPayment): void => {
                            if (currentPayment.facility.payUrl && currentPayment.facility.awbLookupData === null) {
                                this._facilitySearchService
                                    .showExternalFacilityRedirectPopup(currentPayment.facility.name)
                                    .then((result): void => {
                                        if (result.isConfirmed) {
                                            window.open(currentPayment.facility.payUrl, '_blank');
                                        }

                                        this._router.navigate(['/admin/facilityPayments/newPayment']).then(() => {
                                            this.isProcessing = false;
                                        });
                                    });
                            } else if (currentPayment.facility.notesForClients) {
                                this._facilitySearchService
                                    .showNotesForClientsPopup(currentPayment.facility)
                                    .then((result): void => {
                                        if (result.isConfirmed) {
                                            this._paymentFluxService.setCurrentPayment(currentPayment);
                                            this._router
                                                .navigate(['/admin/facilityPayments/newPayment/flux'])
                                                .then(() => {
                                                    this.isProcessing = false;
                                                });
                                        } else {
                                            this._userSessionService.logout();
                                        }
                                    });
                            } else {
                                this._paymentFluxService.setCurrentPayment(currentPayment);
                                this._router.navigate(['/admin/facilityPayments/newPayment/flux']).then(() => {
                                    this.isProcessing = false;
                                });
                            }
                        },
                        error: (): void => {
                            this._userSessionService.logout();
                        },
                    });
            } else {
                this._userSessionService.logout();
            }
        });
    }
}
