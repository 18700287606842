import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { ActivateRoutingModule } from './activate-routing.module';

//COMPONENTS

import { ActivateComponent } from './activate.component';

//MODULES

import { SharedModule } from '../../../shared/shared.module';

@NgModule({
    declarations: [ActivateComponent],
    imports: [CommonModule, ActivateRoutingModule, RouterModule, ReactiveFormsModule, FormsModule, SharedModule],
})
export class ActivateModule {
    constructor() {}
}
