<!--START:  ADMIN -->
<div appCatchEvents id="admin" [class.admin--mobile]="mobileQuery.matches" class="d-flex flex-column">
    <mat-sidenav-container class="admin__container" autosize="true">
        <!-- START: SIDE NAVBAR -->
        <mat-sidenav
            #sidenav
            [mode]="mobileQuery.matches ? 'over' : 'side'"
            [opened]="!mobileQuery.matches"
            [fixedInViewport]="mobileQuery.matches"
            class="sidenav__menu border-0 py-3 px-2"
        >
            <!-- START: MENU OPTIONS -->
            <app-sidenav
                [navItems]="navItems"
                (redirectLink)="redirectLink($event)"
                (helpContactAlert)="helpContactAlert()"
            ></app-sidenav>
            <!-- END: MENU OPTIONS -->
        </mat-sidenav>
        <!-- END: SIDE NAVBAR -->
        <!-- START: MAIN CONTAINER -->
        <mat-sidenav-content>
            <!-- START: TOP NAVBAR - THIS CAN BE PLACE HERE OR OUTSIDE THE MAT SIDENAV CONTAINER IF YOU WANT THE NAVBAR ON TOP OF ALL THE PAGE -->
            <app-header (toggleSidenav)="sidenav.toggle()" (redirectLink)="redirectLink($event)"></app-header>
            <!-- END: TOP NAVBAR - THIS CAN BE PLACE HERE OR OUTSIDE THE MAT SIDENAV CONTAINER IF YOU WANT THE NAVBAR ON TOP OF ALL THE PAGE -->
            <!-- START: ADMIN ROUTER CONTAINER -->
            <app-main></app-main>
            <!-- END: ADMIN ROUTER CONTAINER -->
        </mat-sidenav-content>
        <!-- END: MAIN CONTAINER -->
    </mat-sidenav-container>
</div>
<!--END:  ADMIN -->
<!--START: NGX SPINNER -->
<app-loader></app-loader>
<!--END: NGX SPINNER -->
