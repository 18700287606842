import { Component, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { RestService } from '../../../../services/rest.service';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';
import { ErrorMatcher } from '../../../../utils/errorMatcher';
import { MatchPassword } from '../../../../utils/matchPassword';

@Component({
    selector: 'app-security',
    templateUrl: './security.component.html',
})
export class SecurityComponent implements OnInit {
    securityForm!: FormGroup;
    passwordHintHide: boolean;
    newPasswordHintHide: boolean;
    confirmPasswordHintHide: boolean;
    matcher: ErrorMatcher;
    private readonly _authentication: string;

    constructor(
        private _formBuilder: FormBuilder,
        private _restService: RestService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _errorHandlerService: ErrorHandlerService
    ) {
        this.passwordHintHide = true;
        this.newPasswordHintHide = true;
        this.confirmPasswordHintHide = true;
        this.matcher = new ErrorMatcher();
        this._authentication = environment.uris.method.authentication;
    }

    ngOnInit(): void {
        this._setFromBuilder();
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.securityForm = this._formBuilder.group(
            {
                currentPassword: new FormControl<any | null>(null, [Validators.required]),
                newPassword: new FormControl<any | null>(null, [
                    Validators.required,
                    Validators.minLength(8),
                    this._regexValidator(new RegExp('^\\S*$'), { 'non-whitespaces': true }),
                    this._regexValidator(new RegExp('^(?=.*[A-Za-z]).*$'), { letter: true }),
                    this._regexValidator(new RegExp('^(?=.*?[0-9]).*$'), { number: true }),
                ]),
                reNewPassword: new FormControl<any | null>(null, [Validators.required]),
            },
            {
                validators: [MatchPassword.match('newPassword', 'reNewPassword')],
            }
        );
    }

    /**
     * @method getCurrentPassword()
     * @description: Convenience getter for easy access to form fields
     */

    get getCurrentPassword(): string {
        return this.securityForm.value['currentPassword'] as string;
    }

    /**
     * @method getNewPassword()
     * @description: Convenience getter for easy access to form fields
     */

    get getNewPassword(): string {
        return this.securityForm.value['newPassword'] as string;
    }

    /**
     * @method getReNewPassword()
     * @description: Convenience getter for easy access to form fields
     */

    get getReNewPassword(): string {
        return this.securityForm.value['reNewPassword'] as string;
    }

    /**
     * @method setCurrentPassword()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setCurrentPassword(event: any) {
        this.securityForm.get('currentPassword')!.setValue(event);
    }

    /**
     * @method setNewPassword()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setNewPassword(event: any) {
        this.securityForm.get('newPassword')!.setValue(event);
    }

    /**
     * @method setReNewPassword()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setReNewPassword(event: any) {
        this.securityForm.get('reNewPassword')!.setValue(event);
    }

    /**
     * @method regexValidator()
     * @param (regex: RegExp)
     * @param (error: ValidationErrors)
     * @description: Takes the regex and the name for the validator and return true or false based on the result of the if statements
     */

    private _regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
        return (control: AbstractControl): { [p: string]: any } | null => {
            return !control.value ? null : regex.test(control.value) ? null : error;
        };
    }

    /**
     * @method onSubmit()
     * @description Submission action
     */

    onSubmit(): void {
        if (this.securityForm.valid) {
            this._ngxSpinnerService.show();
            const url: string = `${this._authentication}/changePassword`;
            const params = {
                oldPassword: this.getCurrentPassword,
                newPassword: this.getNewPassword,
                verifyNewPassword: this.getReNewPassword,
            };

            this._restService
                .post(url, { data: params })
                .then((response: any): void => {
                    if (response) {
                        Swal.fire({
                            title: 'Good job...',
                            text: 'Your password was successfully updated!',
                            icon: 'success',
                            confirmButtonText: 'Close',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                        }).then((): void => {
                            window.location.reload();
                        });
                    }
                })
                .catch((error: any): void => {
                    Swal.fire({
                        html: `${this._errorHandlerService.errorMsg(error.error)}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                })
                .finally((): void => {
                    this._ngxSpinnerService.hide();
                });
        } else if (this.securityForm.invalid) {
            return;
        }
    }
}
