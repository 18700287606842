import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, NonNullableFormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { Subject, combineLatest, map, take, takeUntil } from 'rxjs';
import { ExternalLookupFormGroupType } from 'src/app/models/payments/external-lookup-types-form';
import { Facility } from 'src/app/models/payments/facility.model';
import { DynamicField } from 'src/app/models/utils/dynamicFieldsByHouse';
import { ApprovalFlowLevelsService } from 'src/app/services/approval-flow-levels.service';
import { FacilityService } from 'src/app/services/facility.service';
import { CartService } from 'src/app/services/utils/cart.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { SessionService } from 'src/app/services/utils/session.service';
import { companyNameForCoda } from 'src/app/utils/constants';
import { StepperSteps } from 'src/app/utils/stepperTypes';
import Swal from 'sweetalert2';
import { StepperService } from '../../payment-flux/services/stepper.service';

@Component({
    selector: 'app-payment-detail',
    templateUrl: './payment-detail.component.html',
})
export class PaymentDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    form!: ExternalLookupFormGroupType;
    labelForHouse: string;
    datasourceHBL: MatTableDataSource<any> = new MatTableDataSource();
    totalAmountSelected: number = 0;
    submittedItems: boolean = false;
    noInformation: boolean = true;
    isLoading: boolean = false;
    private currentDate = dayjs();
    public minDateAllowed = this.currentDate.toDate();
    readonly companyName: any;
    readonly companyNameConst = companyNameForCoda;
    private pickupDateFormat = 'YYYY-MM-DD';
    private facilityData!: Facility;
    private _currentPayment: any;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private _formBuilder: NonNullableFormBuilder,
        private _changeDetectorRef: ChangeDetectorRef,
        private _facilityService: FacilityService,
        private _paymentFluxService: PaymentFluxService,
        private _customerService: CustomerService,
        private _stepperService: StepperService,
        private _sessionService: SessionService,
        private _router: Router,
        private _cartService: CartService,
        private _approvalFlowService: ApprovalFlowLevelsService,
        private _errorHandlerService: ErrorHandlerService
    ) {
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        if (!this._currentPayment) {
            this._router.navigate([`/admin/facilityPayments/newPayment`]);
        }
        this.facilityData = this._currentPayment.facility;
        this.labelForHouse = this.facilityData?.externalLookupDetails?.dynamicField?.house[0]?.label || '';
        const customer = this._customerService.getCustomer();
        this.companyName = customer.approvalLevels ? customer.approvalLevels.company.name : '';
    }

    ngOnInit(): void {
        this._setFormBuilder();
        this.subscribeCurrentCharges();
    }

    ngAfterViewInit(): void {
        const HBLNumber = this._sessionService.getElement('HBL');
        if (HBLNumber) {
            this.form.controls['search'].setValue(HBLNumber);
            this.searchforHbl();
        }
        this._changeDetectorRef.detectChanges();
    }

    private _setFormBuilder(): void {
        this.form = this._formBuilder.group({
            search: [''],
            pickupDate: [this.minDateAllowed],
        });
    }

    /**
     * @method pickupDate()
     * @description: Convenience getter for easy access to form fields
     */

    get pickupDate(): AbstractControl<Date> {
        return this.form.get('pickupDate') as AbstractControl;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    subscribeCurrentCharges(): void {
        combineLatest([this._cartService.get3rdPartyItems$(), this._cartService.getSubmittedItems$()])
            .pipe(
                map(([chargesOnCart, chargesSubmitted]) => {
                    this.submittedItems = !!chargesSubmitted.length;
                    let totalAmountSelected: number = 0;
                    if (chargesOnCart) {
                        chargesOnCart.map((charge: any) => {
                            totalAmountSelected += charge['Amount to pay'];
                        });
                    }
                    return totalAmountSelected;
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (totalAmount: number) => {
                    this.totalAmountSelected = totalAmount;
                },
            });
    }

    get getSearchInput(): AbstractControl<string> {
        return this.form.get('search') as AbstractControl;
    }

    searchforHbl(pickupDate?: string): void {
        this._sessionService.removeElement('HBL');
        if (!this.getSearchInput?.value || !this.form.valid) {
            return;
        }
        this.datasourceHBL = new MatTableDataSource();
        this.isLoading = true;
        const id = this.facilityData.id;
        const value = this.getSearchInput?.value || '';
        if (!pickupDate) {
            this.form.get('pickupDate')?.setValue(this.minDateAllowed);
        }
        this._facilityService
            .getChargesByHbl(value, id, pickupDate ? 'STORAGE' : 'HOUSE', pickupDate)
            .pipe(take(1))
            .subscribe({
                next: (data: DynamicField[]) => {
                    this._sessionService.saveElement('HBL', value);
                    this.isLoading = false;
                    this.noInformation = false;
                    this.datasourceHBL = new MatTableDataSource<any>(
                        Array.from(
                            data?.map((item: any) => {
                                item['Select'] =
                                    this.getTypeOfRequest(item['Amount to pay']) > 1
                                        ? 'Submit to approver'
                                        : 'Add to cart';
                                return item;
                            })
                        )
                    );
                },
                error: (error: any) => {
                    this.isLoading = false;
                    this.noInformation = true;
                    this.form.reset();
                    const errorMsg = this._errorHandlerService.errorMsg(error.error);
                    Swal.fire({
                        html: errorMsg,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                },
            });
    }

    /**
     * @method checkout()
     * @description Send the user to the customer reference
     */

    checkout(): void {
        const step = this.companyName ? StepperSteps.STEP_FIRST : StepperSteps.STEP_ZERO;
        this._stepperService[step].next(true);
        const redirect: string =
            this._currentPayment && this._currentPayment.redirectRoute
                ? this._currentPayment.redirectRoute
                : '/admin/facilityPayments/newPayment/codafacility/summary';
        this._router.navigate([redirect]);
    }

    /**
     * @method backStep()
     * @description Return into the customer reference for companies
     */

    backStep(): void {
        if (this.companyName) {
            this._router.navigate([
                Object.keys(this.companyNameConst).indexOf(this.companyName.toLowerCase()) === -1
                    ? `/admin/facilityPayments/newPayment/codafacility/${encodeURIComponent(
                          this.companyName.toLowerCase()
                      )}+'/Reference`
                    : `/admin/facilityPayments/newPayment/codafacility/${encodeURIComponent(
                          this.companyName.toLowerCase()
                      )}Reference`,
            ]);
        } else {
            this._paymentFluxService.goBackToFacilitySearch();
        }
    }

    getTypeOfRequest(amount: number): number {
        const levels = this._approvalFlowService.getLevelsOfApprovalFlowByAmount(amount);
        return levels;
    }

    setPickupDate(): void {
        let pkuDate = dayjs().format(this.pickupDateFormat);
        let time: Date = this.pickupDate?.value;
        if (time) {
            pkuDate = dayjs(time).format(this.pickupDateFormat);
            this.searchforHbl(pkuDate);
        }
    }
}
