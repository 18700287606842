import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ThemeType } from 'src/app/models/ui/themes';
import { ThemeService } from 'src/app/services/utils/theme.service';

@Component({
    standalone: true,
    selector: 'app-image-by-brand',
    templateUrl: './image-by-brand.component.html',
    imports: [CommonModule],
})
export class ImageByBrandComponent implements OnInit {
    @Input() imageName!: string;
    @Input() title = '';
    @Input() alt = '';
    @Input() width = 'auto';
    @Input() height = 'auto';

    themeType: ThemeType;
    imageSrc: string;

    constructor(private _themeService: ThemeService) {
        this.themeType = this._themeService.instant_theme;
        this.imageSrc = '';
    }

    ngOnInit(): void {
        this.themeType = this._themeService.instant_theme;

        this.buildImageName();
    }

    buildImageName(): void {
        this.imageSrc = `assets/images/${this.themeType}/${this.imageName}`;
    }
}
