import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// COMPONENTS

import { PaymentDetailComponent } from './payment-detail.component';

const routes: Routes = [
    { path: '', component: PaymentDetailComponent, data: { breadcrumb: { alias: 'payment-detail' } } },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PaymentDetailRoutingModule {}
