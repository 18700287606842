import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, throwError } from 'rxjs';
import { HomeService } from 'src/app/services/home.service';
import Swal from 'sweetalert2';
import { environment } from '../../../../../../environments/environment';
import { Table } from '../../../../../models/ui/table.model';
import { PaymentService } from '../../../../../services/payment.service';
import { RestService } from '../../../../../services/rest.service';
import { SummaryValidationService } from '../../../../../services/summary-validation.service';
import { CustomerService } from '../../../../../services/utils/customer-handler.service';
import { ErrorHandlerService } from '../../../../../services/utils/error-handler.service';
import { SecurityService } from '../../../../../services/utils/security.service';
import { SessionService } from '../../../../../services/utils/session.service';
import { paginatorSize, profileComponents } from '../../../../../utils/constants';
import { Sections } from '../../../../../utils/sections';

@Component({
    selector: 'app-pending-request',
    templateUrl: './pending-approval.component.html',
})
export class PendingApprovalComponent implements OnInit, OnDestroy {
    noInformation: boolean;
    noInformationMessage: string;
    amTable!: Table[];
    userPermissionsSubmit: Array<string>;
    userPermissionsCart: Array<string>;
    pendingPayments: any[];
    sortableColumns: string[];
    cannotAddRequestOfTableToCart: boolean;

    private _subscription: Subscription;
    private _recordsSelected: any;
    private readonly _cartUrl: any;
    private _dynamicColumnsName: [];
    private _dynamicColumns: [];
    private _dynamicFields: any;
    private _typeSort: string;
    private _fieldToSort: string;
    private _isSorting: boolean;
    private _dynamicFileFields: { id: number; name: string }[];

    constructor(
        private _securityService: SecurityService,
        private _sessionService: SessionService,
        private _router: Router,
        private _errorHandlerService: ErrorHandlerService,
        private _restService: RestService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _customerService: CustomerService,
        private _summaryValidationService: SummaryValidationService,
        private _paymentService: PaymentService,
        private _homeService: HomeService
    ) {
        this._dynamicFileFields = [];
        this._cartUrl = environment.uris.method.cart;
        this.userPermissionsSubmit = [''];
        this.userPermissionsCart = [''];
        this._recordsSelected = [];
        this.pendingPayments = [];
        this.noInformation = true;
        this.noInformationMessage = '';
        this._dynamicColumns = [];
        this._dynamicColumnsName = [];
        this._dynamicFields = {};
        this._subscription = Subscription.EMPTY;
        this.sortableColumns = [];
        this._typeSort = '';
        this._fieldToSort = '';
        this._isSorting = false;
        this.cannotAddRequestOfTableToCart = false;
    }

    ngOnInit(): void {
        this.getAllFields();
        const userType: string = this._securityService.getUserType();
        if (this._securityService.verifyComponentsSecurity(profileComponents.sendRequestofTableToApprover)) {
            this.userPermissionsSubmit.push(userType);
        }
        if (this._securityService.verifyComponentsSecurity(profileComponents.addRequestofTableToCart)) {
            this.userPermissionsCart.push(userType);
        }
        this.getPendingApprovals();
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    /**
     * @method ()
     * @param (event: any)
     * @description Order by table column on the table
     */

    sort(event: any): void {
        let typeToSort: any = event.direction;
        if (event.typeSort == '') {
            this._typeSort = '';
            this._fieldToSort = '';
        } else {
            this._typeSort = typeToSort.toUpperCase();
            this._fieldToSort = event.field;
        }

        this._isSorting = true;
        this.getPendingApprovals();
    }

    /**
     * @method _getPendingApprovals()
     * @param (item?: any)
     * @description Generates the URL based on customer approval level and starting level
     */

    getPendingApprovals(item?: any): void {
        const startingLevel: boolean = !!this._sessionService.getElement('startingLevel');
        const url: string = this._cartUrl + '/payment-request/pending-approvals';
        const companyName: string = this._customerService.getCompanyName();
        const pageSize: number = item !== undefined ? item.pageSize : paginatorSize[0];
        const currentSize: number = item !== undefined ? item.currentPage : 0;
        let longUrl: string = `${url}/?page=${currentSize}&size=${pageSize}&date=${new Date().valueOf()}`;
        if (this._typeSort && this._fieldToSort) {
            longUrl = longUrl + `&sort=${this._fieldToSort}-${this._typeSort}`;
        }
        const dynamicCompany: boolean = this._customerService.isDynamicCompany();
        this._ngxSpinnerService.show();

        this._restService
            .get(longUrl, {})
            .then((result: any): void => {
                let companyNameColumns: any;
                let companyNameColumnsName: any;

                Object.keys(Sections.pendingApprovalHomeComponentSection).forEach((item: any, index: any): void => {
                    if (
                        item === `${companyName}_ColumnsPendingApprovalHomeComponent` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsPendingApprovalHomeComponent')
                    ) {
                        companyNameColumns = JSON.parse(
                            JSON.stringify(Object.values(Sections.pendingApprovalHomeComponentSection)[index])
                        );
                        if (!!dynamicCompany && this._dynamicColumns) {
                            this._dynamicColumns.map((item: any): void => {
                                companyNameColumns.splice(7, 0, item);
                            });
                        }
                    } else if (
                        item === `${companyName}_ColumnsNamePendingApprovalHomeComponent` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsNamePendingApprovalHomeComponent')
                    ) {
                        companyNameColumnsName = JSON.parse(
                            JSON.stringify(Object.values(Sections.pendingApprovalHomeComponentSection)[index])
                        );
                        if (!!dynamicCompany && this._dynamicColumnsName) {
                            this._dynamicColumnsName.map((item: any): void => {
                                companyNameColumnsName.splice(7, 0, item);
                            });
                            for (let i: number = 0; i < companyNameColumns.length; i++) {
                                if (
                                    companyNameColumns.indexOf(companyNameColumns[i]) !==
                                    companyNameColumns.lastIndexOf(companyNameColumns[i])
                                ) {
                                    companyNameColumnsName[companyNameColumns.indexOf(companyNameColumns[i])] =
                                        companyNameColumnsName[companyNameColumns.lastIndexOf(companyNameColumns[i])];
                                }
                            }
                        }
                    } else if (
                        item === `${companyName}_getCompletedPaymentsPendingApprovalHomeComponent` ||
                        (!!dynamicCompany && item === 'Dynamic_getCompletedPaymentsPendingApprovalHomeComponent')
                    ) {
                        this.noInformation = !result.content.length;
                        this.pendingPayments = result.content.length
                            ? result.content.map((elements: any) => {
                                  return Object.values(Sections.pendingApprovalHomeComponentSection)[index](elements);
                              })
                            : [];
                        if (!!dynamicCompany && this._dynamicColumns) {
                            let encounteredIndices: any = {};
                            for (let i: number = 0; i < companyNameColumns.length; i++) {
                                if (encounteredIndices[companyNameColumns[i]]) {
                                    companyNameColumns.splice(i, 1);
                                    companyNameColumnsName.splice(i, 1);
                                } else encounteredIndices[companyNameColumns[i]] = 1;
                            }

                            this.pendingPayments.map((item: any): void => {
                                this._dynamicColumns.map((column: any): void => {
                                    column = column === 'facilityName' ? '' : column;
                                    column = column === 'awb' ? '' : column;
                                    column = column === 'hawb' ? '' : column;
                                    column = column === 'location' ? '' : column;
                                    column = column === 'customerRef' ? '' : column;
                                    column = column === 'amount' ? '' : column;
                                    column = column === 'approvalLevel' ? '' : column;
                                    column = column === 'nameFiles' ? '' : column;

                                    if (
                                        item.dynamicValues.filter(
                                            (values: any): boolean => values.dynamicField.name === column
                                        ).length === 0
                                    ) {
                                        item[column] = 'N/A';
                                    } else {
                                        item[column] = item.dynamicValues
                                            .filter((values: any): boolean => values.dynamicField.name === column)
                                            .map((item: any) => {
                                                const fileField: { id: number; name: string }[] =
                                                    this._paymentService.verifyIfDynamicFieldisFile(
                                                        item.dynamicField.id,
                                                        this._dynamicFileFields
                                                    );
                                                if (fileField.length) {
                                                    const filePath = item.value.split('/');
                                                    item.value = filePath[filePath.length - 1];
                                                }
                                                return item.value !== '' &&
                                                    item.value !== null &&
                                                    item.value !== undefined
                                                    ? item.value
                                                    : 'N/A';
                                            });
                                    }
                                });
                            });
                        }
                    } else if (
                        item === `${companyName}_ColumnsSortablePendingApprovalHomeComponent` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsSortablePendingApprovalHomeComponent')
                    ) {
                        this.sortableColumns = JSON.parse(
                            JSON.stringify(Object.values(Sections.pendingApprovalHomeComponentSection)[index])
                        );
                    }
                });
                if (
                    !this._securityService.verifyComponentsSecurity(profileComponents.addRequestofTableToCart) &&
                    !this._securityService.verifyComponentsSecurity(profileComponents.sendRequestofTableToApprover)
                ) {
                    companyNameColumns.splice(0, 1);
                    companyNameColumnsName.splice(0, 1);
                }
                this._getPendingApprovalsTableRender(
                    this.pendingPayments,
                    companyNameColumns,
                    companyNameColumnsName,
                    result.totalElements
                );
            })
            .catch((error: any): void => {
                this.noInformationMessage = error?.error?.errors ? error.error.errors : '';
                this.noInformation = true;
            })
            .finally((): void => {
                this._homeService.setPendingApproval(this.noInformation);
            });
    }

    /**
     * @method _getPendingApprovalsTableRender()
     * @param (source: any)
     * @param (columns: any)
     * @param (columnsName: any)
     * @param (totalElements: number)
     * @description Render the table using the data that we retrieve from the URL
     */

    private _getPendingApprovalsTableRender(source: any, columns: any, columnsName: any, totalElements: number): void {
        this.amTable = [
            new Table({
                sorting: false,
                pagination: false,
                filter: false,
                dataSource: source,
                displayedColumns: columns,
                displayedColumnsName: columnsName,
                paginationSizes: paginatorSize,
                totalElements: totalElements,
            }),
        ];
        this._ngxSpinnerService.hide();
    }

    /**
     * @method _reviewSelectedRows()
     * @description
     */

    private _reviewSelectedRows(): any {
        if (this._recordsSelected.length > 0) {
            return this._recordsSelected.map((item: any) => {
                return item.id;
            });
        } else {
            Swal.fire({
                title: 'Oops....',
                text: 'Please select one or more request to submit',
                icon: 'error',
                showConfirmButton: false,
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                allowOutsideClick: false,
            });
        }
    }

    /**
     * @method submitToApprover()
     * @description
     */

    submitToApprover(): void {
        const ids = this._reviewSelectedRows();
        if (ids) {
            this._ngxSpinnerService.show();
            this._restService
                .post(`${this._cartUrl}/approveMultiplePaymentRequests?id=${ids.join(',')}`, {})
                .then((): void => {
                    setTimeout((): void => {
                        window.location.reload();
                        this._ngxSpinnerService.hide();
                    }, 2000);
                })
                .catch((error: any): void => {
                    this._ngxSpinnerService.hide();
                    Swal.fire({
                        html: `${this._errorHandlerService.errorMsg(error.error)}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                });
        }
    }

    /**
     * @method addToCart()
     * @description
     */

    addToCart(): void {
        this._subscription = this._summaryValidationService.addToCartValidate('paymentRequest').subscribe({
            next: (result: any): void => {
                if (result.continue) {
                    this._postPendingApproval();
                } else {
                    Swal.fire({
                        html: `<h5>Hey, psst psst...</h5>
                    <p>${result.message}</p>`,
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        allowOutsideClick: false,
                        reverseButtons: true,
                    }).then((result): void => {
                        if (result.isConfirmed) {
                            this._summaryValidationService.deleteCart();
                            this._postPendingApproval();
                        }
                    });
                }
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
    }

    /**
     * @method _postPendingApproval()
     * @description Adds and open invoice into the cart
     */

    private _postPendingApproval(): void {
        const ids = this._reviewSelectedRows();
        if (ids) {
            this._ngxSpinnerService.show();
            this._restService
                .post(`${this._cartUrl}/approveMultiplePaymentRequests?id=${ids.join(',')}`, {})
                .then((): void => {
                    this._sessionService.setPayCount(this._sessionService.getElement('payCount') + 1);
                    this._sessionService.setPaymentRequest(this._sessionService.getElement('paymentRequest') + 1);
                    this._router.navigate(['admin/cart']);
                })
                .catch((error: any): void => {
                    this._ngxSpinnerService.hide();
                    Swal.fire({
                        html: `${this._errorHandlerService.errorMsg(error.error)}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                });
        }
    }

    /**
     * @method itemsSelected()
     * @param (items: any)
     * @description
     */

    itemsSelected(items: any): void {
        let canNotAdd: boolean = false;
        this._recordsSelected = items;
        items.forEach((item: any) => {
            if (!item.approvalLevels) {
                canNotAdd = true;
                return;
            }
        });
        this.cannotAddRequestOfTableToCart = canNotAdd;
        items
            .map((item: any) => {
                return item.id;
            })
            .join(',');
    }

    /**
     * @method getAllFields()
     * @description
     */

    getAllFields(): void {
        this._dynamicColumns = [];
        this._dynamicColumnsName = [];
        this._dynamicFields = [];
        this._paymentService.getDynamicFieldsColumns().subscribe({
            next: (response: any): void => {
                this._dynamicFields = response.fields;
                this._dynamicColumns = response.dynamicColumns.map((item: any) => {
                    return item.column;
                });
                this._dynamicColumnsName = response.dynamicColumns.map((item: any) => {
                    return item.columnName;
                });
                this._dynamicFileFields = this._paymentService.getFileFieldsDynamic([
                    ...response.fields[0],
                    ...response.fields[1],
                    ...response.fields[2],
                ]);
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
    }
}
