<div class="calendar-header">
    <button class="icon-button" mat-icon-button (click)="previousClicked('year')">
        <mat-icon>keyboard_double_arrow_left</mat-icon>
    </button>
    <button class="icon-button" mat-icon-button (click)="previousClicked('month')">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-icon-button class="year-btn" (click)="startView()">
        <span class="calendar-header-label">{{ periodLabel }}</span>
        <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <button mat-button color="primary" (click)="todayClicked()">Today</button>

    <button class="icon-button" mat-icon-button (click)="nextClicked('month')">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
    <button class="icon-button" mat-icon-button (click)="nextClicked('year')">
        <mat-icon>keyboard_double_arrow_right</mat-icon>
    </button>
</div>
