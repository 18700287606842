import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { CartBill, ErrorInputFieldComponent, PromoCode, PromoCodeListResponse } from '@cargos/sprintpay-models';
import { PromoCodeService } from '@cargos/sprintpay-services';
import { Subject, catchError, finalize, map, of, switchMap, take, takeUntil } from 'rxjs';
import { Card, Echeck } from 'src/app/models/payments/payment-methods';
import { CartAPIService } from 'src/app/services/requests/cart-api.service';
import { SummaryValidationService } from 'src/app/services/summary-validation.service';
import { CartService } from 'src/app/services/utils/cart.service';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { AwbPromoCode, PromoCodeByAWBService } from '../services/promo-code.service';
import { PromoCodeFormGroupType } from './models/customer-reference-form';
import { PromoCodeList } from './models/promo-code-list-input';
interface EventPaymentMethod {
    item: Card | Echeck | undefined;
    method: number | null;
}
export interface PaymentMethod {
    paymentAccount: EventPaymentMethod;
    method: string;
}
@Component({
    selector: 'app-promo-code',
    templateUrl: './promo-code.component.html',
    styleUrls: ['./promo-code.component.scss'],
})
export class PromoCodeComponent implements OnInit, OnDestroy {
    public promoCodeForm!: PromoCodeFormGroupType;
    public isLoading = false;
    public promoCodeApplied: string | undefined = '';
    public messageConfig!: ErrorInputFieldComponent[];
    public promoCodesList: PromoCodeList[] = [];
    public paymentMethodSelected: any;
    private unsubscribe$ = new Subject<void>();
    private totalPromoCodeApplied: number = 0;
    private cartBill!: CartBill | null;
    private hasRequestsWithExternalLookup: boolean = false;

    @Input() awbNumber: string = '';
    @Output() eventOnPromoCode = new EventEmitter<PromoCode>();
    @Output() eventOnOpenDropDown = new EventEmitter<unknown>();
    @Output() eventOnCloseDropDown = new EventEmitter<unknown>();

    constructor(
        private formBuilder: NonNullableFormBuilder,
        private _cartService: CartService,
        private promoCodeForAWB: PromoCodeByAWBService,
        private promoCodeService: PromoCodeService,
        private _errorHandlerService: ErrorHandlerService,
        private _cartApiService: CartAPIService,
        private promoCodeByAWBService: PromoCodeByAWBService,
        private _summaryValidationService: SummaryValidationService
    ) {}

    get promoCode(): string {
        return this.promoCodeForm.get('promoCode')?.value || '';
    }

    ngOnInit(): void {
        this.hasRequestWithExternalLookup();
        this.getCurrentPaymentMethod();
        this.getPromoCodes();
        this.createForm();
        this.subscribePromoCodesApplied();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getCurrentPaymentMethod(): void {
        this._cartService
            .getPayMethod()
            .pipe(
                map((response: PaymentMethod) => {
                    if (response) {
                        this.paymentMethodSelected = response.method;
                    }
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe();
    }
    createForm(): void {
        this.promoCodeForm = this.formBuilder.group({
            promoCode: ['', [Validators.required]],
        });
    }

    deletePromoCode(): void {
        this.promoCodeForAWB.removePromoCode(this.awbNumber, this.promoCode);
        this.getCartBillWithAdditionalFee();
    }

    subscribePromoCodesApplied(): void {
        this.promoCodeByAWBService
            .getPromoCodesApplied()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (arrayPromoCode) => {
                    this.totalPromoCodeApplied = arrayPromoCode.length * 8;
                    this.promoCodeApplied = arrayPromoCode.find(
                        (promocodeApplied) => promocodeApplied.awb === this.awbNumber
                    )?.promoCodeId;
                    this.promoCodeForm.patchValue({
                        promoCode: this.promoCodeApplied,
                    });
                },
            });
    }

    private hasRequestWithExternalLookup(): void {
        this._summaryValidationService
            .hasRequestWithExternalLookup()
            .pipe(
                map((requestsArray) => {
                    return !!requestsArray.length;
                }),
                catchError(() => {
                    return of(false);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (response) => {
                    this.hasRequestsWithExternalLookup = response;
                },
            });
    }

    validatePromoCode(): void {
        if (!this.promoCodeForm.valid) {
            return;
        }

        if (this.promoCodeForAWB.findPromoCode(this.promoCode)) {
            this.setPromoCodeError('The promo code has already been used');
            return;
        }

        this.isLoading = true;

        this.promoCodeService
            .validatePromoCode(this.promoCode)
            .pipe(
                take(1),
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe({
                next: (response) => {
                    if (!response.isValid) {
                        this.setPromoCodeError(response.message || '');
                        return;
                    }
                    this.eventOnPromoCode.emit(response);
                    this.promoCodeForAWB.setPromoCodesApplied(this.awbNumber, response.promoCodeId || '', response.awb);
                    this.getCartBillWithAdditionalFee();
                },
                error: (error) => {
                    const errorMsg = this._errorHandlerService.errorMsg(error.error);
                    this.setPromoCodeError(errorMsg);
                },
            });
    }

    getCartBillWithAdditionalFee(): void {
        this._cartService
            .getCartBill$()
            .pipe(
                take(1),
                switchMap((cartBill) => {
                    const nonReservationFeeCart = cartBill?.nonReservationFee || 0;
                    const additionalFee: number = nonReservationFeeCart - this.totalPromoCodeApplied;
                    return this._cartApiService
                        .getCartBill(this.paymentMethodSelected, additionalFee, this.hasRequestsWithExternalLookup)
                        .pipe(
                            map((currentCartBill) => {
                                this._cartService.setCartBill(currentCartBill as CartBill);
                            })
                        );
                })
            )
            .subscribe();
    }

    get errorVisible(): boolean {
        if (!this.promoCodeForm.get('promoCode')?.errors) {
            return false;
        }

        return this.promoCodeForm.touched || this.promoCodeForm.dirty;
    }

    setPromoCodeError(message: string): void {
        const messageList = [
            {
                code: 'invalid',
                message: message,
            },
        ];
        this.messageConfig = Array.isArray(messageList) ? messageList : [messageList];
        this.promoCodeForm.get('promoCode')?.setErrors({ invalid: true });
    }

    getPromoCodes(): void {
        this.isLoading = true;
        this.promoCodeByAWBService
            .getPromoCodesByUser()
            .pipe(
                switchMap((promoCodesList: PromoCodeListResponse[]) => {
                    return this.promoCodeByAWBService.getPromoCodesApplied().pipe(
                        map((awbPromoCodes: AwbPromoCode[]) => {
                            const promoCodestoRemove = new Set(
                                awbPromoCodes.map((promoCodeUsed) => promoCodeUsed?.promoCodeId)
                            );

                            return promoCodesList.filter(
                                (promoCode) => !promoCodestoRemove.has(promoCode?.promoCodeId)
                            );
                        })
                    );
                }),
                map((promoCodesList: PromoCodeListResponse[]) => {
                    return promoCodesList.map((promoCodeValue) => ({
                        name: promoCodeValue.promoCodeId,
                    }));
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: (promoCodeList) => {
                    this.isLoading = false;
                    this.promoCodesList = promoCodeList;
                },
                error: () => {
                    this.isLoading = false;
                },
            });
    }

    onOpenDropDown(): void {
        this.eventOnOpenDropDown.emit();
    }

    onCloseDropDown(): void {
        this.eventOnCloseDropDown.emit();
    }
}
