<section>
    <ng-container *ngIf="datasourceHouse.data;">
        <label class="d-inline-block fw-bold mx-2 my-2">HBL Charges</label>
        <div class="overflow-auto">
            <table mat-table [dataSource]="datasourceHouse" class="custom-table">
                <ng-container matColumnDef="{{columnCharge}}" *ngFor="let columnCharge of displayedColumnsNameHBL;">
                    <ng-container *ngIf="datasourceHouse?.data?.length; else noCharges">
                        <ng-container *ngIf="columnCharge !== 'Select'; else action">
                            <th mat-header-cell *matHeaderCellDef>{{columnCharge}}</th>
                            <td mat-cell *matCellDef="let element">
                                <ng-container [ngSwitch]="columnCharge">
                                    <ng-container *ngSwitchCase="'Amount to pay'">
                                        {{element[columnCharge] | currency}}
                                    </ng-container>
                                    <ng-container *ngSwitchCase="'Estimated Pickup'">
                                        {{element[columnCharge] ? (element[columnCharge] | date: 'short') : 'N/A'}}
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        {{element[columnCharge] ? element[columnCharge] : 'N/A'}}
                                    </ng-container>
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-template #action>
                            <th mat-header-cell *matHeaderCellDef>{{columnCharge}}</th>
                            <td mat-cell *matCellDef="let element">
                                <app-button-charge [charge]="element"></app-button-charge>
                            </td>
                        </ng-template>
                    </ng-container>
                    <ng-template #noCharges>
                        <th mat-header-cell *matHeaderCellDef>{{columnCharge}}</th>
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell text-center" colspan="100%">
                                No charges found, if paying storage try a different pickup date <br />
                                If you still believe charges are due on this file number, please email
                                support&#64;cargosprint.com.
                            </td>
                        </tr>
                    </ng-template>
                </ng-container>
                <ng-container matColumnDef="totalDues">
                    <th mat-footer-cell *matFooterCellDef class="footer">Total Dues :</th>
                </ng-container>

                <ng-container matColumnDef="anotherColumn">
                    <th mat-footer-cell *matFooterCellDef>{{ totalDues | currency }}</th>
                </ng-container>
                <!-- Add other columns as per your requirements -->

                <tr mat-header-row *matHeaderRowDef="displayedColumnsNameHBL"></tr>
                <tr mat-row *matRowDef="let nestedElement; columns: displayedColumnsNameHBL;"></tr>
                <tr mat-footer-row *matFooterRowDef="['totalDues','anotherColumn']; sticky: true"></tr>
            </table>
        </div>
    </ng-container>
</section>
