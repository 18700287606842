<div class="row mb-4">
    <table mat-table [dataSource]="charges" class="custom-table">
        <ng-container matColumnDef="{{columnCharge}}" *ngFor="let columnCharge of displayedColumnsName;">
            <ng-container *ngIf="columnCharge !== 'Select'; else action">
                <th mat-header-cell *matHeaderCellDef>{{columnCharge === 'HBL' ? 'File Number': columnCharge}}</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="columnCharge === 'Amount to pay'; else normalColumn">
                        {{element[columnCharge] | currency}}
                    </ng-container>
                    <ng-template #normalColumn> {{element[columnCharge] ? element[columnCharge] : 'N/A'}} </ng-template>
                </td>
            </ng-container>
            <ng-template #action>
                <th mat-header-cell *matHeaderCellDef>{{columnCharge}}</th>
                <td mat-cell *matCellDef="let element">
                    <app-button-charge [charge]="element"></app-button-charge>
                </td>
            </ng-template>
        </ng-container>
        <ng-container matColumnDef="totalDues">
            <th mat-footer-cell [attr.colspan]="2" *matFooterCellDef class="footer">Amount</th>
        </ng-container>

        <ng-container matColumnDef="anotherColumn">
            <th mat-footer-cell *matFooterCellDef>{{ totalAmount | currency }}</th>
        </ng-container>
        <!-- Add other columns as per your requirements -->
        <tr mat-header-row *matHeaderRowDef="displayedColumnsName"></tr>
        <tr mat-row *matRowDef="let nestedElement; columns: displayedColumnsName;"></tr>
        <tr mat-footer-row *matFooterRowDef="['totalDues','anotherColumn']; sticky: true"></tr>
    </table>
</div>
