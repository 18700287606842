<!--START: UPLOAD FILE SECTION -->
<form class="row" [formGroup]="uploadForm">
    <div class="row my-3">
        <div class="col-12">
            <div
                id="dragDrop"
                class="form-group formFile drop-zone-area"
                (drop)="dropHandler($event)"
                (dragover)="dragOverHandler($event)"
            >
                <div class="row">
                    <div class="col-12 d-block text-center">
                        <p>
                            <i class="bi bi-cloud-arrow-up"></i>
                            Drag and drop
                        </p>
                    </div>
                    <div class="col-12 my-2 d-flex justify-content-center text-uppercase">
                        <div class="upload-button d-flex justify-content-center align-items-center">
                            Upload File
                            <input
                                #fileInput
                                type="file"
                                multiple="multiple"
                                (change)="handleFileInput($event.target)"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <mat-error *ngIf="nameFiles?.touched && nameFiles?.errors">
                <mat-error *ngIf="nameFiles.hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"> </i>Please attach the required document
                </mat-error>
            </mat-error>
        </div>
    </div>
</form>
<!--END: UPLOAD FILE SECTION -->
