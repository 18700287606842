import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Actions } from 'src/app/models/utils/actions.model';

@Component({
    selector: 'app-refund-button',
    standalone: true,
    templateUrl: './refund-button.component.html',
    imports: [CommonModule, MatIconModule, MatTooltipModule],
})
export class RefundButtonComponent {
    @Input() set actions(actions: Actions) {
        this.refundAction = actions;
    }

    @Input() set record(record: any) {
        this.rowRecord = record;
    }

    @Output() clickAction = new EventEmitter();

    public refundAction: Actions = {};
    public rowRecord: any;

    constructor() {}

    evalAction(item: any, action: Actions): any {
        if (action.condition === 'hasRenderableFunc') {
            let funcBinding =
                action?.hasRenderableFunc?.name &&
                action?.hasRenderableFunc?.prototype?.[action?.hasRenderableFunc?.name].bind(
                    action.hasRenderableFunc.prototype
                );
            const isRenderable = funcBinding(item.id);
            return eval(isRenderable);
        }
        return action?.condition && eval(action.condition);
    }

    selectedAction(record: Actions): void {
        this.clickAction.emit({ record, item: this.rowRecord });
    }

    cleanDollarMask(amount: string): number {
        return Number(amount?.replace('$', ''));
    }
}
