import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { CustomerService } from '../../../../../../../services/utils/customer-handler.service';
import { PaymentFluxService } from '../../../../../../../services/utils/payment-flux.service';
import { SessionService } from '../../../../../../../services/utils/session.service';
import { PaymentDetailService } from '../../payment-detail.service';

@Component({
    selector: 'app-vin-edoc-nextgen',
    standalone: true,
    imports: [CommonModule, FormsModule, MatFormFieldModule, ReactiveFormsModule, MatRadioModule],
    templateUrl: './vin-edoc-nextgen.component.html',
})
export class VinEdocNextgenComponent implements OnInit, OnChanges {
    paymentForm!: FormGroup;
    private _customer: any;
    private readonly _startingLevel: boolean;
    private readonly _companyName: any;
    private readonly _currentPayment: any;

    @Input() formValidState: any;
    @Output() returnVinEdocNextgen: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private _formBuilder: FormBuilder,
        private _sessionService: SessionService,
        private _customerService: CustomerService,
        private _paymentDetailService: PaymentDetailService,
        private _paymentFluxService: PaymentFluxService
    ) {
        this._customer = this._customerService.getCustomer();
        this._startingLevel = !!this._sessionService.getElement('startingLevel');
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this._companyName = this._customer?.approvalLevels?.company?.name || '';
    }

    ngOnInit(): void {
        this._setFromBuilder();
        if (this._currentPayment && this._currentPayment.details) {
            this.paymentForm = this._formBuilder.group(this._currentPayment.details);
            this.returnVinEdocNextgen.emit(this.paymentForm.getRawValue());
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['formValidState'] !== undefined) {
            if (changes['formValidState'].currentValue !== undefined) {
                if (changes['formValidState'].currentValue !== changes['formValidState'].previousValue) {
                    if (this.paymentForm !== undefined && !this.formValidState) {
                        this.eiReferenceFileNumber.markAsTouched();
                    }
                }
            }
        }
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.paymentForm = this._formBuilder.group({
            eiReferenceFileNumber: new FormControl<number | null>(null, []),
        });
        this.paymentForm = this._paymentDetailService.validateForm(this.paymentForm);
    }

    /**
     * @method eiReferenceFileNumber()
     * @description: Convenience getter for easy access to form fields
     */

    get eiReferenceFileNumber(): FormGroup {
        return this.paymentForm.get('eiReferenceFileNumber') as FormGroup;
    }

    /**
     * @method returnVinEdocNextgenValue()
     * @param (paymentForm: FormGroup)
     * @description Return the value of the standalone component
     */

    returnVinEdocNextgenValue(paymentForm: FormGroup) {
        this.returnVinEdocNextgen.emit(paymentForm.value);
    }
}
