import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cart, CartBill, ComponentPaymentMethodName, PaymentCart } from '@cargos/sprintpay-models';
import { CartService } from '@cargos/sprintpay-services';
import { Observable, map } from 'rxjs';
import { PayInvoicesPayload } from 'src/app/models/payments/payment-methods';
import { PaymentMethodsKeys } from 'src/app/utils/cartTypes';
import { environment } from 'src/environments/environment';
import { TokenService } from '../utils/token.service';

@Injectable({ providedIn: 'root' })
export class CartAPIService {
    private _cartUrl: string;
    constructor(
        private _httpClient: HttpClient,
        private _cartService: CartService,
        private _tokenService: TokenService
    ) {
        this._cartUrl = environment.uris.method.cart;
    }

    getCart(): Observable<CartBill> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient
            .get<PaymentCart[]>(`${this._cartUrl}/getCart?date=${new Date().valueOf()}`, { headers })
            .pipe(
                map((cart) => {
                    return CartBill.fromJson({ cart });
                })
            );
    }

    deleteCart(): Observable<any> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.delete(this._cartUrl + '/deleteCart', { headers });
    }

    getCartBill(
        selectedPayment: PaymentMethodsKeys,
        additionalFee?: number,
        isV2: boolean = false
    ): Observable<CartBill | Cart> {
        if (isV2) {
            return this.getCartBillV2(selectedPayment as ComponentPaymentMethodName);
        }
        return this.getCartBillV1(selectedPayment, additionalFee);
    }

    buildEndPoint(uri: string, params: { [key: string]: string | number | boolean | null | undefined }): string {
        let finalParams: string = '';
        for (let key in params) {
            if (params[key] != null) {
                if (finalParams != '') {
                    finalParams += '&';
                }
                finalParams += key + '=' + encodeURIComponent(params[key] as string | number | boolean);
            }
        }
        return finalParams ? `${uri}?${finalParams}` : uri;
    }

    getCartBillV1(selectedPayment: PaymentMethodsKeys, additionalFee?: number): Observable<CartBill> {
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);
        const url = `${this._cartUrl}/getCartBill`;
        const urlParams = this.buildEndPoint(url, {
            paymentMethod: selectedPayment,
            additionalFee: additionalFee,
            date: new Date().valueOf(),
        });
        return this._httpClient.get<CartBill>(urlParams, { headers }).pipe();
    }

    getCartBillV2(selectedPayment: ComponentPaymentMethodName | undefined): Observable<Cart> {
        return this._cartService.getCartBillV2(selectedPayment);
    }

    payOpenInvoices(
        paymentMetaData: { activeProfileView: string; openInvoiceIds: string[] },
        paymentToken: PayInvoicesPayload
    ): Observable<any> {
        const url = this._cartUrl + '/pay';
        let headers: HttpHeaders = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this._tokenService.getCurrentUser()}`);

        return this._httpClient.post(url, { paymentMetaData, paymentToken }, { headers });
    }
}
