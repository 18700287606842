export enum ThemeType {
    sprintpay = 'sprintpay',
    agi = 'agi',
}

export enum BrandDomainType {
    sprintpay = 'cargosprint.com',
    agi = 'agi.com',
}

export enum ThemeStyleFileType {
    sprintpay = 'main-sprintpay.css',
    agi = 'main-agi.css',
}
