import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { DynamicFieldDTO } from 'src/app/models/ui/dynamic-field-common.model';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { ErrorMatcher } from '../../../utils/errorMatcher';

@Component({
    selector: 'app-textbox',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatInputModule],
    templateUrl: './textbox.component.html',
})
export class TextboxComponent implements OnInit {
    dynamic_minlength!: number;
    dynamic_maxlength!: number;
    dynamic_pattern!: string;
    dynamic_required!: boolean;
    showTooltip: boolean;
    matcher: ErrorMatcher = new ErrorMatcher();

    @Input() field!: DynamicFieldDTO;
    @Input() dynamicFormGroup!: FormGroup;

    constructor(private _paymentFluxService: PaymentFluxService) {
        this.showTooltip = false;
    }

    ngOnInit(): void {
        const dynamicValues = this._paymentFluxService.getDynamicValues();
        if (dynamicValues) {
            Object.keys(dynamicValues).forEach((key: string) => {
                if (key === this.field.id.toString()) {
                    this.dynamicFormGroup.controls[this.field.name].setValue(dynamicValues[key]);
                }
            });
        }
        this._checkValidations();
    }

    /**
     * @method _checkValidations()
     * @description Review and set the validators for the field
     */

    private _checkValidations(): void {
        this.dynamic_minlength = (this.field?.inputValidations?.['minlength']?.value as number) || 0;
        this.dynamic_maxlength = (this.field?.inputValidations?.['maxLength']?.value as number) || 524288;
        this.dynamic_pattern = (this.field?.inputValidations?.['pattern']?.value as string) || '';
        this.dynamic_required = (this.field?.inputValidations?.['required']?.value as boolean) || false;
    }
}
