import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class FileService {
    private _file: BehaviorSubject<File | null> = new BehaviorSubject<File | null>(null);
    private _checkFileFormStatus: Subject<void> = new Subject();

    setFile(file: File | null): void {
        this._file.next(file);
    }

    getFiles(): Observable<File | null> {
        return this._file.asObservable();
    }

    get instant_files(): File | null {
        return this._file.value;
    }

    checkFileFormStatus(): void {
        this._checkFileFormStatus.next();
    }

    getCheckFileFormStatus(): Observable<void> {
        return this._checkFileFormStatus.asObservable();
    }
}
