import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { PendingApprovalRoutingModule } from './pending-approval-routing.module';

//COMPONENTS

import { PendingApprovalComponent } from './pending-approval.component';

//MODULES

import { SharedModule } from '../../../../shared/shared.module';
import { PaymentDetailModule } from './payment-detail/payment-detail.module';

@NgModule({
    declarations: [PendingApprovalComponent],
    imports: [
        CommonModule,
        PendingApprovalRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        PaymentDetailModule,
    ],
})
export class PendingApprovalModule {
    constructor() {}
}
