import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaymentMethodEvents } from 'src/app/models/events/payment-methods-events';

export interface PaymentMethodActions {
    event: PaymentMethodEvents;
    setAsDefault: boolean;
}

@Injectable()
export class PaymentMethodsService {
    private _handlerEvent: BehaviorSubject<PaymentMethodActions | null> =
        new BehaviorSubject<PaymentMethodActions | null>(null);

    constructor() {}

    newEventPaymentMethod(event: PaymentMethodEvents, setAsDefault = false): void {
        this._handlerEvent.next({ event, setAsDefault });
    }

    onEventPaymentMethod$(): Observable<PaymentMethodActions | null> {
        return this._handlerEvent.asObservable();
    }
}
