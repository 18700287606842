import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationsType } from 'src/app/models/notifications/notifications';

@Injectable({
    providedIn: 'root',
})
export class BannerService {
    private _notifications: BehaviorSubject<NotificationsType[]> = new BehaviorSubject<NotificationsType[]>([]);

    setNotifications(notifications: NotificationsType[]): void {
        this._notifications.next(notifications);
    }

    getNotifications(): Observable<NotificationsType[]> {
        return this._notifications.asObservable();
    }
}
