import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomersType } from '@cargos/sprintpay-models';
import { NgxPermissionsGuard } from 'ngx-permissions';

// COMPONENTS

import { BulkPaymentComponent } from './bulk-payment/bulk-payment.component';
import { NewPaymentComponent } from './new-payment.component';

const routes: Routes = [
    {
        path: '',
        title: 'SprintPay | New Payment',
        component: NewPaymentComponent,
        canLoad: [NgxPermissionsGuard],
        data: {
            breadcrumb: { alias: 'new-payment' },
            permissions: {
                only: [CustomersType.SALESFORCE_AUTHENTICATED, CustomersType.GUEST, 'BUSINESS'],
            },
        },
    },
    {
        path: 'bulk-payment',
        title: 'SprintPay | Bulk Payment',
        component: BulkPaymentComponent,
        canLoad: [NgxPermissionsGuard],
        data: {
            breadcrumb: { alias: 'bulk-payment' },
            permissions: {
                only: [CustomersType.SALESFORCE_AUTHENTICATED, 'BUSINESS'],
                except: [CustomersType.GUEST],
                redirectTo: '/admin/facilityPayments/newPayment',
            },
        },
    },
    { path: 'flux', loadChildren: () => import('./payment-flux/payment-flux.module').then((m) => m.PaymentFluxModule) },
    {
        path: 'codafacility',
        loadChildren: () => import('./coda-facility/codafacility.module').then((m) => m.CodafacilityModule),
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class NewPaymentRoutingModule {}
