<app-breadcrumb></app-breadcrumb>
<!--START: BULK PAYMENT BY PORT -->
<div id="upload-file-by-port" class="mt-4">
    <div id="upload-request__header" class="mb-3 pt-3">
        <!--START: HEADER AND ACTION BUTTONS-->
        <div class="row">
            <div class="col-12 col-md-6 d-flex align-items-center justify-content-start">
                <h6>Upload file by port</h6>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS-->
    </div>
    <div id="standard_flux--container">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-end align-items-center">
                    <button
                        mat-button
                        type="button"
                        color="warn"
                        class="d-block border-0 rounded-0 py-3 mt-4 text-uppercase btn__sprintpay btn__sprintpay--linkDelete"
                        (click)="returnToHome()"
                    >
                        Cancel process
                    </button>
                </div>
            </div>
        </div>
        <div class="payment-form-container mt-4 d-block">
            <!--START: FORM -->
            <form class="row" [formGroup]="paymentForm">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="row">
                        <div class="col-12">
                            <!--START: NOTIFICATION EMAIL FIELD -->
                            <div class="form-group">
                                <mat-form-field class="d-block" appearance="outline">
                                    <mat-label>Notification email</mat-label>
                                    <input
                                        matInput
                                        type="email"
                                        id="notificationEmail"
                                        name="notificationEmail"
                                        formControlName="notificationEmail"
                                        autocomplete="off"
                                        placeholder="Payment notification email"
                                        data-cy="notificationEmail"
                                        [errorStateMatcher]="matcher"
                                    />
                                    <mat-error *ngIf="paymentForm.controls['notificationEmail'].hasError('required')">
                                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Notification email is a
                                        required field
                                    </mat-error>
                                    <mat-error *ngIf="paymentForm.controls['notificationEmail'].hasError('pattern')">
                                        <i class="bi bi-exclamation-triangle-fill me-2"></i>Invalid email
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <!--END: NOTIFICATION EMAIL FIELD -->
                        </div>
                    </div>
                    <app-upload-file
                        [removingFile]="removingFile"
                        [downloadTemplateURL]="templateURL"
                        [templateName]="'bulk-payment-by-port-template'"
                        [progressBarPercentage]="progressBarPercentage"
                        (fileEvent)="handleFileEvent($event)"
                    ></app-upload-file>
                </div>
            </form>
            <!--END: FORM -->
            <!--START: STEPPER NEXT AND BACK BUTTON -->
            <div class="row">
                <div class="col-sm-12 col-md-3 offset-md-9">
                    <button
                        mat-flat-button
                        color="primary"
                        (click)="addToCart(paymentForm)"
                        type="submit"
                        class="d-block border-0 rounded-0 w-100 text-uppercase btn__sprintpay btn__sprintpay--green cy__next--stepone"
                    >
                        Continue
                    </button>
                </div>
            </div>
            <!--END: STEPPER NEXT AND BACK BUTTON -->
        </div>
    </div>
</div>
<!--END: BULK PAYMENT BY PORT -->
