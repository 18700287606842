import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Actions } from 'src/app/models/utils/actions.model';

@Component({
    selector: 'app-actions-table',
    standalone: true,
    templateUrl: './actions-table.component.html',
    imports: [CommonModule, MatIconModule, MatTooltipModule, MatMenuModule, MatButtonModule],
})
export class ActionsTableComponent implements OnInit {
    @Input() set actions(actions: Actions[]) {
        this.am_actions = actions;
    }

    @Input() set record(record: any) {
        this.rowRecord = record;
    }

    @Output() clickAction = new EventEmitter();
    public am_actions: Actions[] = [];
    public rowRecord: any;
    public countActions: number = 0;

    ngOnInit(): void {
        if (this.rowRecord && this.am_actions) {
            this.countActions = 0;
            this.am_actions.map((item: any) => {
                if (this.rowRecord && this.evalAction(this.rowRecord, item)) {
                    this.countActions++;
                }
            });
        }
    }
    /**
     * @method evalAction()
     * @param (item: any)
     * @param (action: any)
     * @description
     */
    evalAction(item: any, action: any): any {
        if (action.condition === 'hasRenderableFunc') {
            let funcBinding = action.hasRenderableFunc.prototype[action.hasRenderableFunc.name].bind(
                action.hasRenderableFunc.prototype
            );
            const isRenderable = funcBinding(item.id);
            return eval(isRenderable);
        }
        return eval(action.condition);
    }

    /**
     * @method selectedAction()
     * @param (record: any)
     * @param (item: any)
     * @description
     */
    selectedAction(record: any): void {
        this.clickAction.emit({ record, item: this.rowRecord });
    }
}
