<app-breadcrumb></app-breadcrumb>
<app-no-information *ngIf="noInformation" [error_message]="noInformationMessage"></app-no-information>
<!--START: PAYMENT HISTORY -->
<section id="payment_history_detail" *ngIf="paymentDetailFinish && !noInformation ">
    <div id="payment_history_detail__header" class="mt-3 px-3">
        <!--START: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
        <div class="row">
            <div class="col-12 col-md-6 d-flex align-items-center justify-content-start">
                <div class="row">
                    <div class="col-12">
                        <h1 class="fs-5 lh-1">
                            Payment Detail: {{ paymentDetails[0].vendorInvoiceNumber | formatPaymentReference:id }}
                        </h1>
                    </div>
                </div>
            </div>
            <div class="col-6 justify-content-end d-none d-md-flex align-items-start">
                <div *ngIf="paymentLogs && paymentLogs.length" class="align-items-baseline">
                    <button
                        mat-button
                        type="button"
                        class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-end payment_history__download"
                        (click)="openDialog(logSideNav)"
                    >
                        <mat-icon class="material-symbols-outlined align-middle me-2">show_chart</mat-icon>
                        Activity log
                    </button>
                </div>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
        <!--START: HEADER AND ACTION BUTTONS FOR SMALL SCREEN -->
        <div class="row">
            <div
                *ngIf="paymentLogs && paymentLogs.length"
                class="col-12 justify-content-end d-flex d-md-none align-items-center"
            >
                <button
                    mat-button
                    type="button"
                    class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-start payment_history__download"
                    (click)="openDialog(logSideNav)"
                >
                    <mat-icon class="material-symbols-outlined align-middle me-2">show_chart</mat-icon>
                    Activity log
                </button>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS FOR SMALL SCREEN -->
    </div>
    <div class="container-fluid">
        <mat-accordion>
            <div class="details-by-section">
                <div class="mb-4 py-2">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="p-4">
                                <app-details-by-section
                                    [values]="customerInformation"
                                    [title]="'Customer information'"
                                ></app-details-by-section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngFor="let paymentDetail of paymentDetails; index as i; first as isFirst">
                <mat-expansion-panel [expanded]="isFirst" class="my-3">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3 class="fs-5 lh-1 my-3">
                                Primary facility reference #: {{paymentDetail?.awb}} - {{paymentDetail.paymentType}}
                            </h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="py-4">
                                <!-- START: DETAIL CONTAINER -->
                                <!--START: TEMPLATE PAYMENT DETAILS -->
                                <app-payment-details
                                    [paymentDetail]="paymentDetails[0]"
                                    [files]="files[0].urls"
                                    [nameFiles]="files[0].names"
                                    [values]="paymentDetailsSection[i]"
                                ></app-payment-details>
                                <!--END: TEMPLATE PAYMENT DETAILS -->
                                <!--START: TEMPLATE CUSTOMER REFERENCE -->
                                <app-customer-reference
                                    [values]="paymentCustomerReference[i]"
                                    [paymentDetail]="paymentDetails[0]"
                                ></app-customer-reference>
                                <!--END: TEMPLATE CUSTOMER REFERENCE -->
                                <ng-container [ngSwitch]="paymentDetails[i].companyType">
                                    <!--START: TEMPLATE KN USERS -->
                                    <app-kn-customer-reference
                                        *ngSwitchCase="companyNames.kn"
                                        [paymentDetail]="paymentDetails[i]"
                                    ></app-kn-customer-reference>
                                    <!--END: TEMPLATE KN USERS -->
                                    <!--START: TEMPLATE SCHENKER USERS -->
                                    <app-schenker-customer-reference
                                        *ngSwitchCase="companyNames.schenker"
                                        [paymentDetail]="paymentDetails[i]"
                                    ></app-schenker-customer-reference>
                                    <!--END: TEMPLATE SCHENKER USERS -->
                                    <!--START: TEMPLATE DHL USERS -->
                                    <app-dhl-customer-reference
                                        *ngSwitchCase="companyNames.dhl"
                                        [paymentDetail]="paymentDetails[i]"
                                    ></app-dhl-customer-reference>
                                    <!--END: TEMPLATE KN USERS -->
                                    <!--START: TEMPLATE GEODIS USERS -->
                                    <app-geodis-customer-reference
                                        *ngSwitchCase="companyNames.geodis"
                                        [paymentDetail]="paymentDetails[i]"
                                    ></app-geodis-customer-reference>
                                    <!--END: TEMPLATE GEODIS USERS -->
                                    <!--START: TEMPLATE EMOTRANS USERS -->
                                    <app-emotrans-customer-reference
                                        *ngSwitchCase="companyNames.emotrans"
                                        [paymentDetail]="paymentDetails[i]"
                                    ></app-emotrans-customer-reference>
                                    <!--END: TEMPLATE EMOTRANS USERS -->
                                    <!--START: TEMPLATE RHENUS USERS -->
                                    <app-rhenus-customer-reference
                                        *ngSwitchCase="companyNames.rhenus"
                                        [paymentDetail]="paymentDetails[i]"
                                    ></app-rhenus-customer-reference>
                                    <!--END: TEMPLATE RHENUS USERS -->
                                    <!--START: TEMPLATE DYNAMIC COMPANY -->
                                    <app-company-customer-reference
                                        [paymentDetail]="paymentDetails[i]"
                                        *ngSwitchDefault
                                    ></app-company-customer-reference>
                                    <!--END: TEMPLATE DYNAMIC COMPANY -->
                                </ng-container>
                                <!-- END: DETAIL CONTAINER -->
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </ng-container>
        </mat-accordion>
    </div>
</section>
<!--END: PAYMENT HISTORY -->

<!--START: TEMPLATE ACTIVITY LOG SIDENAV -->
<ng-template #logSideNav>
    <app-timeline [paymentLogs]="paymentLogs" [paymentNumber]="this.paymentDetails[0].awb"></app-timeline>
</ng-template>
<!--END: TEMPLATE ACTIVITY LOG SIDENAV -->
