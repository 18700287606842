import { HttpEventType } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription, map, takeUntil, throwError } from 'rxjs';
import { NewPaymentService } from 'src/app/services/new-payment.service';
import { PaymentService } from 'src/app/services/payment.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { SessionService } from 'src/app/services/utils/session.service';
import { customerReferenceGeodisData } from 'src/app/utils/companies/geodis';
import { companyName, customerReference } from 'src/app/utils/constants';
import { customerReferenceExpeditorsData } from 'src/app/utils/facilities/expeditors';
import { Sections } from 'src/app/utils/sections';
import { environment } from 'src/environments/environment';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { Facility } from '../../../../../models/payments/facility.model';
import { PaymentFluxService } from '../../../../../services/utils/payment-flux.service';
import { SecurityService } from '../../../../../services/utils/security.service';
import { UploadFileHandlerService } from '../../../../../services/utils/upload-file-handler.service';
import { BulkPaymentResultComponent } from '../../../../../standalone-components/bulk-payment-result/bulk-payment-result.component';
import { FileService } from '../../../../../standalone-components/file-handler/services/file.service';
import { profileComponents, regex } from '../../../../../utils/constants';
import { ErrorMatcher } from '../../../../../utils/errorMatcher';

@Component({
    selector: 'app-bulk-payment',
    templateUrl: './bulk-payment.component.html',
    providers: [FileService],
})
export class BulkPaymentComponent implements OnInit, AfterViewInit, OnDestroy {
    matcher: ErrorMatcher;
    paymentForm!: FormGroup;
    formSubmitted: boolean;
    progressBarPercentage: number;
    templateURL!: string;
    selectedFileSize: any;
    template: any;
    cartSecurity: Array<string>;
    newPaymentData: Facility | undefined;
    facility: any;
    removingFile!: boolean;
    private _selectedFile!: File | null;
    private _bulkPaymentRequests: any[];
    private _currentPayment: any;
    private _isNotStandardFlux: boolean;
    private _customer: any;
    private _locationId: number;
    private readonly _companyName: string;
    private readonly _paymentsURL: string;
    private readonly _cartURL: string;
    private _companiesArray: { company: string; turnOn: boolean }[];
    private _customerReferenceHeader: string;
    private _subscription: Subscription;
    private _previewCartCount: number;
    private _dynamicFields: any;
    private _isDynamicCompany: boolean;
    private _unsubscribe$: Subject<void>;

    constructor(
        private _router: Router,
        private _formBuilder: FormBuilder,
        private _customerService: CustomerService,
        private _paymentFluxService: PaymentFluxService,
        private _sessionService: SessionService,
        private _uploadFileService: UploadFileHandlerService,
        private _securityService: SecurityService,
        private _paymentService: PaymentService,
        private _viewContainerRef: ViewContainerRef,
        private _newPaymentService: NewPaymentService,
        private _fileService: FileService,
        private _ngxSpinnerService: NgxSpinnerService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this._subscription = Subscription.EMPTY;
        this._paymentsURL = environment.uris.method.payments;
        this._cartURL = environment.uris.method.cart;
        this.matcher = new ErrorMatcher();
        this.formSubmitted = false;
        this.progressBarPercentage = 0;
        this._customer = this._customerService.getCustomer();
        this._companyName = this._customer?.approvalLevels?.company?.name || null;
        this._locationId = this._customer?.approvalLevels?.company?.firstLocation?.id || null;
        this._isNotStandardFlux = !!this._companyName;
        this._setFromBuilder();
        this._bulkPaymentRequests = [];
        this._currentPayment = this._paymentFluxService.getCurrentPayment();
        this.facility = this._currentPayment.facility ?? {};
        this._customerReferenceHeader = '';
        this.templateURL = `${this._paymentsURL}/download/payment/template`;
        this._companiesArray = [
            { company: companyName.kn, turnOn: this._companyName === companyName.kn },
            { company: companyName.schenker, turnOn: this._companyName === companyName.schenker },
            { company: companyName.dhl, turnOn: this._companyName === companyName.dhl },
            { company: companyName.geodis, turnOn: this._companyName === companyName.geodis },
            { company: companyName.emotrans, turnOn: this._companyName === companyName.emotrans },
            { company: companyName.rhenus, turnOn: this._companyName === companyName.rhenus },
        ];
        this._previewCartCount = 0;
        this.cartSecurity = [''];
        this._isDynamicCompany = this._companyName
            ? Object.keys(companyName).indexOf(this._companyName.toLowerCase()) < 0
            : false;
        this._newPaymentService
            .getSelectedPayment()
            .pipe(
                map((payment) => (this.newPaymentData = payment)),
                takeUntil(this._unsubscribe$)
            )
            .subscribe();
    }

    ngOnInit(): void {
        this._ngxSpinnerService.hide();
        this._newPaymentService
            .getSelectedPayment()
            .pipe(
                map((payment) => (this.newPaymentData = payment)),
                takeUntil(this._unsubscribe$)
            )
            .subscribe();
        if (this._isDynamicCompany && this._customerService.getCompanyId()) {
            this.getAllFieldsWithReplacement();
        }
        this.setNotificationEmail();
        this._getCartCount();
        this._handleSecuritySideBar();
        this.removingFile = false;
    }

    /**
     * @method _handleSecuritySideBar()
     * @description Handles the logic for the security based on user and roles
     */

    private _handleSecuritySideBar(): void {
        if (this._securityService.verifyComponentsSecurity(profileComponents.cart)) {
            this.cartSecurity.push(this._securityService.getUserType());
        }
    }

    ngAfterViewInit(): void {
        this.change(this.paymentForm);
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    /**
     * @method setNotificationEmail()
     * @description Set the form values for initializing
     */
    setNotificationEmail(): void {
        this.notificationEmail.setValue(this._customer.notificationEmail ?? this._customer.email);
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */
    private _setFromBuilder(): void {
        this.paymentForm = this._formBuilder.group({
            notificationEmail: ['', [Validators.required, Validators.minLength(5), Validators.pattern(regex.email)]],
            nameFiles: [null, [Validators.required]],
        });
    }

    /**
     * @method notificationEmail()
     * @description: Convenience getter for easy access to form fields
     */
    get notificationEmail(): FormGroup {
        return this.paymentForm.get('notificationEmail') as FormGroup;
    }

    /**
     * @method nameFiles()
     * @description: Convenience getter for easy access to form fields
     */
    get nameFiles(): FormGroup {
        return this.paymentForm.get('nameFiles') as FormGroup;
    }

    /**
     * @method handleFileEvent()
     * @param (event: any)
     * @description save and handle the files
     */
    handleFileEvent(event: File | null): void {
        if (event) {
            this.paymentForm.controls['nameFiles'].setValue(event.name);
            this._selectedFile = event;
            this.removingFile = false;
        } else {
            this.clearFileResources();
        }
    }

    /**
     * @method removeDragData()
     * @param (event: any)
     * @description removed the drag files
     */
    removeDragData(event: any): void {
        if (event.dataTransfer.items) {
            event.dataTransfer.items.clear();
        } else {
            event.dataTransfer.clearData();
        }
    }

    /**
     * @method clearFileResources()
     * @description clear file resource
     */
    clearFileResources(): void {
        this.paymentForm.get('nameFiles')!.setValue(null);
        this.paymentForm.value.nameFiles = null;
        this._paymentFluxService.setData('bulkPaymentByFacility', this.paymentForm.getRawValue());
    }

    /**
     * @method returnToFacility()
     * @description back to facility
     */
    returnToFacility(): void {
        this._newPaymentService.setSelectedPayment(undefined);
        this._router.navigate(['/admin/facilityPayments/newPayment']);
    }

    /**
     * @method change()
     * @param (form: any)
     * @description save the changed form values
     */
    change(form: any): void {
        this._paymentFluxService.setData('bulkPaymentByFacility', form.getRawValue());
    }

    /**
     * @method forceToNext()
     * @description go to cart
     */
    forceToNext(): void {
        this._router.navigate(['/admin/cart']);
        this._paymentFluxService.removeCurrentPayment();
    }

    /**
     * @method uploadAgain()
     * @description upload file again
     */
    uploadAgain(): void {
        this.progressBarPercentage = 0;
        this._bulkPaymentRequests = [];
    }

    /**
     * @method goToHome()
     * @description go to home
     */
    goToHome(): void {
        this._paymentFluxService.removeCurrentPayment();
        this._router.navigate(['/admin/home']);
    }

    /**
     * @method goToPendingApprovals()
     * @description go to pending approval's payment list
     */
    goToPendingApprovals(): void {
        this._paymentFluxService.removeCurrentPayment();
        this._router.navigate(['/admin/facilityPayments/pendingApproval/paymentList']);
    }

    /**
     * @method _getCartCount()
     * @description Return the number of element on the shopping cart
     */

    private _getCartCount(): void {
        this._subscription = this._sessionService.getPayCount().subscribe({
            next: (result: any): void => {
                this._previewCartCount = result;
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
    }

    /**
     * @method addToCart()
     * @param (form: any)
     * @description add to cart
     */
    addToCart(form: any): void {
        const approveFluxApiURL = `/bulk/startApprovalFlow?facilityId=${this.facility.id}&companyName=${
            this._companyName
        }&notificationEmail=${this.notificationEmail.value}${
            !!this._locationId ? `&locationId=${this._locationId}` : ''
        }`;
        const fluxApiUrl = `${this._cartURL}${
            this._isNotStandardFlux ? approveFluxApiURL : '/customer/uploadPaymentRequests'
        }`;
        if (form.valid && !this.formSubmitted) {
            this.formSubmitted = true;
            let formData = new FormData();
            this._selectedFile && formData.append('file', this._selectedFile);
            if (!this._isNotStandardFlux) {
                formData.append('facilityId', this.facility.id);
                formData.append('notificationEmail', this.notificationEmail.value);
            }
            this.progressBarPercentage = 1;
            this._uploadFileService
                .submitUploadedFile(fluxApiUrl, formData)
                .pipe(takeUntil(this._unsubscribe$))
                .subscribe({
                    next: (response: any) => {
                        if (response.type === HttpEventType.UploadProgress) {
                            this.progressBarPercentage = Math.round((100 * response.loaded) / response.total);
                        }
                        if (response.type === HttpEventType.Response) {
                            this.progressBarPercentage = 100;
                            this._bulkPaymentRequests = JSON.parse(response.body).map((item: any) => {
                                item['icon'] =
                                    item.status.trim().toLowerCase() == 'failed'
                                        ? 'warning'
                                        : item.status.trim().toLowerCase() == 'added to cart' ||
                                            item.status.trim().toLowerCase() == 'success'
                                          ? 'add_shopping_cart'
                                          : 'send';
                                return item;
                            });
                            const paymentRequest = response.status.addedToCart;
                            this._sessionService.setPayCount(this._previewCartCount + paymentRequest);
                            this.paymentForm.controls['nameFiles'].setValue(this._selectedFile?.name);
                            this._paymentFluxService.setData('bulkPaymentByFacility', this.paymentForm.getRawValue());
                            this._determinePaymentModal(response.status);
                        }
                    },
                    error: (err: any) => {
                        Swal.fire({
                            title: 'Alert!',
                            html: err,
                            icon: 'info',
                            width: '34em',
                            showConfirmButton: true,
                            confirmButtonText: 'Continue',
                            confirmButtonColor: '#14bb9c',
                            allowOutsideClick: false,
                        }).then(() => {
                            this._selectedFile = null;
                            this._fileService.setFile(null);
                            this.progressBarPercentage = 0;
                            this.removingFile = true;
                            this.formSubmitted = false;
                        });
                        this.clearFileResources();
                    },
                });
        }
    }

    /**
     * @method _determinePaymentModal()
     * @description determine the bulk payment modal appributes based on the different use cases
     */
    private _determinePaymentModal(status?: any): void {
        let rejectedPaymentRequests: number;
        let submittedToApproverRequests: number;
        let addedToCartRequests: number;
        let requestIcon: SweetAlertIcon;
        let requestTitleTxt: string;
        let requestStatusCountTxt: string;
        let requestStatusTxt: string;
        let requestHelpTxt: string = '';
        let requestHelpNoteTxt: string = '';
        rejectedPaymentRequests = status.failed;
        submittedToApproverRequests = status.submitted;
        addedToCartRequests = status.addedToCart;
        if (rejectedPaymentRequests === this._bulkPaymentRequests.length) {
            requestIcon = 'error';
            requestStatusCountTxt = !this._isNotStandardFlux
                ? `${rejectedPaymentRequests} of ${this._bulkPaymentRequests.length}`
                : '';
            requestHelpTxt = 'See below for details.';
            requestTitleTxt = this._isNotStandardFlux
                ? 'Error: your file was rejected'
                : 'Warning: your file has an error(s)';
            requestStatusTxt = !this._isNotStandardFlux ? 'payments have not been sent.' : '';
        } else {
            requestTitleTxt = submittedToApproverRequests === 0 ? 'Payments sent' : 'Requests sent';
            if (rejectedPaymentRequests > 0) {
                requestIcon = 'warning';
                requestStatusCountTxt = this._isNotStandardFlux
                    ? `${this._bulkPaymentRequests.length - rejectedPaymentRequests} of ${
                          this._bulkPaymentRequests.length
                      }`
                    : `${rejectedPaymentRequests} of ${this._bulkPaymentRequests.length}`;
                requestStatusTxt = this._isNotStandardFlux
                    ? 'requests have been sent.'
                    : 'payments have not been sent.';
                requestHelpTxt = this._isNotStandardFlux
                    ? 'If you would like to update the requests with issues, submit a new file with the corrections.'
                    : '';
                requestHelpNoteTxt = this._isNotStandardFlux
                    ? 'remove from your original file the requests that were successful to avoid creating duplicate requests.'
                    : '';
            } else {
                requestIcon = 'success';
                requestStatusCountTxt = `${this._bulkPaymentRequests.length}`;
                requestStatusTxt =
                    addedToCartRequests === this._bulkPaymentRequests.length
                        ? 'payments have been sent to your cart.'
                        : 'requests have been sent.';
            }
        }
        this._bulkPaymentModal(
            requestIcon,
            requestTitleTxt,
            requestStatusTxt,
            requestStatusCountTxt,
            requestHelpTxt,
            requestHelpNoteTxt,
            submittedToApproverRequests
        );
    }

    /**
     * @method _bulkPaymentModal()
     * @description bulk payment modal to show the payment reqeust list
     */
    private _bulkPaymentModal(
        requestIcon: SweetAlertIcon,
        requestTitleTxt: string,
        requestStatusTxt: string,
        requestStatusCountTxt: string,
        requestHelpTxt: string,
        requestHelpNoteTxt: string,
        submittedToApproverRequests: any
    ): void {
        this._setCustomerReferenceLabel();

        const data = {
            requestIcon,
            requestTitleTxt,
            requestStatusTxt,
            requestStatusCountTxt,
            requestHelpTxt,
            requestHelpNoteTxt,
            submittedToApproverRequests,
            uploadAgainOutlinedBtnCondition: requestIcon === 'warning' || requestIcon === 'error',
            goToPendingApprovalsBtnCondition:
                requestIcon === 'success' &&
                submittedToApproverRequests.length === this._bulkPaymentRequests.length &&
                this._isNotStandardFlux,
            goToHomeBtnCondition: requestIcon === 'error' && !this._isNotStandardFlux,
            goToCartBtnCondition:
                (requestIcon === 'warning' && this.cartSecurity[0] === '' && this.cartSecurity.length > 1) ||
                (requestIcon === 'success' &&
                    ((this.cartSecurity[0] === '' &&
                        this.cartSecurity.length > 1 &&
                        submittedToApproverRequests.length > 0 &&
                        submittedToApproverRequests.length !== this._bulkPaymentRequests.length) ||
                        submittedToApproverRequests.length === 0 ||
                        !this._isNotStandardFlux)),
            backToHomeBtnCondition:
                requestIcon === 'success' &&
                submittedToApproverRequests.length === this._bulkPaymentRequests.length &&
                this._isNotStandardFlux,
            data: this._bulkPaymentRequests,
        };

        const component = this._viewContainerRef.createComponent(BulkPaymentResultComponent);
        component.instance.data = data;

        Swal.fire({
            icon: requestIcon,
            width: '45em',
            html: component.location.nativeElement,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
            allowOutsideClick: false,
            didOpen: () => {
                const subscription = component.instance.callAction.subscribe((action) => {
                    Swal.close();
                    switch (action) {
                        case 'uploadAgain':
                            this.uploadAgain();
                            break;
                        case 'goToPendingApprovals':
                            this.goToPendingApprovals();
                            break;
                        case 'goToHome':
                            this.goToHome();
                            break;
                        case 'forceToNext':
                            this.forceToNext();
                            break;
                    }
                });
                this._subscription.add(subscription);
            },
        }).then(() => {
            this.formSubmitted = false;
            this.clearFileResources();
            this.removingFile = true;
        });
    }

    /**
     * @method clearErrMessage()
     * @description clear error message
     */
    clearErrMessage(): void {
        this.template = '';
    }

    /**
     * @method _setCustomerReferenceLabel()
     * @description get the customer reference title
     */

    private _setCustomerReferenceLabel() {
        let geodisDomain = this._customerService.isUserDomain(companyName.geodis.toLowerCase());
        if (this._companyName) {
            this._companiesArray.every((company: any) => {
                this._customerReferenceHeader = Sections.customerReferenceStartingLevelLabelTags(company);
                return !this._customerReferenceHeader;
            });
        } else {
            if (this._customer.hasGlCodes) {
                this._customerReferenceHeader = geodisDomain
                    ? customerReferenceGeodisData.titleCustomerReference
                    : customerReferenceExpeditorsData.titleCustomerReference;
            } else if (geodisDomain) {
                this._customerReferenceHeader = customerReferenceGeodisData.titleCustomerReference;
            } else {
                this._customerReferenceHeader = customerReference.titleCustomerReference;
            }
        }
    }

    /**
     * @method getAllFieldsWithReplacement()
     * @description
     */

    getAllFieldsWithReplacement(): void {
        this._dynamicFields = [];
        this._paymentService.getDynamicFieldsColumns().subscribe({
            next: (response: any): void => {
                if (Object.values(response.fields).length > 0) {
                    this._dynamicFields = Object.values(response.fields)
                        .flat()
                        .filter((item: any) => !!item.isReplacement)
                        .map((item: any) => {
                            return {
                                name: item.name,
                                label: item.label,
                            };
                        });
                }
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
    }

    /**
     * @method getDynamicFieldsColumns()
     * @description
     */

    getDynamicFieldsColumns(column: string): string {
        if (this._isDynamicCompany && this._customerService.getCompanyId()) {
            const dynamicColumn = this._dynamicFields.filter((item: any) => item.name === column) || [];
            const header = dynamicColumn.length > 0 ? dynamicColumn[0].label : '';
            return header;
        }
        return '';
    }
}
