import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// COMPONENTS

import { InvoicesComponent } from './invoices.component';

const routes: Routes = [
    {
        path: '',
        component: InvoicesComponent,
        data: { breadcrumb: { alias: 'invoices', disable: true } },
        children: [
            {
                path: 'open',
                loadChildren: () => import('./open/open.module').then((m) => m.OpenModule),
            },
            {
                path: 'paid',
                loadChildren: () => import('./paid/paid.module').then((m) => m.PaidModule),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class InvoicesRoutingModule {}
