import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { AuthService } from 'src/app/services/utils/auth.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { TokenService } from 'src/app/services/utils/token.service';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(
        private _tokenService: TokenService,
        private _customerService: CustomerService,
        private _authService: AuthService
    ) {}

    /**
     * @method getCustomerProfile()
     * @description
     */
    getCustomerProfile(token: string): Observable<any> {
        const customer = this.getCustomerByToken(token);
        if (!!customer?.customerProfile?.components.length) {
            return of(customer.customerProfile);
        }
        return this._customerService.customerProfile(token);
    }

    /**
     * @method getRedirectLink()
     * @description
     */

    getRedirectLink(): string {
        const profileType = this._authService.getSprintPayProfileType();
        if (profileType === 'FACILITY') {
            return 'super-admin/payments';
        }
        return 'admin/home';
    }

    /**
     * @method initLogin()
     * @description
     */

    initLogin(token: string): Observable<string> {
        return this.getCustomerProfile(token).pipe(
            map((customerProfile: any) => {
                this._customerService.setCustomerProfile(customerProfile);
                return token;
            })
        );
    }

    /**
     * @method getCustomerByToken()
     * @description
     */

    getCustomerByToken(token: string): any {
        return JSON.parse(
            JSON.parse(JSON.stringify(this._tokenService.getDecodedAccessToken(token)))?.SPRINT_PAY_CUSTOMER
        )?.customer;
    }
}
