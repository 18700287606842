export class CustomMasks {
    static readonly ROUTING_NUMBER = '000000000';

    static iMaskSecurityCode(size: 3 | 4): string {
        return size === 3 ? '000' : '0000';
    }

    static maskCreditCard(gaps: number[], lengths: number[]): string {
        if (!(Array.isArray(gaps) && gaps.length > 0 && Array.isArray(lengths) && lengths.length > 0)) {
            return '0000 0000 0000 0000';
        }
        return Array.from({
            length: lengths.sort((a, b) => a - b)[0],
        })
            .flatMap((_, index) => {
                if (gaps.includes(index)) {
                    return [' ', 0];
                }

                return [0];
            })
            .join('');
    }

    static readonly iMaskExpirationDate: string = '00/00';
    static readonly iMaskZip: string = '00000';

    static iMaskZipCodeValidation(country: string): {
        zipCodeLength: number;
        zipMask: string;
    } {
        const countryParse = country === 'CAN' ? 'Canada' : country;
        const zipCodeConfig = {
            zipCodeLength: countryParse === 'Canada' ? 6 : 5,
            zipMask: countryParse === 'Canada' ? 'AAAAAA' : '00000',
        };

        return zipCodeConfig;
    }
}
