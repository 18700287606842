import { HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// ROUTING

import { AppRoutingModule } from './app-routing.module';

// NGX PERMISSIONS
import { NgxMaskModule } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';

// HASH LOCATION RELOAD

import { LocationStrategy, PathLocationStrategy } from '@angular/common';

// COMPONENTS

import { AppComponent } from './app.component';

// INTERCEPTORS

import { HttpHeadersInterceptor } from './interceptors/http-headers.interceptor';
import { HttpUnauthorizedInterceptor } from './interceptors/http-unauthorized.interceptor';

// DIRECTIVES

import { CatchEventsModule } from './directives/catch-events.module';

//MODULE

import { SPRINTPAY_SERVICES_TOKEN, SprintPayAPIsModule } from '@cargos/sprintpay-services';
import { AdminModule } from './pages/admin/admin.module';
import { ActivateModule } from './pages/auth/activate/activate.module';
import { LoginModule } from './pages/auth/login/login.module';
import { PasswordModule } from './pages/auth/password/password.module';
import { SignupModule } from './pages/auth/signup/signup.module';
import { TokenService } from './services/utils/token.service';
import { SharedModule } from './shared/shared.module';

// Google Maps
import { GoogleMapsModule } from '@angular/google-maps';

// Google Analytics

import { environment } from 'src/environments/environment';
import { GoogleAnalyticsModule, createGoogleAnalyticsConfig } from './modules/google-analytics';
import { Config } from './utils/config';

// SERVICES

import { UrlService } from './services/url.service';
import { ApplicationErrorHandlerService } from './services/utils/application-error-handler.service';

@NgModule({
    declarations: [AppComponent],
    imports: [
        CatchEventsModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxPermissionsModule.forRoot(),
        SharedModule,
        LoginModule,
        AdminModule,
        SignupModule,
        PasswordModule,
        NgxMaskModule.forRoot(),
        ActivateModule,
        SprintPayAPIsModule.forRoot({
            environment: environment.environment,
            tokenGetter: {
                tokenProvider: {
                    provide: SPRINTPAY_SERVICES_TOKEN,
                    useFactory: tokenFactory,
                    deps: [TokenService],
                },
            },
            config: {
                apisWithoutHeaders: Config.apisWithoutHeader,
                apisWithoutContentType: Config.apisWithoutContentType,
                apisWithoutAccessControl: Config.apisWithoutAccessControl,
                allowedDomains: Config.allowedDomains,
                googleMapApiKey: environment.googleMapApiKey,
            },
        }),
        GoogleAnalyticsModule.forRoot(createGoogleAnalyticsConfig(environment)),
        GoogleMapsModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpHeadersInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpUnauthorizedInterceptor, multi: true },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: ErrorHandler, useClass: ApplicationErrorHandlerService },
        UrlService,
    ],
    exports: [HttpClientJsonpModule],
    bootstrap: [AppComponent],
})
export class AppModule {}

function tokenFactory(tokenService: TokenService): () => string | null {
    return () => tokenService.getCurrentUser();
}
