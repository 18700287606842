import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFieldDTO } from 'src/app/models/ui/dynamic-field-common.model';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { FileComponent } from '../file/file.component';
import { InputComponent } from '../input/input.component';
import { RadioButtonComponent } from '../radio-button/radio-button.component';
import { TextboxComponent } from '../textbox/textbox.component';

@Component({
    selector: 'app-dynamic-container',
    standalone: true,
    imports: [
        CommonModule,
        InputComponent,
        CheckboxComponent,
        DropdownComponent,
        RadioButtonComponent,
        TextboxComponent,
        FileComponent,
    ],
    templateUrl: './dynamic-container.component.html',
})
export class DynamicContainerComponent {
    @Input() field!: DynamicFieldDTO;
    @Input() dynamicFormGroupName!: string;
    @Input() dynamicFormGroup: FormGroup | any;

    constructor() {}
}
