import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function restrictedBranch(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const branch = control.value;

        if (branch?.accountSignUpRestricted) {
            return { restrictedBranch: true };
        }

        return null;
    };
}
