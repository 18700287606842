import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// COMPONENTS

import { FacilityContactDetailsComponent } from './facility-contact-details/facility-contact-details.component';
import { FacilityContactsComponent } from './facility-contacts.component';

const routes: Routes = [
    {
        path: '',
        title: 'SprintPay | Facility contacts',
        component: FacilityContactsComponent,
        data: { breadcrumb: { alias: 'facility-contacts' } },
    },
    {
        path: 'paymentDetail/:id',
        component: FacilityContactDetailsComponent,
        data: { breadcrumb: { alias: 'facility-detail' } },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class FacilityContactsRoutingModule {}
