import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DetailsFieldComponent } from '../details-section/details-field/details-field.component';
import { DetailsHeaderComponent } from '../details-section/details-header/details-header.component';

export interface Detail {
    label: string;
    value: string;
    show?: boolean;
}

@Component({
    selector: 'app-details-by-section',
    standalone: true,
    imports: [CommonModule, DetailsHeaderComponent, DetailsFieldComponent],
    templateUrl: './details-by-section.component.html',
})
export class DetailsBySectionComponent {
    @Input() values: Detail[];
    @Input() title: string;
    constructor() {}
}
