/**
 * @method sortPaymentTypes()
 * @param (paymentTypes: string[])
 * @description
 */

export type OrderType = 'asc' | 'desc';

export function sortPaymentTypes(paymentTypes: string[], order: OrderType = 'asc'): string[] {
    const paymentTypesSort: string[] = sortPaymentType(paymentTypes, order);
    let otherIndex = -1;
    paymentTypesSort.forEach((type, index) => {
        if (otherIndex === -1 && type.toLowerCase().startsWith('other')) {
            otherIndex = index;
        }
    });
    if (otherIndex !== -1) {
        const other = paymentTypesSort.splice(otherIndex, 1);
        paymentTypesSort.push(other[0]);
    }
    return paymentTypesSort;
}

function sortPaymentType(paymentTypes: string[], order: OrderType = 'asc'): string[] {
    if (order === 'desc') {
        return sortPaymentTypesDesc(paymentTypes);
    }
    return sortPaymentTypesAsc(paymentTypes);
}

function sortPaymentTypesDesc(paymentTypes: string[]): string[] {
    return paymentTypes.sort().reverse();
}

function sortPaymentTypesAsc(paymentTypes: string[]): string[] {
    return paymentTypes.sort();
}
