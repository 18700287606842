<!--START: ACTIVITY LOG SIDENAV TITLE -->
<div mat-dialog-actions class="w-100 d-block">
    <button mat-button class="d-block float-end mx-0" (click)="closeDialog()">
        <mat-icon class="material-symbols-outlined d-block float-end">close</mat-icon>
    </button>
</div>
<p id="title-timeline">Log information</p>
<p id="items-timeline" class="text-uppercase mb-4">
    <strong>{{paymentLogs.length}}&nbsp;item(s) for shipment&nbsp;{{paymentNumber}}</strong>
</p>
<!--END: ACTIVITY LOG SIDENAV TITLE -->
<!--START: ACTIVITY LOG CONTAINER -->
<div mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <!--START: ACTIVITY LOG ITEM -->
            <ul class="timeline mx-auto my-0 position-relative text-start">
                <ng-container *ngFor="let item of paymentLogs; trackBy: trackBy">
                    <li class="event position-relative">
                        <span class="card-title d-block">
                            <div class="dark-text">
                                <mat-icon class="material-symbols-outlined d-block float-start me-2"
                                    >calendar_today</mat-icon
                                >
                                {{item.date}}
                            </div>
                        </span>
                        <ng-container *ngIf="item.approval">
                            <span class="card-text d-block space-pre-wrap mt-2" [innerHTML]="item.approval"></span>
                        </ng-container>
                        <ng-container *ngIf="item.email"
                            ><span class="card-text d-block space-pre-wrap mt-2" [innerHTML]="item.email"></span
                        ></ng-container>
                        <ng-container [ngSwitch]="item.event">
                            <span class="card-text d-block mt-2" *ngSwitchCase="'Voided'">
                                <strong>
                                    <mat-icon
                                        class="material-symbols-outlined d-flex align-items-center float-start me-2 event__voided"
                                        >block</mat-icon
                                    >
                                    <span class="space-pre-wrap" [innerHTML]="item.event"></span>
                                </strong>
                            </span>
                            <span class="card-text d-block mt-2" *ngSwitchCase="'Created'">
                                <strong>
                                    <mat-icon
                                        class="material-symbols-outlined d-flex align-items-center float-start event__created"
                                        >flag</mat-icon
                                    >
                                    <span class="space-pre-wrap" [innerHTML]="item.event"></span>
                                </strong>
                            </span>
                            <span class="card-text d-block mt-2" *ngSwitchCase="'Rejected'">
                                <strong>
                                    <mat-icon
                                        class="material-symbols-outlined d-flex align-items-center float-start event__rejected"
                                        >flag</mat-icon
                                    >
                                    <span class="space-pre-wrap" [innerHTML]="item.event"></span>
                                </strong>
                            </span>
                            <span class="card-text d-block mt-2" *ngSwitchCase="'Approved'">
                                <strong>
                                    <mat-icon
                                        class="material-symbols-outlined d-flex align-items-center float-start event__approved"
                                        >flag</mat-icon
                                    >
                                    <span class="space-pre-wrap" [innerHTML]="item.event"></span>
                                </strong>
                            </span>
                            <span class="card-text d-block mt-2" *ngSwitchCase="'Edited'">
                                <strong>
                                    <mat-icon
                                        class="material-symbols-outlined d-flex align-items-center float-start event__edited"
                                        >flag</mat-icon
                                    >
                                    <span class="space-pre-wrap" [innerHTML]="item.event"></span>
                                </strong>
                            </span>
                            <span class="card-text d-block mt-2" *ngSwitchCase="'Paid'">
                                <strong>
                                    <mat-icon
                                        class="material-symbols-outlined d-flex align-items-center float-start event__paid"
                                        >flag</mat-icon
                                    >
                                    <span class="space-pre-wrap" [innerHTML]="item.event"></span>
                                </strong>
                            </span>
                            <span class="card-text d-block mt-2" *ngSwitchCase="'Added to cart'">
                                <strong>
                                    <mat-icon
                                        class="material-symbols-outlined d-flex align-items-center float-start event__toCart"
                                        >flag</mat-icon
                                    >
                                    <span class="space-pre-wrap" [innerHTML]="item.event"></span>
                                </strong>
                            </span>
                            <span class="card-text d-block mt-2" *ngSwitchDefault>
                                <span class="space-pre-wrap" [innerHTML]="item.event"></span>
                            </span>
                        </ng-container>
                        <span class="card-text d-block space-pre-wrap mt-2"
                            >{{item.reason ? 'Reason: ' + item.reason : ''}}</span
                        >
                        <ng-container *ngIf="item.request">
                            <span
                                class="card-text d-block space-pre-wrap"
                                *ngIf="item.request"
                                [innerHTML]="item.request"
                            ></span>
                        </ng-container>
                        <ng-container *ngIf="item.refundFee"
                            ><span class="card-text d-block space-pre-wrap mt-2" [innerHTML]="item.refundFee"></span
                        ></ng-container>
                        <ng-container *ngIf="item.totalRefundedAmount"
                            ><span
                                class="card-text d-block space-pre-wrap mt-2"
                                [innerHTML]="item.totalRefundedAmount"
                            ></span
                        ></ng-container>
                        <span class="card-text d-block space-pre-wrap mt-2" *ngIf="item.flag">
                            <mat-icon class="material-symbols-outlined d-flex align-items-center float-start icon-flag">
                                flag
                            </mat-icon>
                            <div class="dark-text">{{item.flag}}</div>
                        </span>
                    </li>
                </ng-container>
            </ul>
            <!--END: ACTIVITY LOG ITEM -->
        </div>
    </div>
</div>
<!--END: ACTIVITY LOG CONTAINER -->
