import * as moment from 'moment';
import { Facility } from 'src/app/models/payments/facility.model';
import { FormatPaymentReferencePipe } from 'src/app/pipes/format-payment-reference.pipe';
import { permissionsName } from '../constants';
import { sortPaymentTypes } from '../ordered-data';

export const customerReferenceSchenker: RegExp[] = [
    /^\d{10}$/,
    /^[a-zA-z]{5}\d{9}$/,
    /^[a-zA-z]{7}\d{6}$/,
    /^SH-(\d{9}|\d{10}|\d{11}|\d{12})$/,
];

export const customerReferenceSchenkerData = {
    title: 'File / Shipment / Archive #',
    placeholder: 'Enter your File / Shipment / Archive #',
};

export class Schenker {
    static readonly paymentReferencePipe = new FormatPaymentReferencePipe();

    static readonly userTypeSchenker = {
        requestor: permissionsName.schenkerRequestor,
        approver: permissionsName.schenkerApprover,
        corporate: permissionsName.schenkerUniqueRequestorApprover,
    };

    static readonly paymentTypes = Object.freeze(
        sortPaymentTypes([
            'Breakdown fee',
            'Charter/ULD Bypass Fees',
            'Import Service Fee (ISC)',
            'Pallets/Wood skids',
            'Storage/Demurrage',
            'Other***',
        ])
    );

    /*
     * REJECTED PAYMENTS
     */
    static readonly schenker_ColumnsRejectedPayment: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'awb',
        'customerReference',
        'voucher',
        'amount',
        'location',
        'approvalLevel',
    ];
    static readonly schenker_ColumnsNameRejectedPayment: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'File/Shipment/Archive#',
        'Voucher#',
        'Amount',
        'Location',
        'Status',
    ];
    static readonly schenker_getCompletedPaymentsRejectedPayment = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        voucher: item.externalData ? item.externalData.voucher : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow.location.name : 'N/A',
        approvalLevel:
            item.approvalFlowLevel.approvalLevel.name +
            ' of ' +
            item.approvalFlowLevel.approvalFlow.approvalFlowLevels.length +
            ' levels',
    });
    static readonly schenker_ColumnsSortableRejectedPayment: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'customerReference',
        'voucher',
        'location',
        'amount',
    ];

    /*
     * VOIDED PAYMENTS
     */

    static readonly schenker_ColumnsVoidedPayment: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'awb',
        'customerReference',
        'voucher',
        'amount',
        'location',
        'approvalLevel',
    ];
    static readonly schenker_ColumnsNameVoidedPayment: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'File/Shipment/Archive#',
        'Voucher#',
        'Amount',
        'Location',
        'Status',
    ];
    static readonly schenker_getCompletedPaymentsVoidedPayment = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        voucher: item.externalData ? item.externalData.voucher : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow.location.name : 'N/A',
        approvalLevel:
            item.approvalFlowLevel.approvalLevel.name +
            ' of ' +
            item.approvalFlowLevel.approvalFlow.approvalFlowLevels.length +
            ' levels',
    });
    static readonly schenker_ColumnsSortableVoidedPayment: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'customerReference',
        'voucher',
        'location',
        'amount',
    ];

    /*
     * HISTORY
     */

    static readonly schenker_ColumnsHistory: string[] = [
        'select',
        'linkId',
        'createdDate',
        'facilityName',
        'awb',
        'customerReference',
        'voucher',
        'amount',
        'location',
        'facilityPaymentDeliveryNotificationShort',
    ];
    static readonly schenker_ColumnsNameHistory: string[] = [
        'select',
        'Payment reference #',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'File/Shipment/Archive#',
        'Voucher#',
        'Amount',
        'Location',
        'Delivery type',
    ];
    static readonly schenker_getCompletedPaymentsHistory = (item: any) => ({
        id: item.name ? item.name : 'N/A',
        link: `../paymentDetail/${item.vendorInvoiceNumber ? item.vendorInvoiceNumber : item.id}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        voucher: item.externalData ? item.externalData.voucher : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.location ?? 'N/A',
        paymentConfirmation: item.isPaymentConfirmationAvailable ? item.isPaymentConfirmationAvailable : '',
        externalPaymentConfirmation: item.isExternalPaymentConfirmationAvailable
            ? item.isExternalPaymentConfirmationAvailable
            : '',
        attachments: item.attachments ? item.attachments : null,
        hasDownloadableContent: item.hasDownloadableContent ?? true,
        facilityPaymentDeliveryNotificationShort:
            item.facility && item.facility.facilityPaymentDeliveryNotificationShort
                ? item.facility.facilityPaymentDeliveryNotificationShort
                : 'N/A',
        linkId: Schenker.paymentReferencePipe.transform(item.vendorInvoiceNumber, item.name),
    });
    static readonly schenker_ColumnsSortableHistory: string[] = [
        'createdDate',
        'awb',
        'customerReference',
        'voucher',
        'location',
        'amount',
    ];

    /*
     * PENDING APPROVAL
     */

    static readonly schenker_ColumnsPendingApproval: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'awb',
        'customerReference',
        'voucher',
        'amount',
        'location',
        'approvalLevel',
    ];
    static readonly schenker_ColumnsNamePendingApproval: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'File/Shipment/Archive#',
        'Voucher#',
        'Amount',
        'Location',
        'Status',
    ];
    static readonly schenker_getCompletedPaymentsPendingApproval = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        voucher: item.externalData ? item.externalData.voucher : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.location?.name : 'N/A',
        approvalLevel: item.approvalFlowLevel
            ? item.approvalFlowLevel.approvalLevel?.name +
              ' of ' +
              item.approvalFlowLevel.approvalFlow?.approvalFlowLevels?.length +
              ' levels'
            : 'Added to cart by - ' + item.customer?.name,
        approvalFlowLevel: item.approvalFlowLevel || '',
        approvalLevels: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.approvalFlowLevels : '',
    });
    static readonly schenker_ColumnsSortablePendingApproval: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'customerReference',
        'voucher',
        'location',
        'amount',
        'approvalLevel',
    ];

    /*
     * PENDING APPROVAL HOME COMPONENT
     */

    static readonly schenker_ColumnsPendingApprovalHomeComponent: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'awb',
        'customerReference',
        'voucher',
        'amount',
        'location',
        'approvalLevel',
    ];
    static readonly schenker_ColumnsNamePendingApprovalHomeComponent: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'File/Shipment/Archive#',
        'Voucher#',
        'Amount',
        'Location',
        'Status',
    ];
    static readonly schenker_getCompletedPaymentsPendingApprovalHomeComponent = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../facilityPayments/pendingApproval/paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerReference: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        voucher: item.externalData ? item.externalData.voucher : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        approvalLevels: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.approvalFlowLevels : '',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.location?.name : 'N/A',
        approvalLevel: item.approvalFlowLevel
            ? item.approvalFlowLevel.approvalLevel?.name +
              ' of ' +
              item.approvalFlowLevel.approvalFlow?.approvalFlowLevels?.length +
              ' levels'
            : 'Added to cart by - ' + item.customer?.name,
    });
    static readonly schenker_ColumnsSortablePendingApprovalHomeComponent: string[] = [
        'createdDate',
        'facilityName',
        'awb',
        'customerReference',
        'voucher',
        'location',
        'amount',
        'approvalLevel',
    ];

    /*
     * USERS
     */

    static readonly schenker_ColumnsUsers: string[] = [
        'firstName',
        'lastName',
        'email',
        'homeLocation',
        'approverType',
        'approverLocations',
        'notificationEmail',
        'isMegafileEnabled',
    ];
    static readonly schenker_ColumnsNameUsers: string[] = [
        'First name',
        'Last name',
        'Email address',
        'Home location',
        'Approver type',
        'Locations for approver visibility',
        'Email notifications',
        'Megafile',
    ];
    static readonly schenker_getCompletedPaymentsUsers = (item: any) => ({
        firstName: item.firstName ? item.firstName : 'N/A',
        lastName: item.lastName ? item.lastName : 'N/A',
        email: item.email ? item.email : 'N/A',
        homeLocation: item.homeLocation ? item.homeLocation : 'N/A',
        approverType: item.approverType ? item.approverType : 'N/A',
        approverLocations: item.approverLocations ? item.approverLocations : 'N/A',
        notificationEmail: item.notificationEmail ? item.notificationEmail : 'N/A',
        isMegafileEnabled: item.isMegafileEnabled ? 'Yes' : 'N/A',
    });

    static readonly getPaymentDetailsPaymentSummary = (item: any, facility: Facility, id: string) => [
        {
            label: facility.externalLookup
                ? 'File Number'
                : facility.awbFieldLabel === 'AWB'
                  ? 'RELATED BOL / AWB #'
                  : facility.awbFieldLabel ?? 'N/A',
            value: item.awb ? item.awb : 'N/A',
            show: (!facility.externalLookup && !id) || id,
        },
        { label: 'Paid To', value: item.paidTo ? item.paidTo : 'N/A', show: true },
        {
            label: 'Amount',
            value: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
            show: (!facility.externalLookup && !id) || id,
        },
        { label: 'HAWB/Container No.', value: item.hawb ? item.hawb : 'N/A', show: !facility.externalLookup },
    ];
    static readonly getCustomerReferencePaymentSummary = (item: any, customerReferenceLabel: string = '') => [
        {
            label: customerReferenceLabel ? customerReferenceLabel : customerReferenceSchenkerData.title,
            value: item.customerRef ? item.customerRef : 'N/A',
            show: true,
        },
        { label: 'Payment Notification Email', value: item.email ? item.email : 'N/A', show: true },
        { label: 'Notes', value: item.notes ? item.notes : 'N/A', show: true },
    ];
    static readonly schenker_getCompanyCustomerReferencePaymentSummary: any = (item: any) => ({
        costCenter: item.costCenter || null,
        voucher: item.voucher ? item.voucher : null,
        paymentAmount: item.paymentAmount || null,
        type: item.type ? item.type : null,
        locationName: item.locationName ? item.locationName : null,
    });

    static readonly getPaymentDetailsPaymentHistory = (item: any, facility: Facility, id: string) => [
        {
            label: facility.externalLookup ? 'File Number' : 'Second facility reference #',
            value: item.hawb ? item.hawb : 'N/A',
            show: !facility.externalLookup && !id,
        },
        { label: 'Paid To', value: item.paidTo ? item.paidTo : 'N/A', show: true },
        { label: 'Cargo facility', value: facility.name ? facility.name : 'N/A', show: true },
        { label: 'Amount', value: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A', show: true },
    ];
    static readonly getCustomerReferencePaymentHistory = (item: any, customerReferenceLabel: string = '') => [
        {
            label: customerReferenceLabel ? customerReferenceLabel : customerReferenceSchenkerData.title,
            value: item.customerReference ? item.customerReference : 'N/A',
            show: true,
        },
        {
            label: 'Payment Notification Email',
            value: item.notificationEmail ? item.notificationEmail : 'N/A',
            show: true,
        },
        { label: 'Notes', value: item.notes ? item.notes : 'N/A', show: true },
    ];
    static readonly schenker_getCompanyCustomerReferencePaymentHistory: any = (item: any) => ({
        costCenter: item.costCenter || null,
        voucher: item.voucher ? item.voucher : null,
        paymentAmount: item.paymentAmount || null,
        type: item.type ? item.type : null,
        locationName: item.locationName ? item.locationName : null,
    });

    paymentDetails = [
        {
            label: 'AWB',
            id: 'awb',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Paid To',
            id: 'paidTo',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Amount',
            id: 'amount',
            value: 'N/A',
            type: 'currency',
        },
        {
            label: ' HAWB/Container No. ',
            id: 'hawb',
            value: 'N/A',
            type: 'string',
        },
    ];
    customerReference = [
        {
            label: customerReferenceSchenkerData.title,
            id: 'customerRef',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Payment Notification Email',
            id: 'email',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Notes',
            id: 'notes',
            value: 'N/A',
            type: 'string',
        },
    ];
    schenkerCustomerReference = [
        {
            label: 'Cost Center',
            id: 'costCenter',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Voucher #',
            id: 'voucher',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Payment amount',
            id: 'paymentAmount',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Type',
            id: 'type',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Location',
            id: 'locationName',
            value: 'N/A',
            type: 'string',
        },
    ];
}
