import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private _modals: any[] = [];

    constructor() {}

    /**
     * @method add()
     * @param (modal: any)
     * @description Add modal to array of active modals
     */

    add(modal: any) {
        this._modals.push(modal);
    }

    /**
     * @method add()
     * @param (id: string)
     * @description Remove modal from array of active modals
     */

    remove(id: string) {
        this._modals = this._modals.filter((x) => x.id !== id);
    }

    /**
     * @method add()
     * @param (id: string)
     * @description Open modal specified by id
     */

    open(id: string) {
        let modal: any = this._modals.filter((x) => x.id === id)[0];
        modal.open();
    }

    /**
     * @method add()
     * @param (id: string)
     * @description Close modal specified by id
     */

    close(id: string) {
        let modal: any = this._modals.filter((x) => x.id === id)[0];
        modal.close();
    }
}
