import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { RejectedPaymentRoutingModule } from './rejected-payment-routing.module';

//COMPONENTS

import { RejectedPaymentComponent } from './rejected-payment.component';

//MODULES

import { SharedModule } from '../../../../shared/shared.module';
import { PaymentDetailModule } from './payment-detail/payment-detail.module';

@NgModule({
    declarations: [RejectedPaymentComponent],
    imports: [
        CommonModule,
        RejectedPaymentRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        PaymentDetailModule,
    ],
})
export class RejectedPaymentModule {
    constructor() {}
}
