import { APP_BOOTSTRAP_LISTENER, APP_INITIALIZER, ComponentRef, InjectionToken, Provider } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsConfig } from './types';

export const GOOGLE_ANALYTICS_SETTINGS_TOKEN: InjectionToken<GoogleAnalyticsConfig> = new InjectionToken<
    GoogleAnalyticsConfig | undefined
>('google-analytics-settings', {
    factory: () => undefined,
});

export const GOOGLE_ANALYTICS_INITIALIZER_PROVIDER: Provider = {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: googleAnalyticsInitializer,
    deps: [GOOGLE_ANALYTICS_SETTINGS_TOKEN],
};

export const GOOGLE_ANALYTICS_ROUTER_INITIALIZER_PROVIDER: Provider = {
    provide: APP_BOOTSTRAP_LISTENER,
    multi: true,
    useFactory: googleAnalyticsRouterInitializer,
    deps: [GOOGLE_ANALYTICS_SETTINGS_TOKEN],
};

function gtag(...args: unknown[]): void {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(args);
}

function googleAnalyticsInitializer(config?: GoogleAnalyticsConfig): () => void {
    return () => {
        if (config?.tagId) {
            const s: HTMLScriptElement = document.createElement('script');
            s.async = true;
            s.src = `https://www.googletagmanager.com/gtag/js?id=${config.tagId}`;
            const head: HTMLHeadElement | null = document.querySelector('head');
            head?.appendChild(s);

            gtag('js', new Date());
            gtag('config', config.tagId);
        }
    };
}

function googleAnalyticsRouterInitializer(config?: GoogleAnalyticsConfig): (component: ComponentRef<any>) => void {
    return (component: ComponentRef<any>) => {
        if (config?.tagId) {
            const router = component.injector.get(Router);
            router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    gtag('config', config.tagId, { page_path: event.urlAfterRedirects });
                }
            });
        }
    };
}
