import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SideNavEndService {
    private componentSource = new BehaviorSubject<ComponentPortal<unknown> | null>(null);
    private currentComponent = this.componentSource.asObservable();
    private instanceSource = new BehaviorSubject<unknown | null>(null);
    private currentInstance = this.instanceSource.asObservable();
    private sidenav!: MatSidenav;

    constructor() {}

    setComponent(component: ComponentPortal<unknown> | null) {
        this.setInstance(null);
        this.componentSource.next(component);
    }

    getComponent(): Observable<ComponentPortal<unknown> | null> {
        return this.currentComponent;
    }

    setSidenav(sidenav: MatSidenav): void {
        this.sidenav = sidenav;
    }

    getSidenav(): MatSidenav {
        return this.sidenav;
    }

    setInstance(instance: unknown | null): void {
        this.instanceSource.next(instance);
    }

    getInstance(): Observable<unknown | null> {
        return this.currentInstance;
    }

    open(): void {
        this.sidenav.open();
    }

    close(): void {
        this.componentSource.next(null);
        this.sidenav.close();
    }
}
