<div id="verification_method">
    <span class="title mb-4 d-block" *ngIf="email && phoneNumber">Please select your verification method</span>
    <span class="text mb-4 d-block">{{message}}</span>
    <div class="p-3 d-flex container pe-4 flex-row mb-2 mb-md-4">
        <div [ngClass]="phoneNumber ? ' col-12 col-md-6':'col-12'" class="p-0 p-md-4" *ngIf="email">
            <ng-container *ngIf="email && phoneNumber">
                <mat-checkbox
                    id="email"
                    class="d-block mb-4 fw-normal"
                    data-cy="email-address"
                    [color]="'primary'"
                    (change)="onChange($event, 'email')"
                    [checked]="verificationMethod === 'email'"
                    class="mb-0 type"
                    >Email address
                </mat-checkbox>
            </ng-container>
            <span class="info my-2 d-block">{{email}}</span>
            <span class="message mb-4 d-block"> {{verificationMethodMessage}} </span>
        </div>
        <div class="p-0 p-md-4 col-12 col-md-6" *ngIf="phoneNumber">
            <mat-checkbox
                id="phone"
                class="d-block mb-4 fw-normal"
                data-cy="phone-number"
                [color]="'primary'"
                (change)="onChange($event, 'sms')"
                [checked]="verificationMethod === 'sms'"
                class="mb-0 type"
            >
                Phone number
            </mat-checkbox>
            <span class="info my-2 d-block">{{phoneNumber}}</span>
            <span class="message mb-4 d-block"> We will send an SMS with a code to your phone number on file. </span>
        </div>
    </div>
</div>
