import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CSCreditService } from 'src/app/services/cs-credit.service';
import { HomeService } from 'src/app/services/home.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { SecurityService } from 'src/app/services/utils/security.service';
import { profileComponents } from 'src/app/utils/constants';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit, OnDestroy {
    facilitySecurity: Array<string>;
    openInvoiceSecurity: Array<string>;
    pendingApprovalSecurity: Array<string>;
    rejectedRequestSecurity: Array<string>;
    walletSecurity: Array<string>;
    facilityStatus: any | undefined;
    openInvoiceStatus: any | undefined;
    pendingApprovalStatus: any | undefined;
    rejectedRequestStatus: any | undefined;
    walletStatus: any | undefined;
    isNewUser: boolean;
    showNewPayment: boolean;
    hasCompany: boolean;

    private _subscriptionHomeService: Subscription;
    private _unsubscribe$: Subject<void>;

    constructor(
        private _paymentFluxService: PaymentFluxService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _homeService: HomeService,
        private _securityService: SecurityService,
        private _breadcrumbService: BreadcrumbService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _customerService: CustomerService,
        private _csCreditService: CSCreditService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this._subscriptionHomeService = Subscription.EMPTY;
        this.isNewUser = false;
        this._breadcrumbService.set('@home', 'Home');
        this.showNewPayment = String(this._router.url).indexOf('newPayment') <= 0;
        this.facilitySecurity = [''];
        this.pendingApprovalSecurity = [''];
        this.openInvoiceSecurity = [''];
        this.rejectedRequestSecurity = [''];
        this.walletSecurity = [''];
        this.hasCompany = !!this._customerService.getCompanyName();
        this._paymentFluxService.removeCurrentPayment();
    }

    ngOnInit(): void {
        const userType: string = this._securityService.getUserType();
        if (this._securityService.verifyComponentsSecurity(profileComponents.newPaymentButton)) {
            this.facilitySecurity.push(userType);
        } else {
            this._homeService.setFacilities(true);
        }
        if (this._securityService.verifyComponentsSecurity(profileComponents.pendingApproval)) {
            this.pendingApprovalSecurity.push(userType);
        } else {
            this._homeService.setPendingApproval(true);
        }
        if (this._securityService.verifyComponentsSecurity(profileComponents.invoiceOpen)) {
            this.openInvoiceSecurity.push(userType);
        } else {
            this._homeService.setInvoices(true);
        }
        if (this._securityService.verifyComponentsSecurity(profileComponents.rejectedPayments)) {
            this.rejectedRequestSecurity.push(userType);
        } else {
            this._homeService.setRejected(true);
        }
        if (
            this._securityService.verifyComponentsSecurity(profileComponents.homeSPCredit) ||
            this._securityService.verifyComponentsSecurity(profileComponents.homeCreditHold)
        ) {
            this.walletSecurity.push(userType);
        } else {
            this._homeService.setWallet(true);
        }
        this.subscribeUserIsEmpty();
        this.showNewPayment = this._router.url ? !this._router.url.includes('newPayment') : false;
        if (this._customerService.isCSCreditAvailable()) {
            this.getCSCreditBalance();
        }
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
        this._changeDetectorRef.detach();
        this._subscriptionHomeService.unsubscribe();
    }

    subscribeUserIsEmpty(): void {
        this._ngxSpinnerService.show();
        this._subscriptionHomeService = this._homeService.userIsEmpty().subscribe({
            next: (response) => {
                this.isNewUser = response;
                this._changeDetectorRef.detectChanges();
                this._ngxSpinnerService.hide();
            },
            error: (error) => {
                this._ngxSpinnerService.hide();
            },
        });
    }

    getCSCreditBalance(): void {
        this._csCreditService.setIsProcessing(true);
        this._csCreditService
            .getCSCreditAmountRequest$()
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe({
                next: () => {
                    this._csCreditService.setIsProcessing(false);
                },
                error: (error) => {
                    this._csCreditService.setIsProcessing(false);
                },
            });
    }
}
