import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { UploadFileByPortRoutingModule } from './upload-file-by-port-routing.module';

//COMPONENTS

import { UploadFileByPortComponent } from './upload-file-by-port.component';

//MODULES

import { SharedModule } from '../../../../shared/shared.module';

@NgModule({
    declarations: [UploadFileByPortComponent],
    imports: [
        CommonModule,
        UploadFileByPortRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
    ],
})
export class UploadFileByPortModule {
    constructor() {}
}
