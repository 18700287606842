import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadRequestModel } from 'src/app/pages/admin/facility-payments/upload-request/models/upload-payment-request.model';
import { TokenService } from 'src/app/services/utils/token.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class UploadPaymentRequestService {
    private cartUrl = environment.uris.method.cart;

    constructor(
        private httpClient: HttpClient,
        private tokenService: TokenService
    ) {}

    uploadPaymentRequests(locationId: string, formData: FormData): Observable<UploadRequestModel> {
        const url = this.cartUrl + '/uploadPaymentRequests?locationId=' + locationId;
        let headers = new HttpHeaders();

        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('Authorization', `Bearer ${this.tokenService.getCurrentUser()}`);

        return this.httpClient.post<UploadRequestModel>(url, formData);
    }
}
