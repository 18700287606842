import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { permissionsSection } from '../../../utils/constants';

// COMPONENTS

import { CartComponent } from './cart.component';

const routes: Routes = [
    {
        path: '',
        title: 'SprintPay | Cart',
        canLoad: [NgxPermissionsGuard],
        data: {
            breadcrumb: { alias: 'cart' },
            permissions: {
                only: permissionsSection.cart,
                redirectTo: 'admin/home',
            },
        },
        children: [
            {
                path: '',
                component: CartComponent,
                pathMatch: 'full',
            },
            {
                path: 'newPaymentMethods',
                loadChildren: () =>
                    import('src/app/modules/payment-methods/new-payment-method/new-payment-method.module').then(
                        (m) => m.NewPaymentMethodModule
                    ),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CartRoutingModule {}
