import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {
    FormControl,
    FormControlStatus,
    FormsModule,
    NonNullableFormBuilder,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EmailService } from '@cargos/sprintpay-services';
import { CustomPatterns, CustomValidators } from '@cargos/sprintpay-utils';
import { Observable, Subject, catchError, from, map, of, switchMap, takeUntil } from 'rxjs';
import { ErrorMatcher } from 'src/app/utils/errorMatcher';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { ValidateEmailFormGroupType } from './models/validate-email-form';
import { EmailFormService } from './services/email-form.service';

@Component({
    selector: 'app-validate-email',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, MatButtonModule, MatFormFieldModule],
    providers: [EmailService],
    templateUrl: './validate-email.component.html',
    styleUrls: ['./validate-email.component.scss'],
})
export class ValidateEmailComponent implements OnInit, OnDestroy {
    public emailForm!: ValidateEmailFormGroupType;
    public matcher!: ErrorMatcher;

    private _unsubscribe$: Subject<void>;
    @Output() eventOnChangeValidateEmail = new EventEmitter<any>();

    constructor(
        private _formBuilder: NonNullableFormBuilder,
        private emailServices: EmailService,
        private emailFormService: EmailFormService
    ) {
        this.matcher = new ErrorMatcher();
        this._unsubscribe$ = new Subject<void>();
    }

    get email(): FormControl<string> {
        return this.emailForm.controls.email;
    }

    ngOnInit(): void {
        this._setFromBuilder();
        this.onChanges();
    }

    ngOnDestroy(): void {
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    private _setFromBuilder(): void {
        const emailSaved = this.emailFormService.getEmail();
        this.emailForm = this._formBuilder.group({
            email: [emailSaved, [Validators.required, CustomValidators.regex(new RegExp(CustomPatterns.EMAIL))]],
        });
        this.emailFormService.setEmailForm(this.emailForm);
    }

    validateEmail(): Observable<boolean> {
        return this.emailServices.validateEmail(this.email.value).pipe(
            catchError((error) => {
                return of({ suspended: false, emailDomainBlocked: false });
            }),
            switchMap((statusEmail) => {
                if (statusEmail.suspended || statusEmail.emailDomainBlocked) {
                    return this.isSuspended().pipe(map(() => false));
                }
                return of(true);
            })
        );
    }

    onChanges(): void {
        this.emailForm.statusChanges
            .pipe(
                switchMap((statusChanges: FormControlStatus) => {
                    if (statusChanges === 'VALID') {
                        return this.validateEmail();
                    }
                    return of(true);
                }),
                takeUntil(this._unsubscribe$)
            )
            .subscribe((isEmailValid: boolean) => {
                if (isEmailValid) {
                    this.eventOnChangeValidateEmail.emit({ isValid: isEmailValid, email: this.email.value });
                } else {
                    this.email.setErrors({ error: true });
                    this.eventOnChangeValidateEmail.emit({ isValid: isEmailValid, email: '' });
                }
            });
    }

    isSuspended(): Observable<SweetAlertResult> {
        return from(
            Swal.fire({
                title: 'Oops...',
                icon: 'error',
                text: 'Your account is suspended. Please contact support@cargosprint.com for assistance',
                allowOutsideClick: false,
                showCloseButton: false,
                showConfirmButton: true,
                confirmButtonText: 'Ok',
            })
        );
    }
}
