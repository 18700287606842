<div class="row" style="margin-bottom: 1rem">
    <form (ngSubmit)="confirmCode(confirmCodeForm.value)" [formGroup]="confirmCodeForm">
        <div class="row justify-content-center" style="margin-bottom: 35px">
            <div class="col-11 p-0 px-md-2 d-flex justify-content-center" formArrayName="digits">
                <ng-container *ngFor="let field of getDigitsArrayControl(); let i = index">
                    <input
                        #inputs
                        [maxLength]="1"
                        [formControlName]="i"
                        (keydown)="check(i, field, $event)"
                        [appDisableControl]="disabled"
                        [ngClass]="{'ms-0': i === 0,  'ms-2 ms-md-3': i > 0}"
                        (keyup.enter)="confirmCode(confirmCodeForm.value)"
                    />
                </ng-container>
            </div>
        </div>
        <div class="row justify-content-center">
            <button
                [disabled]="disabled || !isFulfilled"
                type="submit"
                mat-button
                class="col-9 px-0 px-md-4 verifyButton text-uppercase btn__sprintpay btn__sprintpay--green"
            >
                verify
            </button>
        </div>
    </form>
</div>
