<!--START: DYNAMIC TEXTAREA FIELD-->
<form [formGroup]="dynamicFormGroup" class="row">
    <div class="col-1">
        <div class="mb-3">
            <mat-form-field class="d-block w-100" appearance="outline">
                <mat-label>{{field.label}}</mat-label>
                <textarea
                    matInput
                    [id]="field.name"
                    [name]="field.name"
                    [formControlName]="field.name"
                    [placeholder]="field.placeholder || ''"
                    [required]="dynamic_required"
                    [minLength]="dynamic_minlength"
                    [maxLength]="dynamic_maxlength"
                    [pattern]="dynamic_pattern"
                    [errorStateMatcher]="matcher"
                    (mouseover)="showTooltip = true"
                    (mouseleave)="showTooltip = false"
                    autocomplete="off"
                ></textarea>
                <!--START: ERRORS-->
                <mat-error *ngIf="dynamicFormGroup.controls[field.name].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i
                    >{{field.inputValidations?.['required']?.errorMessage || 'This field it\'s required'}}
                </mat-error>
                <mat-error *ngIf="dynamicFormGroup.controls[field.name].hasError('pattern')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i
                    >{{field.inputValidations?.['pattern']?.errorMessage || 'Not the correct pattern'}}
                </mat-error>
                <mat-error *ngIf="dynamicFormGroup.controls[field.name].hasError('minlength')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i
                    >{{field.inputValidations?.['minLength']?.errorMessage || 'Not the correct min length'}}
                </mat-error>
                <mat-error *ngIf="dynamicFormGroup.controls[field.name].hasError('maxlength')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i
                    >{{field.inputValidations?.['maxLength']?.errorMessage || 'Not the correct max length'}}
                </mat-error>
                <!--END: ERRORS-->
            </mat-form-field>
        </div>
    </div>
    <!--START: DYNAMIC TOOLTIP-->
    <ng-container *ngIf="field?.tooltipTitle || field?.tooltipMessage">
        <ng-container *ngIf="showTooltip === true">
            <div class="col-12 col-lg-9">
                <span class="cs__tooltip cs__tooltip--warning">
                    <strong class="d-inline-block mb-3" *ngIf="field?.tooltipTitle">{{field.tooltipTitle}}</strong>
                    <span class="d-block mb-2" *ngIf="field?.tooltipMessage">{{field.tooltipMessage}}</span>
                </span>
            </div>
        </ng-container>
    </ng-container>
    <!--END: DYNAMIC TOOLTIP-->
</form>
<!--END: DYNAMIC TEXTAREA FIELD-->
