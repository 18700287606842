<div id="confirmation-dialog">
    <div class="icon">
        <!-- <mat-icon [fontIcon]="data.icon ?? 'error_outline'"></mat-icon> -->
        <img [src]="'assets/images/ui/'+(data.icon ?? 'warning')+'.svg'" alt="dialog icon" />
    </div>
    <div class="description">
        <h1 class="mb-3 lh-base" [innerHTML]="data.title"></h1>
        <h2 class="lh-base m-0" [innerHTML]="data.description"></h2>
    </div>
    <div class="buttons text-center">
        <button
            mat-stroked-button
            color="primary"
            class="mb-3 mb-md-0 me-md-3 btn-cancel"
            (click)="onCancel()"
            *ngIf="data.cancelText"
        >
            {{data.cancelText}}
        </button>
        <button mat-flat-button color="primary" class="btn-confirm" (click)="onConfirm()">
            {{data.confirmText ?? "CONFIRM"}}
        </button>
    </div>
</div>
