import { Component, OnDestroy, OnInit, Renderer2, SecurityContext } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';
import { environment } from '../../../../../environments/environment';
import { Table } from '../../../../models/ui/table.model';
import { PaymentService } from '../../../../services/payment.service';
import { RestService } from '../../../../services/rest.service';
import { CustomerService } from '../../../../services/utils/customer-handler.service';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';
import { paginatorSize } from '../../../../utils/constants';
import { Sections } from '../../../../utils/sections';

@Component({
    selector: 'app-voided-payments',
    templateUrl: './voided-payments.component.html',
})
export class VoidedPaymentsComponent implements OnInit, OnDestroy {
    noInformation: boolean | undefined;
    noInformationMessage: string;
    amTable!: Table[];
    voidedPayments: any;
    recordsSelected: any[];
    sortableColumns: string[];
    readonly companyName: string;
    readonly downloadSettings: object;
    private readonly _cartUrl: string;
    private _unsubscribe$: Subject<void>;
    private _dynamicColumnsName: [];
    private _dynamicColumns: [];
    private _dynamicFields: any;
    private _typeSort: string;
    private _fieldToSort: string;
    private _isSorting: boolean;
    private _dynamicFileFields: { id: number; name: string }[];

    constructor(
        private _breadcrumbService: BreadcrumbService,
        private _errorHandlerService: ErrorHandlerService,
        private _customerService: CustomerService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _restService: RestService,
        private _matDialog: MatDialog,
        private _renderer: Renderer2,
        private _domSanitizer: DomSanitizer,
        private _paymentService: PaymentService
    ) {
        this._unsubscribe$ = new Subject<void>();
        this._breadcrumbService.set('@voided-payments', 'Voided payment(s)');
        this._breadcrumbService.set('@payment-list', 'Payment list');
        this._dynamicFileFields = [];
        this._dynamicColumns = [];
        this._dynamicColumnsName = [];
        this._dynamicFields = {};
        this._cartUrl = environment.uris.method.cart;
        this.companyName = this._customerService.getCompanyName();
        this.noInformation = undefined;
        this.noInformationMessage = '';
        this.recordsSelected = [];
        this.downloadSettings = {
            flux: 'GHA',
            paymentName: 'voided payments',
            kind: [
                {
                    type: 'xlsx',
                    default: true,
                },
            ],
        };
        this._typeSort = '';
        this._fieldToSort = '';
        this.sortableColumns = [];
        this._isSorting = false;
    }

    ngOnInit(): void {
        this.getAllFields();
        this.getVoidedPayments();
    }

    ngOnDestroy(): void {
        this._matDialog.closeAll();
        this._unsubscribe$.next();
        this._unsubscribe$.complete();
    }

    /**
     * @method getVoidedPayments()
     * @param (item?: any)
     * @description Generates the URL based on customer approval level and starting level; also performs the first call to retrieve the size of the array
     */

    getVoidedPayments(item?: any): void {
        let pageSize: number;
        let currentSize: number;
        if (item && item['paginator'] !== undefined) {
            pageSize = item.paginator.pageSize;
            currentSize = item.paginator.currentPage;
        } else {
            pageSize = item !== undefined ? item.pageSize : paginatorSize[0];
            currentSize = item !== undefined ? item.currentPage : 0;
        }
        const shortUrl: string = `${this._cartUrl}/cancelledPaymentRequestsByLocation`;

        let longUrl: string = `${shortUrl}/?page=${currentSize}&size=${pageSize}`;
        if (this._typeSort && this._fieldToSort) {
            longUrl = longUrl + `&sort=${this._fieldToSort}-${this._typeSort}`;
        }
        longUrl = `${longUrl}&date=${new Date().valueOf()}`;

        this._ngxSpinnerService.show();
        this._getCompleteData(longUrl);
    }

    /**
     * @method search()
     * @param (filter: any)
     * @description
     */

    search(filter: any): void {
        if (filter.value) {
            const pageSize: number =
                filter.paginator.pageSize !== undefined ? filter.paginator.pageSize : paginatorSize[0];
            const currentSize: number = filter.paginator.currentPage !== undefined ? filter.paginator.currentPage : 0;
            const longUrl: string = `${this._cartUrl}/searchCancelledPaymentRequests/?searchTerms=${
                filter.value
            }&page=${currentSize}&size=${pageSize}&date=${new Date().valueOf()}`;

            this._ngxSpinnerService.show();
            this._getCompleteData(longUrl, 'search');
        } else {
            this.getVoidedPayments(filter);
        }
    }

    /**
     * @method ()
     * @param (event: any)
     * @description Order by table column on the table
     */

    sort(event: any): void {
        let typeToSort: any = event.direction;
        if (event.typeSort == '') {
            this._typeSort = '';
            this._fieldToSort = '';
        } else {
            this._typeSort = typeToSort.toUpperCase();
            this._fieldToSort = event.field;
        }

        this._isSorting = true;
        this.getVoidedPayments();
    }

    /**
     * @method _getCompleteData()
     * @param (url: string)
     * @description Call the APU and return the data
     */

    private _getCompleteData(url: string, type?: string): void {
        this._restService
            .get(url, {})
            .then((result: any): void => {
                let companyNameColumns: any = {};
                let companyNameColumnsName: any = {};
                const dynamicCompany: boolean = this._customerService.isDynamicCompany();

                Object.keys(Sections.voidedPaymentSection).forEach((item: any, index: any): void => {
                    if (
                        item === `${this.companyName}_ColumnsVoidedPayment` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsVoidedPayment')
                    ) {
                        companyNameColumns = JSON.parse(
                            JSON.stringify(Object.values(Sections.voidedPaymentSection)[index])
                        );
                        if (!!dynamicCompany && this._dynamicColumns) {
                            this._dynamicColumns.map((item: any): void => {
                                companyNameColumns.splice(7, 0, item);
                            });
                        }
                    } else if (
                        item === `${this.companyName}_ColumnsNameVoidedPayment` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsNameVoidedPayment')
                    ) {
                        companyNameColumnsName = JSON.parse(
                            JSON.stringify(Object.values(Sections.voidedPaymentSection)[index])
                        );
                        if (!!dynamicCompany && this._dynamicColumnsName) {
                            this._dynamicColumnsName.map((item: any): void => {
                                companyNameColumnsName.splice(7, 0, item);
                            });
                            for (let i: number = 0; i < companyNameColumns.length; i++) {
                                if (
                                    companyNameColumns.indexOf(companyNameColumns[i]) !==
                                    companyNameColumns.lastIndexOf(companyNameColumns[i])
                                ) {
                                    companyNameColumnsName[companyNameColumns.indexOf(companyNameColumns[i])] =
                                        companyNameColumnsName[companyNameColumns.lastIndexOf(companyNameColumns[i])];
                                }
                            }
                        }
                    } else if (
                        item === `${this.companyName}_getCompletedPaymentsVoidedPayment` ||
                        (!!dynamicCompany && item === 'Dynamic_getCompletedPaymentsVoidedPayment')
                    ) {
                        this.voidedPayments =
                            result.content.length > 0
                                ? result.content.map((elements: any) => {
                                      return Object.values(Sections.voidedPaymentSection)[index](elements);
                                  })
                                : [];
                        if (!!dynamicCompany && this._dynamicColumns) {
                            let encounteredIndices: any = {};
                            for (let i: number = 0; i < companyNameColumns.length; i++) {
                                if (encounteredIndices[companyNameColumns[i]]) {
                                    companyNameColumns.splice(i, 1);
                                    companyNameColumnsName.splice(i, 1);
                                } else encounteredIndices[companyNameColumns[i]] = 1;
                            }

                            this.voidedPayments?.map((item: any): void => {
                                this._dynamicColumns.map((column: any): void => {
                                    column = column === 'facilityName' ? '' : column;
                                    column = column === 'awb' ? '' : column;
                                    column = column === 'hawb' ? '' : column;
                                    column = column === 'location' ? '' : column;
                                    column = column === 'customerRef' ? '' : column;
                                    column = column === 'amount' ? '' : column;
                                    column = column === 'nameFiles' ? '' : column;

                                    if (
                                        item.dynamicValues.filter(
                                            (values: any): boolean => values.dynamicField.name === column
                                        ).length === 0
                                    ) {
                                        item[column] = 'N/A';
                                    } else {
                                        item[column] = item.dynamicValues
                                            .filter((values: any): boolean => values.dynamicField.name === column)
                                            .map((item: any) => {
                                                const fileField: { id: number; name: string }[] =
                                                    this._paymentService.verifyIfDynamicFieldisFile(
                                                        item.dynamicField.id,
                                                        this._dynamicFileFields
                                                    );
                                                if (fileField.length) {
                                                    const filePath = item.value.split('/');
                                                    item.value = filePath[filePath.length - 1];
                                                }
                                                return item.value !== '' &&
                                                    item.value !== null &&
                                                    item.value !== undefined
                                                    ? item.value
                                                    : 'N/A';
                                            });
                                    }
                                });
                            });
                        }
                    } else if (
                        item === `${this.companyName}_ColumnsSortableVoidedPayment` ||
                        (!!dynamicCompany && item === 'Dynamic_ColumnsSortableVoidedPayment')
                    ) {
                        this.sortableColumns = JSON.parse(
                            JSON.stringify(Object.values(Sections.voidedPaymentSection)[index])
                        );
                    }
                });

                this.noInformation = type === 'search' ? false : !(this.voidedPayments.length > 0);
                this._getVoidedPaymentsTableRender(
                    this.voidedPayments,
                    companyNameColumns,
                    companyNameColumnsName,
                    result.totalElements
                );

                if (this._isSorting) {
                    this._isSorting = false;
                }
            })
            .catch((error: any): void => {
                if (this.voidedPayments !== undefined) {
                    this.noInformationMessage = error?.error?.errors ? error.error.errors : '';
                }
                if (this._isSorting) {
                    this._isSorting = false;
                } else {
                    this.noInformation = true;
                }
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method _getVoidedPaymentsTableRender()
     * @param (source: any)
     * @param (columns: any)
     * @param (columnsName: any)
     * @param (totalElements: number)
     * @description Render the table using the data that we retrieve from the URL
     */

    private _getVoidedPaymentsTableRender(source: any, columns: any, columnsName: any, totalElements: number): void {
        this.amTable = [
            new Table({
                sorting: true,
                pagination: true,
                filter: true,
                dataSource: source,
                displayedColumns: columns,
                displayedColumnsName: columnsName,
                paginationSizes: paginatorSize,
                totalElements: totalElements,
            }),
        ];
        this._ngxSpinnerService.hide();
    }

    /**
     * @method openDialog()
     * @param (templateRef: any)
     * @description Opens the dialog in this case menu right sidebar for the activity log
     */

    openDialog(templateRef: any): void {
        this._matDialog.open(templateRef, {
            id: 'download',
            disableClose: true,
        });
    }

    /**
     * @method itemsSelected()
     * @param (items: any)
     * @description Get the items selected IDs of the items
     */

    itemsSelected(items: any): void {
        this.recordsSelected = items;
        if (items) {
            items
                .map((item: any) => {
                    return item.id;
                })
                .join(',');
        }
    }

    /**
     * @method downloadRecords()
     * @param (event: any)
     * @description Download records from range date
     */

    downloadRecords(event: any): void {
        let ids: string = '';
        let dates: string = '';
        if (event) {
            ids = this.getIds();
            dates = event.from && event.to ? '&startDate=' + event.from + '&endDate=' + event.to : '';
            this.downloadPaymentRequest(dates ? dates : ids);
        }
    }

    /**
     * @method getIds()
     * @description Get the IDs of the items
     */

    getIds(): string {
        let items: string = '';
        if (this.recordsSelected && this.recordsSelected.length > 0) {
            items = '&paymentsRequestIds=';
            items += this.recordsSelected
                .map((item: any) => {
                    return item.id;
                })
                .join(',');
        }
        return items;
    }

    /**
     * @method getOpenInvoiceFile()
     * @param (items: any)
     * @description Get the open invoices CSV downloaded
     */

    downloadPaymentRequest(items: any): void {
        this._ngxSpinnerService.show();
        this._restService
            .getFiles(this._cartUrl + '/downloadPaymentRequest?paymentRequestType=VOIDED' + items, {})
            .then((result: any): void => {
                const now: Date = new Date();
                const fileName: string = `voidedRequest-${now.getDate()}-${now.getMonth()}-${now.getFullYear()}`;
                const $link = this._renderer.createElement('a');
                const file: Blob = new Blob([result], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                const url: any = this._domSanitizer.sanitize(
                    SecurityContext.URL,
                    this._domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file))
                );
                this._renderer.setAttribute($link, 'href', url);
                this._renderer.setAttribute($link, 'download', fileName);
                $link.click();
            })
            .catch((err: any): void => {
                this._ngxSpinnerService.hide();
                Swal.fire({
                    html: `${this._errorHandlerService.errorMsg(
                        JSON.parse(new TextDecoder('utf-8').decode(err.error as ArrayBuffer))
                    )}`,
                    icon: 'error',
                    showConfirmButton: false,
                    showCancelButton: true,
                    cancelButtonText: 'Close',
                    allowOutsideClick: false,
                });
            })
            .finally((): void => {
                this._ngxSpinnerService.hide();
            });
    }

    /**
     * @method getAllFields()
     * @description
     */

    getAllFields(): void {
        this._dynamicColumns = [];
        this._dynamicColumnsName = [];
        this._dynamicFields = [];
        this._paymentService.getDynamicFieldsColumns().subscribe({
            next: (response: any): void => {
                this._dynamicFields = response.fields;
                this._dynamicColumns = response.dynamicColumns.map((item: any) => {
                    return item.column;
                });
                this._dynamicColumnsName = response.dynamicColumns.map((item: any) => {
                    return item.columnName;
                });
                this._dynamicFileFields = this._paymentService.getFileFieldsDynamic([
                    ...response.fields[0],
                    ...response.fields[1],
                    ...response.fields[2],
                ]);
            },
            error: (error: Error): void => {
                throwError(() => error);
            },
        });
    }
}
