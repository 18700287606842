<!--START: ECHECKS LIST -->
<mat-list>
    <!--START: ECHECKS TITLE & CREATE BUTTON -->
    <div mat-subheader>
        <div class="d-flex w-100 mat-subheader__container justify-content-between align-items-center">
            <span class="d-flex align-items-center justify-content-start mat-subheader__title mt-3">eChecks</span>
            <div class="d-flex align-items-center justify-content-end mat-subheader__button">
                <button
                    mat-flat-button
                    color="primary"
                    type="button"
                    class="btn__sprintpay btn__sprintpay--green p-0 mt-0 border-0 rounded-0 text-uppercase text-center"
                    [routerLink]="'../newPaymentMethods/echeck'"
                >
                    New echeck
                </button>
            </div>
        </div>
    </div>
    <!--END: ECHECKS TITLE & CREATE BUTTON -->
    <!--START: LOADER -->
    <div *ngIf="!noEchecks && (!eChecks || (eChecks && eChecks.length < 1))">
        <div class="d-flex justify-content-center">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    <!--END: LOADER -->
    <!--START: ECHECK LIST ITEM -->
    <mat-list-item class="my-3" *ngFor="let eCheck of eChecks; trackBy: trackBy">
        <div class="d-flex justify-content-between flex-row align-items-center w-100">
            <!--START: ECHECK CARD -->
            <div class="d-flex justify-content-start d-flex flex-row align-items-center">
                <img src="assets/images/credit-card-brand/echeck.svg" alt="eCheck logo" class="me-3" />
                <div class="payment__ending" mat-line>
                    <span class="d-block"><strong>eCheck</strong></span>
                    <span class="d-block">Account ending ---- {{eCheck.accountLastFourDigits}}</span>
                </div>
            </div>
            <!--END: ECHECK CARD -->
            <!--START: ECHECK DELETE BUTTON -->
            <div class="d-flex justify-content-end">
                <div mat-line>
                    <button
                        mat-button
                        type="button"
                        class="mat-focus-indicator btn__sprintpay btn__sprintpay--linkDelete text-uppercase p-0 mt-0 border-0 rounded-0"
                        (click)="deleteECheck(eCheck.fullECheck)"
                        color="warn"
                    >
                        Delete
                    </button>
                </div>
            </div>
            <!--END: ECHECK DELETE BUTTON -->
        </div>
    </mat-list-item>
    <!--END: ECHECK LIST ITEM -->
</mat-list>
<!--END: ECHECKS LIST -->
<!--START: NO ECHEKS -->
<ng-container *ngIf="noEchecks">
    <section class="container-fluid">
        <div class="d-flex flex-column h-100 justify-content-center align-items-center mt-5">
            <div class="row">
                <div class="col-12">
                    <img
                        class="mx-auto d-block noPaymentMethod"
                        src="assets/images/home/frequent-facilities.svg"
                        alt="New user illustration"
                    />
                </div>
                <div class="col-12">
                    <h6 class="d-block text-center mt-5 mb-3">
                        Don’t fret! Click “NEW ECHECK” to store your first payment method
                    </h6>
                </div>
            </div>
        </div>
    </section>
</ng-container>
<!--END: NO ECHEKS -->
