import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CellEventHandlerService } from 'src/app/services/utils/cell-event-handler.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-button-icon',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './button-icon.component.html',
    styleUrls: ['./button-icon.component.scss'],
})
export class ButtonIconComponent {
    data!: any;

    constructor(private _cellEventHandlerService: CellEventHandlerService) {}

    onClick(): void {
        this._cellEventHandlerService.fireEvent({
            component: 'ButtonIconComponent',
            value: this.data,
        });
    }
}
