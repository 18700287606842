<app-breadcrumb></app-breadcrumb>
<app-no-information
    *ngIf="noInformation === true"
    [error_message]="noInformationMessage"
    [paymentType]="'history'"
></app-no-information>
<!--START: PAYMENT HISTORY -->
<section id="payment_history" *ngIf="noInformation === false">
    <div id="payment_history__header" class="mb-3 pt-3">
        <!--START: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
        <div class="row">
            <div class="col-12 col-md-6 d-flex align-items-center justify-content-start">
                <h6>Payment History</h6>
            </div>
            <div *ngIf="companyName" class="col-6 justify-content-end d-none d-md-flex align-items-center">
                <div class="align-items-baseline">
                    <button
                        mat-button
                        type="button"
                        color="primary"
                        (click)="openDialog(download)"
                        class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-end download"
                    >
                        Download
                    </button>
                </div>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS FOR NORMAL SCREEN -->
        <!--START: HEADER AND ACTION BUTTONS FOR SMALL SCREEN -->
        <div class="row">
            <div *ngIf="companyName" class="col-12 justify-content-end d-flex d-md-none align-items-center">
                <button
                    mat-button
                    color="primary"
                    type="button"
                    (click)="openDialog(download)"
                    class="btn__sprintpay btn__sprintpay--link p-0 mt-0 border-0 rounded-0 w-100 text-uppercase text-start download"
                >
                    Download
                </button>
            </div>
        </div>
        <!--END: HEADER AND ACTION BUTTONS FOR SMALL SCREEN -->
    </div>
    <!--START: CALL AM TABLE CHILD COMPONENT -->
    <ng-container *ngIf="paymentsCompleted">
        <div id="payment_history__container" class="container-fluid py-4 px-5">
            <div class="row">
                <div class="col-12">
                    <app-am-table
                        (ifItemSelected)="itemsSelected($event)"
                        (filter)="search($event)"
                        (nextPage)="search($event)"
                        (eventOnSort)="sort($event)"
                        [am_actions]="actions"
                        [am_actionRefund]="actionRefund"
                        [am_filter]="amTable[0].filter"
                        [am_sorting]="amTable[0].sorting"
                        [am_pagination]="amTable[0].pagination"
                        [am_columns]="amTable[0].displayedColumns"
                        [am_columnsName]="amTable[0].displayedColumnsName"
                        [am_source]="amTable[0].dataSource"
                        [am_paginationSizes]="amTable[0].paginationSizes"
                        [am_totalRows]="amTable[0].totalElements"
                        [am_sortableColumns]="sortableColumns"
                    ></app-am-table>
                </div>
            </div>
        </div>
    </ng-container>
    <!--END: CALL AM TABLE CHILD COMPONENT -->
</section>
<!--END: PAYMENT HISTORY -->
<!--START: TEMPLATE ACTIVITY DOWNLOAD -->
<ng-template #download>
    <app-download
        [exportable]="downloadSettings"
        [recordSelected]="recordsSelected"
        (downloadRange)="downloadRecords($event)"
    ></app-download>
</ng-template>
<!--END: TEMPLATE ACTIVITY DOWNLOAD -->
<!-- START: PAYMENT REFUND REQUEST -->
<ng-template #refundModal>
    <app-refund-request
        [currentPayment]="selectedPayment"
        [vendorInvoiceNumber]="vendorInvoiceNumber"
        (refundPayment)="refundPayment($event)"
    ></app-refund-request>
</ng-template>
<!-- END: PAYMENT REFUND REQUEST -->
<!-- START: PAYMENT REFUND REQUEST FROM CHOICE -->
<ng-template #refundModalFromChoice>
    <app-refund-request-choice [currentPayment]="selectedPayment" [refundUrl]="refundURL"></app-refund-request-choice>
</ng-template>
<!-- END: PAYMENT REFUND REQUEST FROM CHOICE-->
