import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProfileTypes } from 'src/app/utils/profileTypes';
import { AuthService } from '../../services/utils/auth.service';
import { BannerComponent } from '../banner/banner.component';
import { PortalSelectorComponent } from '../portal-selector/portal-selector.component';

@Component({
    selector: 'app-main',
    templateUrl: './app-main.component.html',
    standalone: true,
    imports: [CommonModule, SharedModule, PortalSelectorComponent, BannerComponent, RouterModule],
})
export class AppMainComponent {
    ProfileTypesEnum = ProfileTypes;
    profileType: string;
    public hasMultiPortalAccess$: Observable<boolean>;
    constructor(private _authService: AuthService) {
        this.profileType = this._authService.getSprintPayProfileType();
    }
}
