import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { VoidedPaymentsRoutingModule } from './voided-payments-routing.module';

//COMPONENTS

import { VoidedPaymentsComponent } from './voided-payments.component';

//MODULES

import { SharedModule } from '../../../../shared/shared.module';
import { PaymentDetailModule } from './payment-detail/payment-detail.module';

@NgModule({
    declarations: [VoidedPaymentsComponent],
    imports: [
        CommonModule,
        VoidedPaymentsRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        PaymentDetailModule,
    ],
})
export class VoidedPaymentsModule {
    constructor() {}
}
