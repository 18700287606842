import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, take } from 'rxjs';
import { ErrorHandlerService } from 'src/app/services/utils/error-handler.service';
import { FileHandlerService } from 'src/app/services/utils/file-handler.service';
import Swal from 'sweetalert2';

@Component({
    standalone: true,
    selector: 'app-file',
    templateUrl: './file.component.html',
    imports: [CommonModule, MatButtonModule],
})
export class FileComponent {
    @Input() set filesPath(filesPath: string[]) {
        this.files = filesPath;
    }
    @Input() set nameFiles(nameFiles: string[]) {
        this.names = nameFiles;
    }

    names!: string[];
    files!: string[];
    constructor(
        private _fileService: FileHandlerService,
        private _errorHandlerService: ErrorHandlerService,
        private _ngxSpinnerService: NgxSpinnerService
    ) {}

    /**
     * @method downloadFile()
     * @param (index: any)
     * @description
     */

    downloadFile(nameFile: string): void {
        let path: string = this.files.find((path: string) => path.includes(nameFile)) ?? '';
        if (path.length) {
            this._ngxSpinnerService.show();
            this._fileService
                .downloadFile(path, nameFile)
                .pipe(
                    take(1),
                    finalize(() => {
                        this._ngxSpinnerService.hide();
                    })
                )
                .subscribe({
                    next: () => {},
                    error: (error: any) => {
                        let template: string = this._errorHandlerService.errorTemplate(error.error);
                        Swal.fire({
                            html: `${template}`,
                            icon: 'error',
                            showConfirmButton: false,
                            showCancelButton: true,
                            cancelButtonText: 'Cancel',
                            allowOutsideClick: false,
                        });
                    },
                });
        }
    }
}
