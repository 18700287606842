import {
    AccountingDetails,
    Cart,
    CartBill,
    PayloadPayPal,
    PaymentMetaData,
    PaymentMethodsType,
    PaymentToken,
} from '@cargos/sprintpay-models';
import { HostedFieldsAccountDetails } from 'braintree-web/hosted-fields';

export enum PaymentMethods {
    CREDIT_CARD = 'credit-card',
    ECHECK = 'echeck',
    CARGOSPRINT_CREDIT = 'cargo-credit',
    PAYPAL = 'paypal',
}

export enum PaymentMethodsCartBill {
    CREDIT_CARD = 'CREDIT_CARD',
    ECHECK = 'ECHECK',
    CARGOSPRINT_CREDIT = 'CARGOSPRINT_CREDIT',
    PAYPAL = 'PAYPAL',
}

export type PaymentMethodsKeys = keyof typeof PaymentMethods;

interface PaymentAccount {
    details?: HostedFieldsAccountDetails;
    accountingDetails?: AccountingDetails;
}

export interface CartSelectedPaymentMethod {
    paymentAccount: any;
    method: PaymentMethodsKeys;
}

export interface EcheckAccountTypeOptions {
    value: string;
    viewValue: string;
}

export interface PaymentTab {
    type: PaymentMethods;
    label: string;
}

export const CARGOSPRINT_ECHECK_ACCOUNT_NUMBER = '0198548840';
export const CARGOSPRINT_ECHECK_ROUTING_NUMBER = '064000017';

// temporary type, will be changed to use @cargos/sprintpay-models
export interface Balance {
    availableCredit?: number;
    creditLimit?: number;
    creditPercentage?: number;
}

export interface PayloadGuest {
    paymentMetadata: PaymentMetaData;
    nonce: string;
    customerReference: string;
    paymentMethodNumber?: string;
    paymentInstitution?: string;
    paymentMethodExpirationMonth?: string;
    paymentMethodExpirationYear?: string;
}

export interface CartPayRequest {
    paymentMethod: {
        method: PaymentMethodsType;
        paymentAccount?: PaymentAccount;
    };
    cartBill: CartBill | Cart;
    payload: PayloadPayPal | PaymentToken | PayloadGuest;
    paymentMetaData?: PaymentMetaData;
}

export enum Currency {
    USD = 'USD',
}
