import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-echeck-card',
    standalone: true,
    imports: [CommonModule, FormsModule],
    templateUrl: './echeck-card.component.html',
})
export class ECheckCardComponent {
    @Input() data!: any;

    get accountEnding(): string {
        const paymentMethod = this.data?.paymentMethod as string;
        return paymentMethod.replace(/\*/g, '');
    }
}
