<div class="awb-item-fee d-flex">
    <div
        class="item-fee-title foreground-disabled-text mb-0 flex-fill d-flex text-wrap me-1"
        [ngClass]="{'bold': labelBold}"
    >
        {{feeLabel}}
    </div>
    <span class="ms-2 item-fee-value text-break flex-grow-1 mb-0 text-end" [ngClass]="{'bold': valueBold}">
        {{ feeValue | currency }}
    </span>
</div>
