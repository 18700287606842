import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// COMPONENTS

import { HomeComponent } from './home.component';

const routes: Routes = [{ path: '', component: HomeComponent, data: { breadcrumb: { alias: 'home' } } }];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HomeRoutingModule {}
