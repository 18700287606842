<form [formGroup]="termsOfUseForm">
    <div class="my-3">
        <mat-checkbox
            class="d-block mb-4 fw-normal"
            data-cy="terms_privacy"
            formControlName="termsOfUse"
            [color]="'primary'"
            required
        >
            I accept CargoSprint’s &nbsp;
            <a
                [href]="'https://cargosprint.com/legal/terms-of-use/sprintpay' | sanitizer: 'resourceUrl'"
                target="_blank"
            >
                Terms of use
            </a>
            &
            <a [href]="'https://cargosprint.com/legal/privacy-policy' | sanitizer: 'resourceUrl'" target="_blank">
                Privacy policy
            </a>
        </mat-checkbox>
    </div>
</form>
