import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CompanyDetails } from 'src/app/models/utils/company';

@Injectable()
export class CartEmailValidationService {
    private _emailValidationResponse: BehaviorSubject<CompanyDetails | null>;

    constructor() {
        this._emailValidationResponse = new BehaviorSubject<CompanyDetails | null>(null);
    }

    /**
     * @method getEmailValidationResponse()
     * @description Return observable emailValidationResponse value
     */

    getEmailValidationResponse(): Observable<CompanyDetails | null> {
        return this._emailValidationResponse.asObservable();
    }

    /**
     * @method setEmailValidationResponse()
     * @param (emailValidationResponse: CompanyDetails | null)
     * @description Set emailValidationResponse value
     */

    setEmailValidationResponse(emailValidationResponse: CompanyDetails | null): void {
        this._emailValidationResponse.next(emailValidationResponse);
    }
}
