import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-details-field',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './details-field.component.html',
})
export class DetailsFieldComponent {
    @Input() label!: string;
    @Input() value!: string | null | undefined;

    constructor() {}
}
