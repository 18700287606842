import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { NewPaymentRoutingModule } from './new-payment-routing.module';

//COMPONENTS

import { BulkPaymentComponent } from './bulk-payment/bulk-payment.component';
import { FacilitySearchComponent } from './facility-search/facility-search.component';
import { NewPaymentComponent } from './new-payment.component';
import { QRScannerComponent } from './qr-scanner/qr-scanner.component';

//MODULES

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SharedModule } from '../../../../shared/shared.module';

//SERVICES

import { DisableControlDirective } from 'src/app/directives/disable-control.directive';
import { CodafacilityModule } from './coda-facility/codafacility.module';
import { UploadFileComponent } from './payment-flux/payment-detail/upload-file/upload-file.component';
import { FacilitySearchService } from './services/facility-search.service';

@NgModule({
    declarations: [NewPaymentComponent, FacilitySearchComponent, BulkPaymentComponent, QRScannerComponent],
    providers: [FacilitySearchService],
    imports: [
        CommonModule,
        NewPaymentRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        ZXingScannerModule,
        UploadFileComponent,
        CodafacilityModule,
        DisableControlDirective,
    ],
})
export class NewPaymentModule {
    constructor() {}
}
