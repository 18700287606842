<!-- START: EMPTY CART -->
<section id="payment-confirmation" class="container-fluid">
    <div class="d-flex flex-column h-100 justify-content-center align-items-center payment-confirmation--height">
        <div class="row justify-content-center">
            <div class="col-12">
                <img
                    class="mx-auto d-block"
                    src="assets/images/credit-card-brand/success.svg"
                    alt="New user illustration"
                />
            </div>
            <div class="col-12 mb-4">
                <h4 class="d-block text-center mt-4 mb-3">Nice work! Your payment was created successfully!</h4>
                <p class="d-block text-center fw-semibold">Now you can download your receipts.</p>
            </div>
            <ng-container class="row" *ngIf="this.requestPaid?.length === 0">
                <div class="col-sm-12 col-lg-6 my-3">
                    <div class="card p-4">
                        <p class="card--title fw-normal d-flex align-items-center">
                            <i class="bi bi-download me-2"></i>Download
                        </p>
                        <p class="card--description mt-3">A copy of this payment(s)</p>
                        <div class="d-flex justify-content-start flex-row align-items-start">
                            <div class="d-flex flex-column flex-lg-row align-items-center w-100">
                                <div class="card--download w-100">
                                    <button
                                        mat-button
                                        type="button"
                                        (click)="getPaymentReceipt()"
                                        class="text-uppercase btn__sprintpay btn__sprintpay--link d-block"
                                    >
                                        <i class="bi bi-file-earmark-pdf me-2"></i>Download Receipt
                                    </button>
                                </div>
                                <div class="card--download w-100">
                                    <button
                                        mat-button
                                        type="button"
                                        (click)="downloadExcel()"
                                        class="text-uppercase btn__sprintpay btn__sprintpay--link d-block"
                                    >
                                        <i class="bi bi-file-earmark-spreadsheet me-2"></i>Download Excel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container class="row" *ngIf=" requestPaid.length > 0">
                <div
                    class="col-sm-12 col-lg-4 my-3"
                    *ngIf="payResult.completedPayments[0].isPaymentConfirmationAvailable"
                >
                    <div class="card p-4">
                        <p class="card--title fw-normal d-flex align-items-center">
                            <i class="material-symbols-outlined me-2">warehouse</i>For the cargo facility
                        </p>
                        <p class="card--description mt-3">
                            A copy of this payment(s) confirmation has already been sent to the cargo facility but you
                            can reprint it here.
                        </p>
                        <div class="card--download">
                            <button
                                *ngIf="requestPaid.length >= 1"
                                (click)="getPaymentConfirmation()"
                                mat-button
                                type="button"
                                class="text-uppercase btn__sprintpay btn__sprintpay--link d-block"
                            >
                                <i class="bi bi-file-earmark-pdf me-2"></i>Download payment confirmation
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-lg-4 my-3" *ngIf="!customer.isPaymentsNotInvoicedEnabled">
                    <div class="card p-4">
                        <p class="card--title fw-normal d-flex align-items-center">
                            <i class="material-symbols-outlined me-2">person</i>For you
                        </p>
                        <p class="card--description mt-3">
                            The document(s) below are for your company use.
                            <span class="fw-bold">Please do NOT send to the cargo facility.</span>
                        </p>
                        <div class="card--download" *ngIf="requestPaid.length >= 1 && paymentMethod === 'cargo-credit'">
                            <button
                                mat-button
                                type="button"
                                (click)="downloadInvoices()"
                                class="text-uppercase btn__sprintpay btn__sprintpay--link d-block"
                            >
                                <i class="bi bi-file-earmark-pdf me-2"></i>Download invoice(s)
                            </button>
                        </div>
                        <div class="card--download" *ngIf="requestPaid.length > 0 && paymentMethod !== 'cargo-credit'">
                            <button
                                mat-button
                                type="button"
                                (click)="getPaymentReceipt()"
                                class="text-uppercase btn__sprintpay btn__sprintpay--link d-block"
                            >
                                <i class="bi bi-file-earmark-pdf me-2"></i>Download Receipt
                            </button>
                        </div>
                        <div class="card--download" *ngIf="invoicesPaid.length > 0 && paymentMethod !== 'cargo-credit'">
                            <button
                                mat-button
                                type="button"
                                (click)="downloadExcel()"
                                class="text-uppercase btn__sprintpay btn__sprintpay--link d-block"
                            >
                                <i class="bi bi-file-earmark-pdf me-2"></i>Download Statement
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>
<!-- END: EMPTY CART -->
