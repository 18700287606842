// ANGULAR MATERIAL
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatCommonModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
// NGX MASK
import { NgxMaskModule } from 'ngx-mask';
// NGX PERMISSIONS
import { NgxPermissionsModule } from 'ngx-permissions';
//STANDALONE COMPONENTS
import { PipesSharedModule } from '../pipes/pipes.module';
// PIPES
import { PortalModule } from '@angular/cdk/portal';
import { VinEdocNextgenPipe } from '../pipes/vin-edoc-nextgen.pipe';
import { ActionsTableComponent } from '../standalone-components/actions-table/actions-table.component';
import { AmTableComponent } from '../standalone-components/am-table/am-table.component';
import { BreadcrumbComponent } from '../standalone-components/breadcrumb/breadcrumb.component';
import { CompanyCustomerReferenceComponent } from '../standalone-components/customer-reference-companies/company-customer-reference/company-customer-reference.component';
import { DhlCustomerReferenceComponent } from '../standalone-components/customer-reference-companies/dhl-customer-reference/dhl-customer-reference.component';
import { EmotransCustomerReferenceComponent } from '../standalone-components/customer-reference-companies/emotrans-customer-reference/emotrans-customer-reference.component';
import { GeodisCustomerReferenceComponent } from '../standalone-components/customer-reference-companies/geodis-customer-reference/geodis-customer-reference.component';
import { KnCustomerReferenceComponent } from '../standalone-components/customer-reference-companies/kn-customer-reference/kn-customer-reference.component';
import { RhenusCustomerReferenceComponent } from '../standalone-components/customer-reference-companies/rhenus-customer-reference/rhenus-customer-reference.component';
import { SchenkerCustomerReferenceComponent } from '../standalone-components/customer-reference-companies/schenker-customer-reference/schenker-customer-reference.component';
import { CustomerReferenceComponent } from '../standalone-components/customer-reference/customer-reference.component';
import { DetailsBySectionComponent } from '../standalone-components/details-by-section/details-by-section.component';
import { DetailsFieldComponent } from '../standalone-components/details-section/details-field/details-field.component';
import { DetailsHeaderComponent } from '../standalone-components/details-section/details-header/details-header.component';
import { DownloadComponent } from '../standalone-components/download/download.component';
import { DynamicContainerComponent } from '../standalone-components/dynamic-fields/dynamic-container/dynamic-container.component';
import { DynamicFormComponent } from '../standalone-components/dynamic-form/dynamic-form.component';
import { FacilityDataComponent } from '../standalone-components/facility-data/facility-data.component';
import { FileHandlerComponent } from '../standalone-components/file-handler/file-handler.component';
import { FilesContainerComponent } from '../standalone-components/files/files-container';
import { InvalidFormComponent } from '../standalone-components/form-error/form-error.component';
import { LoaderComponent } from '../standalone-components/loader/loader.component';
import { NoInformationComponent } from '../standalone-components/no-information/no-information.component';
import { PaymentDetailsComponent } from '../standalone-components/payment-details/payment-details.component';
import { PaymentNotificationsComponent } from '../standalone-components/payment-notifications/payment-notifications.component';
import { PaymentStatusComponent } from '../standalone-components/payment-status/payment-status.component';
import { RefundAmountUpdateComponent } from '../standalone-components/refund-amount-update/refund-amount-update.component';
import { RefundRequestChoiceComponent } from '../standalone-components/refund-request-choice/refund-request-choice.component';
import { RefundRequestComponent } from '../standalone-components/refund-request/refund-request.component';
import { RefundStatusUpdateComponent } from '../standalone-components/refund-status-update/refund-status-update.component';
import { TimelineComponent } from '../standalone-components/timeline/timeline.component';
import { UploadFileComponent } from '../standalone-components/upload-file/upload-file.component';
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        MatCommonModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatStepperModule,
        MatCheckboxModule,
        MatSelectModule,
        MatToolbarModule,
        MatMenuModule,
        MatSidenavModule,
        MatListModule,
        MatDialogModule,
        MatCardModule,
        MatProgressSpinnerModule,
        ScrollingModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatBadgeModule,
        MatChipsModule,
        NgxMaskModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatTabsModule,
        MatRadioModule,
        MatExpansionModule,
        NgxPermissionsModule.forChild(),
        BreadcrumbComponent,
        LoaderComponent,
        NoInformationComponent,
        AmTableComponent,
        DownloadComponent,
        RefundRequestComponent,
        RefundStatusUpdateComponent,
        RefundRequestChoiceComponent,
        TimelineComponent,
        PaymentDetailsComponent,
        CustomerReferenceComponent,
        KnCustomerReferenceComponent,
        DhlCustomerReferenceComponent,
        SchenkerCustomerReferenceComponent,
        GeodisCustomerReferenceComponent,
        EmotransCustomerReferenceComponent,
        RhenusCustomerReferenceComponent,
        PaymentStatusComponent,
        PaymentNotificationsComponent,
        FacilityDataComponent,
        VinEdocNextgenPipe,
        DynamicContainerComponent,
        DynamicFormComponent,
        CompanyCustomerReferenceComponent,
        PipesSharedModule,
        ActionsTableComponent,
        UploadFileComponent,
        FilesContainerComponent,
        FileHandlerComponent,
        InvalidFormComponent,
        PortalModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        RefundAmountUpdateComponent,
        DetailsBySectionComponent,
        DetailsHeaderComponent,
        DetailsFieldComponent,
    ],
    exports: [
        BreadcrumbComponent,
        LoaderComponent,
        AmTableComponent,
        NoInformationComponent,
        TimelineComponent,
        PaymentDetailsComponent,
        CustomerReferenceComponent,
        KnCustomerReferenceComponent,
        DhlCustomerReferenceComponent,
        SchenkerCustomerReferenceComponent,
        GeodisCustomerReferenceComponent,
        EmotransCustomerReferenceComponent,
        RhenusCustomerReferenceComponent,
        PaymentStatusComponent,
        PaymentNotificationsComponent,
        DownloadComponent,
        RefundRequestComponent,
        RefundStatusUpdateComponent,
        RefundRequestChoiceComponent,
        VinEdocNextgenPipe,
        MatCommonModule,
        MatIconModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatStepperModule,
        MatCheckboxModule,
        MatSelectModule,
        MatToolbarModule,
        MatMenuModule,
        MatSidenavModule,
        MatListModule,
        MatDialogModule,
        MatCardModule,
        MatProgressSpinnerModule,
        ScrollingModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatBadgeModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatTabsModule,
        MatRadioModule,
        MatExpansionModule,
        NgxMaskModule,
        NgxPermissionsModule,
        FacilityDataComponent,
        DynamicContainerComponent,
        DynamicFormComponent,
        CompanyCustomerReferenceComponent,
        PipesSharedModule,
        ActionsTableComponent,
        UploadFileComponent,
        FilesContainerComponent,
        FileHandlerComponent,
        InvalidFormComponent,
        PortalModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        RefundAmountUpdateComponent,
        DetailsBySectionComponent,
        DetailsHeaderComponent,
        DetailsFieldComponent,
    ],
})
export class SharedModule {}
