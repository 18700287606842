<div id="sidenav">
    <!-- START: MENU BRAND -->
    <app-logo-by-brand (click)="goToHomePage()"></app-logo-by-brand>

    <!-- END: MENU BRAND -->
    <!-- START: SWITCH PROFILE -->
    <div class="admin__toolbar--switch-profile_mobile">
        <app-portal-selector></app-portal-selector>
    </div>

    <div class="admin__toolbar--branch-selector_mobile p-3 pt-1" *ngIf="branchSelector && mobileQuery.matches">
        <app-branch-selector></app-branch-selector>
    </div>
    <!-- END: SWITCH PROFILE -->
    <!-- START: MENU OPTIONS -->
    <mat-list class="p-0">
        <ng-container *ngFor="let item of navItems; trackBy: trackBy">
            <!-- START: TEMPLATE FOR ACTION SECTION -->
            <ng-container *ngIf="item.type === sidenavOptionTypes.SECTION_ACTION">
                <ng-container *ngxPermissionsOnly="[item.id]">
                    <span
                        id="{{prepareIdString(item.label)}}"
                        class="fw-normal px-3 py-2 d-flex flex-row align-items-center list-section-header"
                        data-cy="navbar_left"
                        [class.list-section-header__action]="item.type === sidenavOptionTypes.SECTION_ACTION"
                        [class.active]="item.route && getRoute.includes(item.route)"
                        (click)="navigate(item.route)"
                    >
                        <mat-icon mat-list-icon class="me-3 material-symbols-outlined">{{item.icon}}</mat-icon>
                        {{item.label}}
                    </span>
                </ng-container>
            </ng-container>
            <!-- END: TEMPLATE FOR ACTION SECTION -->
            <!-- START: TEMPLATE FOR SECTION -->
            <ng-container *ngIf="item.type === sidenavOptionTypes.SECTION">
                <ng-container *ngxPermissionsOnly="[item.id]">
                    <ng-container *ngIf="item.expandable; else noExpansionContent">
                        <mat-expansion-panel class="mat-elevation-z0">
                            <mat-expansion-panel-header class="px-3 py-2">
                                <mat-panel-title>
                                    <span class="fw-normal d-flex flex-row align-items-center list-section-header">
                                        {{item.label}}
                                    </span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ng-container
                                [ngTemplateOutlet]="subSections"
                                [ngTemplateOutletContext]="{item:item}"
                            ></ng-container>
                        </mat-expansion-panel>
                    </ng-container>
                    <ng-template #noExpansionContent>
                        <span class="fw-normal px-3 py-2 d-flex flex-row align-items-center list-section-header">
                            {{item.label}}
                        </span>
                        <ng-container
                            [ngTemplateOutlet]="subSections"
                            [ngTemplateOutletContext]="{item:item}"
                        ></ng-container>
                    </ng-template>
                </ng-container>
            </ng-container>
            <!-- END: TEMPLATE FOR SECTION -->
            <!-- START: TEMPLATE FOR DIVIDER -->
            <ng-container *ngIf="item.type === sidenavOptionTypes.DIVIDER">
                <mat-divider class="divider"></mat-divider>
            </ng-container>
            <!-- END: TEMPLATE FOR DIVIDER -->
            <!-- START: TEMPLATE FOR HELP DIALOG ACTION -->
            <ng-container *ngIf="item.type === sidenavOptionTypes.HELP">
                <mat-list-item id="{{prepareIdString(item.label)}}" (click)="showHelpAlert()" data-cy="navbar_left">
                    <div mat-line class="fw-normal d-flex flex-row align-items-center list-section-header">
                        <mat-icon mat-list-icon class="me-3 p-0 material-symbols-outlined">{{item.icon}}</mat-icon>
                        {{item.label}}
                    </div>
                </mat-list-item>
            </ng-container>
            <!-- END: TEMPLATE FOR HELP DIALOG ACTION -->
            <!-- START: TEMPLATE FOR LOGOUT ACTION -->
            <ng-container *ngIf="item.type === sidenavOptionTypes.LOGOUT">
                <ng-container *ngxPermissionsExcept="'GUEST'">
                    <mat-list-item id="{{prepareIdString(item.label)}}" (click)="logout()" data-cy="navbar_left">
                        <div mat-line class="fw-normal d-flex flex-row align-items-center list-section-header">
                            <mat-icon mat-list-icon class="me-3 p-0 material-symbols-outlined">{{item.icon}}</mat-icon>
                            {{item.label}}
                        </div>
                    </mat-list-item>
                </ng-container>
            </ng-container>
            <!-- END: TEMPLATE FOR LOGOUT ACTION -->
            <!-- START: TEMPLATE FOR LOGIN ACTION -->
            <ng-container *ngIf="item.type === sidenavOptionTypes.LOGIN">
                <ng-container *ngxPermissionsOnly="'GUEST'">
                    <mat-list-item id="{{prepareIdString(item.label)}}" (click)="signIn()" data-cy="navbar_left">
                        <div mat-line class="fw-normal d-flex flex-row align-items-center list-section-header">
                            <mat-icon mat-list-icon class="me-3 p-0 material-symbols-outlined">{{item.icon}}</mat-icon>
                            {{item.label}}
                        </div>
                    </mat-list-item>
                </ng-container>
            </ng-container>
            <!-- END: TEMPLATE FOR LOGIN ACTION -->
        </ng-container>
    </mat-list>
    <!-- END: MENU OPTIONS -->

    <!-- START: TEMPLATE FOR SUBSECTIONS -->
    <ng-template #subSections let-item="item">
        <ng-container *ngFor="let child of item.children">
            <ng-container *ngxPermissionsOnly="[child.id]">
                <mat-list-item
                    id="{{prepareIdString(child.label)}}"
                    [class.active]="child.route && getRoute.includes(child.route)"
                    data-cy="navbar_left"
                    (click)="navigate(child.route)"
                >
                    <div mat-line class="fw-normal d-flex flex-row align-items-center list-section-header">
                        <mat-icon mat-list-icon class="me-3 material-symbols-outlined">{{child.icon}}</mat-icon>
                        {{child.label}}
                    </div>
                </mat-list-item>
            </ng-container>
        </ng-container>
    </ng-template>
    <!-- END: TEMPLATE FOR SUBSECTIONS -->
</div>
