import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { InvoicesRoutingModule } from './invoices-routing.module';

//COMPONENTS

import { InvoicesComponent } from './invoices.component';

//MODULES

import { SharedModule } from '../../../shared/shared.module';
import { OpenModule } from './open/open.module';
import { PaidModule } from './paid/paid.module';

@NgModule({
    declarations: [InvoicesComponent],
    imports: [
        CommonModule,
        InvoicesRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        OpenModule,
        PaidModule,
    ],
})
export class InvoicesModule {
    constructor() {}
}
