import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// COMPONENTS

import { UploadFileByPortComponent } from './upload-file-by-port.component';

const routes: Routes = [
    {
        path: '',
        title: 'SprintPay | Upload file by port',
        component: UploadFileByPortComponent,
        data: {
            breadcrumb: { alias: 'upload-file-by-port' },
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UploadFileByPortRoutingModule {}
