import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-payment-notifications',
    standalone: true,
    templateUrl: './payment-notifications.component.html',
    imports: [CommonModule, MatIconModule],
})
export class PaymentNotificationsComponent {
    @Input() section: any;

    constructor() {}
}
