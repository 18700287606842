import { Injectable } from '@angular/core';
import { Observable, combineLatest, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestService } from 'src/app/services/rest.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { environment } from 'src/environments/environment';
import { DynamicFieldDTO } from '../models/ui/dynamic-field-common.model';

export interface ColumnsFormat {
    column: any;
    columnName: any;
}

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    private readonly _paymentsUrl: string;

    constructor(
        private _restService: RestService,
        private _customerService: CustomerService
    ) {
        this._paymentsUrl = environment.uris.method.payments;
    }

    /**
     * @method getFormFields()
     * @param (section: string)
     * @param (companyId: string)
     * @description
     * @return
     */

    getFormFields(section: string): Promise<DynamicFieldDTO[]> {
        const apiUrl: string = `${this._paymentsUrl}/company/dynamic-fields?section=${section}`;
        return this._restService.get<DynamicFieldDTO[]>(apiUrl, {});
    }

    /**
     * @method _getFormattedFields()
     * @param (section: string)
     * @param (companyId: string)
     * @description
     * @return
     */

    private _getFormattedFields(section: string): Observable<any> {
        const apiUrl: string = `${this._paymentsUrl}/company/dynamic-fields?section=${section}`;
        return from(this._restService.get(apiUrl, {})).pipe(
            map((result: any) => {
                return result.map((field: any) => {
                    const validations = field.validations
                        .filter((validation: any): boolean => validation.name !== 'tooltip')
                        .reduce((acc: any, item: any) => {
                            acc[item.name] = {
                                value: item.value === 'true' ? true : item.value,
                                errorMessage: item.message,
                            };
                            return acc;
                        }, {});
                    field['type'] = 'text';
                    field['disabled'] = field.isDisabled;
                    field['tooltip'] = field.validations.find((item: any): boolean => item.name === 'tooltip');
                    field['validations'] = validations;
                    field['isReplacement'] = field.isReplacement;
                    return field;
                });
            })
        );
    }

    /**
     * @method getDynamicFieldsColumns()
     * @description
     * @return
     */

    getDynamicFieldsColumns() {
        return combineLatest([
            this._getFormattedFields('0'),
            this._getFormattedFields('1'),
            this._getFormattedFields('2'),
        ]).pipe(
            map(([company, details, reference]) => {
                const fields: any = {};
                let dynamicColumns: Array<any> = [];
                let fieldsReplaced: Array<any> = [];
                fields[0] = company;
                fields[1] = details;
                fields[2] = reference;
                dynamicColumns.push(
                    ...company
                        .filter((item: any): boolean => {
                            return item.isHidden === false;
                        })
                        .map((field: any): ColumnsFormat => {
                            return { columnName: field.label, column: field.name };
                        })
                );
                dynamicColumns.push(
                    ...details
                        .filter((item: any): boolean => {
                            return item.isHidden === false;
                        })
                        .map((field: any): ColumnsFormat => {
                            return { columnName: field.label, column: field.name };
                        })
                );
                dynamicColumns.push(
                    ...reference
                        .filter((item: any): boolean => {
                            return item.isHidden === false;
                        })
                        .map((field: any): ColumnsFormat => {
                            return { columnName: field.label, column: field.name };
                        })
                );
                if (Object.values(fields).length > 0) {
                    fieldsReplaced = Object.values(fields)
                        .flat()
                        .filter((item: any) => !!item.isReplacement)
                        .map((item: any) => {
                            return { name: item.name, label: item.label };
                        });
                }
                return { fields, dynamicColumns, fieldsReplaced };
            })
        );
    }

    getAllDynamicFieldsWithReplacement(): Observable<{ name: string; label: string }[]> {
        return this.getDynamicFieldsColumns().pipe(
            map((fields) => {
                return fields.fieldsReplaced;
            })
        );
    }

    /**
     * @method getHeaderDinamics()
     * @description Function that returns the dynamic and replaceable headers
     */

    getHeaderdinamicAndReplaceable(keyHeaders: string[], staticHeaders: string[], dinamicFields: any): string[] {
        dinamicFields.map((field: { name: string; label: string }) => {
            const index = keyHeaders.findIndex((value) => value === field.name);
            if (index > 0) {
                staticHeaders[index] = field.label;
            }
        });
        return staticHeaders;
    }

    /**
     * @method getLabelsdinamicAndReplaceable()
     * @description Review if dynamic fields exits and if they do, we review if we have any isReplaced field
     */

    getLabelsdinamicAndReplaceable(staticNames: string[], dinamicFields: any) {
        let dynamicLabels: any = {};
        Object.values(dinamicFields).map((item: any) => {
            if (staticNames.indexOf(item.name) !== -1) {
                dynamicLabels[`${item.name}`] = item.label;
            }
        });
        return dynamicLabels;
    }

    /**
     * @method getFileFieldsDynamic()
     * @param fields
     * @description Review if dynamic fields has file input
     */
    getFileFieldsDynamic(fields: DynamicFieldDTO[]): { id: number; name: string }[] {
        return fields
            .filter((field: DynamicFieldDTO) => field.fieldType === 'file' && !field.isReplacement && !field.isHidden)
            .map((field: DynamicFieldDTO) => {
                return { id: field.id, name: field.name };
            });
    }

    /**
     * @method verifyIfDynamicFieldisFile()
     * @param id
     * @param dynamicFileFields
     * @description Review if dynamic fields is a dynamic file field
     */
    verifyIfDynamicFieldisFile(
        id: number,
        dynamicFileFields: { id: number; name: string }[]
    ): { id: number; name: string }[] {
        return dynamicFileFields.filter((field: { id: number; name: string }) => id === field.id);
    }

    filterSource(source: any): any {
        const key = this.findKeyElement(source);
        if (!key) {
            return source;
        }
        let previusValue: string = '';
        return source.map((item: any) => {
            if (item[key].includes('VIN')) {
                if (previusValue !== item[key]) {
                    previusValue = item[key];
                    return item;
                } else {
                    item[key] = '';
                }
            }
            return item;
        });
    }

    findKeyElement(source: any): string {
        const key = source.map((item: any) =>
            Object.entries(item)
                .map(([key, value]) => {
                    if (typeof value === 'string') {
                        let valueAsString = value as string;
                        if (valueAsString?.toLowerCase()?.includes('vin')) {
                            return key;
                        }
                    }
                    return '';
                })
                .find((element: string) => element !== '')
        );
        return key.find((element) => !!element);
    }
}
