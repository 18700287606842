import { Injectable } from '@angular/core';
import { PaymentResponse } from '@cargos/sprintpay-models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PaymentConfirmationService {
    private _paymentConfirmation: BehaviorSubject<boolean>;
    private _paymentResponse: BehaviorSubject<PaymentResponse | null>;

    constructor() {
        this._paymentConfirmation = new BehaviorSubject<boolean>(false);
        this._paymentResponse = new BehaviorSubject<PaymentResponse | null>(null);
    }

    /**
     * @method instant_payment_confirmation()
     * @description Return current paymentConfirmation value
     */

    get instant_payment_confirmation(): boolean {
        return this._paymentConfirmation.value;
    }

    /**
     * @method getPaymentConfirmation()
     * @description Return observable paymentConfirmation value
     */

    getPaymentConfirmation(): Observable<boolean> {
        return this._paymentConfirmation.asObservable();
    }

    /**
     * @method setPaymentConfirmation()
     * @param (paymentConfirmation: boolean)
     * @description Set paymentConfirmation value
     */

    setPaymentConfirmation(paymentConfirmation: boolean): void {
        this._paymentConfirmation.next(paymentConfirmation);
    }

    /**
     * @method instant_payment_response()
     * @description Return current paymentResponse value
     */

    get instant_payment_response(): PaymentResponse | null {
        return this._paymentResponse.value;
    }

    /**
     * @method getPaymentResponse()
     * @description Return observable paymentResponse value
     */

    getPaymentResponse(): Observable<PaymentResponse | null> {
        return this._paymentResponse.asObservable();
    }

    /**
     * @method setPaymentResponse()
     * @param (paymentResponse: boolean)
     * @description Set paymentResponse value
     */

    setPaymentResponse(paymentResponse: PaymentResponse | null): void {
        this._paymentResponse.next(paymentResponse);
    }
}
