import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { NgxMaskModule } from 'ngx-mask';
import { ErrorMatcher } from 'src/app/utils/errorMatcher';
import { ImageByBrandComponent } from '../image-by-brand/image-by-brand.component';

export interface RefundStatusUpdateType {
    title: string;
    icon: string;
    confirmButtonText: string;
    cancelButtonText: string;
    reasons?: string[];
    reasonSelectLabel?: string;
    reasonSelectPlaceholder?: string;
    reasonSelectErrorRequired?: string;
    customNotesLabel?: string;
    customNotesPlaceholder?: string;
}

@Component({
    selector: 'app-refund-status-update',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        MatRadioModule,
        MatSelectModule,
        ImageByBrandComponent,
        NgxMaskModule,
    ],
    templateUrl: './refund-status-update.component.html',
})
export class RefundStatusUpdateComponent implements OnInit {
    @Input() popupData!: RefundStatusUpdateType;
    @Output() handleStatusUpdate: EventEmitter<any> = new EventEmitter();

    matcher: ErrorMatcher;
    refundForm!: FormGroup;

    constructor(
        private _matDialog: MatDialog,
        private _formBuilder: FormBuilder
    ) {
        this.matcher = new ErrorMatcher();
    }

    ngOnInit(): void {
        this._setFromBuilder();
    }

    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */
    private _setFromBuilder(): void {
        this.refundForm = this._formBuilder.group({
            refundReason: ['', [Validators.required]],
            customerNotes: [''],
        });
    }

    /**
     * @method refundReason()
     * @description: Convenience getter for easy access to form fields
     */
    get refundReason(): AbstractControl<string> | null {
        return this.refundForm?.get('refundReason');
    }

    /**
     * @method customerNotes()
     * @description: Convenience getter for easy access to form fields
     */
    get customerNotes(): AbstractControl<string> | null {
        return this.refundForm?.get('customerNotes');
    }

    /**
     * @method refund()
     * @description
     */

    refund(): void {
        this.handleStatusUpdate.emit({ ...this.refundForm.value });
        this.closeDialog();
    }

    /**
     * @method closeDialog()
     * @description Close the dialog in this case menu right sidebar for the activity log
     */

    closeDialog(): void {
        this._matDialog.closeAll();
    }

    /**
     * @method resetCustomerNotes()
     * @description
     */
    resetCustomerNotes(event: MatSelectChange): void {
        if (this.customerNotes) {
            this.customerNotes.setValue('');

            if (event.value === 'Other') {
                this.customerNotes.setValidators([
                    Validators.maxLength(255),
                    Validators.minLength(5),
                    Validators.required,
                ]);
                this.customerNotes.markAsPristine();
                this.customerNotes.markAsUntouched();
            } else {
                this.customerNotes.clearValidators();
            }

            this.customerNotes.updateValueAndValidity();
        }
    }
}
